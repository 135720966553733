import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder,UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-basic-details-popup',
  templateUrl: './basic-details-popup.component.html',
  styleUrls: ['./basic-details-popup.component.css']
})
export class BasicDetailsPopupComponent implements OnInit {
  basicDetailsForm : UntypedFormGroup;
  commonFormSubmitted: Boolean = false;
  @Input() title: string = '';

  constructor(private formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.basicDetailsForm = this.formBuilder.group({
      commonForm: []
    });
  }

  ngOnInit(): void {
  }
  submit(): any {
    this.commonFormSubmitted = true;
    //console.log('basic form', this.basicDetailsForm.controls['commonForm'].value);
    if(!this.basicDetailsForm.valid) {
      return false;
    } else {
      this.activeModal.close(this.basicDetailsForm.controls['commonForm'].value);
    }
  }
}
