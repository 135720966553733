<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Payment Reconcillation Status Report
        </div>
    </div>
    <div class="mainPageContainer mr-3 ">
        <h6 class="mb-3 text-center"><b>Payment Reconcillation Status Report </b></h6>

        <hr class="hrCls mt-4">
        <div class="subHeadingCls mb-3">Billdesk Reconcillation Status</div>

        <div class="row mb-3 mt-3">
            <div class="col col-lg-9 col-md-9 col-sm-9 col-xs-9">

                <div class="table-responsive tableFixHead">
                    <table class="tableCls">
                        <thead>
                            <tr>
                                <th>Last Run Date</th>
                                <th>Transaction Count</th>
                                <th>Action Type</th>
                                <!--  <th>Fail Transcation<br> Count</th>
                                <th>Inprogress Transaction<br> Count</th>
                                <th>Challan Initiated <br>Transaction Count</th>
                                <th>Refund Count</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <!--  <tr>
                              <td> {{ apiResponse.successDate | date: 'dd/MM/yyyy'}} {{ apiResponse.successDate | date: 'h:mm:ss a'}} </td>
                                <td> {{ apiResponse.failCount }} </td>
                                <td> {{ apiResponse.inProgressCount }} </td>
                                <td> {{ apiResponse.challanInitiatedCount }} </td>
                                <td> {{ apiResponse.refundCount }} </td> -->

                            <tr>
                                <td> {{ apiResponse.successDate | date: 'dd/MM/yyyy'}} {{ apiResponse.successDate | date: 'h:mm:ss a'}}</td>
                                <td class="clsCenter"> {{ apiResponse.successCount}}</td>
                                <td> Success</td>
                            </tr>
                            <tr>
                                <td> {{ apiResponse.failDate | date: 'dd/MM/yyyy'}} {{ apiResponse.failDate | date: 'h:mm:ss a'}}</td>
                                <td  class="clsCenter"> {{ apiResponse.failCount}}</td>
                                <td>Fail</td>
                            </tr>
                            <tr>
                                <td> {{ apiResponse.inProgressDate | date: 'dd/MM/yyyy'}} {{ apiResponse.inProgressDate | date: 'h:mm:ss a'}}</td>
                                <td class="clsCenter"> {{ apiResponse.inProgressCount}}</td>
                                <td>InProgress</td>
                            </tr>

                            <tr>
                                <td> {{ apiResponse.challanInitiatedDate | date: 'dd/MM/yyyy'}} {{ apiResponse.challanInitiatedDate | date: 'h:mm:ss a'}}</td>
                                <td class="clsCenter"> {{ apiResponse.challanInitiatedCount}}</td>
                                <td>Challan Initiated</td>
                            </tr>
                            <tr>
                                <td> {{ apiResponse.refundDate | date: 'dd/MM/yyyy'}} {{ apiResponse.refundDate | date: 'h:mm:ss a'}}</td>
                                <td class="clsCenter"> {{ apiResponse.refundCount}}</td>
                                <td>Refund</td>
                            </tr>
                        </tbody>
                    </table>


                </div>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 pl-0" style="border: 1px solid #ccc;">
                <div class="serviceBlock text-center">
                    <div class="searchFeature my-2"></div>
                    <h6>Search By Feature</h6><br><br>
                    <div class="labelCls">Search by Challan ID</div>
                  
                        <div class="row mb-3 mt-3">
                            <div class="col nopadding col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                            <div class="col nopadding col-lg-7 col-md-7 col-sm-7 col-xs-7">
                                <input type="text" #challanId placeholder="Challan Id" class="inputCls"  style="width: 100%;">
                                <div *ngIf="challnRrrorCls" class="selectError">
                                   Please Enter Challan id
                                </div>
                            </div>

                            <div class="col nopadding col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                <button class="btn btnSearch btn-primary mx-auto d-block" (click)="searchChallan(showChallanStatus,challanId.value)">Search</button>
                            </div>
            
                        </div>
                    </div>
            </div>


        </div> <br>

        <div class="row mb-3 mt-3">


            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 btnHide">

                <label class="labelCls">Reconcillation Status:</label>

            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 btnHide">
                <select class="selectCls" required #reconsallationStatus>
                    <option value="">Select</option>
                    <option value="Challan Initiated">Challan Initiated</option>
                    <option value="SUCCESS">Success</option>
                    <option value="Fail">Fail</option>
                    <option value="InProgress">InProgress</option>
                </select>
                <div *ngIf="errorCls" class="selectError">
                    <div>Please select Reconcillation Status</div>
                </div>
            </div>

        </div>

        <div class="row mb-3 mt-3 btnHide">
            <div class="btnHolder text-center col-12">
                <button class="btn btn-primary m-3" (click)="submit(reconsallationStatus.value)">Run Billdesk
                    Reconcillation</button>
                <button class="btn btn-primary" (click)="refundAPI()">Run Billdesk Refund</button>
            </div>
        </div>




        <hr class="hrCls mt-4">
        <div class="subHeadingCls mb-3">Old Portal To New Portal Data Transfer Status</div>

        <div class="row mb-3 mt-3">
            <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div class="table-responsive tableFixHead">
                    <table class="tableCls">
                        <thead>
                            <tr>
                                <th>Last Run Date</th>
                                <th>Last Transfer Number Of Records</th>
                                <th>Total Number of Transactions Transferred Today</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr>
                                <td> {{ gswsoldApiResponse.actionDate | date: 'dd/MM/yyyy'}} {{
                                    gswsoldApiResponse.actionDate | date: 'h:mm:ss a'}} </td>
                                <td  class="clsCenter"> {{ gswsoldApiResponse.transactionCount }} </td>
                                <td  class="clsCenter"> {{ gswsoldApiResponse.totalTransactionCount }} </td>
                                <td> {{ gswsoldApiResponse.remark }} </td>
                            </tr>
                        </tbody>
                    </table>


                </div>

            </div>
        </div><br>
        <div class="row mb-3 mt-3 btnHide">
            <div class="btnHolder text-center col-12">
                <button class="btn btn-primary mx-auto d-block" (click)="saveGswsToVswsServiceData()">Run Old To New
                    Portal Data Transfer</button>
            </div>
        </div>
    </div>
</div>





<!-- showChallanStatus popup -->
<ng-template #showChallanStatus let-modal class="modalCls">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Challan Status Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span >×</span>
        </button>
    </div>

    <div class="modal-body" id="transPoup">
        <br>
        <div class="table-responsive tableFixHead">
            <table class="tableCls">
                <thead>
                    <tr>
                        <th>Challan Id</th>
                        <th>Challan Status</th>
                        <th>Action Date</th>
                        <th>Action Status</th>
                        
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td> {{ challanData.challanId}}</td>
                        <td> {{ challanData.challanStatus}}</td>
                        <td> {{ challanData?.actionDate | date: 'dd/MM/yyyy'}} {{ challanData?.actionDate | date: 'h:mm:ss a'}}
                        </td>
                        <td> {{ challanData.actionStatus}}</td>
                       
                    </tr>
                </tbody>

           
            </table>

        </div>
       
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>