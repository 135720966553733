import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonConstants } from './../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
import * as moment from 'moment';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import {
  NgbNavConfig,
  NgbNavChangeEvent,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.css'],
})
export class PaymentResponseComponent implements OnInit {
  transactionID: any;
  orderId: any;
  paymentError: Boolean = true;
  currentDate = new Date();
  dateTime: any;
  responseMsg: any;
  transactionStatus: any;
  commonConstants: any = CommonConstants;
  postData: any = {};
  userInfo: any = [];
  isZeroPayment: boolean =false;
  userInformation: any;
  loggedinDetails: any;
  loggedinuserId: any;
  aadharNum : any;
  appType: any;
  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private commonService: CommonService,
    private modalService: NgbModal,
  ) {
    this.currentRoute.queryParams.subscribe((params) => {
      console.log(params.msg, '>>>URL message data');
      console.log(params.applicationId, '>>>URL message data');
     
      if(params.msg === "Thread was being aborted."){
     
        this.orderId = 'Fail';
        this.transactionStatus = 'Thread was being aborted.';
        this.paymentError = false;
      }
      else if(params.msg === "zero-payment"){
     
        this.orderId = params.applicationId;
        this.transactionStatus = 'Transaction Aborted';
        this.isZeroPayment = true;
        this.responseMsg='We are unable to Process Your Request Sorry For the Inconvenience'
      }
      else{
        this.getPaymentRespons(params.msg);
      }
    });
    this.formatAMPM()
  }

  ngOnInit(): void {}

  getPaymentRespons(samplePaymentResponse: any) {
    var splitted = samplePaymentResponse.split('|');
    console.log(splitted);
    let userData = this.commonService.getLocalStorage('userInfo');
   
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
    }
    this.getId = splitted[1].substring(1);
    this.dptCode = splitted[18]
    this.reqType = splitted[19];
    this.aadharNum = splitted[21]
    this.appType = splitted[22]
    this.userInformation = this.commonService.getLocalStorage('userInfo');
    this.loggedinDetails = JSON.parse(this.userInformation);
    this.loggedinuserId = this.loggedinDetails.userPrincipalName;
    let secreteriateCode = this.commonService.getLocalStorage('locationCode');
    let isCitizen = (this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva') ? true : false;

    if (splitted[1].length == 22) {

      let ChallanStatus = "Fail";
     
      if (splitted[14] == '0300') {
          ChallanStatus = "Success";
      } else if(splitted[14] == '0002') {
        ChallanStatus = "InProgress";
      } else if(splitted[14] == '0399') {
        ChallanStatus = "Fail"
      }

      this.postData = {
        requisitionId: splitted[1],
        departmentId: '',
        transactionId: splitted[1],
        transactionStatus: ChallanStatus,
        //transactionStatus: splitted[14],
        transactionAmount: splitted[17],
        piplineString: samplePaymentResponse,
        transactionMode: 'BILLDESK_UPI',
        userId: (isCitizen ===  true ? this.userInfo.userPrincipalName : ''),
        secretariat_Code: (isCitizen ===  false ?   secreteriateCode : ''),
      };
    
    } else {
      /* save buildesk response */
      this.postData = {
        requisitionId: splitted[1].substring(1),
        requisitionType: splitted[19],
        departmentId: splitted[18],
        transactionId: splitted[1],
        transactionStatus: splitted[14],
        transactionAmount: splitted[17],
        piplineString: samplePaymentResponse,
        transactionMode: 'UPI',
        userId: this.userInfo.userPrincipalName,
        secretariat_Code: (isCitizen ===  false ?   secreteriateCode : ''),
      };
      // this.updateTransactionStatusInRequisitionDetails(this.postData);
    }

    this.commonService
      .postRequest(this.commonConstants.saveBillDeskResponse, this.postData, true)
      .subscribe({
        next: (responseData: any) => {
          //console.log(responseData, 'saveBillDeskResponse respose data');
          if(responseData.result == "Success") {
            this.showPaymentResponse(samplePaymentResponse);
          } else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = responseData.result;
            modalRef.result.then((result: any) => {
              if (result) {
                  this.router.navigate(['/home']);
              }
            });
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    /* save buildesk response */
  }

  showPaymentResponse(samplePaymentResponse: any) {
    var splitted = samplePaymentResponse.split('|');
    console.log(splitted, 'splitted');
    this.paymentError = true;
    if (splitted[14] == '0300') {
      if (splitted[1].length == 22) {
        const queryParams: Params = { id: splitted[1], amount: splitted[17] ,type:"gsws"};
        this.router.navigate(['/view-challan'], {
          relativeTo: this.currentRoute,
          queryParams: queryParams,
          queryParamsHandling: '',
        });
      } else {

        ///condition here for payment fail
        let reqIDdata = splitted[1].substring(1);
        const queryParams: Params = {
          id: reqIDdata,
          department: splitted[18],
          type: splitted[19],
        };
        this.router.navigate(['/payment-receipt'], {
          relativeTo: this.currentRoute,
          queryParams: queryParams,
          queryParamsHandling: '',
        });
      }
    } else {
      this.transactionID = splitted[25];
      this.orderId = splitted[1];
      this.dateTime = splitted[13];
      this.responseMsg = splitted[24];
      this.transactionStatus = 'Fail';
      this.paymentError = false;
      console.log(this.dptCode,'dptcode payment fail')
      if(this.dptCode == "CS"){
        this.getClusterDetails()
        
      }
      this.getMobileNumber();
    }
  }

  getId: any;
  reqType: any;
  dptCode: any;
  
  workflowStatusUpdate(statusValue:any,statusCode:any,obj:any) {
    console.log("name",this.loggedinuserId);
    let noStatus = '';
    let year=  this.currentDate.getFullYear()
    let month= this.currentDate.getMonth() + 1
    let day=  this.currentDate.getDate()
    let dateFormat = day +"-"+  month +"-"+ year 
    let createdDate = moment(dateFormat,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss")
    let reqType =  this.reqType
    let dpCode  = "CS"
    let resID =  this.getId
    let weblandMsg :any ;
    let weblandStatus:any;
      weblandMsg = '-10';
      weblandStatus = 'Payment Failed - ' + this.getId + ' .Please dont consider this request.'
    let workFlowPostData12 : any = {}
    workFlowPostData12 = {
      workFlowStatusData: {
        id: 0,
        requisitionId: resID,
        departmentCode: dpCode,
        requisitionType: reqType,
        subRequisitionType: '',
        statusCode: weblandMsg,
        statusDesc: weblandStatus, //'Forwarded to Webland Team',
        requisitionStatus:'',
        createdBy: this.loggedinuserId,
        created: createdDate,
        lastModifiedBy: this.loggedinuserId,
        lastModified: createdDate,
        slaDays: "",
        officerId: '',
        officerName: '',
        officerMobileNumber: '',
        additionalInfo1: obj,
        additionalInfo2: '',
        additionalInfo3: ''
      },
      hash: '',  //responseData['result'].hashRes
    }
      console.log('-10 payment issue',this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData12)
      this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData12).subscribe({
        next: (responseData:any) => { 
          console.log('response from postWorkFlowStatusUpdate', responseData)
            if(responseData['result'].reason == 'Success'){
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.title = 'Alert';
              modalRef.componentInstance.message = 'Payment Failed - ' + this.getId + ' .Please dont consider this request.'
              modalRef.result.then((result: any) => {
              });
            }else{
              const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
              modalRef.componentInstance.title = 'Alert';
              modalRef.componentInstance.message = "Error from Request. " + responseData['result'].reason ;
              modalRef.result.then((result: any) => {
              });
            }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
  }

  alertDateTimeZone : any;
   formatAMPM() {
    var date = new Date(); 
      var hours : any = date.getHours();
      var minutes : any= date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime =  date.getDate() + "/"  + (date.getMonth()+1)  + "/"  + date.getFullYear() + ' '+ hours + ':' + minutes + ' ' + ampm;
      this.alertDateTimeZone =  date.getDate() + "/" + (date.getMonth()+1)  + "/" + date.getFullYear() + ' '+ hours + ':' + minutes + ' ' + ampm;
      return strTime; 
   }

   clusterData: any = [];
   userSecretriatId : any;
   getClusterDetails(){
    var x= this.userInfo.userPrincipalName.split('-')
    console.log("userSecretriatId",this.userSecretriatId);
    this.userSecretriatId = x[0].includes('U') ? x[0].slice(1) : x[0]
    this.clusterData = []
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + this.userSecretriatId + '&EntityType=Secretariat').subscribe({  
      next: (rsData: any) => { 
          this.commonService.getRequest(this.commonConstants.getCluster + rsData.result[0]?.id).subscribe({
            next: (responseData: any) => { 
    
            this.clusterData = responseData.result;
            console.log("ClusterData",this.clusterData);
            console.log("ClusterData 123",this.clusterData[0].entityCode);
            this.getfpshopDetails(this.clusterData[0].entityCode)
          
            },
            error: (error) => {
            console.log('client side', error);
            }
          });
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
      
    }

    fpshopData : any
    getfpshopDetails(clusterID : string){
      // console.log("keyValue",this.keyValue.split(':')[1]);
      // console.log("a",this.clusterDetails);
      
      let obj = 
        {
          cluster_id: clusterID.trim(),
          createdBy: this.loggedinuserId,
          requestNumber: this.getId,
      }
       console.log('obj',obj);
      
      this.commonService.postRequest(this.commonConstants.getFpshop,obj).subscribe({
        next: (responseData: any) => { 
                  // this.clusterData =  this.commonService.sortData(responseData.result);
  
        this.fpshopData = responseData.result.cluster_details;
        console.log('this.fpshopData',this.fpshopData)
        this.submitfpshop(clusterID,this.fpshopData[0].shop_no)
        console.log("fpshop",  this.fpshopData[0].shop_no);
        
        },
        error: (error) => {
        console.log('client side', error);
        }
    });
    }

  submitfpshop(clusterID : any,shopNo : any){

    let obj = {
      spandana_application_id: this.getId,
      cluster_id: clusterID, //'10690658012',
      shop_no: shopNo, //'0611003',
      password : "11169d3abc20724bb857cc5ea59e3c42",
      hts : "12",
      aadharNumber: this.commonService.RSAEncrypt(this.aadharNum),
      createdBy: this.loggedinuserId,
      requestNumber: this.getId,
      applicationType : this.appType,
    };
    this.commonService
      .postRequest(this.commonConstants.getClusterFpShopResult, obj)
      .subscribe({
        next: (responseData: any) => {
          if(responseData.result.status == "200" || responseData.result.status == "201")
          {
            this.updatePaymentFailStatus(obj)
          }else{
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Error ' + this.alertDateTimeZone;
          modalRef.componentInstance.message = 'Error in Updating Cluster Data';
          }
        },
      });
  }


  nicStatus : any; 
  nicMessege : any;
  updatePaymentFailStatus(obj : any) {
    var postApporveData={
      approval_Status_Bean: {
        application_id: this.getId,
        district_code: "0",
        status: "2"
      },
      password: "11169d3abc20724bb857cc5ea59e3c42",
      hts: "12",
      aadharNumber: this.commonService.RSAEncrypt(this.aadharNum),
      createdBy: this.loggedinuserId,
      requestNumber: this.getId,
      applicationType : this.appType,
      }
    this.commonService.postRequest(this.commonConstants.requestCSActionStatus, postApporveData).subscribe({
      next: (responseData:any) => { 
        console.log(responseData,'not recommended post respose data');
        this.nicStatus =responseData.result.status;
        this.nicMessege = responseData.result.message;
        if(responseData.result.message.length == 0 ||  responseData.result.status.length ==0){
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
          modalRef.componentInstance.message = this.nicMessege!= undefined || null ? this.nicMessege : 'Error in External API please try again';
          modalRef.result.then((result: any) => {
          });
        }else{
          // this.wFlowGetSLAData("CS",responseData.result.status,postApporveData,2,"Reject")
          console.log(responseData,'Ekyc Completed approve post respose data');
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Success ' + this.alertDateTimeZone;
            modalRef.componentInstance.message = this.nicMessege!= undefined || null ? this.nicMessege : 'Error in External API please try again';
            modalRef.result.then((result: any) => {
            });
            // this.workflowStatusUpdate('2',responseData.result.status,obj);
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  updateTransactionStatusInRequisitionDetails(inputParam: any) {
    let InputStr = {
      Userid: this.userInfo.userPrincipalName,
      RequisitionId: inputParam.requisitionId,
      Department: inputParam.departmentId,
      RequisitionType: inputParam.requisitionType,
      TransactionId: inputParam.transactionId,
      TransactionStatus: inputParam.transactionStatus,
      TransactionAmount : inputParam.transactionAmount,
    };
    this.commonService
      .postRequest(
        this.commonConstants.updateTransactionStatusInRequisitionDetails,
        InputStr
      )
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'responseData');
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  getMobileNumber() {
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + this.postData?.departmentId + '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ this.postData?.requisitionId).subscribe({
      next: (responseData:any) => { 
        let mobileNumber: any = responseData.result?.requisitionHeader?.applicantInfo?.mobileNumber;
        this.sendSMS(mobileNumber);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  
  sendSMS(mobileNo: any) {
    let message = `Dear Applicant, Your payment made for Application No:(`+ this.postData?.requisitionId +`) has failed. Your payment transaction No is (`+ this.postData?.transactionId +`).Please download receipt from following link:https://vswsonline.ap.gov.in . Refund, if applicable, will be done in (7 working) days.-GOVTAP`;
    let postData: any = {};
    postData.phoneNo = [mobileNo];
    postData.templateId = '1007083410179372220';
    postData.message = message;
    postData.department = this.postData?.departmentId;
    postData.referenceId = this.postData?.requisitionId;
    postData.referenceType = this.postData?.requisitionType;
    postData.isBulkSend = false;
    postData.userId = this.loggedinuserId;
    console.log('postdata for SMS', postData);
    this.commonService.postRequest(this.commonConstants.sendSMS, postData).subscribe({
      next: (responseData:any) => { 
        console.log(responseData,'reponse from SMS');
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
}
