import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService, WhitespaceValidator } from '../common.service';
import { fileExtensionValidator, fileSizeValidator } from '../validators/file-validator';
import * as moment from 'moment';
@Component({
  selector: 'app-account-details-popup',
  templateUrl: './account-details-popup.component.html',
  styleUrls: ['./account-details-popup.component.css']
})
export class AccountDetailsPopupComponent implements OnInit {
  accountForm: UntypedFormGroup;
  @Input() officerData: any;
  @ViewChild('passbookLabel')
  passbookLabel!: ElementRef;
  commonConstants : any = CommonConstants;
  submitted: Boolean = false;
  passbookBlobRef: any;
  isEditable: Boolean = false;
  savedData: any;
  imgSrc: any;
  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig, private formBuilder: UntypedFormBuilder, private commonService: CommonService) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.accountForm = this.formBuilder.group({
      acName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      acNo: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate]],
      bankName: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate]],
      ifscCode: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate]],
      passbook: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getAccountDetails();
  }
  get f() { return this.accountForm.controls; }
  saveDetails(): any {
    this.submitted = true;
    console.log('save')
    if(!this.accountForm.valid)
      return false;
      console.log('save ....')
    let postData: any = {};
    let data: any = {};
    let currentDate = new Date();
    let year=  currentDate.getFullYear()
    let month= currentDate.getMonth() + 1
    let day=  currentDate.getDate()
    let dateFormat = day +"-"+  month +"-"+ year
    let createdDate = moment(dateFormat,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss");
    data.createdBy =  this.officerData?.userId,
    data.created = createdDate,
    data.lastModifiedBy = this.officerData?.userId,
    data.lastModified = createdDate,
    data.id = 0,
    data.userId = this.officerData?.userId,
    data.districtName = this.officerData?.district,
    data.mandalOrRevDivisionName = this.officerData?.mandal,
    data.designation = this.officerData?.designation,
    data.designatedPersonName = this.officerData?.name,
    data.aadharNumber = this.officerData?.aadharNo,
    data.contactNumber = this.officerData?.mobileNo,
    data.officialAccountName = this.f.acName.value,
    data.accountNumber = this.f.acNo.value,
    data.bankName = this.f.bankName.value,
    data.branchName = "",
    data.ifscCode = this.f.ifscCode.value,
    data.passbookBlobRef = this.passbookBlobRef

    postData.bankAccountDetailsData = data;
    this.commonService.postRequest(this.commonConstants.saveAccountDetails, postData).subscribe({
      next: (responseData:any) => { 
        if(responseData.result?.isSuccess) {
          this.activeModal.close();
          this.commonService.commonErrorPopup('Alert', 'Account details saved successfully.','md')
        } else {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error,'md');
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  getAccountDetails() {
    let postData: any = {};
    this.commonService.getRequest(this.commonConstants.getAccountDetails + this.officerData?.userId).subscribe({
      next: (responseData:any) => { 
          if(responseData.result?.isSuccess) {
            this.savedData = responseData.result?.bankAccountDetailsData;
            this.viewFile(this.savedData?.passbookBlobRef)
            this.isEditable = false;
          } else {
            this.isEditable = true;
          }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  viewFile(blobRef:any) {
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
          this.imgSrc = 'data:image/jpg;base64,' +fileInfo;
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onPassbookSelected(fileEvent: any) {
    const passbookFile: File | null = fileEvent.target.files[0];
    console.log(fileEvent.target.files[0],'file main')
    const file = fileEvent.target.files[0];
    const reader = new FileReader();
    // reader.addEventListener("loadend", function () {
    //   // convert image file to base64 string
    //   console.log('reader', reader.result);
    //   byteArray = convertDataURIToBinary(reader.result);
    //   console.log('byte array', byteArray);
    // }, false);
    reader.onload = this.convertTobase64.bind(this);
      reader.readAsDataURL(file);
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    console.log(fileExtensionType,'fileExtensionType')
    this.passbookLabel.nativeElement.innerText =
      fileEvent.target.files.length !== 0
        ? fileEvent.target.files[0]?.name
        : 'No File Choosen';
        var validExt = "";
        // console.log('fileExtensionType',fileExtensionType)
        if(fileExtensionType.toLowerCase() === 'jpg'){
          validExt = "jpg"
        }else {
          validExt = "jpeg"
        }
    this.f.passbook.setValidators([Validators.required, fileExtensionValidator(validExt),
       fileSizeValidator(fileEvent)
      ,
    ]);
    this.f.passbook.updateValueAndValidity();
    if (this.f.passbook.valid){
      this.fileUpload(
        passbookFile,
        this.f.passbook.value,
        fileEvent.target.files[0].name,
        'Passbook','passbook', 'passbookLabel'
      );
    }
      
  }
  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    console.log(formData, 'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
      next: (responseData: any) => {
        if(typeof(responseData.result) !== 'string'){ 
          this.passbookBlobRef = responseData['result']?.blobRef;  
          }else{
            if(name && label) {
              this.clearFileUpload(name, label);
            }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          }
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }
  convertTobase64(e: any) {
    console.log('convertTobase64');
    var reader = e.target;
    let imageSrc = reader.result;
    console.log('base64', imageSrc);
  }
  clearFileUpload(name: string, label: string) {
    this.f[name].reset();
    if (label === 'passbookLabel') {
      this.passbookLabel.nativeElement.innerText = 'No File Choosen';
    }
  }
}
  function convertDataURIToBinary(dataURI: any): any {
    var base64Index = dataURI.indexOf(';base64,') + ';base64,'.length;
    var base64 = dataURI.substring(base64Index);
    console.log(base64,'base 64 ')
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
  
    for (let index = 0; index < rawLength; index++) {
      array[index] = raw.charCodeAt(index);
      
    }
    return array;
  }

