import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService,CustomAdapter,CustomDateParserFormatter,WhitespaceValidator,AadharValidator } from '../../shared/common.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalConfig,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerPopupComponent } from '../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { FormGroup, UntypedFormBuilder, Validators, FormControl, FormArray} from '@angular/forms';
import * as moment from 'moment';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DigitalSignService } from 'src/shared/digital-sign.service';
import { DatePipe } from '@angular/common';
import { fileExtensionValidator,fileSizeValidator } from 'src/shared/validators/file-validator';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Thumbs } from 'swiper';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { EnvironmentService } from '../../shared/environment.service';
import { IncomeUpdate } from '../revenue-view/income-certificate-view/income-certificate-view.component';
import { AccountDetailsPopupComponent } from '../../shared/account-details-popup/account-details-popup.component';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-common-dashboard-links',
  templateUrl: './common-dashboard-links.component.html',
  styleUrls: ['./common-dashboard-links.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]

})


export class CommonDashboardLinksComponent{
 
  @Input() role: any = "";

  userInformation: any;
  loggedinDetails: any;
  loggedinuserId: any;
  userIdData : string = '';
  userName : string = '';
  commonConstants : any = CommonConstants;
  isViksitBharathDayNodalReport:Boolean=false;



  constructor(private commonService: CommonService, private modalService: NgbModal,private datePipe: DatePipe, private formBuilder: UntypedFormBuilder,  private digiSign: DigitalSignService,private utils: CommonService, config: NgbModalConfig,
    private environmentService: EnvironmentService, private router: Router, private currentRoute: ActivatedRoute) {
    
      this.userInformation = this.commonService.getLocalStorage('userInfo');
      this.loggedinDetails = JSON.parse(this.userInformation);
      this.loggedinuserId = this.loggedinDetails.userPrincipalName;
      var str = this.loggedinuserId.split("-")[0]?.replace('U', '')

      if (this.userInformation && this.userInformation !== null) {
        let userData = JSON.parse(this.userInformation);
        this.role = userData.jobTitle;
        this.userIdData = userData.userPrincipalName
        this.userName = userData.displayName
        
       // console.log(this.eMudhradisplayName,this.capitalizeWords( this.eMudhradisplayName),'this.eMudhradisplayName')
      }
      if(this.role ==='Municipal Commissioner' || this.role === 'Mandal Parishad Development Officers' || this.role === 'Joint Collector' ||  this.role === 'Collector'){
        this.isViksitBharathDayNodalReport = true;
      }else{
        this.isViksitBharathDayNodalReport =false;
      }
    
    }

   //Redirect to meeseva dept portal
   redirectServices(from: string) {
    const hostname = window && window.location && window.location.hostname;

    let secretariatCode: any;
    let userId = this.loggedinDetails?.userPrincipalName?.split('-');
      if (userId?.length > 0) {
         secretariatCode = userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] : userId[0];
      }
    let url: any;
    if (from === 'RTS') {
      // if (/^.*localhost.*/.test(hostname) || /^gswswebdev.z29.web.core.windows.net/.test(hostname)) {
      //   url = 'http://uat.gramawardsachivalayam.ap.gov.in/GSWSRTS/UI/RTS_SSO.aspx'; //RTS
      // } else {
      //   url = 'https://gramawardsachivalayam.ap.gov.in/GSWSRTS/UI/RTS_SSO.aspx'; //RTS
      // }
      url = this.environmentService.externalUrls.RTS;
    } else {
      // url = 'https://uat.meeseva.gov.in/APSDCDeptPortal/UserInterface/GSWSIntegration.aspx'; // meeseva dept
      url = this.environmentService.externalUrls.deptUrl;
    }
    let postData: any = {};
    postData.userMobileNumber = this.loggedinDetails?.mobilePhone;
    postData.secretariatCode = secretariatCode;
    postData.userId = this.userIdData;
    postData.userName = this.userName;
    this.commonService.postRequest(this.commonConstants.redirectToExisting , postData).subscribe({
      next: (responseData: any) => {
          let transactionId: any = responseData.result?.transactionId;
          // console.log('on redirect', responseData.result);
          window.open(url + '?ReqID='+ transactionId, '_blank');
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }

  redirectToeKYCStatusServices(){
      
    let wUrl = 'http://navaratnalu-housesites1.ap.gov.in/GSVPortal/UserInterface/APSevaRedirection.aspx'; //'http://navaratnalu-uat.ap.gov.in/GSVPortal/Process/EkycStatusUpdationDetails.aspx'
    let loggedinId = '';
    
    this.commonService.getRequest(this.commonConstants.cryptoEncryptValue + encodeURIComponent(this.commonService.RSAEncrypt(this.loggedinDetails.userPrincipalName))).subscribe({
      next: (responseData: any) => {
       
        if (responseData.result?.isSuccess) {
          loggedinId =  responseData.result?.encryptedValue;
          console.log(wUrl + '?msg='+ loggedinId,'responseData')
          window.open(wUrl + '?msg='+ loggedinId);
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
}

