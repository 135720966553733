<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Revenue Department <span class="activePage">/ Challan Payment</span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">

        <button *ngIf="challanTypeActive" class="btn btn-primary text-left chlBtn" (click)="redirectChallanHistory('ots')" routerLinkActive='active'>CHALLAN
            TRANSACTION HISTORY OTS Services</button>
        <button *ngIf="!challanTypeActive" class="btn btn-primary text-left chlBtn" (click)="redirectChallanHistory('gsws')" routerLinkActive='active'>CHALLAN
            TRANSACTION HISTORY GSWS</button>

        <h6 class="mb-3 text-center" *ngIf="!challanTypeActive">CHALLAN PAYMENT GSWS</h6>
        <h6 class="mb-3 text-center" *ngIf="challanTypeActive">CHALLAN PAYMENT OTS Services</h6>

        <hr class="hrCls mt-3"><br>

        <h6 class="mb-3 text-left pCls" *ngIf="(checklist && checklist.length > 0 && newChallanFunction)">Service Transaction
            Details

            <button class="btn btnB btn-primary mx-auto  text-right btnHide" (click)="pageIndexSizeChanged('export')">Download Excel</button>

            <button class="btn btnB btn-primary text-right btnHide" (click)="pageIndexSizeChanged('print')">Print Table</button>
            <button *ngIf="newChallanFunction && checkedList && checkedList.length > 0" type="button" class="btn btnB btn-primary text-right btnHide" (click)="showSelectedChallans()">Show Selected Challans</button>
        </h6>    
       
        <!--start new challan -->
        <div class="row mb-3" *ngIf="newChallanFunction">
            <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div class="table-responsive tableFixHead" *ngIf="checklist && checklist.length > 0">
                    <table class="tableCls">
                        <thead>
                            <tr>
                                <th class="pHide">
                                    <input type="checkbox" class="chkBBox" [(ngModel)]="masterSelectedd" name="list_name" value="m1"
                                    (click)="validateAllChallan($event)" (change)="checkUncheckAll()" /> All
                                </th>
                                <th>Sr.No. </th>
                                <th>TransactionID </th>
                                <th *ngIf="challanTypeActive">Beneficiary Name</th>
                                <th *ngIf="challanTypeActive">Beneficiary ID</th>
                                <th *ngIf="challanTypeActive">Housing Id</th>
                                <th>Service Name</th>
                                <th>Transaction Date</th>
                                <th>Transaction Amount</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="checklist && checklist.length !==0">

                            <tr *ngFor="let item of checklist; let i=index">
                                <td class="text-center pHide">
                                    <input type="checkbox" class="chkBBox" [(ngModel)]="item.isSelected" name="list_name"
                                        value="{{item.id}}" (click)="validateChallan($event)" (change)="selectChallan(item)" />
                                </td>
                                <td>{{ ((page -1) * pageSize) + (i + 1)}} </td>
                                <td class="text-left"> {{item.transactionId}} <span *ngIf="item.department_App_No && item.department_App_No !== ''"> ({{ item.department_App_No}})</span> </td>
                                <td *ngIf="challanTypeActive" class="text-center">{{item.beneficiaryName ? item.beneficiaryName:'-'}}</td>
                                <td *ngIf="challanTypeActive" class="text-center">{{item.beneficiaryId ? item.beneficiaryId:'-'}}</td>
                                <td *ngIf="challanTypeActive" class="text-center">{{item.housingId ? item.housingId:'-'}}</td>
                                <td class="text-left"> {{item.serviceName}}</td>
                                <td *ngIf="item.serviceName === 'OTS Payments'" class="text-center">{{item.transactionDate }}</td>
                                <td *ngIf="item.serviceName !== 'OTS Payments'" class="text-center"> {{item.transactionDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                <td class="text-center"> {{item.transactionAmount}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div class="mt-4 btnHide col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="float-left" *ngIf="totalPageCount > 0 && checklist.length >0">
                        <label for="page">Requests per page: </label>
                        <select  id="page" class="selectCls pageSizeCls" [(ngModel)]="pageSize" (change)="pageIndexSizeChanged('pageSize')">
                            <option  value="50">50</option>
                            <option  value="100">100</option>
                            <option  value="150">150</option>
                            <option  value="200">200</option>
                            <option  value="250">250</option>
                        </select> 
                    </div>
                    <ngb-pagination class="d-flex justify-content-end"   *ngIf="totalPageCount > 0 && checklist.length >0" [collectionSize]="totalPageCount"[(page)]="page" [pageSize]="pageSize"
                        [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageIndexSizeChanged('pageindex')">
                    </ngb-pagination>
                </div>

                <div class="text-center selectError clsSize  mb-3 mt-3"
                    *ngIf="checklist && checklist.length === 0"><br><br>
                    <b> Cash Mode Transactions Not Available.. </b>
                </div>

            </div>

        </div><br>


        <ng-template #selectedChallanModal let-modal>
            <div class="modal-header">
             <h5 class="modal-title" id="editProfileLabel">Selected Challans</h5>
             <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
              <span >&times;</span>
             </button>
            </div>
            
            <div class="modal-body">
                <div class="row">
                    <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
        
                        <div class="table-responsive tableFixHead" *ngIf="checkedList && checkedList.length > 0">
                            <table class="tableCls" id="excel-table">
                                <thead>
                                    <tr>                                
                                        <th>Sr.No. </th>
                                        <th>TransactionID </th>
                                        <th>Service Name</th>
                                        <th>Transaction Date</th>
                                        <th>Transaction Amount</th>
                                    </tr>
                                </thead>
        
                                <tbody *ngIf="checkedList && checkedList.length !==0">
        
                                    <tr *ngFor="let item of getSortedSeletedChallans(); let i=index">                                
                                        <td>{{ item.id }} </td>
                                        <td class="text-left"> {{item.transactionId}} <span *ngIf="item.department_App_No && item.department_App_No !== ''"> ({{ item.department_App_No}})</span> </td>
                                        <td class="text-left"> {{item.serviceName}}</td>
                                        <td *ngIf="item.serviceName === 'OTS Payments'" class="text-center">{{item.transactionDate }}</td>
                                        <td *ngIf="item.serviceName !== 'OTS Payments'" class="text-center"> {{item.transactionDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                        <td class="text-center"> {{item.transactionAmount}}</td>
                                    </tr>
        
                                </tbody>
                                <tbody *ngIf="checkedList.length === 0">
                                    <tr>
                                        <td colspan="6" class="text-center">No challans is selected...</td>
                                    </tr>
                
                                </tbody>
                            </table>
                        </div>
                    </div>
        
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </ng-template>


        <div class="row hideTable" id="_printData" *ngIf="newChallanFunction">
            <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <div class="table-responsive tableFixHead" *ngIf="listOfAllTrasaction && listOfAllTrasaction.length > 0">
                    <table class="tableCls" id="excel-table">
                        <thead>
                            <tr>                                
                                <th>Sr.No. </th>
                                <th>TransactionID </th>
                                <th *ngIf="challanTypeActive">Beneficiary Name</th>
                                <th *ngIf="challanTypeActive">Beneficiary ID</th>
                                <th *ngIf="challanTypeActive">Housing Id</th>
                                <th>Service Name</th>
                                <th>Transaction Date</th>
                                <th>Transaction Amount</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="listOfAllTrasaction && listOfAllTrasaction.length !==0">

                            <tr *ngFor="let item of listOfAllTrasaction; let i=index">                                
                                <td>{{ (i + 1) }} </td>
                                <td class="text-left"> {{item.transactionId}} <span *ngIf="item.department_App_No && item.department_App_No !== ''"> ({{ item.department_App_No}})</span> </td>
                                <td *ngIf="challanTypeActive" class="text-center">{{item.beneficiaryName ? item.beneficiaryName:'-'}}</td>
                                <td *ngIf="challanTypeActive" class="text-center">{{item.beneficiaryId ? item.beneficiaryId:'-'}}</td>
                                <td *ngIf="challanTypeActive" class="text-center">{{item.housingId ? item.housingId:'-'}}</td>
                                <td class="text-left"> {{item.serviceName}}</td>
                                <td *ngIf="item.serviceName === 'OTS Payments'" class="text-center">{{item.transactionDate }}</td>
                                <td *ngIf="item.serviceName !== 'OTS Payments'" class="text-center"> {{item.transactionDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                <td class="text-center"> {{item.transactionAmount}}</td>
                            </tr>

                        </tbody>
                        <tbody *ngIf="listOfAllTrasaction.length === 0">
                            <tr>
                                <td colspan="6" class="text-center">No records available...</td>
                            </tr>
        
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

        <!--end new challan -->

        <form [formGroup]="challanGeneration" *ngIf="(listOfTrasaction && listOfTrasaction.length > 0) || (checklist && checklist.length > 0)">

            <div class="row mb-3">

                <div class="col col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls" for="paymentMode"><b>Select Payment Mode :</b></label>

                    <select class="selectCls" formControlName="payment_mode" required
                        [ngClass]="{ 'is-invalid':submitted && (f.payment_mode.errors || f.payment_mode.value === 'Select') }" id="paymentMode">
                        <option [ngValue]= "null" disabled>Select</option>
                        <option value="ICICI_CHALLAN">ICICI Challan Payment</option>
                        <option value="PAYTM_CHALLAN" *ngIf="!challanTypeActive">PAYTM UPI Payment</option>
                        <option value="BILLDESK_UPI" *ngIf="!challanTypeActive">BILL DESK UPI Payment</option>
                        <option value="IPB_CHALLAN" *ngIf="(isProd && isLoginPresent) || !isProd">India Post Payment Bank</option>
                    </select>
                    <div *ngIf="submitted && (f.payment_mode.errors || f.payment_mode.value === 'Select')"
                        class="selectError">
                        <div *ngIf="f.payment_mode.errors || f.payment_mode.value === 'Select'">Please
                            select payment mode</div>
                    </div>

                </div>

                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <b class="labelCls" >Total Amount :</b>
                    <p *ngIf="!newChallanFunction" class="labelCls">{{totalAmount}} /-</p>
                    <p *ngIf="newChallanFunction" class="labelCls"> {{totalTransactionAmnt}} /-</p>
              
                </div>


                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                   <b class="labelCls" >Total Selected Transaction Count :</b>
                    <p *ngIf="newChallanFunction" class="labelCls"> {{ checkedList.length }} </p>              
                </div>

                <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  </div> -->
            </div>

            <br>
            <div class="row mb-3 mt-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="btnHolder text-center col-12" *ngIf="!challanTypeActive">
                        <button *ngIf="!newChallanFunction" class="btn btn-primary mx-auto d-block" (click)="generateChallan(mymodal)">Challan/UPI Payment</button>
                       
                        <button *ngIf="newChallanFunction"  [disabled]="!isChallan" class="btn btn-primary mx-auto d-block" (click)="generateChallanNew(mymodal)">New Challan/UPI Payment</button>
                    </div>
                    <div class="btnHolder text-center col-12" *ngIf="challanTypeActive">
                       
                        <button *ngIf="newChallanFunction" class="btn btn-primary mx-auto d-block" (click)="generateOTS_ChallanNew(mymodal)">New Challan OTS Servies</button>
                    </div>
                </div>

            </div>

            <hr class="hrCls mt-3 mb-3">
        </form><br>


        <h6 class="mb-3 text-left pCls" *ngIf="listOfTrasaction && listOfTrasaction.length > 0 && !newChallanFunction">Service Transaction
            Details

            <button class="btn btnB btn-primary mx-auto  text-right btnHide" (click)="exportexcel()"
                *ngIf="listOfTrasaction && listOfTrasaction.length !==0">Download Excel</button>

            <button class="btn btnB btn-primary text-right btnHide" (click)="printData('printData')"
                *ngIf="listOfTrasaction && listOfTrasaction.length !==0">Print Table</button>

        </h6>

         <!--start old challan -->
         <div class="row" *ngIf="!newChallanFunction">
            <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12" id="printData">

                <div class="table-responsive tableFixHead" *ngIf="listOfTrasaction && listOfTrasaction.length > 0">
                    <table class="tableCls">
                        <thead>
                            <tr>
                                <th>Serial No</th>
                                <th>Service Name</th>
                                <th>Number Of Transactions</th>
                                <th>Transaction Amount</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="listOfTrasaction && listOfTrasaction.length !==0">

                            <tr *ngFor="let item of listOfTrasaction; let i=index;">
                                <td class="text-center">{{(i+1)}}</td>
                                <td class="clsTd"
                                    (click)="transactionPopup(showTransaction, item?.requisitionType,item?.transactionFrom);">
                                    {{
                                    item?.serviceName }}</td>
                                <td class="text-center">{{ item?.noOfTransactions }}</td>
                                <td class="text-center">{{ item?.amount }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-right clsSize amntCls btnHide"
                        *ngIf="listOfTrasaction && listOfTrasaction.length !==0">
                        <b> Total Amount : </b> {{totalAmount}} /-
                    </div>

                </div>

                <div class="text-center selectError clsSize  mb-3 mt-3"
                    *ngIf="listOfTrasaction && listOfTrasaction.length === 0">
                    <b> Cash Mode Transactions Not Available.. </b>
                </div>

            </div>
        </div>
        <!--end old challan -->
        
        <!-- Generate challan Popup  -->
        <ng-template #mymodal let-modal class="modalCls">

            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Challan Data ...</h4>
                <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span >×</span>
                </button> -->
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.dismiss('Cross click')">
                    <span >×</span></button>
            </div>

            <div id="__printingFrame">

                <div class="modal-body">

                    <div class="text-center">
                        <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                            [value]="barcodeValue" qrc-class="aclass" alt="QR Code" [width]=200>
                        </ngx-qrcode>


                        <!--  <img src="../../assets/qr_code.jpg" class="d-block mx-auto" width="11%">  --><br>
                        <h6>Challan Reference From</h6>
                        <h5>GOVERNMENT OF ANDHRA PRADESH</h5>
                        <hr>
                    </div>

                    <table class="table tableModal">

                        <tr>
                            <th> Beneficiary Name</th>
                            <td> {{ challanDetails?.beneficiaryName }}</td>
                        </tr>

                        <tr *ngIf="this.f.payment_mode.value !== 'IPB_CHALLAN'">
                            <th> Beneficiary Account Number</th>
                            <td> {{ challanDetails?.beneficiaryAccountNo }}</td>
                        </tr>
                        <tr *ngIf="this.f.payment_mode.value === 'IPB_CHALLAN'">
                            <th> Challan Id</th>
                            <td> {{ challanDetails?.challanId }}</td>
                        </tr>
                        <tr>
                            <th> Beneficiary IFSC Code</th>
                            <td> {{ challanDetails?.ifscCode }}</td>
                        </tr>
                    
                        <tr>
                            <th> Bank Name and Branch Name</th>
                            <td> {{ challanDetails?.bankName }}</td>
                        </tr>
                        <tr>
                            <th> Amount to be Remitted</th>
                            <td> {{ challanDetails?.challanAmount }}</td>
                        </tr>
                        <tr>
                            <th> Amounts In Words</th>
                            <td> {{ totalAmountInWords}} Rupees Only</td>
                        </tr>
                        <tr>
                            <th> Remiters Name</th>
                            <td> {{ challanDetails?.remitterName }}</td>
                        </tr>
                        <tr>
                            <th> Remitter A/C Number</th>
                            <td> {{ challanDetails?.remitterAccountNo }}</td>
                        </tr>
                        <tr>
                            <th> Remitter IFSC Code</th>
                            <td> {{ challanDetails?.remitterIFSCCode }}</td>
                        </tr>
                        <tr>
                            <th> Remitter Mobile Number</th>
                            <td>{{ challanDetails?.remitterMobNo }} </td>
                        </tr>
                        <tr>
                            <th> DA / WEDS</th>
                            <td> {{ challanDetails?.createdBy }}</td>
                        </tr>
                        <tr>
                            <th> GSWS Code</th>
                            <td> {{ challanDetails?.gswsCode }}</td>
                        </tr>
                        <tr>
                            <th> Status</th>
                            <td *ngIf="this.f.payment_mode.value !== 'IPB_CHALLAN'"> {{ challanDetails?.challanStatus }} </td>
                            <td *ngIf="this.f.payment_mode.value === 'IPB_CHALLAN'"> Pending </td>

                        </tr>
                        <tr>
                            <th> Date of Challan Generated</th>
                            <!--  <td> {{ challanDetails?.challanDate | date: 'MMM d, y' }} </td> -->
                            <td> {{ challanDetails?.challanDate | date: 'dd/MM/yyyy h:mm:ss a' }} </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <p> <b>NOTE :</b> Kindly deposite amount with the above Bank Account and IFSC code
                                    through
                                    NEFT(Online Transaction).</p>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="printData('__printingFrame')">Print
                    Challan</button>
                <!-- <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button> -->
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.close('Save click')">Close</button>
            </div>
        </ng-template> 
        <!-- Generate challan Popup end -->


        <!-- Transaction Popup -->
        <ng-template #showTransaction let-modal class="modalCls">

            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Transaction Details</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span >×</span>
                </button>
            </div>

            <div class="modal-body" id="transPoup">

                <div class="table-responsive tableFixHead">
                    <table class="tableCls">
                        <thead>
                            <tr>
                                <th>Serial No</th>
                                <th>Transaction ID</th>
                                <th>Service Name</th>
                                <th>Transaction Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            <!--   
                                 <tr *ngFor="let item of trasactionData; let i=index;">
                             <td>{{(i+1)}}</td>
                                <td>{{ item?.transactionId }}</td>
                                <td>{{ item?.serviceName }}</td>
                                <td>{{ item?.transactionDate | date: 'dd/MM/yyyy h:mm:ss a' }}</td>
                                <td>{{ item?.transactionAmount }}</td>
                                </tr>
                            -->

                                <tr *ngFor="let item of trasactionData; let i=index;">
                                    <td>{{(i+1)}}</td>
                                    <td *ngIf="item.transactionFrom === 'NEW'">{{ item?.transactionId }}</td>
                                    <td *ngIf="item.transactionFrom === 'OLD'">{{ item?.transactionId }} ({{ item?.dept_App_No }})</td>
                                    <td>{{ item?.serviceName }}</td>
                                    <td *ngIf="item.serviceName === 'OTS Payments'">{{item.transactionDate }}</td>
                                    <td *ngIf="item.serviceName !== 'OTS Payments'">{{ item?.transactionDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                    <td>{{ item?.transactionAmount }}</td>
                                </tr>

                            
                        </tbody>

                    </table>

                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="printData('transPoup')">Print</button>
                <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
            </div>
        </ng-template>
        <!-- Transaction Popup end -->

    </div>
</div>

<div [className]="isActive ? 'imgLoader' : 'imgLoaderHide'">
    <img src="../../assets/loader.gif">
</div>
<paytm-checkout *ngIf="isCheckoutVisible"></paytm-checkout>