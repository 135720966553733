<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Revenue Department <span class="activePage">/ Challan Transaction History</span>
        </div>
    </div>


    <div class="mainPageContainer mr-3"  *ngIf="newChallanFunction">

        <h6 class="mb-3 text-center ptCls"><b>Challan Transaction History </b> </h6>
        <div class="row">
            <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
               
                <div class="table-responsive tableFixHead" *ngIf="listOfTrasaction && listOfTrasaction.length > 0">
                    <table class="tableCls">
                        <thead>
                            <tr>
                                <th>Serial<br>No</th>
                                <th>Challan Number</th>
                                <th>Number Of<br>Transactions</th>
                                <th>Challan Status</th>
                                <th>Challan Date</th>
                                <th>Payment<br> Mode</th>
                                <th>Transaction<br>Amount</th>
                            </tr>
                        </thead>

                        <tbody *ngIf="listOfTrasaction && listOfTrasaction.length !==0">

                            <tr *ngFor="let item of listOfTrasaction; let i=index;">
                              
                                <td class="text-right">{{(i+1)}}</td>
                              
                                <td class="clsTd" (click)="transactionPopup(showTransaction, item?.challanId , item?.challanStatus);">{{ item?.challanId }}</td>

                                <td class="text-center clsTd" (click)="transactionPopup(showTransaction, item?.challanId, item?.challanStatus);">{{ item?.noOfTransactions }}</td>
                                
                                <td class="text-left">{{ item?.challanStatus }}</td>

                                <!-- <td class="text-center">{{ item?.challanDate | date: 'dd/MM/yyyy' }}</td> -->
                                <td class="text-left">{{ item?.challanDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                <td class="text-left" *ngIf="item?.paymentGateway=='BILLDESK_UPI' || item?.paymentGateway=='PAYTM_CHALLAN'">UPI</td>
                                <td class="text-left" *ngIf="item?.paymentGateway=='ICICI_CHALLAN'">Cash</td>
                                <td class="text-right">{{ item?.challanAmount }}</td>
                            </tr>
                        </tbody>
                    </table>
                  
                    
                </div>
              
                <div class="text-center btnHide selectError clsSize  mb-3 mt-3"
                    *ngIf="listOfTrasaction && listOfTrasaction.length ===0">
                    <b> Challan Transactions Not Available.. </b>
                </div>
            </div>
            <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12 btnHide">

                <div class="row mb-3 mt-3">
                    <div class="btnHolder text-center col-12 text-center">
                        <button class="btn btnB btn-primary mx-auto" (click)="exportexcel()" >Download Excel</button> 
                        
                        <button class="btn btnB btn-primary mx-auto"  (click)="printReceipt('_printData');" >Print</button> 
                        
                        <button class="btn btn-primary mx-auto btnB" routerLink="/view-challan" routerLinkActive='active'>Back</button> 
       
                    </div>
                   
                </div>

            </div>
        </div>
  
    </div>

    
<!-- new  -->

    <div class="mainPageContainer mr-3"  *ngIf="!newChallanFunction">
        <h6 class="mb-3 text-center"><b>Challan History </b></h6>
        <form [formGroup]="ViewTransactions">

            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-3 btnHide">Search Challans With Date Range</div>
            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 btnHide">
                    <label class="labelCls">From Date :
                        <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="d" ngbDatepicker
                            [minDate]="FromminDate" [maxDate]="FrommaxDate" #d="ngbDatepicker" required
                            formControlName="periodFrom"
                            [ngClass]="{ 'is-invalid': submitted && f.periodFrom.errors }" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.periodFrom.errors" class="selectError">
                        <div *ngIf="
                          f.periodFrom.errors?.required || f.periodFrom.invalid
                        ">
                            Please enter valid period From
                        </div>
                    </div>
                </div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 btnHide">
                    <label class="labelCls">To Date :
                        <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="e" ngbDatepicker
                            [minDate]="TominDate" [maxDate]="TomaxDate" #e="ngbDatepicker" required
                            formControlName="periodTo" [ngClass]="{ 'is-invalid': submitted && f.periodTo.errors }" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="e.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.periodTo.errors" class="selectError">
                        <div *ngIf="
                          f.periodTo.errors?.required || f.periodTo.invalid
                        ">
                            Please enter valid period To
                        </div>
                    </div>
                </div>

             




                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 btnHide">

                    <label class="labelCls">Payment Mode:</label>
                    <select class="selectCls" formControlName="department_name" required
                        [ngClass]="{ 'is-invalid':submitted && (f.department_name.errors || f.department_name.value === 'Select') }">
                        <option value="all">All</option>
                        <option value="ICICI_CHALLAN">ICICI Challan Payment</option>
                        <option *ngIf="!challanTypeActive" value="BILLDESK_UPI">BILL DESK UPI Payment</option>
                        <option value="PAYTM_CHALLAN" *ngIf="!challanTypeActive">PAYTM UPI Payment</option>
                        <option value="IPB_CHALLAN" *ngIf="(isProd && isLoginPresent) || !isProd">India Post Payment Bank</option>
                    </select>
                    <div *ngIf="submitted && (f.department_name.errors || f.department_name.value === 'Select')"
                        class="selectError">
                        <div *ngIf="f.department_name.errors?.required || f.department_name.value === 'Select'">Please
                            select Payment Mode</div>
                    </div>
                </div>

            </div>


<!-- 
            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-3">OR Search Criteria 2</div>

            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">User Name : </label>
                </div>
                <div class="col col-lg-3 col-md-3 vcol-sm-12 col-xs-12">
                    <label class="labelCls">{{userInfo.displayName}} </label>
                </div>
            </div>

            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Challan Id: </label>
                </div>
                <div class="col col-lg-3 col-md-3 vcol-sm-12 col-xs-12">
                    <input type="text" name="transactionID" class="inputCls" formControlName="transactionID">
                    <div *ngIf="submitted && f.transactionID.errors" class="selectError">
                        <div *ngIf="f.transactionID.errors?.required || f.transactionID.value === 'Select'">Please
                            Enter transaction id</div>
                    </div>
                </div>

            </div> -->

            <!-- <hr class="hrCls mt-4"> -->
            <div class="row mb-3 btnHide">
                <div class="btnHolder text-center col-12">
                    <button class="btn btn-primary mx-auto d-block" (click)="submit('pagination')">View Challan</button>
                </div>
            </div>
        </form>

        <div *ngIf="showDetails">
            <hr class="hrCls mt-4 btnHide">
            <div class="subHeadingCls mb-4 ol col-lg-12 col-md-12 btnHide">Challan List
               <!--  <button class="btn btnB btn-primary pull-right btnHide tabMargin" >Print </button>  -->
               <!--  <input type="text" placeholder="Search Transaction" class="searchTrans pull-right btnHide" [(ngModel)]="transactionFilterID" (keyup)="searchTransaction()"> -->
               
            </div>

          

            <div class="row" >
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
     
                        <div class="table-responsive" *ngIf="items && items.length > 0">
         
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th>Serial<br>No</th>
                                    <th>Challan Number</th>
                                    <th>Number Of<br>Transactions</th>
                                    <th>Challan Status</th>
                                    <th>Challan Date</th>
                                    <th>Payment<br> Mode</th>
                                    <th>Transaction<br>Amount</th>
                                    <th class="btnHide">Receipt</th>
                                </tr>
                            </thead>
    
                            <tbody *ngIf="items && items.length !==0">
                                <tr *ngFor="let item of items; let i = index;">
                                    <td>{{ ((page -1) * pageSize) + (i + 1)}} </td>
                                 
                                    <td class="clsTd" *ngIf="item?.paymentGateway=='PAYTM_CHALLAN' || item?.paymentGateway=='BILLDESK_UPI' || item?.paymentGateway=='IPB_CHALLAN'" (click)="transactionPopup(showTransaction, item?.challanId , item?.challanStatus);">{{ item?.challanId }}</td>
                                   
                                    <td class="clsTd" *ngIf="item?.paymentGateway=='ICICI_CHALLAN'" (click)="transactionPopup(showTransaction, item?.challanId , item?.challanStatus);">{{ item?.beneficiaryAccountNo }}</td>
                                  
                                    <td class="text-center clsTd" (click)="transactionPopup(showTransaction, item?.challanId, item?.challanStatus);">{{ item?.noOfTransactions }}</td>
                                 
                                    <td class="text-left">{{ item?.challanStatus }}</td>
                                
                                    <td class="text-left">{{ item?.challanDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                
                                    <td class="text-left" *ngIf="item?.paymentGateway=='PAYTM_CHALLAN' || item?.paymentGateway=='BILLDESK_UPI'">UPI</td>
                              
                                    <td class="text-left" *ngIf="item?.paymentGateway=='ICICI_CHALLAN' || item?.paymentGateway=='IPB_CHALLAN'">Cash</td>
                                    <td class="text-right">{{ item?.challanAmount }}</td>

                                    <td class="clsTd text-center btnHide" *ngIf="item?.paymentGateway=='PAYTM_CHALLAN' || item?.paymentGateway=='BILLDESK_UPI'"> - </td>
                                    <td class="clsTd text-center btnHide" (click)="showReceipt(showReceiptModal, item?.challanId);" *ngIf="item?.paymentGateway=='ICICI_CHALLAN' || item?.paymentGateway=='IPB_CHALLAN'">Print Challan</td>
                            
                                </tr>
                            </tbody>
                         
                        </table>
                    </div>


                    <div class="mt-4 btnHide">
                        <div class="float-left" *ngIf="totalPageCount > 0 && items.length >0">
                            <label>Requests per page: </label>
                            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize" (change)="submit('pagination')">
                                <option  value="10">10</option>
                                <option  value="20">20</option>
                                <option  value="30">30</option>
                                <option  value="40">40</option>
                                <option  value="50">50</option>
                            </select> 
                        </div>
                        <ngb-pagination class="d-flex justify-content-end"   *ngIf="totalPageCount > 0 && items.length >0" [collectionSize]="totalPageCount"[(page)]="page" [pageSize]="pageSize"
                            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="submit('pagination')">
                        </ngb-pagination>
                    </div>


                     <div class="text-center selectError clsSize"
                    *ngIf="items.length ===0">

                    No records available...
                </div> 

                <div class="text-center col col-lg-12 col-md-12 col-sm-12 col-xs-12 btnHide" *ngIf="items.length > 0">

                        <div class="row mb-3 mt-3">
                            <div class="btnHolder text-center col-12 text-center">
                                <button class="btn btnB btn-primary mx-auto" (click)="submit('withOutPagination', 'downloadExcel')" >Download Excel</button> 
                                
                                <button class="btn btnB btn-primary mx-auto"  (click)="submit('withOutPagination', '_printData')" >Print</button> 
                                
                                <button *ngIf="challanTypeActive" class="btn btn-primary mx-auto btnB"(click)="backTochallan('ots')" routerLinkActive='active'>Back</button> 
                                <button *ngIf="!challanTypeActive" class="btn btn-primary mx-auto btnB"(click)="backTochallan('gsws')" routerLinkActive='active'>Back</button> 
               
                            </div>
                           
                        </div>
        
                    </div>

                

                </div>
            </div>
      
      
        </div>
    </div>

 <!-- new  -->
           

 <!-- download excel and print table -->
 <div class="row" id="_printData" class="hideTable">
    <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">

            <div class="table-responsive">

            <table class="tableCls" id="excel-table">
                <thead>
                    <tr>
                        <th>Serial<br>No</th>
                        <th>Challan Number</th>
                        <th>Number Of<br>Transactions</th>
                        <th>Challan Status</th>
                        <th>Challan Date</th>
                        <th>Payment<br> Mode</th>
                        <th>Transaction<br>Amount</th>
                        <!-- <th class="btnHide">Receipt</th> -->
                    </tr>
                </thead>

                <tbody *ngIf="itemswithoutPagination && itemswithoutPagination.length >0">
                    <tr *ngFor="let item of itemswithoutPagination; let i = index;">
                        <td>{{i + 1}} </td>
                     
                        <td class="clsTd" *ngIf="item?.paymentGateway=='PAYTM_CHALLAN' || item?.paymentGateway=='BILLDESK_UPI' || item?.paymentGateway=='IPB_CHALLAN'">{{ item?.challanId }}</td>
                       
                        <td class="clsTd" *ngIf="item?.paymentGateway=='ICICI_CHALLAN'">{{ item?.beneficiaryAccountNo }}</td>
                      
                        <td class="text-center clsTd">{{ item?.noOfTransactions }}</td>
                     
                        <td class="text-left">{{ item?.challanStatus }}</td>
                    
                        <td class="text-left">{{ item?.challanDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                    
                        <td class="text-left" *ngIf="item?.paymentGateway=='PAYTM_CHALLAN' || item?.paymentGateway=='BILLDESK_UPI'">UPI</td>
                  
                        <td class="text-left" *ngIf="item?.paymentGateway=='ICICI_CHALLAN' || item?.paymentGateway=='IPB_CHALLAN'">Cash</td>
                        <td class="text-right">{{ item?.challanAmount }}</td>

                        <!-- <td class="clsTd text-center btnHide" *ngIf="item?.paymentGateway=='BILLDESK_UPI'"> - </td> -->
                        <!-- <td class="clsTd text-center btnHide" (click)="showReceipt(showReceiptModal, item?.challanId);" *ngIf="item?.paymentGateway=='ICICI_CHALLAN' || item?.paymentGateway=='IPB_CHALLAN'">Print Challan</td> -->
                
                    </tr>
                </tbody>
                <tbody *ngIf="itemswithoutPagination.length === 0">
                    <tr>
                        <td colspan="7" class="text-center">No records available...</td>
                    </tr>

                </tbody>
            </table>
        </div>


        
<!-- 
        <div class="text-center selectError clsSize"
        *ngIf="itemswithoutPagination.length ===0">

        No records available...
    </div> -->

  
    

    </div>
</div>

<!-- Transaction Popup -->
    <ng-template #showTransaction let-modal class="modalCls btnHide">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Transaction Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span >×</span>
        </button>
    </div>

    <div class="modal-body"  id="transPoup">

        <div class="table-responsive tableFixHead">
            <table class="tableCls">
                <thead>
                    <tr>
                        <th>Serial No</th>
                        <th>Transaction ID</th>
                        <th>Service Name</th>
                        <th>Transaction Date</th>
                        <th>Amount</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let item of trasactionData; let i=index;">
                        <td>{{(i+1)}}</td>
                        <td *ngIf="item.transactionFrom === 'NEW'">{{ item?.transactionId }}</td>
                        <td *ngIf="item.transactionFrom === 'OLD'">{{ item?.transactionId }} ({{ item?.dept_App_No }})</td>
                        <td>{{ item?.serviceName }}</td>
                        <!--  <td>{{ item?.transactionDate | date: 'MMM d, y' }}</td> -->
                        <td  *ngIf="challanTypeActive">{{ item?.transactionDate }}</td>
                        <td  *ngIf="!challanTypeActive">{{ item?.transactionDate | date: 'dd/MM/yyyy h:mm:ss a' }}</td>
                        <td>{{ item?.transactionAmount }}</td>
                    </tr>
                </tbody>

            </table>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="printReceipt('transPoup')">Print</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
    </div>
    </ng-template>
<!-- Transaction Popup end -->

 <!-- Generate challan Popup  -->
 <ng-template #showReceiptModal let-modal class="modalCls">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Challan Data</h4>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="modal.close('Save click')">
            <span >×</span></button>
    </div>

    <div id="__printingFrame">

        <div class="modal-body">

            <div class="text-center clsQRCode">
                <ngx-qrcode [elementType]="elementType" [errorCorrectionLevel]="correctionLevel"
                    [value]="barcodeValue" qrc-class="aclass" alt="QR Code" [width]=200>
                </ngx-qrcode>
                
                <br>
                <h6>Challan Reference From</h6>
                <h5>GOVERNMENT OF ANDHRA PRADESH</h5>
                <hr>
            </div>

            <table class="table tableModalData">

                <tr>
                    <th> Beneficiary Name</th>
                    <td> {{ challanData[0]?.beneficiaryName  }}</td>
                </tr>

                <tr>
                    <th> Beneficiary Account Number</th>
                    <td>  {{ challanData[0]?.beneficiaryAccountNo }} </td>
                </tr>
                <tr>
                    <th> Beneficiary IFSC Code</th>
                    <td>  {{ challanData[0]?.ifscCode }} </td>
                </tr>
            
                <tr>
                    <th> Bank Name and Branch Name</th>
                    <td>  {{ challanData[0]?.bankName }} </td>
                </tr>
                <tr>
                    <th> Amount to be Remitted</th>
                    <td>  {{ challanData[0]?.challanAmount }} </td>
                </tr>
                <tr>
                    <th> Amounts In Words</th>
                    <td> {{ anmtInWords }}  Rupees Only</td>
                </tr>
                <tr>
                    <th> Remiters Name</th>
                    <td> {{ challanData[0]?.remitterName }}  </td>
                </tr>
                <tr>
                    <th> Remitter A/C Number</th>
                    <td> {{ challanData[0]?.remitterAccountNo }} </td>
                </tr>
                <tr>
                    <th> Remitter IFSC Code</th>
                    <td> {{ challanData[0]?.remitterIFSCCode }}  </td>
                </tr>
                <tr>
                    <th> Remitter Mobile Number</th>
                    <td>  {{ challanData[0]?.remitterMobNo }} </td>
                </tr>
                <tr>
                    <th> DA / WEDS</th>
                    <td> {{ challanData[0]?.createdBy }} </td>
                </tr>
                <tr>
                    <th> GSWS Code</th>
                    <td> {{ challanData[0]?.gswsCode }} </td>
                </tr>
                <tr>
                    <th> Status</th>
                    <td> {{ challanData[0]?.challanStatus }}  </td>
                </tr>
                <tr>
                    <th> Date of Challan Generated</th>
                   <td> {{ challanData[0]?.challanDate  | date: 'dd/MM/yyyy h:mm:ss a'  }} </td>
                </tr>

                <tr>
                    <td colspan="2">
                        <p> <b>NOTE :</b> Kindly deposite amount with the above Bank Account and IFSC code
                            through
                            NEFT(Online Transaction).</p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="printReceipt('__printingFrame')">Print Challan</button>
       
   
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')" data-dismiss="modal">Close</button>
    </div>
</ng-template>
<!-- Generate challan Popup end -->


</div>