import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonConstants } from 'src/constants/common-constants.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/shared/common.service';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';

@Component({
  selector: 'app-apseva-feedback',
  templateUrl: './apseva-feedback.component.html',
  styleUrls: ['./apseva-feedback.component.css']
})
export class ApsevaFeedbackComponent implements OnInit {
  feedbackForm: UntypedFormGroup;
  submitted: boolean = false;
  getDetails: boolean = false;
  detailsFetched: boolean = false;
  commonConstants: any = CommonConstants;
  questions: any;
  userData: any;
  feedbackImage: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.feedbackForm = this.formBuilder.group({
      applicationNo: ['', [Validators.required]],
      experienceRating: ['', [Validators.required]],
      completeInformation: ['', [Validators.required]],
      staffBehaviour: ['', [Validators.required]],
      anyExtraAmountPaid: ['', [Validators.required]],
      anySuggestions: ['', [Validators.pattern(this.commonConstants.validTeluguPattern)]]
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.feedbackForm.controls;
  }

  getApplicationDetails() {
    this.getDetails = true;
    this.userData = {};
    if (this.f.applicationNo.value) {
      let postData = {
        applicationId: this.f.applicationNo.value.trim()
      }
      this.commonService.postRequest(this.commonConstants.fetchRequisitionSurveyData, postData, true).subscribe({
        next: (responseData: any) => {
          if (responseData) {
            console.log(responseData);
            if (responseData && responseData.result.constructor === Object) {
              this.detailsFetched = true;
              this.userData = responseData.result;
            } else if (responseData && responseData.result == 'Feedback Already Submitted') {
              let params = {
                status: 'feedback-submitted'
              }
              this.router.navigate(['/feedback-status'], { queryParams: params })
            } else if (responseData && responseData.result == 'Not a Valid Application No') {
              this.detailsFetched = false;
              this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
            }
          }
        },
        error: (error) => {
          if (error) {
            let params = {
              status: 'fail'
            }
            this.router.navigate(['/feedback-status'], { queryParams: params });
          }
          console.log('client side', error);
        }
      });
    }
  }

  ratingChanged() {
    this.setFeedbackImage(this.f.experienceRating?.value);
  }

  hoverOnrating(event: any) {
    this.setFeedbackImage(event);
  }

  leaveHover() {
    this.setFeedbackImage(this.f.experienceRating?.value);
  }

  setFeedbackImage(value: any) {
    if (value == 1) {
      this.feedbackImage = 'fas fa-frown rating-1 ml-4';
    } else if (value == 2) {
      this.feedbackImage = 'fas fa-frown-open rating-2 ml-4';
    } else if (value == 3) {
      this.feedbackImage = 'fas fa-meh rating-3 ml-4';
    } else if (value == 4) {
      this.feedbackImage = 'fas fa-smile rating-4 ml-4';
    } else if (value == 5) {
      this.feedbackImage = 'fas fa-smile-beam rating-5 ml-4';
    } else {
      this.feedbackImage = '';
    }
  }

  submit(): any {
    this.submitted = true;
    if (this.userData === undefined) {
      return false;
    } else if (this.userData?.citizenName == '' || this.userData?.serviceName == '' || !this.feedbackForm.valid) {
      return false;
    } else {
      let payload: any = {
        json: {
          uid: this.userData.citizen_Uid,
          requisitionId: this.f.applicationNo.value,
          citizenName: this.userData.citizenName,
          fatherHusbandName: this.userData.fatherHusbandName,
          serviceName: this.userData.serviceName,
          secretariatCode: this.userData.secretariatCode,
          questionsData: [{
            QUESTION_NAME: "పైన తెలిపిన సేవ పొందడంలో మీ అనుభవాన్ని మీరు ఎలా రేటింగ్ చేస్తారు. (1,2,3,4,5) స్టార్ రేటింగ్ 5 అత్యధికంగా ఉంటుంది.",
            QUESTION_CODE: "experienceRating",
            QUESTION_VALUE: this.f.experienceRating.value.toString()
          }, {
            QUESTION_NAME: "సచివాలయం సిబ్బంది మీకు సేవకి సంబందించిన పూర్తి సమాచారాన్ని అందించారా?",
            QUESTION_CODE: "completeInformation",
            QUESTION_VALUE: this.f.completeInformation.value
          }, {
            QUESTION_NAME: "సచివాలయం సిబ్బంది మీతో మర్యాదపూర్వకంగా మెలిగారా?",
            QUESTION_CODE: "staffBehaviour",
            QUESTION_VALUE: this.f.staffBehaviour.value
          }, {
            QUESTION_NAME: "రసీదులో పేర్కొన్న సేవ రుసుముకి మించి అదనంగా డబ్బులు ఏదైనా వసూలు చేయబడిందా?",
            QUESTION_CODE: "anyExtraAmountPaid",
            QUESTION_VALUE: this.f.anyExtraAmountPaid.value
          }, {
            QUESTION_NAME: "సేవలు మరింత మెరుగు పరుచుకోవడానికి ఏదైనా సలహాలు / సూచనలు ఉంటే ఇవ్వగలరు.",
            QUESTION_CODE: "anySuggestions",
            QUESTION_VALUE: this.f.anySuggestions.value
          }]
        }
      }
      console.log(payload);
      this.commonService.postRequest(this.commonConstants.insertRequisitionSurveyData, payload, true).subscribe({
        next: (responseData: any) => {
          console.log(responseData);
          if (responseData && responseData?.result[0].remarks == 'Success') {
            let params = {
              status: 'success'
            }
            this.router.navigate(['/feedback-status'], {
              queryParams: params
            });
          } else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = responseData?.result[0].remarks;
            modalRef.result.then((result: any) => {
              if (result) {
                this.modalService.dismissAll();
                this.submitted = false;
                this.feedbackForm.reset();
              }
            }).catch((res) => {});
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
  }

}
