<ng-container> 
    <div class="certificateDetails mx-3" style="margin-top: 10% !important;">
      <div id="__printingFrame">
        <div col="" class="text-center">
      <h6 class="mb-3 title">Application Preview</h6>
    </div>
        <form [formGroup]="form">
           <!--  <app-common-form-view [basicInfo]="basicInfo?.applicantInfo" [presentAddr]="presentAddr" [permanentAddr]="permanentAddr" *ngIf="basicInfo && basicInfo.length !==0"></app-common-form-view>
            <app-integrated-certificate-view *ngIf="certificateName === 'IntegratedCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-integrated-certificate-view>
            <app-obc-certificate-view *ngIf="certificateName === 'OBCMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-obc-certificate-view>
            <app-income-certificate-view *ngIf="certificateName === 'IncomeCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList"
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-income-certificate-view>
            <app-family-member-certificate-view *ngIf="certificateName === 'FamilyMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-family-member-certificate-view>
            <app-possession-certificate-view *ngIf="certificateName === 'PossessionCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-possession-certificate-view>
            <app-income-ews-certificate-view *ngIf="certificateName === 'IncomeAssetCertificateForEWS'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-income-ews-certificate-view>
            <app-no-earning-certificate-view *ngIf="certificateName === 'NoEarningMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-no-earning-certificate-view>
            <app-late-reg-certificate-view *ngIf="certificateName === 'BirthDeathCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-late-reg-certificate-view>
            <app-issue-small-farmer-certificate-view *ngIf="certificateName === 'SnMFarmerCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-issue-small-farmer-certificate-view>
            <app-f-line-certificate-view *ngIf="certificateName === 'FlineApplication'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-f-line-certificate-view>
            <app-agriculture-income-certificate-view *ngIf="certificateName === 'AgricultureIncomeCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-agriculture-income-certificate-view>
            <app-encumbrance-certificate-view *ngIf="certificateName === 'EncumbranceCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-encumbrance-certificate-view>
            <app-fmb-copy-view *ngIf="certificateName === 'FMBCopy'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-fmb-copy-view>
            
            <app-change-of-name-certificate-view *ngIf="certificateName === 'ChangeOfNameCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-change-of-name-certificate-view>
            <app-extract-of-house-patta-view *ngIf="certificateName === 'HouseSiteDFormPatta'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-extract-of-house-patta-view>
            <app-extractofnoc-explosiveorpetroleum-cerificate *ngIf="certificateName === 'ExtractOfNOCUnderExplosiveOrPetroleum'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-extractofnoc-explosiveorpetroleum-cerificate>
            
            <app-permission-of-digging-of-bore-well-for-agricultural-purpose-view *ngIf="certificateName === 'PFDAgriculturalwellOrDrinkingwater'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-permission-of-digging-of-bore-well-for-agricultural-purpose-view>
            <app-no-property-certificate-view *ngIf="certificateName === 'NoPropertyCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-no-property-certificate-view>
            <app-renewal-cinema-licence-view *ngIf="certificateName === 'RenewalCinemaLicence'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-renewal-cinema-licence-view>
            
             <app-issue-of-occupancy-rights-certificates-for-inam-lands-view *ngIf="certificateName === 'OccupancyRightsCertificateForInamLands'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-issue-of-occupancy-rights-certificates-for-inam-lands-view>
            <app-issueofnoc-forstoringofpetroleumproducts-certificate-view *ngIf="certificateName === 'IssueOfNOCToStoringPetroleumProducts'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-issueofnoc-forstoringofpetroleumproducts-certificate-view>
            <app-cracker-licence-application *ngIf="certificateName === 'ExplosiveMaterialLicence'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-cracker-licence-application> 
            <app-addition-of-survey-no-view *ngIf="certificateName === 'InsertSurveyNumInAdangal'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-addition-of-survey-no-view> -->
            <app-land-assignment-view-new *ngIf="certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList"
           (view)="viewFile($event)" (download)="downloadFile($event)">
          </app-land-assignment-view-new>
            <app-common-form-view [basicInfo]="basicInfo?.applicantInfo" [commonFormAddedFields]="commonFormAddedFields" [presentAddr]="presentAddr" [permanentAddr]="permanentAddr" *ngIf="basicInfo && basicInfo.length !==0"></app-common-form-view>
            <app-integrated-certificate-view *ngIf="certificateName === 'IntegratedCertificate' || certificateName === 'IntegratedCertificate-RDO' || certificateName === 'IntegratedCertificateForStudents'|| certificateName === 'IntegratedCertificateForStudents-RDO' || certificateName === 'IntegratedCertificate-SuoMoto'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-integrated-certificate-view>
            <app-obc-certificate-view *ngIf="certificateName === 'OBCMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-obc-certificate-view>
            <app-income-certificate-view *ngIf="certificateName === 'IncomeCertificate' || certificateName === 'IncomeCertificateForStudents'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList"
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-income-certificate-view>
            <app-family-member-certificate-view *ngIf="certificateName === 'FamilyMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-family-member-certificate-view>
            <app-possession-certificate-view *ngIf="certificateName === 'PossessionCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-possession-certificate-view>
            <app-income-ews-certificate-view *ngIf="certificateName === 'IncomeAssetCertificateForEWS'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (viewImgFile)="viewImgFile($event)" (download)="downloadFile($event)"></app-income-ews-certificate-view>
            <app-no-earning-certificate-view *ngIf="certificateName === 'NoEarningMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-no-earning-certificate-view>
            <app-late-reg-certificate-view *ngIf="certificateName === 'BirthDeathCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-late-reg-certificate-view>
            <app-issue-small-farmer-certificate-view *ngIf="certificateName === 'SnMFarmerCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-issue-small-farmer-certificate-view>
            <app-f-line-certificate-view *ngIf="certificateName === 'FlineApplication'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-f-line-certificate-view>
            <app-agriculture-income-certificate-view *ngIf="certificateName === 'AgricultureIncomeCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-agriculture-income-certificate-view>
           <app-land-assignment-to-soldiers-view *ngIf="certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList"
           (view)="viewFile($event)" (download)="downloadFile($event)">
          </app-land-assignment-to-soldiers-view>
            <app-encumbrance-certificate-view *ngIf="certificateName === 'EncumbranceCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-encumbrance-certificate-view>
            <app-fmb-copy-view *ngIf="certificateName === 'FMBCopy'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-fmb-copy-view>

            <app-social-audit-grievance-view *ngIf="certificateName === 'GrievanceCaste'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-social-audit-grievance-view>

            <app-social-audit-grievance-view *ngIf="certificateName === 'GrievanceOthers'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-social-audit-grievance-view>
           
            <app-change-of-name-certificate-view *ngIf="certificateName === 'ChangeOfNameCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-change-of-name-certificate-view>
            <app-extract-of-house-patta-view *ngIf="certificateName === 'HouseSiteDFormPatta'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-extract-of-house-patta-view>
            <app-extractofnoc-explosiveorpetroleum-cerificate *ngIf="certificateName === 'ExtractOfNOCUnderExplosiveOrPetroleum'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-extractofnoc-explosiveorpetroleum-cerificate>
            
            <app-permission-of-digging-of-bore-well-for-agricultural-purpose-view *ngIf="certificateName === 'PFDAgriculturalwellOrDrinkingwater'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-permission-of-digging-of-bore-well-for-agricultural-purpose-view>
            <app-no-property-certificate-view *ngIf="certificateName === 'NoPropertyCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-no-property-certificate-view>
            <app-renewal-cinema-licence-view *ngIf="certificateName === 'RenewalCinemaLicence'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-renewal-cinema-licence-view>
            
             <app-issue-of-occupancy-rights-certificates-for-inam-lands-view *ngIf="certificateName === 'OccupancyRightsCertificateForInamLands'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-issue-of-occupancy-rights-certificates-for-inam-lands-view>
            <app-issueofnoc-forstoringofpetroleumproducts-certificate-view *ngIf="certificateName === 'IssueOfNOCToStoringPetroleumProducts'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-issueofnoc-forstoringofpetroleumproducts-certificate-view>
            <app-cracker-licence-application *ngIf="certificateName === 'ExplosiveMaterialLicence'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">  
            </app-cracker-licence-application> 
            <app-addition-of-survey-no-view *ngIf="certificateName === 'InsertSurveyNumInAdangal'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-addition-of-survey-no-view>
            <app-old-adangal-view *ngIf="certificateName === 'ManualAdangalCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-old-adangal-view>
            <app-land-conversion-view *ngIf="certificateName === 'LandConversionNewCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-land-conversion-view>
            <app-regularization-of-sadabainamas-view *ngIf="certificateName === 'SadaBainama'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-regularization-of-sadabainamas-view>
            <app-rofr-mutation-for-transactions-succession-view *ngIf="certificateName === 'ROFRMutationForTransactionsSuccession'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)" (viewImg)="viewImgFile($event)">
            </app-rofr-mutation-for-transactions-succession-view>

            <app-rectification-resurvey-view *ngIf="certificateName === 'RectificationOfResurveyedRecords'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-rectification-resurvey-view>

            <app-ap-dotted-lands-view *ngIf="certificateName === 'ClaimsUnderDottedLands'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-ap-dotted-lands-view>
            <app-modifications-in22-alist-view *ngIf="certificateName === '22AModification'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-modifications-in22-alist-view>
            <app-housing-site *ngIf="certificateName === 'HousingSiteApplication'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-housing-site>
            <app-housing-site *ngIf="certificateName === 'HousingSiteApplication-URBAN'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-housing-site>
            <app-ror1-bview *ngIf="certificateName === 'ROR1BCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-ror1-bview>
            <app-computerised-adangal-view *ngIf="certificateName === 'ComputerizedAdangalCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-computerised-adangal-view>
            <app-pattadar-mobileno-seeding-view *ngIf="certificateName === 'PattadarMobileNoSeed'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-pattadar-mobileno-seeding-view>
            <app-reissuance-view *ngIf="certificateName === 'ReIssuanceCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-reissuance-view>
            <app-titledeed-cum-passbook-view *ngIf="certificateName === 'TitleDeedCumPassbook'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)" (viewImg)="viewImgFile($event)">
            </app-titledeed-cum-passbook-view>
            <app-patta-subdivision-auto-mutation-view *ngIf="certificateName === 'PattaSubdivisionAutoMutation'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-patta-subdivision-auto-mutation-view>
            <app-printingof-titledeed-view *ngIf="certificateName === 'PrintingOfTitleDeedCumPassbook'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-printingof-titledeed-view>
            <app-rectificationof-entries-view *ngIf="certificateName === 'RoRFormRectification'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-rectificationof-entries-view>
            <app-epassbook-view *ngIf="certificateName === 'ePassbookReplacementPPB'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-epassbook-view>

            <app-mutationtitledeed-view *ngIf="certificateName === 'MutationTitleDeedPPB'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" [reqId]="requisitionID" 
            (view)="viewFile($event)" (viewImg)="viewImgFile($event)" (download)="downloadFile($event)">
            </app-mutationtitledeed-view>

            <app-mutation-corrections-resurvey-view *ngIf="certificateName === 'ResurveyofMutationforCorrection' || certificateName === 'ResurveyofMutationforCorrectionExtent'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)" (viewImg)="viewImgFile($event)">
          </app-mutation-corrections-resurvey-view> 
          <app-mutation-transaction-resurvey-view *ngIf="certificateName === 'mutationTransactionsWithLPMNumbers'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)" (viewImg)="viewImgFile($event)">
          </app-mutation-transaction-resurvey-view> 
          <app-issuance-of-passbooks-lpm-view *ngIf="certificateName === 'IssuanceOfBhuHakkuPatram'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
          (view)="viewFile($event)" (download)="downloadFile($event)" (viewImg)="viewImgFile($event)">
          </app-issuance-of-passbooks-lpm-view>
            <app-pattadar-aadhar-seed-lpm-record-view *ngIf="certificateName === 'PattadarAadharSeedingbyLPnumber'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)" (viewImg)="viewImgFile($event)"> 
              
            </app-pattadar-aadhar-seed-lpm-record-view>
            <app-household-split-view *ngIf="certificateName === 'HouseholdSplit' || certificateName === 'HouseholdSplit@1' || certificateName === 'HouseholdSplit@2'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-household-split-view>

            <app-lamination-view *ngIf="certificateName === 'Lamination'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo"></app-lamination-view>

            <app-newricecard *ngIf="certificateName === 'NewRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-newricecard>
            <app-view-marriage-split-ricecard *ngIf="certificateName === 'MarriageSplitRC'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)"></app-view-marriage-split-ricecard>
            <app-view-memberaddtion *ngIf="certificateName === 'MemberAdditionRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-view-memberaddtion>
            <app-view-memberdeletion *ngIf="certificateName === 'MemberDeletionRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-view-memberdeletion>
            <app-view-spillitingricecard *ngIf="certificateName === 'SplittingRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
            (view)="viewFile($event)" (download)="downloadFile($event)">
            </app-view-spillitingricecard>
             <app-view-surrenderricecard *ngIf="certificateName === 'SurrenderRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo"></app-view-surrenderricecard>
             <app-view-correction-of-wrong-aadhar-seeding *ngIf="certificateName === 'CorrectionofWrongAadhaar'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
             (view)="viewFile($event)" (download)="downloadFile($event)">
             </app-view-correction-of-wrong-aadhar-seeding>
             <app-view-change-of-rice-card *ngIf="certificateName === 'ChangeOfAddressInRiceCard'" [commonFormAddedFields]="commonFormAddedFields" [presentAddr]="presentAddr" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" ></app-view-change-of-rice-card>
             <app-fp-shop-view *ngIf="certificateName === 'FPSHOPRENEW-VSKP' || certificateName === 'FPSHOPRENEW-OTH' || certificateName === 'FPSHOPRENEW'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)" ></app-fp-shop-view>
             <app-birth-death-certificate-view *ngIf="certificateName === 'MaudBirthDeathCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-birth-death-certificate-view>
             <app-nonavailability-birth-view *ngIf="certificateName === 'NonAvailabilityBirthApplication'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-nonavailability-birth-view>
             <app-nonavailability-death-view *ngIf="certificateName === 'NonAvailabilityDeathApplication'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-nonavailability-death-view>
             <app-childname-inclusion-view *ngIf="certificateName === 'ChildNameInclusion'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-childname-inclusion-view>
             <app-corrections-in-birth-view *ngIf="certificateName === 'CorrectionsInBirthCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-corrections-in-birth-view>
             <app-corrections-in-death-view *ngIf="certificateName === 'CorrectionsInDeathCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-corrections-in-death-view>
             
             <app-pensionform-view *ngIf="certificateName === 'Pensions@1' || certificateName === 'Pensions@V1'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@2'  || certificateName === 'Pensions@V2' || certificateName === 'Pensions-NEW@2' || certificateName === 'Pensions-NEW@V2'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@3' || certificateName === 'Pensions@V3'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@4' || certificateName === 'Pensions@V4'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@5' || certificateName === 'Pensions@V5'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@6' || certificateName === 'Pensions@V6'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@7' || certificateName === 'Pensions@V7'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@8' || certificateName === 'Pensions@V8'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@9' || certificateName === 'Pensions@V9' || certificateName === 'Pensions-NEW@9' || certificateName === 'Pensions-NEW@V9'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
             <app-pensionform-view *ngIf="certificateName === 'Pensions@10' || certificateName === 'Pensions@V10' || certificateName === 'Pensions-NEW@10' || certificateName === 'Pensions-NEW@V10'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>

             <app-aarogyasri-view *ngIf="certificateName === 'AarogyaSriNewCard' || certificateName === 'UpdateAarogyaSriCard' || certificateName === 'UpdateAarogyaSriCardForImage'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [isNewEnrollment] = "true" [taskId] = "" [originalFileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-aarogyasri-view>
             <app-patient-bankdetails-view *ngIf="certificateName === 'PatientBankDetails'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-patient-bankdetails-view>
             <app-land-conversion-agri-view *ngIf="certificateName === 'LandConversionAgriToNonAgri' || certificateName === 'LandConversionAgriToNonAgriSyNo'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-land-conversion-agri-view>
             <app-token-registration-view *ngIf="certificateName === 'RegistrationofTokens'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [reqId]="requisitionID"></app-token-registration-view>
             <app-sadarem-print-certificate-view *ngIf="certificateName === 'SadaremPrintCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo"></app-sadarem-print-certificate-view>

             <app-reissuance-view *ngIf="certificateName === 'ReIssuanceCertificateNew'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo"[fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)"></app-reissuance-view>
             <app-marriage-certificate-view  *ngIf="certificateName === 'MarriageCertificate' || certificateName === 'MarriageCertificate-Rural'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-marriage-certificate-view>
             <app-job-seeker-reg-view  *ngIf="certificateName === 'JobSeekerRegistration'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-job-seeker-reg-view>
             <app-muslim-marriage-view  *ngIf="certificateName === 'MuslimMarriageCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-muslim-marriage-view>
             <app-labour-registration-view  *ngIf="certificateName === 'LabourRegistration' || certificateName === 'LabourRegistrationRenew'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-labour-registration-view>
             <app-print-service-view *ngIf="certificateName === 'PrintCertificate' || certificateName === 'PrintCertificateForAarogyaSri'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo"></app-print-service-view>
             <app-address-certificate-for-aadhar-enrollment-view  *ngIf="certificateName === 'AddressCertificateForAadharEnrollment' || certificateName === 'AddressCertificateForAadharEnrollment@1' || certificateName === 'AddressCertificateForAadharEnrollment@2' || certificateName === 'AddressCertificateForAadharEnrollment@3'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-address-certificate-for-aadhar-enrollment-view>
            </form>
          </div>
 
        <div class="row mb-3">
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
            <div class="col col-lg-8 col-md-8 col-sm-12 col-xs-12 btnAlign text-center mt-4">
                <button *ngIf='!revoke && certificateName === "AssignmentofLandstoEx-ServicepersonServingSoldiers"' class="btn btn-primary" routerLink="/view-transactionzswo" [queryParams]="{preservesearch:true}" routerLinkActive='active'>back to view transactions</button>
                <button *ngIf='!revoke && certificateName !== "AssignmentofLandstoEx-ServicepersonServingSoldiers"' class="btn btn-primary" routerLink="/view-transactions" [queryParams]="{preservesearch:true}" routerLinkActive='active'>back to view transactions</button>
                <button *ngIf='revoke' class="btn btn-primary" routerLink="/revoke-worklist" [queryParams]="{preservesearch:true}" routerLinkActive='active'>back to revoke worklist</button>
                <button class="btn btn-primary ml-5" *ngIf="certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers'" (click)="printReceipt()">Print Application</button>
             </div>
           <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
        </div>
</div>
   
 </ng-container>

