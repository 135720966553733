<div [formGroup]="form">
  <div class="row mb-3">
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls" *ngIf="service == 'SocialAuditGrievance'">Citizen Aadhaar No. (As per Caste Survey): <span class="mandatoryCls">*</span></label>
          <label class="labelCls" *ngIf="service != 'SocialAuditGrievance'">Aadhaar Number: <span class="mandatoryCls">*</span></label>
          <input type="text" name="aadharNo" formControlName="aadharNo" class="inputCls width75" (blur)= "aadharencrypt($event)" appBlockCopyPaste required numbersOnly
          [ngClass]="{ 'is-invalid':isSubmitted && f.aadharNo.errors }" [readonly] ="isAadharPrefilled" maxlength="12" minlength="12">
          <button class="btn btn-primary float-right" (mousedown)="getCitizenInfo()">Pre-Fill</button>
          <div *ngIf="isSubmitted && f.aadharNo.errors" class="invalid-feedback">
            <div *ngIf="f.aadharNo.errors.required || f.aadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
            <div *ngIf="f.aadharNo.errors.aadharValidate || f.aadharNo.errors.minlength || f.aadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
        </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Citizen Name: <span class="mandatoryCls">*</span></label>
          <input type="text" name="firstName" formControlName="firstName" class="inputCls" appBlockCopyPaste required maxlength="200" [attr.disabled] = "this.isAuthenticated ? '' : null"
          [ngClass]="{ 'is-invalid':isSubmitted && f.firstName.errors }">
          <div *ngIf="isSubmitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required || f.firstName.errors.whiteSpaceValidate">Citizen Name is required</div>
            <div *ngIf="f.firstName.errors?.pattern">Please enter valid Citizen Name</div>
        </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Father/Husband Name: <span class="mandatoryCls">*</span></label>
          <input type="text" name="fatherHusName" formControlName="fatherHusName" class="inputCls" appBlockCopyPaste required maxlength="75"
          [ngClass]="{ 'is-invalid':isSubmitted && f.fatherHusName.errors }">
          <div *ngIf="isSubmitted && f.fatherHusName.errors" class="invalid-feedback">
            <div *ngIf="f.fatherHusName.errors.required || f.fatherHusName.errors.whiteSpaceValidate">Please enter Father/Husband Name</div>
            <div *ngIf="f.fatherHusName.errors?.pattern">Please enter valid Father/Husband Name</div>
        </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
          <select class="selectCls" formControlName="gender" required [ngClass]="{ 'is-invalid':isSubmitted && (f.gender.errors || f.gender.value === 'Select')}" [attr.disabled] = "this.isAuthenticated ? '' : null"> 
              <option [ngValue]="null" disabled>Select</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="Transgender">Transgender</option>
            </select>
          <div *ngIf="isSubmitted && (f.gender.errors || f.gender.value === 'Select')" class="selectError">
            <div *ngIf="f.gender.errors?.required || f.gender.value === 'Select'">Please select Gender</div>
        </div>
        </div>
        <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Middle Name:</label>
          <input type="text" name="middleName" formControlName="middleName" appBlockCopyPaste class="inputCls" maxlength="50"
          [ngClass]="{ 'is-invalid':isSubmitted && f.middleName.errors }">
          <div *ngIf="isSubmitted && f.middleName.errors" class="invalid-feedback">
            <div *ngIf="f.middleName.errors?.pattern">Please enter valid Middle Name</div>
        </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Last Name: <span class="mandatoryCls">*</span></label>
          <input type="text" name="lastName" formControlName="lastName" class="inputCls" appBlockCopyPaste required maxlength="50"
          [ngClass]="{ 'is-invalid':isSubmitted && f.lastName.errors }">
          <div *ngIf="isSubmitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required || f.lastName.errors.whiteSpaceValidate">Last Name is required</div>
            <div *ngIf="f.lastName.errors?.pattern">Please enter valid Last Name</div>
        </div>
        </div> -->
  </div>
  <div class="row mb-3">
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Date of Birth: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" appBlockCopyPaste
                 name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                 [maxDate]="maxDate" required formControlName="dob"
                 [ngClass]="{ 'is-invalid':isSubmitted && f.dob.errors }" [attr.disabled] = "this.isAuthenticated ? '' : null">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="isSubmitted && f.dob.errors" class="selectError">
          <div *ngIf="f.dob.errors?.required || f.dob.invalid">Please enter valid Date of Birth</div>
      </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Caste: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="caste" required (change)="onCasteChange('caste', $event.target)"
        [ngClass]="{ 'is-invalid':isSubmitted && (f.caste.errors || f.caste.value === 'Select')}"> 
          <option [ngValue]="null" disabled>Select</option>
          <option *ngFor="let caste of casteCategory" [ngValue]="caste">{{caste.name}}</option>
        </select>
      <div *ngIf="isSubmitted && (f.caste.errors || f.caste.value === 'Select')" class="selectError">
        <div *ngIf="f.caste.errors?.required || f.caste.value === 'Select'">Please select Caste</div>
    </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Religion: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="religion" required [ngClass]="{ 'is-invalid':isSubmitted && (f.religion.errors || f.religion.value === 'Select')}"> 
          <option [ngValue]="null" disabled>Select</option>
          <option *ngFor="let religion of religions" [ngValue]="religion">{{religion.name}}</option>
        </select>
      <div *ngIf="isSubmitted && (f.religion.errors || f.religion.value === 'Select')" class="selectError">
        <div *ngIf="f.religion.errors?.required || f.religion.value === 'Select'">Please select Religion</div>
      </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Qualification: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" placeholder = "Select" formControlName="qualification" required [ngClass]="{ 'is-invalid':isSubmitted && (f.qualification.errors || f.qualification.value === 'Select') }"> 
          <option [ngValue]="null" disabled>Select</option>
          <option *ngFor="let qual of qualifications" [ngValue]="qual">{{qual.name}}</option>
        </select>
      <div *ngIf="isSubmitted && (f.qualification.errors || f.qualification.value === 'Select')" class="selectError">
        <div *ngIf="f.qualification.errors?.required || f.qualification.value === 'Select'">Please select Qualification</div>
      </div>
      </div>
</div>

  <div class="row mb-3">
    
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Marital Status: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="maritalStatus" required [ngClass]="{ 'is-invalid':isSubmitted && (f.maritalStatus.errors|| f.maritalStatus.value === 'Select')}"> 
        <option [ngValue]="null" disabled>Select</option>
        <option value="Divorced">Divorced</option>
        <option value="Married">Married</option>
        <option value="Unmarried">Unmarried</option>
        <option value="Widow">Widow</option>
        <option value="Single Women(Married)">Single Women(Married)</option>
      </select>
    <div *ngIf="isSubmitted && (f.maritalStatus.errors || f.maritalStatus.value === 'Select')" class="selectError">
      <div *ngIf="f.maritalStatus.errors?.required || f.maritalStatus.value === 'Select'">Please select Marital Status</div>
  </div>
    </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
          <input type="text" name="mobileNo" formControlName="mobileNo" class="inputCls" appBlockCopyPaste required
          [ngClass]="{ 'is-invalid':isSubmitted && f.mobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
          <div *ngIf="isSubmitted && f.mobileNo.errors" class="invalid-feedback">
            <div *ngIf="f.mobileNo.errors.required">Please enter Mobile No</div>
            <div *ngIf="f.mobileNo.errors?.minlength || f.mobileNo.errors?.pattern">Please enter valid Mobile No</div>
            </div>
        </div>

        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Is the Mobile Number same as Whatsapp Number?<span class="mandatoryCls">*</span></label>
          <input type="radio" value="Yes" name="isWhatsAppAvailable" formControlName="isWhatsAppAvailable" checked ><span class="mx-2 radioCls">Yes</span>
          <input type="radio" value="No" name="isWhatsAppAvailable"  formControlName="isWhatsAppAvailable" ><span class="mx-2 radioCls">No</span>
          <div *ngIf="isSubmitted && f.isWhatsAppAvailable.errors" class="selectError">
            <div *ngIf="f.isWhatsAppAvailable.errors?.required">Please select Whatsapp Availabilty</div>
          </div>
        </div>

        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <label class="labelCls">Email:</label>
          <input type="email" name="email" formControlName="email" class="inputCls" maxlength="75" appBlockCopyPaste
          [ngClass]="{ 'is-invalid':isSubmitted && f.email.errors }" [required]="f.deliveryType.value === 'Email'">
          <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors?.required">Please enter Email</div>
            <div *ngIf="f.email.errors?.pattern">Please enter valid Email</div>
          </div>
        </div>
        <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Delivery Type: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="deliveryType" required [ngClass]="{ 'is-invalid':isSubmitted && (f.deliveryType.errors || f.deliveryType.value === 'Select') }"> 
              <option value="By Hand" selected>By Hand</option>
              <option value="Email">Email</option>
              <option value="Post">Post</option>
            </select>
            <div *ngIf="isSubmitted && (f.deliveryType.errors || f.deliveryType.value === 'Select')" class="selectError">
              <div *ngIf="f.deliveryType.errors?.required || f.deliveryType.value === 'Select'">Please select Delivery Type</div>
            </div>
          </div> -->
</div>
  <div class="row mb-3">
    
  </div>
  <hr class="hrCls mt-4">
  <div class="subHeadingCls mb-3">Permanent Address</div>
  <div class="row mb-3">
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Door No & Street Name: <span class="mandatoryCls">*</span></label>
      <input type="text" name="permDoorNo" class="inputCls" required maxlength="50" appBlockCopyPaste formControlName="permDoorNo"
      [ngClass]="{ 'is-invalid':isSubmitted && f.permDoorNo.errors }" (blur)="onDoorNoChange(f.permDoorNo.value)">
      <div *ngIf="isSubmitted && f.permDoorNo.errors" class="invalid-feedback">
        <div *ngIf="f.permDoorNo.errors.required || f.permDoorNo.errors?.whiteSpaceValidate">Please enter Door No</div>
        <div *ngIf="f.permDoorNo.errors?.pattern">Please enter valid  Door No</div>
    </div>
    </div>
    <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Street Name : <span class="mandatoryCls">*</span></label>
      <input type="text" name="permStreet" class="inputCls" required maxlength="50" formControlName="permStreet" appBlockCopyPaste
      [ngClass]="{ 'is-invalid':isSubmitted && f.permStreet.errors }" (blur)="onStreetChange(f.permStreet.value)">
      <div *ngIf="isSubmitted && f.permStreet.errors" class="invalid-feedback">
        <div *ngIf="f.permStreet.errors.required  || f.permStreet.errors?.whiteSpaceValidate">Please enter Street Name</div>
        <div *ngIf="f.permStreet.errors?.pattern">Please enter valid Street Name</div>
    </div>
    </div> -->
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" required formControlName="permDistrict" (change)="onDistrictChange('permanent', 'permDistrict', $event.target)"
      [ngClass]="{ 'is-invalid':isSubmitted && (f.permDistrict.errors || f.permDistrict.value === 'Select') }">
      <option  [ngValue]= "null" disabled>Select</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.name}}</option>
      </select>
      <div *ngIf="isSubmitted && (f.permDistrict.errors || f.permDistrict.value === 'Select')" class="selectError">
        <div *ngIf="f.permDistrict.errors?.required || f.permDistrict.value === 'Select'">Please select District</div>
    </div>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" required formControlName="permMandal" (change)="onMandalChange('permanent', 'permMandal', $event.target)"
      [ngClass]="{ 'is-invalid':isSubmitted && (f.permMandal.errors || f.permMandal.value === 'Select') }">
      <option [ngValue]= "null" disabled>Select</option>
      <option *ngFor="let mandal of permMandals" [ngValue]="mandal">{{mandal.name}}</option>
    </select>
    <div *ngIf="isSubmitted && (f.permMandal.errors || f.permMandal.value === 'Select')" class="selectError">
      <div *ngIf="f.permMandal.errors?.required || f.permMandal.value === 'Select'">Please select Mandal/Municipality</div>
    </div>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" required formControlName="permVillage" (change)="onVillageChange($event.target)"
        [ngClass]="{ 'is-invalid':isSubmitted && (f.permVillage.errors || f.permVillage.value === 'Select') }">
        <option [ngValue]= "null" disabled>Select</option>
        <option *ngFor="let village of permVillages" [ngValue]="village">{{village.name}}</option>
      </select>
      <div *ngIf="isSubmitted && (f.permVillage.errors || f.permVillage.value === 'Select')" class="selectError">
        <div *ngIf="f.permVillage.errors?.required || f.permVillage.value === 'Select'">Please select Village/Ward/Secretariat</div>
    </div>
    </div>
  </div>
  <div class="row mb-3">
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Pin Code: <span class="mandatoryCls">*</span></label>
    <input type="text" name="permPinCode" formControlName="permPinCode" class="inputCls" appBlockCopyPaste required (blur)="onPincodeChange('permanent', f.permPinCode.value)"
    [ngClass]="{ 'is-invalid':isSubmitted && f.permPinCode.errors }" numbersOnly maxlength="6">
    <div *ngIf="isSubmitted && f.permPinCode.errors" class="invalid-feedback">
      <div *ngIf="f.permPinCode.errors.required">Please enter Pin Code</div>
      <div *ngIf="f.permPinCode.errors?.pattern">Please enter valid Pin Code</div>
  </div>
  </div>
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Postal Office: <span *ngIf = "postalRequired" class="mandatoryCls">*</span></label>
    <select class="selectCls"  formControlName="permPostalOffice" (change)="onPostalOfficeChange('permanent', 'permPostalOffice', $event.target)"
      [ngClass]="{ 'is-invalid':isSubmitted && (f.permPostalOffice.errors || f.permPostalOffice.value === 'Select') }">
      <option [ngValue]= "null" disabled>Select</option>
      <option *ngFor="let office of permPostalOffices" [ngValue]="office">{{office.name}}</option>
    </select>
    <div *ngIf="isSubmitted && (f.permPostalOffice.errors || f.permPostalOffice.value === 'Select')" class="selectError">
      <div *ngIf="f.permPostalOffice.errors?.required || f.permPostalOffice.value === 'Select'">Please select postal office</div>
  </div>
  </div>
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Postal Village: <span *ngIf = "postalRequired" class="mandatoryCls">*</span></label>
    <select class="selectCls"  formControlName="permPostalVillage" (change)="onPostalVillageChange($event.target)"
      [ngClass]="{ 'is-invalid':isSubmitted && (f.permPostalVillage.errors || f.permPostalVillage.value === 'Select') }">
      <option [ngValue]= "null" disabled>Select</option>
      <option *ngFor="let village of permPostalVillages" [ngValue]="village">{{village.name}}</option>
    </select>
    <div *ngIf="isSubmitted && (f.permPostalVillage.errors || f.permPostalVillage.value === 'Select')" class="selectError">
      <div *ngIf="f.permPostalVillage.errors?.required || f.permPostalVillage.value === 'Select'">Please select postal village</div>
  </div>
  </div>
  </div>
  <hr class="hrCls mt-4">
<div class="sameAddress"><input type="checkbox" id ="sameAddress" formControlName="sameAddress" (change)="checkAddress()">
  <label class="labelCls ml-2" for="sameAddress">Present Address is same as Permanent Address</label>
</div>
<div class="subHeadingCls mb-3">Present Address (Mailing Address)</div>
<div class="row mb-3">
<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Door No & Street Name: <span class="mandatoryCls">*</span></label>
    <input type="text" name="preDoorNo" class="inputCls" required maxlength="50" appBlockCopyPaste formControlName="preDoorNo"
    [ngClass]="{ 'is-invalid':isSubmitted && f.preDoorNo.errors }" (blur)="validateAddressSame()">
    <div *ngIf="isSubmitted && f.preDoorNo.errors" class="invalid-feedback">
      <div *ngIf="f.preDoorNo.errors.required || f.preDoorNo.errors?.whiteSpaceValidate">Please enter Door No</div>
      <div *ngIf="f.preDoorNo.errors?.pattern">Please enter valid Door No</div>
  </div>
  </div>
  <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Street Name : <span class="mandatoryCls">*</span></label>
    <input type="text" name="preStreet" class="inputCls" appBlockCopyPaste required maxlength="50" formControlName="preStreet"
    [ngClass]="{ 'is-invalid':isSubmitted && f.preStreet.errors }" (blur)="validateAddressSame()">
    <div *ngIf="isSubmitted && f.preStreet.errors" class="invalid-feedback">
      <div *ngIf="f.preStreet.errors.required || f.preStreet.errors?.whiteSpaceValidate">Please enter Street Name</div>
      <div *ngIf="f.preStreet.errors?.pattern">Please enter valid Street Name</div>
  </div>
  </div> -->
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">District: <span class="mandatoryCls">*</span></label>
    <select class="selectCls" required formControlName="preDistrict" (change)="onDistrictChange('present', 'preDistrict', $event.target)"
    [ngClass]="{ 'is-invalid':isSubmitted && (f.preDistrict.errors || f.preDistrict.value === 'Select') }">
    <option [ngValue]= "null" disabled>Select</option>
      <option *ngFor="let district of districts" [ngValue]="district">{{district.name}}</option>
    </select>
    <div *ngIf="isSubmitted && (f.preDistrict.errors || f.preDistrict.value === 'Select')" class="selectError">
      <div *ngIf="f.preDistrict.errors?.required || f.preDistrict.value === 'Select'">Please select District</div>
  </div>
  </div>
<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
  <select class="selectCls" required formControlName="preMandal" (change)="onMandalChange('present', 'preMandal', $event.target)"
  [ngClass]="{ 'is-invalid':isSubmitted && (f.preMandal.errors || f.preMandal.value === 'Select') }">
  <option [ngValue]= "null" disabled>Select</option>
  <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.name}}</option>
</select>
<div *ngIf="isSubmitted && (f.preMandal.errors || f.preMandal.value === 'Select')" class="selectError">
  <div *ngIf="f.preMandal.errors?.required || f.preMandal.value === 'Select'">Please select Mandal/Municipality</div>
</div>
</div>
<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
  <select class="selectCls" required formControlName="preVillage" (change)="validateAddressSame()"
    [ngClass]="{ 'is-invalid':isSubmitted && (f.preVillage.errors || f.preVillage.value === 'Select') }">
    <option [ngValue]= "null" disabled>Select</option>
    <option *ngFor="let village of villages" [ngValue]="village">{{village.name}}</option>
  </select>
  <div *ngIf="isSubmitted && (f.preVillage.errors || f.preVillage.value === 'Select')" class="selectError">
    <div *ngIf="f.preVillage.errors?.required || f.preVillage.value === 'Select'">Please select Village/Ward/Secretariat</div>
</div>
</div>
</div>
<div class="row mb-3">
<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">Pin Code: <span class="mandatoryCls">*</span></label>
  <input type="text" name="prePinCode" formControlName="prePinCode" class="inputCls" appBlockCopyPaste required (blur)="onPincodeChange('present', f.prePinCode.value)"
  [ngClass]="{ 'is-invalid':isSubmitted && f.prePinCode.errors }" numbersOnly maxlength="6">
  <div *ngIf="isSubmitted && f.prePinCode.errors" class="invalid-feedback">
    <div *ngIf="f.prePinCode.errors.required">Please enter Pin Code</div>
    <div *ngIf="f.prePinCode.errors?.pattern">Please enter valid Pin Code</div>
</div>
</div>
<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">Postal Office: <span *ngIf = "postalRequired"  class="mandatoryCls">*</span></label>
  <select class="selectCls"  formControlName="prePostalOffice" (change)="onPostalOfficeChange('present', 'prePostalOffice', $event.target)"
    [ngClass]="{ 'is-invalid':isSubmitted && (f.prePostalOffice.errors || f.prePostalOffice.value === 'Select') }">
    <option [ngValue]= "null" disabled>Select</option>
    <option *ngFor="let office of prePostalOffices" [ngValue]="office">{{office.name}}</option>
  </select>
  <div *ngIf="isSubmitted && (f.prePostalOffice.errors || f.prePostalOffice.value === 'Select')" class="selectError">
    <div *ngIf="f.prePostalOffice.errors?.required || f.prePostalOffice.value === 'Select'">Please select postal office</div>
</div>
</div>
<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">Postal Village: <span *ngIf = "postalRequired"  class="mandatoryCls">*</span></label>
  <select class="selectCls"  formControlName="prePostalVillage" (change)="validateAddressSame()"
    [ngClass]="{ 'is-invalid':isSubmitted && (f.prePostalVillage.errors || f.prePostalVillage.value === 'Select') }">
    <option [ngValue]= "null" disabled>Select</option>
    <option *ngFor="let village of prePostalVillages" [ngValue]="village">{{village.name}}</option>
  </select>
  <div *ngIf="isSubmitted && (f.prePostalVillage.errors || f.prePostalVillage.value === 'Select')" class="selectError">
    <div *ngIf="f.prePostalVillage.errors?.required || f.prePostalVillage.value === 'Select'">Please select postal village</div>
</div>
</div>
</div>

</div>

<div class="btnHolder bntReset">
  <button  class="greenBtnCls float-right bntResetCls" (click)="resetForm()">Reset</button>
</div>