import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-passbook-related-service-status-report',
  templateUrl: './passbook-related-service-status-report.component.html',
  styleUrls: ['./passbook-related-service-status-report.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class PassbookRelatedServiceStatusReportComponent implements OnInit {
  commonConstants : any = CommonConstants;
  form: UntypedFormGroup;
  services: any;
  applicationno:any;
  formatTypes: any[]= [];
  items: any[] = [];
  totalItems: any[] = [];
  beyondSLAItems: any[] = [];
  getData: any[] = [];
  page = 1;
  popupPage = 1;
  pageSize = 10;
  format: any;
  showReports:Boolean=false;
  submitted:Boolean=false;
  noOfCol: any = 23;
  role: any;
  defaultFromDate: any;
  currentDate = new Date();
  popupPageSize = 10;
  maxDate: any;
  minDate: any;
  toMinDate: any;
  totalReport: Boolean = false;
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('BEYONDSLATABLE') beyondSLATable!: ElementRef;
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService,private router: Router,private modalService: NgbModal) {
    this.form = this.formBuilder.group({
      services: ['', Validators.required],
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]]

    });
   
    let userInfo = this.commonService.getLocalStorage('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
    }
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.toMinDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    }
  }

  ngOnInit(): void {
    this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.format = 'District Wise';
     this.form.patchValue({
      services:'All',
      fromDate: this.defaultFromDate,
      toDate: this.defaultFromDate,
     });
  }

  get f() { return this.form.controls; }
  
  
  onFromDateSelect(event: any) {
    this.showReports = false;
    this.toMinDate = event;
  }
  onToDateSelect(event: any) {
    this.showReports = false;
  }

  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  
  getFormat(): any {
      if (this.format === 'District Wise' ) {
      this.noOfCol = 23;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 23;
      return this.noOfCol;
    } else {
      this.noOfCol = 23;
      return this.noOfCol;
    }
  }
 

  getReports(from?: any): any {
    let postData = '';
    let services = this.f.services.value === 'All' ? 'All' : this.f.services.value;
    let fromDateFormat = this.commonService.formatDate(this.form.controls['fromDate'].value);
    let toDateFormat = this.commonService.formatDate(this.form.controls['toDate'].value);
    // let fromDateFormat = this.datePipe.transform(this.commonService.formatDate(this.form.controls['fromDate'].value), 'dd/MM/yyyy');
    // let toDateFormat = this.datePipe.transform(this.commonService.formatDate(this.form.controls['toDate'].value), 'dd/MM/yyyy');    
    postData = '?Servicetype='+ services +'&Fromdate=' + fromDateFormat + '&ToDate=' + toDateFormat;
    let formDateFormat = this.commonService.formatDate(
      this.form.controls['fromDate'].value
    );
    let toDateFormat1 = this.commonService.formatDate(this.form.controls['toDate'].value);
    // let diffDate = this.commonService.diffInDates(this.commonService.formatDate(this.f.fromDate.value), this.commonService.formatDate(this.f.toDate.value));
    // if (diffDate && !this.totalReport) {
    //     const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    //     modalRef.componentInstance.title = 'Alert';
    //     modalRef.componentInstance.message = 'Please select the date range with less than or equal to 31 days.';
    // } else 
    if(new Date(toDateFormat1) < new Date(formDateFormat)){
      this.commonService.commonErrorPopup('Alert', 'Please select proper date range,To date should be greater than or equal to from date !!!', 'md');
      this.getData=[];
    }
    else{
      this.getDetails(postData);
    }
    }

    getDetails(postData: any) {
      this.commonService.getRequest(this.commonConstants.getPassbookReportData+postData).subscribe({
        next: (responseData: any) => { 
          this.getData=[];
          if(responseData.result?.alert){
            this.commonService.commonErrorPopup('Alert', 'Please Change the From Date and To Date', 'md');
            return;
          }
         this.getData = responseData?.result?.mspReportData;
         // console.log(this.getData, 'getData responseDataCount')
        },
           error: (error) => {
           console.log('client side', error);
         }
    });
    }
   
   
  downloadExcel() {
    let fileName: any = 'Application Wise Passbooks Related Service Status' + this.f.fromDate.value +' to '+ this.f.toDate.value;
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Passbooks Report Status');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  downloadExcelForBeyondSLA() {
    let fileName: any =   'Pending Transactions Report'  //this.title + ' ' + this.f.fromDate.value +' to '+ this.f.toDate.value +')';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.beyondSLATable.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let sheetName = 'Pending Transactions Report';
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}