<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
      <!-- <div *ngIf="submitted && (f.permDistrict.errors || f.permDistrict.value === 'Select')" class="selectError">
        <div *ngIf="f.permDistrict.errors?.required || f.permDistrict.value === 'Select'">Please select District</div>
    </div> -->
    </div>


    <!-- new revenue mandal wise added -->
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Revenue Mandal: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="revenueMandal" (change)="getMandals()"
        [ngClass]="{ 'is-invalid':submitted && (f.revenueMandal.errors || f.revenueMandal.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let revenue of revenueMandals" [ngValue]="revenue">{{revenue.revenuE_MANDAL_NAME}}</option>
      </select>
    </div>


    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
        <option value = "All">All</option>
        <option value = "R">Rural</option>
        <option value = "U">Urban</option>
        </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
    </select>
    <!-- <div *ngIf="submitted && (f.permMandal.errors || f.permMandal.value === 'Select')" class="selectError">
      <div *ngIf="f.permMandal.errors?.required || f.permMandal.value === 'Select'">Please select Mandal/Municipality</div>
    </div> -->
    </div>

    

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="village" (change)="onVillageChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
      </select>
      <!-- <div *ngIf="submitted && (f.permVillage.errors || f.permVillage.value === 'Select')" class="selectError">
        <div *ngIf="f.permVillage.errors?.required || f.permVillage.value === 'Select'">Please select Village/Ward/Secretariat</div>
    </div> -->
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Department: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="department" (change)="onDeptChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.department.errors || f.department.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let dept of departments" [ngValue]="dept">{{dept}}</option>
        </select>
        
      </div>
</div>
<div class="row mb-3">
    <!-- <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Department: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="department" (change)="onDeptChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.department.errors || f.department.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let dept of departments" [ngValue]="dept">{{dept}}</option>
        </select>
        
      </div> -->
      <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Service: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="service" (change) = "onServiceChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.service.errors || f.service.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let service of services" [ngValue]="service">{{service}}</option>
        </select>
       
      </div>
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="fromDate"
                 [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.fromDate.errors" class="selectError">
          <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
      </div>
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
          <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
      </div>
      </div>
    </div>
    
      <div class="tabClass">
        <ul>
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              <!-- <li><a>Department Wise</a></li>
              <li><a>Service Wise</a></li>
              <li><a>Mandal Wise</a></li>
              <li><a>Secretariat Wise</a></li> -
              <li><a>Revenue Mandal Wise</a></li> -->
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getCSPDashboardData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <!-- <th [attr.colspan]="format === 'State Wise' ? 11 : 12"><span>{{format}} SLA Dashboard </span><span *ngIf="f.district.value !== 'All'"> 
                    for {{f.district.value?.name}} </span> <span *ngIf="f.mandal.value !== 'All'"> ,
                     {{f.mandal.value?.name}} </span> <span *ngIf="f.village.value !== 'All'"> ,
                     {{f.village.value?.name}} </span><span> from {{f.fromDate.value}} to {{f.toDate.value}}</span></th> -->
                <th [attr.colspan]="noOfCol">AP SEVA Services Dashboard from  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
            <tr>
               <th rowspan="2">S.No</th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION_NAME'">Revenue Division <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL_NAME'">Revenue Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Department Wise' || format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'department'">Department <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'srV_NAME'">Service Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_REQ'">Total No Of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" class="openBeyond" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opN_BYND_SLA'">Open Beyond SLA Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" class="openWithin" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opN_WTH_SLA'">Open Within SLA Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_BYND_SLA'">Closed Beyond SLA Requests </th>
               <th colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_WTH_SLA'">Closed Within SLA Requests </th>
               <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lpS_WITHIN_24HRS'">Lapsing Within 24 Hours <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lpS_WITHIN_48HRS'">Lapsing Within 48 Hours <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
            <tr>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apprvD_BYND_SLA'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'reJ_BYND_SLA'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apprvD_WTH_SLA'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'reJ_WTH_SLA'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
            <!-- <tr>
                <th *ngFor="let head of headerColMain; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="headerColMain[i].colId">{{ head.colName }}
                  <mdb-icon fas icon="sort"></mdb-icon>
                </th>
              </tr> -->
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">{{item.districT_NAME}}</td>
                <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.revenuE_DIVISION_NAME}}</td>
               <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.revenuE_MANDAL_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruraL_URBAN}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
               <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{item.department}}</td>
               <td *ngIf="format === 'Service Wise'">{{item.srV_NAME}}</td>
                <td class="text-right">{{item.toT_REQ}}</td>
                <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, 'beyond')" class="linkCls">{{item.opN_BYND_SLA}}</a></td>
                <td class="openWithin text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, 'within')" class="linkCls">{{item.opN_WTH_SLA}}</a></td>
                <td class="text-right">{{item.apprvD_BYND_SLA}}</td>
                <td class="text-right">{{item.reJ_BYND_SLA}}</td>
                <td class="text-right">{{item.apprvD_WTH_SLA}}</td>
                <td class="text-right">{{item.reJ_WTH_SLA}}</td>
                <td class="text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, '24')" class="linkCls">{{item.lpS_WITHIN_24HRS}}</a></td>
                <td class="text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, '48')" class="linkCls">{{item.lpS_WITHIN_48HRS}}</a></td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">{{totalItems[0].districT_NAME}}</td>
                <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].revenuE_DIVISION_NAME}}</td>
               <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].revenuE_MANDAL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruraL_URBAN}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
               <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{totalItems[0].department}}</td>
               <td *ngIf="format === 'Service Wise'">{{totalItems[0].srV_NAME}}</td>
                <td class="text-right">{{totalItems[0].toT_REQ}}</td>
                <td class="openBeyond text-right"><a class="linkCls">{{totalItems[0].opN_BYND_SLA}}</a></td>
                <td class="openWithin text-right">{{totalItems[0].opN_WTH_SLA}}</td>
                <td class="text-right">{{totalItems[0].apprvD_BYND_SLA}}</td>
                <td class="text-right">{{totalItems[0].reJ_BYND_SLA}}</td>
                <td class="text-right">{{totalItems[0].apprvD_WTH_SLA}}</td>
                <td class="text-right">{{totalItems[0].reJ_WTH_SLA}}</td>
                <!-- <td class="text-right">{{totalItems[0].clsD_BYND_SLA}}</td>
                <td class="text-right">{{totalItems[0].clsD_WTH_SLA}}</td> -->
                <td class="text-right">{{totalItems[0].lpS_WITHIN_24HRS}}</td>
                <td class="text-right">{{totalItems[0].lpS_WITHIN_48HRS}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="format === 'State Wise' ? 13 : 14" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>


</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
           <!-- <th [attr.colspan]="format === 'State Wise' ? 11 : 12"><span>{{format}} SLA Dashboard </span><span *ngIf="f.district.value !== 'All'"> 
            <span>&nbsp; for &nbsp;</span><span> {{f.district.value?.name}} </span></span> <span *ngIf="f.mandal.value !== 'All'"> ,
                     {{f.mandal.value?.name}} </span> <span *ngIf="f.village.value !== 'All'"> ,
                     {{f.village.value?.name}} </span><span> &nbsp;from&nbsp; </span><span> {{f.fromDate.value}} to {{f.toDate.value}}</span></th> -->
                <th [attr.colspan]="noOfCol">AP SEVA Services Dashboard  from  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
           <tr>
               <th rowspan="2" width="100px">S.No</th>
               <th rowspan="2" width="100px" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">District</th>
               <th rowspan="2" width="100px" *ngIf="format === 'RevenueMandal Wise'">Revenue Division</th>
               <th rowspan="2" width="100px" *ngIf="format === 'RevenueMandal Wise'">Revenue Mandal</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Secretariat Wise'">Secretariat</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Department Wise' || format === 'Service Wise'">Department</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Service Wise'">Service Name</th>
               <th rowspan="2" width="100px">Total No Of Requests</th>
               <th rowspan="2" width="100px">Open Beyond SLA Requests</th>
               <th rowspan="2" width="100px">Open Within SLA Requests</th>
               <th colspan="2" width="100px">Closed Beyond SLA Requests</th>
               <th colspan="2" width="100px">Closed Within SLA Requests</th>
               <th rowspan="2" width="100px">Lapsing Within 24 Hours</th>
               <th rowspan="2" width="100px">Lapsing Within 48 Hours</th>
           </tr>
           <tr>
            <th width="100px">Approved</th>
           <th width="100px">Rejected</th>
           <th width="100px">Approved</th>
           <th width="100px">Rejected</th>
        </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td width="100px" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">{{item.districT_NAME}}</td>
               <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.revenuE_DIVISION_NAME}}</td>
               <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.revenuE_MANDAL_NAME}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruraL_URBAN}}</td>
               <td width="100px" *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
               <td width="100px" *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{item.department}}</td>
               <td width="100px" *ngIf="format === 'Service Wise'">{{item.srV_NAME}}</td>
                <td>{{item.toT_REQ}}</td>
                <td>{{item.opN_BYND_SLA}}</td>
                <td>{{item.opN_WTH_SLA}}</td>
                <td>{{item.apprvD_BYND_SLA}}</td>
                <td>{{item.reJ_BYND_SLA}}</td>
                <td>{{item.apprvD_WTH_SLA}}</td>
                <td>{{item.reJ_WTH_SLA}}</td>
                <!-- <td>{{item.clsD_BYND_SLA}}</td>
                <td>{{item.clsD_WTH_SLA}}</td> -->
                <td>{{item.lpS_WITHIN_24HRS}}</td>
                <td>{{item.lpS_WITHIN_48HRS}}</td>
           </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">{{totalItems[0].districT_NAME}}</td>
            <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].revenuE_DIVISION_NAME}}</td>
            <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].revenuE_MANDAL_NAME}}</td>
           <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
           <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruraL_URBAN}}</td>
           <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
           <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{totalItems[0].department}}</td>
           <td *ngIf="format === 'Service Wise'">{{totalItems[0].srV_NAME}}</td>
            <td>{{totalItems[0].toT_REQ}}</td>
            <td><a class="linkCls">{{totalItems[0].opN_BYND_SLA}}</a></td>
            <td>{{totalItems[0].opN_WTH_SLA}}</td>
            <td class="text-right">{{totalItems[0].apprvD_BYND_SLA}}</td>
            <td class="text-right">{{totalItems[0].reJ_BYND_SLA}}</td>
            <td class="text-right">{{totalItems[0].apprvD_WTH_SLA}}</td>
            <td class="text-right">{{totalItems[0].reJ_WTH_SLA}}</td>
            <!-- <td>{{totalItems[0].clsD_BYND_SLA}}</td>
            <td>{{totalItems[0].clsD_WTH_SLA}}</td> -->
            <td>{{totalItems[0].lpS_WITHIN_24HRS}}</td>
            <td>{{totalItems[0].lpS_WITHIN_48HRS}}</td>
        </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
               <td [attr.colspan]="format === 'State Wise' ? 13 : 14" class="text-center">No records found.</td>
           </tr>
       </tbody>
   </table>
</div>
<div class="table-responsive tableFixHead" #BEYONDSLATABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th>S.No</th>
               <th>District</th>
               <th>Mandal</th>
               <th>Secretariat</th>
               <th>Revenue Division Name</th>
               <th>Revenue Mandal Name</th>
               <th>Secretariat Code</th>
               <th>Department</th>
               <th>Service Name</th>
               <th>SLA Days</th>
               <th>Application No</th>
               <th>Raised Date</th>
               <th>Status</th>
               <th>Lapsing Date</th>
               <th>Pending at Designation</th>
            </tr>
        </thead>

        <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
            <tr *ngFor="let item of beyondSLAItems;let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.districT_NAME}}</td>
               <td>{{item.mandaL_NAME}}</td>
               <td>{{item.secretariaT_NAME}}</td>
               <td>{{item.revenuE_DIVISION_NAME}}</td>
               <td>{{item.revenuE_MANDAL_NAME}}</td>
               <td>{{item.secretariaT_CODE}}</td>
               <td>{{item.department}}</td>
               <td>{{item.srV_NAME}}</td>
               <td>{{item.sla}}</td>
               <td>{{item.applicatioN_NO}}</td>
               <td>{{item.apP_DATE  | date: 'dd/MM/yyyy'}}</td>
               <td>{{item.apP_Status +' '+item.slA_Status}}</td>
               <td>{{item.lpS_DATE  | date: 'dd/MM/yyyy'}}</td>
               <td>{{item.pendinG_AT_DESIGNATION}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
            <tr>
                <td colspan="15" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #beyondSLAModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">{{title}}</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="table-responsive tableFixHead">
            <table class="tableCls" >
                <thead> 
                    <tr>
                        <th>S.No</th>
                        <th *ngFor="let head of headerCol; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="beyondSLAItems" [sortBy]="headerCol[i].colId">{{ head.colName }}
                            <mdb-icon fas icon="sort"></mdb-icon>
                          </th>
                       <!-- <th>District</th>
                       <th>Mandal</th>
                       <th>Secretariat</th>
                       <th>Department</th>
                       <th>Service Name</th>
                       <th>SLA Days</th>
                       <th>Application No</th>
                       <th>Raised Date</th>
                       <th>Status</th> -->
                    </tr>
                </thead>
        
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
                    <tr *ngFor="let item of beyondSLAItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                        <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                        <td>{{item.districT_NAME}}</td>
                       <td>{{item.mandaL_NAME}}</td>
                       <td>{{item.secretariaT_NAME}}</td>
                       <td>{{item.revenuE_DIVISION_NAME}}</td>
                       <td>{{item.revenuE_MANDAL_NAME}}</td>
                       <td>{{item.secretariaT_CODE}}</td>
                       <td>{{item.department}}</td>
                       <td>{{item.srV_NAME}}</td>
                        <td>{{item.sla}}</td>
                        <td>{{item.applicatioN_NO}}</td>
                        <td>{{item.apP_DATE | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.apP_Status +' '+item.slA_Status}}</td>
                        <td>{{item.lpS_DATE  | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.pendinG_AT_DESIGNATION}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
                    <tr>
                        <td colspan="15" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="beyondSLAItems.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="beyondSLAItems.length > 0"    [collectionSize]="beyondSLAItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  name="close" class="greenBtnCls" *ngIf="beyondSLAItems && beyondSLAItems.length > 0" (click)="downloadExcelForBeyondSLA()">Download Excel</button>
    </div>
   </ng-template>