import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faLock, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd,Params,ActivatedRoute } from '@angular/router';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalConfig,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-external-payment-gateway',
  templateUrl: './external-payment-gateway.component.html',
  styleUrls: ['./external-payment-gateway.component.css'],
})
export class ExternalPaymentGatewayComponent implements OnInit {
  faLock = faLock;
  faInfoCircle = faInfoCircle;
  commonConstants: any = CommonConstants;

  postData: any = {};
  payment_mode = 'CASH';
  paymentRedirectionURL = '';
  merchantID: String = '';
  merchantIDsamllcase: String = '';
  buildUPIUrl: String = '';
  amountData:any ;
  public isActive: Boolean = false;
  pipelineStr: string = '';
  encodeStr: string = '';

  @Input() queryParmData: any;
  isCashModeEnable: Boolean = true;
  constructor(private router: Router, private commonService: CommonService,private activatedRoute: ActivatedRoute,private modalService: NgbModal) {
  }

  ngOnInit(): void {

    //console.log(this.activatedRoute.snapshot, "next current route");
    //console.log(">>>>>>>>>> In payment gateway>>>>>>>>>>>>>",this.queryParmData)
    this.isActive = false;
    
    ////console.log(window && window.location && window.location.hostname,"---->URL")
    if (
      /^vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {
      //for prod gateway
      this.paymentRedirectionURL =
        'https://app-vsws-payment-response-ci-prod.azurewebsites.net/ExternalPaymentRequest.aspx';
      this.merchantID = 'APGVWVVSWS';
      this.merchantIDsamllcase = 'apgvwvvsws';
      this.buildUPIUrl =
        'https://pgi.billdesk.com/pgidsk/PGIMerchantPayment?msg=';
    } else if (
      /^uat.vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {
      //for UAT gateway
      this.paymentRedirectionURL =
        'https://app-gsws-paymentresponse-ci-uat.azurewebsites.net/ExternalPaymentRequest.aspx';
      this.merchantID = 'BDSKUATY';
      this.merchantIDsamllcase = 'bdskuaty';
      this.buildUPIUrl =
        'https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=';
    } else {
      this.paymentRedirectionURL =
        'https://gsws-paymentresponse.azurewebsites.net/ExternalPaymentRequest.aspx';
      this.merchantID = 'BDSKUATY';
      this.merchantIDsamllcase = 'bdskuaty';
      this.buildUPIUrl =
        'https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=';
    }


      this.activatedRoute.queryParams.subscribe((params) => {
        if (params && Object.keys(params).length>0 && params.hasOwnProperty('currentURL')) {
          //console.log('>>>>> payemnt=========>>>>>>>', params);
          this.getResponseData(params);
        }
      });

   
  }

 /*  verfyAuthentication(){
    
    this.commonService.getRequest(this.commonConstants.verifyPaymentToken +"?"+ this.queryParmData)
    .subscribe({ next: (responseData: any) => {
          //console.log('getCheckSum_External===', responseData);
          
          if(responseData.result !== "Token Matching"){
                     
            const queryParams: Params = { mode: 'unauthorized'};
            this.router.navigate(['/external-payment-response'], {
              relativeTo: this.activatedRoute,
              queryParams: queryParams,
              queryParamsHandling: '',
            });
          }else{
            this.getTestAmount();
          }
        },
      });
  }
  getTestAmount(){

    let reqID = "?requestId=" + this.queryParmData.split("&")[1].replace("requestId=","");
    this.commonService.getRequest(this.commonConstants.getTestAmount + reqID)
    .subscribe({ next: (responseData: any) => {
          //console.log('getTestAmount===', responseData);
          this.amountData = responseData.result;
        },
      });
  } */


  /* getResponseData(data: any) {
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      //  //console.log('Object is empty');
    } else {
      //console.log('????Object is no empty', data);
          this.pipelineStr = data.msg;
          let inputDataStr = data.msg.split("|") ;
          this.amountData = data.msg.split("|") ;
        
    }
  } */

  getResponseData(data: any) {
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      //  //console.log('Object is empty');
    } else {
      //console.log('Object is no empty', data);
      this.externalAESDecryptMesevaRequest(data.msg);
    }
  }

  externalAESDecryptMesevaRequest(reqID: any) {
    let dataURI = reqID.replace(/%20/g, '%2B');
    dataURI = dataURI.replace(/\s/g, '+');
    this.encodeStr = dataURI;
    //console.log('dataURI', dataURI);
    let postdata = {
      EncryptedValue: dataURI,
    };
    
    
    this.commonService
      .postRequest(
        this.commonConstants.externalAESDecryptMesevaRequest,
        postdata
      )
      .subscribe({
        next: (responseData: any) => {
          //console.log('externalAESDecryptMesevaRequest===', responseData);
          
          if(responseData.result.isSuccess === false){
                     
            const queryParams: Params = { mode: 'unauthorized'};
            this.router.navigate(['/external-payment-response'], {
              relativeTo: this.activatedRoute,
              queryParams: queryParams,
              queryParamsHandling: '',
            });
          }else{
            let respdata = responseData.result.rawValue;
            this.pipelineStr =  respdata;
            this.getDistrictCode(this.pipelineStr.split("|")[13]);
            this.amountData = respdata;
            this.commonService.setLocalStorage('piplinnString', respdata);
            this.amountData = this.amountData.split("|")
            //console.log('externalAESDecryptMesevaRequest===',this.pipelineStr);
  
          }

         
        },
      });
  }


  selectPaymentMode(mode: any) {
    //console.log("mode==",mode)
    this.payment_mode = mode;
  }

  confirmPay() {
    if (this.payment_mode == 'CASH') {
      this.saveCashModePayment();
    } else {
      this.checkSumAPI();
    }
  }

  checkSumAPI() {

    this.isActive = false;
    let dataPipe:any = this.pipelineStr;
    dataPipe =  dataPipe.split("|"); 
    
   // dataPipe[0] = dataPipe[0] + 13;
    
    let pipeLineStr =   this.merchantID+'|T' + dataPipe[0] +'|NA|' +dataPipe[7] +'|NA|NA|NA|INR|NA|R|'+this.merchantIDsamllcase+'|NA|NA|F|UPI|' + dataPipe[7] + '|' + "external" +'|NA|NA|NA|NA|' +this.paymentRedirectionURL;

    this.postData = {
      requestId: dataPipe[0],
      requestType: dataPipe[1],
      department: dataPipe[2],
      transactionId: 'T' + dataPipe[0],
      transactionMode: 'UPI',
      piplineString: pipeLineStr,
      userId: dataPipe[8],
      customerName: dataPipe[9],
      agentName :dataPipe[10],
      requestFromCustomer: 'external',
      agentId: dataPipe[11],
      userCharges:dataPipe[3],
      serviceCharges:dataPipe[4],
      statutaryCharges :dataPipe[5],
      deliveryCharges :dataPipe[6],
      transactionAmount: dataPipe[7],
      requestSubType: dataPipe[12],
      secretariatId: dataPipe[13],
      serviceName: dataPipe[15],
    };

    this.commonService.postRequest(this.commonConstants.getCheckSum_External, this.postData)
    .subscribe({ next: (responseData: any) => {
          //console.log('getCheckSum_External===', responseData);
          
          if(responseData.result === "Duplicate Request"){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'This Transaction is alreday exists in Database..!!! Try with new transaction';
          }else{
             let buildDeskURL =  this.buildUPIUrl + responseData.result ;
             //console.log(buildDeskURL, '>> buildDeskURL');
             window.location.href = buildDeskURL;
          }
        },
      });
  }

  saveCashModePayment(){

    let dataPipe:any = this.pipelineStr;
    dataPipe =  dataPipe.split("|"); 

    let pipeLineStr =   this.merchantID+'|T' + dataPipe[0] +'|NA|' +dataPipe[7] +'|NA|NA|NA|INR|NA|R|'+this.merchantIDsamllcase+'|NA|NA|F|UPI|' + dataPipe[7] + '|' + "external" +'|'+'external'+'|NA|NA|NA|' +this.paymentRedirectionURL;

     let postData = {
      requestId: dataPipe[0],
      requestType: dataPipe[1],
      department: dataPipe[2],
      transactionId: 'T' + dataPipe[0],
      transactionAmount: dataPipe[7],
      transactionMode: 'CASH',
      transactionStatus: '0300',
      piplineString: pipeLineStr,
      userId: dataPipe[8],
      customerName: dataPipe[9],
      UserCharges:dataPipe[3],
      ServiceCharges:dataPipe[4],
      StatutaryCharges :dataPipe[5],
      DeliveryCharges :dataPipe[6],
      agentId: dataPipe[11],
      agentName :dataPipe[10],
      requestSubType: dataPipe[12],
      secretariatId: dataPipe[13],
      serviceName: dataPipe[15],
    };

    this.commonService
      .postRequest(this.commonConstants.saveBillDeskResponse_External, postData)
      .subscribe({
        next: (responseData: any) => {

          //console.log('saveBillDeskResponse_External===', responseData);
          if(responseData.result === "Duplicate Request"){
          
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'This Transaction is alreday exists in Database..!!! Try with new transaction';
                  }else{
            this.paymentResponse(dataPipe[0]);
          }
        },
      });
  }
   
  paymentResponse(transactionID: any) {
    const queryParams: Params = { id: transactionID,mode: 'Cash'};
    this.router.navigate(['/external-payment-response'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: '',
    });
  }
  getDistrictCode(secreteriateCode:any) {
    this.commonService.getRequest(this.commonConstants.getDistCodeBySecCode + secreteriateCode).subscribe({
      next: (responseData:any) => { 
        this.isCashModeEnable = (responseData.result?.neW_LGD_DIST_CODE == 503 ? false : true); 
        if(!this.isCashModeEnable) { 
          this.payment_mode = 'UPI'
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

}
