import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-aarogya-suraksha-camp-details',
  templateUrl: './aarogya-suraksha-camp-details.component.html',
  styleUrls: ['./aarogya-suraksha-camp-details.component.css'],
})
export class AarogyaSurakshaCampDetailsComponent implements OnInit {
  form: UntypedFormGroup;
  commonConstants: any = CommonConstants;
  districts: any[] = [];
  mandals: any[] = [];
  vhcs: any[] = [];
  secretariats: any[] = [];
  surakshaCampDetails: any;
  submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      district: [null, Validators.required],
      mandal: [null, Validators.required],
      vhc: [],
      secretariat: []
    });
    this.getDistricts();
  }

  ngOnInit(): void {}

  get f() {
    return this.form.controls;
  }

  getDistricts() {
    this.commonService
      .getRequest(this.commonConstants.getJASDistricts)
      .subscribe({
        next: (responseData: any) => {
          if (responseData.result?.isSuccess) {
            this.districts = this.commonService.sortItems(
              responseData.result?.getDistrictDetails,
              'districtName'
            );
          } else {
            this.districts = [];
            this.commonService.commonErrorPopup(
              'Alert',
              responseData.result?.error,
              'md'
            );
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  onDistrictChange() {
    this.mandals = [];
    this.vhcs = [];
    this.secretariats = [];
    this.f.mandal.patchValue(null);
    this.f.vhc.patchValue(null);
    this.f.secretariat.patchValue(null);
    this.f.mandal.clearValidators();
    this.f.mandal.updateValueAndValidity();
    this.clearCampDetailsData();
    this.commonService
      .getRequest(
        this.commonConstants.getJASMandalsRuralUrban +
          '?DistrictCode=' +
          this.f['district'].value.districtCode +
          '&RuralOrUrbanFlag=' +
          'all'
      )
      .subscribe({
        next: (responseData: any) => {
          if (responseData.result?.isSuccess) {
            this.mandals = this.commonService.sortItems(
              responseData.result?.getMandalDetails,
              'mandalName'
            );
          } else {
            this.mandals = [];
            this.commonService.commonErrorPopup(
              'Alert',
              responseData.result?.error,
              'md'
            );
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }


  onMandalChange() {
    this.vhcs = [];
    this.secretariats = [];
    this.f.vhc.patchValue(null);
    this.f.secretariat.patchValue(null);
    this.clearCampDetailsData();
    this.commonService
      .getRequest(
        this.commonConstants.getJASVHCSecretariat +
          '?MandalCode=' +
          this.f['mandal'].value.mandalCode
      )
      .subscribe({
        next: (responseData: any) => {
          if (responseData.result?.isSuccess) {
            this.vhcs = this.commonService.sortItems(
              responseData.result?.getVHCDetails,
              'vhcName'
            );
            this.secretariats = this.commonService.sortItems(
              responseData.result?.getSecretariatDetails,
              'secretariatName'
            );
          } else {
            this.vhcs = [];
            this.secretariats = [];
            this.commonService.commonErrorPopup(
              'Alert',
              responseData.result?.error,
              'md'
            );
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  clearCampDetailsData() {
    this.submitted = false;
    this.surakshaCampDetails = null;
  }
 
  getAarogyaSurakshaCampDetails() {
    this.submitted = true;
    this.f.district.setValidators([Validators.required]);
    this.f.district.updateValueAndValidity();
    this.f.mandal.setValidators([Validators.required]);
    this.f.mandal.updateValueAndValidity();
    if (this.f.district.value !== null && this.f.mandal.value !== null) {
      if (this.f.vhc.value == null && this.f.secretariat.value == null) {
        this.commonService.commonErrorPopup("Alert", 'Please select VHC or Secretariat', 'md')
      } else {
        let queryParams = "";
        if (this.f.vhc.value != null && this.f.secretariat.value != null) {
          queryParams = '?VHCCode=' + this.f.vhc.value.vhcCode + '&SecretariatCode=' + this.f.secretariat.value.secretariatCode;
        } else if (this.f.vhc.value == null && this.f.secretariat.value != null) {
          queryParams = '?SecretariatCode=' + this.f.secretariat.value.secretariatCode;
        } else if (this.f.vhc.value !== null && this.f.secretariat.value == null) {
          queryParams = '?VHCCode=' + this.f.vhc.value.vhcCode;
        }
        this.commonService
          .getRequest(
            this.commonConstants.getSurakshaCampDetails + queryParams
          )
          .subscribe({
            next: (responseData: any) => {
              this.surakshaCampDetails = responseData.result;
            },
            error: (error) => {
              console.log('client side', error);
            },
          });
      }
    }
  }
}
