<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
        <option value = "All">All</option>
        <option value = "R">Rural</option>
        <option value = "U">Urban</option>
        </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
    </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="village" (change)="onVillageChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
      </select>
    </div>
</div>
    
      <div class="tabClass">
        <ul>
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">GSWS Dynamic Survey Portal</th>
            </tr>
            <tr>
               <th rowspan="2">S.No</th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'district_name'">District <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandal_name'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Designation Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'designation'">Designation <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rural_urban'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariat_name'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'total_secretariats'">Total No of Secretariats <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2"  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'total_designations'">Total No of Designations<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2"  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pending_secretariats'">Pending No of Secretariats<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2"  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pending_designations'">Pending No of Designations<mdb-icon fas icon="sort"></mdb-icon></th>
               <th [attr.colspan]="format === 'Secretariat Wise' ? 4 : 5">Survey Completed</th>
            </tr>
            <tr>
               <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'surveyed_secretariats'">No of Secretariats<mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'surveyed_designations'">No of Designations<mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'regular_designations'">Regular<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'incharge_designations'">Incharge<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vacant_designations'">Vacant<mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
               <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
               <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.district_name}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandal_name}} </td>
               <td *ngIf="format === 'Designation Wise'">{{item.designation}}</td>
               <td  *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" >{{item.rural_urban === 'R' ? 'Rural' : 'Urban'}}</td>
               <td  *ngIf="format === 'Secretariat Wise'"><a (click) ="getSecretariatReport(item, secretariatModal)" class="linkCls">{{item.secretariat_name}}</a></td>
               <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'" >{{item.total_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.total_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{item.pending_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.pending_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{item.surveyed_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.surveyed_designations}}</td>
               <td>{{item.regular_designations}}</td>
               <td>{{item.incharge_designations}}</td>
               <td>{{item.vacant_designations}}</td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].district_name}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandal_name}} </td>
               <td *ngIf="format === 'Designation Wise'">{{totalItems[0].designation}}</td>
               <td  *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" ></td>
               <td  *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariat_name}}</td>
               <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'" >{{totalItems[0].total_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].total_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{totalItems[0].pending_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].pending_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{totalItems[0].surveyed_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].surveyed_designations}}</td>
               <td>{{totalItems[0].regular_designations}}</td>
               <td>{{totalItems[0].incharge_designations}}</td>
               <td>{{totalItems[0].vacant_designations}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<ng-template #secretariatModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">GSWS Dynamic Survey Report</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="table-responsive tableFixHead">
            <table class="tableCls" >
                <thead> 
                    <tr>
                        <th>S.No</th>
                        <th *ngFor="let head of headerCol; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="headerCol[i].colId">{{ head.colName }}
                            <mdb-icon fas icon="sort"></mdb-icon>
                          </th>
                    </tr>
                </thead>
        
                <tbody *ngIf="popupItems && popupItems.length > 0">
                    <tr *ngFor="let item of popupItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                        <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                        <td>{{item.district_name}}</td>
                       <td>{{item.mandal_name}}</td>
                       <td>{{item.rural_urban === 'R' ? 'Rural' : 'Urban'}}</td>
                        <td>{{item.secretariat_name}}</td>
                        <td>{{item.designation}}</td>
                        <td>{{item.role_type}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="popupItems && popupItems.length === 0">
                    <tr>
                        <td colspan="7" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="popupItems.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="popupItems.length > 0"    [collectionSize]="popupItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  name="close" class="greenBtnCls" *ngIf="popupItems && popupItems.length > 0" (click)="downloadExcelForSecretariat()">Download Excel</button>
    </div>
   </ng-template>
   <div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">GSWS Dynamic Survey Portal</th>
            </tr>
            <tr>
               <th rowspan="2">S.No</th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">District </th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal</th>
               <th rowspan="2" *ngIf="format === 'Designation Wise'">Designation </th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" >Rural/Urban </th>
               <th rowspan="2" *ngIf="format === 'Secretariat Wise'">Secretariat</th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">Total No of Secretariats</th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">Total No of Designations</th>
               <th rowspan="2"  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">Pending No of Secretariats</th>
               <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" rowspan="2">Pending No of Designations</th>
               <th [attr.colspan]="format === 'Secretariat Wise' ? 4 : 5">Survey Completed</th>
            </tr>
            <tr>
               <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">No of Secretariats</th>
               <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">No of Designations</th>
               <th>Regular</th>
               <th>Incharge</th>
               <th >Vacant</th>
            </tr>
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i= index">
               <td>{{i + 1}}</td>
               <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.district_name}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandal_name}} </td>
               <td *ngIf="format === 'Designation Wise'">{{item.designation}}</td>
               <td  *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" >{{item.rural_urban === 'R' ? 'Rural' : 'Urban'}}</td>
               <td  *ngIf="format === 'Secretariat Wise'"><a (click) ="getSecretariatReport(item, secretariatModal)" class="linkCls">{{item.secretariat_name}}</a></td>
               <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'" >{{item.total_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.total_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{item.pending_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.pending_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{item.surveyed_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.surveyed_designations}}</td>
               <td>{{item.regular_designations}}</td>
               <td>{{item.incharge_designations}}</td>
               <td>{{item.vacant_designations}}</td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].district_name}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandal_name}} </td>
               <td *ngIf="format === 'Designation Wise'">{{totalItems[0].designation}}</td>
               <td  *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" ></td>
               <td  *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariat_name}}</td>
               <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'" >{{totalItems[0].total_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].total_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{totalItems[0].pending_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].pending_designations}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Designation Wise'">{{totalItems[0].surveyed_secretariats}}</td>
               <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].surveyed_designations}}</td>
               <td>{{totalItems[0].regular_designations}}</td>
               <td>{{totalItems[0].incharge_designations}}</td>
               <td>{{totalItems[0].vacant_designations}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
 </div>
 <div class="table-responsive tableFixHead" #SECRETARIATTABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th>S.No</th>
                <th *ngFor="let head of headerCol;">{{ head.colName }}</th>
            </tr>
        </thead>

        <tbody *ngIf="popupItems && popupItems.length > 0">
            <tr *ngFor="let item of popupItems;let i = index;">;
                <td>{{ i + 1}}</td>
                <td>{{item.district_name}}</td>
               <td>{{item.mandal_name}}</td>
               <td>{{item.rural_urban === 'R' ? 'Rural' : 'Urban'}}</td>
                <td>{{item.secretariat_name}}</td>
                <td>{{item.designation}}</td>
                <td>{{item.role_type}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="popupItems && popupItems.length === 0">
            <tr>
                <td colspan="7" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
 </div>