<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Revenue Department <span class="activePage">/ View Transactions</span>
        </div>
    </div>
    <div  *ngIf="role === 'Zilla Sainik Welfare Officer'" class="btnHolder mb-3">
        <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Go to Home</button></div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3 text-center"><b>VIEW TRANSACTIONS </b></h6>
        <form [formGroup]="ViewTransactions">

            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-3">Search Criteria 1</div>
            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">From Date :
                        <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="d" ngbDatepicker
                            [minDate]="FromminDate" [maxDate]="FrommaxDate" #d="ngbDatepicker" required
                            formControlName="periodFrom"
                            [ngClass]="{ 'is-invalid': submitted && f.periodFrom.errors }" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.periodFrom.errors" class="selectError">
                        <div *ngIf="
                          f.periodFrom.errors?.required || f.periodFrom.invalid
                        ">
                            Please enter valid period From
                        </div>
                    </div>
                </div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">To Date :
                        <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="e" ngbDatepicker
                            [minDate]="TominDate" [maxDate]="TomaxDate" #e="ngbDatepicker" required
                            formControlName="periodTo" [ngClass]="{ 'is-invalid': submitted && f.periodTo.errors }" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="e.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.periodTo.errors" class="selectError">
                        <div *ngIf="
                          f.periodTo.errors?.required || f.periodTo.invalid
                        ">
                            Please enter valid period To
                        </div>
                    </div>
                </div>

             

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">

                    <label class="labelCls">Service Name:</label>
                    <select class="selectCls" formControlName="service_name" required
                        [ngClass]="{ 'is-invalid':submitted && (f.service_name.errors || f.service_name.value === 'Select') }">
                        <!--  <option [selected]=true value="Select" disabled>Select</option> -->
                        <option value="all">All</option>
                        <option value="{{service.serviceCode}}" *ngFor="let service of serviceList ; let i = index">
                            {{ service.serviceName }}</option>
                      
                    </select>
                    <div *ngIf="submitted && (f.service_name.errors || f.service_name.value === 'Select')"
                        class="selectError">
                        <div *ngIf="f.service_name.errors?.required || f.service_name.value === 'Select'">Please
                            select service_name</div>
                    </div>
                </div>



                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">

                    <label class="labelCls">Department Name:</label>
                    <select class="selectCls" formControlName="department_name" required
                        [ngClass]="{ 'is-invalid':submitted && (f.department_name.errors || f.department_name.value === 'Select') }">
                        <option value="all">All</option>
                        <option value="RV">Revenue Department</option>
                        <option value="CS">Civil Supplies Department</option>
                       <!--  <option value="MAUD">MAUD Department</option> -->
                        <option value="RD">Pensions Department</option>
                        <option value="HF">Health and Family Welfare Department</option>
                        <option value="RS">PR & MAUD</option>

                    </select>
                    <div *ngIf="submitted && (f.department_name.errors || f.department_name.value === 'Select')"
                        class="selectError">
                        <div *ngIf="f.department_name.errors?.required || f.department_name.value === 'Select'">Please
                            select department name</div>
                    </div>
                </div>

            </div>



            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-3">OR Search Criteria 2</div> 

            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">User Name : </label>
                </div>
                <div class="col col-lg-3 col-md-3 vcol-sm-12 col-xs-12">
                    <label class="labelCls">{{displayUserName}} </label>
                </div>
            </div>

            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                   <!--  <label class="labelCls">Transaction ID/ Application Number : </label> -->
                    <label class="labelCls">Transaction Id / Requisition Id: </label>
                </div>
                <div class="col col-lg-3 col-md-3 vcol-sm-12 col-xs-12">
                    <input type="text" name="transactionID" class="inputCls" formControlName="transactionID">
                    <div *ngIf="submitted && f.transactionID.errors" class="selectError">
                        <div *ngIf="f.transactionID.errors?.required || f.transactionID.value === 'Select'">Please
                            Enter transaction id</div>
                    </div>
                </div>

            </div>

            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-3">OR Search Criteria 3</div>

            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Token No</label>
                </div>
                <div class="col col-lg-3 col-md-3 vcol-sm-12 col-xs-12">
                    <input type="text" name="TokenNumber" class="inputCls" formControlName="TokenNumber">
                    <div *ngIf="submitted && f.TokenNumber.errors" class="selectError">
                        <div *ngIf="f.TokenNumber.errors?.required || f.TokenNumber.value === 'Select'">Please
                            Enter Token No</div>
                    </div>
                </div>

            </div>


            <hr class="hrCls mt-4">
            <div class="row mb-3 ">
                <div class="btnHolder text-center col-12">
                    <button class="btn btn-primary mx-auto d-block" (click)="submit()">View Transactions</button>
                </div>
            </div>
        </form>

        <div *ngIf="showDetails">
            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-4 ol col-lg-12 col-md-12">TRANSACTION LIST
                <button class="btn btnB btn-primary pull-right btnHide tabMargin" (click)="printData('printData')" *ngIf="items && items.length > 0">Print </button> 
                <input type="text" placeholder="Search Transaction" class="searchTrans pull-right" [(ngModel)]="transactionFilterID" (keyup)="searchTransaction()">
               
            </div>

          

            <div class="row" id="printData">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
     
                        <div class="table-responsive tableFixHead" *ngIf="items && items.length > 0">
         
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th>Serial<br>No</th>
                                    <th>Transaction ID</th>
                                    <th>Token No</th>
                                    <th>Service Name</th>
                                    <th class="widthTD">Citizen Name</th>
                                    <th>Transaction Date</th>
                                    <th>Payment<br> Mode</th>
                                    <th>Amount</th>
                                    <th class="btnHide">Status</th>
                                    <!-- <th class="btnHide">Uploaded Documents</th> -->
                                    <th class="btnHide">Application<br>Preview</th>
                                    <!-- <th class="btnHide">Transaction From</th> -->
                                </tr>
                            </thead>

                            <tbody *ngIf="items && items.length !==0">
                            <!-- Old Code with 5 API s -->


                            <!--     <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize; let i = index;"> -->
                          
                          
                                <!-- Running Code  with single API-->
                                <tr *ngFor="let item of items; let i = index;">
                                    <td>{{ ((page -1) * pageSize) + (i + 1)}} </td>
                                    <td> 
                                        <a *ngIf="item.transactionCategory=='same' || item.transactionCategory=='B2C'" class="textDcor"
                                            (click)="transactionStatus(item?.requisitionId,item?.requisitionType,item?.departmentID,item?.transactionID);">
                                            {{item?.transactionID }}
                                        </a>
                                         
                                            <p *ngIf="item.transactionCategory=='other' || item.source=='CSCAadhaar'" class="mb-0"> {{
                                            item?.transactionID }} </p> 

                                         

                                         
                                            <p *ngIf="item.transactionCategory == 'OLD GSWS'" class="mb-0"> {{
                                            item?.transactionID }}  ( {{item?.dept_App_No}} ) </p> 

                                            
                                         </td>
                                    <td> {{item.tokenNo ? item.tokenNo:'-'}}</td>
                                    <td> {{item?.serviceName}}
                                        <!-- {{item.serviceName === '' ?  item?.requisitionType  : item?.serviceName}} -->
                                    </td>
                                    <td  class="widthTD">{{ item?.applicantName ? item.applicantName: '-' }}</td>
                                    <td>{{ item?.transactionDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                    
                                    <td>{{ item?.transactionMode }}</td>
                                    
                                    <td>{{ item?.transactionAmount }}</td>
                                   
                                  
                                    <td class="btnHide" *ngIf="item.transactionCategory=='same' || item.transactionCategory=='B2C'">
                                        <!-- <p *ngIf="item?.departmentID ==='MAUD'"> NA </p> -->
                                        <a class="textDcor"
                                            (click)="transactionStatusPopup(showTransaction, item?.requisitionId,item?.requisitionType, item?.departmentID,item?.transactionID)">View
                                            Status</a>
                                    </td>
                                    
                                    <td class="btnHide" *ngIf="item.transactionCategory !== 'same' && item.transactionCategory !=='B2C'"> - </td>
                                    <!-- <td class ="align" *ngIf="(item.transactionCategory=='other' || item.transactionCategory == 'OLD GSWS') && (item?.departmentID !=='ESeva')">
                                        {{ item?.transactionStatus }}
                                    </td> -->
                                    
                                  <!--   <td class="btnHide">
                                        <a *ngIf="item.transactionCategory=='same'" class="textDcor"
                                            (click)="downloadDocuments(item?.requisitionId,item?.departmentID,item?.requisitionType)">Download
                                            Documents</a>
                                            <p *ngIf="item.transactionCategory=='other'" class="mb-0"> NA </p>  
                                    </td> -->
                                    <!-- <td class="btnHide" *ngIf="item?.departmentID ==='ESeva'"> - </td>
                                    <td class="btnHide" *ngIf="item?.departmentID !=='ESeva'"> -->
                                        <!-- <p *ngIf="item?.departmentID ==='MAUD'"> NA </p> -->
                                     <td>   <a *ngIf="item.transactionCategory=='same'" class="textDcor"
                                            (click)="viewPreview(item?.requisitionId,item?.departmentID,item?.requisitionType)">Preview</a>
                                            <p *ngIf="item.transactionCategory !=='same'" class="mb-0"> NA </p>  
                                    </td>
                                    <!-- <td> {{ item?.transactionFrom}}</td> -->
                                </tr>

                            </tbody>

                            <!--  <tbody  *ngIf="items && items.length ===0" class="hrCls mt-2">
                                <tr class="text-center selectError">
                                    No records available...
                                </tr>
                            </tbody> -->
                        </table>

                    <!-- Old Code  With 5 API s-->


                        <!-- <div class="mt-4 btnHide">
                            <div class="float-left" *ngIf="items.length > 0">
                                <label>Requests per page: </label>
                                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                                    <option  value="10">10</option>
                                    <option  value="20">20</option>
                                    <option  value="30">30</option>
                                    <option  value="40">40</option>
                                    <option  value="50">50</option>
                                </select> 
                            </div>
                            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination>
                        </div>
                    </div>


                    <div class="text-center selectError clsSize"
                        *ngIf="items && items.length ===0">

                        No records available...
                    </div> -->


                        <!-- Running Code  with single API-->

                        <div class="mt-4 btnHide">
                            <div class="float-left"*ngIf="totalPageCount > 0" >
                                <label>Requests per page: </label>
                                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize" (change)="pageChanged($event)">
                                    <option  value="10">10</option>
                                    <option  value="20">20</option>
                                    <option  value="30">30</option>
                                    <option  value="40">40</option>
                                    <option  value="50">50</option>
                                </select> 
                            </div>
                            <ngb-pagination class="d-flex justify-content-end"  *ngIf="totalPageCount > 0" [collectionSize]="totalPageCount"  [(page)]="page" [pageSize]="pageSize"
                                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged($event)">
                            </ngb-pagination>
                          
                        </div>
                    </div>


                    <div class="text-center selectError clsSize"
                        *ngIf="totalPageCount == 0">

                        No records available...
                    </div>




                </div>
            </div>
        </div>
    </div>
</div>



<!-- Transaction Popup -->
<ng-template #showTransaction let-modal class="modalCls btnHide">

    <div class="modal-header">
        <div class="">
            <img src="../../assets/images/logo_official.png" class="d-block mx-auto" width="10%">
            <div class="subHeadingCls text-center"><b>Transaction Status</b></div>

            <div class="text-right">
                <label class="labelCls note_txt"><b>Date :</b> {{ dateTime | date: 'dd/MM/yyyy'}} {{ dateTime | date:
                    'h:mm:ss a'}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>

            </div>
        </div>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span >×</span>
        </button>
    </div>

    <div class="modal-body">

        <div class="mainPageContainer mr-3 noborder">
            <div>
                <div class="row">

                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>Secretariat Name :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{(this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva') ? 'NA': secretariatName}}</label>
                    </div>

                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>User ID :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <!--   <label class="labelCls">{{userInfo.userPrincipalName}}</label> -->
                      <label class="labelCls">{{ requisitionInfo?.userName }}</label>
                    </div>


                </div>

                <div class="row">


                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>Service Name :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !=='RD'">
                        <label class="labelCls" style="word-break: break-word;">{{ serviceName }}</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@1' || this.requisitionInfo.serviceCode ==='Pensions@V1')">
                        <label class="labelCls" style="word-break: break-word;">Pensions O.A.P</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@2' || this.requisitionInfo.serviceCode ==='Pensions@V2' || this.requisitionInfo.serviceCode ==='Pensions-NEW@2' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V2')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Weavers</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@3' || this.requisitionInfo.serviceCode ==='Pensions@V3')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Disabled</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@4' || this.requisitionInfo.serviceCode ==='Pensions@V4')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Widow</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@5' || this.requisitionInfo.serviceCode ==='Pensions@V5')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Toddy Tappers</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@6' || this.requisitionInfo.serviceCode ==='Pensions@V6')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Transgender</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@7' || this.requisitionInfo.serviceCode ==='Pensions@V7')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Fishermen</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@8' || this.requisitionInfo.serviceCode ==='Pensions@V8')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Single Women</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@9' || this.requisitionInfo.serviceCode ==='Pensions@V9' || this.requisitionInfo.serviceCode ==='Pensions-NEW@9' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V9')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Traditional Cobblers</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"
                        *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@10' || this.requisitionInfo.serviceCode ==='Pensions@V10' || this.requisitionInfo.serviceCode ==='Pensions-NEW@10' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V10')">
                        <label class="labelCls" style="word-break: break-word;">Pensions Dappu Artists</label>
                    </div>


                  
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>Department Name:</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls"> {{departmentName }} Department </label>
                    </div>

                </div>



                <div class="row">


                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b> Applicant Name : </b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ applicantName }} </label>
                    </div>

                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>Father/Husband Name: </b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ applicantFatherName }}</label>
                    </div>


                </div>


                <div class="row">


                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b> Application Number : </b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ requisitionID}} </label>
                    </div>

                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b> Transaction ID / Requisition ID : </b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ transactionID }}</label>
                    </div>


                </div>


                <div class="row">


                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b> District :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{district }}</label>
                    </div>

                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b> Mandal:</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{mandal }}</label>
                    </div>


                </div>


                <div class="row" *ngIf="this.departmentCode !== 'RD'">


                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b> Amount Paid : </b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ totalAmount }} /-</label>
                    </div>

                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>Amount In Words :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls"> {{ amountInwords }}</label>
                    </div>


                </div>


                <div class="row">


                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !== 'RD'">
                        <label class="labelCls lableHead"><b>Payment Mode :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !== 'RD'">
                        <label class="labelCls">{{paymentMode}}</label>
                    </div>

                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>SLA :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ SLA }}</label>
                    </div>


                 
                </div>

                <div class="row">



                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls lableHead"><b>Transaction Status :</b></label>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <label class="labelCls"> {{ transactionStatusData }} </label>
                    </div>
                  
                </div>
          </div>
      </div>

    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
<!-- Transaction Popup end -->