import { Component, OnInit } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal,NgbNavConfig} from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-unveiling-of-welfare-schemes-display',
  templateUrl: './unveiling-of-welfare-schemes-display.component.html',
  styleUrls: ['./unveiling-of-welfare-schemes-display.component.css'],
  providers: [ NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class UnveilingOfWelfareSchemesDisplayComponent implements OnInit {
  currentDate: any= new Date();
  Form: UntypedFormGroup;
  commonConstants : any = CommonConstants;
  maxDate: any;
  minDate: any; 
  programPhoto: any;
  showForm:Boolean=false;
  submitted: Boolean = false;
  isSelectedYes: Boolean = false;
  campStartPhotoLabelValue = "";
  campStartImg: any;
  programPhotoBlobRef: any;
  scheduleDetails: any = [];
  showProgramDetails: Boolean = false;
  constructor(private commonService: CommonService,private router: Router, private formBuilder: UntypedFormBuilder,private datePipe: DatePipe,  private modalService: NgbModal) {
    this.Form = this.formBuilder.group({
      village: [null,[Validators.required]],
      programStarted:[null,[Validators.required]],
      membersAttended:[null,[Validators.required]],
      nameOfGuest:[null,[Validators.required,Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      programPhoto: [null, [Validators.required]],
      dateOfCommencement:[null,[Validators.required]],
      reason:[null,[Validators.required]],
  })
  this.maxDate = {
    year: this.currentDate.getFullYear() + 1,
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  };
  this.minDate = {
    year: this.currentDate.getFullYear(),
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate() + 1
  };
  this.getScheduleDetails();
}
ngOnInit(): void {
}
viewImgFile(blobRef: any) {
  this.commonService
    .getRequest(this.commonConstants.viewFile + blobRef)
    .subscribe({
      next: (responseData: any) => {
        let fileInfo = responseData.result.fileContents;
         const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
         modalRef.componentInstance.isImage = true;
        modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
}
get f() {
  return this.Form.controls;
}
getScheduleDetails() {
  this.showProgramDetails = false;
  let mandalCode: any = this.commonService.getLocalStorage('locationCode');
  this.commonService.getRequest(this.commonConstants.getWelfareSchemeDispalyDetails + mandalCode).subscribe({
    next: (responseData: any) => {
      if(responseData.result?.isSuccess) {
        this.scheduleDetails = responseData.result?.welfareSchemeData;
        this.scheduleDetails =this.commonService.sortItems(this.scheduleDetails.filter((item: any) => item.welfareSchemeDisplayDtls?.length == 0 ||  (item.welfareSchemeDisplayDtls?.length > 0 && item.welfareSchemeDisplayDtls.findIndex((el: any) => el.isProgramStarted === 'Yes') === -1)), 'secretariatName' );
      } else {
        this.scheduleDetails = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
      }
    },
    error: (error) => {
      this.scheduleDetails = [];
      console.log('client side', error);
    },
  });
}
onChangeSecretariat() {
  this.showProgramDetails = false;
  let selectedSecretariat = this.f.village.value;
  let scheduleDate: any = new Date(selectedSecretariat.scheduleDate);
  let currentDate: any = new Date();
  if(scheduleDate > currentDate) {
    this.showProgramDetails = false;
    this.commonService.commonErrorPopup('Alert', 'You can\'t enter the details as schedule date is future date for the selected secretariat.', 'md');
  } else {
    this.showProgramDetails = true;
  }
}

fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
  const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
  this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
    next: (responseData:any) => {
      if(typeof(responseData.result) !== 'string'){
        if(label === 'campStartPhotoLabel')  {
          this.programPhotoBlobRef = responseData.result.blobRef;
        }    
      }else{
        if(name && label) {
          this.clearCampStartPhotoUpload(name, label);
        }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
      }
    },
    error: (error) => {
      if(name && label) {
        this.clearCampStartPhotoUpload(name, label);
      }
      console.log('client side', error);
    }
  });
}
goToHome() {
  this.router.navigate(['/home']);
}
submit(): any {
  this.submitted = true;
  if(!this.Form.valid)
    return false;
  let programDetails: any = this.f.village.value.welfareSchemeDisplayDtls;
  let alreadyNoSelected : any = (programDetails.findIndex((item: any) => item.isProgramStarted === 'No') > -1) ? true : false;
  if(alreadyNoSelected && this.f.programStarted.value === 'No') {
    this.commonService.commonErrorPopup('Alert', 'Already Program Started is saved with no.', 'md');
    return false;
  }
  let postData: any = {};
let dateFormat = this.f.programStarted.value === 'No' ? this.commonService.formatDate(this.f.dateOfCommencement.value): null;
postData.isProgramStarted = this.f.programStarted.value
postData.membersAttendedCnt= this.f.programStarted.value === 'Yes' ? this.f.membersAttended.value: '';
postData.chiefGuestName = this.f.programStarted.value === 'Yes' ? this.f.nameOfGuest.value.toString(): '';
postData.programPhoto = this.f.programStarted.value === 'Yes' ? this.programPhotoBlobRef: '';
postData.reason = this.f.programStarted.value === 'No' ? this.f.reason.value.toString(): '';
postData.tentativeScheduleDate=  this.f.programStarted.value === 'No' ? dateFormat: null;
postData.secretariatCode = this.f.village.value.secretariatCode;
postData.secretariatName = this.f.village.value.secretariatName;
postData.scheduleDate = this.f.village.value.scheduleDate;
this.commonService.postRequest(this.commonConstants.saveWelfareDetails, postData).subscribe({
  next: (responseData: any) => {
    if(responseData.result?.isSuccess) {
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =  'The team details are saved successfully.';
      modalRef.result.then((result: any) => {
        if (result) {
          this.Form.reset();
          this.campStartPhotoLabelValue = 'No File Choosen';
          this.submitted = false;
          this.getScheduleDetails();
        }
      });
    } else {
      this.commonService.commonErrorPopup('Alert', 'An error occured while saving the data. Please try again.', 'md')
    }
  },
  error: (error) => {
    console.log('client side', error);
  },
});
}
keyPressAlpha(event: any){
  var inp = String.fromCharCode(event.keyCode);

  if (/[a-z A-Z.]/.test(inp)) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
}
onChange(){
  if(this.f.programStarted.value === 'Yes') {
    this.isSelectedYes=true;
    this.Form.controls['programStarted'].setValidators([Validators.required]);
    this.Form.controls['programStarted'].updateValueAndValidity();  
    this.Form.controls['membersAttended'].setValidators([Validators.required]);
    this.Form.controls['membersAttended'].updateValueAndValidity();  
    this.Form.controls['nameOfGuest'].setValidators([Validators.required]);
    this.Form.controls['nameOfGuest'].updateValueAndValidity();
    this.Form.controls['programPhoto'].setValidators([Validators.required]);
    this.Form.controls['programPhoto'].updateValueAndValidity();  
    this.Form.controls['dateOfCommencement'].clearValidators();
    this.Form.controls['dateOfCommencement'].updateValueAndValidity();  
    this.Form.controls['reason'].clearValidators();
    this.Form.controls['reason'].updateValueAndValidity();  
    this.Form.controls['reason'].reset();
    this.Form.controls['dateOfCommencement'].reset();
  } else {
     this.isSelectedYes=false;
     this.Form.controls['programStarted'].clearValidators();
      this.Form.controls['programStarted'].updateValueAndValidity();
      this.Form.controls['membersAttended'].clearValidators();
      this.Form.controls['membersAttended'].updateValueAndValidity();
      this.Form.controls['nameOfGuest'].clearValidators();
      this.Form.controls['nameOfGuest'].updateValueAndValidity();
      this.Form.controls['programPhoto'].clearValidators();
      this.Form.controls['programPhoto'].updateValueAndValidity();
      this.Form.controls['dateOfCommencement'].setValidators([Validators.required]);
      this.Form.controls['dateOfCommencement'].updateValueAndValidity();
      this.Form.controls['reason'].setValidators([Validators.required]);
      this.Form.controls['reason'].updateValueAndValidity();
      this.Form.controls['nameOfGuest'].reset();
      this.Form.controls['membersAttended'].reset();
      this.Form.controls['programPhoto'].reset();
  }
}
onCampStartPhotoSelected(fileEvent: any) {
  const campStartFile: File | null = fileEvent.target.files[0];
  this.campStartPhotoLabelValue = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
  this.f.programPhoto.setValidators([Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)])
  this.f.programPhoto.updateValueAndValidity();
  if (this.f.programPhoto.valid){
    this.fileUpload(campStartFile,this.f.programPhoto.value,fileEvent.target.files[0].name,'programPhoto', 'programPhoto', 'campStartPhotoLabel');
  }
}

clearCampStartPhotoUpload(name:string, label:string) {
  if (label === 'campStartPhotoLabel') {
    this.f[name].reset();
    this.campStartPhotoLabelValue = 'No File Choosen';
    this.Form.controls[name].setValidators([Validators.required]);
    this.Form.controls[name].updateValueAndValidity();
  } 
  else{
    this.Form.controls[name].clearValidators();
    this.Form.controls[name].updateValueAndValidity();
  }
}
}

