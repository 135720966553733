import { Component, OnInit } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { Router } from '@angular/router';
import { AadharValidator, CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from 'src/shared/common.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';

@Component({
  selector: 'app-aarogya-suraksha-doctor-assign-dhmo',
  templateUrl: './aarogya-suraksha-doctor-assign-dhmo.component.html',
  styleUrls: ['./aarogya-suraksha-doctor-assign-dhmo.component.css'],
  providers: [
    DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class AarogyaSurakshaDoctorAssignDhmoComponent implements OnInit {
  teamForm: UntypedFormGroup;
  submitted: Boolean = false;
  commonConstants: any = CommonConstants;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  scheduleDate : any;
  isDisabled:any;
  mandals: any = [];
  PHCList: any = [];
  VHCList: any = [];
  PHCDoc2List: any = [];
  doc2NameList: any = [];
  teamList: any[] = [];
  isScheduleConfirmed: Boolean = false;
  isAllSchedulesCompleted: Boolean = false;
  confirmModal1: any;
  confirmModal2: any;
  holidayDates: string[] = [];
  scheduleMandalList: any = [];
  scheduleVHCList: any = [];
  page = 1;
  pageSize = 10;
  showVHCInfo: Boolean = false;
  selectedVHC: any;
  isEditSubmitted: boolean = false;
  editModal: any;
  selectedSchedule:any = null;
  teamOpthForm: UntypedFormGroup;
  specialistForm: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private commonService: CommonService, private calendar: NgbCalendar, private config: NgbDatepickerConfig, private datePipe: DatePipe, private modalService: NgbModal ) { 
    this.teamForm = this.formBuilder.group({
      //sheduleDate: [null, [Validators.required]],
      scheduleMandal: [null, [Validators.required ]], 
      scheduleVHC:  [null, [Validators.required ]], 
      //mandal: [null], 
      // PHC: [null], 
      // VHC: [null], 
      //doc1Name: [null, [Validators.required]],
     // editDoctor: ['No', [Validators.required]],
      doc1NameText: [null,[Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      doc1AadharNo: [null,[AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      doc1MobileNo: [null, [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
      doc1Gender: [null],
      doc1HprId: [null],
     // doctor2Type: [null, [Validators.required]],  
      //PHCDoc2: [null], 
      //doc2Name: [null],
      doc2NameText: [null, [Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      doc2AadharNo: [null, [AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      doc2MobileNo: [null, [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
      doc2Gender: [null],
      doc2Type: [null],
      doc3Type: [null],
      doc2Specialist: [null],
      doc3Specialist: [null],
      doc3Name: [null, [Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      doc3AadharNo: [null, [AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      doc3MobileNo: [null, [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
      doc3Gender: [null],
      docOpthName: [null, [Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      docOpthMobileNo: [null, [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
      // doc4Name: [null, [Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      // doc4AadharNo: [null, [AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      // doc4MobileNo: [null, [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
      // doc4Gender: [null]
    });
    this.teamOpthForm = this.formBuilder.group({
      docOpthName: [null, [Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      docOpthMobileNo: [null, [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
    });

    this.specialistForm = this.formBuilder.group({
      doc2Type: [null, [Validators.required]],
      doc3Type: [null, [Validators.required]],
      doc2Specialist: [null, [Validators.required]],
      doc3Specialist: [null, [Validators.required]],
    });

   this.maxDate = {
      year: 2023,
      month: 11,
      day: 30    
    };

    this.minDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate() + 1
    }; 
   
    this.disableDates();
    this.getScheduledMandals();
    this.getScheduleList();
  }

  ngOnInit(): void {
  }
  get f() {
    return this.teamForm.controls;
  }
  get f1() {
    return this.teamOpthForm.controls;
  }
  get f2() {
    return this.specialistForm.controls;
  }
  disableDates() {
    let data = "FUTURE_DATE";
    this.commonService.getRequest(this.commonConstants.GetJASHolidaysList + data).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.holidayDates = responseData?.result?.holidayList;
          this.isDisabled = (date: NgbDateStruct) => {
            let dt = moment(new Date(date.year,date.month-1, date.day),"DD-MM-YYYY").format("YYYY-MM-DD");
            return this.holidayDates.indexOf(dt) >= 0? true : false;
          };
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  onChangeDoctorEdit() {
    if(this.f.editDoctor.value === 'Yes') {
      this.f.doc1NameText.setValidators([Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
      this.f.doc1NameText.updateValueAndValidity();
      this.f.doc1AadharNo.setValidators([AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.doc1AadharNo.updateValueAndValidity();
      this.f.doc1MobileNo.setValidators([Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]);
      this.f.doc1MobileNo.updateValueAndValidity();
      this.f.doc1Gender.setValidators([Validators.required]);
      this.f.doc1Gender.updateValueAndValidity();
      this.f.doc1HprId.setValidators([Validators.required, Validators.minLength(14), Validators.maxLength(14)]);
      this.f.doc1HprId.updateValueAndValidity();
    } else {
      this.f.doc1NameText.clearValidators();
      this.f.doc1NameText.updateValueAndValidity();
      this.f.doc1AadharNo.clearValidators();
      this.f.doc1AadharNo.updateValueAndValidity();
      this.f.doc1MobileNo.clearValidators();
      this.f.doc1MobileNo.updateValueAndValidity();
      this.f.doc1Gender.clearValidators();
      this.f.doc1Gender.updateValueAndValidity();
      this.f.doc1HprId.clearValidators();
      this.f.doc1HprId.updateValueAndValidity();
      this.f.doc1NameText.reset();
      this.f.doc1AadharNo.reset();
      this.f.doc1MobileNo.reset();
      this.f.doc1Gender.reset();
      this.f.doc1HprId.reset();
    }
  }
  onDoc2TypeChange() {
    if(this.f.doctor2Type.value === 'Others') {
      this.f.doc2NameText.setValidators([Validators.required , Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
      this.f.doc2NameText.updateValueAndValidity();
      this.f.doc2AadharNo.setValidators([AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.doc2AadharNo.updateValueAndValidity();
      this.f.doc2MobileNo.setValidators([Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]);
      this.f.doc2MobileNo.updateValueAndValidity();
      this.f.doc2Gender.setValidators([Validators.required]);
      this.f.doc2Gender.updateValueAndValidity();
      this.f.doc2Type.setValidators([Validators.required]);
      this.f.doc2Type.updateValueAndValidity();
      this.f.doc2Specialist.setValidators([Validators.required]);
      this.f.doc2Specialist.updateValueAndValidity();
      this.f.mandal.clearValidators();
      this.f.PHCDoc2.clearValidators();
      this.f.doc2Name.clearValidators();
      this.f.mandal.updateValueAndValidity();
      this.f.PHCDoc2.updateValueAndValidity();
      this.f.doc2Name.updateValueAndValidity();
      this.f.mandal.reset();
      this.f.PHCDoc2.reset();
      this.f.doc2Name.reset();
    } else {
      this.f.mandal.setValidators([Validators.required]);
      this.f.PHCDoc2.setValidators([Validators.required]);
      this.f.doc2Name.setValidators([Validators.required]);
      this.f.mandal.updateValueAndValidity();
      this.f.PHCDoc2.updateValueAndValidity();
      this.f.doc2Name.updateValueAndValidity();
      this.f.doc2NameText.clearValidators();
      this.f.doc2NameText.updateValueAndValidity();
      this.f.doc2AadharNo.clearValidators();
      this.f.doc2AadharNo.updateValueAndValidity();
      this.f.doc2MobileNo.clearValidators();
      this.f.doc2MobileNo.updateValueAndValidity();
      this.f.doc2Gender.clearValidators();
      this.f.doc2Gender.updateValueAndValidity();
      this.f.doc2Type.clearValidators();
      this.f.doc2Type.updateValueAndValidity();
      this.f.doc2Specialist.clearValidators();
      this.f.doc2Specialist.updateValueAndValidity();
      this.f.doc2NameText.reset();
      this.f.doc2AadharNo.reset();
      this.f.doc2MobileNo.reset();
      this.f.doc2Gender.reset();
      this.f.doc2Type.reset();
      this.f.doc2Specialist.reset();
    }
  }
  getScheduledMandals() {
    this.scheduleVHCList = [];
    let postData: any = 'Flag=GET PENDING MANDALS';
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.scheduleMandalList = this.commonService.sortItems(responseData.result?.jasdmhoTeamScheduleData, 'mandaL_NAME');
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  onScheduleMandalChange() {
    this.showVHCInfo = false;
    this.getPendingVHCList();
    this.scheduleVHCList = [];
    this.f.scheduleVHC.patchValue(null);
  }
  onScheduleVHCChange() {
    this.getVHCList();
  }
  getPendingVHCList() {
    let postData: any = 'Flag=GET PENDING VHCS&PNDMandalId=' + this.f.scheduleMandal.value?.mandaL_CODE;
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.scheduleVHCList = this.commonService.sortItems(responseData.result?.jasdmhoTeamScheduleData, 'vhC_NAME');
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getMandals(data:any) {
    let postData: any = "SelectedDate="  + this.datePipe.transform( data.scheduleD_DATE, 'dd/MM/yyyy') + '&Flag=Get Mandal';
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.mandals = responseData.result?.jasdmhoTeamScheduleData;
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  onMandalChange() {
    this.getPHCList();
    this.f.PHCDoc2.patchValue(null);
    this.PHCDoc2List = [];
    this.doc2NameList = [];
    this.f.doc2Name.patchValue(null);
  }
  getPHCList() {
    let postData: any = "SelectedDate=" + this.datePipe.transform(this.selectedVHC.scheduleD_DATE,"dd/MM/yyyy") + '&Flag=Get PHC' + '&DC2MandalId=' + this.f.mandal.value?.mid;
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.PHCDoc2List = responseData.result?.jasdmhoTeamScheduleData;
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getDoctorList() {
    let postData: any = "SelectedDate=" + this.datePipe.transform(this.selectedVHC.scheduleD_DATE,"dd/MM/yyyy") + '&Flag=Get DOCTOR' + '&DC2MandalId=' + this.f.mandal.value?.mid + '&DC2PHCCode='+ this.f.PHCDoc2.value?.phC_CODE;
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.doc2NameList = responseData.result?.jasdmhoTeamScheduleData;
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  showVHCDetails(data:any) {
    this.VHCList = this.VHCList.map((item: any) => {
      item.isSelected = false;
      return item;
    });
    data.isSelected = true;
    this.scheduleDate =  moment(data.scheduleD_DATE,"yyyy-MM-DD").format("DD/MM/yyyy");
    this.showVHCInfo = true;
    // this.f.VHC.patchValue(data?.vhC_NAME);
    // this.f.PHC.patchValue(data?.phC_NAME);
    // this.f.doc1Name.patchValue(data?.doctoR_1_NAME);
    // this.f.editDoctor.patchValue('No');
    // this.onChangeDoctorEdit();
    this.selectedVHC = data;
    this.f.doc1NameText.patchValue(null);
    this.f.doc1AadharNo.patchValue(null);
    this.f.doc1MobileNo.patchValue(null);
    this.f.doc1Gender.patchValue(null);
    this.f.doc1HprId.patchValue(null);
    // this.f.mandal.patchValue(null);
    // this.f.PHCDoc2.patchValue(null);
    this.f.doc2NameText.patchValue(null);
    this.f.doc2Gender.patchValue(null);
    this.f.doc2Type.patchValue(null);
    this.f.doc2Specialist.patchValue(null);
    this.f.doc2AadharNo.patchValue(null);
   // this.f.doctor2Type.patchValue(null);
    this.f.doc2MobileNo.patchValue(null);
    this.f.doc3Name.patchValue(null);
    this.f.doc3AadharNo.patchValue(null);
    this.f.doc3MobileNo.patchValue(null);
    this.f.doc3Gender.patchValue(null);
    this.f.doc3Type.patchValue(null);
    this.f.doc3Specialist.patchValue(null);
    // this.f.doc4Name.patchValue(null);
    // this.f.doc4AadharNo.patchValue(null);
    // this.f.doc4MobileNo.patchValue(null);
    // this.f.doc4Gender.patchValue(null);
    // this.mandals = [];
    // this.PHCDoc2List = [];
    // this.PHCList = [];
    //this.getMandals(data);
  }
  getVHCList() {
    //mandaL_CODE
    let postData: any =  'Flag=Get DATA&PNDMandalId=' + this.f.scheduleMandal.value?.mandaL_CODE + '&VHCCode=' + this.f.scheduleVHC.value?.vhC_CODE;
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.VHCList = responseData.result?.jasdmhoTeamScheduleData;
          // this.scheduleDate= moment(this.VHCList?.scheduleD_DATE,"YYYY-MM-DD").format("DD-MM-YYYY");
        } else if(responseData.result?.error == 'No Data Found') {
          this.VHCList = [];
          this.commonService.commonErrorPopup('Alert', 'All Secretariats are scheduled in the selected mandal and VHC.', 'md');
        } else {
          this.VHCList = [];
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  onVHCChange() {
    this.f.PHC.patchValue(this.f.VHC.value?.phC_NAME);
    this.f.doc1Name.patchValue(this.f.VHC.value?.doctoR_1_NAME);
  }
  onPHCChange() {
    this.f.doc2Name.patchValue(null);
    this.doc2NameList = [];
    this.getDoctorList();
  }
  findInvalidControls() {
    const invalid: any = [];
    const controls = this.teamForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    console.log('invalid controls', invalid);
}
  addToSchedule(): any {
    this.submitted = true;
    if(!this.teamForm.valid) {
      this.findInvalidControls();
      return false;
    } 
    let aadharNumbers: any = [];
    let mobileNumbers: any = [];
    if(this.f.doc3AadharNo.value !== null && this.f.doc3AadharNo.value !== '') {
      aadharNumbers.push(this.f.doc3AadharNo.value);
    } else if(this.f.doc1AadharNo.value !== null && this.f.doc1AadharNo.value !== '') {
      aadharNumbers.push(this.f.doc1AadharNo.value);
    } else if(this.f.doc2AadharNo.value !== null && this.f.doc2AadharNo.value !== '') {
      aadharNumbers.push(this.f.doc2AadharNo.value);
    }
    //  else if(this.f.doc4AadharNo.value !== null && this.f.doc4AadharNo.value !== '') {
    //   aadharNumbers.push(this.f.doc4AadharNo.value);
    // }
    //  mobileNumbers = [this.f.doc3MobileNo.value, this.f.doc4MobileNo.value];
    mobileNumbers = [this.f.doc1MobileNo.value, this.f.doc2MobileNo.value,this.f.doc3MobileNo.value];

    // if(this.f.doctor2Type.value === 'Others') {
    //   if(this.f.doc2AadharNo.value !== null && this.f.doc2AadharNo.value !== '') {
    //     aadharNumbers.push(this.f.doc2AadharNo.value);
    //   }
    //   mobileNumbers.push(this.f.doc2MobileNo.value);
    // }
    // if(this.f.editDoctor.value === 'Yes') {
    //   if(this.f.doc1AadharNo.value !== null && this.f.doc1AadharNo.value !== '') {
    //     aadharNumbers.push(this.f.doc1AadharNo.value);
    //   }
    //   mobileNumbers.push(this.f.doc1MobileNo.value);
    // }
    if(aadharNumbers.some((el:any, index:any) => aadharNumbers.indexOf(el) !== index)) {
      this.commonService.commonErrorPopup('Alert', 'Same Aadhar Number entered for more than one doctor.', 'md');
      return false;
    } else if(mobileNumbers.some((el:any, index:any) => mobileNumbers.indexOf(el) !== index)) {
      this.commonService.commonErrorPopup('Alert', 'Same Mobile Number entered for more than one doctor.', 'md');
      return false;
    } else {
      let postData: any;
     // if(this.f.doctor2Type.value === 'Others') {
        postData = "SelectedDate=" + this.datePipe.transform(this.selectedVHC.scheduleD_DATE,"dd/MM/yyyy") + '&Flag=SET' +
        '&VHCCode=' + this.selectedVHC?.vhC_CODE + '&DC2Name=' + this.f.doc2NameText.value + 
        '&DC3Name=' + this.f.doc3Name.value + '&DC3MobileNumber=' + this.f.doc3MobileNo.value + 
        // '&DC4Name='+ this.f.doc4Name.value + '&DC4MobileNumber=' + this.f.doc4MobileNo.value +
         '&DC2MobileNumber=' + this.f.doc2MobileNo.value +
        '&DC2Gender=' + this.f.doc2Gender.value + '&Dc2Type=' + this.f.doc2Type.value  +
        '&Dc2Specialist=' + this.f.doc2Specialist.value + '&DC3Gender=' + this.f.doc3Gender.value +
        '&Dc3Type=' + this.f.doc3Type.value + '&Dc3Specialist=' + this.f.doc3Specialist.value +
        // '&DC4Gender=' + this.f.doc4Gender.value + 
        '&PNDMandalId=' + this.f.scheduleMandal.value?.mandaL_CODE +
        '&DC1Flag=Y' + '&DC1Name=' + this.f.doc1NameText.value + '&DC1HprId=' + this.f.doc1HprId.value + 
        '&DC1MobileNumber=' + this.f.doc1MobileNo.value + '&DC1Gender=' + this.f.doc1Gender.value +
        '&SecCode=' + this.selectedVHC.secretariaT_CODE + '&OPHTAssistName='+ this.f.docOpthName.value +
        '&OPHTAssistMobileNo=' + this.f.docOpthMobileNo.value
        if(this.f.doc3AadharNo.value !== null && this.f.doc3AadharNo.value !== '') {
          postData += '&DC3AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc3AadharNo.value))
        }
        // if(this.f.doc4AadharNo.value !== null && this.f.doc4AadharNo.value !== '') {
        //   postData += '&DC4AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc4AadharNo.value))
        // }
        if(this.f.doc2AadharNo.value !== null && this.f.doc2AadharNo.value !== '') {
          postData += '&DC2AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc2AadharNo.value))
        }
        if(this.f.doc1AadharNo.value !== null && this.f.doc1AadharNo.value !== '') {
          postData += '&DC1AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc1AadharNo.value))
        }
        // if(this.f.editDoctor.value === 'Yes') {
        //   postData += '&DC1Name=' + this.f.doc1NameText.value + '&DC1HprId=' + this.f.doc1HprId.value + '&DC1MobileNumber=' + this.f.doc1MobileNo.value + '&DC1Gender=' + this.f.doc1Gender.value;
        //   if(this.f.doc1AadharNo.value !== null && this.f.doc1AadharNo.value !== '') {
        //     postData += '&DC1AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc1AadharNo.value))
        //   }
        // }
     // } 
      // else {
      //   postData = "SelectedDate=" + this.datePipe.transform(this.selectedVHC.scheduleD_DATE,"dd/MM/yyyy") + '&Flag=SET' +
      //   '&VHCCode=' + this.selectedVHC?.vhC_CODE + '&DC2MandalId=' + this.f.mandal.value?.mid + '&DC2PHCCode=' + this.f.PHCDoc2.value?.phC_CODE + 
      //   '&DC2HPRId=' + this.f.doc2Name.value?.hrP_ID + '&DC2Name=' + this.f.doc2Name.value?.mO_NAME + 
      //   '&DC3Name=' + this.f.doc3Name.value + '&DC3MobileNumber=' + this.f.doc3MobileNo.value + 
      //   // '&DC4Name='+ this.f.doc4Name.value + '&DC4MobileNumber=' + this.f.doc4MobileNo.value +
      //   '&DC2Type=' + this.f.doctor2Type.value + '&DC3Gender=' + this.f.doc3Gender.value + 
      //   // '&DC4Gender=' + this.f.doc4Gender.value + 
      //   '&PNDMandalId=' + this.f.scheduleMandal.value?.mandaL_CODE +
      //   '&DC1Flag=' + (this.f.editDoctor.value === 'Yes' ? 'Y' : 'N')
      //   if(this.f.doc3AadharNo.value !== null && this.f.doc3AadharNo.value !== '') {
      //     postData += '&DC3AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc3AadharNo.value))
      //   }
      //   // if(this.f.doc4AadharNo.value !== null && this.f.doc4AadharNo.value !== '') {
      //   //   postData += '&DC4AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc4AadharNo.value))
      //   // }
      //   if(this.f.editDoctor.value === 'Yes') {
      //     postData += '&DC1Name=' + this.f.doc1NameText.value + '&DC1HprId=' + this.f.doc1HprId.value + '&DC1MobileNumber=' + this.f.doc1MobileNo.value + '&DC1Gender=' + this.f.doc1Gender.value;
      //     if(this.f.doc1AadharNo.value !== null && this.f.doc1AadharNo.value !== '') {
      //       postData += '&DC1AadharNumber=' + encodeURIComponent(this.commonService.RSAEncrypt(this.f.doc1AadharNo.value))
      //     }
      //   }
      // }
       
      this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            this.showVHCInfo = false;
           this.commonMsgPopup('Schedule added successfully.');
          } else {
            this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
  }
  getScheduleList() {
    let postData: any = 'Flag=GET COMP DATA';
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.teamList = responseData.result?.jasdmhoTeamScheduleData;
          if(this.teamList && this.teamList?.length > 0) {
            this.teamList = this.teamList.map((item: any) => {
              item.neW_DC1_AADHAAR = (item.neW_DC1_AADHAAR !== null && item.neW_DC1_AADHAAR !== '') ? this.commonService.RSADecrypt(item.neW_DC1_AADHAAR) : null;
              item.doctoR_2_AADHAAR = (item.doctoR_2_AADHAAR !== null && item.doctoR_2_AADHAAR !== '') ? this.commonService.RSADecrypt(item.doctoR_2_AADHAAR) : null;
              item.doctoR_3_AADHAAR = (item.doctoR_3_AADHAAR !== null && item.doctoR_3_AADHAAR !== '') ? this.commonService.RSADecrypt(item.doctoR_3_AADHAAR): null;
              // item.doctoR_4_AADHAAR = (item.doctoR_4_AADHAAR !== null && item.doctoR_4_AADHAAR !== '') ? this.commonService.RSADecrypt(item.doctoR_4_AADHAAR): null;
              item.isSelected = false;
              return item;
            });
            this.isScheduleConfirmed = this.teamList[0]?.confirM_FLAG?.toUpperCase() === 'Y' ? true: false;
            this.isAllSchedulesCompleted= this.teamList[0]?.confirM_FLAG?.toUpperCase() === 'R' ? true: false;
            this.teamList = this.commonService.sortItems(this.teamList, 'scheduleD_DATE');
          }
        } else {
          this.teamList = [];
          //this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
 
  selectSchedule(item: any) {
    this.teamList = this.teamList.map((item: any) => {
      item.isSelected = false;
      return item;
    });
    item.isSelected = true;
  }
  deleteSchedule(): any {
    let data: any = this.teamList.filter((item: any) => item.isSelected);
    if(data.length === 0) {
      this.commonService.commonErrorPopup('Alert', 'Please select schedule to delete it.', 'md');
      return false;
    } else {
      let postData: any =  "SelectedDate=" + this.datePipe.transform( data[0].scheduleD_DATE, 'dd/MM/yyyy') +'&Flag=RESET' + '&VHCCode=' + data[0].vhC_CODE + '&SecCode=' + data[0].secretariaT_CODE;
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.commonMsgPopup('Schedule deleted successfully.', 'delete');
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
    }
  }
  openConfirmScheduleModal1(confirmmodal: any) {   
    this.confirmModal1 = this.modalService.open(confirmmodal, { size: 'md', centered: true, backdrop: 'static'});
  }

  editOpth(item: any, editModal: any) {
    this.f1.docOpthName.patchValue(null);  
    this.f1.docOpthMobileNo.patchValue(null);    
    this.isEditSubmitted = false;
    this.selectedSchedule = {...item};
    this.editModal = this.modalService.open(editModal, { size: 'md', centered: true, backdrop: 'static'});

  }

  updateOpth():any {    
    this.isEditSubmitted = true;
    if(!this.teamOpthForm.valid) {
      return false;  
    }
    let postData = "Flag=SET OPHT ASSISTANT&";
    postData += "VHCCode=" + this.selectedSchedule.vhC_CODE + "&";
    postData += "OPHTAssistName=" + this.f1.docOpthName.value + "&";
    postData += "OPHTAssistMobileNo=" + this.f1.docOpthMobileNo.value;

    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        this.editModal.close();
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'Ophthalmic Assistant detail is added successfully.', 'md');
          this.getScheduleList();
          this.selectedSchedule = null;
          this.isEditSubmitted = false;
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while adding Ophthalmic Assistant detail. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  openConfirmScheduleModal2(confirmmodal: any) {  
    this.confirmModal1.close();    
    this.confirmModal2 = this.modalService.open(confirmmodal, { size: 'md', centered: true, backdrop: 'static'});
  }
  confirmSchedule() {
    this.confirmModal2.close(); 
    let postData: any = 'Flag=SET CONFORMATION';
    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'Schedule details are confirmed successfully.', 'md');
          this.isScheduleConfirmed = true;
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  printSchedule() {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }
  preparePrintFormat() {
    const printContent: HTMLElement | null =
      document.getElementById('teamSchedule');
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>Team Schedule Details</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      `<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} 
      .tableCls td, .tableCls th {padding: 5px;border: 1px solid #ccc;} table {width: 100%;} body{margin: 15px;}
        </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>`;

    htmlData += printdata;
    htmlData += '</body></html>';

    return htmlData;
  }
  // onScheduleDateSelect(event:any) {
  //   this.f.mandal.patchValue(null);
  //   this.f.PHC.patchValue(null);
  //   this.f.VHC.patchValue(null);
  //   this.f.doc1Name.patchValue(null);
  //   this.f.PHCDoc2.patchValue(null);
  //   this.f.doc2Name.patchValue(null);
  //   this.f.doc3Name.patchValue(null);
  //   this.f.doc3AadharNo.patchValue(null);
  //   this.f.doc3MobileNo.patchValue(null);
  //   this.f.doc4Name.patchValue(null);
  //   this.f.doc4AadharNo.patchValue(null);
  //   this.f.doc4MobileNo.patchValue(null);
  //   this.mandals = [];
  //   this.PHCDoc2List = [];
  //   this.PHCList = [];
  //   this.VHCList = [];
  //   this.getMandals();
    
  // }
  goToHome() {
    this.router.navigate(['/home']);
  }
  commonMsgPopup(msg: any, from?:any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md' });
    modalRef.componentInstance.title = 'Alert'
    modalRef.componentInstance.message = msg;
    modalRef.result.then((result: any) => {
      if(from === 'delete') {
        this.getScheduleList();
        this.f.scheduleMandal.patchValue(null);
        this.f.scheduleVHC.patchValue(null);
        this.scheduleMandalList = [];
        this.scheduleVHCList = [];
        this.showVHCInfo = false;
        this.getScheduledMandals();
        if(this.f.scheduleMandal.value !== null && this.f.scheduleVHC.value !== null) {
          this.getVHCList();
        }
      } else {
        //this.teamForm.reset();
        // this.f.mandal.patchValue(null);
        // this.f.PHC.patchValue(null);
        // this.f.VHC.patchValue(null);
        // this.f.doc1Name.patchValue(null);
        // this.f.doctor2Type.patchValue(null);
        // this.f.PHCDoc2.patchValue(null);
        // this.f.doc2Name.patchValue(null);
        this.f.doc1NameText.patchValue(null);
        this.f.doc1AadharNo.patchValue(null);
        this.f.doc1MobileNo.patchValue(null);
        this.f.doc1Gender.patchValue(null);
        this.f.doc1HprId.patchValue(null);
        this.f.doc2NameText.patchValue(null);
        this.f.doc2AadharNo.patchValue(null);
        this.f.doc2MobileNo.patchValue(null);
        this.f.doc2Gender.patchValue(null);
        this.f.doc2Type.patchValue(null);
        this.f.doc2Specialist.patchValue(null);
        this.f.doc3Name.patchValue(null);
        this.f.doc3AadharNo.patchValue(null);
        this.f.doc3MobileNo.patchValue(null);
        this.f.doc3Gender.patchValue(null);
        this.f.docOpthName.patchValue(null);
        this.f.docOpthMobileNo.patchValue(null);
        this.f.doc3Type.patchValue(null);
        this.f.doc3Specialist.patchValue(null);
        // this.f.doc4Name.patchValue(null);
        // this.f.doc4AadharNo.patchValue(null);
        // this.f.doc4MobileNo.patchValue(null);
        // this.f.doc4Gender.patchValue(null);
        // this.mandals = [];
        // this.PHCDoc2List = [];
        // this.PHCList = [];
        //this.VHCList = [];
        this.getScheduleList();
        this.getVHCList();
        this.submitted = false;
      }
    });
   }
   goToReport() {
    this.router.navigate(['/aarogya-suraksha-report']);
  }
  goToReportDashboard() {
    this.router.navigate(['/aarogya-suraksha-dashboard']);
  }

  editSpecialization(item: any, editModal: any) {
    this.f2.doc2Type.patchValue(null);  
    this.f2.doc2Specialist.patchValue(null);   
    this.f2.doc3Type.patchValue(null);  
    this.f2.doc3Specialist.patchValue(null);    
    this.isEditSubmitted = false;
    this.selectedSchedule = {...item};
    this.editModal = this.modalService.open(editModal, { size: 'md', centered: true, backdrop: 'static'});

  }

  updateSpecialization():any {    
    this.isEditSubmitted = true;
    if(!this.specialistForm.valid) {
      return false;  
    }
    let postData = "Flag=SET SPECIALIST&";
    postData += "VHCCode=" + this.selectedSchedule.vhC_CODE + "&";
    postData += "SecCode=" + this.selectedSchedule.secretariaT_CODE + "&";
    postData += "Dc2Type=" + this.f2.doc2Type.value + "&";
    postData += "Dc2Specialist=" + this.f2.doc2Specialist.value + "&";
    postData += "Dc3Type=" + this.f2.doc3Type.value + "&";
    postData += "Dc3Specialist=" + this.f2.doc3Specialist.value;

    this.commonService.getRequest(this.commonConstants.getDMHOTeamScheduleList + postData).subscribe({
      next: (responseData: any) => {
        this.editModal.close();
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'Doctor Specialization details are updated successfully.', 'md');
          this.getScheduleList();
          this.selectedSchedule = null;
          this.isEditSubmitted = false;
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while updating Doctor Specialization details. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
}
