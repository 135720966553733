<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="row mb-3">
        <div class="col">
            <div class="subHeadingCls mb-3 text-center">Caste Audit (2024-25) SC Category:</div>
            <div class="text-center">
                <label class="mr-3"><span class="subHeadingCls">Urban / Rural:</span> {{ certificateInfo.citizenDetails?.ruralUrbanFlag == 'R' ? 'Rural' : 'Urban' }}</label>
                <label class="mr-3"><span class="subHeadingCls">District Name:</span> {{ certificateInfo.citizenDetails?.districtName }}</label>
                <label><span class="subHeadingCls">Mandal Name:</span> {{ certificateInfo.citizenDetails?.mandalName }}</label>
            </div>
            <div class="text-center">
                <label class="mr-3"><span class="subHeadingCls">Secretariat Code:</span> {{ certificateInfo.citizenDetails?.secretariatCode }}</label>
                <label><span class="subHeadingCls">Secretariat Name:</span> {{ certificateInfo.citizenDetails?.secretariatName }}</label>
            </div>
            <div class="tab table-responsive">
                <form [formGroup]="vroForm">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>SL No</th>
                            <th>Citizen Aadhar (Masked)</th>
                            <th>Name of the Citizen</th>
                            <th>Gender</th>
                            <th>Date of Birth</th>
                            <th>Marital Status</th>
                            <th>Caste</th>
                            <th>Sub-Caste</th>
                            <th>Religion</th>
                            <th>Type of House</th>
                            <th>Access To Toilet Facility</th>
                            <th>Access To Clean Drinking Water,</th>
                            <th>Access To Cooking Gas</th>
                            <th>Educational Qualification</th>
                            <th>Profession</th>
                            <th>Agricultural Land Owned</th>
                            <th>Residential Land Owned</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Caste Survey Data</td>
                            <td>{{ certificateInfo?.citizenDetails.aadhar.replace(certificateInfo?.citizenDetails.aadhar?.substr(0, 8), 'XXXX-XXXX-') }}</td>
                            <td>{{ certificateInfo.citizenDetails?.name ? certificateInfo.citizenDetails?.name : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.gender ? certificateInfo.citizenDetails?.gender : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.dateOfBirth ? certificateInfo.citizenDetails?.dateOfBirth : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.maritalStatus ? certificateInfo.citizenDetails?.maritalStatus : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.caste ? certificateInfo.citizenDetails?.caste : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.subcaste ? certificateInfo.citizenDetails?.subcaste : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.religion ? certificateInfo.citizenDetails?.religion : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.typeOfHouse ? certificateInfo.citizenDetails?.typeOfHouse : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.accessToToiletFacility ? certificateInfo.citizenDetails?.accessToToiletFacility : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.accessToCleanDrinkingWater ? certificateInfo.citizenDetails?.accessToCleanDrinkingWater : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.accessToCookingGas ? certificateInfo.citizenDetails?.accessToCookingGas : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.educationalQualification ? certificateInfo.citizenDetails?.educationalQualification : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.profession ? certificateInfo.citizenDetails?.profession : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.agriculturallandOwned ? certificateInfo.citizenDetails?.agriculturallandOwned : '-' }}</td>
                            <td>{{ certificateInfo.citizenDetails?.residentailLandOwned ? certificateInfo.citizenDetails?.residentailLandOwned : '-' }}</td>
                        </tr>
                        <tr *ngIf="loggedinuserName == 'Village Revenue Officer' || loggedinuserName == 'Ward Revenue Secretary'">
                            <td>VRO Verification Data</td>
                            <td>{{ certificateInfo?.citizenDetails.aadhar.replace(certificateInfo?.citizenDetails.aadhar?.substr(0, 8), 'XXXX-XXXX-') }}</td>
                            <td *ngIf="certificateInfo.citizenDetails?.name">{{ certificateInfo.citizenDetails?.name }}</td>
                            <td *ngIf="!certificateInfo.citizenDetails?.name">
                                <input type="text" class="inputCls" name="citizenName" appBlockCopyPaste required
                                    [ngClass]="{ 'is-invalid':formSubmitted && f.citizenName.errors }"
                                    formControlName = "citizenName">
                                <div *ngIf="formSubmitted && f.citizenName.errors" class="invalid-feedback">
                                    <div *ngIf="f.citizenName.errors?.required || f.citizenName.errors.whiteSpaceValidate">Citizen Name is required.</div>
                                    <div *ngIf="f.citizenName.errors?.pattern">Please enter valid Citizen Name</div>
                                </div>
                            </td>
                            <td *ngIf="certificateInfo.citizenDetails?.gender">{{ certificateInfo.citizenDetails?.gender }}</td>
                            <td *ngIf="!certificateInfo.citizenDetails?.gender">
                                <select class="selectCls" name="gender" required formControlName="gender" [ngClass]="{ 'is-invalid':formSubmitted && f.gender.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option value="FEMALE">Female</option>
                                    <option value="MALE">Male</option>
                                    <option value="TRANSGENDER">Transgender</option>
                                </select>
                                <div *ngIf="formSubmitted && f.gender.errors" class="invalid-feedback">
                                    <div *ngIf="f.gender.errors?.required">Gender is required.</div>
                                </div>
                            </td>
                            <td *ngIf="certificateInfo.citizenDetails?.dateOfBirth">{{ certificateInfo.citizenDetails?.dateOfBirth ? certificateInfo.citizenDetails?.dateOfBirth : '-' }}</td>
                            <td *ngIf="!certificateInfo.citizenDetails?.dateOfBirth">
                                <div class="input-group">
                                    <input class="inputCls form-control" appBlockCopyPaste
                                        name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                                        [maxDate]="maxDate" required formControlName = "dob"
                                        [ngClass]="{ 'is-invalid':formSubmitted && f.dob.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                        [ngClass]="{ 'is-invalid':formSubmitted && f.dob.errors }" type="button"></button>
                                    </div>
                                </div>
                                <div *ngIf="formSubmitted && f.dob.errors" class="selectError">
                                    <div *ngIf="f.dob.errors?.required">Date of Birth is required.</div>
                                    <div *ngIf="f.dob.errors?.invalidDate">Please provide valid date in DD/MM/YYYY format only.</div>
                                    <div *ngIf="f.dob.errors?.invalidDob && !f.dob.errors?.invalidDate">Please enter valid DOB between 1800 and present date only.</div>
                                </div>
                            </td>
                            <td *ngIf="!maritalStatusBoolean">{{ certificateInfo.citizenDetails?.maritalStatus ? certificateInfo.citizenDetails?.maritalStatus : '-' }}</td>
                            <td *ngIf="(basicInfo?.requisitionType != 'GrievanceCaste' && maritalStatusBoolean)" class="w-50">
                                <select class="selectCls" name="maritalStatus" required formControlName="maritalStatus" [ngClass]="{ 'is-invalid':formSubmitted && f.maritalStatus.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of maritalStatus" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.maritalStatus.errors" class="invalid-feedback">
                                    <div *ngIf="f.maritalStatus.errors?.required">Marital Status is required.</div>
                                </div>
                            </td>
                            <td *ngIf="basicInfo?.requisitionType == 'GrievanceOthers'">{{ certificateInfo.citizenDetails?.caste ? certificateInfo.citizenDetails?.caste : '-' }}</td>
                            <td class="w-50" *ngIf="basicInfo?.requisitionType != 'GrievanceOthers'">
                                <select class="selectCls" name="caste" required formControlName="caste" [ngClass]="{ 'is-invalid':formSubmitted && f.caste?.errors }" (change)="getSubCaste()">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let caste of casteCategory" [ngValue]="caste.name">{{caste.name}}</option>
                                </select> 
                                <div *ngIf="formSubmitted && f.caste?.errors" class="invalid-feedback">
                                    <div *ngIf="f.caste.errors?.required">Caste is required.</div>
                                  </div>
                            </td>
                            <td *ngIf="basicInfo?.requisitionType == 'GrievanceOthers'">{{ certificateInfo.citizenDetails?.subcaste ? certificateInfo.citizenDetails?.subcaste : '-' }}</td>
                            <td class="w-100" *ngIf="basicInfo?.requisitionType != 'GrievanceOthers'">
                                <select class="selectCls" name="subCaste" required formControlName="subcaste" [ngClass]="{ 'is-invalid':formSubmitted && f.subcaste.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let subcaste of subCasteData" [ngValue]="subcaste">{{subcaste}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.subcaste.errors" class="invalid-feedback">
                                    <div *ngIf="f.subcaste.errors?.required">Subcaste is required.</div>
                                </div>
                            </td>
                            <td *ngIf="!religionBooelan">{{ certificateInfo.citizenDetails?.religion ? certificateInfo.citizenDetails?.religion : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && religionBooelan" class="w-50">
                                <select class="selectCls" name="religion" required formControlName="religion" [ngClass]="{ 'is-invalid':formSubmitted && f.religion.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of religion" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.religion.errors" class="invalid-feedback">
                                    <div *ngIf="f.religion.errors?.required">Religion is required.</div>
                                </div>
                            </td>
                            <td *ngIf="!typeOfHouseBoolen">{{ certificateInfo.citizenDetails?.typeOfHouse ? certificateInfo.citizenDetails?.typeOfHouse : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && typeOfHouseBoolen">
                                <select class="selectCls" name="typeOfHouseDropdown" required formControlName="typeOfHouseDropdown" [ngClass]="{ 'is-invalid':formSubmitted && f.typeOfHouseDropdown.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of typeOfHouseDropdown" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.typeOfHouseDropdown.errors" class="invalid-feedback">
                                    <div *ngIf="f.typeOfHouseDropdown.errors?.required">Type of house is required.</div>
                                  </div>
                            </td>
                            <td *ngIf="!accessToToiletFacilityBoolean">{{ certificateInfo.citizenDetails?.accessToToiletFacility ? certificateInfo.citizenDetails?.accessToToiletFacility : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && accessToToiletFacilityBoolean">
                                <select class="selectCls" name="accessToToiletFacility" required formControlName="accessToToiletFacility" [ngClass]="{ 'is-invalid':formSubmitted && f.accessToToiletFacility.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of accessToToiletFacility" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.accessToToiletFacility.errors" class="invalid-feedback">
                                    <div *ngIf="f.accessToToiletFacility.errors?.required">Access to toilet facility is required.</div>
                                  </div>
                            </td>
                            <td *ngIf="!accessToCleanDrinkingWaterBoolean">{{ certificateInfo.citizenDetails?.accessToCleanDrinkingWater ? certificateInfo.citizenDetails?.accessToCleanDrinkingWater : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && accessToCleanDrinkingWaterBoolean">
                                <select class="selectCls" name="accessToCleanDrinkingWater" required formControlName="accessToCleanDrinkingWater" [ngClass]="{ 'is-invalid':formSubmitted && f.accessToCleanDrinkingWater.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of accessToDrinkingWater" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.accessToCleanDrinkingWater.errors" class="invalid-feedback">
                                    <div *ngIf="f.accessToCleanDrinkingWater.errors?.required">Access to Drinking water is required.</div>
                                  </div>
                            </td>
                            <td *ngIf="!accessToCookingGasBoolean">{{ certificateInfo.citizenDetails?.accessToCookingGas ? certificateInfo.citizenDetails?.accessToCookingGas : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && accessToCookingGasBoolean">
                                <select class="selectCls" name="accessToCookingGas" required formControlName="accessToCookingGas" [ngClass]="{ 'is-invalid':formSubmitted && f.accessToCookingGas.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of accessToCookingGas" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.accessToCookingGas.errors" class="invalid-feedback">
                                    <div *ngIf="f.accessToCookingGas.errors?.required">Access to cooking gas is required.</div>
                                  </div>
                            </td>
                            
                            <td *ngIf="!educationalQualificationBoolean">{{ certificateInfo.citizenDetails?.educationalQualification ? certificateInfo.citizenDetails?.educationalQualification : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && educationalQualificationBoolean">
                                <select class="selectCls" name="educationalQualification" required formControlName="educationalQualification" [ngClass]="{ 'is-invalid':formSubmitted && f.educationalQualification.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of educationalQualification" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.educationalQualification.errors" class="invalid-feedback">
                                    <div *ngIf="f.educationalQualification.errors?.required">Educational Qualification is required.</div>
                                  </div>
                            </td>
                          
                            <td *ngIf="!professionBoolean">{{ certificateInfo.citizenDetails?.profession ? certificateInfo.citizenDetails?.profession : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && professionBoolean">
                                <select class="selectCls" name="profession" required formControlName="profession" [ngClass]="{ 'is-invalid':formSubmitted && f.profession.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of profession" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.profession.errors" class="invalid-feedback">
                                    <div *ngIf="f.profession.errors?.required">Profession is required.</div>
                                  </div>
                            </td>
                            
                            <td *ngIf="!agriculturalLandOwnedBoolean">{{ certificateInfo.citizenDetails?.agriculturallandOwned ? certificateInfo.citizenDetails?.agriculturallandOwned : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && agriculturalLandOwnedBoolean">
                                <select class="selectCls" name="agriculturallandOwned" required formControlName="agriculturallandOwned" [ngClass]="{ 'is-invalid':formSubmitted && f.agriculturallandOwned.errors }">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let value of agriculturalLandOwned" [ngValue]="value.name">{{value.name}}</option>
                                </select>
                                <div *ngIf="formSubmitted && f.agriculturallandOwned.errors" class="invalid-feedback">
                                    <div *ngIf="f.agriculturallandOwned.errors?.required">Agricultural land owned is required.</div>
                                  </div>
                            </td>
                           
                            <td *ngIf="!resedentialLandOwnedBoolean">{{ certificateInfo.citizenDetails?.residentailLandOwned ? certificateInfo.citizenDetails?.residentailLandOwned : '-' }}</td>
                            <td *ngIf="basicInfo?.requisitionType != 'GrievanceCaste' && resedentialLandOwnedBoolean">
                            <select class="selectCls" name="residentailLandOwned" required formControlName="residentailLandOwned" [ngClass]="{ 'is-invalid':formSubmitted && f.residentailLandOwned.errors }">
                                <option [ngValue]="null" disabled>Select</option>
                                <option *ngFor="let value of resedentialLandOwned" [ngValue]="value.name">{{value.name}}</option>
                            </select>
                            <div *ngIf="formSubmitted && f.residentailLandOwned.errors" class="invalid-feedback">
                                <div *ngIf="f.residentailLandOwned.errors?.required">Residentail land owned is required.</div>
                              </div>
                            </td>
                        </tr>
                        <tr *ngIf="loggedinuserName == 'Revenue Inspector' || loggedinuserName == 'Tahsildar' || loggedinuserName == 'Revenue Divisonal Officer' || loggedinuserName == 'Collector'">
                            <td>VRO Verification Data</td>
                            <td>{{ certificateInfo?.approverData[0].aadhar.replace(certificateInfo?.approverData[0].aadhar?.substr(0, 8), 'XXXX-XXXX-') }}</td>
                            <td>{{ certificateInfo?.approverData[0].name ? certificateInfo?.approverData[0].name : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].gender ? certificateInfo?.approverData[0].gender : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].dateOfBirth ? certificateInfo?.approverData[0].dateOfBirth : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].maritalStatus ? certificateInfo?.approverData[0].maritalStatus : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].caste ? certificateInfo?.approverData[0].caste : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].subCaste ? certificateInfo?.approverData[0].subCaste : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].religion ? certificateInfo?.approverData[0].religion : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].typeofHouse ? certificateInfo?.approverData[0].typeofHouse : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].accessToToiletFacility ? certificateInfo?.approverData[0].accessToToiletFacility : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].accessToCleanDrinkingWater ? certificateInfo?.approverData[0].accessToCleanDrinkingWater : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].accessToCookingGas ? certificateInfo?.approverData[0].accessToCookingGas : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].educationalQualification ? certificateInfo?.approverData[0].educationalQualification : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].profession ? certificateInfo?.approverData[0].profession : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].acriculturalLandOwned ? certificateInfo?.approverData[0].acriculturalLandOwned : '-' }}</td>
                            <td>{{ certificateInfo?.approverData[0].residentailLandOwned ? certificateInfo?.approverData[0].residentailLandOwned : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </form>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Grievance Raised By: </label>
                <div class="valueCls">{{ certificateInfo?.grievanceRaisedBy }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Grievance Type: </label>
                <div class="valueCls">{{ certificateInfo?.grievanceType }}</div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <div class="fieldHolder" *ngIf="certificateInfo.grievanceType == 'others'">
                <label class="labelCls">Raised Grievance Types: </label>
                <div style="color: #535353;" *ngFor="let data of certificateInfo.othersGrievance; let i = index">({{ i + 1 }}) {{ data.label }}</div>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList?.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'Grievance Copy'">
                        <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Grievance Copy:</label>
                        <div class="valueCls">
                        <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                            class="download ml-2"></span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>