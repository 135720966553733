import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';

import { DatePipe } from '@angular/common';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from '../../shared/common.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './view-transactions.component.html',
  styleUrls: ['./view-transactions.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})

export class ViewTransactionsComponent implements OnInit {
 
   public submitted: Boolean = false;
  public showDetails: Boolean = false;
  public commonFormSubmitted: Boolean = false;
  commonConstants: any = CommonConstants;
  TomaxDate: any;
  TominDate: any;
  FrommaxDate: any;
  FromminDate: any;
  currentDate = new Date();
  userInfo: any = [];
  serviceList: any = [];
  closeResult:any;
  displayUserName:any='';
  //*Transaction status
  ViewTransactions: UntypedFormGroup;
  dateTime: any;
  requisitionInfo: any;
  requisitionID: any;
  transactionID: any;
  TokenNumber:any;
  serviceName: any;
  applicantName: any;
  applicantFatherName: any;
  totalAmount: any;
  SLA: any = "";
  mandal: any;
  district: any;
  transactionStatusData :any = "Pending";
  paymentMode: any ="UPI";
  departmentCode :any = "RV";
  departmentName :any = "Revenue";
  showPrintCertificate :Boolean = false;
  reIssuanceData: any;
  secretariatName: any;
  amountInwords: any;
  is_requestsent:Boolean=false; 
  certificateTypeName:any;
  currentDateFormat:any;
  page = 1;
  pageSize = 10;
  secreteriateCode:any;
  transactionsData: any[] = [];
  transactionFilterID: string = '';
  items: any[] = [];
  tempItems: any = [];
  is_categoryA:Boolean=false; 
  serchCriteria:any=0;
  transactionDataPopup: any = {};
  totalPageCount: number = 0;
  isCitizen:Boolean = false;
  isMeesevaUser: Boolean = false;
  role: string = '';
  constructor(
    private config: NgbNavConfig,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private router: Router,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) {

    this.getServiceListData();
    
    this.TomaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.TominDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
   
    this.FrommaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.FromminDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };

    this.ViewTransactions = this.formBuilder.group({
      periodFrom: [null, [Validators.required]],
      periodTo: [null, [Validators.required]],
      service_name: [],//[null, [Validators.required]],
      department_name: [],//[null, [Validators.required]],
      transactionID:  ['', [Validators.required]],
      TokenNumber: ['', [Validators.required]],
    });

    config.destroyOnHide = false;
    config.roles = false;


    let userData = this.commonService.getLocalStorage('userInfo');
   
    if(userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      this.role = this.userInfo.jobTitle;
      this.isCitizen = this.userInfo.jobTitle === 'Citizen' ? true : false;
      this.isMeesevaUser = this.userInfo.jobTitle === 'Meeseva' ? true : false;
      let officeLocation = this.userInfo.officeLocation?.split('-');
      if (officeLocation?.length > 0) {
        this.secretariatName = officeLocation[1];
      }

      this.displayUserName =  this.commonService.getLocalStorage('displayUserName') ;
    }
  } 

  ngOnInit(): void {
  this.secreteriateCode =this.commonService.getLocalStorage('locationCode');
    this.activatedRoute.queryParams.subscribe((params) => {
      const applySearch:string = params?.preservesearch;
      let searchApplied = false;
      if(applySearch === "true"){
        const searchData:string = this.commonService.getLocalStorage('viewtransaction_searchData') ?? "";
        const postData:string =  this.commonService.getLocalStorage('viewtransaction_postData') ?? "";
        const postDataForOld:string = this.commonService.getLocalStorage('viewtransaction_postDataForOld') ?? "";
        if(searchData && postData && postDataForOld){
          searchApplied = true;
          this.ViewTransactions.patchValue(JSON.parse(searchData));
          // this.getTransactionDataAPI(postData,postDataForOld);
          this.getTransactionData(postData,postDataForOld);
        } else {
          searchApplied = false;
        }
      }
      if(searchApplied === false){
        localStorage.removeItem('viewtransaction_searchData');
        localStorage.removeItem('viewtransaction_postData');
        localStorage.removeItem('viewtransaction_postDataForOld');
        this.currentDateFormat = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
        this.ViewTransactions.patchValue({
          service_name:'all',
          department_name:'all',
          periodFrom: this.currentDateFormat,
          periodTo: this.currentDateFormat,
          transactionID: '',
          TokenNumber: ''
        });
        let curDate = this.currentDate.getFullYear()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getDate();
        // Old code with 5 API s
        // let postData = '?FromDate=' + curDate + '&ToDate=' + curDate + '&RequisitionType=&Userid='+this.userInfo.userPrincipalName+'&Department='+'&TransactionStatus=0300';
        // let postDataForOld = '?FromDate=' + curDate + '&ToDate=' + curDate + '&RequisitionType=&Userid='+this.userInfo.userPrincipalName+'&Department='+'&TransactionStatus=';      
     
     
        // Running Code  with single API 
        let postData = '?FromDate=' + curDate + '&ToDate=' + curDate + '&RequisitionType=&Userid='+ ((this.isCitizen ===  true || this.isMeesevaUser === true) ? this.userInfo.userPrincipalName : '') +'&Department='+'&TransactionStatus=0300'+'&Secretariat_Code=' + ((this.isCitizen ===  false && this.isMeesevaUser === false) ?   this.secreteriateCode : '') + '&PageIndex=' + this.page + '&PageSize=' + this.pageSize;
        let postDataForOld = '?FromDate=' + curDate + '&ToDate=' + curDate + '&RequisitionType=&Userid='+((this.isCitizen ===  true || this.isMeesevaUser === true) ? this.userInfo.userPrincipalName : '')+'&Department='+'&TransactionStatus='+'&Secretariat_Code=' +((this.isCitizen ===  false && this.isMeesevaUser === false)  ?   this.secreteriateCode : '') + '&PageIndex=' + this.page + '&PageSize=' + this.pageSize;      
       
   
        
        // this.getTransactionDataAPI(postData,postDataForOld);
        this.getTransactionData(postData,postDataForOld);
      }
    });    
  }

  get f() {
    return this.ViewTransactions.controls;
  }

  
  submit(){
    this.page =1;
    this.ViewTransactions.controls['department_name'].setValidators([Validators.required]);
    this.ViewTransactions.controls['department_name'].updateValueAndValidity();
    this.ViewTransactions.controls['service_name'].setValidators([Validators.required]);
    this.ViewTransactions.controls['service_name'].updateValueAndValidity();
    this.ViewTransactions.controls['periodFrom'].setValidators([Validators.required]);
    this.ViewTransactions.controls['periodFrom'].updateValueAndValidity();
    this.ViewTransactions.controls['periodTo'].setValidators([Validators.required]);
    this.ViewTransactions.controls['periodTo'].updateValueAndValidity();
    this.ViewTransactions.controls['transactionID'].setValidators([Validators.required]);
    this.ViewTransactions.controls['transactionID'].updateValueAndValidity(); 
    this.ViewTransactions.controls['TokenNumber'].setValidators([Validators.required]);
    this.ViewTransactions.controls['TokenNumber'].updateValueAndValidity(); 
    if(this.f['periodFrom'].value && this.f['periodTo'].value){
        let formDateFormat = this.commonService.formatDate(
          this.f['periodFrom'].value
        );
        let toDateFormat = this.commonService.formatDate(this.f['periodTo'].value);
        if(new Date(toDateFormat) < new Date(formDateFormat)){
          this.commonService.commonErrorPopup('Alert', 'Please select proper date range,To date should be greater than or equal to from date !!!', 'md');
        }else{
          this.final_submit();
      }
      }else{
        this.final_submit();
      }
    }

  final_submit(): any {
   
    this.submitted = true;
    this.transactionsData =[];
    this.transactionFilterID = "";
    
    this.ViewTransactions.controls['service_name'].clearValidators();
    this.ViewTransactions.controls['service_name'].updateValueAndValidity();
    this.ViewTransactions.controls['department_name'].clearValidators();
    this.ViewTransactions.controls['department_name'].updateValueAndValidity();
    
    if(this.f['transactionID'].value){
      this.ViewTransactions.controls['periodFrom'].clearValidators();
      this.ViewTransactions.controls['periodTo'].clearValidators();
      this.ViewTransactions.controls['periodFrom'].updateValueAndValidity();
      this.ViewTransactions.controls['periodTo'].updateValueAndValidity();
      this.ViewTransactions.controls['TokenNumber'].clearValidators();
      this.ViewTransactions.controls['TokenNumber'].updateValueAndValidity();
    }
    if(this.f['TokenNumber'].value){
      this.ViewTransactions.controls['periodFrom'].clearValidators();
      this.ViewTransactions.controls['periodTo'].clearValidators();
      this.ViewTransactions.controls['periodFrom'].updateValueAndValidity();
      this.ViewTransactions.controls['periodTo'].updateValueAndValidity();
      this.ViewTransactions.controls['transactionID'].clearValidators();
      this.ViewTransactions.controls['transactionID'].updateValueAndValidity();
    }
    if(this.f['periodFrom'].value && this.f['periodTo'].value){
      this.ViewTransactions.controls['transactionID'].clearValidators();
      this.ViewTransactions.controls['transactionID'].updateValueAndValidity();
      this.ViewTransactions.controls['TokenNumber'].clearValidators();
      this.ViewTransactions.controls['TokenNumber'].updateValueAndValidity();
    }

    if (!this.ViewTransactions.valid) {
      return false;
    } else {
      let postData = "";
      let postDataForOld = "";
     
      if(this.f['transactionID'].value){
        
        this.serchCriteria = 1;
        
       // postData = '?TransactionID=' + this.f['transactionID'].value+'&Userid='+this.userInfo.userPrincipalName+'&TransactionStatus=0300';
        postData = '?TransactionID=' + this.f['transactionID'].value+'&Userid='+'&TransactionStatus=0300';
        postDataForOld = '?TransactionID=' + this.f['transactionID'].value;
        
      }
      else  if(this.f['TokenNumber'].value){
        
        this.serchCriteria = 2;
        
        postData = '?TokenNumber=' + this.f['TokenNumber'].value;
        postDataForOld = '?TokenNumber=' + this.f['TokenNumber'].value;
        
      }
      else{
        
        this.serchCriteria = 0;

        let formDateFormat = this.commonService.formatDate(
          this.f['periodFrom'].value
        );
        let toDateFormat = this.commonService.formatDate(this.f['periodTo'].value);
       
        let requisitionType = (this.f['service_name'].value == "all")? "" : this.f['service_name'].value;
        let departmentName = (this.f['department_name'].value == "all")? "" : this.f['department_name'].value;
        
// Old code with 5 API s
        // postData = '?FromDate=' + formDateFormat.split('T')[0] + '&ToDate=' + toDateFormat.split('T')[0] + '&RequisitionType='+requisitionType+'&Userid='+this.userInfo.userPrincipalName+'&Department='+departmentName+'&TransactionStatus=0300';
        // postDataForOld = '?FromDate=' + formDateFormat.split('T')[0] + '&ToDate=' + toDateFormat.split('T')[0] + '&RequisitionType='+requisitionType+'&Userid='+this.userInfo.userPrincipalName+'&Department='+departmentName+'&TransactionStatus=';

        
// Running Code  with single API 
        postData = '?FromDate=' + formDateFormat.split('T')[0] + '&ToDate=' + toDateFormat.split('T')[0] + '&RequisitionType='+requisitionType+'&Userid='+((this.isCitizen ===  true || this.isMeesevaUser === true) ? this.userInfo.userPrincipalName : '') +'&Department='+departmentName+'&TransactionStatus=0300'+'&Secretariat_Code=' + ((this.isCitizen ===  false && this.isMeesevaUser === false)  ?   this.secreteriateCode : '') + '&PageIndex=' + this.page + '&PageSize=' + this.pageSize;
       
        postDataForOld = '?FromDate=' + formDateFormat.split('T')[0] + '&ToDate=' + toDateFormat.split('T')[0] + '&RequisitionType='+requisitionType+'&Userid='+((this.isCitizen ===  true || this.isMeesevaUser === true)  ? this.userInfo.userPrincipalName : '') +'&Department='+departmentName+'&TransactionStatus='+'&Secretariat_Code=' + ((this.isCitizen ===  false && this.isMeesevaUser === false)  ?   this.secreteriateCode : '') + '&PageIndex=' + this.page + '&PageSize=' + this.pageSize;
      }
      // const searchData = {
      //   periodFrom: this.f['periodFrom'].value,
      //   periodTo: this.f['periodTo'].value,
      //   service_name: this.f['service_name'].value,
      //   department_name: this.f['department_name'].value,
      //   transactionID: this.f['transactionID'].value
      // };
      const searchData = {
        periodFrom: this.f['periodFrom'].value,
        periodTo: this.f['periodTo'].value,
        service_name: this.f['service_name'].value,
        department_name: this.f['department_name'].value,
        transactionID: this.f['transactionID'].value,
        TokenNumber:this.f['TokenNumber'].value
      };
      this.commonService.setLocalStorage('viewtransaction_searchData', JSON.stringify(searchData));
      this.commonService.setLocalStorage('viewtransaction_postData', postData);
      this.commonService.setLocalStorage('viewtransaction_postDataForOld', postDataForOld);
      // this.getTransactionDataAPI(postData,postDataForOld);
      this.getTransactionData(postData,postDataForOld);
    }
  }
  


  // Old Code with 5 API s


  // getTransactionDataAPI(postData:any,postDataForOld:any){
  //   this.items = [];
  //   this.tempItems = [];
  //   this.page = 1;

  //   this.commonService
  //   .getRequest(this.commonConstants.getTransactions + postData)
  //   .subscribe({
  //     next: (responseData: any) => {

  //       this.showDetails = true;
  //       this.transactionsData = responseData.result.reverse();
  //       console.log(responseData.result.length ,'responseData.result')
  //       console.log(responseData.result,'this.responseData.result')
  //       let i=1;

  //       this.transactionsData.forEach((element: any) => {

  //         let temp: any = {};
  //         // temp.SNo = i++;
  //         temp.id = element.id;
  //         temp.applicantFatherHusbandName  = element?.applicantFatherHusbandName;

  //         if(element?.applicantName === null || element?.applicantName == ""){
  //           temp.applicantName  ='' ; 
  //         }else{
  //           temp.applicantName  = element?.applicantName.replace("null", ""); 
  //         }

  //         /* for(let i=0; i < this.serviceList.length;i++){
  //           if(this.serviceList[i].serviceCode === element?.serviceCode){
  //             this.serviceName = this.serviceList[i].serviceName;
  //             console.log(this.serviceName)
  //           }
  //         } */
     
  //         temp.departmentID  = element?.departmentID ; 
  //         temp.district  = element?.district ; 
  //         temp.mandal  = element?.mandal ; 
  //         temp.mobileNumber  = element?.mobileNumber ; 
  //         temp.requisitionId  = element?.requisitionId ; 
  //         temp.requisitionType  = element?.requisitionType ; 
  //         temp.secretariateName  = element?.secretariateName ; 
  //         temp.serviceCode  = element?.requisitionType ; 
  //         temp.serviceName  = (element?.serviceName === "" || element?.serviceName === null) ? element?.requisitionType :  element?.serviceName;
  //         temp.slaDays  = element?.slaDays ;       ;       
  //         temp.transactionDate  = element?.transactionDate ; 
  //         temp.transactionID  = element?.transactionID ; 
  //         temp.transactionMode  = element?.transactionMode == 'cash' ? this.titleCaseWord(element?.transactionMode): element?.transactionMode; 
  //         temp.transactionStatus  = element?.transactionStatus ; 
  //         temp.transactionAmount  = element?.transactionAmount ; 
  //         temp.dept_App_No  = '' ; 
  //         temp.transactionCategory  = "same"; 
  //         temp.transactionFrom  = "Same Site";

  //         this.items.push(temp);
  //         this.tempItems.push(temp);
  //       });

  //       if(this.serchCriteria == 1){
  //          if( responseData.result.length === 0){
  //            this. getTransactionFromRedirectionServices(postDataForOld);
  //          }
  //       }else{
  //         this. getTransactionFromRedirectionServices(postDataForOld);
  //       }
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     },
  //   });

   
  //   //this. getTransactionFromRedirectionServices(postDataForOld);
  // }



  // Running Code  with single API


//   getTransactionDataAPI(postData:any,postDataForOld:any){
//     this.items = [];
//     this.tempItems = [];
// // removed getTransactions and added new API Pagginated_GetAllTransactionDetail
//     this.commonService
//     .getRequest(this.commonConstants.pagginated_GetAllTransactionDetails + postData)
//     .subscribe({
//       next: (responseData: any) => {

//         this.showDetails = true;
//         this.transactionsData = responseData.result?.pagedList?.reverse();
//         this.totalPageCount = responseData.result?.totalResultsCount;
//         this.page = responseData.result?.currentPage;
//         console.log(this.totalPageCount ,'responseData.result.totalPages')
//         let i=1;

//         this.transactionsData.forEach((element: any) => {

//           let temp: any = {};
//           // temp.SNo = i++;
//           temp.id = element.id;
//           temp.applicantFatherHusbandName  = element?.applicantFatherHusbandName;

//           if(element?.applicantName === null || element?.applicantName == ""){
//             temp.applicantName  ='' ; 
//           }else{
//             temp.applicantName  = element?.applicantName?.replace("null", ""); 
//           }

//           /* for(let i=0; i < this.serviceList.length;i++){
//             if(this.serviceList[i].serviceCode === element?.serviceCode){
//               this.serviceName = this.serviceList[i].serviceName;
//               console.log(this.serviceName)
//             }
//           } */
     
//           temp.departmentID  = element?.departmentID ; 
//           temp.district  = element?.district ; 
//           temp.mandal  = element?.mandal ; 
//           temp.mobileNumber  = element?.mobileNumber ; 
//           temp.requisitionId  = element?.requisitionId ; 
//           temp.requisitionType  = element?.requisitionType ; 
//           temp.secretariateName  = element?.secretariateName ; 
//           temp.serviceCode  = element?.requisitionType ; 
//           temp.serviceName  = (element?.serviceName === "" || element?.serviceName === null) ? element?.requisitionType :  element?.serviceName;
//           temp.slaDays  = element?.slaDays ;       ;       
//           temp.transactionDate  = element?.transactionDate ; 
//           temp.transactionID  = element?.transactionID ; 
//           temp.transactionMode  = element?.transactionMode == 'cash' ? this.titleCaseWord(element?.transactionMode): element?.transactionMode; 
//            temp.transactionStatus  = element?.transactionStatus ; 
//            temp.transactionAmount  = element?.transactionAmount ; 
//            temp.dept_App_No  = element?.dept_App_No ;
//           // temp.transactionCategory  = "same"; 
//           // temp.transactionFrom  = "Same Site";
//           //temp.source =element.source;
//           if(element.source === 'NEW'){
//             temp.transactionCategory  =  "same" ;
//             temp.transactionFrom  = "Same Site";
//             temp.dept_App_No  = '' ; 
//            }else if(element.source === 'OLD'){
//             temp.transactionCategory  = "OLD GSWS";
//             temp.transactionFrom  = "OLD GSWS";
//             temp.applicantName  = "NA" ; 
//            }else if(element.source === 'MAUD'){
//             temp.transactionCategory  = "other";
//             temp.transactionFrom  = "MAUD Redirectional";
//             temp.transactionMode  =  "-" ; 
//             temp.transactionAmount  = 0 ;
//            }else if(element.source === 'MESEVA'){
//             temp.transactionCategory  = "other";
//             temp.transactionFrom  = "Meeseva";         
            
//            }else if(element.source ==='ENERGY'){
//             temp.transactionCategory  = "other";
//             temp.transactionFrom  = "ENERGY Redirectional";
//             temp.transactionMode  =  "-" ; 
//             temp.transactionAmount  = 0 ;
//            }else if(element.source === 'B2C') {
//             temp.transactionCategory  =  "B2C" ;
//             temp.transactionFrom  = "BillPayments";
//            }

//           this.items.push(temp);
//           this.tempItems.push(temp);
//         });

//         // if(this.serchCriteria == 1){
//         //    if( responseData.result.length === 0){
//         //      this. getTransactionFromRedirectionServices(postDataForOld);
//         //    }
//         // }else{
//         //   this. getTransactionFromRedirectionServices(postDataForOld);
//         // }

//         console.log(JSON.stringify(this.items) + 'Items')
//       },
//       error: (error) => {
//         console.log('client side', error);
//       },
//     });

   
//     //this. getTransactionFromRedirectionServices(postDataForOld);
//   }
  getTransactionData(postData:any,postDataForOld:any){
    this.items = [];
    this.tempItems = [];
// removed getTransactions and added new API Pagginated_GetAllTransactionDetail
    this.commonService
    .getRequest(this.commonConstants.pagginated_GetAllTransactionDetailsJS + postData)
    .subscribe({
      next: (responseData: any) => {

        this.showDetails = true;
        this.transactionsData = responseData.result?.pagedList?.reverse();
        this.totalPageCount = responseData.result?.totalResultsCount;
        this.page = responseData.result?.currentPage;
        console.log(this.totalPageCount ,'responseData.result.totalPages')
        let i=1;

        this.transactionsData.forEach((element: any) => {

          let temp: any = {};
          // temp.SNo = i++;
          temp.id = element.id;
          temp.applicantFatherHusbandName  = element?.applicantFatherHusbandName;

          if(element?.applicantName === null || element?.applicantName == ""){
            temp.applicantName  ='' ; 
          }else{
            temp.applicantName  = element?.applicantName?.replace("null", ""); 
          }

          /* for(let i=0; i < this.serviceList.length;i++){
            if(this.serviceList[i].serviceCode === element?.serviceCode){
              this.serviceName = this.serviceList[i].serviceName;
              console.log(this.serviceName)
            }
          } */
     
          temp.departmentID  = element?.departmentID ; 
          temp.district  = element?.district ; 
          temp.mandal  = element?.mandal ; 
          temp.mobileNumber  = element?.mobileNumber ; 
          temp.requisitionId  = element?.requisitionId ; 
          temp.requisitionType  = element?.requisitionType ; 
          temp.secretariateName  = element?.secretariateName ; 
          temp.serviceCode  = element?.requisitionType ; 
          temp.serviceName  = (element?.serviceName === "" || element?.serviceName === null) ? element?.requisitionType :((element?.requisitionType == 'RegistrationofTokens') ? 'Suraksha Token Registration': element?.serviceName);
          temp.slaDays  = element?.slaDays ;       ;       
          temp.transactionDate  = element?.transactionDate ; 
          temp.transactionID  = element?.transactionID ; 
          temp.transactionMode  = element?.transactionMode == 'cash' ? this.titleCaseWord(element?.transactionMode): element?.transactionMode; 
           temp.transactionStatus  = element?.transactionStatus ; 
           temp.transactionAmount  = element?.transactionAmount ; 
           temp.dept_App_No  = element?.dept_App_No ;
          // temp.transactionCategory  = "same"; 
          // temp.transactionFrom  = "Same Site";
          temp.source =element.source;
          temp.tokenNo = element?.tokenNo ;
          if(element.source === 'NEW'){
            temp.transactionCategory  =  "same" ;
            temp.transactionFrom  = "Same Site";
            temp.dept_App_No  = '' ; 
           }else if(element.source === 'OLD'){
            temp.transactionCategory  = "OLD GSWS";
            temp.transactionFrom  = "OLD GSWS";
            temp.applicantName  = "NA" ; 
           }else if(element.source === 'MAUD'){
            temp.transactionCategory  = "other";
            temp.transactionFrom  = "MAUD Redirectional";
            temp.transactionMode  =  "-" ; 
            temp.transactionAmount  = 0 ;
           }else if(element.source === 'MESEVA'){
            temp.transactionCategory  = "other";
            temp.transactionFrom  = "Meeseva";         
            
           }else if(element.source ==='ENERGY'){
            temp.transactionCategory  = "other";
            temp.transactionFrom  = "ENERGY Redirectional";
            temp.transactionMode  =  "-" ; 
            temp.transactionAmount  = 0 ;
           }else if(element.source === 'B2C') {
            temp.transactionCategory  =  "B2C" ;
            temp.transactionFrom  = "BillPayments";
           }
           

          this.items.push(temp);
          this.tempItems.push(temp);
        });

        // if(this.serchCriteria == 1){
        //    if( responseData.result.length === 0){
        //      this. getTransactionFromRedirectionServices(postDataForOld);
        //    }
        // }else{
        //   this. getTransactionFromRedirectionServices(postDataForOld);
        // }

        console.log(JSON.stringify(this.items) + 'Items')
      },
      error: (error) => {
        console.log('client side', error);
      },
    });

   
    //this. getTransactionFromRedirectionServices(postDataForOld);
  }
  pageChanged(event:any){
    this.final_submit();
    console.log(this.page + 'page number')
  }
  getServiceName(reqType:any){
    console.log(this.serviceList)
    //get service name
    for(let i=0; i < this.serviceList.length;i++){
      if(this.serviceList[i].serviceCode === reqType){
        this.serviceName = this.serviceList[i].serviceName;
        console.log(this.serviceName)
        return this.serviceName;
      }
    }
  }

  getTransactionFromRedirectionServices(data:any){
   // data = "?UserId=SIRIPURAM-1-DA@apgsws.onmicrosoft.com&TransactionID&Department&FromDate=2021-12-18&ToDate=2021-12-30";
    this.commonService.getRequest(this.commonConstants.getOtherServicesTransactionDetails + data , 'MAUD'
    ).
    subscribe({
      next: (responseData: any) => {
        
        if(this.serchCriteria == 1){
          if( responseData.result.length === 0){
            this. getTransactionFromRedirectionMAUD(data);
          }
       }else{
         this. getTransactionFromRedirectionMAUD(data);
       }

        if(responseData.result){
          responseData.result.forEach((element: any) => {

            let temp: any = {};
            temp.id = element.id;
            temp.applicantFatherHusbandName  = "";
            temp.mandal  = element?.mandal_Name ; 
            temp.mobileNumber  = ""; 
            temp.requisitionType  = "" ; 
            temp.serviceCode  = "" ; 
            temp.transactionMode  = element?.payment_Mode ; 
            temp.transactionStatus  =  element?.app_Status; 
            temp.transactionAmount  = element?.transaction_Amount ; 
            temp.requisitionId  = element?.app_No ; 
            temp.transactionID  = element?.app_No ; 
            temp.district  = element?.district_Name ; 
            temp.secretariateName  = element?.secretariat_Name ; 
            temp.serviceName  = element?.service_Desc ;
            temp.slaDays  = element?.slA_Days ;       ;       
            temp.transactionDate  = element?.request_Date ; 
            temp.applicantName  = "NA" ; 
            temp.departmentID  = element?.dept_App_No ; 
            temp.dept_App_No  = element?.dept_App_No ; 
            temp.transactionCategory  = "OLD GSWS";
            temp.transactionFrom  = "OLD GSWS";
        

            this.items.push(temp);
            this.tempItems.push(temp);
          });
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
 
  }

  getTransactionFromRedirectionMAUD(data:any){
       /* FOR MAUD REDIRECTION TRANSCATION */
   // data= "?UserId=U21028013-WEDS@apgsws.onmicrosoft.com&Department&FromDate=2021-12-06&ToDate=2021-12-18";
    this.commonService.getRequest(this.commonConstants.getMuadServiceRequest + data , 'MAUD'
    ).subscribe({
      next: (responseData: any) => {
      

        if(this.serchCriteria == 1){
          if( responseData.result.length === 0){
            this. getTransactionFromRedirectionENERGY(data);
          }
       }else{
         this. getTransactionFromRedirectionENERGY(data);
       }
       
        if(responseData.result){
                 responseData.result.forEach((element: any) => {

            let temp: any = {};
            temp.id = element.id;
            temp.applicantFatherHusbandName  = "";
            temp.mandal  = element?.mandal_Name ; 
            temp.mobileNumber  = ""; 
            temp.requisitionType  = "" ; 
            temp.serviceCode  = "" ; 
            temp.transactionMode  =  "-" ; 
            temp.transactionStatus  = ""; 
            temp.transactionAmount  = 0 ; 
            temp.requisitionId  = element?.requisitionId ; 
            temp.transactionID  = element?.requisitionId ; 
            temp.district  = ""; 
            temp.secretariateName  = element?.secretariatCode ; 
            temp.serviceName  = element?.serviceDescription ;
            temp.slaDays  = "" ;       ;       
            temp.transactionDate  = element?.created ; 
            temp.applicantName  = element?.userName ; 
            temp.departmentID  = element?.departmentCode ; 
            temp.dept_App_No  = element?.dept_App_No ; 
            temp.transactionCategory  = "other";
            temp.transactionFrom  = "MAUD Redirectional";
        
            this.items.push(temp);
            this.tempItems.push(temp);
          });
        }
    },
    error: (error) => {
      console.log('client side', error);
    },
   });
  }
  getTransactionFromRedirectionENERGY(data:any){
        /* FOR ENERGY REDIRECTION TRANSCATION */
  //  data= "?UserId=Test-DA@apgsws.onmicrosoft.com&Department&FromDate=2021-12-06&ToDate=2021-12-18";
    this.commonService.getRequest(this.commonConstants.getEnergyServiceRequest + data , 'MAUD'
    ).subscribe({
      next: (responseData: any) => {
       // console.log(responseData, '=========>getEnergyServiceRequest');
       if(this.serchCriteria == 1){
        if( responseData.result.length === 0){
          this.getTransactionFromMeeseva(data);
        }
     }else{
       this.getTransactionFromMeeseva(data);
     }
        if(responseData.result){
          responseData.result.forEach((element: any) => {

            let temp: any = {};
            temp.id = element.id;
            temp.applicantFatherHusbandName  = "";
            temp.mandal  = element?.mandal_Name ; 
            temp.mobileNumber  = ""; 
            temp.requisitionType  = "" ; 
            temp.serviceCode  = "" ; 
            temp.transactionMode  =  "-" ; 
            temp.transactionStatus  = ""; 
            temp.transactionAmount  = 0 ; 
            temp.requisitionId  = element?.requisitionId ; 
            temp.transactionID  = element?.requisitionId ; 
            temp.district  = ""; 
            temp.secretariateName  = element?.secretariatCode ; 
            temp.serviceName  = element?.serviceDescription ;
            temp.slaDays  = "" ;       ;       
            temp.transactionDate  = element?.created ; 
            temp.applicantName  = element?.userName ; 
            temp.departmentID  = element?.departmentCode ; 
            temp.dept_App_No  = element?.dept_App_No ; 
            temp.transactionCategory  = "other";
            temp.transactionFrom  = "ENERGY Redirectional";
        
            this.items.push(temp);
            this.tempItems.push(temp);
          });
        }
    },
    error: (error) => {
      console.log('client side', error);
    },
   });

  }
  getTransactionFromMeeseva(data:any) {
    this.commonService.getRequest(this.commonConstants.getMeesevaTransactions + data
    ).subscribe({
      next: (responseData: any) => {
       // console.log(responseData, '=========>getEnergyServiceRequest');
        if(responseData.result){
          responseData.result.forEach((element: any) => {

            let temp: any = {};
            temp.id = element.id;
            temp.applicantFatherHusbandName  = "";
            temp.mandal  = element?.mandal_Name ; 
            temp.mobileNumber  = ""; 
            temp.requisitionType  = "" ; 
            temp.serviceCode  = "" ; 
            temp.transactionMode  =  element?.transactionMode ; 
            temp.transactionStatus  = element?.transactionStatus ? element?.transactionStatus : '-'; 
            temp.transactionAmount  = element?.transactionAmount; 
            temp.requisitionId  = element?.requisitionId ; 
            temp.transactionID  = element?.transactionID ; 
            temp.district  = ""; 
            temp.secretariateName  = element?.secretariatCode ; 
            temp.serviceName  = element?.serviceName ;
            temp.slaDays  = element?.slaDays ;           
            temp.transactionDate  = element?.transactionDate ; 
            temp.applicantName  = element?.applicantName  ? element?.applicantName :'NA'; 
            temp.departmentID  = element?.departmentCode ; 
            temp.dept_App_No  = element?.dept_App_No ; 
            temp.transactionCategory  = "other";
            temp.transactionFrom  = "Meeseva";
        
            this.items.push(temp);
            this.tempItems.push(temp);
            console.log(JSON.stringify(this.items) + 'records from meeseva')
          });
        }
    },
    error: (error) => {
      console.log('client side', error);
    },
   });
  }
  searchTransaction() {
    let value = this.transactionFilterID.trim()?.toLowerCase();
   
      if (value !== null && value !== '') {
        let filteredItems = this.tempItems.filter((item: any) => item.transactionID?.toLowerCase().match(value) || item.serviceName?.toLowerCase().match(value) || item.applicantName?.toLowerCase().match(value));
      
        this.items = filteredItems;
        this.page = 1;
      } else {
        this.items = this.tempItems;
      }
  }

  getServiceListData(){
    this.serviceList = [];

    this.commonService
    .getRequest(this.commonConstants.getServiceList )
    .subscribe({
      next: (responseData: any) => {
            this.serviceList = responseData.result;
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  viewStatus(reqID:any, deptId:any) {
    /* const queryParams: Params = { id: reqID,department:deptId};
    this.router.navigate(['/transaction-status'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    }); */

    this.transactionPopup(reqID);
  }

 
  transactionStatus(reqID:any,reqType:any,deptId:any,tranID:any) {
    const queryParams: Params = { id: reqID,department:deptId,type:reqType,transactionID:tranID,from:'transaction'};
    this.router.navigate(['/payment-receipt'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }


  downloadDocuments(reqID:any,deptId:any,reqType:any) {
  const queryParams: Params = { id: reqID,department:deptId,type:reqType};
    this.router.navigate(['/application-preview'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
  viewPreview(reqID:any,deptId:any,reqType:any) {
  const queryParams: Params = { id: reqID,department:deptId,type:reqType};
    this.router.navigate(['/application-preview'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
 
 
  /* trasaction status start*/


  transactionStatusPopup(content:any, requisitionID:any,requisitionType:any,departmentCode:any,transactionID:any){
    
    //let postData = "?RequisitionId="+requisitionID+"&DepartmentCode="+departmentCode+"&TransactionId="+transactionID;
    this.transactionID = transactionID;
    this.requisitionID = requisitionID;
    this.is_categoryA = false;
    this.departmentName = "";
    if(departmentCode === "CS"){
      this.departmentName = "Civil Supplies";
    }
    if(departmentCode === "RD"){
      this.departmentName = "Panchayat Raj and Rural Development";
    }
    if(departmentCode === "MAUD"){
      this.departmentName = "MAUD";
    }
    if(departmentCode === "RV"){
      this.departmentName = "Revenue ";
    }
    if(departmentCode === "HF"){
      this.departmentName = "Health and Family Welfare";
    }
    if(departmentCode === "DEE"){
      this.departmentName = "Human Resource";
    }
    if(departmentCode === "MW"){
      this.departmentName = "Minorities Welfare";
    }
    if(departmentCode === "RS"){
      this.departmentName = "PR & MAUD";
    }
    if(departmentCode === "LD"){
      this.departmentName = "Labour";
    }
    
    let postData = {
      RequisitionId: this.requisitionID,
      DepartmentCode: departmentCode,
      TransactionId: this.transactionID
    } 
      this.commonService
        .postRequest(this.commonConstants.receiptPageData, postData,true)
        .subscribe({
          next: (responseData: any) => {              
          this.requisitionInfo = responseData?.result;          
          if(this.requisitionInfo.userName === "Unknown User"){
          var designation1 = this.userInfo.userPrincipalName.split('-')[1];
          
          var finalDes = designation1.split('@')[0];
          console.log(finalDes + 'designation')
          if(finalDes == 'DA'){
            this.requisitionInfo.userName = this.userInfo.userPrincipalName?.replace('DA','VRO');
          }else if(finalDes == 'WEDS'){
            this.requisitionInfo.userName = this.userInfo.userPrincipalName?.replace('WEDS','WRS');
          }
          console.log(this.requisitionInfo.userName)
          }
          this.dateTime = this.requisitionInfo.transactionDate;
          this.serviceName = departmentCode === "ES" ? this.requisitionInfo.serviceName :  this.requisitionInfo.serviceCode;
           this.applicantName =  this.titleCaseWord(this.requisitionInfo.applicantName);
          this.applicantFatherName = this.titleCaseWord(this.requisitionInfo.applicantFatherHusbandName) ;
          this.district = this.requisitionInfo.district ;
          this.mandal = this.requisitionInfo.mandal ;
          this.totalAmount = this.requisitionInfo.transactionAmount;
          this.paymentMode = this.requisitionInfo.transactionMode ;

          if(this.requisitionInfo.serviceCode == "RegistrationofTokens" || this.requisitionInfo.serviceCode == "Lamination" || this.requisitionInfo.serviceCode === 'HouseholdSplit'  || this.requisitionInfo.serviceCode === 'HouseholdSplit@1' || this.requisitionInfo.serviceCode === 'HouseholdSplit@2' || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment'
            || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment@1' || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment@2' || this.requisitionInfo.serviceCode === 'AddressCertificateForAadharEnrollment@3')
          {
            this.departmentName = "GSWS"
          }
          
          
          this.amountInwords = this.commonService.decimalWords(this.totalAmount);
         
          this.getByEntitycode(this.district, 'District');
          this.getByEntitycode(this.mandal, 'Mandal');

          this.certificateTypeName = ""
          this.commonConstants.certificateList.forEach((e: any) => {
            if(this.serviceName == e.certName)
            {
               this.certificateTypeName =e.certID
            }
          });
         
          //get service name
          for(let i=0; i < this.serviceList.length;i++){
            if(this.serviceList[i].serviceCode === this.serviceName){
              this.serviceName = this.serviceList[i].serviceName;
            }
          }
          this.SLA = "";
          this.transactionStatusData = "Pending";
          //check catA service or not
          for(let i=0; i < this.commonConstants.categoryAServices.length;i++){
            if(this.commonConstants.categoryAServices[i].serviceCode == this.requisitionInfo.serviceCode){
             this.is_categoryA = true;
             this.transactionStatusData = "Approved";
             if(this.requisitionInfo.serviceCode === 'UpdateAarogyaSriCard') {
              this.SLA = "24 Hours" ;
            } else {
              this.SLA = "15 Minutes" ;
            }
          }
         }
     
         if(this.is_categoryA == false){
          //get SLA for those which having workflows
            this.getSLAData(this.requisitionInfo.serviceCode);
            //this.getStatusOfService(requisitionID,requisitionType);
            this.getApplicationStatusForCatB(requisitionID);
         }
           this.transactionPopup(content);
          
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  transactionPopup(content: any) {
    this.modalService.open(content, {
              size: 'lg',
              ariaLabelledBy: 'modal-basic-title',
              windowClass: 'app-modal-window',
            })
            .result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getStatusOfService(reqID:any,reqType:any) {
     //check catB service or not
     this.commonService.getRequest(this.commonConstants.getWorkItemHistory + reqType + '&WorkItemReference=' + reqID).subscribe({
       next: (responseData:any) => { 

         let historyItems = responseData?.result?.items;
         if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 3) {
          
          this.transactionStatusData = "Approved";
        
         } else if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 4) {
         
          this.transactionStatusData = 'Rejected';
      
        }else{

           if(historyItems?.length > 0){
             this.transactionStatusData = "Pending with "+responseData.result.items[(responseData.result.items.length - 1)].workflowStepCode;
            
            }
           
           if(historyItems?.length === 0){
             this.transactionStatusData = "Pending with "+responseData.result.items[0].workflowStepCode;
           
            }
       
         }
       },
       error: (error) => {
         console.log('client side', error);
       }
     });
  }

  getApplicationStatusForCatB(applicationNo:any){

    this.transactionDataPopup = [];
    let postData ={
      ApplicationId : applicationNo
    }
    this.commonService
    .postRequest( this.commonConstants.getDashBoardApplicationDetails, postData, true)
    .subscribe({
      next: (responseData: any) => {
      
        console.log(responseData,"getDashBoardApplicationDetails")
  
        this.transactionDataPopup =  responseData.result;
        let result =  Array.isArray(this.transactionDataPopup);
        if(result && this.transactionDataPopup.length != 0 )
        { 
          this.transactionDataPopup.sort((a:any, b:any) => {
            var keyA = new Date(a.step_Order);
            var keyB = new Date(b.step_Order);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          this.transactionStatusData = this.transactionDataPopup[0].slA_Status
          
        }else{
         // this.alertPopupForNoRecords();
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  alertPopupForNoRecords() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = 'The entered application not found...Please enter valid application number.';
    modalRef.result.then((result: any) => {});
  }


  getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
    next: (responseData: any) => { 
        let name =  responseData.result[0]?.name;
        name = this.titleCaseWord(name) ;
        if (entityType === 'District' ) {``
          this.district = name;
        }else if (entityType === 'Mandal') {
          this.mandal = name; 
        } 
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
   
  getSLAData(ServiceCode:any){

    this.commonService
    .getRequest(this.commonConstants.getSLAData + "?ServiceCode="+ServiceCode)
    .subscribe({
      next: (responseData: any) => {
        if(responseData?.result != ""){
          if(responseData?.result === "No applicable workflow found"){
            if(ServiceCode === 'UpdateAarogyaSriCard') {
              this.SLA = "24 Hours" ;
            } else {
              this.SLA = "15 Minutes" ;
            }
          }else{
            this.SLA = responseData.result;
          }
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  titleCaseWord(word: string) {
    if (!word) return word;
     word = word.toLowerCase();
    var splitStr = word.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

   intToEnglishWordConverter(number: number): any {
    var NS = [
      { value: 10000000, str: 'Crore' },
      { value: 100000, str: 'Lakh' },
      { value: 1000, str: 'Thousand' },
      { value: 100, str: 'Hundred' },
      { value: 90, str: 'Ninety' },
      { value: 80, str: 'Eighty' },
      { value: 70, str: 'Seventy' },
      { value: 60, str: 'Sixty' },
      { value: 50, str: 'Fifty' },
      { value: 40, str: 'Forty' },
      { value: 30, str: 'Thirty' },
      { value: 20, str: 'Twenty' },
      { value: 19, str: 'Nineteen' },
      { value: 18, str: 'Eighteen' },
      { value: 17, str: 'Seventeen' },
      { value: 16, str: 'Sixteen' },
      { value: 15, str: 'Fifteen' },
      { value: 14, str: 'Fourteen' },
      { value: 13, str: 'Thirteen' },
      { value: 12, str: 'Twelve' },
      { value: 11, str: 'Eleven' },
      { value: 10, str: 'Ten' },
      { value: 9, str: 'Nine' },
      { value: 8, str: 'Eight' },
      { value: 7, str: 'Seven' },
      { value: 6, str: 'Six' },
      { value: 5, str: 'Five' },
      { value: 4, str: 'Four' },
      { value: 3, str: 'Three' },
      { value: 2, str: 'Two' },
      { value: 1, str: 'One' },
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return (
              this.intToEnglishWordConverter(t) +
              ' ' +
              n.str +
              ' ' +
              this.intToEnglishWordConverter(d)
            );
          } else {
            return this.intToEnglishWordConverter(t) + ' ' + n.str;
          }
        }
      }
    }
    return result;
  }

  /* trasaction status  end*/


  public printData(printId: any) {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat(printId));
    pwa?.document.close();
  }


  preparePrintFormat(printID: any) {
    const printContent: HTMLElement | null = document.getElementById(printID);
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>ChallanPrint</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';

    
    htmlData +=
      "<style type='text/css' media='print'> @media print{@page{size: A4 !important;margin: 11mm 17mm 17mm 17mm !important; }  table td, table th{ padding:10px !important; border:0.5px solid #ccc !important; } .ptCls{ margin:50px !important;} table {width:90% !important; margin:0 auto !important;} .btnHide{display:none !important;}.headCls{ text-align:center !important; padding :10px 0px !important;}.tableFixHead{height:auto !important;overflow:unset !important;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'><div class='headCls'><h3>Transaction Details<h3><br><div>";
    
    htmlData += printdata;
    htmlData += '<hr></body></html>';

    return htmlData;
  }

  goToHome() {
    this.router.navigate(['/home']);
  }


}
