import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NgbNavConfig, NgbModal, NgbNavChangeEvent, NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pensions-report',
  templateUrl: './pensions-report.component.html',
  styleUrls: ['./pensions-report.component.css']
})
export class PensionsReportComponent implements OnInit {

  form!: UntypedFormGroup;
  submitted: Boolean = false;
  showReports: Boolean = false;
  mandals: any[] = [];
  villages: any[]= [];
  commonConstants : any = CommonConstants;
  districts: any[] = [];
  format: any;
  defaultFromDate: any;
  currentDate = new Date();
  formatTypes: any[]= [];
  noOfCol: any = 47;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  totalItems: any[] = [];
  formattypestatus: any;
  resultitemsforstatus: any[] = [];
  itemsforstatus: any[] = [];
  statusformat: any;
  popupPageSize = 10;
  popupPage = 1;

  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('BEYONDTABLEFORSTATUS') beyondtableForStatus!: ElementRef;
  pensiontypes: any;
  role: any;

  constructor(private formBuilder: UntypedFormBuilder, public activeModal: NgbActiveModal, public config: NgbModalConfig, private modalService: NgbModal , private commonService: CommonService, private router: Router) { 
    this.format = 'DISTRICT WISE';
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null],
    });
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.format = 'DISTRICT WISE';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village: 'All',
       RUFlag: 'All',
     });
     this.formatTypes = [
        {name: 'DISTRICT WISE', isActive: true},
        {name:'MANDAL WISE', isActive: false},
        {name:'SECRETARIAT WISE', isActive: false},

      ]
    this.getDistricts();
    this.getpensionreportData();
    let userInfo = this.commonService.getLocalStorage('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
    }
  }

  onRuralUrbanChange() {
    if(this.f['district'].value !== 'All') {
      this.onDistrictChange();
    }
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + '').subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange() {
    this.showReports = false;
    this.villages = [];
    this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onVillageChange() {
    this.showReports = false;
  }

  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }

  getFormat(): any {
    if (this.format === 'DISTRICT WISE' ) {
      this.noOfCol = 47;
      return this.noOfCol;
    } else if (this.format === 'MANDAL WISE') {
      this.noOfCol = 51;
      return this.noOfCol;
    }  else{
      this.noOfCol = 53;
      return this.noOfCol;
    }
  }

  
  getpensionreportData(from?: any): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } else { 
        this.page = 1;
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
        let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
        let postData: any = '?FlagName='+ this.format +'&DistrictID=' + district + '&MandalId=' + mandal +'&SecCode=' + village + '&RUrban=' + RUFlag ;
        this.commonService.getRequest(this.commonConstants.getPENSIONReportData + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter((item: any) => item.totalflag !== 'Y')
                  this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')
            } else {
              this.showReports = true;
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
  }

  getpensionreportDataStatus(status: any, modal: any,formattype: any,from?: any, event?: any, districtCode?: any, mandalCode?: any,secretariatCode?: any, ruFlag?: any ): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } else { 
        let district = districtCode;
        let mandal = mandalCode;
        let village = secretariatCode;
        let RUFlag = ruFlag;
        let postData: any = '?FlagName='+ formattype +'&DistrictID=' + district  + '&MandalId=' + mandal +'&SecCode=' + village + '&RUrban=' + RUFlag + '&Service=' + from;
        this.commonService.getRequest(this.commonConstants.getPENSIONReportData + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.resultitemsforstatus = responseData?.result;
              this.formattypestatus = formattype;
              this.statusformat = status;
              this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'})
                } else {
              this.showReports = true;
              this.formattypestatus = formattype;
              this.statusformat = status;
              this.resultitemsforstatus = [];
              this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'})
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }

  }

  downloadExcel() {
    let fileName: any = 'Pensions Report' 
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'PensionsReport');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  downloadExcelForstatus() {
    let fileName: any = 'Pensions Sub-Report' 
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.beyondtableForStatus.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  ws['!cols'] = wscols;
    let sheetName = 'Pensions-Sub-Report'
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
 
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  get f() { return this.form.controls; }

}
