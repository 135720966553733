import { Component, OnInit, ViewChild, ElementRef, NgModule } from '@angular/core';
import { NgbNavConfig, NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter, CommonService, AadharValidator, WhitespaceValidator } from 'src/shared/common.service';
import { DatePipe } from '@angular/common';
import { CommonConstants } from 'src/constants/common-constants.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { fileExtensionValidator, fileResolutionValidator, fileSizeValidator,fileSizeValidatorForAarogyaSri } from 'src/shared/validators/file-validator';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { HealthViewModule } from 'src/app/health-view/health-view.module';
import * as CryptoJS from 'crypto-js'
import { FileService } from 'src/shared/file.service';

@Component({
  selector: 'app-aarogya-sri',
  templateUrl: './aarogya-sri.component.html',
  styleUrls: ['./aarogya-sri.component.css'],
  providers: [NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe 
  ]
})

// @NgModule({
//   declarations : [
//     HealthViewModule
//   ]
// })
export class AarogyaSriComponent implements OnInit {
  FileList : { FileName: string, DocumentType: string, BlobRef : string }[] = [];
  fileList: any = [];
  public submitted: Boolean = false;
  public commonFormSubmitted: Boolean = false;
  commonConstants : any = CommonConstants;
  aarogyaSri : UntypedFormGroup;
  genders: any[] = [];
  relations : any[] = [];
  maxDate: any;
  minDate: any;
  currentDate = new Date();
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[] = [];
  duplicatesRecords :Boolean = false;
  tempData: any[] = [];
  public FourWheeler : any[]= [];
  sumAnnualIncome : Number = 0;
  sumDryLand : Number = 0;
  sumWetLand : Number = 0;
  sumPropertyTax : Number = 0;
  sumFourWheeler : Number = 0;
  validExt = '';
  alertCount = 0;
  incomeValidation : Boolean = true;
  wetlandValidation : Boolean = true;
  drylandValidation : Boolean = true;
  propertyValidation : Boolean = true;
  fourWheelerValidation : Boolean = true;
  relationValidation : Boolean = true;
  selfAgeValidation : Boolean = true;
  releationSelf : boolean = false;
  showAge: number = 0;
  basicDetailsAadharNo : any = null;
  volunteerName :any;
  loggedInSecretariat: any;
  postData: any = {};
  public familyDetailsTableData : any[] = [];
  sixStepResponseData :any[]= [];
  sixStepResponseDatatoCheckFamilyData:any[]=[];
  sixStepResponseDatatoCheckProperNames:any[]=[];
  sixStepResponseDatatoCheckwithOutDeath:any[]=[];
  femaleRelations:any[]=[];
  maleRelations:any[]=[];
  isNewEnrollment : Boolean = false;
  basicInfo: any = [];
  certificateInfo: any = [];
  certificateName: string | undefined;
  items: any[] = [];
  tempItems: any = [];
  mailId: string = '';
  page = 1;
  pageSize = 10;
  statusofCard:any;
  existingApplictionId:any;
  existingUHID:any;
  mobileNo:any;
  ifFourWheeler:Boolean=false;
  isPhoto:Boolean=false;
  isNotAlive:any[]=[];
  isAlive:any[]=[];
  familyDoesNotHaveProperDate:Boolean=false;
  isMigrationReason:Boolean=false;
  invalidNamesMsg: string[] = [];
  postDataToCheck:any={};
  gridData : {
    memberName: string,
    gender: string,
    teluguName: string,
    dateOfBirth: any,
    familyAadharNo: string,
    relationwithHead: string,
    age: any,
    mobileNumber:any,
    isGovernment: string,
    isPayingIncomeTax: string,
    annualIncome: string,
    landStatus: string,
    wetLand: any,
    dryLand: any,
    isPayingPropertyTax: string,
    totalNoOfSqft: any,
    residentId:any,
    isFourWheeler: string,
    noOfFourWheelers: string,
    fourWheelerNumbers:any,
    IS_LIVING_WITHFAMILY:any,
    MigrationReason:any;
    salary:any
    empCategory:any
  } [] = [];
  decrypted:any=[];
  @ViewChild('familyPhotoLabel')
  familyPhotoLabel!: ElementRef ;
  @ViewChild('aadharCardLabel')
  aadharCardLabel!: ElementRef ;
  @ViewChild('nav')
  nav: any;
  reqId: string = '';
  list: any = [];
  totalCount: Number=0;
  familyMembers:any=[];
  imgSrc: any;
  showImg:Boolean=false;
  vehicleData :any;
  constructor(private config: NgbNavConfig, private formBuilder: UntypedFormBuilder, private commonService: CommonService, private router: Router, private modalService: NgbModal,private activatedRoute:ActivatedRoute , private searchFormBuilder: UntypedFormBuilder, private datePipe: DatePipe,private fileService:FileService) { 
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };

    let userInfo = this.commonService.getLocalStorage('userInfo');
    if (userInfo && userInfo !== null) {
     let userData = JSON.parse(userInfo);
      let userId = userData.userPrincipalName?.split('-');
      if (userId?.length > 0) {
        this.loggedInSecretariat = userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] : userId[0];
      }
    }

    this.aarogyaSri = this.formBuilder.group({
      commonForm: [],
      appDate: [''],
      district: [null],
      mandal : [null],
      villageWard : [null],
      doorNo : [''],
      locality : [''],
      pinCode : [''],
      totalIncome : [''],
      totalWetLand : [''],
      totalDryLand : [''],
      totalPropertyTax : [''],
      familyForm : [''],
      familyPhoto : [''],
      aadharCard : [''],
      appType : [''],
      reqID : [''],
      familyDetailsTableData : this.formBuilder.array([])
    })

    this.getGender();
    this.getRelation();
    this.getDistricts();
    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {
    let userInfo = this.commonService.getLocalStorage('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.mailId = userData.mail !== null ? userData.mail : userData.userPrincipalName;
     // this.existingSelected();
    }

    /*if(this.f['commonForm'].value['firstName'] !== null){
      this.f['commonForm'].value['firstName'].disable();
    }*/   

  }

  getCitizenInfo() {
      let postData: any = {};
      postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f['commonForm'].value['aadharNo'], true);
    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => {
        if(responseData?.result &&responseData?.result.length !==0)
        { 
        responseData.result.forEach((item: any) => {
          item.aadhaarNumber = item.aadhaarNumber;
        });
      }
       var finalDate = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
       this.f['appDate'].patchValue(finalDate)
       this.basicDetailsAadharNo = responseData?.result[0].aadhaarNumber;
       this.volunteerName = responseData?.result[0].volunteername;       
       this.sixStepResponseData = [];
       var sendPostData={
          CITIZEN_ID: this.commonService.RSAEncrypt(this.basicDetailsAadharNo, true),
        }

        let reqData = responseData?.result[0];
        this.f.district.patchValue(reqData?.district)
        this.f.mandal.patchValue(reqData?.mandalMunicipality)
        this.f.villageWard.patchValue(reqData?.villageWard)
        this.f.doorNo.patchValue(reqData?.doorNo)
        this.f.locality.patchValue(reqData?.streetName)
        this.f.pinCode.patchValue(reqData?.pinCode)

        this.commonService.postRequestForWebLand(this.commonConstants.newSixStepDetails, sendPostData , 'citizenInfo').subscribe({
          next: (responseData:any) => { 
            
            this.sixStepResponseData = responseData.result;
            let dob =this.f['commonForm'].value['dob'] 
            const convertAge = new Date(this.commonService.formatDate(dob));
             const timeDiff = Math.abs(Date.now() - convertAge.getTime());
             let age  = Math.floor(timeDiff / (1000 * 3600 * 24) / 365); 
             this.sixStepResponseDatatoCheckwithOutDeath=[];
             this.familyMembers=[];
             this.sixStepResponseDatatoCheckwithOutDeath = this.sixStepResponseData?.filter((item:any) => (item.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() !== "DEATH"));
             for (let i = 0; i < this.sixStepResponseDatatoCheckwithOutDeath.length; i++){ 
              this.familyMembers.push({'citizen_UID':this.commonService.RSAEncrypt(this.sixStepResponseDatatoCheckwithOutDeath[i].CITIZEN_ID)}); 
             }
             let id=this.basicDetailsAadharNo
             console.log(JSON.stringify(this.familyMembers) + 'familyMembers')
             this.postDataToCheck = {
              aadharNumbers:this.familyMembers
            }
             this.commonService.postRequest(this.commonConstants.checkCardStatus ,this.postDataToCheck).subscribe({
               next: (checkData:any) => {
                if(checkData.result){
                  this.statusofCard=checkData.result.isExisting;
                  this.existingApplictionId=checkData.result.existingApplictionId;
                  this.existingUHID=checkData.result.existingUHID;
                  console.log(this.statusofCard)
                  if(this.statusofCard === true  && this.existingUHID != null || this.existingApplictionId != null){
                    if(this.existingUHID !== null){ 
                      this.commonService.commonErrorPopup("Alert", 'One of the member of this House hold has Health Card with UHID  ' + this.existingUHID + ' Kindly update the details in existing card using Update Dr NTR Vaidya Seva Card Service.', "md")
                   
                  }else{
                    this.commonService.commonErrorPopup("Alert", 'Pending at DC with Application id ' + this.existingApplictionId, "md")
                  
                  }
                }
                else if(this.statusofCard === false  &&(this.existingUHID == null || this.existingApplictionId == null)){
            if(this.sixStepResponseData.length>0){
              let isValid: any;
              let teluguName:any;
              let isTeluguName:any=[];    
              let data:any=[];
              this.sixStepResponseDatatoCheckFamilyData=[];
              this.sixStepResponseDatatoCheckProperNames=[];
              this.sixStepResponseDatatoCheckProperNames = this.sixStepResponseData;
               this.sixStepResponseDatatoCheckFamilyData = this.sixStepResponseData.filter((item:any) => (item.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() !== "DEATH"));
               this.invalidNamesMsg = [];
               for (let i = 0; i < this.sixStepResponseDatatoCheckProperNames.length; i++){     
                  teluguName =[];    
                 // this.familyMembers.push({'CITIZEN_ID':encodeURIComponent(this.commonService.RSAEncrypt(this.sixStepResponseDatatoCheckFamilyData[i].CITIZEN_ID))}); 
                  teluguName =   this.sixStepResponseDatatoCheckProperNames[i].CITIZEN_NAME.match(this.commonConstants.namePattern)      
                  if(teluguName == null) {
                    this.invalidNamesMsg.push(this.sixStepResponseDatatoCheckProperNames[i].CITIZEN_NAME);
                  }
                  data.push(teluguName);
                }
                
                isTeluguName = data.filter((item:any) => (item === null));
                this.isNotAlive = this.sixStepResponseData.filter((item:any) => (item.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() == "DEATH"));
              this.isAlive = this.isNotAlive.filter(item => (item.CITIZEN_ID == this.f['commonForm'].value['aadharNo']))
                isValid=this.sixStepResponseDatatoCheckFamilyData.filter(item => (item.CITIZEN_NAME === "")|| (item.CITIZEN_NAME === null)||(item.CITIZEN_NAME === undefined) ||(item.CITIZEN_NAME === 'NA') || (item.RESIDENT_ID=== ""));
           
                if(this.isAlive.length === 0){


               // if(isValid.length === 0 &&  isTeluguName.length === 0){
                  if(age > 18){
                    if(isValid.length === 0 && isTeluguName.length ===0 ){
                      this.familyDoesNotHaveProperDate = false; // proper data
                    }else{                      
                      this.familyDoesNotHaveProperDate = true; // improper data
                    }
                    this.nav.select(2); 
                  }else{
                    
                    const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    mdRef.componentInstance.message = 'The Head of the family age must and should above 18 years!'
                 return
                  }
                        
              //  }
                //   else{
                //     const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                //     mdRef.componentInstance.message = 'One of the family members does not have proper data!'
                //   return;
                // }
            } else{
              this.commonService.commonErrorPopup("Alert", 'This Applicant status is death in house hold details.Please apply with other member aadhar in the family', "md")
            }
          }
            console.log(this.sixStepResponseData,'CITIZEN_ID info for six step');  
            if(this.sixStepResponseData.length > 0)
            {
            this.getVehicleDetails();                
            }
          }
          }       
        }})
            
          }
        })
      },
      error: (error) => {
        console.log('client side', error);
      }
    });  

    

  }
  
  get familyDetailsTableDatas() {
    return this.aarogyaSri.get('familyDetailsTableData') as UntypedFormArray;
  }

  async onFamilyPhotoSelected(fileEvent: any) {
    const familyPhotoFile: File | null = fileEvent.target.files[0];
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    console.log(fileExtensionType);
    this.imgSrc = fileExtensionType=== 'pdf' ? this.imgSrc="" : this.imgSrc;
    if (fileExtensionType.toLowerCase() === 'jpg') {
      this.validExt = 'jpg';
    } else if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } else{
      this.validExt = 'png';
    }
    this.familyPhotoLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    
       // code for resolution check
       const resolutionDimesion:any=  await this.fileService.readFile(fileEvent.target.files[0]);
       if(resolutionDimesion?.width>640){
        this.imgSrc=""
        this.f['familyPhoto'].patchValue('');
        this.isPhoto  = false;
        this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
        this.aarogyaSri.controls['familyPhoto'].setValidators([
          Validators.required
        ]);
        this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = 'Please upload image with 640 x 480 resolution or lower.'
        return;
       }


    this.aarogyaSri.controls['familyPhoto'].setValidators([
     fileExtensionValidator(this.validExt), fileSizeValidatorForAarogyaSri(fileEvent)
    ])
    this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
    if (this.f.familyPhoto.valid){
      this.onFileChange(fileEvent)
      this.fileUpload(familyPhotoFile,this.f['familyPhoto'].value,fileEvent.target.files[0].name,'Family Photo', 'familyPhoto', 'familyPhotoLabel');
  }
  else if(fileExtensionType=== 'pdf'){
    this.imgSrc=""
    this.f['familyPhoto'].patchValue('');
    this.isPhoto  = false;
    this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
    this.aarogyaSri.controls['familyPhoto'].setValidators([
      Validators.required
    ]);
    this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
    const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    mdRef.componentInstance.message = 'Please upload image in PNG/JPG/JPEG Format'
   return;
  }
    else{
    this.imgSrc=""
    this.f['familyPhoto'].patchValue('');
    this.isPhoto  = false;
    this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
    this.aarogyaSri.controls['familyPhoto'].setValidators([
      Validators.required
    ]);
    this.aarogyaSri.controls['familyPhoto'].updateValueAndValidity();
    const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    mdRef.componentInstance.message = 'File size exceeding 200KB.Please compress the image and reupload!'
   return;
  }

}

  onFileChange(event: any) {
    this.imgSrc='';
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imgSrc = e.target.result;
        this.showImg = true;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onAadharCardSelected(fileEvent: any) {
    const aadharCardFile: File | null = fileEvent.target.files[0];
    this.aadharCardLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
  if(this.aadharCardLabel.nativeElement.innerText !=="No File Choosen"){{}
    this.aarogyaSri.controls['aadharCard'].setValidators([
     fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)
    ])
    this.aarogyaSri.controls['aadharCard'].updateValueAndValidity();
  }
    if (this.f.aadharCard.valid)
      this.fileUpload(aadharCardFile,this.f['aadharCard'].value,fileEvent.target.files[0].name,'Aadhaar Card', 'aadharCard', 'aadharCardLabel');
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    // this.docUploaded = true;
    // console.log("doc uploaded" + this.docUploaded)
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{}");   
      formData.append('CertType', 'DHC')  
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => { 
        if(typeof(responseData.result) !== 'string'){
        let index = this.FileList.map((item) => item.DocumentType).indexOf(documentType);
        if (index > -1) {
          this.FileList.splice(index, 1);
        }
        this.FileList.push({
          FileName: fileName,
          DocumentType: documentType,
          BlobRef : responseData['result'].blobRef
        })}else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
        console.log(this.FileList,'FileList')
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }

  clearFileUpload(name:string, label:string) {
    this.f[name].reset();
    
    if (label === 'familyPhotoLabel') {
      this.showImg = false;
      this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
      this.aarogyaSri.controls[name].setValidators([
        Validators.required 
      ]);
      this.aarogyaSri.controls[name].updateValueAndValidity();
    }
    if (label === 'aadharCardLabel') {
      this.aadharCardLabel.nativeElement.innerText = 'No File Choosen';
      this.aarogyaSri.controls[name].clearValidators();
      this.aarogyaSri.controls[name].updateValueAndValidity();
    }
  }

  checkSecretariat() {
    let householdSecretariat: any;
    let householdDob:any;
    let householdGender:any;
    let householdCaste:any;
    let householdName:any;
    this.sixStepResponseData=[];
    let postData: any = {};
    this.familyDetailsTableDatas.clear();
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.commonForm.value['aadharNo'], true);

    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => { 

        console.log(responseData?.result,'my checkSecretariat response')
        if(responseData?.result && responseData?.result.length !==0)
         { 
          responseData.result.forEach((item: any) => {
            item.aadhaarNumber = item.aadhaarNumber;
          });
          householdSecretariat = responseData?.result[0]?.seccode;
          householdDob=responseData?.result[0]?.birthDate;
          householdGender=responseData?.result[0]?.gender;
          householdName=responseData?.result[0]?.firstName;
          householdCaste=responseData?.result[0]?.caste;
          //houseHoldId = responseData?.result[0]?.id;
          //volunteername =responseData?.result[0]?.volunteername;
          //volunteermobile =responseData?.result[0]?.volunteermobile;
          let dateFormat =  this.commonService.dobFormatDate(this.f['commonForm'].value['dob']);

          console.log('householdSecretariat', householdSecretariat);
          console.log('this.loggedInSecretariat', this.loggedInSecretariat);
          console.log('present address', this.f.commonForm.value['preVillage'].entityCode);
      //  this.nav.select(2);
      //       this.getEkycDetails();
    //  this.nav.select(2);
      
         
            if(dateFormat === householdDob && this.f['commonForm'].value['gender'].toUpperCase() ===householdGender 
            && this.f['commonForm'].value['firstName']+""+this.f['commonForm'].value['middleName'] ===householdName 
            ){            
              this.getCitizenInfo();               
          //  this.getVehicleDetails();
              //this.loadData();
            
             
            }else{
              const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
              modalRef.componentInstance.title = 'Alert';
              modalRef.componentInstance.message = 'As per the scheme guidelines, you are not authorized to change the Name,Date of Birth and Gender, please intimate citizen to update the house hold details through eKYC';

            }
            
         
         }
        else
          {
            const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'The applicant details are not available in Household data.';
          }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  // checkSecretariat() {
  //   let householdSecretariat: any;
  //   let householdDob:any;
  //   let householdGender:any;
  //   let householdCaste:any;
  //   let householdName:any;
    
  //   let postData: any = {};
    
  //   postData.aadhaarNumber = this.f.commonForm.value['aadharNo'];

  //   this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
  //     next: (responseData: any) => { 

  //       console.log(responseData?.result,'my checkSecretariat response')
  //       if(responseData?.result && responseData?.result.length !==0)
  //        { 
  //         householdSecretariat = responseData?.result[0]?.seccode;
  //         householdDob=responseData?.result[0]?.birthDate;
  //         householdGender=responseData?.result[0]?.gender;
  //         householdName=responseData?.result[0]?.firstName;
  //         householdCaste=responseData?.result[0]?.caste;
  //         //houseHoldId = responseData?.result[0]?.id;
  //         //volunteername =responseData?.result[0]?.volunteername;
  //         //volunteermobile =responseData?.result[0]?.volunteermobile;
  //         let dateFormat =  this.commonService.dobFormatDate(this.f['commonForm'].value['dob']);

  //         //console.log('householdSecretariat', householdSecretariat);
  //         //console.log('this.loggedInSecretariat', this.loggedInSecretariat);
  //         //console.log('present address', this.f.commonForm.value['preVillage'].entityCode);
  //     //  this.nav.select(2);
  //     //       this.getEkycDetails();

  //     if(dateFormat === householdDob && this.f['commonForm'].value['gender'].toUpperCase() ===householdGender 
  //           && this.f['commonForm'].value['firstName']+""+this.f['commonForm'].value['middleName'] ===householdName 
  //           ){
  //             this.nav.select(2);
  //             if(this.basicDetailsAadharNo == null)
  //           {
  //             this.getCitizenInfo();
  //           }
  //             //this.loadData();
  //           }
  //           else{
  //             const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
  //             modalRef.componentInstance.title = 'Alert';
  //             modalRef.componentInstance.message = 'As per the scheme guidelines, you are not authorized to change the Name,Date of Birth and Gender, please intimate citizen to update the house hold details through eKYC';

  //           }
  //         /*if (this.loggedInSecretariat?.trim() === householdSecretariat?.trim() && householdSecretariat?.trim() === this.f.commonForm.value['preVillage'].entityCode) 
  //         {
            
            
  //         } else {
  //           const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
  //           modalRef.componentInstance.title = 'Alert';
  //           modalRef.componentInstance.message = 'The applicant present address/household secretariat is not belongs to this Secretariat.';
  //         }*/
  //        }
  //       else
  //         {
  //           const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md'});
  //           modalRef.componentInstance.title = 'Alert';
  //           modalRef.componentInstance.message = 'The applicant details are not available in Household data.';
  //         }
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }

  viewFile(blobRef:any) {
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.showPrintDownload = true;
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  downloadFile(file:any) {
    this.commonService.getRequest(this.commonConstants.fileDownload + file.blobRef).subscribe({
      next: (responseData: any) => { 
          this.commonService.downloadPdfFile(responseData.result.fileContents, file.fileName)
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  id : any;
  taskId : any;

  getRequisitionDetails(item : any ,reqID : any)
  {
    console.log(item , "selected Item")
    this.taskId = item.id;
    console.log(this.taskId , "taskID")
    this.commonService.getRequest(this.commonConstants.getCertificateDetails +'HF&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ reqID).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'shams req data')
        this.basicInfo = responseData.result?.requisitionHeader;
        console.log(this.basicInfo?.applicantInfo?.mobileNumber,"New aarogyasri applicant phone number") 
        this.mobileNo = this.basicInfo?.applicantInfo?.mobileNumber;
        this.certificateInfo = responseData.result?.requisitionData;
        this.fileList = responseData.result?.fileList;
        this.certificateName = responseData.result?.requisitionHeader?.requisitionType;
        this.id = reqID
        // console.log(this.basicInfo , this.certificateInfo , this.fileList , this.certificateName)
      },
      error: (error) => {
        console.log('client side', error);
      },
    })
  }

  newEnrollSelected()
  {
    this.isNewEnrollment = true;
    this.f['district'].setValidators([
      Validators.required 
    ]);
    this.f['mandal'].setValidators([
      Validators.required 
    ]);
    this.f['villageWard'].setValidators([
      Validators.required 
    ]);
    this.f['doorNo'].setValidators([
      Validators.required 
    ]);
    this.f['locality'].setValidators([
      Validators.required
    ]);
    this.f['pinCode'].setValidators([
      Validators.required , Validators.pattern(this.commonConstants.pincodePattern)
    ]);
    this.f['familyForm'].setValidators([
      Validators.required
    ]);
    this.f['familyPhoto'].setValidators([
      Validators.required
    ]);
   
    this.f['district'].updateValueAndValidity();
    this.f['mandal'].updateValueAndValidity();
    this.f['villageWard'].updateValueAndValidity();
    this.f['doorNo'].updateValueAndValidity();
    this.f['locality'].updateValueAndValidity();
    this.f['pinCode'].updateValueAndValidity();
    this.f['familyForm'].updateValueAndValidity();
    this.f['familyPhoto'].updateValueAndValidity();

  }

  existingSelected()
  {
    this.isNewEnrollment = false;
    this.list = [];

    this.f['district'].clearValidators();
    this.f['mandal'].clearValidators();
    this.f['villageWard'].clearValidators();
    this.f['doorNo'].clearValidators();
    this.f['locality'].clearValidators();
    this.f['pinCode'].clearValidators();
    this.f['familyForm'].clearValidators();
    this.f['familyPhoto'].clearValidators();


    this.f['district'].updateValueAndValidity();
    this.f['mandal'].updateValueAndValidity();
    this.f['villageWard'].updateValueAndValidity();
    this.f['doorNo'].updateValueAndValidity();
    this.f['locality'].updateValueAndValidity();
    this.f['pinCode'].updateValueAndValidity();
    this.f['familyForm'].updateValueAndValidity();
    this.f['familyPhoto'].updateValueAndValidity();

    this.items = [];
    this.tempItems = [];
    this.page = 1;
   
    this.commonService.getRequest(this.commonConstants.getWorklist).subscribe({
      next: (responseData: any) => {
        
    for(let i = 0 ; i<responseData.result.items.length ; i++)
    {
      if(responseData['result'].items[i].extendedProperties.serviceCode === 'AarogyaSriNewCard' || responseData['result'].items[i].extendedProperties.serviceCode === 'UpdateAarogyaSriCard')
      {
        this.list.push(responseData['result'].items[i]);
      }
    }
    console.log(this.list , "list")
        // this.list = responseData['result'].items.extendedProperties;
        if (this.list && this.list.length > 0) {
          let i=1;
          this.list.forEach((element: any) => {
            if(element.extendedProperties.serviceCode === 'AarogyaSriNewCard' || element.extendedProperties.serviceCode === 'UpdateAarogyaSriCard')
            {
            let temp: any = {};
            let reqId: any[] = element.title.split(' ');
            temp.SNo = i++;
           // temp.id = element.id;
            temp.requisitionId = element.extendedProperties.requisitionId;
            temp.ApplicantName = element.extendedProperties.applicantName;
            // temp.DateApplied = element.extendedProperties.DateApplied;
            temp.DepartmentCode = element.extendedProperties.departmentCode;
            temp.RequestedDate = element.extendedProperties.requestedDate;
            temp.serviceName = element.extendedProperties.serviceCode;
         
           
            //temp.serviceFullName = this.getServiceName(element.extendedProperties.serviceCode);
          
            this.items.push(temp);
            this.tempItems.push(temp);
            console.log(element.extendedProperties.serviceCode,"test");
          }
          
          });
          this.totalCount = this.items.length;
         // this.getCertificateDetails(this.items[0]);
         this.items = this.tempItems
         console.log(this.items , "items")
         console.log(this.commonService.formatDate(this.items[0].RequestedDate))
        //  console.log()
        }
        else{
          if(this.items.length ==0){
           this.totalCount = 0;
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  ageCalculator(date: any) {
    const convertAge = new Date(this.commonService.formatDate(date));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    this.showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    return this.showAge;
  }

  dateChange(event : any , i : any)
  {
    console.log(event.target.value , "dateChange date change")
    const convertAge = new Date(this.commonService.formatDate(event.target.value));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    this.showAge = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    console.log("show age" , this.showAge)
    this.familyDetailsTableDatas.controls[i].get('tbAge')?.patchValue(this.showAge);

    if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
      this.showAge <= 18){
          this.selfAgeValidation = false;
          //console.log('I amm innn dateChangeToggle inavlid Zone...')
        }
        else if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
        this.showAge > 18) {
          this.selfAgeValidation = true
          //console.log('I amm innn dateChangeToggle valid Zone...')
        }

  }

  dateChangeToggle(event : any , i : any)
  {
    console.log(event.year , "dateChangeToggle date change")
    this.showAge = Number(this.currentDate.getFullYear()) - Number(event.year)
    console.log("show age" , this.showAge)
    this.familyDetailsTableDatas.controls[i].get('tbAge')?.patchValue(this.showAge);

    if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
      this.showAge <= 18){
          this.selfAgeValidation = false;
          //console.log('I amm innn dateChangeToggle inavlid Zone...')
        }
        else if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
        this.showAge > 18) {
          this.selfAgeValidation = true
          //console.log('I amm innn dateChangeToggle valid Zone...')
        }
  }

  numberMasking(aadhaar:any) {
    var mask = "";
    if(aadhaar){
      for(let j=1; j<=aadhaar.length-4; j++){
        mask += "x";
      }
      return mask + aadhaar.slice(8,12);
    }
    else return null;
  }

  getDetails()
  {
    this.familyDetailsTableDatas.clear();    
    this.submitted = false;
    console.log(JSON.stringify(this.sixStepResponseData) + 'six step in getdetails')
    this.sixStepResponseData.forEach(
      (element: { 
        CDMA: any
        CITIZEN_ID: any
        CITIZEN_NAME: any
        CLUSTER_ID: any
        DISTRICT_CODE: any
        DISTRICT_NAME: any
        DOB_DT: any
        DRY_LAND: any
        ELE_LAST_6MONTHS_UNITS: any
        EMP_STATUS: any
        EMP_CATEGORY:any
        Salary:any
        FOUR_WHEELER: any
        FourWheelerNumber:any
        NoOfFourWheelers:string
        GENDER: any
        GSWS_CODE: any
        HOUSEHOLD_ID: any
        INCOME_TAX: any
        MANDAL_CODE: any
        MANDAL_NAME: any
        MOBILE_NUMBER: any
        RESIDENT_ID: any
        SECRETARIAT_NAME: any
        WET_LAND: any
        isSelf: any
        IS_LIVING_WITHFAMILY:any
      } , index) => {
      
        let formGroup = this.formBuilder.group({
          tbName: element.CITIZEN_NAME,
          tbTeluguName : this.convertToTelugu(element.CITIZEN_NAME , index),
          tbGender :  element.GENDER ,
          tbRelation : "",
          tbDob : element.DOB_DT !==null && element.DOB_DT !== '' && element.DOB_DT !="Invalid date" ? element.DOB_DT :'',
          tbAge: element.DOB_DT !== null && element.DOB_DT !== ''&& element.DOB_DT !="Invalid date" ? this.ageCalculator(element.DOB_DT) : 0,
          tbMobileNo: element.MOBILE_NUMBER,
          tbAadharNo : element.CITIZEN_ID !== null ? element.CITIZEN_ID : null, // Removed Number conversion
          tbMaskAadharNo: this.numberMasking(element.CITIZEN_ID),
          tbPayingIncome : element.INCOME_TAX,
          tbAnnualIncome : "",
          tbLandStatus :   (Number(element.DRY_LAND) + Number(element.WET_LAND)) !== 0 ? 'YES' : 'NO',
          tbWetLandStatus : Number(element.WET_LAND) !== 0 ? 'YES' : 'NO',
          tbDryLandStatus : Number(element.DRY_LAND) !== 0 ? 'YES' : 'NO',
          tbWetLand : element.WET_LAND ? element.WET_LAND : "0",
          tbDryLand : element.DRY_LAND ? element.DRY_LAND : "0",
          tbPayingPropertyTax :element.CDMA !== '0' ? "YES" : 'NO',
          tbPropertyTax : element.CDMA ? element.CDMA : "0",
          tbFourWheeler : element.FOUR_WHEELER,
          tbFourWheelerNo : element.NoOfFourWheelers ?  element.NoOfFourWheelers:'0',
          tbFourWheelerNumbers:element.FourWheelerNumber,
          tbGovernmentPensioner : element.EMP_STATUS,
          tbGovernmentCategory:element.EMP_CATEGORY,
          tbSalary:element.Salary,
          tbSelf : false,
          residentId:element.RESIDENT_ID,
          IS_LIVING_WITHFAMILY:element.IS_LIVING_WITHFAMILY
          
        });
        this.familyDetailsTableDatas.push(formGroup)
      }
    ) 

  for(var i = 0; i<this.familyDetailsTableDatas.length ; i++)
    {
      if(this.f['commonForm'].value['aadharNo'] === 
      this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.value){
        this.familyDetailsTableDatas.controls[i].get('tbRelation')?.patchValue('SELF');
        this.familyDetailsTableDatas.controls[i].get('tbSelf')?.patchValue(true);
        //this.familyDetailsTableDatas.controls[i].get('tbRelation')?.disable();
        //this.releationSelf = true;
      }

      
      if(this.familyDetailsTableDatas.controls[i].get('tbPayingPropertyTax')?.value === 'YES'){
        this.sumPropertyTax = Number(this.sumPropertyTax) +  Number(this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.value);
        this.f['totalPropertyTax'].patchValue(this.sumPropertyTax)
      }
           

      if(this.familyDetailsTableDatas.controls[i].get('tbPayingIncome')?.value === 'YES'){
        this.sumAnnualIncome = Number(this.sumAnnualIncome) +  Number(this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.value);
        this.f['totalIncome'].patchValue(this.sumAnnualIncome);
      }

      if(this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.value !== '0'){
        this.sumDryLand = Number(this.sumDryLand) +  Number(this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.value);
        this.f['totalDryLand'].patchValue(this.sumDryLand);
      }

      if(this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.value !== '0'){
        this.sumWetLand = Number(this.sumWetLand) +  Number(this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.value);
        this.f['totalWetLand'].patchValue(this.sumWetLand);
      }

      /*if(this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value !== 'NO'){
        this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.disable();
      }*/

     
    }

    for(var i = 0; i<this.familyDetailsTableDatas.length ; i++)
    {
      if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
         this.familyDetailsTableDatas.controls[i].get('tbAge')?.value <= '18'){
          this.selfAgeValidation = false;
          //console.log('I amm innn the inavlid Zone...')
        }
        else if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF' &&
        this.familyDetailsTableDatas.controls[i].get('tbAge')?.value > '18') {
          this.selfAgeValidation = true
        }
    }  
}



translatedText : any;
  
convertToTelugu(teluguName : any , index : any)
  {
    let obj =
    {
      Txt_English : (teluguName).toLowerCase()
    }
    this.commonService.postRequestForWebLand(this.commonConstants.teluguTranslator ,obj).subscribe({
      next: (responseData: any) => {
        // console.log(responseData, "bbb");
        this.translatedText = responseData.d[0]
        console.log(this.translatedText)
        this.familyDetailsTableDatas.controls[index].get('tbTeluguName')?.patchValue(this.translatedText);
      }
    })   
  }

  checkText(event : any , index : any)
  {
    let regex = '/?/g'
    if(event?.target?.value?.includes("?"))
    {
      let result = event.target.value.replace(event.target.value, "");
      console.log(event.target.value , "event.target.value",result)
      this.familyDetailsTableDatas.controls[index].get('tbTeluguName')?.setValue(result)
    }  
  } 

  relationValidator(i : any){

    var cnt : number = 0;
   
      if (this.f['commonForm'].value['aadharNo'] !== this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.value){
        if(this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'SELF'){
          this.relationValidation = false;
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = "Other members cannot be self!";
          modalRef.result.then((result: any) => {
           if (result) {
              console.log(result);
           }
      });
        }
        else{
          for(var j = 0; j<this.familyDetailsTableDatas.length ; j++){
            if (this.f['commonForm'].value['aadharNo'] !== this.familyDetailsTableDatas.controls[j].get('tbAadharNo')?.value
                && this.familyDetailsTableDatas.controls[j].get('tbRelation')?.value !== 'SELF'){
                cnt++;
                if (cnt === this.familyDetailsTableDatas.length-1){
                  this.relationValidation = true;
                  //console.log(cnt,'cnt');
                  //console.log(this.familyDetailsTableDatas.length,'cnttttt');
                }
            }
          }
         
        }
      }
    
  }

  totalFourWheeler(value:any,i :any)
  {
    if(value === 0|| value === null || value === ""){
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.patchValue('NO');
    }
    else{
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.patchValue('');
      this.sumFourWheeler = 0;
      for(let i = 0 ; i < this.familyDetailsTableDatas.length ; i++)
      {
        this.sumFourWheeler = Number(this.sumFourWheeler) +  Number(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value)
      }
      if(this.sumFourWheeler > 1)
      {
        this.fourWheelerValidation = false;
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = "As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant Total number of four wheeler beyond the eligibility";
        modalRef.result.then((result: any) => {
          if (result) {
            console.log(result);
          }
        });
      }
      else
      {
        this.fourWheelerValidation = true
      }
    }
   
    
  }
  fourWheelerOnSubmit(){
    this.sumFourWheeler = 0;
    for(let i = 0 ; i < this.familyDetailsTableDatas.length ; i++)
    {
      this.sumFourWheeler = Number(this.sumFourWheeler) +  Number(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value)
    }
    if(this.sumFourWheeler > 1)
    {
      this.fourWheelerValidation = false;     
    }
    else
    {
      this.fourWheelerValidation = true
    }
  }
  totalPropertyCalculator(event : any)
  {
    this.sumPropertyTax = 0;
    for(let i = 0 ; i < this.familyDetailsTableDatas.length ; i++)
    {
      this.sumPropertyTax = Number(this.sumPropertyTax) +  Number(this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.value)
    }
    this.f['totalPropertyTax'].patchValue(this.sumPropertyTax)
    if(this.sumPropertyTax >= 3000)
    {
      this.propertyValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = "As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant property beyond the eligibility";
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    else
    {
      this.propertyValidation = true;
    }
    // console.log(this.totalPropertyTax)
  }

  totalDryLandCalculator(event : any)
  {
    this.sumDryLand = 0;
    for(let i = 0 ; i < this.familyDetailsTableDatas.length ; i++)
    {
      this.sumDryLand = Number(this.sumDryLand) +  Number(this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.value)
    }
    this.f['totalDryLand'].patchValue(this.sumDryLand)
    if(Number(this.sumDryLand) > 35)
    {
      this.drylandValidation = false
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = "As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant dry land beyond the eligibility";
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    else if(Number(this.sumWetLand) + Number(this.sumDryLand) > 35)
    {
      this.drylandValidation = false;
      this.wetlandValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = "As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant total land beyond the eligibility";
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    else if(Number(this.sumDryLand) <= 35 && Number(this.sumWetLand) < 12)
    {
      this.drylandValidation = true;
      this.wetlandValidation = true;
    }
  }

  totalWetLandCalculator(event : any)
  {
    this.sumWetLand = 0;
    for(let i = 0 ; i < this.familyDetailsTableDatas.length ; i++)
    {
      
      this.sumWetLand = Number(this.sumWetLand) +  Number(this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.value)
    }
    this.f['totalWetLand'].patchValue(this.sumWetLand)
  
    if(Number(this.sumWetLand) >= 12)
    {
      this.wetlandValidation = false
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = "As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant wet land beyond the eligibility";
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    else if(Number(this.sumWetLand) + Number(this.sumDryLand) > 35)
    {
      this.drylandValidation = false
      this.wetlandValidation = false;
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = "As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant total land beyond the eligibility";
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    /*else
    {
      this.wetlandValidation = true;
    }*/
    else if(Number(this.sumDryLand) <= 35 && Number(this.sumWetLand) < 12)
    {
      this.drylandValidation = true;
      this.wetlandValidation = true;
    }
  }

  changeConsent(){
    //console.log(this.aarogyaSri.controls['consent'].value,'consent cgange')
    if(this.aarogyaSri.controls['familyForm'].value === false){
      this.aarogyaSri.controls['familyForm'].reset();
    this.aarogyaSri.controls['familyForm'].setValidators(Validators.required);
    this.aarogyaSri.controls['familyForm'].updateValueAndValidity();
    }
  }

  totalIncomeCalculator(event : any)
  {
    this.sumAnnualIncome = 0;
    for(let i = 0 ; i < this.familyDetailsTableDatas.length ; i++)
    {
      this.sumAnnualIncome = Number(this.sumAnnualIncome) +  Number(this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.value)
      /*if(this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.value > 500000 
      && this.familyDetailsTableDatas.controls[i].get('tbGovernmentPensioner')?.value != "Y")
      {
        this.incomeValidation = false
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = "Employee other than Government Employee/Pensioner must have income upto 500000";
        modalRef.result.then((result: any) => {
          if (result) {
            console.log(result);
        }
      });
      }*/
    }
    this.f['totalIncome'].patchValue(this.sumAnnualIncome)
    if(this.sumAnnualIncome > 500000)
    {
      this.incomeValidation = false
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = "As per eligible criteria of Aarogyasri G.O.Rt.No.628 the family income beyond the eligibility";
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    else
    {
      this.incomeValidation = true;
    }
  }

  get f(){ return this.aarogyaSri.controls; }

  
  
  
  
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;     
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      mdRef.componentInstance.message = 'Please enter the mandatory fields!'       
      changeEvent.preventDefault();
    }
    else if (changeEvent.nextId === 2 && this.f.commonForm.valid) { 
      changeEvent.preventDefault();
      this.checkValidation()
    }
  }
    checkValidation(){       
        this.f['totalIncome'].patchValue('');
        this.f['totalWetLand'].patchValue('0');
        this.f['totalDryLand'].patchValue('0');
        this.f['totalPropertyTax'].patchValue('0');
        this.f['aadharCard'].patchValue('');
        this.f['familyPhoto'].patchValue('');
        this.f['familyForm'].reset();
        this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
        this.aadharCardLabel.nativeElement.innerText='No File Choosen';
        this.aarogyaSri.controls['aadharCard'].clearValidators();
        this.aarogyaSri.controls['aadharCard'].updateValueAndValidity();
        this.sumPropertyTax = 0;
        this.sumAnnualIncome = 0;
        this.sumDryLand =0;
        this.sumWetLand =0;
        this.f['pinCode'].patchValue(this.f.pinCode.value ? this.f.pinCode.value :this.f['commonForm'].value['permPinCode'])
        this.showImg = false;      
        this.checkSecretariat();    
         }
                       
            
         
   
  goToNextTab() {
    this.commonFormSubmitted = true;
    this.f['totalIncome'].patchValue('');
    this.f['totalWetLand'].patchValue('0');
    this.f['totalDryLand'].patchValue('0');
    this.f['totalPropertyTax'].patchValue('0');
    this.f['aadharCard'].patchValue('');
    this.f['familyPhoto'].patchValue('');
    this.f['familyForm'].reset();
    this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
    this.aadharCardLabel.nativeElement.innerText='No File Choosen';
    this.aarogyaSri.controls['aadharCard'].clearValidators();
      this.aarogyaSri.controls['aadharCard'].updateValueAndValidity();
    this.sumPropertyTax = 0;
    this.sumAnnualIncome = 0;
    this.sumDryLand =0;
    this.sumWetLand =0;
    //this.f['familyPhoto'].reset();
    this.f['pinCode'].patchValue(this.f.pinCode.value ? this.f.pinCode.value :this.f['commonForm'].value['permPinCode'])
    this.showImg = false;
    if (this.f.commonForm.valid) {    
        this.checkSecretariat();
    }
    else{
      this.commonFormSubmitted = true;
      const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      mdRef.componentInstance.message = 'Please enter the mandatory fields!'
    }
  }
//   onReset(){    
// this.volunteerName ='';
// //this.f['appDate'].reset();
// this.aarogyaSri.reset();

// this.f['totalIncome'].patchValue('');
// this.f['totalWetLand'].patchValue('');
// this.f['totalDryLand'].patchValue('');
// this.f['totalPropertyTax'].patchValue('');
// this.sumPropertyTax = 0;
// this.sumAnnualIncome = 0;
// this.sumDryLand =0;
// this.sumWetLand =0;
//   }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => { 
        this.districts = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  /*onDistrictChange(event: any,name: string) {
    console.log('district',this.f[name].value.id,this.f[name].value)
    this.commonService.getRequest(this.commonConstants.getMandals + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
          this.mandals = this.commonService.sortData(responseData.result);
          this.villages = [];  
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  onMandalChange(event: any,name: string) {
    this.commonService.getRequest(this.commonConstants.getVillages + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
          this.villages = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }*/

  getGender() {
    this.commonService.getRequest(this.commonConstants.getGender).subscribe({
      next: (responseData: any) => {
        this.genders = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getRelation() {
    this.commonService.getRequest(this.commonConstants.getRelationsAarogyasri).subscribe({
      next: (responseData: any) => {
        this.relations = this.commonService.sortData(responseData.result);        
        this.relations = this.relations.filter(item => item.name !== 'OTHERS');
        this.femaleRelations=[{"name": "HUSBANDS SISTER"}, {"name"  :"GRAND DAUGHTER"},
        {"name" : "MOTHER"}, {"name" :  "MOTHER IN LAW"},{"name": "DAUGHTER"},
        {"name": "AUNT(FATHERS SISTER)"}, {"name": "WIFE"},{"name": "SISTER"},
        {"name": "GRAND MOTHER(FATHERS SIDE)"},
        {"name": "WIFE OF HUSBANDS ELDER BROTHER"},{"name": "DAUGHTER IN LAW"},
      {"name": "BROTHERS  WIFE"},{"name": "SELF"}];
    

this.maleRelations=[{"name" :"SON"}, {"name" : "GRANDFATHER(FATHERS SIDE)"},
      {"name" : "UNCLE(MOTHERS BROTHER)"},{"name": "HUSBAND"},
      {"name": "GRAND SON"},{"name": "SON IN LAW"}, {"name": "SISTERS HUSBAND"},
     {"name": "FATHER IN LAW"},{"name": "FATHER"},
      {"name": "BROTHER IN LAW"},{"name": "BROTHER"},{"name": "SELF"}
     ]  
      },
      error: (error) => {
        console.log('client side', error);
      },
    });

    /*for(var i = 0; i<this.familyDetailsTableDatas.length ; i++)
    {
      if(this.f['commonForm'].value['aadharNo'] === this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.value){
        this.familyDetailsTableDatas.controls[i].get('tbRelation')?.patchValue('SELF');
      }
    }*/

  }


  validationSetter()
  {
    for(var i = 0; i<this.familyDetailsTableDatas.length ; i++)
    {

      this.familyDetailsTableDatas.controls[i].get('tbGender')?.setValidators([
        Validators.required 
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbRelation')?.setValidators([
        Validators.required 
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbDob')?.setValidators([
        Validators.required 
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.setValidators([
        Validators.required , AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.setValidators([
        Validators.required ,
        Validators.minLength(10),
        Validators.pattern(this.commonConstants.mobilePattern),
        WhitespaceValidator.whiteSpaceValidate
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.setValidators([
        Validators.required 
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.setValidators([
        Validators.required , Validators.pattern(this.commonConstants.decimalPatternforland)
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.setValidators([
        Validators.required , Validators.pattern(this.commonConstants.decimalPatternforland)
      ]);
      this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.setValidators([
        Validators.required ,Validators.pattern(this.commonConstants.decimalPatternforland)
      ]);
     
             
      if(this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'NO' &&
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value > 0  &&  
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value  === ''){
        this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.setValidators([
          Validators.required,
          Validators.pattern(this.commonConstants.fourwheelerNumber)
       ]);

      }else{
        this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.updateValueAndValidity();
      }
      if(this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH'){
        this.familyDetailsTableDatas?.controls[i]?.get('residentId')?.patchValue(this.familyDetailsTableDatas?.controls[i]?.get('residentId')?.value ? 
        this.familyDetailsTableDatas?.controls[i]?.get('residentId')?.value : '');
        this.familyDetailsTableDatas?.controls[i]?.get('tbAadharNo')?.patchValue(this.familyDetailsTableDatas?.controls[i]?.get('tbAadharNo')?.value  ? 
        this.familyDetailsTableDatas?.controls[i]?.get('tbAadharNo')?.value: '');
        this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.updateValueAndValidity();
        this.familyDetailsTableDatas.controls[i].get('tbGender')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbRelation')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbDob')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.setValidators([
          Validators.required , AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate
        ]);
        // this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.setValidators([
          
        //   Validators.minLength(10),
        //   Validators.pattern(this.commonConstants.mobilePattern),
        //   WhitespaceValidator.whiteSpaceValidate
        // ]);
        this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.clearValidators();
        this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.clearValidators();
       
      }
     
      this.familyDetailsTableDatas.controls[i].get('tbGender')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbAadharNo')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbRelation')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbDob')?.updateValueAndValidity();     
      this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.updateValueAndValidity();    
      this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.updateValueAndValidity();
      this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.updateValueAndValidity();

    }
  }

  addGridDataValuesinArray(){
    this.tempData = [];
    this.gridData = []; 
    this.familyDetailsTableDatas.value.forEach((element: { tbAadharNo:any ; tbName: any; tbTeluguName: any; tbGender:any; 
      tbDob: any; tbMobileNo: any; tbRelation : any; tbAge : any; tbPayingIncome : any; tbLandStatus : any ; tbWetLand : any ; tbDryLand : any ; tbPayingPropertyTax : any; 
      tbPropertyTax : any ; tbFourWheeler : any ; tbFourWheelerNo : any ; tbFourWheelerNumbers:any; tbGovernmentPensioner : any ; tbAnnualIncome : any; residentId:any; IS_LIVING_WITHFAMILY:any;
      tbGovernmentCategory:any ; tbSalary:any}) => {
      
        this.tempData.push({
          aadhar: element.tbAadharNo,
        });
      let gridDate =  element.tbDob != '' ? this.commonService.formatDate(element.tbDob) : null;
      return this.gridData.push(
        {
          memberName: element.tbName,
          gender: element.tbGender,
          teluguName: element.tbTeluguName,
          dateOfBirth: gridDate ,
          familyAadharNo: this.commonService.RSAEncrypt(element.tbAadharNo),
          relationwithHead: element.tbRelation,
          age: element.tbAge,
          mobileNumber:element.tbMobileNo ? element.tbMobileNo : this.mobileNo,
          isGovernment: element.tbGovernmentPensioner,
          isPayingIncomeTax: element.tbPayingIncome,
          annualIncome: element.tbAnnualIncome,
          landStatus : element.tbLandStatus,
          wetLand: element.tbWetLand,
          dryLand: element.tbDryLand,
          isPayingPropertyTax: element.tbPayingPropertyTax,
          totalNoOfSqft: element.tbPropertyTax,
          isFourWheeler: element.tbFourWheeler,
          noOfFourWheelers: element.tbFourWheelerNo,
          fourWheelerNumbers:element.tbFourWheelerNumbers,
          residentId:element.residentId,
          IS_LIVING_WITHFAMILY:element.IS_LIVING_WITHFAMILY,
          MigrationReason:(element.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() === 'DEATH') ? 'Death': null,
          salary:element.tbSalary,
          empCategory:element.tbGovernmentCategory

        }
      ) 
    });

    this.duplicatesRecords = this.tempData.some(user => {
      let counter  = 0;
      for (const iterator of this.tempData) {
        if (iterator.aadhar === user.aadhar && iterator.aadhar !== null) {
          counter += 1;
        }
      }
      return counter > 1;
    });
  }

  updateFileUpload(id: any) {
    let fileData = {
      FileList: this.FileList,
      RequisitionId: id
    }
    
    if(this.FileList.length > 0 && this.FileList.findIndex((c:any)=> c.DocumentType == 'Family Photo' )> -1){
    
    this.commonService.postRequest(this.commonConstants.HealthCardFileUpload,fileData).subscribe({
      next: (resData:any) => { 
        console.log(resData,'File Upload  Respose data');
        if(resData.result?.updateResult == 'Success') {
          this.showPayment(id);
        }else{
          this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
        }
        
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }else{
    this.commonService.commonErrorPopup('Alert', 'Family Photo is missed, Please re-upload ', 'md');
  }
  }

  showPayment(requisionId:any) {
    this.router.navigate(['/payment-gateway']);
    const queryParams: Params = { id: requisionId,department: 'HF' ,type:'AarogyaSriNewCard'};
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', 
    });
  }
  
  submit():any
  { 
   // this.wetlandValidation  = true;
    //this.drylandValidation  = true;   
    // let migrationReason:any=0;
    // for(let i = 0; i < this.familyDetailsTableDatas.length; i++)
    // {
    //   if(this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value =='Death')
    //   {        
    //     migrationReason += 1
    //   }
    // }
    // this.isMigrationReason  = migrationReason === 0 ? false : true;

    if(this.familyDoesNotHaveProperDate === false ){

    
    this.alertCount =0;
    if(this.isNewEnrollment)
    {
      this.validationSetter(); 
    }
    this.gridData = [];
    this.addGridDataValuesinArray();          
   this.fourWheelerOnSubmit();
    this.submitted = true;
    this.f['pinCode'].patchValue(this.f.pinCode.value ? this.f.pinCode.value :this.f['commonForm'].value['permPinCode'])
    let govtEmpOrPensionerValidation = 0
    for(let i = 0; i < this.familyDetailsTableDatas.length; i++)
    {
      var selectedGender = this.familyDetailsTableDatas.controls[i].get('tbGender')?.value;
      var selectedRelation = this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value;
      if(  (this.familyDetailsTableDatas.controls[i].get('tbGovernmentPensioner')?.value === 'YES')  &&((
       (  (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value !== 'Govt Employee') || (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value !== 'Pension') || (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value !== '')   ) &&
        (  (12 * Number(this.familyDetailsTableDatas.controls[i].get('tbSalary')?.value)) > 500000)) || 
        (this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value === 'Govt Employee' || this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value === 'Pension')) && this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() !== 'DEATH')
      {
        govtEmpOrPensionerValidation += 1
      }
   
    }
    
    if(!this.aarogyaSri.valid){
      const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      mdRef.componentInstance.message = 'Please enter the mandatory fields!'
      return false
    }
    else if(this.duplicatesRecords)
    {
      const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      mdRef.componentInstance.message = 'Duplicate Aadhar entered!'
      return false

    }else if(govtEmpOrPensionerValidation !== 0){
      this.alertCount += 1
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message =  "As per eligible criteria of Aarogyasri G.O.Rt.No.628 Family having Government Employee/Pensioner,so application has been ineligible";
   return false
    }
    else if(!this.incomeValidation || this.f['totalIncome'].value > 500000)
    {
      console.log(this.incomeValidation , "incomeValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant income beyond the eligibility")
   return false
    }
    else if(!this.wetlandValidation || this.f['totalWetLand'].value  >= 12)
    {
      console.log(this.wetlandValidation , "wetlandValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant wet land or total land beyond the eligibility")
    return false
    }
    else if(!this.drylandValidation || this.f['totalDryLand'].value  > 35)
    {
      console.log(this.drylandValidation , "drylandValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant dry land or total land beyond the eligibility")
   return false
    }
    else if( !this.propertyValidation || this.f['totalPropertyTax'].value  >= 3000)
    {
      console.log(this.propertyValidation , "propertyValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant property beyond the eligibility")
    return false
    }
    else if( !this.fourWheelerValidation )
    {
      console.log(this.fourWheelerValidation , "fourWheelerValidation")
      this.InvalidFiveStepValidation("As per eligible criteria of Aarogyasri G.O.Rt.No.628 the applicant four wheelers beyond the eligibility")
  return false
    }
    else if( !this.relationValidation )
    {
      console.log(this.relationValidation , "relationValidation")
      this.InvalidFiveStepValidation("other family members are declared as SELF")
   return false
    }
    else if( !this.selfAgeValidation )
    {
      console.log(this.selfAgeValidation , "selfAgeValidation")
      this.InvalidFiveStepValidation("Self should be above 18 years of age")
   return false
    }
  
    else
    {      
     
      if(this.alertCount == 0)
      {
      let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
      this.postData = {
        requisitionHeader: {
          requisitionType: 'AarogyaSriNewCard',
          department: 'HF',
          applicantInfo: {
            id: '',
            aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo'].trim(), // CryptoJS.AES.encrypt(this.f['commonForm'].value['aadharNo'].trim(), this.commonConstants.encryptDecryptPassword.trim()).toString(), //this.f['commonForm'].value['aadharNo'],
            personNames: [
              {
                languageCode: 'en-in',
                firstName: this.f['commonForm'].value['firstName'],
                middleName: this.f['commonForm'].value['middleName'],
                lastName: this.f['commonForm'].value['lastName'],
                fatherHusbandName:
                  this.f['commonForm'].value['fatherHusName'],
              },
            ],
            gender: this.f['commonForm'].value['gender'],
            birthDate: dateFormat,
            caste: this.f['commonForm'].value['caste'].name,
            religion: this.f['commonForm'].value['religion'].name,
            qualification: this.f['commonForm'].value['qualification'].name,
            maritalStatus: this.f['commonForm'].value['maritalStatus'],
            addresses: [
              {
                addressType: 'present',
                doorNo: this.f['commonForm'].value['preDoorNo'],
                streetName: this.f['commonForm'].value['preStreet'],
                villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                district: this.f['commonForm'].value['preDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['prePinCode'],
                postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
              },
              {
                addressType: 'permanent',
                doorNo: this.f['commonForm'].value['permDoorNo'],
                streetName: this.f['commonForm'].value['permStreet'],
                villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                district: this.f['commonForm'].value['permDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['permPinCode'],
                postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
              },
            ],
            rationCardNo: '',
            deliveryType: this.f['commonForm'].value['deliveryType'],
            emailAddress: this.f['commonForm'].value['email'],
            mobileNumber: this.f['commonForm'].value['mobileNo'],
            isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
            isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
          },
          remarks: '',
        },
        requisitionData: {
          familyDetails: this.gridData,
          familyAddress: {
            addressType: 'present',
            doorNo: this.f['doorNo'].value,
            streetName: this.f['locality'].value,
            villageWard: this.f['villageWard'].value,
            mandalMunicipality: this.f['mandal'].value,
            district: this.f['district'].value,
            pinCode: this.f['pinCode'].value
          },
          isDeclared: "true",
          totalFamilyIncome: this.f['totalIncome'].value,
          totalFamilyWetland: this.f['totalWetLand'].value?this.f['totalWetLand'].value.toString():"0", // Converted to String
          totalFamilyDryland: this.f['totalDryLand'].value?this.f['totalDryLand'].value.toString():"0", // Converted to String
          totalPropertyTax: this.f['totalPropertyTax'].value?this.f['totalPropertyTax'].value.toString():"0",
          applicationDate: this.currentDate,
          volunteerName: this.volunteerName
        }
      }
       let selfValidations:any=[]; 
     
      selfValidations = this.gridData.filter((s:any) => (s.relationwithHead === "SELF"));     
      if(selfValidations.length == 1){
      if(this.postData?.requisitionData?.familyDetails?.length > 0  && (this.FileList.length > 0 &&  this.f.familyPhoto.valid &&( this.f.familyPhoto.value != '' && this.f.familyPhoto.value != null && this.f.familyPhoto.value != undefined  ))){
      this.commonService.postRequest(this.commonConstants.aarogyasriNewHealthCard, this.postData).subscribe({
        next: (responseData:any) => { 
          console.log(responseData,'Aarogya sri new health card respose data');
            console.log(responseData.status,'stats');
            this.reqId = responseData['result'].id;
            this.updateFileUpload(responseData['result'].id)
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }else{
      this.commonService.commonErrorPopup("Alert",'Family details or Image are missing Unable to proceed' , "md")
    
    return false
  }
      }else{
        this.commonService.commonErrorPopup("Alert",'Family should have one SELF' , "md")
        return false
      }
      }
    }
  
}else{
  if(this.invalidNamesMsg.length > 0){
  this.commonService.commonErrorPopup("Alert",'Family members does not have proper data or have blank member name(citizen Name or resident Id missed) i.e. Member Names: ' + this.invalidNamesMsg.toString() , "md");
  } else {
    this.commonService.commonErrorPopup("Alert",'Family members does not have proper data or have blank member name(citizen Name or resident Id missed)' , "md");
  }
} 
  }
  InvalidFiveStepValidation(data : any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
      size: 'md',
    });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = data;
    modalRef.result.then((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }
  
  InvalidMaleRelationPair() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
      size: 'md',
    });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message =
      'Invalid Relation selected for male gender';
    modalRef.result.then((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }
  
  InvalidFemaleRelationPair() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
      size: 'md',
    });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message =
      'Invalid Relation selected for female gender';
    modalRef.result.then((result: any) => {
      if (result) {
        console.log(result);
      }
    });
}


getVehicleDetails(){
  var sendPostData = {
    CITIZEN_ID: this.commonService.RSAEncrypt(this.f['commonForm']?.value['aadharNo'], true),
  };
  this.commonService.postRequestForWebLand(this.commonConstants.getVehicleDetails, sendPostData,'aarogyaSri').subscribe({
    next: (vehicle:any) => { 
      if(vehicle.result.length>0){
        this.vehicleData =  vehicle.result[0]?.encryptedData;
      }
      else{
        this.vehicleData = [];
      }
     
     let dataofVechicles;
     if(this.vehicleData.length>0){

      let _key = CryptoJS.enc.Utf8.parse('319985531ea3b002');
      let _iv = CryptoJS.enc.Utf8.parse('2a98754723e4445f');
  
      this.decrypted = CryptoJS.AES.decrypt(
        this.vehicleData, _key, {
          keySize: 16,
          iv: _iv
        }).toString(CryptoJS.enc.Utf8);
     dataofVechicles=JSON.parse(this.decrypted)
      this.FourWheeler = dataofVechicles.RTARES;
      console.log(JSON.stringify(this.FourWheeler) + 'fourwheeler in get vechicles')
     for (let i = 0; i < this.sixStepResponseData.length; i++) {
        let fourwheelerCounter = 0;
        let vehicleNumb :any= '';
        for(let j = 0; j < this.FourWheeler.length; j++)
      if(this.sixStepResponseData[i].FOUR_WHEELER === 'YES'){
        if(this.sixStepResponseData[i].CITIZEN_ID === this.FourWheeler[j].UID_NUM){
        // this.sixStepResponseData[i].FourWheelerNumber = this.FourWheeler[j].VEHICLE_NUMBER;
        vehicleNumb= vehicleNumb !== '' ? (vehicleNumb + ',' + this.FourWheeler[j].VEHICLE_NUMBER) :  this.FourWheeler[j].VEHICLE_NUMBER
       fourwheelerCounter++;      
       console.log(fourwheelerCounter)
     }
          this.sixStepResponseData[i].FourWheelerNumber = vehicleNumb;
          this.sixStepResponseData[i].NoOfFourWheelers=fourwheelerCounter.toString();
      }else{
        this.sixStepResponseData[i].FourWheelerNumber='NO';
      }
      }
      
    if(this.sixStepResponseData.length > 0)            
            {
                this.getDetails()  
            }
     }
     else{
       for(let i = 0; i < this.sixStepResponseData.length; i++){
      this.sixStepResponseData[i].FourWheelerNumber='NO';  
    }
    if(this.sixStepResponseData.length > 0)            
    {
        this.getDetails()  
    }
    }
    },
    error: (error) => {
      console.log('client side', error);
    }
  }); 
}


// keyPress(e:any) {
//   var k;
//   k = e.keyCode ? e.KeyCode : e.which;
//   return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k !=17 ||k != 32 || (k >= 48 && k <= 57) );
// }
matcher(event:any) {
  const allowedRegex = /[0-9a-zA-Z]/g;
  const special =/[`~,!@#$%^&*<>;':"{}()=_+-]/g;

  if (event.key.match(allowedRegex) || event.key.match(special) ) {
      event.preventDefault();
  }

}
cammamatch(event:any){
  const allowedRegex = /[a-zA-Z]/g;
  const special =/[`~,!@#$%^&*<>;':"{}()=_+-]/g;

  if (event.key.match(allowedRegex) || event.key.match(special) ) {
      event.preventDefault();
  }
}
}





