<div class="mainPageContainer mr-3">
  <div class="row col col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <label>Revoke Worklist</label>
  </div>
        <div class="btnHolder">
          <button class=" mt-3 float-right greenBtnCls homebtn mr-3" (click)="goToHome()">Home</button>
      </div>
    <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <table class="tableCls mb-3" >
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Serial No.</th>
                                    <th>Application No</th>
                                    <th>Service Name</th>
                                    <th>Department Name</th>
                                    <th class="widthTD">Citizen Name</th>
                                    <th class="widthTD">Father Name</th>
                                    <th>Mandal Name</th>
                                    <th>Secretariat Name</th>
                                    <th>Transaction Date</th>
                                    <th>Reason for Revoke</th>
                                    <th class="btnHide">Application<br>Preview</th>
                                    <th class="btnHide">View<br>Certificate</th>
                               </tr>
                            </thead>

                            <tbody *ngIf="items.length > 0"> 
                            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;" [ngClass]="{'selected':item.isSelected}"> 
                                <td><input type="radio" name="select" value="{{ item.isSelected }}"  (change)="onRadioClick(item)" /></td>
                                <td>{{((page -1) * pageSize) + (i + 1)}}</td>
                                <td>{{ item?.requisitionId }}</td>
                                <td>{{ item?.serviceCode }}</td>
                                <td>{{ item?.departmentCode }}</td>
                                <td>{{ item?.applicantName }}</td>
                                <td>{{ item?.fatherHusbandName }}</td>
                                <td>{{ item?.mandalName }}</td>
                                <td>{{ item?.secretariateName }}</td>
                                <td>{{ item?.requestedDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                <td>{{ item?.requestReason }}</td>
                                <td><a class="textDcor" (click)="viewPreview(item?.requisitionId,item?.departmentCode,item?.requisitionType)">Application Preview</a></td>
                                <td><a *ngIf="item?.serviceCode != 'Integrated Certificate-SuoMoto'" class ="textDcor" (click)="getDashBoardForCertificatePreview(item?.requisitionId,item?.departmentCode)">Certificate Preview</a></td>
                              </tr>
                       </tbody> 
                            <tbody *ngIf="items.length === 0">
                                <tr>
                                 <td [attr.colspan]="11" class="text-center">No records found.</td>
                                </tr>
                             </tbody>
                            </table>
                <div class="float-left" *ngIf="items.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
               <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                    
                </div>
                </div>
                    <div class="text-center my-3">
                        <button  name="approve" class="greenBtnCls mr-3"  (click)="approveReject('approve',3, editProfileModal)" >Revoke</button>
                        <button  name="reject" class="blueBtnCls mr-3"  (click)="approveReject('reject',4,editProfileModal)" >Don't Revoke</button>
                    </div>    
    <ng-template #editProfileModal let-modal>
        <div class="modal-header">
         <h5 class="modal-title" id="editProfileLabel"> {{modalFormName}} </h5>
         <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
          <span >&times;</span>
         </button>
        </div>
        
        <div class="modal-body">
         <form [formGroup]="emudhraformGroup" >
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Tokens : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-8">
              <select class="selectCls" required formControlName = "ddTokens"  (change)="tokenChange()" [ngClass]="{ 'is-invalid':eMudhraSubmitted && (e.ddTokens.errors || e.ddTokens.value === 'Select') }">
                <option [ngValue]="null" disabled>Select</option>
                <option *ngFor="let obj of tokenList" [ngValue]="obj">{{ obj.keyStoreDisplayName }}</option>
              </select>
              <div *ngIf="eMudhraSubmitted && (e.ddTokens.errors || e.ddTokens.value === 'Select')" class="selectError">
                <div *ngIf="e.ddTokens.errors?.required || e.ddTokens.value === 'Select'">Please select Token</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Certificates : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-8">
              <select class="selectCls" required formControlName = "ddCertificates" (change)="getCertificateName()"   [ngClass]="{ 'is-invalid':eMudhraSubmitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select') }">
                <option [ngValue]="null" disabled>Select</option>
                <option *ngFor="let obj of certificateList" [ngValue]="obj">{{ obj.CommonName }}</option>
              </select>
              <div *ngIf="eMudhraSubmitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select')" class="selectError">
                <div *ngIf="e.ddCertificates.errors?.required || e.ddCertificates.value === 'Select'">Please select Certificate</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Password : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-8">
              <input type="password" name="ddPassword" formControlName="ddPassword" class="inputCls" required 
              [ngClass]="{ 'is-invalid':eMudhraSubmitted && e.ddPassword.errors }"  maxlength="20">
              <div *ngIf="eMudhraSubmitted && e.ddPassword.errors" class="invalid-feedback">
                <div *ngIf="e.ddPassword.errors.required">Please enter Password</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button  type="submit" name="signdocument" class="btn btn-primary " [disabled]="isSigning" (click)="btnSignDoc()">{{modalFormButtonName}} </button>
          </div>
         </form>
        </div>
    </ng-template>
 </div> 

 
