import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-external-payment',
  templateUrl: './external-payment.component.html',
  styleUrls: ['./external-payment.component.css'],
})
export class ExternalPaymentComponent implements OnInit {
  @Input() queryParmData: any;
  @Input() selectedPage: any;
  commonConstants: any = CommonConstants;

  transactionId: string = '';
  originalStr: string = '';
  tokenId: string = '';
  queryPramDataString: string = '';
  pipelineStr: string = '';

  isAuth: boolean = false;
  amountData: any;
  userData: any;
  unsubscribe: Subscription = new Subscription();
  isProceed: boolean = false;
  constructor(
    private currentRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private modalService: NgbModal
  ) {
    //const url = this.currentRoute['_routerState'].snapshot.url;
  }

  ngOnInit(): void {
    //console.log(this.selectedPage + '--------------------- In E payment>>>>>>>>>>>>>');

    let userInfo:any = this.commonService.getLocalStorage('userInfo');
    if (userInfo && userInfo !== null) {
      userInfo = JSON.parse(userInfo);
      this.userData = userInfo.userPrincipalName;
    }


    this.unsubscribe = this.currentRoute.queryParams.subscribe((params) => {
      if (params  && Object.keys(params).length>0 && !params.hasOwnProperty('currentURL')) {
        //console.log('params payemnt=========>>>>>>>', params);
        this.getResponseData(params);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.unsubscribe();
  }

  getResponseData(data: any) {
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      //  //console.log('Object is empty');
    } else {
      //console.log('Object is no empty', data);
      
      this.originalStr = encodeURIComponent(data.msg);
      this.queryPramDataString = JSON.stringify(data);
      this.transactionId = data.msg;
      //this.verifyExternalPaymentChecksum(this.transactionId)
      this.externalAESDecryptMesevaRequest(this.transactionId);
    }
  }

  externalAESDecryptMesevaRequest(reqID: any) {

    let dataURI = reqID.replace(/%20/g, '%2B');
    dataURI = dataURI.replace(/\s/g, '+');
    //console.log('dataURI', dataURI);
    let postdata = {
      EncryptedValue: dataURI,
    };
    
    this.commonService
      .postRequest(
        this.commonConstants.externalAESDecryptMesevaRequest,
        postdata
      )
      .subscribe({
        next: (responseData: any) => {
          //console.log('externalAESDecryptMesevaRequest===', responseData);
          
          if(responseData.result.isSuccess === false){
                     
            const queryParams: Params = { mode: 'unauthorized'};
            this.router.navigate(['/external-payment-response'], {
              relativeTo: this.currentRoute,
              queryParams: queryParams,
              queryParamsHandling: '',
            });
          }else{

              let respdata = responseData.result.rawValue;
              this.pipelineStr =  respdata;
              this.amountData = respdata;

              this.amountData = this.amountData.split("|")
              this.isAuth = true;
              this.saveExternalPaymentAllRequest(respdata);
          }

        },
      });
  }

  saveExternalPaymentAllRequest(inputPaymentStr:any) {
    
    inputPaymentStr = inputPaymentStr.split('|');

    //console.log("inputPaymentStr",inputPaymentStr)

    let inputData = {
      requestId: inputPaymentStr[0],
      requestType: inputPaymentStr[1],
      department: inputPaymentStr[2],
      userCharges: inputPaymentStr[3],
      serviceCharges: inputPaymentStr[4],
      statutaryCharges: inputPaymentStr[5],
      deliveryCharges: inputPaymentStr[6],
      transactionAmount: inputPaymentStr[7],
      userId: inputPaymentStr[8],
      customerName: inputPaymentStr[9],
      agentName: inputPaymentStr[10],
      agentId: inputPaymentStr[11],
      requestSubType: inputPaymentStr[12],
      secretariatId: inputPaymentStr[13],
      sendBackURL: inputPaymentStr[14],
      serviceName: inputPaymentStr[15],
    };
    //console.log('inputData==>', inputData);
    this.commonService
      .postRequest(
        this.commonConstants.saveExternalPaymentAllRequest,
        inputData
      )
      .subscribe({
        next: (responseData: any) => {
          if(responseData.result === 'Success') {
            this.isProceed = true;
          } else if(responseData.result === 'Duplicate Request') {
            this.isProceed = false;
            this.commonService.commonErrorPopup('Alert', 'Duplicate Request', 'md')
            this.encryptData(inputPaymentStr[14], inputPaymentStr[0]+'|'+inputPaymentStr[0]+'|'+'duplicate request'+'|'+ 'NA'+'|'+inputPaymentStr[8]+'|'+inputPaymentStr[1]+'|'+inputPaymentStr[7]+'|'+ 'NA');
          } else {
            this.isProceed = false;
            this.commonService.commonErrorPopup('Alert', 'We can\'t proceed your payment right now. Please try again.', 'md')
            this.encryptData(inputPaymentStr[14], inputPaymentStr[0]+'|'+inputPaymentStr[0]+'|'+'error occured'+'|'+ 'NA'+'|'+inputPaymentStr[8]+'|'+inputPaymentStr[1]+'|'+inputPaymentStr[7]+'|'+ 'NA');
          }
          //console.log('saveExternalPaymentAllRequest===>', responseData);
        },
      });
  }

  encryptData(url: any, data: any) {
    let postdata = {
      rawValue:  data
    };
    this.commonService
      .postRequest(
        this.commonConstants.externalAESEncryptMesevaRequest,
        postdata
      )
      .subscribe({
        next: (responseData: any) => {
          //console.log('externalAESEncryptMesevaRequest===', responseData);

          if(responseData.result.isSuccess === true){ 
            let encryptedMsg = encodeURIComponent(responseData.result.encryptedValue);
            setTimeout(() => {
              window.location.href = url +"?msg="+ encryptedMsg;
            }, 15000);
          }
        },
      });
  }
  verifyExternalPaymentChecksum(id: any) {
    let inputData = {
      requestId: this.transactionId,
      agentId: 'test',
      userId: 'test',
      piplineString: 'tets|221|sdfdsf',
    };

    //console.log('verifyExternalPaymentChecksum inputData==>', inputData);
    this.commonService
      .postRequest(
        this.commonConstants.verifyExternalPaymentChecksum,
        inputData
      )
      .subscribe({
        next: (responseData: any) => {
          //console.log('verifyExternalPaymentChecksum===>', responseData);
          if (responseData.result.isSuccess === false) {
            /*  const queryParams: Params = { mode: 'unauthorized' ,currentURL:"EpaymentRoute"};
            this.router.navigate(['/external-payment-response'], {
              relativeTo: this.currentRoute,
              queryParams: queryParams,
              queryParamsHandling: '',
            }); */
          }
        },
      });
  }

  proceedPayment() {
    //let tokenData = this.queryParmData.replace('token=', '');
    const queryParams: Params = {
      //dataM: this.originalStr,
      currentURL: 'EpaymentRoute',
    };
    this.router.navigate(['/external-payment-gateway'], {
      relativeTo: this.currentRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

 
  /* idAuthData(){
    this.generateToken(this.transactionId);
    this.saveExternalPaymentAllRequest();
  }

  generateToken(id:any){
    let inputData = {
      requestId: id,
      agentId:"test",
      userId:"test"
    };

    this.isAuth = false;
    //console.log('generatePaymentToken inputData==>', inputData);
    this.commonService
      .postRequest(this.commonConstants.generatePaymentToken, inputData)
      .subscribe({
        next: (responseData: any) => {
          //console.log('generatePaymentToken===>', responseData);
          if(responseData.result.isSuccess === false){

            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'The token is invalid of expired !! Please try with new request ';

          }else{

            this.isAuth = true;
            this.verfyAuthentication(responseData.result.result);
          }
        },
      });
  }

  verfyAuthentication(data:any) {
   // let tokenData = this.queryParmData.replace('token=', '');
   //console.log('inputData==>', data);
    let inputData = '?token='+data.token+'&requestId='+data.requestId;
    this.tokenId = data.token;
    this.transactionId = data.requestId;
    this.commonService
      .getRequest(this.commonConstants.verifyPaymentToken + inputData)
      .subscribe({
        next: (responseData: any) => {
          //console.log('verifyPaymentToken===', responseData);
          if (responseData.result !== 'Token Matching') {
            const queryParams: Params = { mode: 'unauthorized' };
            this.router.navigate(['/external-payment-response'], {
              relativeTo: this.currentRoute,
              queryParams: queryParams,
              queryParamsHandling: '',

            });
          }

          this.getTestAmount();
        },
      });
  } */
}
