<div class="reportContainer">
    <div [formGroup]="form">
        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>
            <!-- <div *ngIf="submitted && (f.permDistrict.errors || f.permDistrict.value === 'Select')" class="selectError">
                <div *ngIf="f.permDistrict.errors?.required || f.permDistrict.value === 'Select'">Please select District</div>
            </div> -->
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
            <!-- <div *ngIf="submitted && (f.permMandal.errors || f.permMandal.value === 'Select')" class="selectError">
            <div *ngIf="f.permMandal.errors?.required || f.permMandal.value === 'Select'">Please select Mandal/Municipality</div>
            </div> -->
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village" (change)="onVillageChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            
            </div>
        </div>
    </div>

<!-- <div class="row mb-3"> -->
    
      <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getPaymentReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
<!-- </div> -->
<div class="row">
    <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Payment Report</th>
            </tr>
            <tr>
                <th  [attr.rowspan]="3">S.No</th>
                <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
               <th [attr.rowspan]="2" [attr.colspan]="2">Total</th>
                <th [attr.rowspan]="2" [attr.colspan]="2">Paid</th>
                <th [attr.rowspan]="2" [attr.colspan]="2">Pending</th>
                <th [attr.rowspan]="2" [attr.colspan]="2">UPI Single</th>
                <th [attr.rowspan]="2" [attr.colspan]="2">Cash Mode</th>
                <th [attr.rowspan]="2" [attr.colspan]="2">Challan Not Generated</th>
                <th [attr.colspan]="4">ICICI Challan</th>
                <th [attr.colspan]="4">Bill Desk Challan Deposited</th>
            </tr>
            <tr>
                <th  [attr.colspan]="2">Deposited</th>
                <th [attr.colspan]="2">Not Deposited</th>
                <th [attr.colspan]="2">Deposited</th>
                <th [attr.colspan]="2">Not Deposited</th>
            </tr>
            <tr>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'singlE_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'singlE_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'casH_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'casH_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_CHALLAN_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_CHALLAN_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'iciC_PAID_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'iciC_PAID_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'iciC_PENDING_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'iciC_PENDING_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'billdesK_PAID_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'billdesK_PAID_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'billdesK_PENDING_TRANS'">Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'billdesK_PENDING_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               
            </tr>
            
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                <td class="text-right">{{item.toT_TRANS}}</td>
                <td class="text-right">{{item.toT_AMOUNT}}</td>
                <td class="text-right">{{item.paiD_TRANS}}</td>
                <td class="text-right">{{item.paiD_AMOUNT}}</td>
                <!-- <td class="text-right">{{item.pendinG_TRANS}}</td> -->
                <td class="openWithin text-right"><a (click) ="getPendingTransaction(item, beyondSLAModal )" class="linkCls" ><b>{{item.pendinG_TRANS}}</b></a></td>
                <td class="text-right">{{item.pendinG_AMOUNT}}</td>
                <td class="text-right">{{item.singlE_TRANS}}</td>
                <td class="text-right">{{item.singlE_AMOUNT}}</td>
                <td class="text-right">{{item.casH_TRANS}}</td>
                <td class="text-right">{{item.casH_AMOUNT}}</td>
                <!-- <td class="openWithin text-right"><a (click) ="getPendingTransaction(item, beyondSLAModal )" class="linkCls" >{{item.casH_AMOUNT}}</a></td> -->
                <td class="text-right">{{item.nO_CHALLAN_TRANS}}</td>
                <td class="text-right">{{item.nO_CHALLAN_AMOUNT}}</td>
                <td class="text-right">{{item.iciC_PAID_TRANS}}</td>
                <td class="text-right">{{item.iciC_PAID_AMOUNT}}</td>
                <!-- <td class="text-right"><a (click) ="getPendingTransaction(item, beyondSLAModal, )" class="linkCls">{{item.iciC_PENDING_TRANS}}</a></td> -->
                <td class="text-right" >{{item.iciC_PENDING_TRANS}}</td>
                <td class="text-right">{{item.iciC_PENDING_AMOUNT}}</td>
                <td class="text-right">{{item.billdesK_PAID_TRANS}}</td>
                <td class="text-right">{{item.billdesK_PAID_AMOUNT}}</td>
                <td class="text-right">{{item.billdesK_PENDING_TRANS}}</td>
                <td class="text-right">{{item.billdesK_PENDING_AMOUNT}}</td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                <td class="text-right">{{totalItems[0].toT_TRANS}}</td>
                <td class="text-right">{{totalItems[0].toT_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].paiD_TRANS}}</td>
                <td class="text-right">{{totalItems[0].paiD_AMOUNT}}</td>
                <td class="text-right"  >{{totalItems[0].pendinG_TRANS}}</td>
                <td class="text-right">{{totalItems[0].pendinG_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].singlE_TRANS}}</td>
                <td class="text-right">{{totalItems[0].singlE_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].casH_TRANS}}</td>
                <td class="text-right">{{totalItems[0].casH_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].nO_CHALLAN_TRANS}}</td>
                <td class="text-right">{{totalItems[0].nO_CHALLAN_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].iciC_PAID_TRANS}}</td>
                <td class="text-right">{{totalItems[0].iciC_PAID_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].iciC_PENDING_TRANS}}</td>
                <td class="text-right">{{totalItems[0].iciC_PENDING_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].billdesK_PAID_TRANS}}</td>
                <td class="text-right">{{totalItems[0].billdesK_PAID_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].billdesK_PENDING_TRANS}}</td>
                <td class="text-right">{{totalItems[0].billdesK_PENDING_AMOUNT}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    </div>
    <div class="mt-4 btnHide" *ngIf="showReports">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>


<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
        <tr>
            <th [attr.colspan]="noOfCol">Payment Report</th>
        </tr>
        <tr>
            <th  [attr.rowspan]="3">S.No</th>
            <th width="100px" [attr.rowspan]="3" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">District</th>
            <th width="100px" [attr.rowspan]="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal</th>
            <th width="100px" [attr.rowspan]="3" *ngIf="format === 'Secretariat Wise'">Secretariat</th>
            <th width="100px" [attr.rowspan]="2" [attr.colspan]="2">Total</th>
            <th width="100px" [attr.rowspan]="2" [attr.colspan]="2">Paid</th>
            <th width="100px" [attr.rowspan]="2" [attr.colspan]="2">Pending</th>
            <th width="100px" [attr.rowspan]="2" [attr.colspan]="2">UPI Single</th>
            <th width="100px" [attr.rowspan]="2" [attr.colspan]="2">Cash Mode</th>
            <th width="100px" [attr.rowspan]="2" [attr.colspan]="2">Challan Not Generated</th>
            <th width="100px" [attr.colspan]="4">ICICI Challan</th>
            <th width="100px" [attr.colspan]="4">Bill Desk Challan Deposited</th>
        </tr>
        <tr>
            <th  width="100px" [attr.colspan]="2">Deposited</th>
            <th width="100px" [attr.colspan]="2">Not Deposited</th>
            <th width="100px" [attr.colspan]="2">Deposited</th>
            <th width="100px" [attr.colspan]="2">Not Deposited</th>
        </tr>
           <tr>
               <!-- <th width="100px">S.No</th>
               <th width="100px" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">District</th>
               <th width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal</th>
               <th width="100px" *ngIf="format === 'Secretariat Wise'">Secretariat</th> -->
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
               <th width="100px">Transactions</th>
               <th width="100px">Amount</th>
           </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td width="100px" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
               <td width="100px" *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
               <td >{{item.toT_TRANS}}</td>
               <td >{{item.toT_AMOUNT}}</td>
               <td >{{item.paiD_TRANS}}</td>
               <td >{{item.paiD_AMOUNT}}</td>
               <td >{{item.pendinG_TRANS}}</td>
               <td >{{item.pendinG_AMOUNT}}</td>
               <td >{{item.singlE_TRANS}}</td>
               <td >{{item.singlE_AMOUNT}}</td>
               <td >{{item.casH_TRANS}}</td>
               <td >{{item.casH_AMOUNT}}</td>
               <td >{{item.nO_CHALLAN_TRANS}}</td>
               <td >{{item.nO_CHALLAN_AMOUNT}}</td>
               <td >{{item.iciC_PAID_TRANS}}</td>
               <td >{{item.iciC_PAID_AMOUNT}}</td>
               <td >{{item.iciC_PENDING_TRANS}}</td>
               <td >{{item.iciC_PENDING_AMOUNT}}</td>
               <td >{{item.billdesK_PAID_TRANS}}</td>
               <td >{{item.billdesK_PAID_AMOUNT}}</td>
               <td >{{item.billdesK_PENDING_TRANS}}</td>
               <td >{{item.billdesK_PENDING_AMOUNT}}</td>
           </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
           <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
           <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
           <td >{{totalItems[0].toT_TRANS}}</td>
           <td >{{totalItems[0].toT_AMOUNT}}</td>
           <td >{{totalItems[0].paiD_TRANS}}</td>
           <td >{{totalItems[0].paiD_AMOUNT}}</td>
           <td >{{totalItems[0].pendinG_TRANS}}</td>
           <td >{{totalItems[0].pendinG_AMOUNT}}</td>
           <td >{{totalItems[0].singlE_TRANS}}</td>
           <td >{{totalItems[0].singlE_AMOUNT}}</td>
           <td >{{totalItems[0].casH_TRANS}}</td>
           <td >{{totalItems[0].casH_AMOUNT}}</td>
           <td >{{totalItems[0].nO_CHALLAN_TRANS}}</td>
           <td >{{totalItems[0].nO_CHALLAN_AMOUNT}}</td>
           <td >{{totalItems[0].iciC_PAID_TRANS}}</td>
           <td >{{totalItems[0].iciC_PAID_AMOUNT}}</td>
           <td >{{totalItems[0].iciC_PENDING_TRANS}}</td>
           <td >{{totalItems[0].iciC_PENDING_AMOUNT}}</td>
           <td >{{totalItems[0].billdesK_PAID_TRANS}}</td>
           <td >{{totalItems[0].billdesK_PAID_AMOUNT}}</td>
           <td >{{totalItems[0].billdesK_PENDING_TRANS}}</td>
           <td >{{totalItems[0].billdesK_PENDING_AMOUNT}}</td>
        </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
               <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
           </tr>
       </tbody>
   </table>
</div>
<div class="table-responsive tableFixHead" #BEYONDSLATABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th>S.No</th>
               <th>District</th>
               <th>Mandal</th>
               <th>Secretariat</th>
               <th>Requisition Id</th>
               <th>Amount</th>
               <th>Requisition Date</th>
               <th>Payment Status</th>
               <th>Challan Id</th>
               <th>Challan Type</th>
               <th>Challan Date</th>
            </tr>
        </thead>

        <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
            <tr *ngFor="let item of beyondSLAItems;let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.districT_NAME}}</td>
               <td>{{item.mandaL_NAME}}</td>
               <td>{{item.secretariaT_NAME}}</td>
               <td>{{item.requisitionid}}</td>
               <td>{{item.transactionamount}}</td>
               <td>{{item.transactiondate }}</td>
               <td>{{item.paymenT_STATUS}}</td>
               <td>{{item.challanid}}</td>
               <td>{{item.challaN_TYPE}}</td>
               <td>{{item.challaN_DATE }}</td>
            </tr>
        </tbody>
        <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
            <tr>
                <td colspan="12" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
</div>
</div>
<ng-template #beyondSLAModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Pending Transactions Payment Report</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="table-responsive tableFixHead">
            <table class="tableCls" >
                <thead> 
                    <tr>
                        <th>S.No</th>
                        <th *ngFor="let head of headerCol; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="beyondSLAItems" [sortBy]="headerCol[i].colId">{{ head.colName }}
                            <mdb-icon fas icon="sort"></mdb-icon>
                          </th>
                       
                    </tr>
                </thead>
        
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
                    <tr *ngFor="let item of beyondSLAItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                        <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                        <td>{{item.districT_NAME}}</td>
                       <td>{{item.mandaL_NAME}}</td>
                       <td>{{item.secretariaT_NAME}}</td>
                       <td>{{item.requisitionid}}</td>
                       <td>{{item.transactionamount}}</td>
                        <td>{{item.transactiondate }}</td>
                        <td>{{item.paymenT_STATUS}}</td>
                        <td>{{item.challanid}}</td>
                        <td>{{item.challaN_TYPE}}</td>
                        <td>{{item.challaN_DATE  }}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
                    <tr>
                        <td colspan="12" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="beyondSLAItems.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="beyondSLAItems.length > 0" [collectionSize]="beyondSLAItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  name="close" class="greenBtnCls" *ngIf="beyondSLAItems && beyondSLAItems.length > 0" (click)="downloadExcelForBeyondSLA()">Download Excel</button>
    </div>
</ng-template>