import { Component, OnInit } from '@angular/core';
import {CommonService} from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
import {
  NgbNavConfig,
  NgbDateAdapter,
  NgbDateParserFormatter,NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from 'src/shared/common.service';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';

@Component({
  selector: 'app-aarogya-sri-card-dispatch-status',
  templateUrl: './aarogya-sri-card-dispatch-status.component.html',
  styleUrls: ['./aarogya-sri-card-dispatch-status.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
})
export class AarogyaSriCardDispatchStatusComponent implements OnInit {
  maxDate: any;
  minDate: any;
  currentDate = new Date();
  commonConstants : any = CommonConstants;
  secreteriateCode:any;
  applicationData:any[]=[];
  page = 1;
  pageSize = 10;
  constructor(private commonService: CommonService,private modalService: NgbModal,) { 
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
  
  }

  ngOnInit(): void {
    this.secreteriateCode =this.commonService.getLocalStorage('locationCode');
    if(this.secreteriateCode){
      this.getApplicationsListBySecId();
    }
    
    
   
  }
 
  getApplicationsListBySecId(){
    this.commonService.getRequest(this.commonConstants.getApplicationsListBySecId + this.secreteriateCode).subscribe({
      next: (responseData: any) => { 
        let resData = responseData?.result;  
        if(resData.isSuccess === true){        
                this.applicationData =resData.applicationData;
        }       
        else{
          if( resData.error === 'No Applications found for this DA Secreatariat code'){
            this.applicationData=[];
          } 
          this.commonService.commonErrorPopup("Alert", resData.error, "md");          
        }       
           
      }
    }) 
  }
   updateData(data:any):any{
    let todayDate = new Date().setHours(0, 0, 0, 0);    
     
    
    if(!data?.receivedDate){
      this.commonService.commonErrorPopup("Alert", 'Please enter Received Date', "md")
      return false;
    }
    let receiveDate = this.commonService.formatDate(data?.receivedDate);
    if(receiveDate === 'Invalid date'){
      this.commonService.commonErrorPopup("Alert", 'Please enter Valid Received Date', "md");
      return false;
    }  else if(new Date(receiveDate).setHours(0, 0, 0, 0) > todayDate){  
      this.commonService.commonErrorPopup("Alert", 'Received Date should be less than current Date', "md");
      return false;
    }else {
    
    let postData: any = {};
    postData={
     uhid:data?.uhid,
     requisitionId:data?.referenceId,
     receivedDate:receiveDate
    }
    this.commonService.postRequest(this.commonConstants.updateCardReceivedStatusData, postData).subscribe({
      next: (responseData:any) => { 
       if(responseData?.result?.isSuccess === true){        
       this.commonMsgPopup('Application submitted successfully')
       
     
       }else{
        this.commonService.commonErrorPopup("Alert", responseData?.result?.error, "md");
        
       }
      
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
   }
  }

  commonMsgPopup(msg: any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = msg;
    modalRef.result.then((result: any) => {
      this.getApplicationsListBySecId()
    });
   }
}
