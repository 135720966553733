import { Component, ElementRef,OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, ChangeDetectionStrategy  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CommonService, WhitespaceValidator } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal, NgbNavChangeEvent, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-sadaremprint-certificate',
  templateUrl: './sadaremprint-certificate.component.html',
  styleUrls: ['./sadaremprint-certificate.component.css'],
  providers: [
    NgbNavConfig,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SadaremprintCertificateComponent implements OnInit {
  @ViewChild('nav')
  nav: any;
  sadaremForm: UntypedFormGroup;
  submitted: Boolean = false;
  commonConstants : any = CommonConstants;
  commonFormSubmitted: Boolean = false;
  requisitionType: any;
  sadaremdetailsfromapi: any = {};
  postDataCertificate: any = {};
  postData: any = {};
  showData: Boolean = false;
  reset: Boolean = false;
  showgetdetails: Boolean = true;
  commonFormAddedFields : any = {
    aadharNum : ''
  };
  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, private commonService: CommonService,private config: NgbNavConfig, private activatedRoute: ActivatedRoute, private router: Router) { 
    this.sadaremForm = this.formBuilder.group({
       commonForm: [],
       sadaremID: ['',[Validators.required, Validators.minLength(17), Validators.maxLength(17)]],
       familyForm: [false, [Validators.required]]
  });
  config.destroyOnHide = false;
  config.roles = false;
  }
  get f() {
    return this.sadaremForm.controls;
  }
  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  goToNextTab() {
    this.commonFormSubmitted = true;
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    }
  }

  getSadaremDetails() {
    this.submitted = true;
    this.showData = false;
    this.f.sadaremID.enable();
    this.f.familyForm.setValue(false);
    if(this.sadaremForm.valid) {
      this.submitted = false;
      this.showData = true;
      this.f.sadaremID.disable();
      this.f.familyForm.setValue(false);
      this.postData = {
        sadaremID: this.f.sadaremID.value
      }
      this.commonService.postRequestForWebLand(this.commonConstants.getsadaramdetailsfromapi, this.postData).subscribe({
        next: (responseData: any) => {
          if(responseData && responseData.RESPONSE.Status == '01-Certificate Generated') {
            if(responseData.RESPONSE.AadharNumber.toString().substring(8) === this.f['commonForm'].value['aadharNo'].toString().substring(8)) {
              this.sadaremdetailsfromapi = responseData.RESPONSE;
              this.showData = true;
              this.reset = true;
              this.showgetdetails = false;
            }
            else {
              this.showData = false;
              this.reset = false;
              this.showgetdetails = true;
              this.sadaremdetailsfromapi = {};
              this.f.sadaremID.enable();
              this.commonService.commonErrorPopup('Alert', 'Aadhar Number Should match the basic details aadhar number', 'md');
            }
          }
          else {
            this.showData = false;
            this.reset = false;
            this.showgetdetails = true;
            this.sadaremdetailsfromapi = {};
            this.f.sadaremID.enable();
            this.commonService.commonErrorPopup('Alert', responseData.RESPONSE.Status, 'md')
          }
        }
      }
      )
    }
  }

  resetsadaremid() {
    this.f['familyForm'].setValue(true);
    this.f.sadaremID.enable();
    this.sadaremdetailsfromapi = {};
    this.showData = false;
    this.showgetdetails = true;
    this.reset = false;
  }

  submit() {
    this.submitted = true;
    if(this.sadaremForm.valid) {
      this.submitted = false;
    let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
    let postData = {          
      requisitionHeader:  {
        requisitionType: 'SadaremPrintCertificate',
        requisitionPKey: '',
        department: 'HF',
        applicantInfo: {
          id: '',
          aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo']?.trim(),
          personNames: [
            {
              languageCode: 'en-in',
              firstName: this.f['commonForm'].value['firstName'],
              middleName: this.f['commonForm'].value['middleName'],
              lastName: this.f['commonForm'].value['lastName'],
              fatherHusbandName:
                this.f['commonForm'].value['fatherHusName'],
            },
          ],
          gender: this.f['commonForm'].value['gender'],
          birthDate: dateFormat,
          caste: this.f['commonForm'].value['caste'].name,
          religion: this.f['commonForm'].value['religion'].name,
          qualification: this.f['commonForm'].value['qualification'].name,
          maritalStatus: this.f['commonForm'].value['maritalStatus'],
          addresses: [
            {
              addressType: 'present',
              doorNo: this.f['commonForm'].value['preDoorNo'],
              streetName: this.f['commonForm'].value['preStreet'],
              villageWard: this.f['commonForm'].value['preVillage'].entityCode,
              mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
              district: this.f['commonForm'].value['preDistrict'].entityCode,
              pinCode: this.f['commonForm'].value['prePinCode'],
              isRevenueLocation: true,
              postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
              postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
              postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
              postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
            },
            {
              addressType: 'permanent',
              doorNo: this.f['commonForm'].value['permDoorNo'],
              streetName: this.f['commonForm'].value['permStreet'],
              villageWard: this.f['commonForm'].value['permVillage'].entityCode,
              mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
              district: this.f['commonForm'].value['permDistrict'].entityCode,
              pinCode: this.f['commonForm'].value['permPinCode'],
              isRevenueLocation: true,
              postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
              postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
              postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
              postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
            },
          ],
          rationCardNo: '',
          deliveryType: this.f['commonForm'].value['deliveryType'],
          emailAddress: this.f['commonForm'].value['email'],
          mobileNumber: this.f['commonForm'].value['mobileNo'],
          isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
          isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
        },
        remarks: '',
      },        
      requisitionData: {
        "sadaremId": this.f.sadaremID.value,
        "Sadaremcode": this.sadaremdetailsfromapi.Sadaremcode,
        "AadharNumber": this.commonService.RSAEncrypt(this.sadaremdetailsfromapi.AadharNumber),
	      "Address": this.sadaremdetailsfromapi.Address,
	      "DateOfBirth": this.sadaremdetailsfromapi.DateOfBirth,
	      "DateOfIssue": this.sadaremdetailsfromapi.DateOfIssue,
        "PERSONNAME": this.sadaremdetailsfromapi.PERSONNAME,
	      "Status": this.sadaremdetailsfromapi.Status,
	      "TYPEOFDISABILITY": this.sadaremdetailsfromapi.TYPEOFDISABILITY,
	      "gender": this.sadaremdetailsfromapi.gender,
        "percentage": this.sadaremdetailsfromapi.percentage
      },
    };
    this.commonService.postRequest(this.commonConstants.saveSadaremPrintCertificateDetails, postData).subscribe({
      next: (responseData: any) => {
          if(responseData) {
            this.showPayment(responseData.result['id']);
          }
        }
    })
  }
  }


  showPayment(requisionId: any) {
    const queryParams: Params = {
      id: requisionId,
      department: 'HF',
      type: 'SadaremPrintCertificate',
    };
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
 }
