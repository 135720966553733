import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonFormComponent } from 'src/shared/common-form/common-form.component';
import { AadharValidator, CommonService, WhitespaceValidator } from 'src/shared/common.service';

@Component({
  selector: 'app-print-certificate-popup',
  templateUrl: './print-certificate-popup.component.html',
  styleUrls: ['./print-certificate-popup.component.css']
})
export class PrintCertificatePopupComponent implements OnInit {
  @Input() aadharNumberInput: any;
  submits: boolean = false;
  ekycForm: UntypedFormGroup;
  stateForm: UntypedFormGroup;
  showBiometricPopup = false;
  aadharNumber: any;
  isAadharDisable: Boolean = false;
  isDisable = false;
  biometricFlag:Boolean = false;
  isAadharOtpValidated = false;
  commonConstants: any = CommonConstants;
  aadharTransactionID: any;
  aadharData: any;
  encrtyptedAadhar: any;
  loggedInUserId: any;
  userInfo: any;
  autoBiometricId: any;
  windowObjectReference: any;
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService, private modalService: NgbModal, public activeModal: NgbActiveModal,) {
    let userData = this.commonService.getLocalStorage('userInfo');
    if (userData && userData !== null) {
       this.userInfo = JSON.parse(userData);
       this.loggedInUserId = this.userInfo?.userPrincipalName
    }
    this.ekycForm = this.formBuilder.group({
      commonForm: [],
      authenticationType: [null, [Validators.required]],
      aadharOtp: [null, [Validators.required]],
      aadhaarNumber: ['',[AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      aadhaarNumberBio: [null, [Validators.required]],
    });
    this.stateForm = this.formBuilder.group({
      authenticationType: [null, [Validators.required]],
      rbAuthenticationType: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  
  reset() {
    // this.fbSearchBirthDetailsForm.reset();
    // this.showGrid = false;
  }

  authenticateUpdate() {
    this.activeModal.close('true');
  }
}