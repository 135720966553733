
<div class="reportContainer">
    <div  [formGroup]="form">
        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>
            <!-- <div *ngIf="submitted && (f.permDistrict.errors || f.permDistrict.value === 'Select')" class="selectError">
                <div *ngIf="f.permDistrict.errors?.required || f.permDistrict.value === 'Select'">Please select District</div>
            </div> -->
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
            <!-- <div *ngIf="submitted && (f.permMandal.errors || f.permMandal.value === 'Select')" class="selectError">
            <div *ngIf="f.permMandal.errors?.required || f.permMandal.value === 'Select'">Please select Mandal/Municipality</div>
            </div> -->
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village"  (change)="onVillageChange()"
            >
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            
            </div>
        </div>
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                         [maxDate]="maxDate" required formControlName="fromDate"
                         [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.fromDate.errors" class="selectError">
                  <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                         [maxDate]="maxDate" required formControlName="toDate"
                         [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.toDate.errors" class="selectError">
                  <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
              </div>
              </div>
        </div>
    </div>

 <!-- <div class="row mb-3">  -->
    
      <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getCasteGrievancesData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
 <!-- </div>  -->
<div class="row">
    <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            
            <tr>
                <th [attr.colspan]="noOfCol">Caste Survey Grievance Dashboard</th>
            </tr>
            <tr>
                <th [attr.rowspan]="3">S.No</th>
                <th [attr.rowspan]="3" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" >Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.colspan]="3">Total Grievance</th>
                <th [attr.colspan]="8">Caste and subcaste Grievance</th>
                <th [attr.colspan]="3">Others Grievance</th>
              </tr>
              <tr>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'casT_CNT'" >Caste and subcaste <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'otheR_CNT'">Others <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'toT_CNT'">Total <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'castE_APPROVE'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'castE_REJECT'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.colspan]="6">Pending </th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'otherS_APPROVE'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'otherS_REJECT'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" class="openBeyond" [sortBy]="'otherS_PENDING'">Pending <mdb-icon fas icon="sort"></mdb-icon></th>
              </tr>
              <tr>
                <th aria-controls="tableSortExample" scope="col" class="openBeyond" [mdbTableSort]="items" [sortBy]="'castE_VRO_PENDING'">With VRO <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" class="openBeyond" [mdbTableSort]="items" [sortBy]="'castE_RI_PENDING'">With RI <mdb-icon fas icon="sort"></mdb-icon></th>
              
              <th aria-controls="tableSortExample" scope="col"   class="openBeyond" [mdbTableSort]="items" [sortBy]="'castE_TEH_PENDING'">With Tahsildar <mdb-icon fas icon="sort"></mdb-icon></th>
            
            <th aria-controls="tableSortExample" scope="col" class="openBeyond" [mdbTableSort]="items" [sortBy]="'castE_RDO_PENDING'">With RDO <mdb-icon fas icon="sort"></mdb-icon></th>
       
        <th aria-controls="tableSortExample" scope="col" class="openBeyond" [mdbTableSort]="items" [sortBy]="'castE_COLLECTOR_PENDING'">With Collector <mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" class="openBeyond" scope="col" [mdbTableSort]="items" [sortBy]="'castE_TOTAL_PENDING'">Total Pending <mdb-icon fas icon="sort"></mdb-icon></th>
    </tr>
            
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">

            <!-- <tr *ngFor="let item of items;let i = index;">  -->
            <td width="100px">{{((page -1) * pageSize) + i + 1}}</td>
            <td>{{item.districT_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
            <td class="text-right">{{item.casT_CNT}}</td>
            <td class="text-right">{{item.otheR_CNT}}</td>
            <td class="text-right">{{item.toT_CNT}}</td>
            <td class="text-right">{{item.castE_APPROVE}}</td>
            <td class="text-right">{{item.castE_REJECT}}</td>
            <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, 'VRO')" class="linkCls">{{item.castE_VRO_PENDING}}</a></td>
            <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, 'RI')" class="linkCls">{{item.castE_RI_PENDING}}</a></td>
            <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, 'TEH')" class="linkCls">{{item.castE_TEH_PENDING}}</a></td>
            <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, 'RDO')" class="linkCls">{{item.castE_RDO_PENDING}}</a></td>
            <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal,'COLLECTOR')" class="linkCls">{{item.castE_COLLECTOR_PENDING}}</a></td>
            <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal,'PENDING TOTAL')" class="linkCls">{{item.castE_TOTAL_PENDING}}</a></td>
            <td class="text-right">{{item.otherS_APPROVE}}</td>
            <td class="text-right">{{item.otherS_REJECT}}</td>
            <td class="openBeyond text-right"><a (click) ="getBeyondSLA(item, beyondSLAModal, 'OTHER')" class="linkCls">{{item.otherS_PENDING}}</a></td>
            
        </tr>
        <tr *ngIf="totalItems && totalItems.length > 0"> 
            <td></td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
            <td class="text-right">{{totalItems[0].casT_CNT}}</td>
            <td class="text-right">{{totalItems[0].otheR_CNT}}</td>
            <td class="text-right">{{totalItems[0].toT_CNT}}</td>
            <td class="text-right">{{totalItems[0].castE_APPROVE}}</td>
            <td class="text-right">{{totalItems[0].castE_REJECT}}</td>
            <td class="text-right">{{totalItems[0].castE_VRO_PENDING}}</td>
            <td class="text-right">{{totalItems[0].castE_RI_PENDING}}</td>
            <td class="text-right">{{totalItems[0].castE_TEH_PENDING}}</td>
            <td class="text-right">{{totalItems[0].castE_RDO_PENDING}}</td>
            <td class="text-right">{{totalItems[0].castE_COLLECTOR_PENDING}}</td>
            <td class="text-right">{{totalItems[0].castE_TOTAL_PENDING}}</td>
            <td class="text-right">{{totalItems[0].otherS_APPROVE}}</td>
            <td class="text-right">{{totalItems[0].otherS_REJECT}}</td>
            <td class="text-right">{{totalItems[0].otherS_PENDING}}</td>
            
        </tr>
        
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    </div>

    <div class="table-responsive tableFixHead" #BEYONDSLATABLE class="hideTable">
        <table class="tableCls" >
            <thead> 
                <tr>
                   <th>S.No</th>
                   <th>District</th>
                   <th>Mandal</th>
                   <th>Secretariat</th>
                   <th>Secretariat Code</th>
                   <th>Grievance type</th>
                   <th>Application No</th>
                   <th>Raised Date</th>
                   <th>Pending at User</th>
                   <th>Pending at Designation</th>
                </tr>
            </thead>
    
            <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
                <tr *ngFor="let item of beyondSLAItems;let i = index;">
                    <td>{{i + 1}}</td>
                   <td>{{item.districT_NAME}}</td>
                   <td>{{item.mandaL_NAME}}</td>
                   <td>{{item.secretariaT_NAME}}</td>
                   <td>{{item.secretariaT_CODE}}</td>
                   <td>{{item.grievancE_TYPE}}</td>
                   <td>{{item.applicatioN_NO}}</td>
                   <td>{{item.createD_DATE  | date: 'dd/MM/yyyy'}}</td>
                   <td>{{item.pendinG_AT_USER}}</td>
                   <td>{{item.pendinG_AT_DESIGNATION}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
                <tr>
                    <td colspan="10" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="mt-4 btnHide" *ngIf="showReports">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>


</div>
</div>

<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            
            <tr>
                <th [attr.colspan]="noOfCol">Caste Survey Grievance Dashboard</th>
            </tr>
            <tr>
                <th width="100px" [attr.rowspan]="3">S.No</th>
                <th  width="100px" [attr.rowspan]="3" >District </th>
                <th  width="100px"  [attr.rowspan]="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'"  >Mandal </th>
               <th  width="100px"  [attr.rowspan]="3" *ngIf="format === 'Secretariat Wise'" >Secretariat </th>
                <th  width="100px" [attr.colspan]="3">Total Grievance</th>
                <th  width="100px" [attr.colspan]="8">Caste and subcaste Grievance</th>
                <th  width="100px" [attr.colspan]="3">Others Grievance</th>
              </tr>
              <tr>
                <th  width="100px" [attr.rowspan]="2"  >Caste and subcaste </th>
                <th  width="100px" [attr.rowspan]="2">Others </th>
                <th  width="100px" [attr.rowspan]="2" >Total </th>
                <th  width="100px" [attr.rowspan]="2" >Approved </th>
                <th  width="100px" [attr.rowspan]="2" >Rejected </th>
                <th  width="100px" [attr.colspan]="6">Pending </th>
                <th  width="100px" [attr.rowspan]="2" >Approved </th>
                <th  width="100px" [attr.rowspan]="2">Rejected </th>
                <th  width="100px" [attr.rowspan]="2" >Pending </th>
              </tr>
              <tr>
                <th  width="100px">With VRO </th>
                <th  width="100px">With RI </th>
                <th  width="100px">With Tahsildar </th>
                <th  width="100px">With RDO </th>
                <th  width="100px">With Collector </th>
                <th  width="100px">Total Pending </th>
              </tr>
            
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;"> 
            <td width="100px">{{i + 1}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
            <td>{{item.casT_CNT}}</td>
            <td>{{item.otheR_CNT}}</td>
            <td>{{item.toT_CNT}}</td>
            <td>{{item.castE_APPROVE}}</td>
            <td>{{item.castE_REJECT}}</td>
            <td>{{item.castE_VRO_PENDING}}</td>
            <td>{{item.castE_RI_PENDING}}</td>
            <td>{{item.castE_TEH_PENDING}}</td>
            <td>{{item.castE_RDO_PENDING}}</td>
            <td>{{item.castE_COLLECTOR_PENDING}}</td>
            <td>{{item.castE_TOTAL_PENDING}}</td>
            <td>{{item.otherS_APPROVE}}</td>
            <td>{{item.otherS_REJECT}}</td>
            <td>{{item.otherS_PENDING}}</td>
            
        </tr>
        <tr *ngIf="totalItems && totalItems.length > 0"> 
            <td></td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
            <td>{{totalItems[0].casT_CNT}}</td>
            <td>{{totalItems[0].otheR_CNT}}</td>
            <td>{{totalItems[0].toT_CNT}}</td>
            <td>{{totalItems[0].castE_APPROVE}}</td>
            <td>{{totalItems[0].castE_REJECT}}</td>
            <td>{{totalItems[0].castE_VRO_PENDING}}</td>
            <td>{{totalItems[0].castE_RI_PENDING}}</td>
            <td>{{totalItems[0].castE_TEH_PENDING}}</td>
            <td>{{totalItems[0].castE_RDO_PENDING}}</td>
            <td>{{totalItems[0].castE_COLLECTOR_PENDING}}</td>
            <td>{{totalItems[0].castE_TOTAL_PENDING}}</td>
            <td>{{totalItems[0].otherS_APPROVE}}</td>
            <td>{{totalItems[0].otherS_REJECT}}</td>
            <td>{{totalItems[0].otherS_PENDING}}</td>
            
        </tr>
        
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
 </div>



</div>

<ng-template #beyondSLAModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">{{title}}</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="table-responsive tableFixHead">
            <table class="tableCls" >
                <thead> 
                    <tr>
                        <th>S.No</th>
                        <th>District</th>
                        <th>Mandal</th>
                        <th>Secretariat</th>
                        <th>Secretariat Code</th>
                        <th>Grievance type</th>
                        <th>Application No</th>
                        <th>Raised Date</th>
                        <th>Pending at User</th>
                        <th>Pending at Designation</th>
                    </tr>
                </thead>
        
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
                    <tr *ngFor="let item of beyondSLAItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                        <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                        <td>{{item.districT_NAME}}</td>
                        <td>{{item.mandaL_NAME}}</td>
                        <td>{{item.secretariaT_NAME}}</td>
                        <td>{{item.secretariaT_CODE}}</td>
                        <td>{{item.grievancE_TYPE}}</td>
                        <td>{{item.applicatioN_NO}}</td>
                        <td>{{item.createD_DATE  | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.pendinG_AT_USER}}</td>
                        <td>{{item.pendinG_AT_DESIGNATION}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
                    <tr>
                        <td colspan="15" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="beyondSLAItems.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="beyondSLAItems.length > 0"    [collectionSize]="beyondSLAItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  name="close" class="greenBtnCls" *ngIf="beyondSLAItems && beyondSLAItems.length > 0" (click)="downloadExcelForBeyondSLA()">Download Excel</button>
    </div>
   </ng-template>

 