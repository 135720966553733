import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from 'src/shared/common.service';
import { NgbModalConfig, NgbModal, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mutation-for-corrections',
  templateUrl: './mutation-for-corrections.component.html',
  styleUrls: ['./mutation-for-corrections.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class MutationForCorrectionsComponent implements OnInit {
  commonConstants : any = CommonConstants;
  form: UntypedFormGroup;
  maxDate: any;
  minDate: any;
  toMinDate: any;
  currentDate = new Date();
  showReports: Boolean = false;
  submitted:Boolean = false;
  currentDateFormat: any;
  popupPageSize = 10;
  popupPage = 1;
  getData: any[] = [];
  totalItems: any[] = [];
  postData:any;
  toDate: any;
  fromDate: any;

  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal, private datePipe: DatePipe, private router: Router) { 
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.toMinDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear() - 150,
    }

    this.form = this.formBuilder.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.popupPageSize=10;
    this.currentDateFormat = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.form.patchValue({
     fromDate: this.currentDateFormat,
     toDate: this.currentDateFormat
    });
  }

  get f() { 
    return this.form.controls; }

    getReports(){
     let postData = '';
     let fromDateFormat = this.datePipe.transform(this.commonService.formatDate(this.form.controls['fromDate'].value), 'dd/MM/yyyy');
     let toDateFormat = this.datePipe.transform(this.commonService.formatDate(this.form.controls['toDate'].value), 'dd/MM/yyyy');
         postData = '?FromDate=' + fromDateFormat + '&ToDate=' + toDateFormat;
         let formDateFormat = this.commonService.formatDate(
          this.form.controls['fromDate'].value
        );
        let toDateFormat1 = this.commonService.formatDate(this.form.controls['toDate'].value);
        if(new Date(toDateFormat1) < new Date(formDateFormat)){
          this.commonService.commonErrorPopup('Alert', 'Please select proper date range,To date should be greater than or equal to from date !!!', 'md');
          this.getData=[];
        }
        else{
          this.getDetails(postData);
        }
    }
    
  getDetails(postData: any) {
    this.commonService.getRequest(this.commonConstants.getMutationForCorrection+postData).subscribe({
      next: (responseData: any) => {
        if(typeof(responseData?.result) !== 'string') {
          this.showReports = true;
        this.getData = responseData?.result;
        this.getData = this.getData.filter(data => data.districT_NAME !== 'Total')
        this.totalItems = responseData?.result.filter((data: { districT_NAME: string; }) => data.districT_NAME === 'Total')
        }
        else {
          this.getData = [];
          this.totalItems = [];
        }
    },
       error: (error) => {
       console.log('client side', error);
     }
  });
  }
  
  onFromDateSelect(event: any) {
    this.showReports = false;
    this.toMinDate = event;
 }

  onToDateSelect(event: any) {
    this.showReports = false;
  }
}
