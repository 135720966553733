import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-integrated-certificate-view',
  templateUrl: './integrated-certificate-view.component.html',
  styleUrls: ['./integrated-certificate-view.component.css']
})
export class IntegratedCertificateViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  jobtitle: any;
  requisitiontype: any;
  
  filelistforsuomoto: any;
  sumotovrofile: any;
  sumotorifile: any;
  sumototehfile: any;
  filelist: any;
  sumotofiles: any = [];
  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
    let userData: any = this.commonService.getLocalStorage('userInfo');
    let userInfo = JSON.parse(userData);
    this.jobtitle = userInfo.jobTitle;
    this.requisitiontype = this.basicInfo.requisitionType;
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

  getOrderFiles() {
    if(this.requisitiontype === 'IntegratedCertificate-SuoMoto' && (this.jobtitle == 'Revenue Inspector' || this.jobtitle == 'Revenue Divisonal Officer' || this.jobtitle == 'Collector' || this.jobtitle == 'Tahsildar' )) {
      if(this.commonService?.filesforsumotodata?.length) {
        this.sumotovrofile = (this.commonService.filesforsumotodata.filter((itemsumotovro: any) => itemsumotovro.documentType == 'sumotoverificationDoc'))
        this.sumotorifile = (this.commonService.filesforsumotodata.filter((item: any) => (item.documentType === 'sumotoverificationDocforRI')))
        this.sumototehfile = (this.commonService.filesforsumotodata.filter((item: any) => (item.documentType === 'sumotoverificationDocforTahsildar')))
        this.sumotofiles = this.sumotovrofile.concat(this.sumotorifile, this.sumototehfile);
      }
      return this.sumotofiles
    }
  }
}
