import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbNavChangeEvent, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../../shared/common.service';
import { CommonConstants } from '../../../constants/common-constants.component';
import { DatePipe } from '@angular/common';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-job-seeker-reg',
  templateUrl: './job-seeker-reg.component.html',
  styleUrls: ['./job-seeker-reg.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class JobSeekerRegComponent implements OnInit {
  fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  jobSeekerForm : UntypedFormGroup;
  submitted: Boolean = false;
  commonFormSubmitted: Boolean = false;
  commonConstants : any = CommonConstants;
  @ViewChild('nav')
  nav: any;
  subCaste: any[] = [];
  month:any[]=[];
  passMonth:any[] = [];
  totExperience:any[]=[];
  years:any[]=[];
  entryYears:any[]=[];
  entrYear: any;
  maxDate: any;
  minDate: any; 
  toMinDate: any;
  currentDate = new Date();
  @ViewChild('passCertificateLabel')
  passCertificateLabel!: ElementRef;
  @ViewChild('marksheetLabel')
  marksheetLabel!: ElementRef;
  @ViewChild('aadharFileLabel')
  aadharFileLabel!: ElementRef;
  @ViewChild('casteCertLabel')
  casteCertLabel!: ElementRef;
  @ViewChild('docCVLabel')
  docCVLabel!: ElementRef;
  highestEdu: any[] = [];
  employmentStatus: any[] = [];
  //employmentStatusName: any[] = [];
  casteCategories: any[] = [];
  additionalDisabilities: any[] = [];
  educationList: any[] = [];
  boardUniversities: any[] = [];
  courseNatures: any[] = [];
  durationTypes: any[] = [];
  jobNature: any[] = [];
  sectors: any[] = [];
  states: any[] = [];
  districts: any[] = [];
  postData :any={};
  Languages:any[]=[];
  gridEducationHistoryList: 
    {
      education: string,
      educationName: string,
      specialization: string,
      boardUniversity: string,
      boardUniversityName: string,
      institute: string,
      entryYear:  string,
      entryMonth: string,
      passedYear: string,
      passedMonth: string,
      mediumEducation: string,
      mediumEducationName: string,
      courseNature:string,
      grade: string,
      marksheetRef: string,
      degreeCertificateRef: string
    }[]=[];
 
    gridQualTrainingsCoursesList: {
    courseName: string, 
    year: string,
    issuedBy: string,
    duration: string,
    durationIn: string,
    furtherDetails: string
 }[]=[];
 gridWorkExperienceList: {
  keySkills: string,
  totalExp: string,
  availableToJoin: string,
  currentJobTitle: string,
  currentSalary: string,
  salaryDuration: string,
  empOrgType: string,
  seekingJob: string
 }[]=[];
 gridEmploymentHistoryList: {
  employerName: string,
  employerAddress: string,
  state: string,
  district: string,
  empOrgType: string,
  designation: string,
  natureOfJob: string,
  sector: string,
  functionalArea: string,
  functionalRole: string,
  startDate: string,
  endDate: string,
  isCurrentJob: any,
  keyResponsibilities: string
 }[]=[];
 userInfo: any;

  constructor(    private config: NgbNavConfig, private formBuilder: UntypedFormBuilder,private datePipe: DatePipe, private commonService: CommonService,private router: Router,private activatedRoute:ActivatedRoute , private modalService: NgbModal) { 
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.toMinDate = {
      day: this.currentDate.getDate(),
      month: this.currentDate.getMonth() + 1,
      year: this.currentDate.getFullYear() - 150,
    }
    
for (let i=1;i<51;i++){
  this.totExperience.push({year:i})
  
}
console.log(this.totExperience);
let currentYear = (new Date()).getFullYear(); 
//Loop and add the Year values to DropDownList.
for (var i = 1950; i <= currentYear; i++) {
   this.years.push({year:i})
}
    this.month=[{month:1},{month:2},
                {month:3},{month:4},
                {month:5},{month:6},
                {month:7},{month:8},
                {month:9},{month:10},
                {month:11},{month:12}]
               
    this.jobSeekerForm = this.formBuilder.group({
      commonForm: [],
      highestEducation: [null, [Validators.required]],
      educationHistory: this.formBuilder.array([this.addEducationForm()]),
      otherQuals: this.formBuilder.array([this.addOtherQualForm()]),
      keySkills: [null, [Validators.required]],
      totalExpYear: [null, [Validators.required]],
      totalExpMonth: [null, [Validators.required]],
      joinInDays: [null, [Validators.required]],
      jobTitle: [null],
      salary: [null],
      salaryType: [null],
      empOrgType: [null, [Validators.required]],
      jobSeeking: [null],
      jobNature: [null],
      shiftTimings: [null],
      preferredLocation: [null],
      empHistory: this.formBuilder.array([this.addEmpForm()]),
      physicallyDisabled: [null],
      disabilityType: [null],
      workingCapacity: [null],
      fullPartDisable: [null],
      disablePercent: [null],
      additionDisability: [null],
      haveCertificate: [null],
      empStatus: [null, [Validators.required]],
      primaryLang: [null, [Validators.required]],
      //primaryLangName: [null],
      govEmployee: [null],
      exServiceMan: [null],
      category: [null, [Validators.required]],
      casteCert: [''],
      docCV: [''],
      aadharFile: [''],
      consent: [null, [Validators.required]],
      consent2: [null, [Validators.required]],

    })

    let userData = this.commonService.getLocalStorage('userInfo');
    if (userData && userData !== null) {
       this.userInfo = JSON.parse(userData);
    }

    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {
    this.getHighestEducation();
    this.getEmpStatus();
    this.getCasteCategory();
    this.getDisabilities();
    this.getEducation();
    this.getBoardUniversities();
    this.getCourseNature();
    this.getDurationType();
    this.getJobNature();
    this.getSectors();
    this.getStates();
    this.getLanguage();
  }
  get f() {
    return this.jobSeekerForm.controls;
  }
  get educationHistory() {
    return this.jobSeekerForm.get('educationHistory') as UntypedFormArray;
  }
  get otherQuals() {
    return this.jobSeekerForm.get('otherQuals') as UntypedFormArray;
  }
  get empHistory() {
    return this.jobSeekerForm.get('empHistory') as UntypedFormArray;
  }
  goToNextTab() {
    this.commonFormSubmitted = true;
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    }
  }

  keyPressAlphaNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlphabet(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  getEntryYear(i:any){
    this.entrYear = this.educationHistory.controls[i].get('entryYear')?.value;
    console.log('my entry year',this.entrYear)

    let currYear = (new Date()).getFullYear(); 

    for (let i = this.entrYear; i <= currYear; i++) {
      this.entryYears.push({year:i})
   }
   
  }

  getPassedMonth(i:any){
    this.passMonth.splice(0);
    if (this.educationHistory.controls[i].get('passedYear')?.value === 
    this.educationHistory.controls[i].get('entryYear')?.value){
      for (var i = this.educationHistory.controls[i].get('entryMonth')?.value+1; i<=12; i++){
        
        this.passMonth.push({month:i})
      }
     }
     else{
      this.passMonth=[{month:1},{month:2},
        {month:3},{month:4},
        {month:5},{month:6},
        {month:7},{month:8},
        {month:9},{month:10},
        {month:11},{month:12}]
     }
  }

  getSubCaste(data:any) {
    this.subCaste = data;
  }
  onChangeDisablity() {
    let controls :any = ['disabilityType', 'workingCapacity', 'fullPartDisable', 'disablePercent'];
    if(this.f.physicallyDisabled.value === 'Yes') {
      controls.forEach((item: any) => {
        this.f[item].setValidators([Validators.required]);
        this.f[item].updateValueAndValidity();
      });
    } else {
      controls.forEach((item: any) => {
        this.f[item].clearValidators();
        this.f[item].updateValueAndValidity();
      });
    }
  }
  addEducationForm() {
    return this.formBuilder.group({
      education: [null, [Validators.required]],
      boardUniversity: [null, [Validators.required]],
      specialization: [null, [Validators.required]],
      institute: [''],
      entryYear: [null],
      entryMonth: [null],
      passedYear: [null, [Validators.required]],
      passedMonth: [null, [Validators.required]],
      mediumEducation: [null, [Validators.required]],
      course: [null, [Validators.required]],
      gradeType: [null],
      gradeValue: [null],
      passCertificate:[''],
      marksheet: ['']
    })
  }
  addOtherQualForm() {
    return this.formBuilder.group({
      courseName: [null, [Validators.required]],
      year: [null, [Validators.required]],
      issuedBy: [null, [Validators.required]],
      duration: [''],
      durationIn: [null],
      details: [null]
    })
  }
  addEmpForm() {
    return this.formBuilder.group({
      employerName: [null, [Validators.required]],
      employerAddress: [null, [Validators.required]],
      state: [null, [Validators.required]],
      district: [null, [Validators.required]],
      empOrgType: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      natureOfJob: [null, [Validators.required]],
      sector: [null, [Validators.required]],
      funcArea: [null, [Validators.required]],
      funcRole: [null, [Validators.required]],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      isCurrentJob: [null],
      keyResp:[null]
    })
  }
  addEducation() {
    this.educationHistory.push(
      this.addEducationForm()
    );
  }
  removeEducation(i: any) {
    this.educationHistory.removeAt(i);
  }
  addEmployment() {
    this.empHistory.push(
      this.addEmpForm()
    );
  }
  removeEmployment(i: any) {
    this.empHistory.removeAt(i);
  }
  addQual() {
    this.otherQuals.push(
      this.addOtherQualForm()
    );
  }
  removeQual(i: any) {
    this.otherQuals.removeAt(i);
  }
  onPDFFileSelected(fileEvent: any, degree: any, controlName: any, labelName:any,  i?: any) {
      if(labelName === 'marksheetLabel') {
        this.marksheetLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      } else if(labelName === 'passCertificateLabel') {
        this.passCertificateLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      }
      let docType = degree + '_'+controlName;
      const file: File | null = fileEvent.target.files[0];
      console.log(docType,'to check education type')
      console.log('fileEvent', fileEvent);
      let fieldcontrol: any =
        this.educationHistory.controls[i].get(controlName);
        fieldcontrol?.setValidators([
        Validators.required,
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
      fieldcontrol?.updateValueAndValidity();
      if (fieldcontrol?.valid)
        this.fileUpload(file, fieldcontrol?.value, fileEvent.target.files[0].name,docType, controlName,labelName,i);
  }
  onFileSelected(fileEvent: any, controlName: any, labelName:any) {
    if(labelName === 'marksheetLabel') {
      this.marksheetLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    } else if(labelName === 'passCertificateLabel') {
      this.passCertificateLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    } else if (labelName === 'aadharFileLabel') {
      this.aadharFileLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    } else if (labelName === 'casteCertLabel') {
      this.casteCertLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    } else if (labelName === 'docCVLabel') {
      this.docCVLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      
    } 
    const file: File | null = fileEvent.target.files[0];
    console.log('fileEvent', fileEvent);
    //let fieldcontrol: any = this.f.controlName;
    let fieldcontrol: any = this.jobSeekerForm.get(controlName);
    console.log(fieldcontrol,'file upload control');
      fieldcontrol?.setValidators([
      Validators.required,
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
    fieldcontrol?.updateValueAndValidity();
    console.log(fieldcontrol?.valid)
    if (fieldcontrol?.valid)
    console.log(fieldcontrol?.value,fileEvent.target.files[0].name, controlName)
      this.fileUpload(file, fieldcontrol?.value, fileEvent.target.files[0].name, controlName,controlName,labelName);
}
  clearFileUpload(name: string, label: string, i?:any) {
    if(label === 'marksheetLabel' || label === 'passCertificateLabel') {
      this.educationHistory.controls[i].get(name)?.reset();
      this.educationHistory.controls[i].get(name)?.clearValidators();
      this.educationHistory.controls[i].get(name)?.updateValueAndValidity();
    } else {
      this.f[name]?.reset();
      this.f[name]?.clearValidators();
      this.f[name]?.updateValueAndValidity();
    }
   
    if (label === 'marksheetLabel') {
      this.marksheetLabel.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'passCertificateLabel') {
      this.passCertificateLabel.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'aadharFileLabel') {
      this.aadharFileLabel.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'casteCertLabel') {
      this.casteCertLabel.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'docCVLabel') {
      this.docCVLabel.nativeElement.innerText = 'No File Choosen';
    } 
  }
  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string, i?:any) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    console.log(formData, 'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
        next: (responseData: any) => {
          if(typeof(responseData.result) !== 'string'){
            let index = this.fileList.map((item) => item.DocumentType).indexOf(documentType);
            if (index > -1) {
              this.fileList.splice(index, 1);
            }
            this.fileList.push({
              FileName: fileName,
              DocumentType: documentType,
              BlobRef: responseData['result'].blobRef,
            });
            console.log(this.fileList)
          } else{
            if(name && label) {
              this.clearFileUpload(name, label, i);
            }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          }
          console.log(this.fileList, 'FileList');
        },
        error: (error) => {
          if(name && label) {
            this.clearFileUpload(name, label,i );
          }
          console.log('client side', error);
        },
      });
  }
  onStartDateSelect(event: any) {
    this.toMinDate = event;
 }

  submit() {
    let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
    
    let isgovEmployee = this.f['govEmployee'].value ? this.f['govEmployee'].value : false
    let isexServiceMan = this.f['exServiceMan'].value ? this.f['exServiceMan'].value : false
    console.log(isgovEmployee,'govt emp true or false')
    console.log(isexServiceMan,'ex service man true or false')
    console.log(this.jobSeekerForm,'job seeker form')
    if(this.jobSeekerForm.valid){
      this.submitted=false;
    this.addGridDataValuesinArray();
    console.log(this.gridEducationHistoryList,'checking code and name both')
    // console.log(this.f['jobNature'].value.name,'job nature name'),
    // console.log(this.f['jobNature'].value.entityCode,'job entity'),
    this.postData =
    {
      requisitionHeader: {
          requisitionType: 'JobSeekerRegistration',
          requisitionPKey: '',
          department: 'DEE',
          applicantInfo: {
          id:'',
          aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo'].trim(),
          personNames: [
        {
          languageCode: "en-in",
          firstName: this.f['commonForm'].value['firstName'],
          middleName: this.f['commonForm'].value['middleName'],
          lastName: this.f['commonForm'].value['lastName'],
          fatherHusbandName: this.f['commonForm'].value['fatherHusName'],
        }
            ],
            gender:this.f['commonForm'].value['gender'],
            birthDate: dateFormat,
            caste: this.f['commonForm'].value['caste'].name,
            religion: this.f['commonForm'].value['religion'].name,
            qualification: this.f['commonForm'].value['qualification'].name,
            maritalStatus: this.f['commonForm'].value['maritalStatus'],
            addresses: [
              {
                   addressType: "present",
                   doorNo: this.f['commonForm'].value['preDoorNo'],
                   streetName: this.f['commonForm'].value['preStreet'],
                   villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                   mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                   district: this.f['commonForm'].value['preDistrict'].entityCode,
                   pinCode: this.f['commonForm'].value['prePinCode'],
                   isRevenueLocation: true,
                   postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                   postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                   postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                   postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
              
              },
             {
                   addressType:"permanent",
                   doorNo: this.f['commonForm'].value['permDoorNo'],
                   streetName:this.f['commonForm'].value['permStreet'],
                   villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                   mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                   district: this.f['commonForm'].value['permDistrict'].entityCode,
                   pinCode: this.f['commonForm'].value['permPinCode'],
                   isRevenueLocation: true,
                   postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                   postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                   postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                   postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
      },
            ],
              rationCardNo: '',
              deliveryType:  this.f['commonForm'].value['deliveryType'],
              emailAddress: this.f['commonForm'].value['email'],
              mobileNumber: this.f['commonForm'].value['mobileNo'],
              isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
              isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
          },
          remarks:''
        },
     requisitionData: {
       casteCategory: this.f['category'].value,
       physicallyDsiabled: this.f['physicallyDisabled'].value,
       disabilityType: this.f['disabilityType'].value,
       workingCapacity: this.f['workingCapacity'].value,
       partialOrFullDisable: this.f['fullPartDisable'].value,
       disabilityPercentage: this.f['disablePercent'].value,
       additionalDisableType: this.f['additionDisability'].value,
       haveCertificate: this.f['haveCertificate'].value,
       employmentStatus: this.f['empStatus'].value.entityCode,
       employmentStatusName: this.f['empStatus'].value.name,
       primaryLang: this.f['primaryLang'].value.entityCode,
       primaryLangName: this.f['primaryLang'].value.name,
       IsGovtEmployee: isgovEmployee,
       IsExServiceman: isexServiceMan,
       highestEducation: this.f['highestEducation'].value.entityCode,
       highestEducationName: this.f['highestEducation'].value.name,
       workExperienceList: [
        {
            keySkills: this.f['keySkills'].value,
            totalExp: this.f['totalExpYear'].value,
            availableToJoin: this.f['totalExpMonth'].value,
            currentJobTitle: this.f['jobTitle'].value,
            currentSalary: this.f['salary'].value,
            salaryDuration: this.f['salaryType'].value,
            empOrgType: this.f['empOrgType'].value,
            seekingJob: ''
        }
      ],
      educationHistoryList:this.gridEducationHistoryList,
      qualTrainingsCoursesList:this.gridQualTrainingsCoursesList,
      employmentHistoryList:this.gridEmploymentHistoryList,

      //  educationHistoryList: [{
      //     education:'',
      //     specialization: '',
      //     boardUniversity: '',
      //     Institute: '',
      //     entryYear: '',
      //     entryMonth: '',
      //     passedYear: '',
      //     passedMonth: '',
      //     mediumEducation: '',
      //     courseNature: '',
      //     grade: '',
      //     marksheetRef: '',
      //     degreeCertificateRef: ''
      //  }],
      //  qualTrainingsCoursesList: [{
      //     courseName: '', 
      //     year: '',
      //     issuedBy: '',
      //     duration: '',
      //     furtherDetails: ''
      //  }],
      //  workExperienceList: {
      //   keySkills: '',
      //   totalExp: '',
      //   availableToJoin: '',
      //   currentJobTitle: '',
      //   currentSalary: '',
      //   salaryDuration: '',
      //   empOrgType: '',
      //   seekingJob: ''
      //  },
      //  employmentHistoryList: [{
      //   employerName: '',
      //   employerAddress: '',
      //   state: '',
      //   district: '',
      //   empOrgType: '',
      //   designation: '',
      //   natureOfJob: '',
      //   sector: '',
      //   functionalArea: '',
      //   functionalRole: '',
      //   startDate: '',
      //   endDate: '',
      //   isCurrentJob: '',
      //   keyResponsibilities: ''
      //  }],
       preferedLocation: this.f['preferredLocation'].value,
       jobNature: this.f['jobNature'].value,
       shiftTimings: this.f['shiftTimings'].value
    }
  }
  console.log(this.postData,'to check postData')
    this.commonService.postRequest(this.commonConstants.JobSeekerRegistration, this.postData).subscribe({
      next: (responseData:any) => { 
        console.log(responseData, 'ssss resData')
        this.updateFileUpload(responseData);
      }})
    }else{
      this.submitted=true;
    }
    //console.log()
  }
  updateFileUpload(data: any) {
    let fileData = {
      FileList: this.fileList,
      RequisitionId: data.result.id,
      RequisitionType : "JobSeekerRegistration"
    }
    
    this.commonService.postRequest(this.commonConstants.updateFileUpload,fileData).subscribe({
      next: (resData:any) => { 
        console.log(resData,'File Upload  Respose data');
        //this.showPayment(id);
        this.workflowStatusUpdate(data);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  
  workflowStatusUpdate(responseData: any, postAPIRes?:any, postAPIReq?:any, from?: any, statusCode?:any) {
    let NCSMsg: any;
    let NCSStatus: any;
    let year=  this.currentDate.getFullYear()
    let month= this.currentDate.getMonth() + 1
    let day=  this.currentDate.getDate()
    let dateFormat = day +"-"+  month +"-"+ year 
    let createdDate = moment(dateFormat,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss")
    let reqType = responseData.result.requisitionHeader.requisitionType
    let dpCode  = responseData.result.requisitionHeader.department
    let resID = responseData.result.id
    if (from === 'post') {
      if(statusCode === '1'){
        NCSMsg = '1';
        NCSStatus = 'Forwarded to NCS Team'
      } else{
        NCSMsg = '-1';
        NCSStatus = 'Not Forwarded to NCS Team'
      }
    } else {
      NCSMsg = '0';
      NCSStatus = 'Forwarded'
    }
    let reqStatus: any;
    if(from === 'post') {
        reqStatus = (postAPIRes != undefined || postAPIRes != null) ? JSON.stringify(postAPIRes) : '';
    } else {
      reqStatus = NCSStatus;
    }
    let addInfo1: any = from === 'post' ? JSON.stringify(postAPIReq) :'';
    // let stringToHash = resID + '&' + 
    // dpCode + '&' + 
    // '' + '&' + '' + '&'+ '' + '&' + NCSStatus +'&' +  reqType + '&' +
    // this.SLA+ '&' + NCSMsg +'&' + NCSStatus +'&' +''+'&' + this.userInfo.userPrincipalName + '&' + createdDate;
    // let postData: any = {};
    // postData = {
    //   strToHash : stringToHash
    // }
  
    //console.log('update', postData);
    // this.commonService.postRequest(this.commonConstants.postWorkFlowGetHmacHash, postData).subscribe({
    //   next: (responseData:any) => { 
    //     console.log('response from GetHmacHash', responseData)
    //     if(responseData['result'].reason == 'Success'){
          let workFlowPostData : any = {}
          workFlowPostData = {
            workFlowStatusData: {
              createdBy: this.userInfo.userPrincipalName,
              created: createdDate,
              lastModifiedBy: this.userInfo.userPrincipalName,
              lastModified: createdDate,
              id: 0,
              requisitionId: resID,
              departmentCode: dpCode,
              requisitionType: reqType,
              subRequisitionType: '',
              statusCode: NCSMsg,
              statusDesc: NCSStatus,
              requisitionStatus: reqStatus,
              //slaDays: this.SLA,
              officerId: '',
              officerName: '',
              officerMobileNumber: '',
              additionalInfo1: addInfo1,
              additionalInfo2: '',
              additionalInfo3: ''
            },
            hash: '',  //responseData['result'].hashRes
          }
          console.log('req body from workFlowPostData', workFlowPostData)
          this.commonService.postRequest(this.commonConstants.postWorkFlowStatusUpdate, workFlowPostData).subscribe({
            next: (workflowRes:any) => { 
              console.log('response from postWorkFlowStatusUpdate', workflowRes)
              if(workflowRes['result'] == 'Saved Successfully'){
                if(from === 'post') {
                  if(NCSMsg === '1'){
                    this.showPayment(resID);
                  }else{
                    let errorMessage = "Error occured while posting the application.";
                    if(postAPIRes?.JSResults?.length > 0 && postAPIRes?.JSResults[0]?.RegistrationFault) {
                      errorMessage = postAPIRes?.JSResults[0]?.RegistrationFault;
                    }
                    const modalRef = this.modalService.open(CommonMsgModalComponent, {
                      size: 'md',
                    });
                    modalRef.componentInstance.title = 'Alert';
                    modalRef.componentInstance.message =  errorMessage;
                  }
                } else {
                  this.fetchNCSMappedData(responseData);
                }
              } else {
                this.commonService.commonErrorPopup('Alert', 'Error occured while saving data in workflow status update table.', 'md');
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
          });
  }

  getNCSGender(id:any) {
    if(id == "Female") {
      return "F";
    } else if(id == "Male") {
      return "M";
    } else if(id == "Transgender") {
      return "T";
    } else {
      return null;
    }
  }

  getNCSMaritalStatus(id:any) {
    if(id == "Divorced") {
      return "4";
    } else if(id == "Married") {
      return "2";
    } else if(id == "Unmarried") {
      return "1";
    } else if(id == "Widow") {
      return "3";
    } else if(id == "Single Women(Married)") {
      return "5";
    } else {
      return null;
    }
  }

  fetchNCSMappedData(result:any) {
    forkJoin([this.commonService.getRequest(this.commonConstants.getNCSDistrict+ this.f['commonForm'].value['preDistrict'].id), 
    this.commonService.getRequest(this.commonConstants.getNCSDistrict+ this.f['commonForm'].value['permDistrict'].id),
    // this.commonService.getRequest(this.commonConstants.getNCSMandal+ this.f['commonForm'].value['preMandal'].id),
    // this.commonService.getRequest(this.commonConstants.getNCSMandal+ this.f['commonForm'].value['permMandal'].id),
    this.commonService.getRequest(this.commonConstants.getNCSReligion+ this.f['commonForm'].value['religion'].id)])
     .subscribe({
      next: (res: any) => { 
        console.log("NCS Data", res);
        let preNCSDistrict=null;
        let permNCSDistrict=null;
        // let preNCSMandal=null;
        // let permNCSMandal=null;
        let ncsReligion=null;
        if(res[0]?.result.length > 0) {
          preNCSDistrict = res[0]?.result[0]?.entityCode;
        }
        if(res[1]?.result.length > 0) {
          permNCSDistrict = res[1]?.result[0]?.entityCode;
        }
        // if(res[2]?.result.length > 0 && preNCSDistrict) {
        //   preNCSMandal = res[2]?.result[0]?.entityCode;
        // }
        // if(res[3]?.result.length > 0 && permNCSDistrict) {
        //   permNCSMandal = res[3]?.result[0]?.entityCode;
        // }
        if(res[2]?.result.length > 0) {
          ncsReligion = res[2]?.result[0]?.entityCode;
        }

        this.postToNCS(result, preNCSDistrict, permNCSDistrict, ncsReligion);
      },
      error: (error) => {
      console.log('client side', error);
      }
    });
  
  }

  postToNCS(result:any, preNCSDistrict:any, permNCSDistrict:any, ncsReligion:any) {
    this.commonService.postRequestForNCS(this.commonConstants.EXNCSAuthenticateUser, '', '').subscribe({
      next: (responseData: any) => {
        if(responseData.AuthenticateUserResult.IsSuccess == true) {
          let token = responseData.AuthenticateUserResult.Cookie;
          
          let educationHistoryList: any[] = [];
          this.educationHistory.value.forEach((element: { 
            passedYear: any;
            course: any;
            gradeType:any;
            gradeValue: any;}) => {
            return educationHistoryList.push(
              {
                PassingYear: element.passedYear,
                NatureOfCourse: element.course,
                GradePercentagePercentileType: element.gradeType == "Grade" ? "4" : "1",
                GradePercentagePercentileValue: element.gradeValue,        
              }
            ) 
          });
         
          let postData:any = [{
            ApplicationFormID: result.result.id,
            FirstName: this.f['commonForm'].value['firstName'],
            DOB: this.commonService.NCSFormatDate(this.f['commonForm'].value['dob']),
            GuardianName: this.f['commonForm'].value['fatherHusName'],
            MobileNo: this.f['commonForm'].value['mobileNo'],
            Gender: this.getNCSGender(this.f['commonForm'].value['gender']),
            EmploymentStatus: this.f['empStatus'].value.entityCode,
            PrimaryLanguage: this.f['primaryLang'].value.entityCode,
            Addresses: [
              {
                Address1: this.f['commonForm'].value['preDoorNo'],
                State: "28", 
                District: preNCSDistrict,
                TerritoryType: null,
                // SubDistrictTaluka: preNCSMandal,
                Pincode: this.f['commonForm'].value['prePinCode']
              },
              {
                Address1: this.f['commonForm'].value['permDoorNo'],
                State: "28", 
                District: permNCSDistrict,
                TerritoryType: "r", 
                // SubDistrictTaluka: permNCSMandal,
                Pincode: this.f['commonForm'].value['permPinCode']
              }
            ],
            HighestEducation  : this.f['highestEducation'].value.entityCode,
            Education: educationHistoryList,
            Skills: [
              {
                SkillName: this.f['keySkills'].value
              }
            ],
            MaritalStatus: this.getNCSMaritalStatus(this.f['commonForm'].value['maritalStatus']),
            ReligionID: ncsReligion,
            Caste: this.f['category'].value,
            UIDs: [                 
              {
                UIDType: "5",
                UIDNumber: this.commonService.RSAEncrypt(this.f['commonForm'].value['aadharNo'].trim(), true)
              }
            ]
          }];     
          this.commonService.postRequestForNCS(this.commonConstants.EXNCSRegisterJobseekers, postData, token).subscribe({
            next: (responseData: any) => {
              if(responseData.IsFault == false && responseData?.JSResults?.length > 0 && responseData?.JSResults[0]?.IsRegistrationSuccessfull == true) {
                  this.workflowStatusUpdate(result, responseData, postData,  'post', '1');
              } else {
                this.workflowStatusUpdate(result, responseData, postData,  'post', '-1');
              }
            },
            error: (error: any) => {
            console.log('client side', error);
            }
          });
        } else {
          this.commonService.commonErrorPopup('Alert', 'Error occured at posting data at NCS application.', 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    }); 
   }

  // updateFileUpload(id: any) {
  // //  let finalFiles:any[]=[];
  
  //   let fileData = {
  //     FileList: this.fileList,
  //     RequisitionId: id,
  //   };
  //   this.commonService
  //     .postRequest(this.commonConstants.HealthCardFileUpload, fileData)
  //     .subscribe({
  //       next: (resData: any) => {
  //         console.log(resData, 'File Upload  Respose data');
         
  //           this.showPayment(id);
          
          
  //         //this.showPayment(id);
  //       },
  //       error: (error) => {
  //         console.log('client side', error);
  //       },
  //     });
  // }
  showPayment(requisionId: any) {
   // this.router.navigate(['/payment-gateway']);
    let type:any;
    type='JobSeekerRegistration'
    const queryParams: Params = {
      id: requisionId,
      department: 'DEE',
      type: type,
    };
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
  addGridDataValuesinArray(){

    this.gridEducationHistoryList = [];
    this.gridQualTrainingsCoursesList=[];    
    this.gridEmploymentHistoryList=[];
    this.gridWorkExperienceList=[];
    
    this.educationHistory.value.forEach((element: { 
      education:any;
      educationName:any;
      boardUniversity: any;
      boardUniversityName: any;
      specialization: any;
      institute: any;
      entryYear: any;
      entryMonth: any;
      passedYear: any;
      passedMonth: any;
      mediumEducation:any;
      mediumEducationName:any;
      course: any;
      gradeType:any;
      gradeValue: any;
      passCertificate:any;
      marksheet: any;}) => {

      return this.gridEducationHistoryList.push(
        {
          education:element.education?.entityCode,
          educationName:element.education?.name,
          specialization:element.specialization,
          boardUniversity:element.boardUniversity?.entityCode,
          boardUniversityName:element.boardUniversity?.name,
          institute: element.institute,
          entryYear: element.entryYear,
          entryMonth: element.entryMonth,
          passedYear: element.passedYear,
          passedMonth: element.passedMonth,
          mediumEducation: element.mediumEducation.entityCode,
          mediumEducationName:element.mediumEducation.name,
          courseNature: element.course,
          grade: element.gradeValue,
          marksheetRef: element.marksheet,
          degreeCertificateRef: ''

        }
      ) 
    });
    this.otherQuals.value.forEach(
      (element: { 
        courseName: any;
        year:  any;
        issuedBy:  any;
        duration: any;
        durationIn:  any;
        details:  any;}) => {
          return this.gridQualTrainingsCoursesList.push({
            courseName: element.courseName, 
            year: element.year,
            issuedBy: element.issuedBy,
            duration: element.duration,
            durationIn: element.durationIn,
            furtherDetails: element.details
          })
  
         } );
   this.empHistory.value.forEach(
          (element: { 
            employerName:any;
            employerAddress:any;
            state: any;
            district: any;
            empOrgType: any;
            designation: any;
            natureOfJob: any;
            sector: any;
            funcArea: any;
            funcRole: any;
            startDate: any;
            endDate: any;
            isCurrentJob:any;
            keyResp:any;
          }) => {
         let  startDate= this.commonService.formatDate(element.startDate);
         let  endDate= this.commonService.formatDate(element.endDate);
              return this.gridEmploymentHistoryList.push({
                employerName: element.employerName,
                employerAddress: element.employerAddress,
                state: element.state,
                district: element.district,
                empOrgType: element.empOrgType,
                designation: element.designation,
                natureOfJob: element.natureOfJob,
                sector: element.sector,
                functionalArea: element.funcArea,
                functionalRole: element.funcRole,
                startDate: startDate,
                endDate: endDate,
                isCurrentJob: element.isCurrentJob? true:false,
                keyResponsibilities: element.keyResp
              })
      
             } );

  }
  getHighestEducation() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-HighestEducation').subscribe({
      next: (responseData: any) => { 
      this.highestEdu = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getEmpStatus() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-EmploymentStatus').subscribe({
      next: (responseData: any) => { 
      this.employmentStatus = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getLanguage() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-EDU-LANGUAGE').subscribe({
      next: (responseData: any) => { 
      this.Languages = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getCasteCategory() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-CASTES').subscribe({
      next: (responseData: any) => { 
      this.casteCategories = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getDisabilities() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-DifferentlyAbledCategory').subscribe({
      next: (responseData: any) => { 
      this.additionalDisabilities = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getEducation() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-Education').subscribe({
      next: (responseData: any) => { 
      this.educationList = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getBoardUniversities() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-BOARDUNIVERSITIES').subscribe({
      next: (responseData: any) => { 
      this.boardUniversities = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getCourseNature() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-CourseNature').subscribe({
      next: (responseData: any) => { 
      this.courseNatures = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getDurationType() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-CourseDurationTypes').subscribe({
      next: (responseData: any) => { 
      this.durationTypes = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getJobNature() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-JobNatures').subscribe({
      next: (responseData: any) => { 
      this.jobNature = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getSectors() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-Sectors').subscribe({
      next: (responseData: any) => { 
      this.sectors = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getStates() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'NCS-LD_States_UT').subscribe({
      next: (responseData: any) => { 
      this.states = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  onChangeState(i: any) {
    this.districts = [];
    this.empHistory.controls[i].get('district')?.patchValue(null);
    let state:any
    state=this.states.filter((c:any)=> (c.name === this.empHistory.controls[i].get('state')?.value));
    
    this.getDistricts(state[0].id)
  }
  getDistricts(id: any) {
    this.commonService.getRequest(this.commonConstants.getByParentId + 'NCS-LD_Districts&ParentId='+ id +'&ParentType=NCS-LD_States_UT&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => { 
      this.districts = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  
  }

