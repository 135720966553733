<div class="modal-header">
  <h6 class="modal-title">Know your Aarogya Suraksha 2.0 Camp Date</h6>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span >&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [formGroup]="form">
    <div class="row mb-3">
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls"
          >District : <span class="mandatoryCls">*</span></label
        >
        <select
          class="selectCls"
          formControlName="district"
          (change)="onDistrictChange()"
          [ngClass]="{
            'is-invalid':
              submitted && (f.district.errors || f.district.value === 'null')
          }"
        >
          <option [ngValue]="null">Select</option>
          <option *ngFor="let district of districts" [ngValue]="district">
            {{ district.districtName }}
          </option>
        </select>
        <div
          *ngIf="
            submitted &&
            (f.district.errors?.required || f.district.value === 'null')
          "
          class="selectError"
        >
          Please select District
        </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls"
          >Mandal/Municipality: <span class="mandatoryCls">*</span></label
        >
        <select
          class="selectCls"
          formControlName="mandal"
          (change)="onMandalChange()"
          [ngClass]="{
            'is-invalid':
              submitted && (f.mandal.errors || f.mandal.value === 'null')
          }"
        >
          <option [ngValue]="null">Select</option>
          <option *ngFor="let mandal of mandals" [ngValue]="mandal">
            {{ mandal.mandalName }}
          </option>
        </select>
        <div
          *ngIf="
            submitted &&
            (f.mandal.errors?.required || f.mandal.value === 'Select')
          "
          class="selectError"
        >
          Please select Mandal
        </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">VHC:</label>
        <select
          class="selectCls"
          formControlName="vhc"
          (change)="clearCampDetailsData()"
          [ngClass]="{
            'is-invalid':
              submitted && (f.vhc.errors || f.vhc.value === 'null')
          }"
        >
          <option [ngValue]="null">Select</option>
          <option *ngFor="let vhc of vhcs" [ngValue]="vhc">
            {{ vhc.vhcName }}
          </option>
        </select>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Secretariat:</label>
        <select
          class="selectCls"
          formControlName="secretariat"
          (change)="clearCampDetailsData()"
          [ngClass]="{
            'is-invalid':
              submitted && (f.secretariat.errors || f.secretariat.value === 'null')
          }"
        >
          <option [ngValue]="null">Select</option>
          <option *ngFor="let secretariat of secretariats" [ngValue]="secretariat">
            {{ secretariat.secretariatName }}
          </option>
        </select>
      </div>
    </div>
    <div class="mb-3 mt-4">
      <div class="btnHolder text-center col-xs-12">
        <button
          class="btn btn-primary mx-auto d-block"
          (click)="getAarogyaSurakshaCampDetails()"
        >
          Check Camp Details
        </button>
      </div>
    </div>
    <div *ngIf="surakshaCampDetails">
      <table class="tableCls" *ngIf="surakshaCampDetails.jasByVHCScheduledDateData != null">
        <thead>
          <tr>
            <th>Schedule Date</th>
            <th>Secretariat Name</th>
            <th>VHC/UPHC Name</th>
            <th>Venue Type</th>
            <th>Venue Address</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of surakshaCampDetails.jasByVHCScheduledDateData">
            <td>
              {{
                data.scheduleD_DATE ? data.scheduleD_DATE : "-"
              }}
            </td>
            <td>
              {{
                data.secretariaT_NAME ? data.secretariaT_NAME : "-"
              }}
            </td>
            <td>
              {{
                data.vhC_NAME ? data.vhC_NAME : "-"
              }}
            </td>
            <td>
              {{
                data.venuE_TYPE ? data.venuE_TYPE : "-"
              }}
            </td>
            <td>
              {{
                data.venuE_ADDRESS ? data.venuE_ADDRESS : "-"
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <p
        *ngIf="submitted && form.valid && surakshaCampDetails.jasByVHCScheduledDateData == null"
        style="text-align: center; color: red"
      >
        <strong>No records found</strong>
      </p>
    </div>
  </div>
</div>
