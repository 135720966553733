 <!--header start-->
 <header id="masthead" class="header ttm-header-style-classicinfo">
   
    <!-- ttm-topbar-wrapper -->
    <section class="ap_seva_portal_section">
        <div class="ap_seva_portal_main_div">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 col-lg-2">
                        <div class="main_logo_section">
                            <img src="../../assets/home-images/ap_seva_portal_govt_logo.svg" alt="ap_seva_logo">
                        </div>

                    </div>
                    <div class=" d-none d-lg-block col-lg-1"></div>
                    <div class="col-md-3 col-lg-2 d-flex align-items-center justify-content-center">
                        <div class="search-main-box ">
                            <div class="input-box">
                                <input #aadharLabel [type]="isMobile ? 'password':'text'"  class="search__input width165" placeholder="Enter Your Aadhar" maxlength="12" minlength="12" 
                                appBlockCopyPaste  numbersOnly  [value]="maskedaadharNumber" (select)="select($event)"
                                (mousedown)="setSelection($event,true)" (mouseup)="setSelection($event,false)"
                                (keydown)="onkeydown($event)"
                                (keypress)="onKeypress($event)">
                                       <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black"
                                        class="bi bi-search" viewBox="0 0 16 16" (click)="validateCaptcha('aadhar')">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg></a>
                            </div>


                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
                        <div class="search-main-box ">
                            <div class="input-box">
                                <input type="text" class="search__input width165" placeholder="Preview AP Seva Certificate" [(ngModel)]="certApplicationNo">
                                 <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black"
                                        class="bi bi-search" viewBox="0 0 16 16"  (click)="validateCaptcha('transaction')">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg></a>
                            </div>


                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 d-flex align-items-center justify-content-center">
                        <div class="search-main-box ">
                            <div class="input-box">
                                <input type="text" class="search__input" placeholder="Service Request Status Check" [(ngModel)]="applicationNo">
                                   <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black"
                                    (click)="validateCaptcha('application', showTransactionStatusNewPopup)"   class="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg></a>
                            </div>


                        </div>
                    </div>
                    <div class="col-md-5 d-lg-none"></div>
                    <div class="col-md-2 col-lg-1 d-flex align-items-center justify-content-center">
                        <div class="site-navigation-cls">
                            <ul class="p-0 pull-right">
                            <li  class="loginMenu"><a href="#" class="login-btn">Login</a>
                                <ul class="home-sub-menu" style="top: 28px;">
                                    <li><a (click)="login()">Citizen Login</a>
                                    <li><a (click)="login()">Employee Login</a>
                                </ul>
                            </li>                            
                                                    
                                    </ul>
                            </div> </div>
                    <div class="col-md-5 d-lg-none"></div>

                </div>
            </div>
        </div>
    </section>

   <!-- ttm-topbar-wrapper end -->
    <!-- ttm-header-wrap -->
    
</header>

<ng-template #searchModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title">Transaction Details</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Transaction Id:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Name:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Transaction Date:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Status:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Approved / Rejected Date:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Printed By:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">SLA (In days):</label>
                    <div class="valueCls"></div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Application No:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Service Name:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Amount:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Remarks:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Printed Date:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Franchisee Id / VLE Id:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Mandal:</label>
                    <div class="valueCls"></div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Delivery Type:</label>
                    <div class="valueCls"></div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="login-btn" (click)="modal.dismiss()">Close</button>
      <button  type="submit" class="login-btn" name="logout" (click)="print()">Print</button>
    </div>
   </ng-template>



   
<ng-template #showTransactionStatusNewPopup let-modal class="modalCls">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Transaction Details </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span >×</span>
        </button>
    </div>

    <div class="modal-body" id="transPoup">
        <br>
        <div class="table-responsive">
            <table class="table-bordered table-info table">
                <thead>
                    <tr>
                        <th *ngIf="isAadharapplication == false"  colspan="7" style="text-align:center; font-size:15px;">Transaction ID - {{ applicationNo ? applicationNo :''}}</th>
                        <th *ngIf="isAadharapplication == true" colspan="7" style="text-align:center; font-size:15px;">Transaction ID - {{ adharApplication ? adharApplication : ''}}</th>
                    </tr>
                    <tr>
                        <th scope="col">District Name</th>
                        <th scope="col">Mandal Name</th>
                        <th scope="col" class="width32">Secretariat Name</th>
                        <th scope="col">Service Name</th>
                        <th scope="col">Citizen Name</th>
                        <th scope="col">SLA</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td> {{ transactionDataPopup[0].raised_District}}</td>
                        <td> {{ transactionDataPopup[0].raised_Mandal}}</td>
                        <td> {{ transactionDataPopup[0].raised_Secretariat}}</td>
                        <td> {{ transactionDataPopup[0].serviceName}}</td>
                        <td> {{ transactionDataPopup[0].applicant_Name}}</td>
                        <td> {{ transactionDataPopup[0].slaDays}}</td>
                        <td> {{ transactionDataPopup[0].slA_Status}}</td>
                    </tr>
                </tbody>

           
            </table>

        </div>
        <br>
        <br>

        <div class="row mt-5 nomargin pl-3" *ngIf="transactionDataPopup[0].service_Type === 'B'">
          
         <div class="col nopadding">
            <ul class="progressbar row progressbar{{cntDash}}">
            
                <li class="col nopadding"
                *ngFor="let item of transactionDataPopup;let i = index;"
                [ngClass]="[ (item?.sent_To_User_DT !== null) && (((item.sub_SLA_Status == 'Closed Within Sub-SLA')||( item.sub_SLA_Status =='Closed Within SUBSLA') ? 'closed_wihtin_request':'') ||  (item.sub_SLA_Status == 'Closed Beyond Sub-SLA' ? 'closed_request':'') || (item.sub_SLA_Status == 'Open Beyond Sub-SLA' ? 'inactive':'') || (item.sub_SLA_Status == 'Open Within Sub-SLA' ? 'active':''))  ]">
                    <div class="left-divider green-divider">
                        <div>
                        <h6>{{ item.userDesignation }} ({{item?.sub_SLA_Days }}) </h6>
                        <p> Received Date: {{ item?.sent_To_User_DT | date: 'dd-MM-yyyy' }} </p>
                        <p> Action Taken Date: {{ item?.action_Taken_DT | date: 'dd-MM-yyyy'}} </p>
                        <p> Pending Days: {{ item?.pending_For_Days }} </p>
                        <p> User: {{ item?.assignedTo }} </p>
                        <p *ngIf="item?.sent_To_User_DT !== null"> {{ item.sub_SLA_Status }} </p>
                    </div>
                    </div>
                </li>
            
            </ul>

         </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>

<ng-template #addModal let-modal>
    <div class="name">
       {{this.services[0].citizeN_NAME}}
        <button type="button" style="color:black;" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span>×</span>
        </button>
    </div>
    

    <div class="modal-body">
        <table class="tableCls">
            <thead>
            <tr>
                <th>S.No</th>               
                <th>Service Name</th>
                <th>Application Number</th>
                <th>Status</th>
            </tr>
        </thead>
            <tbody  *ngIf="services && services.length !==0">
            <tr *ngFor="let service of services | slice: (page-1) * pageSize : page * pageSize; let i = index;">
                <td class="serial">{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{service?.serviceName}}</td>
                <td><a class="textDcor" (click)="getDashBoardApplicationDetails(showTransactionStatusNewPopup,service.requisitioN_ID,'aadharSearch')">{{service?.requisitioN_ID}}</a></td>
                <td>{{service?.apP_STATUS}}</td>
        </tr>
    </tbody>
        </table>
        <div class="mt-4 btnHide">
            <div class="float-left" *ngIf="services.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="services.length > 0" [collectionSize]="services.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
        <div class="text-center selectError clsSize"
        *ngIf="services && services.length ===0">

        No records available...
    </div>
        
    </div>
    <div class="modal-footer">
    
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>