import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbNavChangeEvent ,NgbNavConfig} from '@ng-bootstrap/ng-bootstrap';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonService, CustomAdapter, CustomDateParserFormatter,WhitespaceValidator, AadharValidator } from '../../../shared/common.service';
import { CommonConstants } from '../../../constants/common-constants.component';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { fileExtensionValidator, fileSizeValidator ,fileSizeValidatorForLabourService} from 'src/shared/validators/file-validator';
import { timeStamp } from 'console';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import * as moment from 'moment';
//import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-labour-registration',
  templateUrl: './labour-registration.component.html',
  styleUrls: ['./labour-registration.component.css'],
  providers: [ NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    
  ]
})
export class LabourRegistrationComponent implements OnInit {
  @ViewChild('nav')
  nav: any;
  @ViewChild('empPhotoLabel')
  empPhotoLabel!:ElementRef;
  @ViewChild('panNoLabel')
  panNoLabel!: ElementRef;
  @ViewChild('aadharNoLabel')
  aadharNoLabel!: ElementRef;
  @ViewChild('vatNoLabel')
  vatNoLabel!: ElementRef;
  @ViewChild('gstNoLabel')
  gstNoLabel!: ElementRef;
  @ViewChild('AuthorizationLabel')
  AuthorizationLabel!: ElementRef;
  @ViewChild('uploadEstablishmentLabel')
  uploadEstablishmentLabel!: ElementRef;
  
  registartionForm : UntypedFormGroup;
  commonFormSubmitted: Boolean = false;
  showForm:Boolean=false;
  submitted: Boolean = false;
  postData :any={};
  categoryEstablishment:any[]=[];
  classificationEstablishment:any[]=[];
  natureOFBusiness:any[]=[];
  villages:any[]=[];
  districts:any[]=[];
  mandals:any[]=[];
  empMandals:any[]=[];
  empVillages:any[]=[];
  designations:any[]=[];
  relations:any[]=[];
  commonConstants : any = CommonConstants;
  closeResult: string = '';
  isShowStartUpId:Boolean=false;
  maxDate: any;
  minDate: any; 
  isOtherAddress:Boolean=false;
  isAPAddress:Boolean=false;
  validExt = '';

  certificateInfo: any = [];
  created: any;
  fileListView: any = [];
  basicInfo: any = [];
  isFetchSubmitted: boolean = false;
  isInfoPrefilled: boolean = false;
 
  // maxDateforcompletion:any;
  // minDateforcompletion:any;
  currentDate = new Date();
  certificateTypeName:string="";
  reqId:any;
  showWorkers:Boolean=false;
   fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService, private config: NgbNavConfig,
    private router: Router,private activatedRoute:ActivatedRoute, private modalService: NgbModal
    ) { 
    this.registartionForm = this.formBuilder.group({
      commonForm: [],
      isFreshRenew:[null,[Validators.required]],
      prevApplicationNo:[null],
      isEstablishment:[null,[Validators.required]],
      isRegistered:['',Validators.required],
      StartUpId:[null],
      areaOfService:[null,[Validators.required]],
      //applicationNumber:[null,[Validators.required]],
      natureOfBusiness:[null,[Validators.required]],
      apShop:[null,[Validators.required]],
      directMale:[null,[Validators.required]],
      directFemale:[null,[Validators.required]],
      contractMale:[null,[Validators.required]],
      contractFemale:[null,[Validators.required]],
      casualMale:[null,[Validators.required]],
      casualFemale:[null,[Validators.required]],
      totalWorkers:[null,[Validators.required]],
      // motorTransport:[null],
      // contractLabour:[null],
      // InterStateMigrantWorkmen:[null],
      // buildingAndOtherConstructionWorker:[null],
      // paymentOfGatuity:[null],
      // beediAndCigerWorkers:[null],
      // contractLabourEstablishment:[null],
      // interStateMigrantWorkmen:[null],
      nameOfShop:[null,[Validators.required]],
      classificationOfEstablishment:[null,[Validators.required]],
      categoryofEstablishment:[null,[Validators.required]],
      StreetOrDoorNo:[null,[Validators.required, Validators.pattern(this.commonConstants.doorNoStreetNamePattern)]],
      locality:[null,[Validators.required,Validators.pattern(this.commonConstants.streetNamePattern)]],
      district:[null,[Validators.required]],
      mandal:[null,[Validators.required]],
      village:[null,[Validators.required]],
      pincode:[null,[Validators.required]],
      mobile:[null],
      email: ['', [Validators.pattern(this.commonConstants.emailPattern)]],
      relationShip:[null,[Validators.required]],
      companyOrPropritor:[null,[Validators.required]],
      dateOfCommencement:[null,[Validators.required]],
      dateOfCompletion:[null],
      employerState:[null,[Validators.required]],
      employersAadhar:['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      emplyersName:[null,[Validators.required]],
      fatherOrHusbandName:[null,[Validators.required]],
      otherAddress:[null],
      employerDistrict:[null],
      employerMandal:[null],
      employerVillage:[null],
      employerPincode:[null],
      employerMobile:[null,[Validators.required]],
      employerDisgnation:[null,[Validators.required]],
      empPhoto:[null,Validators.required],
      panNo:[null],
      aadharNo:[null,Validators.required],
      vatNo:[''],
      gstNo:[''],
      Authorization:[null,[Validators.required]],
      uploadEstablishment:[null,[Validators.required]],
      checkDeclaration:[null,[Validators.required]]



    
    })
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    // this.maxDateforcompletion = {
    //   year: this.currentDate.getFullYear()+150,
    //   month: this.currentDate.getMonth() + 1,
    //   day: this.currentDate.getDate()
    // };
    // this.minDateforcompletion={
    //   year: this.currentDate.getFullYear(),
    //   month: this.currentDate.getMonth() + 1,
    //   day: this.currentDate.getDate()
    // }
    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {
    this.getcategoryEstablishment();
    this.getclassficationEstablishment();
    this.getNatureOfBusiness();
    this.getDistricts();
    this.getDesignations();
    this.getRelations();
    // var finalDate = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy');
    //    this.f['appDate'].patchValue(finalDate)
  }

  checkValidity(requisitionHeader:any, requisitionData:any):boolean {
    if(requisitionHeader?.requisitionType == 'LabourRegistration') {
      if(this.addDays(this.getExpiryDate(new Date(requisitionData?.applicationDate)), -31) < this.currentDate ) {
        return true;
      } else {
        this.commonService.commonErrorPopup('Alert', 'Registration is not expired.', 'md');
        return false;
      }
    } else if(requisitionHeader?.requisitionType == 'LabourRegistrationRenew') {
      if(requisitionData?.certificateValidity){
        let dt = moment(requisitionData?.certificateValidity,"DD/MM/YYYY").format("YYYY-MM-DDThh:mm:ss[Z]");
        if(this.addDays(dt, -31) < this.currentDate ) {
          return true;
        } else {
          this.commonService.commonErrorPopup('Alert', 'Registration is not expired.', 'md');
          return false;
        }
      }      
    }
    return false;
  }

  addDays(date:any, days:any) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getExpiryDate(dateOfIssue: Date): Date{
    let year;
    if(dateOfIssue.getMonth() > 2){
      year = this.currentDate.getFullYear() + 1;
    } else {
      year = this.currentDate.getFullYear();
    }
    return new Date(year,2,31);
  }

  onTypeChanged(type: string){
    this.f.prevApplicationNo.patchValue('');
    this.isFetchSubmitted = false;
    this.isInfoPrefilled = false;
    this.showForm = false;
  }

  getCertificateDetails() {
    this.isFetchSubmitted = true;
    if(!this.f.prevApplicationNo.value && this.f.prevApplicationNo.value.trim() == '') {
      return;
    }
    let requisitionData: any = this.f.prevApplicationNo.value;

    this.commonService
    .getRequest(this.commonConstants.GetRenewalValidationCheck + requisitionData)
    .subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess) {
          this.commonService
          .getRequest(this.commonConstants.getCertificateDetails +'LD&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ requisitionData)
          .subscribe({
            next: (responseData: any) => {
              if (responseData && responseData.length !== 0 &&  typeof(responseData.result) != 'string') {
                // if(this.checkValidity(responseData.result?.requisitionHeader, responseData.result?.requisitionData)){
                  this.basicInfo = responseData.result?.requisitionHeader;
                  this.certificateInfo = responseData.result?.requisitionData;
                  this.created = responseData.result?.created;
                  this.fileList = responseData.result?.fileList;    
                  this.isInfoPrefilled = true;   
                // } else {
                //   this.basicInfo = [];
                //   this.certificateInfo = [];
                //   this.fileList = []; 
                //   this.isInfoPrefilled = false;  
                // }     
              } else { 
                this.commonService.commonErrorPopup('Alert', responseData.result, 'md');          
                this.clearApplicationView();        
              }
            },
            error: (error) => {
              this.clearApplicationView();            
              console.log('client side', error);
            },
          });
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          this.clearApplicationView();
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while validating the application. Please try again.', 'md');
          this.clearApplicationView();
        }
      },
      error: (error) => {
        this.clearApplicationView();
        console.log('client side', error);
      },
    });
  }

  clearApplicationView(){
    this.basicInfo = [];
    this.certificateInfo = [];
    this.fileList = []; 
    this.isInfoPrefilled = false; 
    this.created = ''; 
  }

  viewFile(blobRef: any) {
    this.commonService
      .getRequest(this.commonConstants.viewFile + blobRef)
      .subscribe({
        next: (responseData: any) => {
          let fileInfo = responseData.result.fileContents;
           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
            modalRef.componentInstance.sourceFile = fileInfo; 
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  viewImgFile(blobRef: any) {
    this.commonService
      .getRequest(this.commonConstants.viewFile + blobRef)
      .subscribe({
        next: (responseData: any) => {
          let fileInfo = responseData.result.fileContents;
           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
           modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  downloadFile(file: any) {
    this.commonService
      .getRequest(this.commonConstants.fileDownload + file.blobRef)
      .subscribe({
        next: (responseData: any) => {
          this.commonService.downloadPdfFile(
            responseData.result.fileContents,
            file.fileName
          );
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  get f() {
    return this.registartionForm.controls;
  }
  goToNextTab() {
    this.commonFormSubmitted = true;
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    }
  }
  getcategoryEstablishment() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'LABOUR_CAT_ESTD').subscribe({
      next: (responseData: any) => { 
      this.categoryEstablishment = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getclassficationEstablishment(){
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'LABOUR_CLASSIFICATION_OF_ESTD').subscribe({
      next: (responseData: any) => { 
      this.classificationEstablishment = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  
  getNatureOfBusiness(){
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'LABOUR_NATUREOFBUSSINESS').subscribe({
      next: (responseData: any) => { 
      this.natureOFBusiness = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  // getDistricts(){
  //   this.commonService.getRequest(this.commonConstants.getByEntityType + 'LABOUR_DISTRICTS').subscribe({
  //     next: (responseData: any) => { 
  //     this.districts = this.commonService.sortData(responseData.result);
  //     console.log(this.districts + 'districts')
  //     },
  //     error: (error) => {
  //     console.log('client side', error);
  //     }
  // });
  // }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => { 
        this.districts = this.commonService.sortData(responseData.result);
        console.log(JSON.stringify(this.districts) + 'districts')
       // this.commonService.setMasterData('districts', this.districts);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onDistrictChange(from: string, name: string, event?: any) {
    this.commonService.getRequest(this.commonConstants.getMandals + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
        if (from === 'establishment') {
          this.mandals = this.commonService.sortData(responseData.result);
          console.log(JSON.stringify(this.mandals) + 'mandals')
          
        } else if (from === 'employer') {
          this.empMandals = this.commonService.sortData(responseData.result);
          console.log(JSON.stringify(this.empMandals) + 'empMandals')
          
         
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onMandalChange(from: string, name: string, event?: any) {
    this.commonService.getRequest(this.commonConstants.getVillages + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
        if (from === 'establishment') {
          this.villages = this.commonService.sortData(responseData.result);
          console.log(JSON.stringify(this.villages) + 'villages')
          
        } else if (from === 'employer') {
          this.empVillages = this.commonService.sortData(responseData.result);
          console.log(JSON.stringify(this.empVillages) + 'empVillages')
          
         
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  // onDistrictChange(value:any){
  //  // this.districts = [];
  //   console.log(value + 'value')
  //   let district:any
  //   district=this.districts.filter((c:any)=> (c.entityCode === value));
    
  //   console.log(district + 'district')
  //  let id:any;
  //  id=district[0].id;
  //     this.commonService.getRequest(this.commonConstants.getByParentId + 'LABOUR_MANDAL&ParentId='+ id +'&ParentType=LABOUR_DISTRICTS&LanguageCode=EN-IN').subscribe({
  //       next: (responseData: any) => { 
  //       this.mandals = this.commonService.sortData(responseData.result);
  //       },
  //       error: (error) => {
  //       console.log('client side', error);
  //       }
  //   });
   
  // }
  // onMandalChange(value:any){
  //    console.log(value + 'mandal')
  //   let mandal:any
  //   mandal=this.mandals.filter((c:any)=> (c.entityCode === value));
    
  //   console.log(JSON.stringify(mandal) + 'mandal')
  //  let id:any;
  //  id=mandal[0].id;
    
  //   this.commonService.getRequest(this.commonConstants.getByParentId + 'LABOUR_VILLAGE&ParentId='+ id +'&ParentType=LABOUR_MANDAL&LanguageCode=EN-IN').subscribe({
  //     next: (responseData: any) => { 
  //     this.villages = this.commonService.sortData(responseData.result);
  //     },
  //     error: (error) => {
  //     console.log('client side', error);
  //     }
  // });
  // }
  getDesignations(){
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'LABOUR_DESIGNATION').subscribe({
      next: (responseData: any) => { 
      this.designations = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getRelations(){
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'LABOUR_RELATIONSHIP').subscribe({
      next: (responseData: any) => { 
      this.relations = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }

  onChangeOfState(){
  if(this.f.employerState.value === 'Other State'){
    this.isOtherAddress=true;
    this.isAPAddress=false;
    this.registartionForm.controls['otherAddress'].setValidators([
    Validators.required]);
    this.registartionForm.controls['otherAddress'].updateValueAndValidity();
    
    this.registartionForm.controls['employerDistrict'].clearValidators();
    this.registartionForm.controls['employerDistrict'].updateValueAndValidity();
    this.registartionForm.controls['employerDistrict'].reset();

    this.registartionForm.controls['employerMandal'].clearValidators();
    this.registartionForm.controls['employerMandal'].updateValueAndValidity();
    this.registartionForm.controls['employerMandal'].reset();

    this.registartionForm.controls['employerVillage'].clearValidators();
    this.registartionForm.controls['employerVillage'].updateValueAndValidity();
    this.registartionForm.controls['employerVillage'].reset();
  } 
  else if (this.f.employerState.value === 'Andhra Pradesh'){
    this.isAPAddress=true;
    this.isOtherAddress=false;
    this.registartionForm.controls['employerDistrict'].setValidators([
    Validators.required]);
    this.registartionForm.controls['employerDistrict'].updateValueAndValidity();
    
    this.registartionForm.controls['employerMandal'].setValidators([
    Validators.required]);
    this.registartionForm.controls['employerMandal'].updateValueAndValidity();
    
    this.registartionForm.controls['employerVillage'].setValidators([
    Validators.required]);
    this.registartionForm.controls['employerVillage'].updateValueAndValidity();

    this.registartionForm.controls['otherAddress'].clearValidators();
    this.registartionForm.controls['otherAddress'].updateValueAndValidity();
    this.registartionForm.controls['otherAddress'].reset();
  }
    /*else{
          this.isOtherAddress=false;
          this.isAPAddress=false;

          this.registartionForm.controls['otherAddress'].clearValidators();
          this.registartionForm.controls['otherAddress'].updateValueAndValidity();

          this.registartionForm.controls['employerDistrict'].clearValidators();
          this.registartionForm.controls['employerDistrict'].updateValueAndValidity();

          this.registartionForm.controls['employerMandal'].clearValidators();
          this.registartionForm.controls['employerMandal'].updateValueAndValidity();

          this.registartionForm.controls['employerVillage'].clearValidators();
          this.registartionForm.controls['employerVillage'].updateValueAndValidity();
        }*/
     }
  keyPressAlphaNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/^[a-zA-Z0-9\-_.,;:()'"!@#$%^&*+=<>?/\\[\]{}|~` ]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  keyPressAlphaNumericOnly(event: any) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressAlpha(event: any){
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-z A-Z.]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onChange(content:any){
    if(this.f.isEstablishment.value === 'Yes') {
      this.showForm=false;
      this.modalService
      .open(content, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'app-modal-window',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

      
    } else {
      this.showForm=true;
    }

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onChangeRegistered(){
    if(this.f.isRegistered.value === 'Yes') {
      this.isShowStartUpId=true;
      this.registartionForm.controls['StartUpId'].setValidators([
        Validators.required 
      ]);
            this.registartionForm.controls['StartUpId'].updateValueAndValidity();
      

    }else{
      this.isShowStartUpId=false;
      this.registartionForm.controls['StartUpId'].clearValidators();
            this.registartionForm.controls['StartUpId'].updateValueAndValidity();
    }
  }
  on1988Selected(){

if(this.f.apShop.value === true){
  this.showWorkers= true;
} else{
  this.showWorkers= false;
}   
  }

  onWorkersChange(){
    let totalSum=0;
    totalSum =totalSum + Number(this.f.directFemale.value!= null ? this.f.directFemale.value : 0) + Number(this.f.directMale.value != null ? this.f.directMale.value : 0 )+
    + Number(this.f.casualFemale.value!= null ? this.f.casualFemale.value : 0) + Number(this.f.casualMale.value != null ? this.f.casualMale.value : 0 )+
    Number(this.f.contractFemale.value!= null ? this.f.contractFemale.value : 0) + Number(this.f.contractMale.value != null ? this.f.contractMale.value : 0 )

    console.log(totalSum + 'totalSum')
    
    this.f.totalWorkers.patchValue(totalSum);
  }
  submit(){
    
    console.log(JSON.stringify(this.f.dateOfCommencement.value)+ 'commencement')
    console.log(JSON.stringify(this.f.dateOfCompletion.value) + 'dateOfCompletion')
    console.log(this.registartionForm.value + 'registrationForm')
    console.log(this.f)
    console.log('Total workers are - ',this.f.totalWorkers.value)
    let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
    //this.submitted=true
    if(!this.registartionForm.valid && this.f.isFreshRenew.value == 'fresh'){
      
      this.submitted=true
      console.log('submitted',this.submitted)
    } else {
      this.submitted=false
      console.log('submitted',this.submitted)
    let establishmentDistrict = this.districts.filter((c:any)=> (c.name === this.f.district.value));
    let establishmentMandal=this.mandals.filter((c:any)=> (c.name === this.f.mandal.value));
    let establishmentVillage =this.villages.filter((c:any)=> (c.name === this.f.village.value));
    let empDistricts=this.districts.filter((c:any)=> (c.name === this.f.employerDistrict.value));
    let empMandals=this.empMandals.filter((c:any)=> (c.name === this.f.employerMandal.value));
    let empVillage =this.empVillages.filter((c:any)=> (c.name === this.f.employerVillage.value));
    this.postData =
    {
      requisitionHeader: {
          requisitionType: (this.f.isFreshRenew.value == 'fresh' ? 'LabourRegistration' : 'LabourRegistrationRenew'),
          requisitionPKey: '',
          department: 'LD',
          applicantInfo: {
          id:'',
          aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo'].trim(),
          personNames: [
        {
          languageCode: "en-in",
          firstName: this.f['commonForm'].value['firstName'],
          middleName: this.f['commonForm'].value['middleName'],
          lastName: this.f['commonForm'].value['lastName'],
          fatherHusbandName: this.f['commonForm'].value['fatherHusName'],
        }
            ],
            gender:this.f['commonForm'].value['gender'],
            birthDate: dateFormat,
            caste: this.f['commonForm'].value['caste'].name,
            religion: this.f['commonForm'].value['religion'].name,
            qualification: this.f['commonForm'].value['qualification'].name,
            maritalStatus: this.f['commonForm'].value['maritalStatus'],
            addresses: [
              {
                   addressType: "present",
                   doorNo: this.f['commonForm'].value['preDoorNo'],
                   streetName: this.f['commonForm'].value['preStreet'],
                   villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                   mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                   district: this.f['commonForm'].value['preDistrict'].entityCode,
                   pinCode: this.f['commonForm'].value['prePinCode'],
                   isRevenueLocation: true,
                   postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                   postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                   postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                   postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
              
              },
             {
                   addressType:"permanent",
                   doorNo: this.f['commonForm'].value['permDoorNo'],
                   streetName:this.f['commonForm'].value['permStreet'],
                   villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                   mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                   district: this.f['commonForm'].value['permDistrict'].entityCode,
                   pinCode: this.f['commonForm'].value['permPinCode'],
                   isRevenueLocation: true,
                   postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                   postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                   postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                   postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
      },
            ],
              rationCardNo: '',
              deliveryType:  this.f['commonForm'].value['deliveryType'],
              emailAddress: this.f['commonForm'].value['email'],
              mobileNumber: this.f['commonForm'].value['mobileNo'],
              isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
              isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
          },
          remarks:''
        }     
    }

    if(this.f.isFreshRenew?.value == 'fresh') {
      this.postData.requisitionData =  {
        applicationDate:this.currentDate,
        isEstablishmentAnIndustry: this.f.isEstablishment.value === 'Yes' ? true : false,
        registeredWithAPInnovationSociety: this.f.isRegistered.value === 'Yes' ? true :false,
       // isRegistered:this.f.isRegistered.value,
        //aPISStartup_id:this.f.StartUpId.value,
        isRegistered:this.f.StartUpId.value,
        relationShip:this.f.relationShip.value,
        workerDetails: {
          directWorkers: {
            male: this.f.directMale.value,
              female:this.f.directFemale.value
          },
          contractWorkers: {
            male: this.f.contractMale.value,
            female:this.f.contractFemale.value
          },
          dailyWageWorkers: {
            male: this.f.casualMale.value,
            female:this.f.casualFemale.value
          },
          noOfWorker: this.f.totalWorkers.value.toString(),
          //validity:''
      },
        serviceDetails: {
            areaOfService: this.f.areaOfService.value,
            //applicationNumber:'' ,
            natureOfBusiness: this.f.natureOfBusiness.value
        },
        registrationRequired: {
            isAct1988: this.f.apShop.value != null ? this.f.apShop.value:false,
            // isAct1961: this.f.motorTransport.value != null ?this.f.motorTransport.value : false,
            // isAct1970_PrincipalEmployer: this.f.contractLabour.value != null ? this.f.contractLabour.value :false,
            // isAct1979_PrincipalEmployer: this.f.InterStateMigrantWorkmen.value != null ? this.f.InterStateMigrantWorkmen.value :false,
            // isAct1996: this.f.buildingAndOtherConstructionWorker.value != null ? this.f.buildingAndOtherConstructionWorker.value: false ,
            // isAct1972: this.f.paymentOfGatuity.value != null ? this.f.paymentOfGatuity.value : false,
            // isAct1966: this.f.beediAndCigerWorkers.value !=null ? this.f.beediAndCigerWorkers.value : false,
            // isAct1970_LicenseofContractorEstablishment: this.f.contractLabourEstablishment.value != null ? this.f.contractLabourEstablishment.value :false,
            // isAction1979_LicenseofContractorEstablishment: this.f.interStateMigrantWorkmen.value != null ? this.f.interStateMigrantWorkmen.value :false
        },
        establishmentDetails: {
          name: this.f.nameOfShop.value,
          classification: this.f.classificationOfEstablishment.value,
          category: this.f.categoryofEstablishment.value,
          address:{
            addressType:'Present',
            doorNo: this.f.StreetOrDoorNo.value,
            locality: this.f.locality.value,
            districtName: this.f.district.value.name,
            district:this.f.district.value.entityCode,
            mandalMunicipality :this.f.mandal.value.entityCode,
            mandalMunicipalityName:this.f.mandal.value.name,
            villageWardName: this.f.village.value.name,
            villageWard: this.f.village.value.entityCode,
            pinCode: this.f.pincode.value,
          },
         
          mobileNo: this.f.mobile.value,
          email: this.f.email.value,
          ownerShipType: this.f.companyOrPropritor.value,
          dateOfCommencement:this.f.dateOfCommencement.value !== null? this.commonService.formatDate(this.f.dateOfCommencement.value):'',
          dateOfCompletion:this.f.dateOfCompletion.value  !== null ? this.commonService.formatDate(this.f.dateOfCompletion.value):null,
        },
        employerDetails: {
          state:this.f.employerState.value,
          otherStateAddress:this.f.otherAddress.value !== null ? this.f.otherAddress.value : '',
          name: this.f.emplyersName.value,
          fatherHusbandName: this.f.fatherOrHusbandName.value,
          aadharNumber: this.commonService.RSAEncrypt(this.f.employersAadhar.value),
          address:{
            addressType: "Present",
            doorNo: "",
            streetName: "",
            districtName: this.f.employerDistrict.value !== null ? this.f.employerDistrict.value.name : '',
            district: this.f.employerDistrict.value !== null ? this.f.employerDistrict.value.entityCode : '',
            villageWardName:this.f.employerVillage.value !== null ? this.f.employerVillage.value.name : '',
            villageWard:this.f.employerVillage.value !== null ? this.f.employerVillage.value.entityCode : '',
            mandalMunicipalityName:this.f.employerMandal.value !== null ? this.f.employerMandal.value.name : '',
            mandalMunicipality:this.f.employerMandal.value !== null ? this.f.employerMandal.value.entityCode : '',
            pinCode: this.f.employerPincode.value,
          },
          // districtName: this.f.employerDistrict.value,
          // districtCode: this.f.employerDistrict.value,
          // mandalName: this.f.employerMandal.value,
          // mandalCode: this.f.employerMandal.value,
          // villageName: this.f.employerVillage.value,
          // villageCode: this.f.employerVillage.value,
          //pinCode: this.f.employerPincode.value,
          mobileNo: this.f.employerMobile.value,
          designation: this.f.employerDisgnation.value
        }
      }
    } else {
      this.postData.requisitionData = { ...this.certificateInfo } ;
      this.postData.requisitionData.employerDetails.aadharNumber = this.commonService.RSAEncrypt(this.postData.requisitionData.employerDetails.aadharNumber),
      this.postData.requisitionData.oldApplicationNumber = this.f.prevApplicationNo.value;
      this.postData.requisitionData.applicationDate = this.currentDate;
      this.postData.requisitionData.OldApplicationCreatedDate = this.created;
    }

    let postAPI = '';
    if(this.f.isFreshRenew?.value == 'fresh') {
      postAPI = this.commonConstants.IntegratedRegistrationOfEstablishment;
    } else {
      postAPI = this.commonConstants.RenewalIntegratedRegistrationOfEstablishment;
    }

  console.log('my post data',this.postData)
    this.commonService.postRequest(postAPI, this.postData).subscribe({
      next: (responseData:any) => { 
        console.log(responseData)
        this.reqId=responseData.result.id;
        if(this.reqId){
          this.genearateRegistrationNumber();
        }
       // this.updateFileUpload(responseData.result.id);
      }})
    }
  }
  
  genearateRegistrationNumber(){
    this.commonService.getRequest(this.commonConstants.GenerateRegistrationNumberForEstablishment+ "?Requisitionid=" +this.reqId).subscribe({
      next: (responseData:any) => { 
        console.log(responseData)
        if(responseData.result.isSuccess == true){
          let registerId=responseData.result?.registrationId;
         if(registerId !== null && registerId?.trim() !== '' && registerId != undefined ){
          this.updateFileUpload(this.reqId);
         }else{
          this.commonService.commonErrorPopup('Alert', 'Registration Number is not available,Please try again !!!', 'md');
         }
          
        }
       
      }})
  }
  clearFileUpload(name: string, label: string) {
    
      this.f[name]?.reset();
      this.f[name]?.clearValidators();
      this.f[name]?.updateValueAndValidity();
      if(label === 'empPhotoLabel') {
        this.empPhotoLabel.nativeElement.innerText = 'No File Choosen';
       
      } else if(label === 'panNoLabel') {
        this.panNoLabel.nativeElement.innerText = 'No File Choosen';
      } else if (label === 'aadharNoLabel') {
        this.aadharNoLabel.nativeElement.innerText = 'No File Choosen';
      } else if (label === 'vatNoLabel') {
        this.vatNoLabel.nativeElement.innerText = 'No File Choosen';
      } else if (label === 'gstNoLabel') {
        this.gstNoLabel.nativeElement.innerText = 'No File Choosen';
      }   else if (label === 'AuthorizationLabel') {
        this.AuthorizationLabel.nativeElement.innerText = 'No File Choosen';
      } else if (label === 'uploadEstablishmentLabel') {
        this.uploadEstablishmentLabel.nativeElement.innerText = 'No File Choosen';
      } 
    }
  
    onFileSelected(fileEvent: any, controlName: any, labelName:any) {
      
      if(labelName === 'empPhotoLabel') {
        const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
        if (fileExtensionType.toLowerCase() === 'jpg') {
          this.validExt = 'jpg';
        } else if (fileExtensionType.toLowerCase() === 'jpeg') {
          this.validExt = 'jpeg';
        } else{
          this.validExt = 'png';
        }
        this.empPhotoLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
        this.registartionForm.controls['empPhoto']?.setValidators([
          fileExtensionValidator(this.validExt),
          fileSizeValidator(fileEvent),
        ]);
        this.registartionForm.controls['empPhoto']?.updateValueAndValidity();
      } else if(labelName === 'panNoLabel') {
        this.panNoLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
        this.registartionForm.controls['panNo']?.setValidators([
          fileExtensionValidator('pdf'),
          fileSizeValidator(fileEvent),
        ]);
        this.registartionForm.controls['panNo']?.updateValueAndValidity();
      } else if (labelName === 'aadharNoLabel') {
        this.aadharNoLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
        this.registartionForm.controls['aadharNo']?.setValidators([
          fileExtensionValidator('pdf'),
          fileSizeValidator(fileEvent),
        ]);
        this.registartionForm.controls['aadharNo']?.updateValueAndValidity();
      } else if (labelName === 'vatNoLabel') {
        this.vatNoLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
        this.registartionForm.controls['vatNo']?.setValidators([
          fileExtensionValidator('pdf'),
          fileSizeValidator(fileEvent),
        ]);
        this.registartionForm.controls['vatNo']?.updateValueAndValidity();
      } else if (labelName === 'gstNoLabel') {
        this.gstNoLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
        this.registartionForm.controls['gstNo']?.setValidators([
          fileExtensionValidator('pdf'),
          fileSizeValidator(fileEvent),
        ]);
        this.registartionForm.controls['gstNo']?.updateValueAndValidity();
      }   else if (labelName === 'AuthorizationLabel') {
        this.AuthorizationLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
        this.registartionForm.controls['Authorization']?.setValidators([
          fileExtensionValidator('pdf'),
          fileSizeValidator(fileEvent),
        ]);
        this.registartionForm.controls['Authorization']?.updateValueAndValidity();
      } else if (labelName === 'uploadEstablishmentLabel') {
        this.uploadEstablishmentLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
        this.registartionForm.controls['uploadEstablishment']?.setValidators([
          fileExtensionValidator('pdf'),
          fileSizeValidator(fileEvent),
        ]);
        this.registartionForm.controls['uploadEstablishment']?.updateValueAndValidity();
      } 
    
    
      const file: File | null = fileEvent.target.files[0];
      console.log('fileEvent', fileEvent);
      let name=controlName;
      let fieldcontrol: any = this.f.controlName;
      
      console.log(fieldcontrol + 'fieldcontrol')
    
     
      if (fieldcontrol?.valid)
      console.log(fieldcontrol?.value,fileEvent.target.files[0].name, controlName)
        this.fileUpload(file, fieldcontrol?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
  }
  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    console.log(formData, 'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
        next: (responseData: any) => {
          if(typeof(responseData.result) !== 'string'){
            let index = this.fileList.map((item) => item.DocumentType).indexOf(documentType);
            if (index > -1) {
              this.fileList.splice(index, 1);
            }
            this.fileList.push({
              FileName: fileName,
              DocumentType: documentType,
              BlobRef: responseData['result'].blobRef,
            });
            console.log(this.fileList)
          } else{
            if(name && label) {
              this.clearFileUpload(name, label);
            }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          }
          console.log(this.fileList, 'FileList');
        },
        error: (error) => {
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          console.log('client side', error);
        },
      });
  }

 
  updateFileUpload(id: any) {
    let fileData = {
      FileList: this.fileList,
      RequisitionId: id,
      RequisitionType : "LabourRegistration"
    }
    
    this.commonService.postRequest(this.commonConstants.updateFileUpload,fileData).subscribe({
      next: (resData:any) => { 
        console.log(resData,'File Upload  Respose data');
        this.straightThruGenerateCertificateData();
       // this.showPayment(id);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  straightThruGenerateCertificateData(){
    this.certificateTypeName='';
    this.commonConstants.certificateList.forEach((e: any) => {
      if('LabourRegistration' == e.certName || 'LabourRegistrationRenew' == e.certName)
      {
        // console.log('e cert name ',e.certID)
        this.certificateTypeName = e.certID
      }
    });
  
    var sendPostData={
      RequisitionId: this.reqId,
      Department: 'LD',
      CertificateType : 'LD'
    }
    console.log(sendPostData,'post data')
    this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
      next: (responseData:any) => { 
        if (responseData?.result?.isSuccess) {
            this.straightThruGenerateCertificate()
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  straightThruGenerateCertificate() {
    this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + this.reqId + '&Department=' + 'LD'+'&Mode=1').subscribe({
      next: (responseData: any) => {
        if (responseData?.result?.isSuccess) {
          this.showPayment(this.reqId);
        }  else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
  }
  showPayment(requisionId: any) {
    // this.router.navigate(['/payment-gateway']);
     let type:any;
     type=(this.f.isFreshRenew.value == 'fresh' ? 'LabourRegistration' : 'LabourRegistrationRenew')
     const queryParams: Params = {
       id: requisionId,
       department: 'LD',
       type: type,
     };
     this.router.navigate(['/payment-gateway'], {
       relativeTo: this.activatedRoute,
       queryParams: queryParams,
       queryParamsHandling: 'merge',
     });
   }
}
