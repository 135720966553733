<div class="modal-header" >
    <h6 class="modal-title">Know your Suraksha Camp Date</h6>
    <button type="button" class="close" aria-label="Close"  (click)="activeModal.dismiss('Cross click')">
      <span >&times;</span>
    </button>
</div>
   

<div class="modal-body">
        <!-- <h6 class="mb-3 mt-15"><b>Check Jagananna Suraksha Team Schedule Search</b></h6> -->
        <div  [formGroup]="form">
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                   <label class="subHeadingCls">Aadhaar No:</label></div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                           <input type="text" class="inputCls value" maxlength="12" numbersOnly
                           name="aadharNo" id="aadharNo" formControlName="aadharNo" (keypress) = "hideResult()"
                           [ngClass]="{ 'is-invalid':submitted && f.aadharNo.errors }"> 
                              <div *ngIf="submitted && f.aadharNo.errors" class="invalid-feedback">
                                <div *ngIf="f.aadharNo.errors.whiteSpaceValidate || f.aadharNo.errors?.aadharValidate || f.aadharNo.errors?.minlength">Please enter valid  Aadhaar Number</div>
                              </div> 
                        </div>
            </div>
            <div class="text-center"> 
                OR
            </div>
           
<br>
            <div class="row  mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                   <label class="subHeadingCls">Secretariat Code</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
                  <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
                  [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                  <option [ngValue]= "null" disabled>Select</option>
                  <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
                  </select>
                 
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
                    [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
                    <option [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
                  </select>
                
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" formControlName="village"  (change)="hideResult()"
                  >
                  <option [ngValue]= "null" disabled>Select</option>
                  <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
                  </select>
                  
                  </div>
             

            </div>


    </div>

    <div class="row mb-3 mt-4">
        <div class="btnHolder text-center col-12">
           <!-- <button class="btn btn-primary mx-auto d-block" (click)="submit()">Check Status</button> -->
           <button class="btn btn-primary mx-auto d-block" (click)="getCitizenInfo()">Check Status</button>

        </div>
    </div>
   
    <div class="mr-3" *ngIf="showReports">
        <table class="tableCls">
            <thead  *ngIf="reqData.isSuccess===true">
                <tr>
                <th>Secretariat Name</th>
                <!-- <th>Secretariat Code</th> -->
                <th>Schedule Date</th>
                <th>Venue Details</th>
                </tr>
            </thead>
                <tbody *ngIf="reqData.isSuccess===true">
                        <tr>
                        <td><ng-container>{{reqData?.secretariatName }} {{reqData?.secretariatCode? '(' + reqData?.secretariatCode + ')' : ''}}  </ng-container> </td>
                        <!-- <td>{{reqData.secretariatName ? reqData.secretariatName:'-'}}</td> -->
                        <!-- <td>{{reqData.secretariatCode ? reqData.secretariatCode:'-'}}</td>   -->
                        <td>{{reqData.scheduleDate | date: 'dd/MMM/yyyy'}}</td> 
                        <td>{{reqData.venueDetails ? reqData.venueDetails:'-'}}</td> 
                         </tr>
            </tbody>
            <tbody *ngIf="reqData.isSuccess===false">
                 
                <p style="text-align: center; color:red"><strong>No records found.</strong></p>
            </tbody>
        </table>
    </div>
   <!-- </ng-container> -->
    </div> 

