<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Revenue Mandal: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="revenueMandal" (change)="getMandals()"
        [ngClass]="{ 'is-invalid':submitted && (f.revenueMandal.errors || f.revenueMandal.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let revenue of revenueMandals" [ngValue]="revenue">{{revenue.revenuE_MANDAL_NAME}}</option>
      </select>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
        <option value = "All">All</option>
        <option value = "R">Rural</option>
        <option value = "U">Urban</option>
        </select>
    </div>

    
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
    </select>
   </div>
    </div>
    <div class="row mb-3">
  
   
   <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="village" (change)="onVillageChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
      </select>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Service: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="service" (change) = "onServiceChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.service.errors || f.service.value === 'Select') }">
          <option value="All">All</option>
          <option value="AgricultureIncomeCertificate">Agriculture Income Certificate</option>
          <option value="APDottedLandsApplication">AP Dotted Lands Application</option>
          <option value="COMPUTERIZEDADANGAL">COMPUTERIZED ADANGAL</option>
          <option value="ExtractofHousesite/D-FormPattaApplication">Extract of House site / D-Form Patta Application</option>
          <option value="FamilyMemberCertificate">Family Member Certificate</option>
          <option value="IncomeCertificate">Income Certificate</option>
          <option value="IntegratedCertificate">Integrated Certificate</option>
          <option value="IssuanceOfIncome&AssetCertificateForEconomicallyWeakerSections(EWS)">Issuance Of Income & Asset Certificate For Economically Weaker Sections (EWS)</option>
          <option value="IssuanceOfSmallAndMarginalFarmerCertificate">Issuance Of Small And Marginal Farmer Certificate</option>
          <option value="MutationbyCorrections&AutomaticPattadarPassbookOrdering">Mutation by Corrections & Automatic Pattadar Passbook Ordering</option>
          <option value="MutationbyTransactions-LPNumber(s)">Mutation by Transactions - LP Number(s)</option>
          <option value="MutationforCorrections">Mutation for Corrections</option>
          <option value="MutationforTransactions">Mutation for Transactions</option>
          <option value="OBCCertificate">OBC Certificate</option>
          <option value="PattadarAadharSeedingbyLPnumber">Pattadar Aadhar Seeding by LP number</option>
          <option value="PossessionCertificate">Possession Certificate</option>
          <option value="PrintingOfTitleDeedCumPassbook">Printing Of TitleDeed Cum Passbook</option>
          <option value="ROR1BCertificate">ROR1B Certificate</option>
          <option value="WaterTaxService">Water Tax Service</option>
          <option value="NoPropertyApplicationService">No Property Application Service</option>
          <option value="IssueofOccupancyRightsCertificatesforInamLands">Issue of Occupancy Rights Certificates for Inam Lands</option>
          <option value="ChangeofNameApplication">Change of Name Application</option>
          <option value="ManualAdangalCertificate">Manual Adangal Certificate</option>
          <option value="NoEarningMemberCertificate">NoEarning Member Certificate</option>
          <option value="DuplicateofOBC/EBC/IntegratedCertificates">Duplicate of OBC/EBC/Integrated Certificates</option>
          <option value="TitleDeedCumPassbook">TitleDeed Cum Passbook</option>

          <option value="AGRICULTURALLANDVALUEAPPLICATION-Meeseva">AGRICULTURAL LAND VALUE APPLICATION-Meeseva</option>
          <option value="COMPUTERIZEDADANGAL-Meeseva">COMPUTERIZED ADANGAL-Meeseva</option>
          <option value="E-PASSBOOKSERVICE-Meeseva">E-PASSBOOKSERVICE-Meeseva</option>
          <option value="MANUALADANGAL-Meeseva">MANUAL ADANGAL-Meeseva</option>
          <option value="MobileNumber&PattadarAadhaarSeeding-Meeseva">Mobile Number  & Pattadar Aadhaar Seeding-Meeseva</option>
          <option value="MODIFICATIONSIN22ALIST-Meeseva">MODIFICATIONS IN 22 A LIST-Meeseva</option>
          <option value="OnlineSubdivision-Meeseva">Online Subdivision-Meeseva</option>
        </select>
       
      </div>

     
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="fromDate"
                 [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.fromDate.errors" class="selectError">
          <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
      </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
          <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
      </div>
      </div>
</div>

  <div class="tabClass">
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)=" GetJaganannaSurakshaReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Revenue Sadassulu Service Dashboard From {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
            <tr>
               <th rowspan="3">S.No</th>
               <th rowspan="3" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dname'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION_NAME'">Revenue Division <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL_NAME'">Revenue Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mname'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <!-- <th  rowspan="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_TOKENS'">No. of Tokens registered <mdb-icon fas icon="sort"></mdb-icon></th> -->
               <th rowspan="3" *ngIf="format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'servicename'">Service Name <mdb-icon fas icon="sort"></mdb-icon></th>

               <th  rowspan="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'toT_REQ'">No. of service requests raised <mdb-icon fas icon="sort"></mdb-icon></th>
               <th colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Open</th>
               <th colspan="4" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Closed</th>
              </tr>
            <tr>
                <th rowspan="2" class="openBeyond" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opN_BYND_SLA_REQ'">Beyond SLA <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" class="openWithin" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opN_WTH_SLA_REQ'">Within SLA <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="2"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Beyond SLA<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  colspan="2"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Within SLA <mdb-icon fas icon="sort"></mdb-icon></th>
               </tr>
               <tr>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_BYND_SLA_APRVD_REQ'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_BYND_SLA_REJ_REQ'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_WTH_SLA_APRVD_REQ'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_WTH_SLA_REJ_REQ'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
           </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'RevenueMandal Wise' ">{{item.dname}}</td>
                <td  *ngIf="format === 'RevenueMandal Wise'">{{item.divname}}</td>
               <td  *ngIf="format === 'RevenueMandal Wise'">{{item.revenuemandal}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mname}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.seC_NAME}}</td>
               <td *ngIf="format === 'Service Wise'">{{item.servicename}}</td>
               <!-- <td class="text-right">{{item.nO_OF_TOKENS}}</td> -->
                <td class="text-right">{{item.toT_REQ}}</td>
                <td class="openBeyond text-right linkCls"> <a (click) ="getBeyondSLA(item, beyondSLAModal, 'beyond')" class="linkCls">{{item.opN_BYND_SLA_REQ}} </a></td>
                <td class="openWithin text-right"> <a (click) ="getBeyondSLA(item, beyondSLAModal, 'within')" class="linkCls">{{item.opN_WTH_SLA_REQ}} </a></td>
                <!-- <td class="text-right">{{item.}}</td>
                <td class="text-right">{{item.}}</td> -->
                <td class="text-right">{{item.clsD_BYND_SLA_APRVD_REQ}}</td>
                <td class="text-right">{{item.clsD_BYND_SLA_REJ_REQ}}</td>
                <td class="text-right">{{item.clsD_WTH_SLA_APRVD_REQ}}</td>
                <td class="text-right">{{item.clsD_WTH_SLA_REJ_REQ}}</td>
              <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">{{totalItems[0].dname}}</td>
                <td  *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].divname}}</td>
               <td  *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].revenuemandal}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mname}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].seC_NAME}}</td>
                <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>
                <!-- <td class="text-right">{{totalItems[0].nO_OF_TOKENS}}</td> -->
                <td class="text-right">{{totalItems[0].toT_REQ}}</td>
                <td class="text-right">{{totalItems[0].opN_BYND_SLA_REQ}}</td>
                <td class="text-right">{{totalItems[0].opN_WTH_SLA_REQ}}</td>
            <!-- <td class="text-right">{{totalItems[0].}}</td>
            <td class="text-right">{{totalItems[0].}}</td> -->
            <td class="text-right">{{totalItems[0].clsD_BYND_SLA_APRVD_REQ}}</td>
            <td class="text-right">{{totalItems[0].clsD_BYND_SLA_REJ_REQ}}</td>
            <td class="text-right">{{totalItems[0].clsD_WTH_SLA_APRVD_REQ}}</td>
            <td class="text-right">{{totalItems[0].clsD_WTH_SLA_REJ_REQ}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
           
                <th [attr.colspan]="noOfCol">Revenue Sadassulu Service Dashboard From {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
           <tr>
               <th rowspan="3" width="100px">S.No</th>
               <th width="100px" rowspan="3" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">District Name</th>
               <th width="100px" rowspan="3" *ngIf="format === 'RevenueMandal Wise'">Revenue Division </th>
               <th width="100px" rowspan="3" *ngIf="format === 'RevenueMandal Wise'">Revenue Mandal </th>
               <th rowspan="3" width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name</th>
               <th width="100px" rowspan="3" *ngIf="format === 'Secretariat Wise'">Secretariat Name</th>
               <th width="100px" rowspan="3" *ngIf="format === 'Service Wise'">Service Name</th>

               <!-- <th width="100px" rowspan="3">No. of Tokens registered</th> -->
               <th width="100px" rowspan="3">No. of service requests raised</th>
               <th width="100px" colspan="2">Open</th>
               <th width="100px" colspan="4">Closed</th>
              </tr>
               <tr>
                <th rowspan="2" width="100px">Beyond SLA</th>
                <th rowspan="2" width="100px">Within SLA</th>
                <th colspan="2" width="100px">Beyond SLA</th>
                <th colspan="2" width="100px">Within SLA</th>
                </tr>
                <tr>
                  <th width="100px">Approved</th>
                  <th width="100px">Rejected</th>
                  <th width="100px">Approved</th>
                  <th width="100px">Rejected</th>
                  </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">{{item.dname}}</td>
               <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.divname}}</td>
               <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.revenuemandal}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mname}}</td>
               <td width="100px" *ngIf="format === 'Secretariat Wise'">{{item.seC_NAME}}</td>
                <!-- <td width="100px">{{item.nO_OF_TOKENS}}</td> -->
               <td width="100px" *ngIf="format === 'Service Wise'">{{item.servicename}}</td>

               <td width="100px">{{item.toT_REQ}}</td>
               <td width="100px">{{item.opN_BYND_SLA_REQ}}</td>
               <td width="100px">{{item.opN_WTH_SLA_REQ}}</td>
                <!-- <td width="100px">{{item.}}</td>
                <td width="100px">{{item.}}</td> -->
                <td width="100px">{{item.clsD_BYND_SLA_APRVD_REQ}}</td>
                <td width="100px">{{item.clsD_BYND_SLA_REJ_REQ}}</td>
                <td width="100px">{{item.clsD_WTH_SLA_APRVD_REQ}}</td>
                <td width="100px">{{item.clsD_WTH_SLA_REJ_REQ}}</td>
                </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'RevenueMandal Wise'">{{totalItems[0].dname}}</td>
            <td *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].divname}}</td>
            <td *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].revenuemandal}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mname}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].seC_NAME}}</td>
            <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>

           <!-- <td >{{totalItems[0].nO_OF_TOKENS}}</td> -->
           <td >{{totalItems[0].toT_REQ}}</td>
           <td >{{totalItems[0].opN_BYND_SLA_REQ}}</td>
           <td >{{totalItems[0].opN_WTH_SLA_REQ}}</td>
           <!-- <td >{{totalItems[0].}}</td>
           <td >{{totalItems[0].}}</td> -->
           <td >{{totalItems[0].clsD_BYND_SLA_APRVD_REQ}}</td>
           <td >{{totalItems[0].clsD_BYND_SLA_REJ_REQ}}</td>
           <td >{{totalItems[0].clsD_WTH_SLA_APRVD_REQ}}</td>
           <td >{{totalItems[0].clsD_WTH_SLA_REJ_REQ}}</td>
           </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div>
  <div class="table-responsive tableFixHead" #BEYONDSLATABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th>S.No</th>
               <th>District</th>
               <th>Mandal</th>
               <th>Secretariat</th>
               <th>Revenue Division Name</th>
               <th>Revenue Mandal Name</th>
               <th>Secretariat Code</th>
               <th>Department</th>
               <th>Service Name</th>
               <th>SLA Days</th>
               <th>Application No</th>
               <th>Raised Date</th>
               <th>Status</th>
               <th>Lapsing Date</th>
               <th>Pending at Designation</th>
            </tr>
        </thead>

        <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
            <tr *ngFor="let item of beyondSLAItems;let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.dname}}</td>
                <td>{{item.mname}}</td>
                <td>{{item.seC_NAME}}</td>
                <td>{{item.divname}}</td>
                <td>{{item.revenuemandal}}</td>
                <td>{{item.secretariaT_CODE}}</td>
                <td>{{item.dname}}</td>
                <td>{{item.servicename}}</td>
                 <td>{{item.slA_DAYS}}</td>
                 <td>{{item.apP_NO}}</td>
                 <td>{{item.raiseddate | date: 'dd/MM/yyyy'}}</td>
                 <td>{{item.apP_STATUS }}</td>
                 <td>{{item.lpS_DATE  | date: 'dd/MM/yyyy'}}</td>
                 <td>{{item.pendinG_AT_DESIGNATION}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
            <tr>
                <td colspan="15" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
</div>

  <ng-template #beyondSLAModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">{{title}}</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="table-responsive tableFixHead">
            <table class="tableCls" >
                <thead> 
                    <tr>
                        <th>S.No</th>
                        <th *ngFor="let head of headerCol; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="beyondSLAItems" [sortBy]="headerCol[i].colId">{{ head.colName }}
                            <mdb-icon fas icon="sort"></mdb-icon>
                          </th>
                       <!-- <th>District</th>
                       <th>Mandal</th>
                       <th>Secretariat</th>
                       <th>Department</th>
                       <th>Service Name</th>
                       <th>SLA Days</th>
                       <th>Application No</th>
                       <th>Raised Date</th>
                       <th>Status</th> -->
                    </tr>
                </thead>
        
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
                  <tr *ngFor="let item of beyondSLAItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                    <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                    <td>{{item.dname}}</td>
                   <td>{{item.mname}}</td>
                   <td>{{item.seC_NAME}}</td>
                   <td>{{item.divname}}</td>
                   <td>{{item.revenuemandal}}</td>
                   <td>{{item.secretariaT_CODE}}</td>
                   <td>{{item.dname}}</td>
                   <td>{{item.servicename}}</td>
                    <td>{{item.slA_DAYS}}</td>
                    <td>{{item.apP_NO}}</td>
                    <td>{{item.raiseddate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{item.apP_STATUS}}</td>
                    <td>{{item.lpS_DATE  | date: 'dd/MM/yyyy'}}</td>
                    <td>{{item.pendinG_AT_DESIGNATION}}</td>
                </tr>
                </tbody>
                <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
                    <tr>
                        <td colspan="15" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="beyondSLAItems.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="beyondSLAItems.length > 0"    [collectionSize]="beyondSLAItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  name="close" class="greenBtnCls" *ngIf="beyondSLAItems && beyondSLAItems.length > 0" (click)="downloadExcelForBeyondSLA()">Download Excel</button>
    </div>
   </ng-template>
  