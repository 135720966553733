<div class="row mainCls dashboardPage">
    <div class="container-fluid ">
       <app-common-dashboard-links [role]="role" ></app-common-dashboard-links>
        <div class="breadcrumbContainer mx-3 row">
          <!--   <div class="col col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="dashboardBox totalSubmitted" (click)="getWorklist()">
                    <span class="count">{{totalCount}}</span>
                    <div>Total Requests</div>
                </div>
            </div>
            <div class="col col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="dashboardBox beyondSLA" (click)="getBeyondSLA()">
                    <span class="count">{{beyondSLAItems?.length > 0 ? beyondSLAItems?.length : 0}}</span>
                    <div>Open Beyond SLA</div>
                </div>
            </div> 
         

            <div class="col col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div class="dashboardBox withinSLA" (click)="getWithInSLA()">
                    <span  class="count">{{withInSLAItems?.length > 0 ? withInSLAItems?.length : 0}}</span>
                    <div>Open Within SLA</div>
                </div>
            </div>-->

            <div class="col">
              <!--   <div class="dashboardBox totalSubmitted" (click)="getVRODashBoardDetailsList(showReqData,'All','All')"> -->
                <div class="dashboardBox totalSubmitted" (click)="getWorklist()">
                    <div class="row">
                     <div class="col col-lg-10 col-md-10 text-center"> 
                        <span class="count">{{ (dashbardVroCounts?.toT_APPS !== '' ) ? (dashbardVroCounts?.toT_APPS):0 }}</span>
                        <div class="tilesFont">Total Service Requests</div>
                    </div>
                </div>
                </div>
            </div>

            <div class="col">
                <div class="dashboardBox openWithinSLA borderCls" (click)="getOpenRequests('within')"> 
                    <div class="row">
                     <div class="col col-lg-10 col-md-10 text-center"> 
                        <span class="count">{{ (dashbardVroCounts?.opeN_WITH !== '' ) ? (dashbardVroCounts?.opeN_WITH):0 }}</span> <br>
                        <div class="tilesFont">Open Within SLA</div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <!-- <div class="dashboardBox openBeyondSLA borderCls" (click)="getVRODashBoardDetailsList(showReqData,'Open','Beyond-SubSLA')">  -->
                <div class="dashboardBox openBeyondSLA borderCls" (click)="getOpenRequests('beyond')"> 
                    <div class="row">
                     <div class="col col-lg-10 col-md-10 text-center"> 
                        <span class="count">{{ (dashbardVroCounts?.opeN_BEYOND !== '' ) ? (dashbardVroCounts?.opeN_BEYOND):0 }}</span> <br>
                        <div class="tilesFont">Open Beyond SLA</div>
                    </div>
                </div>
                </div>
            </div>
        
            <div class="col">
                <div class="dashboardBox closedWithinSLA borderCls" (click)="getVRODashBoardDetailsList(showReqData,'Closed','Within-SubSLA')"> 
                    <div class="row">
                     <div class="col col-lg-10 col-md-10 text-center"> 
                        <span class="count"> {{ (dashbardVroCounts?.closeD_WITH !== '' ) ? (dashbardVroCounts?.closeD_WITH):0 }}</span> <br>
                                <div class="tilesFont">Closed Within SLA</div>
                    </div>
                </div>
                </div>
            </div>
        
            <div class="col">
                <div class="dashboardBox closedBeyondSLA borderCls" (click)="getVRODashBoardDetailsList(showReqData,'Closed','Beyond-SubSLA')"> 
                    <div class="row">
                     <div class="col col-lg-10 col-md-10 text-center"> 
                        <span class="count">{{ (dashbardVroCounts?.closeD_BEYOND !== '' ) ? (dashbardVroCounts?.closeD_BEYOND):0 }}</span><br>
                                <div class="tilesFont">Closed Beyond SLA</div>
                    </div>
                </div>
                </div>
            </div>



           
         
            <!-- <div class="col col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboardBox revertBack">
                    <span class="count">03</span>
                    <div>Revert Back</div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboardBox recommended">
                    <span class="count">07</span>
                    <div>Recommended</div>
                </div>
            </div> -->
        </div>
        <div class="mt-1 mx-3 requestList">
          <h6 class="mb-3 float-left">Request List - {{workListName}}</h6>
          <input type="text" placeholder="Search Transaction" class="searchInput float-right" [(ngModel)]="transactionID" (keyup)="searchTransaction()">
          <div class="dashboardGrid">
            <table class="dashboardGrid">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Request ID</th>
                        <th>Service Name</th>
                        <th>Service Category</th>
                        <th>Department Name</th>
                        <th>Citizen Name</th>
                        <!-- <th>Mobile Number</th> -->
                        <th>Applied Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
    
                <tbody *ngIf="items.length > 0">
                    <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;" (click)= "getCertificateDetails(item); this.scrollToElement(scrolltarget);" [ngClass]="{'selected':item.isSelected}">
                        <td>{{((page -1) * pageSize) + (i + 1)}}</td>
                        <td>{{item.requisitionId}}</td>
                        <td *ngIf="item.DepartmentCode !=='RD' && item.ServiceCode !== 'RectificationOfResurveyedRecords'  && item.ServiceCode !== 'UpdateAarogyaSriCardForImage' && item.ServiceCode !== 'HouseholdSplit@1' && item.ServiceCode !== 'HouseholdSplit@2' &&
                        item.ServiceCode !== 'AddressCertificateForAadharEnrollment' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@1' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@2' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@3'">{{item.ServiceCode}}</td>
                        <td *ngIf="item.DepartmentCode ==='HF' && item.ServiceCode === 'UpdateAarogyaSriCardForImage' ">UpdateAarogyaSriCard</td>
                        <td *ngIf="item.ServiceCode === 'RectificationOfResurveyedRecords'">Grievance in Resurvey Process</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@1' || item.ServiceCode === 'Pensions@V1'">Pensions O.A.P</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@2' || item.ServiceCode === 'Pensions@V2' || item.ServiceCode === 'Pensions-NEW@2' || item.ServiceCode === 'Pensions-NEW@V2'">Pensions Weavers</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@3' || item.ServiceCode === 'Pensions@V3'">Pensions Disabled</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@4' || item.ServiceCode === 'Pensions@V4'">Pensions Widow</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@5' || item.ServiceCode === 'Pensions@V5'">Pensions Toddy Tappers</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@6' || item.ServiceCode === 'Pensions@V6'">Pensions Transgender</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@7' || item.ServiceCode === 'Pensions@V7'">Pensions Fishermen</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@8' || item.ServiceCode === 'Pensions@V8'">Pensions Single Women</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@9' || item.ServiceCode === 'Pensions@V9' || item.ServiceCode === 'Pensions-NEW@9' || item.ServiceCode === 'Pensions-NEW@V9'">Pensions Traditional Cobblers</td>
                        <td *ngIf="item.ServiceCode === 'Pensions@10' || item.ServiceCode === 'Pensions@V10' || item.ServiceCode === 'Pensions-NEW@10' || item.ServiceCode === 'Pensions-NEW@V10'">Pensions Dappu Artists</td>
                        <td *ngIf="item.ServiceCode === 'HouseholdSplit@1' || item.ServiceCode === 'HouseholdSplit@2'">HouseholdSplit</td>
                        <td *ngIf="item.ServiceCode === 'AddressCertificateForAadharEnrollment' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@1' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@2' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@3'">Address Certificate for Aadhar Enrollment/Update</td>
                        <td *ngIf="item.ServiceCode !== 'AddressCertificateForAadharEnrollment' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@1' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@2' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@3'">{{item.DepartmentCode}}</td>
                        <td *ngIf="item.ServiceCode !== 'AddressCertificateForAadharEnrollment' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@1' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@2' && item.ServiceCode !== 'AddressCertificateForAadharEnrollment@3'">{{item.DepartmentCode}}</td>
                        <td *ngIf="item.ServiceCode === 'AddressCertificateForAadharEnrollment' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@1' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@2' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@3'">GSWS</td>
                        <td *ngIf="item.ServiceCode === 'AddressCertificateForAadharEnrollment' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@1' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@2' || item.ServiceCode === 'AddressCertificateForAadharEnrollment@3'">GSWS</td>
                        <td>{{item.ApplicantName}}</td>
                        <!-- <td>-</td> -->
                        <td>{{item.RequestedDate | date: 'dd/MM/yyyy'}}</td>
                        <td>Pending</td>
    
                    </tr>
                </tbody>
                <tbody *ngIf="items.length === 0">
                    <tr>
                        <td colspan="9" class="text-center">No requests are pending.</td>
    
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="float-left" *ngIf="items.length > 0" >
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
       
        </div>
        <div #scrolltarget>&nbsp;</div>
    <ng-container *ngIf="items.length > 0"> 
        <div class="certificateDetails mx-3 mt-3">
            <h6 class="mb-3 title">Request ID:{{reqId}}</h6>
            <!-- <ng-container *ngIf="certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers'">
                <div class="certificateInfo">
                    <div class="subHeadingCls mb-3">Details of Ex-Service Person/Soldier applying for grant of Assignment:</div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Name of Service Person/Soldier:</label>
                                <div class="valueCls">{{certificateInfo?.nameofServicePersonOrSoldier ? (certificateInfo?.nameofServicePersonOrSoldier) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Category of Service person/Soldier:</label>
                                <div class="valueCls">{{certificateInfo?.categoryofServicepersonOrSoldie ? (certificateInfo?.categoryofServicepersonOrSoldie) : '-'}}</div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Is the Soldier/Ex-Service person a Gallantry Award Winner ?</label>
                                <div class="valueCls">{{certificateInfo?.isGallantryAwardWinner === true ? 'Yes' : 'No' }}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie === 'Ex-Serviceperson' || certificateInfo?.categoryofServicepersonOrSoldie === 'Serving Soldier'">
                                <label class="labelCls">Is the soldier/ex-service person War Disabled ?</label>
                                <div class="valueCls">{{ certificateInfo?.isWarDisabled === true ? 'Yes' : 'No' }}</div>
                            </div>
                          </div> 
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="fieldHolder" *ngIf="certificateInfo?.isWarDisabled == true">
                                <label class="labelCls">Please Specify: </label>
                                <div class="valueCls">{{certificateInfo?.disabilityDetails ? (certificateInfo?.disabilityDetails) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Date of Application received to ZSWO:</label>
                                <div class="valueCls">{{certificateInfo?.dateofApplicationreceivedtoZSWO ? (certificateInfo?.dateofApplicationreceivedtoZSWO) : '-' }}</div>
                            </div>
                           </div> 
                    </div>
                </div>
            </ng-container> -->
            <app-land-assignment-view-new [basicInfo]="basicInfo" [certificateInfo]="certificateInfo"  *ngIf="certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers'">
            </app-land-assignment-view-new>
            <form [formGroup]="form">
                <app-common-form-view [basicInfo]="basicInfo?.applicantInfo" [commonFormAddedFields]="commonFormAddedFields" [presentAddr]="presentAddr" [permanentAddr]="permanentAddr" *ngIf="basicInfo && basicInfo.length !==0"></app-common-form-view>
                <app-integrated-certificate-view *ngIf="certificateName === 'IntegratedCertificate' || certificateName === 'IntegratedCertificate-RDO' || certificateName === 'IntegratedCertificateForStudents' || certificateName === 'IntegratedCertificateForStudents-RDO' || certificateName === 'IntegratedCertificate-SuoMoto'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList"
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-integrated-certificate-view>
                <app-obc-certificate-view *ngIf="certificateName === 'OBCMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-obc-certificate-view>
                <app-income-certificate-view *ngIf="certificateName === 'IncomeCertificate' || certificateName === 'IncomeCertificateForStudents'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList"
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-income-certificate-view>
                <app-family-member-certificate-view *ngIf="certificateName === 'FamilyMemberCertificate'" [isEditable]="role === 'Tahsildar' && !isApproveExist" [basicInfo]="basicInfo" 
                [certificateInfo]="certificateInfo" [fileList]="fileList" [reqId]="receivedRequestionId"
                (view)="viewFile($event)" (download)="downloadFile($event)" (validateForm)="validateForm($event)"></app-family-member-certificate-view>
                <app-social-audit-grievance-view *ngIf="certificateName === 'GrievanceCaste'"  [reqId]="receivedRequestionId" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (validateForm)="validateForm($event)" (download)="downloadFile($event)">  
                </app-social-audit-grievance-view>
                <app-social-audit-grievance-view *ngIf="certificateName === 'GrievanceOthers'" [reqId]="receivedRequestionId" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (validateForm)="validateForm($event)" (download)="downloadFile($event)">  
                </app-social-audit-grievance-view>
                <app-possession-certificate-view *ngIf="certificateName === 'PossessionCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-possession-certificate-view>
                <app-income-ews-certificate-view *ngIf="certificateName === 'IncomeAssetCertificateForEWS'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-income-ews-certificate-view>
                <app-no-earning-certificate-view *ngIf="certificateName === 'NoEarningMemberCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-no-earning-certificate-view>
                <app-late-reg-certificate-view *ngIf="certificateName === 'BirthDeathCertificate'" [reqId]="getId" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-late-reg-certificate-view>
                <app-issue-small-farmer-certificate-view *ngIf="certificateName === 'SnMFarmerCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-issue-small-farmer-certificate-view>
                <app-f-line-certificate-view *ngIf="certificateName === 'FlineApplication'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-f-line-certificate-view>
                <app-agriculture-income-certificate-view *ngIf="certificateName === 'AgricultureIncomeCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-agriculture-income-certificate-view>
                <app-encumbrance-certificate-view *ngIf="certificateName === 'EncumbranceCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-encumbrance-certificate-view>
                <app-fmb-copy-view *ngIf="certificateName === 'FMBCopy'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-fmb-copy-view>
                
                <app-change-of-name-certificate-view *ngIf="certificateName === 'ChangeOfNameCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-change-of-name-certificate-view>
                <app-extract-of-house-patta-view *ngIf="certificateName === 'HouseSiteDFormPatta'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-extract-of-house-patta-view>
                <app-extractofnoc-explosiveorpetroleum-cerificate *ngIf="certificateName === 'ExtractOfNOCUnderExplosiveOrPetroleum'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-extractofnoc-explosiveorpetroleum-cerificate>
                <app-land-assignment-to-soldiers-view *ngIf="certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-land-assignment-to-soldiers-view>
                <app-permission-of-digging-of-bore-well-for-agricultural-purpose-view *ngIf="certificateName === 'PFDAgriculturalwellOrDrinkingwater'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-permission-of-digging-of-bore-well-for-agricultural-purpose-view>
                <app-no-property-certificate-view *ngIf="certificateName === 'NoPropertyCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-no-property-certificate-view>
                <app-renewal-cinema-licence-view *ngIf="certificateName === 'RenewalCinemaLicence'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-renewal-cinema-licence-view>
                
                 <app-issue-of-occupancy-rights-certificates-for-inam-lands-view *ngIf="certificateName === 'OccupancyRightsCertificateForInamLands'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-issue-of-occupancy-rights-certificates-for-inam-lands-view>
                <app-issueofnoc-forstoringofpetroleumproducts-certificate-view *ngIf="certificateName === 'IssueOfNOCToStoringPetroleumProducts'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-issueofnoc-forstoringofpetroleumproducts-certificate-view>
                <app-cracker-licence-application *ngIf="certificateName === 'ExplosiveMaterialLicence'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">  
                </app-cracker-licence-application> 
                <app-addition-of-survey-no-view *ngIf="certificateName === 'InsertSurveyNumInAdangal'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-addition-of-survey-no-view>
                <app-old-adangal-view *ngIf="certificateName === 'ManualAdangalCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-old-adangal-view>
                <app-land-conversion-view *ngIf="certificateName === 'LandConversionNewCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-land-conversion-view>
              
                <app-ap-dotted-lands-view *ngIf="certificateName === 'ClaimsUnderDottedLands'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-ap-dotted-lands-view>
                <app-modifications-in22-alist-view *ngIf="certificateName === '22AModification'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-modifications-in22-alist-view>
                <app-housing-site *ngIf="certificateName === 'HousingSiteApplication'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-housing-site>
                <app-housing-site *ngIf="certificateName === 'HousingSiteApplication-URBAN'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-housing-site>
                <app-epassbook-view *ngIf="certificateName === 'ePassbookReplacementPPB'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-epassbook-view>
                <app-household-split-view *ngIf="certificateName === 'HouseholdSplit' || certificateName === 'HouseholdSplit@1' || certificateName === 'HouseholdSplit@2'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-household-split-view>

                <app-muslim-marriage-view  *ngIf="certificateName === 'MuslimMarriageCertificate'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-muslim-marriage-view>
    
                <app-newricecard *ngIf="certificateName === 'NewRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-newricecard>
                <app-view-marriage-split-ricecard *ngIf="certificateName === 'MarriageSplitRC'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)"></app-view-marriage-split-ricecard>
                <app-view-memberaddtion *ngIf="certificateName === 'MemberAdditionRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-view-memberaddtion>
                <app-view-memberdeletion *ngIf="certificateName === 'MemberDeletionRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-view-memberdeletion>
                <app-view-spillitingricecard *ngIf="certificateName === 'SplittingRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                (view)="viewFile($event)" (download)="downloadFile($event)">
                </app-view-spillitingricecard>
                 <app-view-surrenderricecard *ngIf="certificateName === 'SurrenderRiceCard'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo"></app-view-surrenderricecard>
                 <app-view-correction-of-wrong-aadhar-seeding *ngIf="certificateName === 'CorrectionofWrongAadhaar'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" 
                 (view)="viewFile($event)" (download)="downloadFile($event)">
                 </app-view-correction-of-wrong-aadhar-seeding>
                <app-fp-shop-view *ngIf="certificateName === 'FPSHOPRENEW-VSKP' || certificateName === 'FPSHOPRENEW-OTH' || certificateName === 'FPSHOPRENEW'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)"  (download)="downloadFile($event)" ></app-fp-shop-view>
                <app-rectification-resurvey-view *ngIf="certificateName === 'RectificationOfResurveyedRecords'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-rectification-resurvey-view>
                
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@1' || certificateName === 'Pensions@V1'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@2' || certificateName === 'Pensions@V2' || certificateName === 'Pensions-NEW@2' || certificateName === 'Pensions-NEW@V2'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@3' || certificateName === 'Pensions@V3'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@4' || certificateName === 'Pensions@V4'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@5' || certificateName === 'Pensions@V5'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@6' || certificateName === 'Pensions@V6'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@7' || certificateName === 'Pensions@V7'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@8' || certificateName === 'Pensions@V8'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@9' || certificateName === 'Pensions@V9' || certificateName === 'Pensions-NEW@9' || certificateName === 'Pensions-NEW@V9'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-pensionform-view *ngIf="certificateName === 'Pensions@10' || certificateName === 'Pensions@V10' || certificateName === 'Pensions-NEW@10' || certificateName === 'Pensions-NEW@V10'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-pensionform-view>
                 <app-marriage-certificate-view  *ngIf="certificateName === 'MarriageCertificate' || certificateName === 'MarriageCertificate-Rural'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-marriage-certificate-view>
                <app-aarogyasri-view *ngIf="certificateName === 'AarogyaSriNewCard' || certificateName === 'UpdateAarogyaSriCardForImage' " [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [isNewEnrollment] = "true"  [isEditable]="true" [taskId] = "reqId" [originalFileList]="fileList" (view)="viewFile($event)" (getUpdatedData)="getUpdatedData($event)" (download)="downloadFile($event)"></app-aarogyasri-view>
                <app-address-certificate-for-aadhar-enrollment-view  *ngIf="certificateName === 'AddressCertificateForAadharEnrollment' || certificateName === 'AddressCertificateForAadharEnrollment@1' || certificateName === 'AddressCertificateForAadharEnrollment@2' || certificateName === 'AddressCertificateForAadharEnrollment@3'" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-address-certificate-for-aadhar-enrollment-view>
                <!-- <app-job-seeker-reg-view *ngIf="certificateName === 'JobSeekerRegistration'"></app-job-seeker-reg-view> -->
                  <div class="certificateInfo certificateClsBirthDeath" *ngIf = "role === 'Revenue Divisonal Officer' && certificateName === 'BirthDeathCertificate'">
                <div class="row">
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="fieldHolder" style="padding: 0px 20px 15px 0px; font-weight: 500;">
                            <input type="radio"  value="Municipality" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                            <span class="mx-2 radioCls"><strong>Municipality</strong></span>
                            <!-- <input type="radio"  value="Municipal Corporation" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                            <span class="mx-2 radioCls"><strong>Municipal Corporation</strong></span>
                            <input type="radio"  value="Grampanchayat" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                           <span class="mx-2 radioCls"><strong>Grampanchayat</strong></span>  -->
                        </div>
                     </div>
                     <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12" style="font-weight: 500;">
                        <label class="labelCls" *ngIf="f.panchayatType.value === 'Grampanchayat'"> <strong>Grampanchayat Name:</strong> <span class="mandatoryCls">*</span></label>
                        <label class="labelCls" *ngIf="f.panchayatType.value === 'Municipality'"><strong>Municipality Name:</strong> <span class="mandatoryCls">*</span></label>
                        <label class="labelCls" *ngIf="f.panchayatType.value === 'Municipal Corporation'"><strong>Municipal Corporation Name:</strong> <span class="mandatoryCls">*</span></label> 
                            <!-- <input type="radio"  value="Municipal Corporation" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                            <span class="mx-2 radioCls"><strong>Municipal Corporation</strong></span>
                            <input type="radio"  value="Grampanchayat" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                           <span class="mx-2 radioCls"><strong>Grampanchayat</strong></span>  -->
                        
                     </div>
                </div>
                <div class="row">
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="fieldHolder" style="padding: 0px 20px 15px 0px; font-weight: 500;" >
                            <!-- <input type="radio"  value="Municipality" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                            <span class="mx-2 radioCls"><strong>Municipality</strong></span> -->
                            <input type="radio"  value="Municipal Corporation" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                            <span class="mx-2 radioCls"><strong>Municipal Corporation</strong></span>
                            <!-- <input type="radio"  value="Grampanchayat" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                           <span class="mx-2 radioCls"><strong>Grampanchayat</strong></span>  -->
                        </div>
                     </div>
                     <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <input type="text" name="panchayatName" class="inputCls" formControlName="panchayatName" 
                        [required] = "role === 'Revenue Divisonal Officer' && certificateName === 'BirthDeathCertificate'"
                        [ngClass]="{ 'is-invalid':submitted && f.panchayatName.errors }" maxlength="50" appBlockCopyPaste>
                        <div *ngIf="submitted && f.panchayatName.errors" class="invalid-feedback">
                            <div *ngIf="f.panchayatName.errors.required ">{{f.panchayatType.value}} is required</div>
                        </div>
                     </div>
                </div>
                <div class="row">
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="fieldHolder" style="padding: 0px 20px 15px 0px; font-weight: 500;">
                            <!-- <input type="radio"  value="Municipality" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                            <span class="mx-2 radioCls"><strong>Municipality</strong></span> -->
                            <!-- <input type="radio"  value="Municipal Corporation" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                            <span class="mx-2 radioCls"><strong>Municipal Corporation</strong></span> -->
                            <input type="radio"  value="Grampanchayat" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                           <span class="mx-2 radioCls"><strong>Grampanchayat</strong></span> 
                        </div>
                     </div>
                     
                </div>
                <div class="row">
                    
                    <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <input type="radio"  value="Municipality" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                        <span class="mx-2 radioCls">Municipality</span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <input type="radio"  value="Municipal Corporation" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                        <span class="mx-2 radioCls">Municipal Corporation</span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <input type="radio"  value="Grampanchayat" name="panchayatType" formControlName="panchayatType" required (change)="selectType()">
                        <span class="mx-2 radioCls">Grampanchayat</span>
                    </div> -->

                <!-- </div>
                <div class="row mt-3"> -->

                    <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label class="labelCls" *ngIf="f.panchayatType.value === 'Grampanchayat'">Grampanchayat Name: <span class="mandatoryCls">*</span></label>
                        <label class="labelCls" *ngIf="f.panchayatType.value === 'Municipality'">Municipality Name: <span class="mandatoryCls">*</span></label>
                        <label class="labelCls" *ngIf="f.panchayatType.value === 'Municipal Corporation'">Municipal Corporation Name: <span class="mandatoryCls">*</span></label>
                        <input type="text" name="panchayatName" class="inputCls" formControlName="panchayatName" 
                        [required] = "role === 'Revenue Divisonal Officer' && certificateName === 'BirthDeathCertificate'"
                        [ngClass]="{ 'is-invalid':submitted && f.panchayatName.errors }" maxlength="50" appBlockCopyPaste>
                        <div *ngIf="submitted && f.panchayatName.errors" class="invalid-feedback">
                            <div *ngIf="f.panchayatName.errors.required ">{{f.panchayatType.value}} is required</div>
                        </div>
                    </div> -->
                    <!-- <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                       
                    </div> -->
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label class="labelCls">L.Dis.ASO (MRO LR No): <span class="mandatoryCls">*</span></label>
                        <input type="text" name="DisASONo" class="inputCls" formControlName="DisASONo" 
                        [required] = "role === 'Revenue Divisonal Officer' && certificateName === 'BirthDeathCertificate'"
                        [ngClass]="{ 'is-invalid':submitted && f.DisASONo.errors }" maxlength="50" appBlockCopyPaste>
                        <div *ngIf="submitted && f.DisASONo.errors" class="invalid-feedback">
                            <div *ngIf="f.DisASONo.errors.required ">L.Dis.ASO (MRO LR No) is required</div>
                        </div>
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label class="labelCls">L.Dis.H (RDO/SEM LR No's): <span class="mandatoryCls">*</span></label>
                        <input type="text" name="DisHeadNo" class="inputCls" formControlName="DisHeadNo" 
                        [required] = "role === 'Revenue Divisonal Officer' && certificateName === 'BirthDeathCertificate'"
                        [ngClass]="{ 'is-invalid':submitted && f.DisHeadNo.errors }" maxlength="50" appBlockCopyPaste>
                        <div *ngIf="submitted && f.DisHeadNo.errors" class="invalid-feedback">
                            <div *ngIf="f.DisHeadNo.errors.required ">L.Dis.H (RDO/SEM LR No's) is required</div>
                        </div>
                    </div>
                    </div>
                </div>
            </form>
    
        <!-- <div class="certificateInfo certificateCls" *ngIf="checklist && checklist.length > 0 && role === 'Village Revenue Officer'">
            <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="subHeadingCls mb-3">VRO Checklist</div>
                <form  [formGroup]="checklistForm">
                <div class="fieldHolder">
                    <label class="labelCls">Applicant Name:</label>
                    <div class="valueCls">{{basicInfo?.applicantInfo?.personNames[0]?.firstName}} {{basicInfo?.applicantInfo?.personNames[0]?.middleName}} {{basicInfo?.applicantInfo?.personNames[0]?.lastName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Applicant Address:</label>
                    <div class="valueCls mb-2">
                        <div>Door No: {{presentAddr[0].doorNo}}, Street: {{presentAddr[0].streetName}}</div>
                        <div>Village/ward: {{presentAddr[0].villageWard}}, Mandal: {{presentAddr[0].mandalMunicipality}}</div>
                        <div>District: {{presentAddr[0].district}}</div>
                    </div>
                </div>
                <div class="fieldHolder" *ngFor="let item of checklist" >
                    <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                    <div class="valueCls">
                    <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                    <label for="{{item.id}}_Y">Yes</label>
                    <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                    <label for="{{item.id}}_N">No</label>
                    </div>
                    <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                        <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                    </div>
                </div>
                </form>
                </div>
            </div>
        </div>
        <div class="certificateInfo certificateCls" *ngIf="checklist && checklist.length > 0 && role === 'Revenue Inspector'">
            <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="subHeadingCls mb-3">VRO Checklist</div>
                <div class="fieldHolder">
                    <label class="labelCls">Applicant Name:</label>
                    <div class="valueCls">{{basicInfo?.applicantInfo?.personNames[0]?.firstName}} {{basicInfo?.applicantInfo?.personNames[0]?.middleName}} {{basicInfo?.applicantInfo?.personNames[0]?.lastName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Applicant Address:</label>
                    <div class="valueCls mb-2">
                        <div>Door No: {{presentAddr[0].doorNo}}, Street: {{presentAddr[0].streetName}}</div>
                        <div>Village/ward: {{presentAddr[0].villageWard}}, Mandal: {{presentAddr[0].mandalMunicipality}}</div>
                        <div>District: {{presentAddr[0].district}}</div>
                    </div>
                </div>
                <div class="fieldHolder" *ngFor="let item of history.list" >
                    <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                    <div class="valueCls">
                    <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory"
                        [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                    <label for="{{item.id}}_Y">Yes</label>
                    <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                        [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                    <label for="{{item.id}}_N">No</label>
                    </div>
                </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="subHeadingCls mb-3">RI Checklist</div>
                    <form  [formGroup]="checklistForm">
                    <div class="fieldHolder" *ngFor="let item of checklist" >
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                            <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    </form>
                    </div>
            </div>
        </div> -->
       
            <!-- <div class="certificateInfo"> -->
            <!-- <h6 class="text-center" style="margin-top: 80px;">Rice card proforma download for field verification</h6> -->
            <form [formGroup]="sixstepForm">

            <div class="certificateInfo" *ngIf="departmentCode === 'CS' && !isFpShopApplication">

                <div class="subHeadingCls  mt-2">6 STEP VALIDATION PROFORMA</div>
                <div class="row mt-2">
                        <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 ml-3">
                        <label class="labelCls ">Cluster:<span class="mandatoryCls">*</span></label>
                        <select class="selectCls " name="cluster" formControlName = "cluster" (change)="submitCluster($event)"  required   style="width: 75%;" [ngClass]="{ 'is-invalid':submits && (k.cluster.errors || k.cluster.value === 'Select')}">
                            <option  [ngValue]= "null" disabled>Select</option>
                            <!-- <option value="1">1</option> -->

                            <option *ngFor="let clusters of clusterData" [ngValue]="clusters.entityCode">{{clusters.name}}
    
                        </select> 
                        <div *ngIf="submits && (k.cluster.errors || k.cluster.value === 'Select')" class="selectError">
                            <div *ngIf="k.cluster.errors?.required || k.cluster.value === 'Select'">Please select cluster</div>
                        </div>
                          </div> 
                          <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mr-3">
                            <label class="labelCls textboxname">FP Shop:<span class="mandatoryCls">*</span></label>
                            <select class="selectCls textboxname" formControlName ="fpshop"  (change)="submitfpshop($event)" style="width: 75%;"  [ngClass]="{ 'is-invalid':submits && (k.fpshop.errors || k.fpshop.value === 'Select')}">
                                <option  [ngValue]= "null" disabled>Select</option>
                                <option *ngFor="let fpshops of fpshopData" [ngValue]="fpshops.shop_no">{{fpshops.shop_no}}

                               
                            </select> 
                            <div *ngIf="submits && (k.fpshop.errors || k.fpshop.value === 'Select')" class="selectError textboxname">
                                <div *ngIf="k.fpshop.errors?.required || k.fpshop.value === 'Select'">Please select fpshop</div>
                            </div>
                            <!-- <button class="btn btn-primary float-right bbtn "  >Submit</button> -->
    
                          </div>  
                     
                </div>
    
            </div>
            <div class="mb-1 mx-3 requestList" *ngIf="showCluster && departmentCode === 'CS' && sixStepGridData != '' && !isFpShopApplication">
                
                <br class="mb-3">
                <!-- <button class="btn btn-primary float-right mb-3" (click)="printData();" style="margin-top:-25px;">SAVE PDF</button> -->
                <app-print-six-step-proforma  *ngIf="!isFpShopApplication" [sixstepPrintData]="sixStepGridData"></app-print-six-step-proforma>
        
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Date of Apply:</label>
                            <div class="valueCls">{{myDate | date: 'shortDate'}}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">District:</label>
                            <div class="valueCls">{{sixStepGridData[0].districtName}}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Mandal:</label>
                            <div class="valueCls">{{sixStepGridData[0].mandalName}}</div>
                        </div>
                        
                     </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Family Id:</label>
                                <div class="valueCls">{{sixStepGridData[0].householdId}}</div>
                            </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Secretriat:</label>
                            <div class="valueCls">{{sixStepGridData[0].secretariatName}}</div>
                        </div>
                        
                                         
                            
                        
                    </div>
                 </div>
                <!-- <h6 class="mb-3 float-left">Request List - {{workListName}}</h6> -->
                <!-- <input type="text" placeholder="Search Transaction" class="searchInput float-right"> -->
                <div class="dashboardGrid tables">
                    <!-- <div class="row" > -->
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <table class="tableCls mt-2">
                        <thead>
                            <tr>
                                <th class="texthead">S.No</th>
                                <th class="texthead">Name</th>
                                <th class="texthead">Mobile No</th>
                                <th class="texthead">Gender</th>
                                <!-- <th class="texthead">Relation with HOF</th> -->
                                 <th class="texthead">Details of family member  if govt employee/pensioner</th>
                                 <th  class="texthead">Agree/Disagree</th>

                                <th class="texthead">Details of family member paying income tax</th>
                                <th  class="texthead" >Agree/Disagree</th>

                                <th class="texthead">Details of 4 wheeler owned by family member</th>
                                <th  class="texthead">Agree/Disagree</th>

                                <th class="texthead" >Details of land holding of the family</th>
                                <th  class="texthead" >Agree/Disagree</th>

                                <th class="texthead">Municipal property details more than 1000sq. ft</th>
                                <th  class="texthead" >Agree/Disagree</th>

                                <th class="texthead" >Details of 6months Electricity consumption of more than 300units per month</th>
                                <th  class="texthead" >Agree/Disagree</th>

                                <!-- <th class="texthead">Birth/Death permanent migration due to marriage/ Not member of family</th>
                                <th class="texthead">Family income per month</th> -->
                            </tr>
                        </thead>
            
                        <tbody formArrayName="tableData">
                            <tr class="tableRow" [formGroupName]="i" *ngFor="let member of tableDatas?.controls; let i=index;" >
                                <td>{{i+1}}</td>
                                <td class="">{{this.tableDatas.controls[i].get('name')?.value}}
                                    <!-- <input type="text" class="inputCls clsname" name="name" id="name"  formControlName = "name"  readonly> -->
                                </td>
                                <td>
                                    {{this.tableDatas.controls[i].get('mobileNo')?.value}}
                                    <!-- <input type="text" class="inputCls clsname" name="mobileNo" id="mobileNo"   formControlName = "mobileNo" readonly> -->
                                </td>
                                <td>
                                    {{this.tableDatas.controls[i].get('gender')?.value}}
                                    <!-- <select class="selectCls clsname" name="gender"  id="gender"  formControlName = "gender" readonly>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let gender of genders" [ngValue]="gender.name">{{gender.name}}
            
                                  </select> -->
                                </td>
                                <!-- <td><select class="selectCls clsname" name="relation"  id="relation"  formControlName = "relation" >
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let relation of relations" [ngValue]="relation.name">{{relation.name}}

                                  </select></td> -->
                                    
                                  <!-- <td><input type="text" class="inputCls clsname" name="govtEmploye" id="govtEmploye"   formControlName = "govtEmploye" readonly >
                                </td> -->
                                <td>
                                    <span *ngIf="this.tableDatas.controls[i].get('govtEmploye')?.value == 'NO' || this.tableDatas.controls[i].get('govtEmploye')?.value == null">N</span>
                                    <input type="text" class="inputCls clsname" name="govtEmploye" id="govtEmploye"   formControlName = "govtEmploye" *ngIf="this.tableDatas.controls[i].get('govtEmploye')?.value != 'NO'  && this.tableDatas.controls[i].get('govtEmploye')?.value != null">
                                </td>
                                <td >
                                    <div [hidden]="this.tableDatas.controls[i].get('govtEmploye')?.value == 'NO' || this.tableDatas.controls[i].get('govtEmploye')?.value == null  || this.tableDatas.controls[i].get('govtEmploye')?.value == 0"   [ngClass]="{ 'is-invalid':submitTable && this.tableDatas.controls[i].get('statusEmployee')?.errors }">
                                    <input type="radio"  value="agree" name="statusEmployee{{i}}" formControlName="statusEmployee"    [required]="this.tableDatas.controls[i].get('govtEmploye')?.value != 'NO' && this.tableDatas.controls[i].get('govtEmploye')?.value != null    && this.tableDatas.controls[i].get('govtEmploye')?.value != 0" ><span class="mx-2 radioCls">Agree</span>
                                    <input type="radio"  value="disagree" name="statusEmployee{{i}}" formControlName="statusEmployee" [required]="this.tableDatas.controls[i].get('govtEmploye')?.value != 'NO' && this.tableDatas.controls[i].get('govtEmploye')?.value != 0 && this.tableDatas.controls[i].get('govtEmploye')?.value != null" ><span class="mx-2 radioCls" >Disagree</span>
                                 </div>
                                 <div *ngIf="submitTable && this.tableDatas.controls[i].get('statusEmployee')?.errors" class="invalid-feedback">
                                    <div *ngIf="this.tableDatas.controls[i].get('statusEmployee')?.errors?.required ">Please enter Agree/Disagree</div>
                                </div>
                                 </td>
                                    <td>
                                        <span *ngIf="this.tableDatas.controls[i].get('incomeTax')?.value == 'NO' || this.tableDatas.controls[i].get('incomeTax')?.value == null">N</span>
                                        <input type="text" class="inputCls clsname" name="incomeTax" id="incomeTax"   formControlName = "incomeTax" *ngIf="this.tableDatas.controls[i].get('incomeTax')?.value != 'NO' && this.tableDatas.controls[i].get('incomeTax')?.value != null">
                                    </td>

                                    <td >
                                        <div [hidden]="this.tableDatas.controls[i].get('incomeTax')?.value == 'NO' || this.tableDatas.controls[i].get('incomeTax')?.value == null ||this.tableDatas.controls[i].get('incomeTax')?.value == 0"   [ngClass]="{ 'is-invalid':submitTable && this.tableDatas.controls[i].get('statusIncome')?.errors }">
                                        <input type="radio"  value="agree" name="statusIncome{{i}}" formControlName="statusIncome"    [required]="this.tableDatas.controls[i].get('incomeTax')?.value != 'NO'  && this.tableDatas.controls[i].get('incomeTax')?.value  != null && this.tableDatas.controls[i].get('incomeTax')?.value != 0" ><span class="mx-2 radioCls">Agree</span>
                                        <input type="radio"  value="disagree" name="statusIncome{{i}}" formControlName="statusIncome" [required]="this.tableDatas.controls[i].get('incomeTax')?.value != 'NO' && this.tableDatas.controls[i].get('incomeTax')?.value != null && this.tableDatas.controls[i].get('incomeTax')?.value != 0" ><span class="mx-2 radioCls" >Disagree</span>
                                     </div>
                                     <div *ngIf="submitTable && this.tableDatas.controls[i].get('statusIncome')?.errors" class="invalid-feedback">
                                        <div *ngIf="this.tableDatas.controls[i].get('statusIncome')?.errors?.required ">Please enter Agree/Disagree</div>
                                    </div>
                                     </td>
                                     <td>
                                        <span *ngIf="this.tableDatas.controls[i].get('fourWheeler')?.value == 'NO' || this.tableDatas.controls[i].get('fourWheeler')?.value == null">N</span>
                                        <input type="text" class="inputCls clsname" name="fourWheeler" id="fourWheeler"   formControlName = "fourWheeler" *ngIf="this.tableDatas.controls[i].get('fourWheeler')?.value != 'NO' && this.tableDatas.controls[i].get('fourWheeler')?.value != null">
                                    </td>
                                    <td >
                                        <div [hidden]="this.tableDatas.controls[i].get('fourWheeler')?.value == 'NO' || this.tableDatas.controls[i].get('fourWheeler')?.value == null|| this.tableDatas.controls[i].get('fourWheeler')?.value == 0"   [ngClass]="{ 'is-invalid':submitTable && this.tableDatas.controls[i].get('statusWheeler')?.errors }">
                                        <input type="radio"  value="agree" name="statusWheeler{{i}}" formControlName="statusWheeler"    [required]="this.tableDatas.controls[i].get('fourWheeler')?.value != 'NO'  && this.tableDatas.controls[i].get('fourWheeler')?.value != null  && this.tableDatas.controls[i].get('fourWheeler')?.value != 0" ><span class="mx-2 radioCls">Agree</span>
                                        <input type="radio"  value="disagree" name="statusWheeler{{i}}" formControlName="statusWheeler" [required]="this.tableDatas.controls[i].get('fourWheeler')?.value != 'NO' && this.tableDatas.controls[i].get('fourWheeler')?.value != 0  && this.tableDatas.controls[i].get('fourWheeler')?.value != null" ><span class="mx-2 radioCls" >Disagree</span>
                                     </div>
                                     <div *ngIf="submitTable && this.tableDatas.controls[i].get('statusWheeler')?.errors" class="invalid-feedback">
                                        <div *ngIf="this.tableDatas.controls[i].get('statusWheeler')?.errors?.required ">Please enter Agree/Disagree</div>
                                    </div>
                                     </td>
                                    <!-- <td><input type="text" class="inputCls clsname" name="landHolding" id="landHolding"   formControlName = "landHolding"></td> -->
                                    
                                    <td><span *ngIf="this.tableDatas.controls[i].get('landHolding')?.value == null || this.tableDatas.controls[i].get('landHolding')?.value === 0" >N</span>
                                        <input type="text" class="inputCls clsname" name="landHolding" id="landHolding"   formControlName="landHolding" *ngIf=" this.tableDatas.controls[i].get('landHolding')?.value != 0"></td>

                                  
                                    <td >
                                        <div [hidden]="this.tableDatas.controls[i].get('landHolding')?.value == null || this.tableDatas.controls[i].get('landHolding')?.value < 10"  [ngClass]="{ 'is-invalid':submitTable && this.tableDatas.controls[i].get('statusLand')?.errors }">
                                            <input type="radio"  value="agree" name="statusLand{{i}}"   formControlName="statusLand"  [required]="this.tableDatas.controls[i].get('landHolding')?.value != null && this.tableDatas.controls[i].get('landHolding')?.value >= 10 " ><span class="mx-2 radioCls">Agree</span>
                                            <input type="radio"  value="disagree" name="statusLand{{i}}"   formControlName="statusLand" [required]="this.tableDatas.controls[i].get('landHolding')?.value != null  && this.tableDatas.controls[i].get('landHolding')?.value >= 10" ><span class="mx-2 radioCls" >Disagree</span>
                                        </div>
                                            <div *ngIf="submitTable && this.tableDatas.controls[i].get('statusLand')?.errors" class="invalid-feedback">
                                                <div *ngIf="this.tableDatas.controls[i].get('statusLand')?.errors?.required ">Please enter Agree/Disagree</div>
                                            </div>
                                        
                                     </td>

                                     <td>
                                        <span *ngIf="this.tableDatas.controls[i].get('muncipalProperty')?.value == null">N</span>
                                        <input type="text" class="inputCls clsname" name="muncipalProperty" id="muncipalProperty"   formControlName = "muncipalProperty" *ngIf="this.tableDatas.controls[i].get('muncipalProperty')?.value != null">
                                    </td>
                                    <td >
                                        <div [hidden]="this.tableDatas.controls[i].get('muncipalProperty')?.value == null || this.tableDatas.controls[i].get('muncipalProperty')?.value < 1000"  [ngClass]="{ 'is-invalid':submitTable && this.tableDatas.controls[i].get('statusMunicipal')?.errors }">
                                        <input type="radio"  value="agree" name="statusMunicipal{{i}}" formControlName="statusMunicipal" [required]="this.tableDatas.controls[i].get('muncipalProperty')?.value != null && this.tableDatas.controls[i].get('muncipalProperty')?.value >= 1000" ><span class="mx-2 radioCls">Agree</span>
                                        <input type="radio"  value="disagree" name="statusMunicipal{{i}}" formControlName="statusMunicipal" [required]="this.tableDatas.controls[i].get('muncipalProperty')?.value != null  && this.tableDatas.controls[i].get('muncipalProperty')?.value >= 1000"><span class="mx-2 radioCls">Disagree</span>
                                     </div>
                                     <div *ngIf="submitTable && this.tableDatas.controls[i].get('statusMunicipal')?.errors" class="invalid-feedback">
                                        <div *ngIf="this.tableDatas.controls[i].get('statusMunicipal')?.errors?.required ">Please enter Agree/Disagree</div>
                                    </div>
                                     </td>

                                     <!-- <td><input type="text" class="inputCls clsname" name="muncipalProperty" id="name"   formControlName = "muncipalProperty" readonly></td> -->
                                    <!-- <td><input type="text" class="inputCls clsname" name="electricity" id="name"   formControlName = "electricity"></td> -->
                                    <td><span *ngIf="this.tableDatas.controls[i].get('electricity')?.value == null">N</span>
                                        <span *ngIf="this.tableDatas.controls[i].get('electricity')?.value != null"><input type="text" class="inputCls clsname" name="electricity" id="electricity"   formControlName="electricity"></span>
                                    </td>

                                  
                                     <td>
                                        <div [hidden]="this.tableDatas.controls[i].get('electricity')?.value == null || this.tableDatas.controls[i].get('electricity')?.value < 300"  [ngClass]="{ 'is-invalid':submitTable && this.tableDatas.controls[i].get('statusElectricity')?.errors }">
                                        <input type="radio"  value="agree" name="statusElectricity{{i}}" formControlName="statusElectricity" [required]="this.tableDatas.controls[i].get('electricity')?.value != null && this.tableDatas.controls[i].get('electricity')?.value >= 300" ><span class="mx-2 radioCls">Agree</span>
                                        <input type="radio"  value="disagree" name="statusElectricity{{i}}" formControlName="statusElectricity" [required]="this.tableDatas.controls[i].get('electricity')?.value != null  && this.tableDatas.controls[i].get('electricity')?.value >= 300"><span class="mx-2 radioCls">Disagree</span>
                                     </div>
                                     <div *ngIf="submitTable && this.tableDatas.controls[i].get('statusElectricity')?.errors" class="invalid-feedback">
                                        <div *ngIf="this.tableDatas.controls[i].get('statusElectricity')?.errors?.required ">Please enter Agree/Disagree</div>
                                    </div>
                                     </td>
                            
                                     <!-- <td><select class="selectCls clsname" name="migration"  id="migration"  formControlName = "migration" >
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                
                                      </select>
                                    </td>
                
                                    <td><input type="text" class="inputCls" name="income" id="income" formControlName = "income"  >  -->
                                
                              
                               
                                <!-- <td>
                                    <input type="radio"  value="agree" formControlName="status"><span class="mx-2 radioCls">Agree</span>
                                    <input type="radio"  value="disagree" formControlName="status" ><span class="mx-2 radioCls">Disagree</span>
                                </td> -->
            
                               
            
                            </tr>
                          
            
                        </tbody>
                        <!-- <tbody>
                            <tr>
                                <td colspan="9" class="text-center">No requests are pending.</td>
                            </tr>
                        </tbody> -->
                    </table>
                <!-- </div> -->
                </div>
                    
            </div>
            <!-- </div> -->
                <!-- <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length"
                    [(page)]="page" [pageSize]="pageSize" [maxSize]="5" [rotate]="true" [ellipses]="false"
                    [boundaryLinks]="true"></ngb-pagination> -->
            </div>
            <div class="certificateInfo" *ngIf="showCluster && departmentCode === 'CS'  && sixStepGridData != ''&& !isFpShopApplication">
    
                <div >
                    <table class="printTable">
                        <thead>
                            <tr class="printRow"> 
                                <th class="spacw30">FOUR WHEELER</th>
                                <th class="spacw10">Please Tick (✔)</th>
                                <th class="spacw30">FAMILY LAND HOLDINGS</th>
                                <th class="spacw10">Please Tick (✔)</th>
                            </tr>
    
                        </thead>
                        <tbody>
                            <tr class="printRow">
                                <td class="printTb">
                                    1.The Personal Vehicle not in the name of applicant / familymembers.
                                </td >
                                <td  class="printTb"></td>
                                <td  class="printTb">1.The extents of land details are not correct.</td>
                                <td  class="printTb"></td>
                            </tr>
                            <tr>
                                <td  class="printTb">
                                    2.The Taxi vehicle in the name of applicant / family members.
                                </td>
                                <td  class="printTb"></td>
                                <td class="printTb">2.The land details not updated and the totalextent of the land is less than 3.00 Ac.Wet
                                    (or) 10 Ac. Dry or both together 10Ac.</td>
                                <td class="printTb"></td>
                            </tr>
                            <tr>
                                <td  class="printTb">
                                    3.The Vehicle was in the name of the applicant / family member but sold to other person
                                    notupdated in the RTA database
                                </td>
                                <td class="printTb"></td>
                                <td  class="printTb">3. Applicant or any Family Member do not own any land.</td>
                                <td class="printTb"></td>
                            </tr>
                            <tr>
                                <td  class="printTb">
                                    4.The Vehicle never belonged to applicant/family member and RTA database is not updated.
                                </td>
                                <td class="printTb"></td>
                                <td class="printTb"></td>
                                <td class="printTb"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    
                <br>
                <div>
                    <table class="printTable">
                        <thead>
                            <tr>
                                <th class="spacw30">ELECTRICTY CONSUMPTION</th>
                                <th class="spacw10">Please Tick (✔)</th>
                                <th class="spacw30">MUNCIPAL PROPERTY DETAILS</th>
                                <th class="spacw10">Please Tick (✔)</th>
                            </tr>
    
                        </thead>
                        <tbody>
                            <tr>
                                <td class="printTb">
                                    1.The meter connection is on the name of applicant /family members & average electricity
                                    consumptionfor last six months is less than 300 units.
                                </td>
                                <td class="printTb"></td>
                                <td class="printTb">1. Applicant having property less than 1000Sq.ft. with assessment no._____________.</td>
                                <td class="printTb"></td>
                            </tr>
                            <tr>
                                <td class="printTb">
                                    2.The meter connection is on the name of the property owner, but the average consumption
                                    of the applicant for the last six months is less than 300 units.
                                </td>
                                <td class="printTb"></td>
                                <td class="printTb">2. Ancestral property . The Applicant is legal heir and would get less than 1000Sq.Ft. if
                                    mutated / sub divided.</td>
                                <td class="printTb"></td>
                        </tbody>
                    </table>
                </div>
                 
            </div>
            <div  *ngIf="showCSdata" >
                <div class="row"  *ngIf="departmentCode === 'CS' && this.showInfo && !isFpShopApplication"> 
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 ml-5"  >
                        <label class="labelCls">Social Audit:<span class="mandatoryCls">*</span></label>
                        <select class="selectCls" formControlName="socialAudit"
                        [ngClass]="{ 'is-invalid':submitTable && (k.socialAudit.errors || k.socialAudit.value === 'Select')}"  >
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="consent">Consent</option>
                        <option value="nonconsent">Non-Consent</option>
        
                    </select>
                    <div *ngIf="submitTable && (k.socialAudit.errors || k.socialAudit.value === 'Select')" class="selectError">
                        <div *ngIf="k.socialAudit.errors?.required || k.socialAudit.value === 'Select'">Please select Social Audit</div>
                    </div>
                    </div>
                </div>
            </div>
             

            </form>

    
            
           <!-- FP-SHop renewel application for VRO start -->
             <div class="certificateInfo mt-4" *ngIf="isFpShopApplication && (role === 'Village Revenue Officer'  || role === 'Ward Revenue Secretary')">
            <hr class="hrCls">
                <form [formGroup]="fpShopForm">
                    <div class="subHeadingCls mb-3">VRO/WRS Checklist</div>
                <div class="row mb-3">
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <label class="labelCls photolbl">Latitude and Longitude of FP Shop :<span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <input type="text" name="lat" class="inputCls" formControlName="latofFpShop"  placeholder="00.0000" 
                    [required] = "(role === 'Village Revenue Officer'  || role === 'Ward Revenue Secretary') && (certificateName === 'FPSHOPRENEW-VSKP' || certificateName === 'FPSHOPRENEW-OTH' || certificateName === 'FPSHOPRENEW')"                  
                    [ngClass]="{ 'is-invalid':submitted && fp.latofFpShop.errors }" maxlength="20" appBlockCopyPaste>
                   
                    <div *ngIf="submitted && fp.latofFpShop.errors" class="invalid-feedback">
                        <div *ngIf="fp.latofFpShop.errors.required ">Latitude of FP Shop is required</div>
                        <div *ngIf="fp.latofFpShop.errors?.pattern">Please enter valid Latitude</div>
                    </div>
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <input type="text" name="long" class="inputCls" formControlName="longofFpShop"  placeholder="00.0000"
                    [required] = "(role === 'Village Revenue Officer'  || role === 'Ward Revenue Secretary') && (certificateName === 'FPSHOPRENEW-VSKP' || certificateName === 'FPSHOPRENEW-OTH' || certificateName === 'FPSHOPRENEW')"                  
                    [ngClass]="{ 'is-invalid':submitted && fp.longofFpShop.errors }" maxlength="20" appBlockCopyPaste>
                   
                    <div *ngIf="submitted && fp.longofFpShop.errors" class="invalid-feedback">
                        <div *ngIf="fp.longofFpShop.errors.required ">Longitude of FP Shop is required</div>
                        <div *ngIf="fp.longofFpShop.errors?.pattern">Please enter valid Longitude</div>
                    </div>
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <p class="labelCls photolbl" style="color:blue">(Andhra Pradesh latitude range (12.41 - 19.07) and <br>longitude range (77 - 84.40) )</p>
                </div>
                 </div>
                 <div class="row">
                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <label class="labelCls photolbl">FP shop is in same location :<span class="mandatoryCls">*</span></label>
                       </div>

                        <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 valueCls">
                        <input id="YES" type="radio"  [required] = "(role === 'Village Revenue Officer'  || role === 'Ward Revenue Secretary') && (certificateName === 'FPSHOPRENEW-VSKP' || certificateName === 'FPSHOPRENEW-OTH' || certificateName === 'FPSHOPRENEW')" formControlName="isSameLocation" value="YES" class="radioCls mr-2">
                        <label for="YES">Yes</label>
                        <input id="NO" type="radio"  [required] = "(role === 'Village Revenue Officer'  || role === 'Ward Revenue Secretary') && (certificateName === 'FPSHOPRENEW-VSKP' || certificateName === 'FPSHOPRENEW-OTH' || certificateName === 'FPSHOPRENEW')" formControlName="isSameLocation" value="NO" class="radioCls mx-2">
                        <label for="No">No</label>
                       
                        <div class="selectError" *ngIf="submitted && fp.isSameLocation.errors">
                            <div *ngIf="submitted && fp.isSameLocation.errors?.required">Please select FP shop is in same location or not</div>
                        </div>
                    </div>
                    </div>

                <div class="row mt-3">                  
                    
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label class="labelCls photolbl" for="fpShopphoto">FP Shop Photograph(Supported docs are .jpg, .jpeg & .png, Size in 1 MB) :<span class="mandatoryCls">*</span></label>
                       
                        <div class="input-group mb-3">
                            <div class="custom-file">
                            <input type="file" class="custom-file-input" formControlName="fpShopPhoto" id="fpShopphoto" accept=".jpg, .jpeg , .png" name="fpShopPhoto"
                            [required] = "(role === 'Village Revenue Officer'  || role === 'Ward Revenue Secretary') && (certificateName === 'FPSHOPRENEW-VSKP' || certificateName === 'FPSHOPRENEW-OTH' || certificateName === 'FPSHOPRENEW')"
                            [ngClass]="{ 'is-invalid':submitted && fp.fpShopPhoto.errors }" (change)="onfpShopPhotoSelected($event)">
                            <label class="custom-file-label" #fpShopPhotoLabel for="fpShopPhoto"><i class="fas fa-search"></i>No File choosen</label>
                            </div>
                            <div class="clearCls" (click)="clearFileUpload('fpShopPhoto', 'fpShopPhotoLabel')" *ngIf="fp.fpShopPhoto.value|| isPhoto=== true"></div>
                        </div>
                        <div *ngIf="submitted && fp.fpShopPhoto.errors" class="selectError mt-30">
                            <div *ngIf="fp.fpShopPhoto.errors.required">Please FP Shop Photograph</div>
                            <div *ngIf="fp.fpShopPhoto?.errors?.inValidExt && !fp.fpShopPhoto.errors.required ">Please upload the file in PNG/JPG/JPEG Format</div>
                            <div *ngIf="fp.fpShopPhoto.errors?.inValidSize && !fp.fpShopPhoto.errors.required && !fp.fpShopPhoto.errors.inValidExt">File size exceeding 1 MB</div> 
                        </div>
                   
                </div>
                
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div *ngIf="showImg && imgSrc !== '' ">
                            <img [src]="imgSrc" class="mt-3 img-fluid" width="200" height="140">
                        </div>

                    </div>
                </div>
               
                    
            
             </form>

            </div>
            <!-- fpShop end for VRO -->

            <!-- fpShop Tehsildar/ASO and RDO/DSO Start-->

            <div class="certificateInfo mt-4" *ngIf="(role === 'Tahsildar' || role === 'Assistant Civil Supplies Officer' || role === 'Revenue Divisonal Officer' || role === 'District Civil Supplies Officer') && isFpShopApplication">
              <hr class="hrCls">  
              <div class="subHeadingCls mb-3" *ngIf="(role === 'Tahsildar' || role === 'Assistant Civil Supplies Officer')">VRO/WRS Checklist</div>
              <div class="subHeadingCls mb-3" *ngIf="(role === 'Revenue Divisonal Officer' || role === 'District Civil Supplies Officer')">VRO/WRS Checklist and Tahsildar/ASO Recommendation</div>
                <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="VROHistory && VROHistory.length > 0">
                    <!-- <label class="labelCls subHeadingCls">Village Surveyor Checklist:</label> -->
                    <div class="fieldHolder">
                        <label class="labelCls">Latitude and Longitude of FP Shop:</label>
                        <div class="valueCls">{{VROHistory[0]?.fpShopData?.latofFpShop}} ,  {{VROHistory[0]?.fpShopData?.longofFpShop}}</div>                 
                       </div>                    
                    <div class="fieldHolder">
                        <label class="labelCls">FP Shop is in Same Location or Not:</label>
                        <div class="valueCls">{{VROHistory[0]?.fpShopData?.isSameLocation}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Recommendation of VRO/WRS:</label>
                        <div class="valueCls">{{VROHistory[0]?.remarks}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Document Uploaded from VRO/WRS:</label>
                        <!-- <div class="valueCls">{{VROHistory[0]?.docName}}</div> -->
                        <div class="valueCls" *ngIf="VROHistory[0]?.fileList?.length > 0">
                            <div *ngFor="let file of VROHistory[0]?.fileList; let i = index">
                                <div *ngIf="file.documentType === 'verificationDoc'">
                                    <div class="valueCls"><a (click)="viewFile(file?.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                                </div>
                            </div>
                         
                        </div>
                        <div class="valueCls" *ngIf="VROHistory[0].fileList?.length === 0">Not Available</div>
                    </div>
                    <ng-container *ngIf="TEHHistory.length >0">
                        <div class="fieldHolder">
                            <label class="labelCls">Recommendation of Tehsildar/Assistant Civil Supplies Officer:</label>
                            <div class="valueCls">{{TEHHistory[0]?.remarks}}</div>
                        </div>
                        
                        <div class="fieldHolder">
                            <label class="labelCls">Document Uploaded from Tahsildar/Assistant Civil Supplies Officer:</label>
                            <!-- <div class="valueCls">{{TEHHistory[0]?.docName}}</div> -->
                            <div class="valueCls" *ngIf="TEHHistory[0]?.fileList?.length > 0">
                                <div *ngFor="let files of TEHHistory[0]?.fileList; let i = index">
                                    <div *ngIf="files.documentType === 'verificationDoc'">
                                        <div class="valueCls"><a (click)="viewFile(files?.blobRef)">{{files.fileName}}</a><span (click)="downloadFile(files)" class="download ml-2"></span></div>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="valueCls" *ngIf="TEHHistory[0].fileList?.length === 0">Not Available</div>
                        </div>    
                    </ng-container>
                </div>
            </div>
            
                <div class="row">
                    
                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12"><label class="labelCls photolbl" >FP Shop Photograph:</label></div>
                
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div *ngIf="showImg && imgSrc !== '' ">
                            <img [src]="imgSrc" class="img-fluid">
                        </div>  
                    </div>
                
                
               
            </div>
            
        </div>

           <!-- fpShop Tehsildar/ASO and RDO/DSO End-->
            
    
            
            <!-- </div> -->
        <div class="certificateInfo checklistCls mt-3">
            <div class="row">

 <!-- rectification start -->

<!-- MANDAL SURVEYOR -->

<ng-container *ngIf="role === 'Mandal surveyor' && certificateName === 'RectificationOfResurveyedRecords'">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="VSHistory && VSHistory.length > 0">
            <label class="labelCls subHeadingCls">Village Surveyor Checklist:</label>
            <!-- <div class="fieldHolder">
                <label class="labelCls">Observed Grievance Type:</label>
                <div class="valueCls">{{VSHistory[0]?.rectificationData?.ObservedGrievanceType}}</div>
            </div> -->
            <div class="fieldHolder custom-flex">
                <label class="labelCls ">Remarks by Village Surveyor:</label>
                <div class="valueCls">{{VSHistory[0]?.rectificationData?.RemarksbyVillageSurveyor}}</div>
          </div>
            <div class="fieldHolder custom-flex">
                <label class="labelCls">Recommendation:</label>
                <div class="valueCls">{{VSHistory[0]?.remarks}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Document Uploaded: <span class="mandatoryCls">*</span></label>
                <div class="valueCls"><a (click)="viewFile(VSHistory[0]?.fileList[0]?.blobRef)">{{VSHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(VSHistory[0]?.fileList[0])" class="download ml-2"></span></div>
            </div>
        </div>
</ng-container>
<!-- TAHSILDHAR -->
<ng-container *ngIf="role === 'Tahsildar' && certificateName === 'RectificationOfResurveyedRecords'">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4" *ngIf="VSHistory && VSHistory.length > 0">
        <label class="labelCls subHeadingCls">Village Surveyor Checklist:</label>
        <!-- <div class="fieldHolder">
            <label class="labelCls">Observed Grievance Type:</label>
            <div class="valueCls">{{VSHistory[0]?.rectificationData?.ObservedGrievanceType}}</div>
        </div> -->
        <div class="fieldHolder custom-flex">
            <label class="labelCls">Remarks by Village Surveyor:</label>
            <div class="valueCls">{{VSHistory[0]?.rectificationData?.RemarksbyVillageSurveyor}}</div>
        </div>
        <div class="fieldHolder custom-flex">
            <label class="labelCls">Recommendation:</label>
            <div class="valueCls">{{VSHistory[0]?.remarks}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Document Uploaded:</label>
            <div class="valueCls"><a (click)="viewFile(VSHistory[0]?.fileList[0]?.blobRef)">{{VSHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(VSHistory[0]?.fileList[0])" class="download ml-2"></span></div>
        </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4" *ngIf="VSHistory && VSHistory.length > 0">
        <label class="labelCls subHeadingCls">Mandal Surveyor Checklist:</label>
        <!-- <div class="fieldHolder">
            <label class="labelCls">Observed Grievance Type:</label>
            <div class="valueCls">{{MSHistory[0]?.rectificationData?.ObservedGrievanceType}}</div>
        </div> -->
        <div class="fieldHolder custom-flex">
            <label class="labelCls">Remarks by Mandal Surveyor:</label>
            <div class="valueCls">{{MSHistory[0]?.rectificationData?.RemarksbyVillageSurveyor}}</div>
        </div>
        <div class="fieldHolder custom-flex">
            <label class="labelCls">Recommendation:</label>
            <div class="valueCls">{{MSHistory[0]?.remarks}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Document Uploaded:</label>
            <div class="valueCls"><a (click)="viewFile(MSHistory[0]?.fileList[0]?.blobRef)">{{MSHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(MSHistory[0]?.fileList[0])" class="download ml-2"></span></div>
        </div>
    </div>
</ng-container>

<!-- MANDAL , VILLAGE AND TAHSILDHAR SURVEYOR -->
<ng-container *ngIf="(role === 'Village surveyor' || role === 'Mandal surveyor'|| role === 'Tahsildar' )&& certificateName === 'RectificationOfResurveyedRecords'">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2 mt-5">
        <form [formGroup]="rectificationForm">
            <div class="subHeading mb-2 subHeadingCls captilize">{{ role }} Checklist</div>
         
          
            <div class="fieldHolder mb-2 ">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2 mt-5">
                <label class="labelCls captilize">Remarks by {{ role }}:<span class="mandatoryCls">*</span></label>
                <div class="valueCls ">
                    <textarea type="text" name="remarksByVS" class="text-areaCls " formControlName="remarksByVS"
                    rows="3"></textarea>
                </div>
                <div *ngIf="xyz.remarksByVS.errors" class="selectError">
                    <div *ngIf="xyz.remarksByVS.errors.required || xyz.remarksByVS.errors.whiteSpaceValidate">Please enter remarks.</div>
                </div> 
                </div>  
            </div>
        </form>
    </div>
</ng-container>
         <!-- rectification end -->


                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4" *ngIf ="certificateName !== 'IntegratedCertificateForStudents' && certificateName !== 'IncomeCertificateForStudents' && certificateName !== 'MarriageCertificate' && certificateName !== 'MarriageCertificate-Rural' && certificateName !== 'AddressCertificateForAadharEnrollment@1' && certificateName !== 'AddressCertificateForAadharEnrollment@3'">
                <ng-container  *ngIf="checklist && checklist.length > 0">
                  
                <ng-container *ngIf="((role === 'Village Revenue Officer' || role == 'Revenue Divisonal Officer' || role === 'Collector' || role === 'Ward Revenue Secretary') && checklist?.length > 0) || (VROHistory && VROHistory.length > 0  && VROHistory[0]?.list?.length > 0)">
                <div class="subHeadingCls mb-3">VRO/WRS Checklist</div>
                <div class="fieldHolder">
                    <label class="labelCls">Applicant Name:</label>
                    <div class="valueCls">{{basicInfo?.applicantInfo?.personNames[0]?.firstName}} {{basicInfo?.applicantInfo?.personNames[0]?.middleName}} {{basicInfo?.applicantInfo?.personNames[0]?.lastName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Applicant Address:</label>
                    <div class="valueCls mb-2">
                        <!-- {{presentAddr[0]?.streetName}} -->
                        <div>Door No & Street Name: {{presentAddr[0]?.doorNo}}</div>
                        <div>Village/ward: {{commonFormAddedFields.presentVillage}}, Mandal: {{commonFormAddedFields.presentMandal}}</div>
                        <div>District: {{commonFormAddedFields.presentDistrict}}</div>
                    </div>
                </div>
                </ng-container>
                <ng-container *ngIf="checklist && checklist.length > 0 && ((role === 'Village Revenue Officer' || role === 'Ward Revenue Secretary') && certificateName !== 'AssignmentofLandstoEx-ServicepersonServingSoldiers')" >
                <form  [formGroup]="checklistForm">
                <div class="fieldHolder" *ngFor="let item of checklist" >
                    <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                    <div class="valueCls">
                    <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                    <label for="{{item.id}}_Y">Yes</label>
                    <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                    <label for="{{item.id}}_N">No</label>
                    </div>
                    <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                        <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                    </div>
                </div>
                </form>
                </ng-container>
                <ng-container *ngIf="(certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && ((role == 'Tahsildar') || (role == 'Revenue Divisonal Officer') || (role == 'Sub Collector') || (role == 'Joint Collector')))">
                   
                    <div class="fieldHolder">
                        <label class="labelCls"> Is the vacant Land available for grant of assignment in Sy Number/LP Number 
                            proposed by the Applicant in the Village: <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                                <input id="landavailableforgrant_Y" type="radio" value="Yes" class="radioCls mr-2"
                                    [checked] ="zswovrodata?.landavailableforgrant == 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                                <label for="landavailableforgrant_Y">Yes</label>
                                <input id="landavailableforgrant_N" type="radio" value="No" class="radioCls mx-2"
                                    [checked] ="zswovrodata?.landavailableforgrant === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                                <label for="landavailableforgrant_N">No</label>
                        </div> 
                    </div>
                    <div class="fieldHolder" *ngIf="zswovrodata?.landavailableforgrant === 'Yes'">
                        <label class="labelCls" *ngIf="isResurveyVillage">Proposed LP Number by the Applicant:</label>
                        <label class="labelCls" *ngIf="!isResurveyVillage">Proposed Sy Number by the Applicant:</label>
                        <div class="valueCls">{{ (certificateInfo?.lpNoSYNo) ? (certificateInfo?.lpNoSYNo) : 'Not Available' }}</div>
                    </div>
                    <div class="fieldHolder" *ngIf="zswovrodata?.landavailableforgrant === 'No'">
                        <label class="labelCls"> Is vacant land available in any other Sy Number/LP Number in the village for grant 
                            of assignment: <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                                <input id="vacantland" type="radio" value="Yes" class="radioCls mr-2"
                                    [checked] ="zswovrodata?.vacantland == 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                                <label for="vacantland">Yes</label>
                                <input id="vacantland_N" type="radio" value="No" class="radioCls mx-2"
                                    [checked] ="zswovrodata?.vacantland === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                                <label for="vacantland_N">No</label>
                        </div> 
                    </div>
                    <div class="fieldHolder" *ngIf="zswovrodata?.remarksByVroZswo">
                        <label class="labelCls"> Remarks: <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">{{zswovrodata?.remarksByVroZswo}}</div>
                    </div>
                    <div class="fieldHolder" *ngIf="(zswovrodata?.landavailableforgrant == 'Yes' || zswovrodata?.vacantland == 'Yes')">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab table-responsive">
                                        <table class="tableCls">
                                            <thead>
                                                <tr>
                                                    <th *ngIf="isResurveyVillage">LP Number</th>
                                                    <th *ngIf="!isResurveyVillage">Sy Number</th>
                                                    <th>Khata No</th>
                                                    <th>Land Nature </th>
                                                    <th>Land Classification </th>
                                                    <th *ngIf="isResurveyVillage">LP Number Extent (Acrs-Cents) </th>
                                                    <th *ngIf="!isResurveyVillage">Sy Number Extent (Acrs-Cents) </th>
                                                    <th>Occupant Extent (Acrs-Cents) </th>
                                                    <th>Proposed Extent (Acrs-Cents)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                <tr class="text-center" *ngFor="let data of zswovrodata?.tableData">
                                    <td *ngIf="!isResurveyVillage">{{ (data?.Survey_No) ? (data?.Survey_No) : "-"}}</td>
                                    <td *ngIf="isResurveyVillage">{{ (data?.LPMNO) ? (data?.LPMNO) : "-"}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ (data?.khata_no) ? (data?.khata_no) : "-" }}</td>
                                    <td *ngIf="isResurveyVillage">{{(data?.KhataNo) ? (data?.KhataNo) : "-"}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ (data?.land_nature) ? (data?.land_nature) : "-" }} </td>
                                    <td *ngIf="isResurveyVillage">{{ (data?.LandNature) ? (data?.LandNature) : "-"}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ (data?.land_classification) ? (data?.land_classification) : "-"}}</td>
                                    <td *ngIf="isResurveyVillage">{{ (data?.LandCalssification) ? (data?.LandCalssification) : "-"}}</td>
                                    <td *ngIf="isResurveyVillage">{{(data?.LPM_Extent) ? (data?.LPM_Extent) : "-"}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ (data?.Total_Extent) ? (data?.Total_Extent) : "-"}}</td>
                                    <td *ngIf="isResurveyVillage">{{ (data?.LPM_Extent) ? (data?.LPM_Extent) : "-"}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ (data?.Occupant_Extent) ? (data?.Occupant_Extent) : "-"}}</td>
                                     <td>{{ (data?.proposedextentvalue) ? (data?.proposedextentvalue) : "-"}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                    </div>
                </ng-container>
                <!-- pension -->
                <ng-container *ngIf="(role ==='Ward Welfare & Development Secretary'|| role ==='Welfare & Educational Assistant') && checklist?.length > 0 || (WEAWWDSHistory && WEAWWDSHistory.length > 0  && WEAWWDSHistory[0]?.list?.length > 0)">
                    <div class="subHeadingCls mb-3">WEA/WWDS Checklist</div>
                    <div class="fieldHolder">
                        <label class="labelCls">Applicant Name:</label>
                        <div class="valueCls">{{basicInfo?.applicantInfo?.personNames[0]?.firstName}} {{basicInfo?.applicantInfo?.personNames[0]?.middleName}} {{basicInfo?.applicantInfo?.personNames[0]?.lastName}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Applicant Address:</label>
                        <div class="valueCls mb-2">
                            <div>Door No & Street Name: {{presentAddr[0]?.doorNo}}</div>
                            <div>Village/ward: {{commonFormAddedFields.presentVillage}}, Mandal: {{commonFormAddedFields.presentMandal}}</div>
                            <div>District: {{commonFormAddedFields.presentDistrict}}</div>
                        </div>
                    </div>
                    </ng-container>
                    <ng-container *ngIf="(role ==='Ward Welfare & Development Secretary'|| role ==='Welfare & Educational Assistant') && checklist?.length > 0 || (WEAWWDSHistory && WEAWWDSHistory.length > 0  && WEAWWDSHistory[0]?.list?.length > 0)">
                        <div class="valueCls">
                            <div *ngIf="departmentCode === 'RD'">Please check whether the parameters shown in the above table are correct - Click Yes if correct, Click No if incorrect:</div>
                      </div>
                        </ng-container>
                <!-- showing initial login checklist -->
                    <ng-container *ngIf="checklist && checklist.length > 0 && role ==='Ward Welfare & Development Secretary'" >
                    <form  [formGroup]="checklistForm">
                    <div class="fieldHolder" *ngFor="let item of checklist" >
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                            <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    </form>
                    </ng-container>
                    <ng-container *ngIf="checklist && checklist.length > 0 && role ==='Welfare & Educational Assistant'" >
                        <form  [formGroup]="checklistForm">
                        <div class="fieldHolder" *ngFor="let item of checklist" >
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                            <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                                <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                            </div>
                        </div>
                        </form>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="departmentCode === 'RD' && (role ==='Ward Welfare & Development Secretary' || role ==='Welfare & Educational Assistant')" >
                        <ng-container [ngTemplateOutlet]="handloomWeaverTemplate"  *ngIf="certificateName === 'Pensions@2' || certificateName === 'Pensions@V2' || certificateName === 'Pensions-NEW@2' || certificateName === 'Pensions-NEW@V2'"></ng-container>
                        <ng-container [ngTemplateOutlet]="fisheriesTemplate"  *ngIf="certificateName === 'Pensions@7' || certificateName === 'Pensions@V7'"> </ng-container>
                        <ng-container [ngTemplateOutlet]="traditionalCobblerTemplate"  *ngIf="certificateName === 'Pensions@9' || certificateName === 'Pensions@V9' || certificateName === 'Pensions-NEW@9' || certificateName === 'Pensions-NEW@V9'"></ng-container>
                        <ng-container [ngTemplateOutlet]="dappuArtistsTemplate"  *ngIf="certificateName === 'Pensions@10' || certificateName === 'Pensions@V10' || certificateName === 'Pensions-NEW@10' || certificateName === 'Pensions-NEW@V10'"> </ng-container>
                    </ng-container>
                <!-- showing initial login checklist -->
                    <!-- showing history  -->
                    <ng-container  *ngIf="WEAWWDSHistory && WEAWWDSHistory.length > 0">
                        <div class="subHeadingCls mb-3">WWDS/WEA Checklist</div>
                        <div class="fieldHolder" *ngFor="let item of WEAWWDSHistory[0]?.list" >
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                        </div>
                        <ng-container  *ngIf="certificateName === 'Pensions@2' || certificateName === 'Pensions@V2' || certificateName === 'Pensions-NEW@2' || certificateName === 'Pensions-NEW@V2'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Whether Loom is present in the House?<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes'">
                                <label class="labelCls">Type of Loom <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.loomType}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes'">
                                <label class="labelCls">Loom is working condition? <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes' && WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'Yes'">
                                <label class="labelCls">Photo with GPS coordinates <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingConditionDocument?.blobRef)">{{WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingConditionDocument?.fileName}}</a><span (click)="downloadFile(WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingConditionDocument)" class="download ml-2"></span></div>
                            </div>
                            <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes' && WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'Yes'">
                                <div class="fieldHolder">
                                    <label class="labelCls">From how many years He/she is depending on weaving profession (at least from last Two years)? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.noOfYearsIntoWeaving}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Working Under <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Any Household benefited under Nethanna Nestham? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.benifittedUnderNethannaNestham}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Whether any of the Member of the family already getting weaver pension? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.receivingWeaverPension}}</div>
                                </div>
                                <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.receivingWeaverPension === 'Yes'">
                                    <label class="labelCls">Pension id of the family member <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{WEAWWDSHistory[0]?.handloomWeaverData?.pensionId}}</div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container  *ngIf="certificateName === 'Pensions@7' || certificateName === 'Pensions@V7'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Beneficiary Depending on Fishing Profession <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.isFishingProfession}}</div>
                            </div>
                            <ng-container *ngIf="WEAWWDSHistory[0]?.fisheriesData?.isFishingProfession === 'Yes'">
                                <div class="fieldHolder">
                                    <label class="labelCls">How many years he/she is in the Fishing Profession. ? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.noOfYears}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Type of Fishermen <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.typeOfFishermen}}</div>
                                </div>
                                <ng-container *ngIf="WEAWWDSHistory[0]?.fisheriesData?.typeOfFishermen === 'Marine'">
                                    <div class="fieldHolder">
                                        <label class="labelCls">Marine FCS Member  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.marineFCSMember}}</div>
                                       
                                    </div>
                                    <ng-container *ngIf="WEAWWDSHistory[0]?.fisheriesData?.marineFCSMember === 'Yes'">
                                    <div class="fieldHolder" >
                                        <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.marineAdmissionNo}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.marineDateOfAdmission}}</div>
                                    </div>
                                    </ng-container>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Possess Valid Bio Metric ID Card  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.validBiometricId}}</div>
                                    </div>
                                    <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.fisheriesData?.validBiometricId === 'Yes'">
                                        <label class="labelCls">MFID No <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.mfidNo}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Availed Ban Period Relief  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.banOnFishingProf}}</div>
                                       
                                    </div>
                                    <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.fisheriesData?.banOnFishingProf === 'Yes'">
                                        <label class="labelCls">Copy of Bank entry <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls"><a (click)="viewFile(WEAWWDSHistory[0]?.fisheriesData?.copyOfBankEntry?.blobRef)">{{WEAWWDSHistory[0]?.fisheriesData?.copyOfBankEntry?.fileName}}</a><span (click)="downloadFile(WEAWWDSHistory[0]?.fisheriesData?.copyOfBankEntry)" class="download ml-2"></span></div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="WEAWWDSHistory[0]?.fisheriesData?.typeOfFishermen === 'Inland'">
                                    <div class="fieldHolder">
                                        <label class="labelCls">Inland FCS Member ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.inlandFCSMember}} </div>
                                    </div>
                                    <ng-container *ngIf="WEAWWDSHistory[0]?.fisheriesData?.inlandFCSMember === 'Yes'">
                                    <div class="fieldHolder" >
                                        <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.inlandAdmissionNo}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">No of Tanks under FCS<span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.inlandNoOfTanks}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Extent under FCS <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.inlandExtent}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls"> {{WEAWWDSHistory[0]?.fisheriesData?.inlandDateOfAdmission}}</div>
                                    </div>
                                    </ng-container>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Individual License holder  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{WEAWWDSHistory[0]?.fisheriesData?.individualLicenceHolder}}</div>
                                    </div>
                                    <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.fisheriesData?.individualLicenceHolder === 'Yes'">
                                        <label class="labelCls">Copy of the license issued <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls"><a (click)="viewFile(WEAWWDSHistory[0]?.fisheriesData?.copyOfLicence?.blobRef)">{{WEAWWDSHistory[0]?.fisheriesData?.copyOfLicence?.fileName}}</a><span (click)="downloadFile(WEAWWDSHistory[0]?.fisheriesData?.copyOfLicence)" class="download ml-2"></span></div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container  *ngIf="certificateName === 'Pensions@9' || certificateName === 'Pensions@V9' || certificateName === 'Pensions-NEW@9' || certificateName === 'Pensions-NEW@V9'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Whether the cobbling is the main occupation of the applicant without any principal source of income?<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.traditionalCobblerData?.isCobblingMainOcc}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether beneficiary having his own cobbler shop?<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.traditionalCobblerData?.isHavingCobblerShop}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.traditionalCobblerData?.isHavingCobblerShop === 'Yes'">
                                <label class="labelCls">Cobbler Shop Address<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.traditionalCobblerData?.cobblingAddress}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.traditionalCobblerData?.isHavingCobblerShop === 'No'">
                                <label class="labelCls">If there is no shop, where does he perform the cobbling activity<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.traditionalCobblerData?.cobblingAddress}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">From how many years he is in cobbler occupation.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.traditionalCobblerData?.noOfYearsIntoCobbling}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether any of the member of the family already getting Cobbler pension.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.traditionalCobblerData?.receivingCobblerPension}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.traditionalCobblerData?.receivingCobblerPension === 'Yes'">
                                <label class="labelCls">Pension id of the family member<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.traditionalCobblerData?.pensionId}}</div>
                            </div>
                        </ng-container>
                        <ng-container  *ngIf="certificateName === 'Pensions@10' || certificateName === 'Pensions@V10' || certificateName === 'Pensions-NEW@10' || certificateName === 'Pensions-NEW@V10'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Whether Dappu Instrument is in the house <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.dappuArtistsData?.instrumentInhouse}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether the applicant is able to play the Dappu properly atleast for a minute<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.dappuArtistsData?.playDappu}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether beneficiary play Dappu solo or as part of Group<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.dappuArtistsData?.dappuType}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">From how many years he is playing Dappu Instrument.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.dappuArtistsData?.noOfYears}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Date of his last program playing in a public place with date and place<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.dappuArtistsData?.lastProgDate}}, <div>{{WEAWWDSHistory[0]?.dappuArtistsData?.address}}</div></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether any of the member of the family already getting Dappu Artists pension.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.dappuArtistsData?.existPension}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="WEAWWDSHistory[0]?.dappuArtistsData?.existPension === 'Yes'">
                                <label class="labelCls">Pension Id of the family member</label>
                                <div class="valueCls">{{WEAWWDSHistory[0]?.dappuArtistsData?.pensionId}}</div>
                            </div>
                        </ng-container>
                    </ng-container>
                        <!-- showing history -->
                <!-- pension -->

                <ng-container  *ngIf="(VROHistory && VROHistory.length > 0 && (certificateName != 'AssignmentofLandstoEx-ServicepersonServingSoldiers') && VROHistory[0]?.list?.length > 0)">
                    <div class="subHeadingCls mb-3" *ngIf="departmentCode === 'RD'">VRO/WRS Checklist</div>  
                    <div *ngIf="checklist == undefined">
                        <div class="subHeadingCls mb-3" *ngIf="certificateName == 'IntegratedCertificate' && (role == 'Revenue Divisonal Officer' || role == 'Collector' || role == 'Tahsildar')">VRO/WRS Checklist</div> 
                        <div class="fieldHolder">
                            <label class="labelCls">Applicant Name:</label>
                            <div class="valueCls">{{basicInfo?.applicantInfo?.personNames[0]?.firstName}} {{basicInfo?.applicantInfo?.personNames[0]?.middleName}} {{basicInfo?.applicantInfo?.personNames[0]?.lastName}}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Applicant Address:</label>
                            <div class="valueCls mb-2">
                                <!-- {{presentAddr[0]?.streetName}} -->
                                <div>Door No & Street Name: {{presentAddr[0]?.doorNo}}</div>
                                <div>Village/ward: {{commonFormAddedFields.presentVillage}}, Mandal: {{commonFormAddedFields.presentMandal}}</div>
                                <div>District: {{commonFormAddedFields.presentDistrict}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="fieldHolder" *ngFor="let item of VROHistory[0]?.list">
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </ng-container>
                
                </div>

             
                  

              

                
<!---Sumoto Start for VRO-->

<ng-container *ngIf="(role === 'Village Revenue Officer' || role === 'Ward Revenue Secretary') && certificateName === 'IntegratedCertificate-SuoMoto' && f2.remarksReasons.value != 'Not Traceable'">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
        <div class="subHeadingCls mb-3" *ngIf = "role === 'Ward Revenue Secretary'">Checklist By WRS</div>
        <div class="subHeadingCls mb-3 text-left" *ngIf="role === 'Village Revenue Officer'">Checklist By VRO</div>
        <form [formGroup]="sumotoForm">
            <div class="row">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Caste:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                       <select class="selectCls" name="caste" required formControlName="caste" (change)="getSubCaste('oncasteChange')" [ngClass]="{ 'is-invalid':sumotoSubmits && (sumotoFormControls.caste.errors || sumotoFormControls.caste.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let caste of casteCategory" [ngValue]="caste.name">{{caste.name}}</option>
                       </select>    
                    </div>  
                    <div *ngIf="sumotoSubmits && sumotoFormControls.caste.errors" class="selectError">
                        <div *ngIf="sumotoFormControls.caste.errors.required">Please select caste.</div>
                    </div> 
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls captilize">Sub-Caste:<span class="mandatoryCls">*</span></label>
                        <div class="valueCls">  
                           <select class="selectCls" name="subCaste" (change)="sumotoChange()" required formControlName="subCaste" [ngClass]="{ 'is-invalid':sumotoSubmits && (sumotoFormControls.subCaste.errors || sumotoFormControls.subCaste.value === 'Select')}">
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let subcaste of subCasteData" [ngValue]="subcaste" selected>{{subcaste}}</option>
                           </select>    
                        </div>
                        <div *ngIf="sumotoSubmits && sumotoFormControls.subCaste.errors" class="selectError">
                            <div *ngIf="sumotoFormControls.subCaste.errors.required">Please select subcaste.</div>
                        </div>   
                </div>
                
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Religion:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">  
                       <select class="selectCls" name="religion" (change)="sumotoChange()" required formControlName="religion" [ngClass]="{ 'is-invalid':sumotoSubmits && (sumotoFormControls.religion.errors || sumotoFormControls.religion.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let religion of religions" [ngValue]="religion.name" selected>{{religion.name}}</option>
                       </select>    
                    </div>
                    <div *ngIf="sumotoSubmits && sumotoFormControls.religion.errors" class="selectError">
                        <div *ngIf="sumotoFormControls.religion.errors.required">Please select religion.</div>
                    </div>   
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Qualification:</label>
                <select class="selectCls" placeholder = "Select" (change)="sumotoChange()" formControlName="qualification" [ngClass]="{ 'is-invalid':sumotoSubmits && (sumotoFormControls.qualification.errors || sumotoFormControls.qualification.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let qual of qualifications" [ngValue]="qual.name">{{qual.name}}</option>
                </select>
              
              </div>
            
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Revenue village:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">  
                       <select class="selectCls" (change)="sumotoChange()" [required] = "(role === 'Village Revenue Officer' || role === 'Ward Revenue Secretary') && certificateName === 'IntegratedCertificate-SuoMoto'" name="revenuevillage" formControlName="revenuevillage" [ngClass]="{ 'is-invalid':sumotoSubmits && (sumotoFormControls.revenuevillage.errors || sumotoFormControls.revenuevillage.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let revenuevillages of appVillages" [ngValue]="revenuevillages.name">{{revenuevillages.name}}</option>
                       </select>    
                    </div>
                    <div *ngIf="sumotoSubmits && sumotoFormControls.revenuevillage.errors" class="selectError">
                        <div *ngIf="sumotoFormControls.revenuevillage.errors.required">Please select revenue village.</div>
                    </div>   
            </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
                    <label class="labelCls">Father/Husband Name:<span class="mandatoryCls">*</span></label>
                    <input type="text" name="fathername" (input)="sumotoChange()" class="inputCls" formControlName="fathername" appBlockCopyPaste required maxlength="50" 
                        [ngClass]="{ 'is-invalid':sumotoSubmits && sumotoFormControls.fathername.errors }">
                      <div *ngIf="sumotoSubmits && sumotoFormControls.fathername.errors" class="selectError">
                        <div *ngIf="sumotoFormControls.fathername.errors.required || sumotoFormControls.fathername.errors.whiteSpaceValidate">Please enter the relation name</div>
                        <div *ngIf="sumotoFormControls.fathername.errors.pattern">Please enter text only</div>
                      </div>
                  </div>

                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Marital Status:</label>
                    <select class="selectCls" formControlName="maritalStatus" (change)="sumotoChange()" [ngClass]="{ 'is-invalid':sumotoSubmits && (sumotoFormControls.maritalStatus.errors || sumotoFormControls.maritalStatus.value === 'Select')}"> 
                      <option [ngValue]="null" disabled>Select</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Married">Married</option>
                      <option value="Unmarried">Unmarried</option>
                      <option value="Widow">Widow</option>
                      <option value="Single Women(Married)">Single Women(Married)</option>
                    </select>
                  </div>
                 
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
                        <label class="labelCls">Mobile<span class="mandatoryCls">*</span></label>
                        <input type="text" name="mobile" class="inputCls" (input)="sumotoChange()" formControlName="mobile" minlength="10" maxlength="10" numbersOnly [required] = "(role === 'Village Revenue Officer' || role === 'Ward Revenue Secretary')"
                            [ngClass]="{ 'is-invalid':sumotoSubmits && sumotoFormControls.mobile.errors }">
                          <div *ngIf="sumotoSubmits && sumotoFormControls.mobile.errors" class="selectError">
                            <div *ngIf="sumotoFormControls.mobile.errors.required">Please enter mobile number</div>
                            <div *ngIf="sumotoFormControls.mobile.errors.pattern">Please enter valid mobile number</div>
                          </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Email:</label>
                        <input type="email" name="email" formControlName="email" (input)="sumotoChange()" class="inputCls" maxlength="75" appBlockCopyPaste
                        [ngClass]="{ 'is-invalid':sumotoSubmits && sumotoFormControls.email.errors }" [required]="sumotoFormControls.deliveryType?.value === 'Email'">
                        <div *ngIf="sumotoSubmits && sumotoFormControls.email.errors" class="invalid-feedback">
                          <div *ngIf="sumotoFormControls.email.errors?.pattern">Please enter valid Email</div>
                        </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Date of Birth</label>
                        <!-- <div class="input-group">
                          <input class="calendarCls form-control" appBlockCopyPaste required
                                 name="kz"  ngbDatepicker #kz="ngbDatepicker" [minDate]="minDate"
                                 [maxDate]="maxDate" formControlName="dob" (change)="sumotoChange()" (dateSelect)="sumotoChange()"
                                 [ngClass]="{ 'is-invalid':sumotoSubmits && sumotoFormControls.dob.errors }">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="kz.toggle()" type="button"></button>
                          </div>
                        </div>
                        <div *ngIf="sumotoSubmits && sumotoFormControls.dob.errors" class="selectError">
                          <div *ngIf="sumotoFormControls.dob.invalid">Please enter valid date of birth.</div>  
                         </div> -->
                         <div class="input-group">
                            <input class="calendarCls form-control" appBlockCopyPaste
                                   name="kz"  ngbDatepicker #kz="ngbDatepicker" [minDate]="minDate"
                                   [maxDate]="maxDate" formControlName="dob" (change)="sumotoChange()" (dateSelect)="sumotoChange()"
                                   [ngClass]="{ 'is-invalid': sumotoSubmits && sumotoFormControls.dob.errors }" [attr.disabled] = "this.isAuthenticated ? '' : null">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="kz.toggle()" type="button"></button>
                            </div>
                          </div>
                          <div *ngIf="sumotoSubmits && sumotoFormControls.dob.errors" class="selectError">
                            <div *ngIf="sumotoFormControls.dob.invalid">Please enter valid date of birth.</div>  
                           </div> 
                      </div>
                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2">
                        <label class="labelCls">Applicant Status <span class="mandatoryCls">*</span></label>
                      <div class="d-flex">
                        <label class="labelCls">Status of the Head of House Hold</label>
                    <div class="valueCls">
                    <input id="statusofhousehold_DeceasedVRO" required type="radio" formControlName="statusofhouseholdVRO" value="Deceasedvro" class="radioCls mx-2" (change)="sumotoChange()">
                    <label for="statusofhousehold_DeceasedVRO">Deceased</label>
                    <input id="statusofhousehold_AliveVRO" type="radio" required formControlName="statusofhouseholdVRO" value="Alivevro" class="radioCls mx-2" (change)="sumotoChange()">
                    <label for="statusofhousehold_AliveVRO">Alive</label>
                    <div *ngIf="sumotoSubmits && sumotoFormControls.statusofhouseholdVRO.errors" class="selectError mt-30">
                        <div *ngIf="sumotoFormControls.statusofhouseholdVRO.errors.required">Please select appropriate value to confirm.</div>
                    </div>
                    </div>
                    </div>
                        </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1">
                        <label class="labelCls" for="sumotoverificationDoc">Ration Card/EPIC Card/AADHAR Card<span class="mandatoryCls">*</span></label>
                        <div class="input-group">
                            <div class="custom-file">
                            <input type="file" required class="custom-file-input" accept=".pdf" name="sumotoverificationDoc" formControlName = "sumotoverificationDoc"
                            [ngClass]="{ 'is-invalid':sumotoSubmits && sumotoFormControls.sumotoverificationDoc.errors }" (change)="sumotoDocSelected($event)">
                            <label class="custom-file-label" #sumotoverificationDocLabel for="sumotoverificationDoc"><i class="fas fa-search"></i>No File Choosen</label>
                        </div>
                            <div class="clearCls" (click)="clearFileUpload('sumotoverificationDoc', 'sumotoverificationDocLabel')" *ngIf="sumotoFormControls.sumotoverificationDoc.value"></div>
                        </div>
                        <div *ngIf="sumotoSubmits && sumotoFormControls.sumotoverificationDoc.errors" class="selectError mt-30">
                            <div *ngIf="sumotoFormControls.sumotoverificationDoc.errors.required">Please Upload Document</div>
                            <div *ngIf="sumotoFormControls.sumotoverificationDoc?.errors?.inValidExt && !sumotoFormControls.sumotoverificationDoc.errors.required ">Please upload the file in PDF Format</div>
                            <div *ngIf="sumotoFormControls.sumotoverificationDoc.errors?.inValidSize && !sumotoFormControls.sumotoverificationDoc.errors.required && !sumotoFormControls.sumotoverificationDoc.errors.inValidExt">File size exceeding 1 MB</div> 
                        </div>
                    </div>
            </div>
            <!-- <div class="row">
                <div class="mr-auto ml-auto mt-3">
                    <button type="submit" class="greenBtnCls mr-3" (click)="updateSumotoData('sumotoverificationDoc')">Update</button>
                </div>
            </div> -->
        </form>
    </div>
</ng-container>         
<!--Sumoto end for VRO-->

<!--Sumoto start for RI-->
<ng-container *ngIf="(role === 'Revenue Inspector') && certificateName === 'IntegratedCertificate-SuoMoto' && f2.remarksReasons.value != 'Not Traceable'">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
        <div class="subHeadingCls mb-3" *ngIf = "role === 'Revenue Inspector'">Checklist By Revenue Inspector: </div>
        <form [formGroup]="sumotoFormForRI">
            <div class="row">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Caste:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                       <select class="selectCls" name="casteforsumotoRI" required formControlName="casteforsumotoRI" (change)="getSubCaste('oncasteChange')" [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && (sumotoFormControlsForRI.casteforsumotoRI.errors || sumotoFormControlsForRI.casteforsumotoRI.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let caste of casteCategory" [ngValue]="caste.name">{{caste.name}}</option>
                       </select>    
                    </div>  
                    <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.casteforsumotoRI.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForRI.casteforsumotoRI.errors.required">Please select caste.</div>
                    </div> 
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls captilize">Sub-Caste:<span class="mandatoryCls">*</span></label>
                        <div class="valueCls">  
                           <select class="selectCls" name="subCasteforsumotoRI" (change)="sumotoChangeForRI()" required formControlName="subCasteforsumotoRI" [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && (sumotoFormControlsForRI.subCasteforsumotoRI.errors || sumotoFormControlsForRI.subCasteforsumotoRI.value === 'Select')}">
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let subcaste of subCasteData" [ngValue]="subcaste">{{subcaste}}</option>
                           </select>    
                        </div>
                        <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.subCasteforsumotoRI.errors" class="selectError">
                            <div *ngIf="sumotoFormControlsForRI.subCasteforsumotoRI.errors.required">Please select subcaste.</div>
                        </div>   
                </div>
                
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Religion:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">  
                       <select class="selectCls" name="religionforsumotoRI" (change)="sumotoChangeForRI()" required formControlName="religionforsumotoRI" [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && (sumotoFormControlsForRI.religionforsumotoRI?.errors || sumotoFormControlsForRI.religionforsumotoRI?.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let religion of religions" [ngValue]="religion.name" selected>{{religion.name}}</option>
                       </select>    
                    </div>
                    <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI?.religionforsumotoRI?.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForRI.religionforsumotoRI?.errors?.required">Please select religion.</div>
                    </div>   
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Qualification:</label>
                <select class="selectCls" placeholder = "Select" (change)="sumotoChangeForRI()" name="qualificationforsumotoRI" formControlName="qualificationforsumotoRI"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let qual of qualifications" [ngValue]="qual.name">{{qual.name}}</option>
                </select>
              </div>
            
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Revenue village:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">  
                       <select class="selectCls" (change)="sumotoChangeForRI()" [required] = "(role === 'Revenue Inspector') && certificateName === 'IntegratedCertificate-SuoMoto'" name="revenuevillageforsumotoRI" formControlName="revenuevillageforsumotoRI" [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && (sumotoFormControlsForRI.revenuevillageforsumotoRI.errors || sumotoFormControlsForRI.revenuevillageforsumotoRI.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let revenuevillages of appVillages" [ngValue]="revenuevillages.name">{{revenuevillages.name}}</option>
                       </select>    
                    </div>
                    <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.revenuevillageforsumotoRI.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForRI.revenuevillageforsumotoRI.errors.required">Please select revenue village.</div>
                    </div>   
            </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
                    <label class="labelCls">Father/Husband Name:<span class="mandatoryCls">*</span></label>
                    <input type="text" name="fathernameforsumotoRI" (input)="sumotoChangeForRI()" class="inputCls" formControlName="fathernameforsumotoRI" appBlockCopyPaste required maxlength="50" 
                        [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && sumotoFormControlsForRI.fathernameforsumotoRI.errors }">
                      <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.fathernameforsumotoRI.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForRI.fathernameforsumotoRI.errors.required || sumotoFormControlsForRI.fathernameforsumotoRI.errors.whiteSpaceValidate">Please enter the relation name</div>
                        <div *ngIf="sumotoFormControlsForRI.fathernameforsumotoRI.errors.pattern">Please enter text only</div>
                      </div>
                  </div>

                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Marital Status:</label>
                    <select class="selectCls" formControlName="maritalStatusforsumotoRI" (change)="sumotoChangeForRI()" [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && (sumotoFormControlsForRI.maritalStatusforsumotoRI?.errors || sumotoFormControlsForRI.maritalStatusforsumotoRI?.value === 'Select')}"> 
                      <option [ngValue]="null" disabled>Select</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Married">Married</option>
                      <option value="Unmarried">Unmarried</option>
                    <option value="Widow">Widow</option>
                      <option value="Single Women(Married)">Single Women(Married)</option>
                    </select>
                  </div>
                 
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
                        <label class="labelCls">Mobile<span class="mandatoryCls">*</span></label>
                        <input type="text" name="mobileforsumotoRI" class="inputCls" (input)="sumotoChangeForRI()" formControlName="mobileforsumotoRI" minlength="10" maxlength="10" numbersOnly [required] = "(role === 'Revenue Inspector')"
                            [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && sumotoFormControlsForRI?.mobileforsumotoRI?.errors }">
                          <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.mobileforsumotoRI?.errors" class="selectError">
                            <div *ngIf="sumotoFormControlsForRI?.mobileforsumotoRI?.errors?.required">Please enter mobile number</div>
                            <div *ngIf="sumotoFormControlsForRI?.mobileforsumotoRI?.errors?.pattern">Please enter valid mobile number</div>
                          </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Email:</label>
                        <input type="email" name="emailforsumotoRI" formControlName="emailforsumotoRI" (input)="sumotoChangeForRI()" class="inputCls" maxlength="75" appBlockCopyPaste
                        [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && sumotoFormControlsForRI?.emailforsumotoRI?.errors }" [required]="sumotoFormControls.deliveryType?.value === 'Email'">
                        <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.emailforsumotoRI?.errors" class="invalid-feedback">
                          <div *ngIf="sumotoFormControlsForRI.emailforsumotoRI.errors?.pattern">Please enter valid Email</div>
                        </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Date of Birth</label>
                         <div class="input-group">
                            <input class="calendarCls form-control" appBlockCopyPaste
                                   name="kz"  ngbDatepicker #kz="ngbDatepicker" [minDate]="minDate"
                                   [maxDate]="maxDate" formControlName="dobforsumotoRI" (change)="sumotoChangeForRI()" (dateSelect)="sumotoChangeForRI()"
                                   [ngClass]="{ 'is-invalid': sumotoSubmitsforRI && sumotoFormControlsForRI.dobforsumotoRI?.errors }" [attr.disabled] = "this.isAuthenticated ? '' : null">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="kz.toggle()" type="button"></button>
                            </div>
                          </div>
                          <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI?.dobforsumotoRI?.errors" class="selectError">
                            <div *ngIf="sumotoFormControlsForRI?.dobforsumotoRI?.invalid">Please enter valid date of birth.</div>  
                           </div> 
                      </div>
                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2">
                        <label class="labelCls">Applicant Status <span class="mandatoryCls">*</span></label>
                      <div class="d-flex">
                        <label class="labelCls">Status of the Head of House Hold</label>
                    <div class="valueCls">
                        <input id="statusofhousehold_DeceasedRI" type="radio" formControlName="statusofhouseholdRI" required value="Deceasedri" class="radioCls mx-2" (change)="sumotoChangeForRI()">
                        <label for="statusofhousehold_DeceasedRI">Deceased</label>
                        <input id="statusofhousehold_AliveRI" type="radio" formControlName="statusofhouseholdRI" required value="Aliveri" class="radioCls mx-2" (change)="sumotoChangeForRI()">
                    <label for="statusofhousehold_AliveRI">Alive</label>
                    <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.statusofhouseholdRI.errors" class="selectError mt-30">
                        <div *ngIf="sumotoFormControlsForRI.statusofhouseholdRI.errors.required">Please select appropriate value to confirm.</div>
                    </div>
                    </div>
                    </div>
                        </div>
                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1">
                        <label class="labelCls" for="sumotoverificationDocforRI">Ration Card/EPIC Card/AADHAR Card</label>
                        <div class="input-group">
                            <div class="custom-file">
                            <input type="file" class="custom-file-input" accept=".pdf" name="sumotoverificationDocforRI" formControlName = "sumotoverificationDocforRI"
                            [ngClass]="{ 'is-invalid':sumotoSubmitsforRI && sumotoFormControlsForRI.sumotoverificationDocforRI.errors }" (change)="sumotoDocSelectedforRI($event)">
                            <label class="custom-file-label" #sumotoverificationDocforRILabel for="sumotoverificationDocforRI"><i class="fas fa-search"></i>No File Choosen</label>
                        </div>
                            <div class="clearCls" (click)="clearFileUpload('sumotoverificationDocforRI', 'sumotoverificationDocforRILabel')" *ngIf="sumotoFormControlsForRI?.sumotoverificationDocforRI.value"></div>
                        </div>
                        <div *ngIf="sumotoSubmitsforRI && sumotoFormControlsForRI.sumotoverificationDocforRI.errors" class="selectError mt-30">
                            <div *ngIf="sumotoFormControlsForRI.sumotoverificationDocforRI.errors.required">Please Upload Document</div>
                            <div *ngIf="sumotoFormControlsForRI.sumotoverificationDocforRI?.errors?.inValidExt && !sumotoFormControlsForRI.sumotoverificationDocforRI.errors.required ">Please upload the file in PDF Format</div>
                            <div *ngIf="sumotoFormControlsForRI.sumotoverificationDocforRI.errors?.inValidSize && !sumotoFormControlsForRI.sumotoverificationDocforRI.errors.required && !sumotoFormControls.sumotoverificationDocforRI.errors.inValidExt">File size exceeding 1 MB</div> 
                        </div>
                    </div>
            </div>
            <!-- <div class="row">
                <div class="mr-auto ml-auto mt-3">
                    <button type="submit" class="greenBtnCls mr-3" (click)="updateSumotoDataForRI('sumotoverificationDocforRI')">Update</button>
                </div>
            </div> -->
        </form>
    </div>
</ng-container>

<!--Sumoto end for RI-->

<!--Sumoto start for Tahsildar-->
<ng-container *ngIf="(role == 'Tahsildar') && certificateName == 'IntegratedCertificate-SuoMoto' && f2.remarksReasons.value != 'Not Traceable'">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
        <div class="subHeadingCls mb-3" *ngIf = "role === 'Tahsildar'">Checklist By Tahsildar: </div>
        <form [formGroup]="sumotoFormForTahsildar">
            <div class="row">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Caste:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                       <select class="selectCls" name="casteforsumotoTahsildar" required formControlName="casteforsumotoTahsildar" (change)="getSubCaste('oncasteChange')" [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && (sumotoFormControlsForTahsildar.casteforsumotoTahsildar.errors || sumotoFormControlsForTahsildar.casteforsumotoTahsildar.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let caste of casteCategory" [ngValue]="caste.name">{{caste.name}}</option>
                       </select>    
                    </div>  
                    <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.casteforsumotoTahsildar.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForTahsildar.casteforsumotoTahsildar.errors.required">Please select caste.</div>
                    </div> 
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls captilize">Sub-Caste:<span class="mandatoryCls">*</span></label>
                        <div class="valueCls">  
                           <select class="selectCls" name="subCasteforsumotoTahsildar" (change)="sumotoChangeForTahsildarspecialcaste($event)" required formControlName="subCasteforsumotoTahsildar" [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && (sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.errors || sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.value === 'Select')}">
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let subcaste of subCasteData"  [ngValue]="subcaste" selected>{{subcaste}}</option>
                           </select>    
                        </div>
                        <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.errors" class="selectError">
                            <div *ngIf="sumotoFormControlsForTahsildar.subCasteforsumotoTahsildar.errors.required">Please select subcaste.</div>
                        </div>   
                </div>
                
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Religion:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">  
                       <select class="selectCls" name="religionforsumotoTahsildar" (change)="sumotoChangeForTahsildar()" required formControlName="religionforsumotoTahsildar" [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && (sumotoFormControlsForTahsildar.religionforsumotoTahsildar?.errors || sumotoFormControlsForTahsildar.religionforsumotoTahsildar?.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let religion of religions" [ngValue]="religion.name" selected>{{religion.name}}</option>
                       </select>    
                    </div>
                    <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar?.religionforsumotoTahsildar?.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForTahsildar.religionforsumotoTahsildar?.errors?.required">Please select religion.</div>
                    </div>   
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Qualification:</label>
                <select class="selectCls" placeholder = "Select" (change)="sumotoChangeForTahsildar()" name="qualificationforsumotoTahsildar" formControlName="qualificationforsumotoTahsildar"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let qual of qualifications" [ngValue]="qual.name">{{qual.name}}</option>
                </select>
              </div>
            
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls captilize">Revenue village:<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">  
                       <select class="selectCls" (change)="sumotoChangeForTahsildar()" [required] = "(role === 'Tahsildar') && certificateName === 'IntegratedCertificate-SuoMoto'" name="revenuevillageforsumotoTahsildar" formControlName="revenuevillageforsumotoTahsildar" [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && (sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.errors || sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let revenuevillages of appVillages" [ngValue]="revenuevillages.name">{{revenuevillages.name}}</option>
                       </select>    
                    </div>
                    <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.revenuevillageforsumotoTahsildar.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForTahsildar?.revenuevillageforsumotoTahsildar.errors.required">Please select revenue village.</div>
                    </div>   
            </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
                    <label class="labelCls">Father/Husband Name:<span class="mandatoryCls">*</span></label>
                    <input type="text" name="fathernameforsumotoTahsildar" (input)="sumotoChangeForTahsildar()" class="inputCls" formControlName="fathernameforsumotoTahsildar" appBlockCopyPaste required maxlength="50" 
                        [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.errors }">
                      <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.errors" class="selectError">
                        <div *ngIf="sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.errors.required || sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.errors.whiteSpaceValidate">Please enter the relation name</div>
                        <div *ngIf="sumotoFormControlsForTahsildar.fathernameforsumotoTahsildar.errors.pattern">Please enter text only</div>
                      </div>
                  </div>

                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Marital Status:</label>
                    <select class="selectCls" formControlName="maritalStatusforsumotoTahsildar" (change)="sumotoChangeForTahsildar()" [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && (sumotoFormControlsForTahsildar.maritalStatusforsumotoTahsildar?.errors || sumotoFormControlsForTahsildar.maritalStatusforsumotoTahsildar?.value === 'Select')}"> 
                      <option [ngValue]="null" disabled>Select</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Married">Married</option>
                      <option value="Unmarried">Unmarried</option>
                      <option value="Widow">Widow</option>
                      <option value="Single Women(Married)">Single Women(Married)</option>
                    </select>
                  </div>
                 
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-1">
                        <label class="labelCls">Mobile<span class="mandatoryCls">*</span></label>
                        <input type="text" name="mobileforsumotoTahsildar" class="inputCls" (input)="sumotoChangeForTahsildar()" formControlName="mobileforsumotoTahsildar" minlength="10" maxlength="10" numbersOnly [required] = "(role === 'Tahsildar')"
                            [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar?.mobileforsumotoTahsildar?.errors }">
                          <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.mobileforsumotoTahsildar?.errors" class="selectError">
                            <div *ngIf="sumotoFormControlsForTahsildar?.mobileforsumotoTahsildar?.errors?.required">Please enter mobile number</div>
                            <div *ngIf="sumotoFormControlsForTahsildar?.mobileforsumotoTahsildar?.errors?.pattern">Please enter valid mobile number</div>
                          </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Email:</label>
                        <input type="email" name="emailforsumotoTahsildar" formControlName="emailforsumotoTahsildar" (input)="sumotoChangeForTahsildar()" class="inputCls" maxlength="75" appBlockCopyPaste
                        [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar?.emailforsumotoTahsildar?.errors }" [required]="sumotoFormControls.deliveryType?.value === 'Email'">
                        <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.emailforsumotoTahsildar?.errors" class="invalid-feedback">
                          <div *ngIf="sumotoFormControlsForTahsildar.emailforsumotoTahsildar.errors?.pattern">Please enter valid Email</div>
                        </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Date of Birth:</label>
                         <div class="input-group">
                            <input class="calendarCls form-control" appBlockCopyPaste
                                   name="kz"  ngbDatepicker #kz="ngbDatepicker" [minDate]="minDate"
                                   [maxDate]="maxDate" formControlName="dobforsumotoTahsildar" (change)="sumotoChangeForTahsildar()" (dateSelect)="sumotoChangeForTahsildar()"
                                   [ngClass]="{ 'is-invalid': sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.dobforsumotoTahsildar?.errors }" [attr.disabled] = "this.isAuthenticated ? '' : null">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="kz.toggle()" type="button"></button>
                            </div>
                          </div>
                          <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar?.dobforsumotoTahsildar?.errors" class="selectError">
                            <div *ngIf="sumotoFormControlsForTahsildar?.dobforsumotoTahsildar?.invalid">Please enter valid date of birth.</div>  
                           </div> 
                      </div>
                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2">
                        <label class="labelCls">Applicant Status <span class="mandatoryCls">*</span></label>
                      <div class="d-flex">
                        <label class="labelCls">Status of the Head of House Hold <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                    <input id="statusofhousehold_DeceasedTeh" type="radio" required formControlName="statusofhouseholdTEH" value="Deceasedteh" class="radioCls mx-2" (change)="sumotoChangeForTahsildar()">
                    <label for="statusofhousehold_DeceasedTeh">Deceased</label>
                    <input id="statusofhousehold_AliveTeh" type="radio" required formControlName="statusofhouseholdTEH" value="Aliveteh" class="radioCls mx-2" (change)="sumotoChangeForTahsildar()">
                    <label for="statusofhousehold_AliveTeh">Alive</label>
                    <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.statusofhouseholdTEH.errors" class="selectError mt-30">
                        <div *ngIf="sumotoFormControlsForTahsildar.statusofhouseholdTEH.errors.required">Please select appropriate value to confirm.</div>
                    </div>
                      </div>
                      </div>
                      </div>
                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1">
                        <label class="labelCls" for="sumotoverificationDocforTahsildar">Ration Card/EPIC Card/AADHAR Card</label>
                        <div class="input-group">
                            <div class="custom-file">
                            <input type="file" class="custom-file-input" accept=".pdf" name="sumotoverificationDocforTahsildar" formControlName = "sumotoverificationDocforTahsildar"
                            [ngClass]="{ 'is-invalid':sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors }" (change)="sumotoDocSelectedforTahsildar($event)">
                            <label class="custom-file-label" #sumotoverificationDocforTahsildarLabel for="sumotoverificationDocforTahsildar"><i class="fas fa-search"></i>No File Choosen</label>
                        </div>
                            <div class="clearCls" (click)="clearFileUpload('sumotoverificationDocforTahsildar', 'sumotoverificationDocforTahsildarLabel')" *ngIf="sumotoFormControlsForTahsildar?.sumotoverificationDocforTahsildar.value"></div>
                        </div>
                        <div *ngIf="sumotoSubmitsforTahsildar && sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors" class="selectError mt-30">
                            <div *ngIf="sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors?.required">Please Upload Document</div>
                            <div *ngIf="sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors?.inValidExt && !sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors?.inValidSize">Please upload the file in PDF Format</div>
                            <div *ngIf="sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors?.inValidSize && !sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors?.inValidExt">File size exceeding 1 MB</div>
                            <div *ngIf="sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors?.inValidSize && sumotoFormControlsForTahsildar.sumotoverificationDocforTahsildar.errors?.inValidExt">Please upload the file in PDF Format and below 1MB</div>
                        </div>
                    </div>
            </div>
            <!-- <div class="row">
                <div class="mr-auto ml-auto mt-3">
                    <button type="submit" class="greenBtnCls mr-3" (click)="updateSumotoDataForTahsildar('sumotoverificationDocforTahsildar')">Update</button>
                </div>
            </div> -->
        </form>
    </div>
</ng-container>
<!--Sumoto end for Tahsildar-->


                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="PSHistory && PSHistory.length > 0  && PSHistory[0]?.list?.length > 0">
                    <div class="subHeadingCls mb-3">PS/WS Checklist</div>
                    <div class="fieldHolder" *ngFor="let item of PSHistory[0]?.list" >
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="RIHistory && RIHistory.length > 0  && RIHistory[0]?.list?.length > 0">
                    <div class="subHeadingCls mb-3">RI Checklist</div>
                    <div class="fieldHolder" *ngFor="let item of RIHistory[0]?.list">
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="TEHHistory && TEHHistory.length > 0  && TEHHistory[0]?.list?.length > 0">
                    <div class="subHeadingCls mb-3">Tahsildar/MC Checklist</div>
                    <div class="fieldHolder" *ngFor="let item of TEHHistory[0]?.list">
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="DTHistory && DTHistory.length > 0  && DTHistory[0]?.list?.length > 0">
                    <div class="subHeadingCls mb-3">Deputy Tahsildar Checklist</div>
                    <div class="fieldHolder" *ngFor="let item of DTHistory[0]?.list">
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="RDOHistory && RDOHistory.length > 0 && RDOHistory[0]?.list?.length > 0">
                    <div class="subHeadingCls mb-3">RDO Checklist</div>
                    <div class="fieldHolder" *ngFor="let item of RDOHistory[0]?.list">
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="JCHistory && JCHistory.length > 0 && JCHistory[0]?.list?.length > 0">
                    <div class="subHeadingCls mb-3">Joint Collector Checklist</div>
                    <div class="fieldHolder" *ngFor="let item of JCHistory[0]?.list; let i = index">
                       
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mx-2"  [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </div>
                <!-- pension -->
                <!-- showing history -->
                <!-- MRO -->
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="MROHistory && MROHistory.length > 0  && MROHistory[0]?.list?.length > 0">
                    <div class="subHeadingCls mb-3">MRO Checklist</div>
                    <div class="fieldHolder" *ngFor="let item of MROHistory[0]?.list" >
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                            [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                    </div>
                </div>
                    <!-- MRO -->
                    <!-- Handloom DC -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes' && WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'Yes' && ADODHTOHistory && ADODHTOHistory.length > 0">
                        <div class="subHeadingCls mb-3">HandloomDO Checklist</div>
                        <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder === 'HWCS'">
                            <div class="fieldHolder">
                                <label class="labelCls">Date of Admission into Society. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.admissionDate}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Undertaking from the President / Secretary / Accountant of the PHWCS that they are providing work to weaver member. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.undertakingFromPHWC}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="ADODHTOHistory[0]?.weaverADODHTOData?.undertakingFromPHWC === 'Yes'">
                                <label class="labelCls">Supporting Document <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(ADODHTOHistory[0]?.weaverADODHTOData?.undertakingFromPHWCDocument?.blobRef)">{{ADODHTOHistory[0]?.weaverADODHTOData?.undertakingFromPHWCDocument?.fileName}}</a><span (click)="downloadFile(ADODHTOHistory[0]?.weaverADODHTOData?.undertakingFromPHWCDocument)" class="download ml-2"></span></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">The weaver produced the production at least 15 days on a month for PHWCS <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.productionAtPHWC}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="ADODHTOHistory[0]?.weaverADODHTOData?.productionAtPHWC === 'Yes'">
                                <label class="labelCls">Supporting Document <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(ADODHTOHistory[0]?.weaverADODHTOData?.productionAtPHWCDocument?.blobRef)">{{ADODHTOHistory[0]?.weaverADODHTOData?.productionAtPHWCDocument?.fileName}}</a><span (click)="downloadFile(ADODHTOHistory[0]?.weaverADODHTOData?.productionAtPHWCDocument)" class="download ml-2"></span></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'Master Weaver'">
                            <div class="fieldHolder">
                                <label class="labelCls">Date of Working with Master Weaver <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.workingDate}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Proof of wages transferred to weaver account through online for the past (2) years. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(ADODHTOHistory[0]?.weaverADODHTOData?.wagesProofDocument?.blobRef)">{{ADODHTOHistory[0]?.weaverADODHTOData?.wagesProofDocument?.fileName}}</a><span (click)="downloadFile(ADODHTOHistory[0]?.weaverADODHTOData?.wagesProofDocument)" class="download ml-2"></span></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Undertaking from the Master Weaver that he is providing work to the weaver since (2) years. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(ADODHTOHistory[0]?.weaverADODHTOData?.undertakingMasterDocument?.blobRef)">{{ADODHTOHistory[0]?.weaverADODHTOData?.undertakingMasterDocument?.fileName}}</a><span (click)="downloadFile(ADODHTOHistory[0]?.weaverADODHTOData?.undertakingMasterDocument)" class="download ml-2"></span></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'Individual'">
                            <div class="fieldHolder">
                                <label class="labelCls">Date of Erection of loom. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.erectionLoomDate}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">The Weaver should purchase yarn from NHDC or from private merchant having GST number for the last two years. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(ADODHTOHistory[0]?.weaverADODHTOData?.yarnPurchaseDocument?.blobRef)">{{ADODHTOHistory[0]?.weaverADODHTOData?.yarnPurchaseDocument?.fileName}}</a><span (click)="downloadFile(ADODHTOHistory[0]?.weaverADODHTOData?.yarnPurchaseDocument)" class="download ml-2"></span></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Verify the NHDC Yarn Pass Book or Yarn Purchase Bills with GST number for the last (2) years.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.yarnPassBookOrBills}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Is the weaver consuming atleast (1) bundle (approximate value Rs. 4000/-) or silk yarn should be (2) Kgs (approximate value Rs. 10,000/), per month? <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.isWeaverConsuming}}</div>
                            </div>
                        </ng-container>
                        <div class="fieldHolder">
                            <label class="labelCls">Whether enquired with Neighbours regarding loom functioning since last (2) years? <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.loomFunctioningEnquired}}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Which type of Varieties are producing by the Weaving? <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">{{ADODHTOHistory[0]?.weaverADODHTOData?.varitiesProduced}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes' && WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'Yes' && OtherDCHistory && OtherDCHistory.length > 0">
                        <div class="subHeadingCls mb-3">HandloomDC Checklist</div>
                        <ng-container *ngIf="OtherDCHistory[0]?.list?.length > 0">
                            <div class="fieldHolder" *ngFor="let item of OtherDCHistory[0]?.list" >
                                <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                                <div class="valueCls">
                                <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                                    [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                                <label for="{{item.id}}_Y">Yes</label>
                                <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                                    [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                                <label for="{{item.id}}_N">No</label>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'HWCS'">
                            <div class="fieldHolder">
                                <label class="labelCls">Date of Admission into Society. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.admissionDate}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Undertaking from the President / Secretary / Accountant of the PHWCS that they are providing work to weaver member. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.undertakingFromPHWC}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="OtherDCHistory[0]?.weaverADODHTOData?.undertakingFromPHWC === 'Yes'">
                                <label class="labelCls">Supporting Document <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(OtherDCHistory[0]?.weaverADODHTOData?.undertakingFromPHWCDocument?.blobRef)">{{OtherDCHistory[0]?.weaverADODHTOData?.undertakingFromPHWCDocument?.fileName}}</a><span (click)="downloadFile(OtherDCHistory[0]?.weaverADODHTOData?.undertakingFromPHWCDocument)" class="download ml-2"></span></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">The weaver produced the production at least 15 days on a month for PHWCS <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.productionAtPHWC}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="OtherDCHistory[0]?.weaverADODHTOData?.productionAtPHWC === 'Yes'">
                                <label class="labelCls">Supporting Document <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(OtherDCHistory[0]?.weaverADODHTOData?.productionAtPHWCDocument?.blobRef)">{{OtherDCHistory[0]?.weaverADODHTOData?.productionAtPHWCDocument?.fileName}}</a><span (click)="downloadFile(OtherDCHistory[0]?.weaverADODHTOData?.productionAtPHWCDocument)" class="download ml-2"></span></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'Master Weaver'">
                            <div class="fieldHolder">
                                <label class="labelCls">Date of Working with Master Weaver <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.workingDate}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Proof of wages transferred to weaver account through online for the past (2) years. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(OtherDCHistory[0]?.weaverADODHTOData?.wagesProofDocument?.blobRef)">{{OtherDCHistory[0]?.weaverADODHTOData?.wagesProofDocument?.fileName}}</a><span (click)="downloadFile(OtherDCHistory[0]?.weaverADODHTOData?.wagesProofDocument)" class="download ml-2"></span></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Undertaking from the Master Weaver that he is providing work to the weaver since (2) years. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(OtherDCHistory[0]?.weaverADODHTOData?.undertakingMasterDocument?.blobRef)">{{OtherDCHistory[0]?.weaverADODHTOData?.undertakingMasterDocument?.fileName}}</a><span (click)="downloadFile(OtherDCHistory[0]?.weaverADODHTOData?.undertakingMasterDocument)" class="download ml-2"></span></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'Individual'">
                            <div class="fieldHolder">
                                <label class="labelCls">Date of Erection of loom. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.erectionLoomDate}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">The Weaver should purchase yarn from NHDC or from private merchant having GST number for the last two years. <span class="mandatoryCls">*</span></label>
                                <div class="valueCls"><a (click)="viewFile(OtherDCHistory[0]?.weaverADODHTOData?.yarnPurchaseDocument?.blobRef)">{{OtherDCHistory[0]?.weaverADODHTOData?.yarnPurchaseDocument?.fileName}}</a><span (click)="downloadFile(OtherDCHistory[0]?.weaverADODHTOData?.yarnPurchaseDocument)" class="download ml-2"></span></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Verify the NHDC Yarn Pass Book or Yarn Purchase Bills with GST number for the last (2) years.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.yarnPassBookOrBills}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Is the weaver consuming atleast (1) bundle (approximate value Rs. 4000/-) or silk yarn should be (2) Kgs (approximate value Rs. 10,000/), per month? <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.isWeaverConsuming}}</div>
                            </div>
                        </ng-container>
                        <div class="fieldHolder">
                            <label class="labelCls">Whether enquired with Neighbours regarding loom functioning since last (2) years? <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.loomFunctioningEnquired}}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Which type of Varieties are producing by the Weaving? <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">{{OtherDCHistory[0]?.weaverADODHTOData?.varitiesProduced}}</div>
                        </div>
                    </div>
                        <!-- Handloom DC -->
                         <!-- FDO -->
                         <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="FDOHistory && FDOHistory.length > 0">
                            <div class="subHeadingCls mb-3">FDO Checklist</div>
                            <div class="fieldHolder">
                                <label class="labelCls">Beneficiary Depending on Fishing Profession <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.isFishingProfession}}</div>
                            </div>
                            <ng-container *ngIf="FDOHistory[0]?.fisheriesData?.isFishingProfession === 'Yes'"> 
                                <div class="fieldHolder">
                                    <label class="labelCls">How many years he/she is in the Fishing Profession. ? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.noOfYears}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Type of Fishermen <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.typeOfFishermen}}</div>
                                </div>
                                <ng-container *ngIf="FDOHistory[0]?.fisheriesData?.typeOfFishermen === 'Marine'">
                                    <div class="fieldHolder">
                                        <label class="labelCls">Marine FCS Member  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.marineFCSMember}}</div>
                                        
                                    </div>
                                    <ng-container *ngIf="FDOHistory[0]?.fisheriesData?.marineFCSMember === 'Yes'">
                                    <div class="fieldHolder" >
                                        <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.marineAdmissionNo}}</div>
                                    </div>
                                    <div class="fieldHolder" >
                                        <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.marineDateOfAdmission}}</div>
                                    </div>
                                </ng-container>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Possess Valid Bio Metric ID Card  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.validBiometricId}}</div>
                                    </div>
                                    <div class="fieldHolder" *ngIf="FDOHistory[0]?.fisheriesData?.validBiometricId === 'Yes'">
                                        <label class="labelCls">MFID No <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.mfidNo}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Availed Ban Period Relief  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.banOnFishingProf}}</div>
                                        
                                    </div>
                                    <div class="fieldHolder" *ngIf="FDOHistory[0]?.fisheriesData?.banOnFishingProf === 'Yes'">
                                        <label class="labelCls">Copy of Bank entry <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls"><a (click)="viewFile(FDOHistory[0]?.fisheriesData?.copyOfBankEntry?.blobRef)">{{FDOHistory[0]?.fisheriesData?.copyOfBankEntry?.fileName}}</a><span (click)="downloadFile(FDOHistory[0]?.fisheriesData?.copyOfBankEntry)" class="download ml-2"></span></div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="FDOHistory[0]?.fisheriesData?.typeOfFishermen === 'Inland'">
                                    <div class="fieldHolder">
                                        <label class="labelCls">Inland FCS Member ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.inlandFCSMember}} </div>
                                    </div>
                                    <ng-container *ngIf="FDOHistory[0]?.fisheriesData?.inlandFCSMember === 'Yes'">
                                    <div class="fieldHolder" >
                                        <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.inlandAdmissionNo}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">No of Tanks under FCS<span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.inlandNoOfTanks}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Extent under FCS <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.inlandExtent}}</div>
                                    </div>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls"> {{FDOHistory[0]?.fisheriesData?.inlandDateOfAdmission}}</div>
                                    </div>
                                </ng-container>
                                    <div class="fieldHolder">
                                        <label class="labelCls">Individual License holder  ? <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">{{FDOHistory[0]?.fisheriesData?.individualLicenceHolder}}</div>
                                    </div>
                                    <div class="fieldHolder" *ngIf="FDOHistory[0]?.fisheriesData?.individualLicenceHolder === 'Yes'">
                                        <label class="labelCls">Copy of the license issued <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls"><a (click)="viewFile(FDOHistory[0]?.fisheriesData?.copyOfLicence?.blobRef)">{{FDOHistory[0]?.fisheriesData?.copyOfLicence?.fileName}}</a><span (click)="downloadFile(FDOHistory[0]?.fisheriesData?.copyOfLicence)" class="download ml-2"></span></div>
                                    </div>
                                </ng-container>
                            </ng-container> 
                        </div>
                            <!-- FDO-->
                        <!-- Fisherman Dc -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="fisheryDCHistory && fisheryDCHistory.length > 0">
                        <div class="subHeadingCls mb-3">FisheryDC Checklist</div>
                        <ng-container *ngIf="fisheryDCHistory[0]?.list?.length > 0">
                        <div class="fieldHolder" *ngFor="let item of fisheryDCHistory[0]?.list" >
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                        </div>
                        </ng-container>
                        <div class="fieldHolder">
                            <label class="labelCls">Beneficiary Depending on Fishing Profession <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.isFishingProfession}}</div>
                        </div>
                        <ng-container *ngIf="fisheryDCHistory[0]?.fisheriesData?.isFishingProfession === 'Yes'"> 
                            <div class="fieldHolder">
                                <label class="labelCls">How many years he/she is in the Fishing Profession. ? <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.noOfYears}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Type of Fishermen <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.typeOfFishermen}}</div>
                            </div>
                            <ng-container *ngIf="fisheryDCHistory[0]?.fisheriesData?.typeOfFishermen === 'Marine'">
                                <div class="fieldHolder">
                                    <label class="labelCls">Marine FCS Member  ? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.marineFCSMember}}</div>
                                    
                                </div>
                                <ng-container *ngIf="fisheryDCHistory[0]?.fisheriesData?.marineFCSMember === 'Yes'">
                                <div class="fieldHolder" >
                                    <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.marineAdmissionNo}}</div>
                                </div>
                                <div class="fieldHolder" >
                                    <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.marineDateOfAdmission}}</div>
                                </div>
                            </ng-container>
                                <div class="fieldHolder">
                                    <label class="labelCls">Possess Valid Bio Metric ID Card  ? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.validBiometricId}}</div>
                                </div>
                                <div class="fieldHolder" *ngIf="fisheryDCHistory[0]?.fisheriesData?.validBiometricId === 'Yes'">
                                    <label class="labelCls">MFID No <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.mfidNo}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Availed Ban Period Relief  ? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.banOnFishingProf}}</div>
                                    
                                </div>
                                <div class="fieldHolder" *ngIf="fisheryDCHistory[0]?.fisheriesData?.banOnFishingProf === 'Yes'">
                                    <label class="labelCls">Copy of Bank entry <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls"><a (click)="viewFile(fisheryDCHistory[0]?.fisheriesData?.copyOfBankEntry?.blobRef)">{{fisheryDCHistory[0]?.fisheriesData?.copyOfBankEntry?.fileName}}</a><span (click)="downloadFile(fisheryDCHistory[0]?.fisheriesData?.copyOfBankEntry)" class="download ml-2"></span></div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="fisheryDCHistory[0]?.fisheriesData?.typeOfFishermen === 'Inland'">
                                <div class="fieldHolder">
                                    <label class="labelCls">Inland FCS Member ? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.inlandFCSMember}} </div>
                                </div>
                                <ng-container *ngIf="fisheryDCHistory[0]?.fisheriesData?.inlandFCSMember === 'Yes'">
                                <div class="fieldHolder" >
                                    <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.inlandAdmissionNo}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">No of Tanks under FCS<span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.inlandNoOfTanks}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Extent under FCS <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.inlandExtent}}</div>
                                </div>
                                <div class="fieldHolder">
                                    <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls"> {{fisheryDCHistory[0]?.fisheriesData?.inlandDateOfAdmission}}</div>
                                </div>
                            </ng-container>
                                <div class="fieldHolder">
                                    <label class="labelCls">Individual License holder  ? <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls">{{fisheryDCHistory[0]?.fisheriesData?.individualLicenceHolder}}</div>
                                </div>
                                <div class="fieldHolder" *ngIf="fisheryDCHistory[0]?.fisheriesData?.individualLicenceHolder === 'Yes'">
                                    <label class="labelCls">Copy of the license issued <span class="mandatoryCls">*</span></label>
                                    <div class="valueCls"><a (click)="viewFile(fisheryDCHistory[0]?.fisheriesData?.copyOfLicence?.blobRef)">{{fisheryDCHistory[0]?.fisheriesData?.copyOfLicence?.fileName}}</a><span (click)="downloadFile(fisheryDCHistory[0]?.fisheriesData?.copyOfLicence)" class="download ml-2"></span></div>
                                </div>
                            </ng-container>
                        </ng-container> 
                    </div>
                        <!-- FisherMan DC -->
                    <!--ASWO checklist-->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="ASWOHistory && ASWOHistory.length > 0">
                        <div class="subHeadingCls mb-3">ASWO Checklist</div>
                        <ng-container  *ngIf="certificateName === 'Pensions@9' || certificateName === 'Pensions@V9' || certificateName === 'Pensions-NEW@9' || certificateName === 'Pensions-NEW@V9'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Whether the cobbling is the main occupation of the applicant without any principal source of income?<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.traditionalCobblerData?.isCobblingMainOcc}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether beneficiary having his own cobbler shop?<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.traditionalCobblerData?.isHavingCobblerShop}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="ASWOHistory[0]?.traditionalCobblerData?.isHavingCobblerShop === 'Yes'">
                                <label class="labelCls">Cobbler Shop Address<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.traditionalCobblerData?.cobblingAddress}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="ASWOHistory[0]?.traditionalCobblerData?.isHavingCobblerShop === 'No'">
                                <label class="labelCls">If there is no shop, where does he perform the cobbling activity<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.traditionalCobblerData?.cobblingAddress}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">From how many years he is in cobbler occupation.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.traditionalCobblerData?.noOfYearsIntoCobbling}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether any of the member of the family already getting Cobbler pension.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.traditionalCobblerData?.receivingCobblerPension}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="ASWOHistory[0]?.traditionalCobblerData?.receivingCobblerPension === 'Yes'">
                                <label class="labelCls">Pension id of the family member<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.traditionalCobblerData?.pensionId}}</div>
                            </div>
                        </ng-container>
                        <ng-container  *ngIf="certificateName === 'Pensions@10' || certificateName === 'Pensions@V10' || certificateName === 'Pensions-NEW@10' || certificateName === 'Pensions-NEW@V10'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Whether Dappu Instrument is in the house <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.dappuArtistsData?.instrumentInhouse}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether the applicant is able to play the Dappu properly atleast for a minute<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.dappuArtistsData?.playDappu}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether beneficiary play Dappu solo or as part of Group<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.dappuArtistsData?.dappuType}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">From how many years he is playing Dappu Instrument.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.dappuArtistsData?.noOfYears}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Date of his last program playing in a public place with date and place<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.dappuArtistsData?.lastProgDate}}, <div>{{ASWOHistory[0]?.dappuArtistsData?.address}}</div></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether any of the member of the family already getting Dappu Artists pension.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{ASWOHistory[0]?.dappuArtistsData?.existPension}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="ASWOHistory[0]?.dappuArtistsData?.existPension === 'Yes'">
                                <label class="labelCls">Pension Id of the family member</label>
                                <div class="valueCls">{{ASWOHistory[0]?.dappuArtistsData?.pensionId}}</div>
                            </div>
                        </ng-container>
                    </div>
                    <!--ASWO checklist-->
                        <!-- TraditionalCobblers /DappuArtists DC -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="scweldcDCHistory && scweldcDCHistory.length > 0">
                        <div class="subHeadingCls mb-3">SCWELDC Checklist</div>
                        <ng-container *ngIf="scweldcDCHistory[0]?.list?.length > 0">
                        <div class="fieldHolder" *ngFor="let item of scweldcDCHistory[0]?.list" >
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                        </div>
                    </ng-container>
                        <ng-container  *ngIf="certificateName === 'Pensions@9' || certificateName === 'Pensions@V9' || certificateName === 'Pensions-NEW@9' || certificateName === 'Pensions-NEW@V9'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Whether the cobbling is the main occupation of the applicant without any principal source of income?<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.traditionalCobblerData?.isCobblingMainOcc}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether beneficiary having his own cobbler shop?<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.traditionalCobblerData?.isHavingCobblerShop}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="scweldcDCHistory[0]?.traditionalCobblerData?.isHavingCobblerShop === 'Yes'">
                                <label class="labelCls">Cobbler Shop Address<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.traditionalCobblerData?.cobblingAddress}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="scweldcDCHistory[0]?.traditionalCobblerData?.isHavingCobblerShop === 'No'">
                                <label class="labelCls">If there is no shop, where does he perform the cobbling activity<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.traditionalCobblerData?.cobblingAddress}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">From how many years he is in cobbler occupation.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.traditionalCobblerData?.noOfYearsIntoCobbling}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether any of the member of the family already getting Cobbler pension.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.traditionalCobblerData?.receivingCobblerPension}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="scweldcDCHistory[0]?.traditionalCobblerData?.receivingCobblerPension === 'Yes'">
                                <label class="labelCls">Pension id of the family member<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.traditionalCobblerData?.pensionId}}</div>
                            </div>
                        </ng-container>
                        <ng-container  *ngIf="certificateName === 'Pensions@10' || certificateName === 'Pensions@V10' || certificateName === 'Pensions-NEW@10' || certificateName === 'Pensions-NEW@V10'" >
                            <div class="fieldHolder">
                                <label class="labelCls">Whether Dappu Instrument is in the house <span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.dappuArtistsData?.instrumentInhouse}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether the applicant is able to play the Dappu properly atleast for a minute<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.dappuArtistsData?.playDappu}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether beneficiary play Dappu solo or as part of Group<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.dappuArtistsData?.dappuType}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">From how many years he is playing Dappu Instrument.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.dappuArtistsData?.noOfYears}}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Date of his last program playing in a public place with date and place<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.dappuArtistsData?.lastProgDate}}, <div>{{scweldcDCHistory[0]?.dappuArtistsData?.address}}</div></div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Whether any of the member of the family already getting Dappu Artists pension.<span class="mandatoryCls">*</span></label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.dappuArtistsData?.existPension}}</div>
                            </div>
                            <div class="fieldHolder" *ngIf="scweldcDCHistory[0]?.dappuArtistsData?.existPension === 'Yes'">
                                <label class="labelCls">Pension Id of the family member</label>
                                <div class="valueCls">{{scweldcDCHistory[0]?.dappuArtistsData?.pensionId}}</div>
                            </div>
                        </ng-container>
                    </div>
                        <!-- TraditionalCobblers /DappuArtists DC -->
                         <!-- Toddy Tappers -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="exciseDCHistory && exciseDCHistory.length > 0  && exciseDCHistory[0]?.list?.length > 0">
                        <div class="subHeadingCls mb-3">ExciseDC Checklist</div>
                        <div class="fieldHolder" *ngFor="let item of exciseDCHistory[0]?.list" >
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                        </div>
                    </div>
                        <!-- Toddy Tappers -->
                        <!-- Transgender -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="dmoDCHistory && dmoDCHistory.length > 0  && dmoDCHistory[0]?.list?.length > 0">
                        <div class="subHeadingCls mb-3">DMODC Checklist</div>
                        <div class="fieldHolder" *ngFor="let item of dmoDCHistory[0]?.list" >
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" name="{{item.id}}" value="Yes" class="radioCls mr-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'Yes'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" name="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory"
                                [checked] ="item.answer === 'No'" [disabled]="true" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                        </div>
                    </div>
                        <!-- Transgender -->
                        <!-- showing history -->
                        <!-- pension -->
                         <!-- <div class="certificateInfo" *ngIf="certificateName == 'IntegratedCertificate-SuoMoto' && (role === 'Village Revenue Officer' || role === 'Revenue Inspector' || role == 'Ward Revenue Secretary' || role === 'Tahsildar')"></div> -->
                         <!-- <hr class="hrCls"> -->
                <ng-container *ngIf="checklist && checklist.length > 0 &&  role !== 'Village Revenue Officer' && role !== 'Ward Revenue Secretary' && departmentCode !== 'RD' && role !=='Ward Welfare & Development Secretary' && role !=='Welfare & Educational Assistant' && ((certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && role != 'Joint Collector') || (certificateName != 'AssignmentofLandstoEx-ServicepersonServingSoldiers'))">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Revenue Inspector'">RI Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Tahsildar'">Tahsildar/MC Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Deputy Tahsildar'">Deputy Tahsildar Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Revenue Divisonal Officer'">RDO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'District Revenue Officer'">DRO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Joint Collector'">Joint Collector Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Collector'">Collector Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Panchayat Secretary DDO'">Panchayat Secretary DDO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Panchayat Secretary' || role === 'Ward Admin Secretary'">PS/WS Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Municipal Commissioner'">MC Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Mandal Parishad Development Officers'">MPDO Checklist</div>
                        <form  [formGroup]="checklistForm">
                        <div class="fieldHolder" *ngFor="let item of checklist; let i = index">
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item, i)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item, i)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                            <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                                <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                            </div>
                        </div>
                        </form>
                    </div>
                </ng-container>

                <!--ZSWO workflow Joint collector-->
                <ng-container *ngIf="checklist && checklist.length > 0 &&  role !== 'Village Revenue Officer' && role !== 'Ward Revenue Secretary' && departmentCode !== 'RD' && role !=='Ward Welfare & Development Secretary' && role !=='Welfare & Educational Assistant' && (certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && role == 'Joint Collector')">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Joint Collector'">Joint Collector Checklist</div>
                        <form [formGroup]="zillasainikwelfareformforJC">
                                    <div class="fieldHolder mb-2">
                                        <label class="labelCls">Is the Applicant eligible for Allotment of Land: <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">
                                            <input id="allotmentoflandjc" type="radio"  (change)="onChangeChecklist($event, id, i)"
                                                formControlName="allotmentoflandjc" name="allotmentoflandjc" value="Yes" class="radioCls mr-2"/>
                                            <label for="allotmentoflandjc_Y">Yes</label>
                                            <input id="allotmentoflandjc" type="radio" (change)="onChangeChecklist($event, id, i)"
                                                formControlName="allotmentoflandjc" name="allotmentoflandjc" value="No" class="radioCls mx-2"/>
                                            <label for="allotmentoflandjc_N">No</label>
                                        </div>
                                        <div class="selectError" *ngIf="submitted && z2.allotmentoflandjc.errors">
                                            <div *ngIf="z2.allotmentoflandjc.errors?.required">Please select appropriate value</div>
                                        </div>
                                    </div>
                                    <div class="fieldHolder mb-2">
                                        <label class="labelCls">Do you agree with the recommendation of RDO/Sub Collector: <span class="mandatoryCls">*</span></label>
                                        <div class="valueCls">
                                            <input id="recommendationofrdoandjc" type="radio" (change)="onChangeChecklist($event, id, i)"
                                                formControlName="recommendationofrdoandjc" name="recommendationofrdoandjc" value="Yes" class="radioCls mr-2"/>
                                            <label for="recommendationofrdoandjc_Y">Yes</label>
                                            <input id="recommendationofrdoandjc" type="radio" (change)="onChangeChecklist($event, id, i)"
                                                formControlName="recommendationofrdoandjc" name="recommendationofrdoandjc" value="No" class="radioCls mx-2"/>
                                            <label for="recommendationofrdoandjc_N">No</label>
                                        </div>
                                        <div class="selectError" *ngIf="submitted && z2.recommendationofrdoandjc.errors">
                                            <div *ngIf="z2.recommendationofrdoandjc.errors?.required">Please select appropriate value</div>
                                        </div>
                                    </div>
                             
                        </form>
                    </div>
                </ng-container>


                <!-- Pension -->
                <ng-container *ngIf="checklist && checklist.length > 0 && departmentCode === 'RD' && role !=='Ward Welfare & Development Secretary'&& role !=='Welfare & Educational Assistant' && role !== 'Village Revenue Officer' && role !== 'Ward Revenue Secretary'">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Mandal Revenue Officer'">MRO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'HandloomDC'">Handloom DC Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Municipal Commissioner'">MC Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Mandal Parishad Development Officers'">MPDO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'FisheryDC'">Fishery DC Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'ExciseDC'">Excise DC Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'SCWELDC'">SCWEL DC Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'DMO'">DMO DC Checklist</div>
                        <form  [formGroup]="checklistForm">
                        <div class="fieldHolder" *ngFor="let item of checklist" >
                            <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                            <div class="valueCls">
                            <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_Y">Yes</label>
                            <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                            <label for="{{item.id}}_N">No</label>
                            </div>
                            <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                                <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                            </div>
                        </div>
                        </form>
                        </div>
                </ng-container>
                <ng-container *ngIf="(certificateName === 'Pensions@2' || certificateName === 'Pensions@V2' || certificateName === 'Pensions-NEW@2' || certificateName === 'Pensions-NEW@V2') &&
                    (role === 'HandloomDO' || role === 'HandloomDC') && (WEAWWDSHistory[0]?.handloomWeaverData?.isLoomPresentInHouse === 'Yes' && WEAWWDSHistory[0]?.handloomWeaverData?.loomWorkingCondition === 'Yes')" >
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="subHeadingCls mb-3" *ngIf="role === 'HandloomDO'">HandloomDO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'HandloomDC'">HandloomDC Checklist</div>
                        <ng-container [ngTemplateOutlet]="handloomWeaverADODHTOTemplate"> </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="(certificateName === 'Pensions@7' || certificateName === 'Pensions@V7') && (role ==='FisheryDC' || role === 'Fisheries Development Officer')" >
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Fisheries Development Officer'">FDO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'FisheryDC'">Fishery DC Checklist</div>
                        <ng-container [ngTemplateOutlet]="fisheriesTemplate"> </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="(certificateName === 'Pensions@9' || certificateName === 'Pensions@V9' || certificateName === 'Pensions-NEW@9' || certificateName === 'Pensions-NEW@V9') && (role === 'SCWELDC' || role === 'Assistant Social Welfare Officer')" >
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Assistant Social Welfare Officer'">ASWO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'SCWELDC'">SCWELDC Checklist</div>
                        <ng-container [ngTemplateOutlet]="traditionalCobblerTemplate"></ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="(certificateName === 'Pensions@10' || certificateName === 'Pensions@V10' || certificateName === 'Pensions-NEW@10' || certificateName === 'Pensions-NEW@V10') && (role === 'SCWELDC' || role === 'Assistant Social Welfare Officer')" >
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="subHeadingCls mb-3" *ngIf="role === 'Assistant Social Welfare Officer'">ASWO Checklist</div>
                        <div class="subHeadingCls mb-3" *ngIf="role === 'SCWELDC'">SCWELDC Checklist</div>
                        <ng-container [ngTemplateOutlet]="dappuArtistsTemplate"> </ng-container>
                    </div>
                </ng-container>
                <!-- Pension -->
            </div>
        </div>
    <div class="certificateInfo">
    <ng-container *ngIf="(role == 'Village Revenue Officer' || role == 'Ward Revenue Secretary') && certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers'">
        <form [formGroup]="zillasainikwelfareform">
            <div class="row" *ngIf="landavailableforgrantvalue == 'Yes'">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                        <label class="labelCls" *ngIf="isResurveyVillage">LP Numbers selected by  Applicant:</label>
                        <label class="labelCls" *ngIf="!isResurveyVillage">Sy.Numbers selected by Applicant:</label>
                        <div class="valueCls">{{this.lpNoSyNofromworklist}}</div>
                </div> 
                </div>
                </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Is the vacant Land available for grant of assignment in Sy Number/LP Number 
                            proposed by the Applicant in the Village: <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input id="landavailableforgrant" type="radio" 
                                formControlName="landavailableforgrant" name="landavailableforgrant" value="Yes" class="radioCls mr-2" (change)="landavailableforgrant($event)"/>
                            <label for="landavailableforgrant_Y">Yes</label>
                            <input id="landavailableforgrant_N" type="radio" 
                                formControlName="landavailableforgrant" name="landavailableforgrant" value="No" class="radioCls mx-2" (change)="landavailableforgrant($event)"/>
                            <label for="landavailableforgrant_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && z1.landavailableforgrant.errors">
                            <div *ngIf="z1.landavailableforgrant.errors?.required">Please select appropriate value</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="landavailableforgrantvalue == 'No'">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Is vacant land available in any other Sy Number/LP Number in the village for grant 
                            of assignment: <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input id="vacantland" type="radio" 
                                formControlName="vacantland" name="vacantland" value="Yes" class="radioCls mr-2" (change)="vacantland($event)"/>
                            <label for="vacantland_Y">Yes</label>
                            <input id="vacantland_N" type="radio"
                                formControlName="vacantland" name="vacantland" value="No" class="radioCls mx-2" (change)="vacantland($event)"/>
                            <label for="vacantland_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && z1.vacantland.errors">
                            <div *ngIf="z1.vacantland.errors?.required">Please select appropriate value</div>
                        </div>
                    </div>
                </div>
            </div>
          
       
        <div class="row mb-3" *ngIf="landavailableforgrantvalue == 'Yes'">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls font-weight-bold" *ngIf="isResurveyVillage">Select LP Number requested by Applicant from Land Records: <span class="mandatoryCls">*</span></label>
                    <label class="labelCls font-weight-bold" *ngIf="!isResurveyVillage">Select Sy Number requested by Applicant from Land Records: <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 lpdropdownpositon"  *ngIf="(landavailableforgrantvalue == 'Yes')">
                <ng-multiselect-dropdown [ngClass]="{ 'is-invalid':getlpsysubmitted && z1.lpmnumbers.errors }"
                [placeholder]="'Select'"
                [settings]="dropdownSettings"
                [data]="certificateInfo?.lpNoSYNo"
                (onSelect) ="onChangeLPMNumber()"
                (change)="onChangeLPMNumber()"
                name="lpmnumbers"
                formControlName="lpmnumbers"
                (onDeSelect)="onChangeLPMNumber()">
            </ng-multiselect-dropdown>
            <div *ngIf="getlpsysubmitted && (z1.lpmnumbers.errors)" class="selectError" >
                <ng-container *ngIf="isResurveyVillage">
                    Please select atleast one Lp Number
                </ng-container>
                <ng-container *ngIf="!isResurveyVillage == true">
                    Please select atleast one Sy Number
                </ng-container>
              </div>
            </div>
                <div class="btnHolder">
                    <button class="greenBtnCls float-right" (click)="generateLPtoken()">
                      Get Details
                    </button>
                  </div>
        </div>
        <ng-container *ngIf="(showTable && landavailableforgrantvalue == 'Yes')">
            <div class="row mb-3">
                <div class="col">
                    <div class="tab table-responsive">
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th *ngIf="isResurveyVillage">LP Number</th>
                                    <th *ngIf="!isResurveyVillage">Sy Number</th>
                                    <th>Khata No</th>
                                    <th>Land Nature </th>
                                    <th>Land Classification </th>
                                    <th *ngIf="isResurveyVillage">LP Number Extent (Acrs-Cents) </th>
                                    <th *ngIf="!isResurveyVillage">Sy Number Extent (Acrs-Cents) </th>
                                    <th>Occupant Extent (Acrs-Cents) </th>
                                    <th>Proposed Extent (Acrs-Cents)</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="lpsyobj?.length">
                                    <tr class="text-center" *ngFor = "let lpNumbertabledata of lpsyobj; let i = index;">
                                        <td>
                                            <input type="checkbox" [ngModelOptions]="{standalone: true}" value="id" formControlName="{{i+1}}"
                                            (change)="checkValue(lpNumbertabledata, $event, i+1)"/>
                                            
                                        </td>
                                        <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.Survey_No }}</td>
                                        <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LPMNO}}</td>
                                        <td *ngIf="!isResurveyVillage">{{(lpNumbertabledata?.khata_no ) ? (lpNumbertabledata?.khata_no) : "-" }}</td>
                                        <td *ngIf="isResurveyVillage">{{(lpNumbertabledata?.KhataNo ) ? (lpNumbertabledata?.KhataNo) : "-" }}</td>
                                        <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.land_nature }} </td>
                                        <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LandNature}}</td>
                                        <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.land_classification}}</td>
                                        <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LandCalssification}}</td>
                                        <td *ngIf="isResurveyVillage">{{(lpNumbertabledata?.LPM_Extent) ? (lpNumbertabledata?.LPM_Extent) : "-"}}</td>
                                        <td *ngIf="!isResurveyVillage">{{ (lpNumbertabledata?.Total_Extent) ? (lpNumbertabledata?.Total_Extent) : "-"}}</td>
                                        <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LPM_Extent}}</td>
                                        <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.Occupant_Extent}}</td>
                                        <!-- <td>
                                            <input type="text" numbersOnly class="inputCls" (change)="checkValue()" [attr.disabled]="checkboxstate ?  null : ''" name="proposedextent" formControlName="proposedextent" [ngClass]="{ 'is-invalid':submitted && z1.proposedextent.errors }">
                                            <div *ngIf="submitted && (z1.proposedextent.errors)" class="selectError">
                                                <div *ngIf="z1.proposedextent.errors?.required">Please enter proposed extent</div>
                                                <div *ngIf="z1.proposedextent.errors?.pattern">Please enter valid proposed extent</div>
                                            </div>
                                        </td> -->
                                        <td>
                                            <input type="text" class="inputCls" (change)="proposedextentChange(lpNumbertabledata,i+1)" formControlName="{{i+1 + 'Value'}}">
                                            <!-- <div *ngIf="submitted && (z1.proposedextent.errors)" class="selectError">
                                                <div *ngIf="z1.{{i+1 + 'Value'}}.errors?.required">Please enter proposed extent</div>
                                                <div *ngIf="z1.{{i+1 + 'Value'}}.errors?.pattern">Please enter valid proposed extent</div>
                                            </div> -->
                                        </td>
                                    </tr>
                            </tbody>
                            <tbody *ngIf="lpsyobj?.length == 0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        No Records Found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="row mb-3" *ngIf="vacantlandvalue == 'Yes'">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls font-weight-bold" *ngIf="isResurveyVillage">Select LP Number: <span class="mandatoryCls">*</span></label>
                    <label class="labelCls font-weight-bold" *ngIf="!isResurveyVillage">Select Sy Number: <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 lpdropdownpositon"  *ngIf="(vacantlandvalue == 'Yes')">
                <ng-multiselect-dropdown [ngClass]="{ 'is-invalid': getlpsysubmitted && z1.lpmnumbers.errors }"
                [placeholder]="'Select'"
                [settings]="dropdownSettings"
                [data]="lpNumberSurveyNumber"
                (onSelect) ="onChangeLPMNumber()"
                name="lpmnumbers"
                (change)="onChangeLPMNumber()"
                formControlName="lpmnumbers"
                (onDeSelect)="onChangeLPMNumber()">
            </ng-multiselect-dropdown>
            <div *ngIf="getlpsysubmitted && (z1.lpmnumbers.errors)" class="selectError">
                 <div *ngIf="z1.lpmnumbers.errors?.required">
                    <ng-container *ngIf="isResurveyVillage">
                        Please select atleast one Lp Number
                    </ng-container>
                    <ng-container *ngIf="!isResurveyVillage == true">
                        Please select atleast one Sy Number
                    </ng-container>
                </div>
            </div>
            </div>
                <div class="btnHolder">
                    <button class="greenBtnCls float-right" (click)="generateLPtoken()">
                      Get Details
                    </button>
                  </div>
        </div>
        <ng-container *ngIf="(showTable && (vacantlandvalue == 'Yes'))">
            <div class="row mb-3">
                <div class="col">
                    <div class="tab table-responsive">
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th *ngIf="isResurveyVillage">LP Number</th>
                                    <th *ngIf="!isResurveyVillage">Sy Number</th>
                                    <th>Khata No</th>
                                    <th>Land Nature </th>
                                    <th>Land Classification </th>
                                    <th *ngIf="isResurveyVillage">LP Number Extent (Acrs-Cents) </th>
                                    <th *ngIf="!isResurveyVillage">Sy Number Extent (Acrs-Cents) </th>
                                    <th>Occupant Extent (Acrs-Cents) </th>
                                    <th>Proposed Extent (Acrs-Cents)</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="lpsyobj?.length">
                                <tr class="text-center" *ngFor = "let lpNumbertabledata of lpsyobj; let i=index">
                                    <td>
                                        <input type="checkbox" [ngModelOptions]="{standalone: true}" formControlName="{{i+1}}"
                                        (change)="checkValue(lpNumbertabledata, $event, i+1)"/>
                                    </td>
                                    <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.Survey_No }}</td>
                                    <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LPMNO}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ (lpNumbertabledata?.khata_no) ? lpNumbertabledata?.khata_no : "-" }}</td>
                                    <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.KhataNo}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.land_nature }} </td>
                                    <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LandNature}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.land_classification}}</td>
                                    <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LandCalssification}}</td>
                                    <td *ngIf="isResurveyVillage">{{(lpNumbertabledata?.LPM_Extent) ? (lpNumbertabledata?.LPM_Extent) : "-"}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ (lpNumbertabledata?.Total_Extent) ? (lpNumbertabledata?.Total_Extent) : "-"}}</td>
                                    <td *ngIf="isResurveyVillage">{{ lpNumbertabledata?.LPM_Extent}}</td>
                                    <td *ngIf="!isResurveyVillage">{{ lpNumbertabledata?.Occupant_Extent}}</td>
                                    <!-- <td>
                                        <input type="text" class="inputCls" name="proposedextent" formControlName="proposedextent" (change)="checkValue()" [attr.disabled]="checkboxstate ?  null : ''" [ngClass]="{ 'is-invalid':submitted && z1.proposedextent.errors }">
                                        <div *ngIf="submitted && (z1.proposedextent.errors)" class="selectError">
                                            <div *ngIf="z1.proposedextent.errors?.required">Please enter proposed extent</div>
                                            <div *ngIf="z1.proposedextent.errors?.pattern">Please enter valid proposed extent</div>
                                        </div>
                                    </td> -->
                                    <td>
                                        <input type="text" class="inputCls" (change)="proposedextentChange(lpNumbertabledata,i+1)" formControlName="{{i+1 + 'Value'}}">
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lpsyobj?.length == 0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        No Records Found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="row" *ngIf="(vacantlandvalue === 'No')">
            <div class="col col-lg-2 col-md-2 col-sm-5 col-xs-5 remarksCls">
                <label class="valueCls labelCls font-weight-bold">Remarks <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder mb-2">
                    <div class="valueCls">
                        <textarea type="text" name="remarksByVroZswo" class="text-areaCls" formControlName="remarksByVroZswo"
                        rows="3"></textarea>
                    </div>
                    <div *ngIf="z1.remarksByVroZswo.errors" class="selectError">
                        <div *ngIf="z1.remarksByVroZswo.errors.required || z1.remarksByVroZswo.errors.whiteSpaceValidate">Please enter remarks.</div>
                    </div>   
                </div>
            </div>
        </div>
        </form>
    </ng-container>
     </div>


        <!-- <div class="certificateInfo mt-3" *ngIf="checklist && checklist.length > 0 &&  ((certificateName == 'IntegratedCertificate' || certificateName == 'IntegratedCertificate-SuoMoto') && (role == 'Tahsildar' || role == 'Revenue Inspector'))">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="subHeadingCls mb-3" *ngIf="role === 'Tahsildar'">Tahsildar Checklist</div>
                    <div class="subHeadingCls mb-3" *ngIf="role === 'Revenue Inspector'">RI Checklist</div>
                    <form  [formGroup]="checklistForm">
                    <div class="fieldHolder" *ngFor="let item of checklist" >
                        <label class="labelCls">{{item.questionText}} <span class="mandatoryCls" *ngIf="item.isMandatory">*</span></label>
                        <div class="valueCls">
                        <input id="{{item.id}}_Y" type="radio" formControlName="{{item.id}}" value="Yes" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_Y">Yes</label>
                        <input id="{{item.id}}_N" type="radio" formControlName="{{item.id}}" value="No" class="radioCls mx-2" [required]="item.isMandatory" (change)="onChangeChecklist($event, item)">
                        <label for="{{item.id}}_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f1[item.id].errors">
                            <div *ngIf="submitted && f1[item.id].errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    </form>
                    </div>
                </div>
            </div> -->
        
       
        <form [formGroup]="remarksForm">
            <div class="certificateInfo mt-3" >
                <div class="row" *ngIf="departmentCode != 'CS'">
                    <ng-container *ngIf="VROHistory && VROHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from VRO/WRS:</label>
                            <div class="valueCls">{{VROHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="VROHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from VRO/WRS:</label>
                            <div class="valueCls">{{VROHistory[0]?.reason}} <div *ngIf= "VROHistory[0]?.reason === 'Documents Not Available'" >({{VROHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls" *ngIf="certificateName != 'IntegratedCertificate-SuoMoto'">Document from VRO/WRS:</label>
                        <label *ngIf="certificateName == 'IntegratedCertificate-SuoMoto'" class="labelCls">VRO Enquiry Report:</label>
                        <div class="valueCls" *ngIf="VROHistory[0]?.fileList?.length > 0">
                            <div *ngFor="let file of VROHistory[0]?.fileList; let i = index">
                                <div *ngIf="file.documentType === 'verificationDoc'">
                                    <div class="valueCls"><a (click)="viewFile(file?.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                                </div>
                                <div *ngIf="file.documentType === 'casteVerificationDoc'">
                                    <div class="valueCls"><a (click)="viewFile(file?.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                                </div>
                            </div>
                            <div class="valueCls" *ngIf="filterfileforverificationdocument === 0 && certificateName == 'IntegratedCertificate-SuoMoto'">Not Available</div>
                        </div>
                        <div class="valueCls" *ngIf="VROHistory[0]?.fileList?.length === 0">Not Available</div>
                        <!-- <div class="valueCls" *ngIf="VROHistory[0]?.fileList?.length > 0"><a (click)="viewFile(VROHistory[0]?.fileList[0]?.blobRef)">{{VROHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(VROHistory[0]?.fileList[0])" class="download ml-2"></span></div> -->
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Revenue Inspector' || role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                        <label class="labelCls">Supporting Document from VRO/WRS:</label>
                        <div class="valueCls" *ngIf="VROHistory[0]?.fileList?.length > 0">
                            <div *ngFor="let file of VROHistory[0]?.fileList; let i = index">
                                <div *ngIf="file.documentType === 'supportingDocuments'">
                                    <div class="valueCls"><a (click)="viewFile(file?.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                                </div>
                            </div>
                            <div class="valueCls" *ngIf="filterfileforsupportingdocument == 0">Not Available</div>
                        </div>
                        <div class="valueCls" *ngIf="VROHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    <ng-container *ngIf="VROHistory[0]?.reason === 'Migrated to another place in Andhra Pradesh' && certificateName === 'IntegratedCertificate-SuoMoto'">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Revenue Inspector' || role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">District Selected by VRO/WRS:</label>
                            <div class="valueCls">{{VROHistory[0]?.sumotoData.remarksdistrictvro}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Revenue Inspector' || role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Mandal Selected by VRO/WRS:</label>
                            <div class="valueCls">{{VROHistory[0]?.sumotoData.remarksmandalvro}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Revenue Inspector' || role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Secretariat Selected by VRO/WRS:</label>
                            <div class="valueCls">{{VROHistory[0]?.sumotoData.remarkssecratariatvro}}</div>
                        </div>
                    </ng-container>
                    
                    </ng-container>
                    <ng-container *ngIf="RIHistory && RIHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                    <div class="fieldHolder">
                        <label class="labelCls">Remarks from RI:</label>
                        <div class="valueCls">{{RIHistory[0]?.remarks}}</div>
                    </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="RIHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from RI:</label>
                            <div class="valueCls">{{RIHistory[0]?.reason}}<div *ngIf= "RIHistory[0]?.reason === 'Documents Not Available'" >({{RIHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls" *ngIf="certificateName != 'IntegratedCertificate-SuoMoto'">Document from RI:</label>
                        <div class="labelCls" *ngIf="certificateName=='IntegratedCertificate-SuoMoto'">Enquiry Report by RI: </div>
                        <ng-container *ngIf="RIHistory[0]?.fileList?.length > 0 && (certificateName != 'IntegratedCertificate-SuoMoto' )">
                            <div class="valueCls" *ngIf="RIHistory[0]?.fileList?.length > 0"><a (click)="viewFile(RIHistory[0]?.fileList[0]?.blobRef)">{{RIHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(RIHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        </ng-container>
                        <ng-container *ngIf="RIHistory[0]?.fileList?.length > 0 && (certificateName == 'IntegratedCertificate-SuoMoto' && (role == 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector'))">
                            <ng-container class="fieldHolder" *ngFor="let file of RIHistory[0]?.fileList; let i = index">
                                <ng-container *ngIf="file.documentType == 'verificationDoc'">
                                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <div class="valueCls" *ngIf="RIHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    <ng-container *ngIf="RIHistory[0]?.reason === 'Migrated to another place in Andhra Pradesh' && certificateName === 'IntegratedCertificate-SuoMoto'">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">District Selected by RI:</label>
                            <div class="valueCls">{{RIHistory[0]?.sumotoDataforri.remarksdistrictri}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Mandal Selected by RI:</label>
                            <div class="valueCls">{{RIHistory[0]?.sumotoDataforri.remarksmandalri}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Secretariat Selected by RI:</label>
                            <div class="valueCls">{{RIHistory[0]?.sumotoDataforri.remarkssecratariatri}}</div>
                        </div>
                    </ng-container>
                    </ng-container>
                    <ng-container *ngIf="DTHistory && DTHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from Deputy Tahsildar:</label>
                            <div class="valueCls">{{DTHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="DTHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from Deputy Tahsildar:</label>
                            <div class="valueCls">{{DTHistory[0]?.reason}}<div *ngIf= "DTHistory[0]?.reason === 'Documents Not Available'" >({{DTHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from Deputy Tahsildar:</label>
                        <div class="valueCls" *ngIf="DTHistory[0]?.fileList?.length > 0"><a (click)="viewFile(DTHistory[0]?.fileList[0]?.blobRef)">{{DTHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(DTHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="DTHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                    <ng-container  *ngIf="TEHHistory && TEHHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from Tahsildar/MC:</label>
                            <div class="valueCls">{{TEHHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="TEHHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from Tahsildar/MC:</label>
                            <div class="valueCls">{{TEHHistory[0]?.reason}}<div *ngIf= "TEHHistory[0]?.reason === 'Documents Not Available'" >({{TEHHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from Tahsildar/MC:</label>
                        <ng-container *ngIf="certificateName != 'IntegratedCertificate-SuoMoto'">
                            <div class="valueCls" *ngIf="TEHHistory[0]?.fileList?.length > 0"><a (click)="viewFile(TEHHistory[0]?.fileList[0]?.blobRef)">{{TEHHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(TEHHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        </ng-container>
                        <ng-container *ngIf="TEHHistory[0]?.fileList?.length > 0 && certificateName == 'IntegratedCertificate-SuoMoto'">
                            <ng-container class="fieldHolder" *ngFor="let file of TEHHistory[0]?.fileList; let i = index">
                                <ng-container *ngIf="file.documentType == 'verificationDoc'">
                                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <div class="valueCls" *ngIf="TEHHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    <ng-container *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && TEHHistory[0]?.reason === 'Migrated to another place in Andhra Pradesh'">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">District Selected by Tahsildar:</label>
                            <div class="valueCls">{{TEHHistory[0]?.sumotoDataforteh.remarksdistrictforteh}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Mandal Selected by Tahsildar:</label>
                            <div class="valueCls">{{TEHHistory[0]?.sumotoDataforteh.remarksmandalforteh}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Secretariat Selected by Tahsildar:</label>
                            <div class="valueCls">{{TEHHistory[0]?.sumotoDataforteh.remarkssecratariatforteh}}</div>
                        </div>
                    </ng-container>
                    </ng-container>
                    <ng-container *ngIf="RDOHistory && RDOHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from RDO:</label>
                            <div class="valueCls">{{RDOHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="RDOHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from RDO:</label>
                            <div class="valueCls">{{RDOHistory[0]?.reason}}<div *ngIf= "RDOHistory[0]?.reason === 'Documents Not Available'" >({{RDOHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from RDO:</label>
                        <ng-container *ngIf="certificateName != 'IntegratedCertificate-SuoMoto'">
                            <div class="valueCls" *ngIf="RDOHistory[0]?.fileList?.length > 0"><a (click)="viewFile(RDOHistory[0]?.fileList[0]?.blobRef)">{{RDOHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(RDOHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        </ng-container>
                        <ng-container *ngIf="certificateName == 'IntegratedCertificate-SuoMoto' && (role == 'Collector') && RDOHistory[0]?.fileList">
                            <ng-container class="fieldHolder" *ngFor="let file of RDOHistory[0]?.fileList; let i = index">
                                <ng-container *ngIf="file.documentType == 'verificationDoc'">
                                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <div class="valueCls" *ngIf="RDOHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    <ng-container *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && RDOHistory[0]?.reason === 'Migrated to another place in Andhra Pradesh'">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">District Selected by RDO:</label>
                            <div class="valueCls">{{RDOHistory[0]?.sumotoDataforrdo.remarksdistrictforrdo}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Mandal Selected by RDO:</label>
                            <div class="valueCls">{{RDOHistory[0]?.sumotoDataforrdo.remarksmandalforrdo}}</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role === 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')">
                            <label class="labelCls">Secretariat Selected by RDO:</label>
                            <div class="valueCls">{{RDOHistory[0]?.sumotoDataforrdo.remarkssecratariatforrdo}}</div>
                        </div>
                    </ng-container>
                    </ng-container>
                    <ng-container *ngIf="JCHistory && JCHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from Joint Collector:</label>
                            <div class="valueCls">{{JCHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="JCHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from Joint Collector:</label>
                            <div class="valueCls">{{JCHistory[0]?.reason}}<div *ngIf= "JCHistory[0]?.reason === 'Documents Not Available'" >({{JCHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from Joint Collector:</label>
                        <div class="valueCls" *ngIf="JCHistory[0]?.fileList?.length > 0"><a (click)="viewFile(JCHistory[0]?.fileList[0]?.blobRef)">{{JCHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(JCHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="JCHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                    <!-- Pension -->
                    <!-- WEA/WWDS REMARKS -->
                    <ng-container *ngIf="WEAWWDSHistory && WEAWWDSHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from WEA/WWDS:</label>
                            <div class="valueCls">{{WEAWWDSHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="WEAWWDSHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from WEA/WWDS:</label>
                            <div class="valueCls">{{WEAWWDSHistory[0]?.reason}}<div *ngIf= "WEAWWDSHistory[0]?.reason === 'Documents Not Available'" >({{WEAWWDSHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="WEAWWDSHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from WEA/WWDS:</label>
                            <div class="valueCls">{{WEAWWDSHistory[0]?.reason ? (WEAWWDSHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from WEA/WWDS:</label>
                        <div class="valueCls" *ngIf="WEAWWDSHistory[0]?.fileList?.length > 0"><a (click)="viewFile(WEAWWDSHistory[0]?.fileList[0]?.blobRef)">{{WEAWWDSHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(WEAWWDSHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="WEAWWDSHistory[0]?.fileList?.length === 0">Not Available</div>
                        
                    </div>
                    </ng-container>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="PSHistory && PSHistory.length > 0">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from PS/WS:</label>
                            <div class="valueCls">{{PSHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="PSHistory && PSHistory.length > 0 && PSHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from PS/WS:</label>
                            <div class="valueCls">{{PSHistory[0]?.reason}}<div *ngIf= "PSHistory[0]?.reason === 'Documents Not Available'" >({{PSHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="PSHistory && PSHistory.length > 0 && PSHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from PS/WS:</label>
                            <div class="valueCls">{{PSHistory[0]?.reason ? (PSHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="PSHistory && PSHistory.length > 0">
                        <label class="labelCls">Document from PS/WS:</label>
                        <div class="valueCls" *ngIf="PSHistory[0]?.fileList?.length > 0"><a (click)="viewFile(PSHistory[0]?.fileList[0]?.blobRef)">{{PSHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(PSHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="PSHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    <!--  WEA/WWDS REMARKS -->
                    <!-- Muslim Marriage REMARKS -->
                    
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="SPT3History && SPT3History.length > 0">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from Superintendent:</label>
                            <div class="valueCls">{{SPT3History[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="SPT3History && SPT3History.length > 0 && SPT3History[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from Superintendent:</label>
                            <div class="valueCls">{{SPT3History[0]?.reason}}<div *ngIf= "SPT3History[0]?.reason === 'Documents Not Available'" >({{SPT3History[0]?.docName}})</div></div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="SPT3History && SPT3History.length > 0 && SPT3History[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from Superintendent:</label>
                            <div class="valueCls">{{SPT3History[0]?.reason ? (SPT3History[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="SPT3History && SPT3History.length > 0">
                        <label class="labelCls">Document from Superintendent:</label>
                        <div class="valueCls" *ngIf="SPT3History[0]?.fileList?.length > 0"><a (click)="viewFile(SPT3History[0]?.fileList[0]?.blobRef)">{{SPT3History[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(SPT3History[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="SPT3History[0]?.fileList?.length === 0">Not Available</div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="AS2History && AS2History.length > 0">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from Assistant Secretary:</label>
                            <div class="valueCls">{{AS2History[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="AS2History && AS2History.length > 0 && AS2History[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from Assistant Secretary:</label>
                            <div class="valueCls">{{AS2History[0]?.reason}}<div *ngIf= "AS2History[0]?.reason === 'Documents Not Available'" >({{AS2History[0]?.docName}})</div></div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="AS2History && AS2History.length > 0 && AS2History[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from Assistant Secretary:</label>
                            <div class="valueCls">{{AS2History[0]?.reason ? (AS2History[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="AS2History && AS2History.length > 0">
                        <label class="labelCls">Document from Assistant Secretary:</label>
                        <div class="valueCls" *ngIf="AS2History[0]?.fileList?.length > 0"><a (click)="viewFile(AS2History[0]?.fileList[0]?.blobRef)">{{AS2History[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(AS2History[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="AS2History[0]?.fileList?.length === 0">Not Available</div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="CEOWAQFHistory && CEOWAQFHistory.length > 0">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from CEO, WAQF board:</label>
                            <div class="valueCls">{{CEOWAQFHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="CEOWAQFHistory && CEOWAQFHistory.length > 0 && CEOWAQFHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from CEO, WAQF board:</label>
                            <div class="valueCls">{{CEOWAQFHistory[0]?.reason}}<div *ngIf= "CEOWAQFHistory[0]?.reason === 'Documents Not Available'" >({{CEOWAQFHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="CEOWAQFHistory && CEOWAQFHistory.length > 0 && CEOWAQFHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from CEO, WAQF board:</label>
                            <div class="valueCls">{{CEOWAQFHistory[0]?.reason ? (CEOWAQFHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="CEOWAQFHistory && CEOWAQFHistory.length > 0">
                        <label class="labelCls">Document from CEO, WAQF board:</label>
                        <div class="valueCls" *ngIf="CEOWAQFHistory[0]?.fileList?.length > 0"><a (click)="viewFile(CEOWAQFHistory[0]?.fileList[0]?.blobRef)">{{CEOWAQFHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(CEOWAQFHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="CEOWAQFHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>

                    <!-- Muslim Marriage REMARKS -->
                    <!-- MRO REMARKS -->
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="MROHistory && MROHistory.length > 0">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from MRO:</label>
                            <div class="valueCls">{{MROHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="MROHistory && MROHistory.length > 0 && MROHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from MRO:</label>
                            <div class="valueCls">{{MROHistory[0]?.reason}}<div *ngIf= "MROHistory[0]?.reason === 'Documents Not Available'" >({{MROHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="MROHistory && MROHistory.length > 0 && MROHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from MRO:</label>
                            <div class="valueCls">{{MROHistory[0]?.reason ? (MROHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="MROHistory && MROHistory.length > 0">
                        <label class="labelCls">Document from MRO:</label>
                        <div class="valueCls" *ngIf="MROHistory[0]?.fileList?.length > 0"><a (click)="viewFile(MROHistory[0]?.fileList[0]?.blobRef)">{{MROHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(MROHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="MROHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    <!-- MRO REMARKS -->
                     <!-- OTHERS DC REMARKS -->
                     <!-- Handloom DC -->
                    <ng-container *ngIf="ADODHTOHistory && ADODHTOHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from HandloomDO:</label>
                            <div class="valueCls">{{ADODHTOHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="ADODHTOHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from HandloomDO:</label>
                            <div class="valueCls">{{ADODHTOHistory[0]?.reason}}<div *ngIf= "ADODHTOHistory[0]?.reason === 'Documents Not Available'" >({{ADODHTOHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="ADODHTOHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from HandloomDO:</label>
                            <div class="valueCls">{{ADODHTOHistory[0]?.reason ? (ADODHTOHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from HandloomDO:</label>
                        <div class="valueCls" *ngIf="ADODHTOHistory[0]?.fileList?.length > 0"><a (click)="viewFile(ADODHTOHistory[0]?.fileList[0]?.blobRef)">{{ADODHTOHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(ADODHTOHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="ADODHTOHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                     <ng-container *ngIf="OtherDCHistory && OtherDCHistory.length > 0">
                     <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from HandloomDC:</label>
                            <div class="valueCls">{{OtherDCHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="OtherDCHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from HandloomDC:</label>
                            <div class="valueCls">{{OtherDCHistory[0]?.reason}}<div *ngIf= "OtherDCHistory[0]?.reason === 'Documents Not Available'" >({{OtherDCHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="OtherDCHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from HandloomDC:</label>
                            <div class="valueCls">{{OtherDCHistory[0]?.reason ? (OtherDCHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from HandloomDC:</label>
                        <div class="valueCls" *ngIf="OtherDCHistory[0]?.fileList?.length > 0"><a (click)="viewFile(OtherDCHistory[0]?.fileList[0]?.blobRef)">{{OtherDCHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(OtherDCHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="OtherDCHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                    <!-- Handloom DC -->
                      <!-- FDO -->
                    <ng-container *ngIf="FDOHistory && FDOHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from FDO:</label>
                            <div class="valueCls">{{FDOHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="FDOHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from FDO:</label>
                            <div class="valueCls">{{FDOHistory[0]?.reason}}<div *ngIf= "FDOHistory[0]?.reason === 'Documents Not Available'" >({{FDOHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="FDOHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from FDO:</label>
                            <div class="valueCls">{{FDOHistory[0]?.reason ? (FDOHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from FDO:</label>
                        <div class="valueCls" *ngIf="FDOHistory[0]?.fileList?.length > 0"><a (click)="viewFile(FDOHistory[0]?.fileList[0]?.blobRef)">{{FDOHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(FDOHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="FDOHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder" *ngIf="FDOHistory[0]?.fisheriesData?.fdoDocument !== null">
                        <label class="labelCls">Society Membership Proof/License copy /Biometric ID copy/ Evidence of availing ban relief (Bank entry)</label>
                        <div class="valueCls"><a (click)="viewFile(FDOHistory[0]?.fisheriesData?.fdoDocument?.blobRef)">{{FDOHistory[0]?.fisheriesData?.fdoDocument?.fileName}}</a><span (click)="downloadFile(FDOHistory[0]?.fisheriesData?.fdoDocument)" class="download ml-2"></span></div>
                    </div>
                    </ng-container>
                    <!-- FDO -->
                    <!-- Fisherman DC -->
                    <ng-container *ngIf="fisheryDCHistory && fisheryDCHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from FisheryDC:</label>
                            <div class="valueCls">{{fisheryDCHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf=" fisheryDCHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from FisheryDC:</label>
                            <div class="valueCls">{{fisheryDCHistory[0]?.reason}}<div *ngIf= "fisheryDCHistory[0]?.reason === 'Documents Not Available'" >({{fisheryDCHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="fisheryDCHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from FisheryDC:</label>
                            <div class="valueCls">{{fisheryDCHistory[0]?.reason ? (fisheryDCHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from FisheryDC:</label>
                        <div class="valueCls" *ngIf="fisheryDCHistory[0]?.fileList?.length > 0"><a (click)="viewFile(fisheryDCHistory[0]?.fileList[0]?.blobRef)">{{fisheryDCHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(fisheryDCHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="fisheryDCHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                    <!-- FisherMan DC -->
                    <!-- TraditionalCobblers /DappuArtists DC -->
                    <ng-container *ngIf="ASWOHistory && ASWOHistory.length > 0">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Remarks from ASWO:</label>
                                <div class="valueCls">{{ASWOHistory[0]?.remarks}}</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="ASWOHistory[0]?.remarks === 'Not Recommended'">
                            <div class="fieldHolder">
                                <label class="labelCls">Reason from ASWO:</label>
                                <div class="valueCls">{{ASWOHistory[0]?.reason}}<div *ngIf= "ASWOHistory[0]?.reason === 'Documents Not Available'" >({{ASWOHistory[0]?.docName}})</div></div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="ASWOHistory[0]?.remarks === 'others'">
                            <div class="fieldHolder">
                                <label class="labelCls">Reason from ASWO:</label>
                                <div class="valueCls">{{ASWOHistory[0]?.reason ? (ASWOHistory[0]?.reason):'-'}}</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                            <label class="labelCls">Document from ASWO:</label>
                            <div class="valueCls" *ngIf="ASWOHistory[0]?.fileList?.length > 0"><a (click)="viewFile(ASWOHistory[0]?.fileList[0]?.blobRef)">{{ASWOHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(ASWOHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                            <div class="valueCls" *ngIf="ASWOHistory[0]?.fileList?.length === 0">Not Available</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="scweldcDCHistory && scweldcDCHistory.length > 0">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from SCWELDC:</label>
                            <div class="valueCls">{{scweldcDCHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="scweldcDCHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from SCWELDC:</label>
                            <div class="valueCls">{{scweldcDCHistory[0]?.reason}}<div *ngIf= "scweldcDCHistory[0]?.reason === 'Documents Not Available'" >({{scweldcDCHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="scweldcDCHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from SCWELDC:</label>
                            <div class="valueCls">{{scweldcDCHistory[0]?.reason ? (scweldcDCHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from SCWELDC:</label>
                        <div class="valueCls" *ngIf="scweldcDCHistory[0]?.fileList?.length > 0"><a (click)="viewFile(scweldcDCHistory[0]?.fileList[0]?.blobRef)">{{scweldcDCHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(scweldcDCHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="scweldcDCHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                    <!-- TraditionalCobblers /DappuArtists DC -->
                      <!-- Toddy Tappers -->
                      <ng-container *ngIf="exciseDCHistory && exciseDCHistory.length > 0">
                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from ExciseDC:</label>
                            <div class="valueCls">{{exciseDCHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exciseDCHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from ExciseDC:</label>
                            <div class="valueCls">{{exciseDCHistory[0]?.reason}}<div *ngIf= "exciseDCHistory[0]?.reason === 'Documents Not Available'" >({{exciseDCHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="exciseDCHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from ExciseDC:</label>
                            <div class="valueCls">{{exciseDCHistory[0]?.reason ? (exciseDCHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from ExciseDC:</label>
                        <div class="valueCls" *ngIf="exciseDCHistory[0]?.fileList?.length > 0"><a (click)="viewFile(exciseDCHistory[0]?.fileList[0]?.blobRef)">{{exciseDCHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(exciseDCHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="exciseDCHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                    <!-- Toddy Tappers DC -->
                      <!-- Trasngender -->
                      <ng-container *ngIf="dmoDCHistory && dmoDCHistory.length > 0">
                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Remarks from DMODC:</label>
                            <div class="valueCls">{{dmoDCHistory[0]?.remarks}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="dmoDCHistory[0]?.remarks === 'Not Recommended'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from DMODC:</label>
                            <div class="valueCls">{{dmoDCHistory[0]?.reason}}<div *ngIf= "dmoDCHistory[0]?.reason === 'Documents Not Available'" >({{dmoDCHistory[0]?.docName}})</div></div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="dmoDCHistory[0]?.remarks === 'others'">
                        <div class="fieldHolder">
                            <label class="labelCls">Reason from DMODC:</label>
                            <div class="valueCls">{{dmoDCHistory[0]?.reason ? (dmoDCHistory[0]?.reason):'-'}}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 fieldHolder">
                        <label class="labelCls">Document from DMODC:</label>
                        <div class="valueCls" *ngIf="dmoDCHistory[0]?.fileList?.length > 0"><a (click)="viewFile(dmoDCHistory[0]?.fileList[0]?.blobRef)">{{dmoDCHistory[0]?.fileList[0]?.fileName}}</a><span (click)="downloadFile(dmoDCHistory[0]?.fileList[0])" class="download ml-2"></span></div>
                        <div class="valueCls" *ngIf="dmoDCHistory[0]?.fileList?.length === 0">Not Available</div>
                    </div>
                    </ng-container>
                    <!-- Trasngender -->
                    <!-- OTHERS DC REMARKS -->
                    <!-- Pension -->
                </div>
    
                <ng-container *ngIf="(role === 'Tahsildar' || role ==='Municipal Commissioner') && (certificateName === 'HousingSiteApplication' || certificateName === 'HousingSiteApplication-URBAN')">
                <hr class="hrCls">
                
                <div class="row ">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="labelCls">Recommanded for :</label>
                            <label class="valueCls"> <input type="radio" name="house" value="house" [checked]=true
                                (click)="IsTidcoSelected('House')"><span class="mx-2 radioCls">Housesites</span></label>
                        </div>
                    </div>
    
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                        <div class="fieldHolder">
                            <label class="valueCls"><input type="radio" name="house" value="TIDCO" (click)="IsTidcoSelected('TIDCO')"><span class="mx-2 radioCls">TIDCO</span></label>
                        </div>
                    </div>
                </div>  
                </ng-container>

                <hr class="hrCls">
              
                <div class="subHeadingCls mb-3 text-left" *ngIf="role === 'Village Revenue Officer'">Document upload by VRO:</div>
                <div class="row" *ngIf= "!(certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && workflowstepcode == 'TEH-Verify')">
                <div class="col  col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="checkBoxCont">
                            <label class="labelCls" for ="verificationDoc"><span *ngIf = "certificateName !== 'ClaimsUnderDottedLands' && (certificateName !== 'RectificationOfResurveyedRecords' && !isRectification) && (certificateName === 'ClaimsUnderDottedLands' && isForwardAvailable) || (certificateName !== 'IntegratedCertificate-SuoMoto') && ((certificateName !== 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && certificateName !== 'RectificationOfResurveyedRecords'))">Verification Document</span>
                            <span *ngIf="(certificateName == 'IntegratedCertificate-SuoMoto' && (role == 'Tahsildar' || role == 'Revenue Divisonal Officer' || role == 'Collector')) || (certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (role == 'Tahsildar' && workflowstepcode == 'TEH-Verify'))">Verification Document <span class="mandatoryCls" *ngIf="(certificateName == 'IntegratedCertificate-SuoMoto' && (role == 'Tahsildar' || role == 'Collector' || role == 'Revenue Divisonal Officer'))">*</span> </span>
                            <span *ngIf = "certificateName === 'ClaimsUnderDottedLands' && isApprovalAuthority">Upload Collector's proceeding</span> <span class="mandatoryCls" *ngIf="isHouseholdSplitApp">*</span></label>
                            <label *ngIf = "isRectification" class="labelCls" for="verificationDoc"><span class="captilize">Upload Enquiry Report of {{ role }} <span *ngIf="role == 'Tahsildar'">/Endorsement</span> <span class="mandatoryCls">*</span></span></label>
                            <label *ngIf = "(certificateName === 'IntegratedCertificate-SuoMoto' || certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers') && (role == 'Village Revenue Officer')">VRO Enquiry Report:<span class="mandatoryCls">*</span></label>
                            <label *ngIf=  "(certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && role == 'Ward Revenue Secretary')">WRS Enquiry Report:<span class="mandatoryCls">*</span></label>
                            <label *ngIf = "certificateName === 'IntegratedCertificate-SuoMoto' && (role == 'Ward Revenue Secretary')">WRS Enquiry Report:<span class="mandatoryCls">*</span></label>
                            <label *ngIf = "certificateName === 'IntegratedCertificate-SuoMoto' && (role == 'Revenue Inspector')">Enquiry Report by RI:<span class="mandatoryCls">*</span></label>
                            <label *ngIf = "certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (role == 'Tahsildar' && workflowstepcode == 'TEH-Approval')">Tahsildar Enquiry Report:<span class="mandatoryCls">*</span></label>
                            <label *ngIf = "certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (role == 'Revenue Divisonal Officer' || role == 'Sub Collector')">RDO / SC Enquiry Report:<span class="mandatoryCls">*</span></label>
                            <label *ngIf = "certificateName === 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && (role == 'Joint Collector')">Joint Collector Enquiry Report:<span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="input-group">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="verificationDoc" formControlName = "verificationDoc" [ngClass]="{ 'is-invalid':submitted && f2.verificationDoc.errors }" accept=".pdf" (change) = "onDocFileSelected($event)" >
                          <label class="custom-file-label" #verificationDocLabel for="verificationDoc"><i class="fas fa-search"></i>No File choosen??</label>
                      </div>
                      <div class="clearCls" (click)="clearFileUpload('verificationDoc', 'verificationDocLabel')" *ngIf="f2.verificationDoc.value"></div>
                    </div>
                    <div *ngIf="submitted && f2.verificationDoc.errors" class="selectError">
                        <div *ngIf="f2.verificationDoc.errors?.required">Please upload Document</div>
                      <div *ngIf="f2.verificationDoc.errors?.inValidExt && !f2.verificationDoc.errors.required">Please upload the file in PDF Format</div>
                      <div *ngIf="f2.verificationDoc.errors?.inValidSize && !f2.verificationDoc.errors.inValidExt && !f2.verificationDoc.errors.required">File size exceeding 1MB</div>  
                    </div>
                  </div>
                  <div class="col  col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="certificateName === 'IntegratedCertificate-SuoMoto' && (role == 'Village Revenue Officer' || role == 'Ward Revenue Secretary')">
                    <div class="checkBoxCont">
                            <label class="labelCls" for="supportingDocuments">Supporting Documents:<span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="input-group">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" required name="supportingDocuments" formControlName = "supportingDocuments" [ngClass]="{ 'is-invalid':submitted && f2.supportingDocuments.errors }" accept=".pdf" (change) = "onSupportingDocuments($event)" >
                          <label class="custom-file-label" #supportingDocumentsLabel for="supportingDocuments"><i class="fas fa-search"></i>No File choosen</label>
                      </div>
                      <div class="clearCls" (click)="clearFileUpload('supportingDocuments', 'supportingDocumentsLabel')" *ngIf="f2.supportingDocuments.value"></div>
                    </div>
                    <div *ngIf="submitted && f2.supportingDocuments.errors" class="selectError">
                      <div *ngIf="f2.supportingDocuments.errors?.required">Please upload supporting Document</div>
                      <div *ngIf="f2.supportingDocuments.errors?.inValidExt && !f2.supportingDocuments.errors.required">Please upload the file in PDF Format</div>
                      <div *ngIf="f2.supportingDocuments.errors?.inValidSize && !f2.supportingDocuments.errors.inValidExt && !f2.supportingDocuments.errors.required">File size exceeding 1MB</div>  
                    </div>
                  </div>  
                  <div class="col  col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="departmentCode === 'RD' && (role === 'Village Revenue Officer' || role === 'Ward Revenue Secretary')">
                    <div class="checkBoxCont">
                        <label class="labelCls" for="casteVerificationDoc">SSC marks memo or DOB extract or Transfer Certificate / 1 to 10th Study Certificate or DOB certificate issued by Gram Panchayat<span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="input-group mb-3">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" name="casteVerificationDoc" formControlName = "casteVerificationDoc" [ngClass]="{ 'is-invalid':submitted && f2.casteVerificationDoc.errors }" accept=".pdf" (change) = "onCasteVerificationDocSelected($event)" >
                          <label class="custom-file-label" #casteVerificationDocLabel for="casteVerificationDoc"><i class="fas fa-search"></i>No File choosen</label>
                      </div>
                      <div class="clearCls" (click)="clearFileUpload('casteVerificationDoc', 'casteVerificationDocLabel')" *ngIf="f2.casteVerificationDoc.value"></div>
                    </div>
                    <div *ngIf="submitted && f2.casteVerificationDoc.errors" class="selectError">
                        <div *ngIf="f2.casteVerificationDoc.errors?.required">Please upload Document</div>
                      <div *ngIf="f2.casteVerificationDoc.errors?.inValidExt && !f2.casteVerificationDoc.errors.required">Please upload the file in PDF Format</div>
                      <div *ngIf="f2.casteVerificationDoc.errors?.inValidSize && !f2.casteVerificationDoc.errors.inValidExt && !f2.casteVerificationDoc.errors.required">File size exceeding 1MB</div>  
                    </div>
                  </div>
                   <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="!isRectification">
                       <label class="labelCls">Remarks:<span class="mandatoryCls">*</span></label>
                       <select class="selectCls" formControlName="remarks" (change)="onChangeRemarks()"
                       [ngClass]="{ 'is-invalid':submitted && (f2.remarks.errors || f2.remarks.value === 'Select')}">
                       <option [ngValue]="null" disabled>Select</option>
                       <option *ngFor="let option of remarksOptions" [ngValue]="option">{{option}}</option>
                        </select>                    
                     <div *ngIf="submitted && (f2.remarks.errors || f2.remarks.value === 'Select')" class="selectError">
                       <div *ngIf="f2.remarks.errors?.required || f2.remarks.value === 'Select'">Please select the appropriate option</div>
                     </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="isRectification">
                        <label class="labelCls">Recommendations:<span class="mandatoryCls">*</span></label>
                        <select class="selectCls" formControlName="remarks" required 
                        [ngClass]="{ 'is-invalid':submitted && (f2.remarks.errors || f2.remarks.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="Approve">Approve</option>
                        <option value="Reject">Reject</option>
                      </select>
                      <div *ngIf="submitted && (f2.remarks.errors || f2.remarks.value === 'Select')" class="selectError">
                        <div *ngIf="f2.remarks.errors?.required || f2.remarks.value === 'Select'">Please select the appropriate option</div>
                      </div>
                     </div>
                    <div class="col  col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="role === 'Fisheries Development Officer' && f2.remarks.value === 'Recommended' && (this.certificateName === 'Pensions@7' || this.certificateName === 'Pensions@V7')">
                        <div class="checkBoxCont">
                            <label class="labelCls" for="fdoDocument">Society Membership Proof/License copy /Biometric ID copy/ Evidence of availing ban relief (Bank entry)<span class="mandatoryCls">*</span></label>
                        </div>
                        <div class="input-group mb-3">
                          <div class="custom-file">
                              <input type="file" class="custom-file-input" name="fdoDocument" formControlName = "fdoDocument" [ngClass]="{ 'is-invalid':submitted && f2.fdoDocument.errors }" accept=".pdf" (change) = "onFDODocSelected($event)" >
                              <label class="custom-file-label" #fdoDocumentLabel for="fdoDocument"><i class="fas fa-search"></i>No File choosen</label>
                          </div>
                          <div class="clearCls" (click)="clearFileUpload('fdoDocument', 'fdoDocumentLabel')" *ngIf="f2.fdoDocument.value"></div>
                        </div>
                        <div *ngIf="submitted && f2.fdoDocument.errors" class="selectError">
                            <div *ngIf="f2.fdoDocument.errors?.required">Please upload Document</div>
                          <div *ngIf="f2.fdoDocument.errors?.inValidExt && !f2.fdoDocument.errors?.required">Please upload the file in PDF Format</div>
                          <div *ngIf="f2.fdoDocument.errors?.inValidSize && !f2.fdoDocument.errors?.inValidExt && !f2.fdoDocument.errors?.required">File size exceeding 1MB</div>  
                        </div>
                      </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="f2.remarks.value === 'Not Recommended'">
                        <label class="labelCls">Reason:<span class="mandatoryCls">*</span></label>
                        
                       <!--  <select class="selectCls" formControlName="remarksReasons" [required] = "f2.remarks.value === 'Not Recommended'"
                        [ngClass]="{ 'is-invalid':submitted && (f2.remarksReasons.errors || f2.remarksReasons.value === 'Select')}"  (change)="getDocList()">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let option of remarksReasonsOptions" [ngValue]="option">{{option}}</option>
                        </select> -->

                        <select class="selectCls" formControlName="remarksReasons" [required] = "f2.remarks.value === 'Not Recommended'"
                        [ngClass]="{ 'is-invalid':submitted && (f2.remarksReasons.errors || f2.remarksReasons.value === 'Select')}"  (change)="getDocList()">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let option of remarksReasonsOptionsDynamic" [ngValue]="option">{{ option === 'Documents Not Available' ? 'Invalid Document submitted' : option}}</option>
                        </select>
                      <div *ngIf="submitted && (f2.remarksReasons.errors || f2.remarksReasons.value === 'Select')" class="selectError">
                        <div *ngIf="f2.remarksReasons.errors?.required || f2.remarksReasons.value === 'Select'">Please select the appropriate option</div>
                      </div>
                     </div>
                     <ng-container *ngIf="(f2.remarksReasons.value === 'Migrated to another place in Andhra Pradesh')">
                        <ng-container *ngIf="(role == 'Village Revenue Officer' || role == 'Ward Revenue Secretary') && (certificateName === 'IntegratedCertificate-SuoMoto')">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">District:<span class="mandatoryCls">*</span></label>  
                                 <select class="selectCls" formControlName="districtforsuomotovro" required [ngClass]="{ 'is-invalid':submitted && (f2.districtforsuomotovro.errors || f2.districtforsuomotovro.value === 'Select') }" (change)="onDistrictChange()">
                                     <option [ngValue]="null" disabled>Select</option>
                                     <option *ngFor="let option of districtsforsuomoto" [ngValue]="option">{{option.districtName}}</option>
                                 </select>
                                 <div *ngIf="submitted && (f2.districtforsuomotovro.errors || f2.districtforsuomotovro.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.districtforsuomotovro.errors?.required || f2.districtforsuomotovro.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                             </div>
                             <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Mandal:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="mandalforsuomotovro" required [ngClass]="{ 'is-invalid':submitted && (f2.mandalforsuomotovro.errors || f2.mandalforsuomotovro.value === 'Select') }" (change)="onMandalChange()">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of mandaloptions" [ngValue]="option">{{option.mandalName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.mandalforsuomotovro.errors || f2.mandalforsuomotovro.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.mandalforsuomotovro.errors?.required || f2.mandalforsuomotovro.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Secretariat:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="secretariatforsuomotovro" required [ngClass]="{ 'is-invalid':submitted && (f2.secretariatforsuomotovro.errors || f2.secretariatforsuomotovro.value === 'Select') }">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of secretariatoptions" [ngValue]="option">{{option.secretariatName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.secretariatforsuomotovro.errors || f2.secretariatforsuomotovro.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.secretariatforsuomotovro.errors?.required || f2.secretariatforsuomotovro.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                        </ng-container>
                        <ng-container *ngIf="role == 'Revenue Inspector' && (certificateName === 'IntegratedCertificate-SuoMoto')">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">District:<span class="mandatoryCls">*</span></label>  
                                 <select class="selectCls" formControlName="districtforsuomotori" required [ngClass]="{ 'is-invalid':submitted && (f2.districtforsuomotori.errors || f2.districtforsuomotori.value === 'Select') }" (change)="onDistrictChange()">
                                     <option [ngValue]="null" disabled>Select</option>
                                     <option *ngFor="let option of districtsforsuomoto" [ngValue]="option">{{option.districtName}}</option>
                                 </select>
                                 <div *ngIf="submitted && (f2.districtforsuomotori.errors || f2.districtforsuomotori.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.districtforsuomotori.errors?.required || f2.districtforsuomotori.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                             </div>
                             <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Mandal:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="mandalforsuomotori" required [ngClass]="{ 'is-invalid':submitted && (f2.mandalforsuomotori.errors || f2.mandalforsuomotori.value === 'Select') }" (change)="onMandalChange()">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of mandaloptions" [ngValue]="option">{{option.mandalName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.mandalforsuomotori.errors || f2.mandalforsuomotori.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.mandalforsuomotori.errors?.required || f2.mandalforsuomotori.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Secretariat:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="secretariatforsuomotori" required [ngClass]="{ 'is-invalid':submitted && (f2.secretariatforsuomotori.errors || f2.secretariatforsuomotori.value === 'Select') }">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of secretariatoptions" [ngValue]="option">{{option.secretariatName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.secretariatforsuomotori.errors || f2.secretariatforsuomotori.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.secretariatforsuomotori.errors?.required || f2.secretariatforsuomotori.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                        </ng-container>
                        <ng-container *ngIf="role == 'Tahsildar' && (certificateName === 'IntegratedCertificate-SuoMoto')">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">District:<span class="mandatoryCls">*</span></label>  
                                 <select class="selectCls" formControlName="districtforsuomototeh" required (change)="onDistrictChange()" [ngClass]="{ 'is-invalid':submitted && (f2.districtforsuomototeh.errors || f2.districtforsuomototeh.value === 'Select') }">
                                     <option [ngValue]="null" disabled>Select</option>
                                     <option *ngFor="let option of districtsforsuomoto" [ngValue]="option">{{option.districtName}}</option>
                                 </select>
                                 <div *ngIf="submitted && (f2.districtforsuomototeh.errors || f2.districtforsuomototeh.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.districtforsuomototeh.errors?.required || f2.districtforsuomototeh.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                             </div>
                             <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Mandal:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="mandalforsuomototeh" required [ngClass]="{ 'is-invalid':submitted && (f2.mandalforsuomototeh.errors || f2.mandalforsuomototeh.value === 'Select') }" (change)="onMandalChange()">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of mandaloptions" [ngValue]="option">{{option.mandalName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.mandalforsuomototeh.errors || f2.mandalforsuomototeh.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.mandalforsuomototeh.errors?.required || f2.mandalforsuomototeh.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Secretariat:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="secretariatforsuomototeh" required [ngClass]="{ 'is-invalid':submitted && (f2.secretariatforsuomototeh.errors || f2.secretariatforsuomototeh.value === 'Select') }">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of secretariatoptions" [ngValue]="option">{{option.secretariatName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.secretariatforsuomototeh.errors || f2.secretariatforsuomototeh.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.secretariatforsuomototeh.errors?.required || f2.secretariatforsuomototeh.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                        </ng-container>
                        <ng-container *ngIf="role == 'Revenue Divisonal Officer' && (certificateName === 'IntegratedCertificate-SuoMoto')">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">District:<span class="mandatoryCls">*</span></label>  
                                 <select class="selectCls" formControlName="districtforsuomotordo" required [ngClass]="{ 'is-invalid':submitted && (f2.districtforsuomotordo.errors || f2.districtforsuomotordo.value === 'Select') }" (change)="onDistrictChange()">
                                     <option [ngValue]="null" disabled>Select</option>
                                     <option *ngFor="let option of districtsforsuomoto" [ngValue]="option">{{option.districtName}}</option>
                                 </select>
                                 <div *ngIf="submitted && (f2.districtforsuomotordo.errors || f2.districtforsuomotordo.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.districtforsuomotordo.errors?.required || f2.districtforsuomotordo.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                             </div>
                             <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Mandal:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="mandalforsuomotordo" required [ngClass]="{ 'is-invalid':submitted && (f2.mandalforsuomotordo.errors || f2.mandalforsuomotordo.value === 'Select') }" (change)="onMandalChange()">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of mandaloptions" [ngValue]="option">{{option.mandalName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.mandalforsuomotordo.errors || f2.mandalforsuomotordo.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.mandalforsuomotordo.errors?.required || f2.mandalforsuomotordo.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Secretariat:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="secretariatforsuomotordo" required [ngClass]="{ 'is-invalid':submitted && (f2.secretariatforsuomotordo.errors || f2.secretariatforsuomotordo.value === 'Select') }">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of secretariatoptions" [ngValue]="option">{{option.secretariatName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.secretariatforsuomotordo.errors || f2.secretariatforsuomotordo.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.secretariatforsuomotordo.errors?.required || f2.secretariatforsuomotordo.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                        </ng-container>
                        <ng-container *ngIf="role == 'Collector' && (certificateName === 'IntegratedCertificate-SuoMoto')">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">District:<span class="mandatoryCls">*</span></label>  
                                 <select class="selectCls" formControlName="districtforsuomotojc" required (change)="onMandalChange()" [ngClass]="{ 'is-invalid':submitted && (f2.districtforsuomotojc.errors || f2.districtforsuomotojc.value === 'Select') }" (change)="onDistrictChange()">
                                     <option [ngValue]="null" disabled>Select</option>
                                     <option *ngFor="let option of districtsforsuomoto" [ngValue]="option">{{option.districtName}}</option>
                                 </select>
                                 <div *ngIf="submitted && (f2.districtforsuomotojc.errors || f2.districtforsuomotojc.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.districtforsuomotojc.errors?.required || f2.districtforsuomotojc.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                             </div>
                             <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Mandal:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="mandalforsuomotojc" required [ngClass]="{ 'is-invalid':submitted && (f2.mandalforsuomotojc.errors || f2.mandalforsuomotojc.value === 'Select') }" (change)="onMandalChange()">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of mandaloptions" [ngValue]="option">{{option.mandalName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.mandalforsuomotojc.errors || f2.mandalforsuomotojc.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.mandalforsuomotojc.errors?.required || f2.mandalforsuomotojc.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                 <label class="labelCls">Secretariat:<span class="mandatoryCls">*</span></label>  
                                  <select class="selectCls" formControlName="secretariatforsuomotojc" required [ngClass]="{ 'is-invalid':submitted && (f2.secretariatforsuomotojc.errors || f2.secretariatforsuomotojc.value === 'Select') }">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let option of secretariatoptions" [ngValue]="option">{{option.secretariatName}}</option>
                                  </select>
                                  <div *ngIf="submitted && (f2.secretariatforsuomotojc.errors || f2.secretariatforsuomotojc.value === 'Select')" class="selectError">
                                    <div *ngIf="f2.secretariatforsuomotojc.errors?.required || f2.secretariatforsuomotojc.value === 'Select'">Please select the appropriate option</div>
                                  </div>
                              </div>
                        </ng-container>
                     </ng-container>
                       
                     <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="f2.remarksReasons.value === 'Documents Not Available' || f2.remarksReasons.value ==='Invalid Document submitted'">
                        <label class="labelCls">Document Name:<span class="mandatoryCls">*</span></label>
                        <ng-multiselect-dropdown formControlName="docName" [required] = "f2.remarksReasons.value === 'Documents Not Available' || f2.remarksReasons.value ==='Invalid Document submitted'"
                            [ngClass]="{ 'is-invalid':submitted && (f2.docName.errors || f2.docName.value === 'Select')}"
                            [placeholder]="'Select'" 
                            [settings]="dropdownSettings"
                            [data]="reasonDocList">
                        </ng-multiselect-dropdown>
                        <div *ngIf="submitted && (f2.docName.errors || f2.docName.value === 'Select')" class="selectError">
                            <div *ngIf="f2.docName.errors?.required || f2.docName.value === 'Select'">Please select the Document Name</div>
                        </div>
                     </div>
                     <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="f2.remarks.value === 'Others'">
                       <label class="labelCls">Enter the Remarks:<span class="mandatoryCls">*</span></label>
                       <input type="text" name="otherRemarks" class="inputCls" formControlName="otherRemarks" [required] = "f2.remarks.value === 'Others'"
                           [ngClass]="{ 'is-invalid':submitted && f2.otherRemarks.errors }" maxlength="200">
                         <div *ngIf="submitted && f2.otherRemarks.errors" class="invalid-feedback">
                           <div *ngIf="f2.otherRemarks.errors.required">Please enter the remarks</div>
                            <div *ngIf="f2.otherRemarks.errors?.pattern">Please enter valid remarks</div>
                         </div>
                     </div>
               </div>
           </div>
        </form>
        
      
        <ng-container *ngIf="checklist && checklist.length > 0 &&  role !== 'Village Revenue Officer' && role !== 'Ward Revenue Secretary' && departmentCode !== 'RD' && role !=='Ward Welfare & Development Secretary' && role !=='Welfare & Educational Assistant' && (certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && role == 'Joint Collector') && f2.remarks.value == 'Recommended'">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-4">
                <form [formGroup]="zillasainikwelfareformforJC2">
                    <ng-container *ngIf="f2.remarks.value == 'Recommended'">
                        <div class="fieldHolder mb-2">
                            <label class="labelCls">Is the Assignment Approved by District Committee: <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">
                                <input id="assignmentapproveddistJC" type="radio" (change)="onChangeChecklist($event, id, i)"
                                    formControlName="assignmentapproveddistJC" name="assignmentapproveddistJC" value="Yes" class="radioCls mr-2"/>
                                <label for="assignmentapproveddistJC_Y">Yes</label>
                                <input id="assignmentapproveddistJC" type="radio" (change)="onChangeChecklist($event, id, i)"
                                    formControlName="assignmentapproveddistJC" name="assignmentapproveddistJC" value="No" class="radioCls mx-2"/>
                                <label for="assignmentapproveddistJC_N">No</label>
                            </div>
                            <div class="selectError" *ngIf="submitted && z3.assignmentapproveddistJC.errors">
                                <div *ngIf="z3.assignmentapproveddistJC.errors?.required">Please select appropriate value</div>
                            </div>
                         </div>
                        <div class="fieldHolder mb-2">
                            <label class="labelCls">Have the changes on grant of Assignment been incorporated in Webland: <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">
                                <input id="grantofassignmentlandjc" type="radio" (change)="onChangeChecklist($event, id, i)"
                                    formControlName="grantofassignmentlandjc" name="grantofassignmentlandjc" value="Yes" class="radioCls mr-2"/>
                                <label for="grantofassignmentlandjc_Y">Yes</label>
                                <input id="grantofassignmentlandjc" type="radio" (change)="onChangeChecklist($event, id, i)"
                                    formControlName="grantofassignmentlandjc" name="grantofassignmentlandjc" value="No" class="radioCls mx-2"/>
                                <label for="grantofassignmentlandjc_N">No</label>
                            </div>
                            <div class="selectError" *ngIf="submitted && z3.grantofassignmentlandjc.errors">
                                <div *ngIf="z3.grantofassignmentlandjc.errors?.required">Please select appropriate value</div>
                            </div>
                        </div>
                        <div class="fieldHolder mb-2">
                            <label class="labelCls">Is the ROR 1B available for the Assignee in Webland: <span class="mandatoryCls">*</span></label>
                            <div class="valueCls">
                                <input id="ror1bJC" type="radio" (change)="onChangeChecklist($event, id, i)"
                                    formControlName="ror1bJC" name="ror1bJC" value="Yes" class="radioCls mr-2"/>
                                <label for="ror1bJC_Y">Yes</label>
                                <input id="ror1bJC" type="radio" (change)="onChangeChecklist($event, item, i)"
                                    formControlName="ror1bJC" name="ror1bJC" value="No" class="radioCls mx-2"/>
                                <label for="ror1bJC_N">No</label>
                            </div>
                            <div class="selectError" *ngIf="submitted && z3.ror1bJC.errors">
                                <div *ngIf="z3.ror1bJC.errors?.required">Please select appropriate value</div>
                            </div>
                        </div>
                      </ng-container> 
                </form>
                </div>
                </ng-container>

                <div *ngIf="certificateName === 'RectificationOfResurveyedRecords'; else templateName">
                
                </div>
                
                <ng-template #templateName>
        
                    <div class="row mx-3" *ngIf="role === 'Tahsildar' && (certificateName === 'IntegratedCertificate' || certificateName === 'IntegratedCertificate-RDO' || certificateName === 'IntegratedCertificateForStudents' || certificateName === 'IntegratedCertificateForStudents-RDO') && certificateName !== 'IntegratedCertificate-SuoMoto'">
                        <ng-container *ngIf="certificateName != 'AssignmentofLandstoEx-ServicepersonServingSoldiers'">
                            <div class="checkBoxCont col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <input type="checkbox" id="isNative"  [(ngModel)]="isNative"><label class="ml-2 redFontCls" for="isNative">Please uncheck the checkbox, if the native place is not the above given present address.</label>
                             </div>
                             <div class="checkBoxCont col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <input type="checkbox" id="isDOBcorrect"  [(ngModel)]="isDOBCorrect"><label class="ml-2 redFontCls" for="isDOBcorrect">Please uncheck the checkbox, if the date of birth is not correct.</label>
                             </div>
                        </ng-container>
                       
                        </div>
                </ng-template>

          
            </div>
     </ng-container>
     <div  *ngIf="showCSdata" >
        <div class="text-center my-3" *ngIf="departmentCode === 'CS' && items.length > 0 && !isFpShopApplication">
        <ng-container >
           <input type="button" value="Submit"  *ngIf="isCSclick" (click)="btnSubmitCS()"  class="greenBtnCls mr-3">

           <!-- <input type="button" value="Submit" (click)="authenticationForCivilsupplies(aadharAuthentication)" class="greenBtnCls mr-3"> -->

        </ng-container>
       
        </div>
    </div>
    <div class="row my-3" *ngIf="items.length > 0">
        <div class="checkBoxCont col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="!(certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && workflowstepcode == 'TEH-Verify')">
            <input type="checkbox" id="consent"  [(ngModel)]="consent">
            <label class="ml-2" for="consent"> I, hereby, declare that the particulars given above are correct and complete. I will be responsible for any misrepresentation of facts and will be liable for punitive action.</label>
            <div class="redFontCls" *ngIf = "submitted && !consent">Please check the consent</div>
         </div>
         <div class="checkBoxCont col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="role === 'Fisheries Development Officer' && f2.remarks.value === 'Recommended' && (this.certificateName === 'Pensions@7' || this.certificateName === 'Pensions@V7') ">
            <input type="checkbox" id="fdoConsent"  [(ngModel)]="fdoConsent">
            <label class="ml-2" for="fdoConsent"> It is hereby to certify that on field inspection and enquiry, it is found that the above mentioned Fisherman is genuine and found eligible for Fisherman Pension based on the above provided information.</label>
            <div class="redFontCls" *ngIf = "submitted && !fdoConsent">Please check the consent</div>
         </div>
    </div>
    
   
 
     <div class="text-center my-3" *ngIf="(departmentCode !== 'CS' || ( departmentCode == 'CS' && isFpShopApplication))">
        <ng-container *ngIf="items.length > 0 && isApprovalAuthority && certificateName != 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && certificateName != 'IntegratedCertificate-SuoMoto'">
            <button *ngIf="!isApproveExist && ((f2.remarks.value != 'Not Recommended') && (f2.remarks.value != 'Reject') )" name="approve" class="greenBtnCls mr-3"  (click)="approveReject('approve', 3, editProfileModal,confirmationModal)" >Approve</button>
            <!--<button *ngIf="!isApproveExist && f2.remarks.value != 'Recommended' && (role === 'Aarogyasri District Coordinator') &&( (certificateName === 'AarogyaSriNewCard' ) || (certificateName === 'UpdateAarogyaSriCard' ) )" name="approve" class="greenBtnCls mr-3" (click)="approveReject('sentBack', 2, editProfileModal,confirmationModal)">Send Back</button> -->
            <button  *ngIf="!isApproveExist && ((f2.remarks.value != 'Recommended') && (f2.remarks.value != 'Approve'))" name="reject" class="blueBtnCls mr-3"  (click)="approveReject('reject', 4, editProfileModal,confirmationModal)" >Reject</button>
            <!-- <input type="button" value="Submit" (click)="btnSubmit()" class="greenBtnCls mr-3"  *ngIf="departmentCode === 'CS'"> -->
        </ng-container>    
        <ng-container *ngIf="items.length > 0 && isApprovalAuthority && certificateName == 'AssignmentofLandstoEx-ServicepersonServingSoldiers' && certificateName != 'IntegratedCertificate-SuoMoto'">
            <button *ngIf="!isApproveExist && enabledisablebutton == true" name="approve" class="greenBtnCls mr-3"  (click)="approveReject('approve', 3, editProfileModal,confirmationModal)" >Approve</button>
            <!--<button *ngIf="!isApproveExist && f2.remarks.value != 'Recommended' && (role === 'Aarogyasri District Coordinator') &&( (certificateName === 'AarogyaSriNewCard' ) || (certificateName === 'UpdateAarogyaSriCard' ) )" name="approve" class="greenBtnCls mr-3" (click)="approveReject('sentBack', 2, editProfileModal,confirmationModal)">Send Back</button> -->
            <button  *ngIf="!isApproveExist && enabledisablebutton == false" name="reject" class="blueBtnCls mr-3"  (click)="approveReject('reject', 4, editProfileModal,confirmationModal)" >Reject</button>
            <!-- <input type="button" value="Submit" (click)="btnSubmit()" class="greenBtnCls mr-3"  *ngIf="departmentCode === 'CS'"> -->
        </ng-container>  
        <!-- <button *ngIf="items.length > 0" name="aadhar" class="greenBtnCls mr-3" (click)="openaadhar(aadharAuthentication)">Aadhar</button> -->
        <button *ngIf="items.length > 0 && (!isApprovalAuthority || isForwardAvailable) && !isForwardExist && !isApproveExist && certificateName != 'IntegratedCertificate-SuoMoto'" name="approve" class="greenBtnCls mr-3" (click)="approveReject('approve', 1, editProfileModal,aadharAuthentication)">Forward</button>
        
        <button *ngIf="isApproveExist && isApprovalAuthority && certificateName != 'IntegratedCertificate-SuoMoto'" name="getCertificate" class="greenBtnCls" (click)="viewandgetCertificate()">Get Certificate</button>
        <!-- <button  name="getCertificate" class="greenBtnCls" (click)="generateNoticeModal(editDateTimePickerModal)">Generate Notice</button> -->
        <!-- dont remove commented by abi on22-11-2021 -->


        
       

        <!-- <ng-container *ngIf="items.length > 0 && role === 'Tahsildar'">
            <button name="approve" class="greenBtnCls mr-3" (click)="approveReject('approve', 'id',editProfileModal)">Approve</button>
            <button  name="reject" class="blueBtnCls mr-3" (click)="approveReject('reject', 'id',editProfileModal)">Reject</button>
        </ng-container>
        <button *ngIf="items.length > 0 && role === 'Village Revenue Officer'" name="approve" class="greenBtnCls mr-3" (click)="approveReject('approve', 'id',editProfileModal)">Forward to RI</button>
        <button *ngIf="items.length > 0 && role === 'Revenue Inspector'" name="approve" class="greenBtnCls mr-3" (click)="approveReject('approve', 'id',editProfileModal)">Forward to Tahsildar</button>
        <button *ngIf="isApproveExist && role === 'Tahsildar'" name="getCertificate" class="greenBtnCls" (click)="viewandgetCertificate()">Get Certificate</button> -->
        <!-- <button *ngIf="isApproveExist && role === 'Tahsildar'" name="getCertificate" class="greenBtnCls" (click)="openModal(editProfileModal)">Get Certificate</button> -->
        <!-- <button type="button" class="btn btn-primary" (click)="openModal(editProfileModal)">Sign Document</button> -->
        <div *ngIf="items.length > 0 && isApprovalAuthority && certificateName == 'IntegratedCertificate-SuoMoto' && role == 'Tahsildar'">
            <ng-container *ngIf="isspecialcasteforsuomoto">
                <button class = "text-center my-3" *ngIf="items.length > 0 && (!isApprovalAuthority || isForwardAvailable) && !isForwardExist && !isApproveExist && f2.remarksReasons.value != 'Not Traceable'" name="approve" class="greenBtnCls mr-3" (click)="approveReject('approve', 1, editProfileModal,aadharAuthentication)">Forward</button>
                <button class = "text-center my-3"  *ngIf="!isApproveExist && ((f2.remarks.value != 'Recommended') && (f2.remarks.value != 'Approve') && (f2.remarksReasons.value == 'Not Traceable'))" name="reject" class="blueBtnCls mr-3"  (click)="approveReject('reject', 4, editProfileModal,confirmationModal)" >Reject</button>
            </ng-container>
            <ng-container *ngIf="!isspecialcasteforsuomoto">
                <button class = "text-center my-3" *ngIf="!isApproveExist && ((f2.remarks.value != 'Not Recommended') && (f2.remarks.value != 'Reject') &&  (f2.remarksReasons.value != 'Not Traceable') )" name="approve" class="greenBtnCls mr-3"  (click)="approveReject('approve', 3, editProfileModal,confirmationModal)" >Approve</button>
                <button class = "text-center my-3"  *ngIf="!isApproveExist && ((f2.remarks.value != 'Recommended') && (f2.remarks.value != 'Approve'))" name="reject" class="blueBtnCls mr-3"  (click)="approveReject('reject', 4, editProfileModal,confirmationModal)" >Reject</button>
                <button class = "text-center my-3" *ngIf="items.length > 0 && (!isApprovalAuthority || isForwardAvailable) && !isForwardExist && !isApproveExist && f2.remarksReasons.value != 'Not Traceable'" name="approve" class="greenBtnCls mr-3" (click)="approveReject('approve', 1, editProfileModal,aadharAuthentication)">Forward</button>
            </ng-container>
        </div>

        <div *ngIf="items.length > 0 && isApprovalAuthority && certificateName == 'IntegratedCertificate-SuoMoto' && role != 'Tahsildar'">
            <button class = "text-center my-3" name="approve" *ngIf="!isApproveExist && ((f2.remarks.value != 'Not Recommended') && (f2.remarks.value != 'Reject') )" class="greenBtnCls mr-3"  (click)="approveReject('approve', 3, editProfileModal,confirmationModal)" >Approve</button>
            <button class = "text-center my-3" name="reject" *ngIf="!isApproveExist && ((f2.remarks.value != 'Recommended') && (f2.remarks.value != 'Approve'))" class="blueBtnCls mr-3"  (click)="approveReject('reject', 4, editProfileModal,confirmationModal)" >Reject</button>
        </div>
        <button class = "text-center my-3" *ngIf="items.length > 0 && (!isApprovalAuthority || isForwardAvailable) && !isForwardExist && !isApproveExist && (certificateName == 'IntegratedCertificate-SuoMoto' && role != 'Tahsildar')" name="approve" class="greenBtnCls mr-3" (click)="approveReject('approve', 1, editProfileModal,aadharAuthentication)">Forward</button>
    </div>
        <!-- Approve Reject and forward button for suomoto for role Tahsildar -->

       

    </div>
    </div>

 
    <ng-template #editDateTimePickerModal let-modal>
        <div class="modal-header">
         <h5 class="modal-title" id="editDateTimePickerModal">Select Notice Date and Time </h5>
         <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
          <span >&times;</span>
         </button>
        </div>
        
        <div class="modal-body">
            <form [formGroup]="dateTimeformGroup" (ngSubmit)="btngenerateDateTime()">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Schedule Date : <span class="mandatoryCls">*</span></label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input  class="calendarCls form-control"  appBlockCopyPaste placeholder="dd/mm/yyyy" name="e" ngbDatepicker
                        [minDate]="minDate"  [maxDate]="maxDate"   
                        #e="ngbDatepicker" required formControlName="ddDate"
                        [ngClass]="{ 'is-invalid': dateTimeFormSubmitted && z.ddDate.errors }" />
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar"  (click)="e.toggle()" type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="dateTimeFormSubmitted && z.ddDate.errors" class="selectError">
                        <div *ngIf="z.ddDate.errors?.required || z.ddDate.invalid">Please enter valid Date</div>
                      </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label">Schedule Time : <span class="mandatoryCls">*</span></label>
                <div class="col-sm-8">
                    <!-- <ngb-timepicker [(ngModel)]="time" formControlName="time"></ngb-timepicker> -->
                    <ngb-timepicker [(ngModel)]="time" formControlName="ddTime" [meridian]="meridian"></ngb-timepicker>
                    <button class="btn btn-sm btn-outline-{{meridian ? 'success' : 'danger'}}" (click)="toggleMeridian()">
                        Time - {{meridian ? "12 Hour Format" : "24 Hour Format"}}
                    </button>
                    <!-- <app-datepicker></app-datepicker> -->
                </div>
            </div>
            <div class="modal-footer">
                <button  type="submit" name="generateTimeDate" class="btn btn-primary "  (click)="btngenerateDateTime()">Generate</button>
            </div>
            </form>
        </div>
    </ng-template>
   
    <ng-template #editProfileModal let-modal>
        <div class="modal-header">
         <h5 class="modal-title" id="editProfileLabel"> {{modalFormName}} </h5>
         <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
          <span >&times;</span>
         </button>
        </div>
        
        <div class="modal-body">
         <form [formGroup]="emudhraformGroup" >
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Tokens : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-8">
              <select class="selectCls" required formControlName = "ddTokens"  (change)="tokenChange()" [ngClass]="{ 'is-invalid':eMudhraSubmitted && (e.ddTokens.errors || e.ddTokens.value === 'Select') }">
                <option [ngValue]="null" disabled>Select</option>
                <option *ngFor="let obj of tokenList" [ngValue]="JSON.stringify(obj)">{{ obj.keyStoreDisplayName }}</option>
              </select>
              <div *ngIf="eMudhraSubmitted && (e.ddTokens.errors || e.ddTokens.value === 'Select')" class="selectError">
                <div *ngIf="e.ddTokens.errors?.required || e.ddTokens.value === 'Select'">Please select Token</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Certificates : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-8">
              <select class="selectCls" required formControlName = "ddCertificates" (change)="getCertificateName()"   [ngClass]="{ 'is-invalid':eMudhraSubmitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select') }">
                <option [ngValue]="null" disabled>Select</option>
                <option *ngFor="let obj of certificateList" [ngValue]="obj">{{ obj.CommonName }}</option>
              </select>
              <div *ngIf="eMudhraSubmitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select')" class="selectError">
                <div *ngIf="e.ddCertificates.errors?.required || e.ddCertificates.value === 'Select'">Please select Certificate</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Password : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-8">
              <input type="password" name="ddPassword" formControlName="ddPassword" class="inputCls" required 
              [ngClass]="{ 'is-invalid':eMudhraSubmitted && e.ddPassword.errors }"  maxlength="20">
              <div *ngIf="eMudhraSubmitted && e.ddPassword.errors" class="invalid-feedback">
                <div *ngIf="e.ddPassword.errors.required">Please enter Password</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button  type="submit" name="signdocument" class="btn btn-primary " [disabled]="isSigning" (click)="btnSignDoc()">{{modalFormButtonName}} </button>
            <!-- <button  name="close" class="btn btn-secondary" (click)="modal.dismiss()">Close</button> -->
          </div>
         </form>
        </div>
    </ng-template>

    <ng-template #confirmationModal let-modal>
        <div class="modal-header">
         <h5 class="modal-title" id="approveCOnfirmation">Confirmation</h5>
         <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
          <span >&times;</span>
         </button>
        </div>
        
        <div class="modal-body">
            <h6 class="modal-title" id="approveCOnfirmation"> {{statusReason}}  </h6>
          <!-- <p>Are you sure you want to Approve this Request?</p> -->
        </div>
        <div class="modal-footer">
          <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">No</button>
          <button  type="submit" class="greenBtnCls" name="yesApprove" (click)="approveRequest()">Yes</button>
        </div>
    </ng-template>

    <ng-template #aadharAuthentication let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="aadharAuthentication">Aadhar Authentication </h5>
            <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
            <span >&times;</span>
            </button>
        </div>
        
        <div class="modal-body">
            <form [formGroup]="aadharformGroup" >
                <div class="row">
                    <label class="col-sm-4 col-form-label labelCls">Authentication Type : <span class="mandatoryCls">*</span></label>
                    <div class="col-sm-8 labelCls">
                        <input type="radio" name="authenticationType" value="OTP" id="otp"
                        formControlName="authenticationType"><label for="otp" class="mx-2 my-0 radioCls">OTP(One Time
                        Password)</label>
                    <input type="radio" name="authenticationType" value="Biometric" id="Biometric"
                        formControlName="authenticationType"><label for="Biometric" class="mx-2  my-0 radioCls ">Biometric</label>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label labelCls">Aadhar Number : <span class="mandatoryCls">*</span></label>
                    <div class="col-sm-4">
                        <input type="text" name="ddAadharNo" class="inputCls" formControlName="ddAadharNo" readonly
                            [ngClass]="{ 'is-invalid':aadharSubmitted && o.ddAadharNo.errors }"  minlength="12" maxlength="12" appBlockCopyPaste>
                        <!-- <div *ngIf="aadharSubmitted && o.ddAadharNo.errors" class="invalid-feedback">
                            <div *ngIf="o.ddAadharNo?.errors?.required ">Aadhar Number is required</div>
                            <div *ngIf="o.ddAadharNo?.errors?.aadharValidate  || o.ddAadharNo?.errors?.minlength || o.ddAadharNo?.errors?.maxlength">Please enter valid Aadhaar Number</div>
                        </div> -->
                    <!-- <div *ngIf="eMudhraSubmitted && o.ddAadharNo?.errors" class="selectError">
                        <div *ngIf="o.ddAadharNo?.errors?.required || e.ddTokens.value === 'Select'">Please Enter Token</div>
                    </div> -->
                    </div>
                </div>
                <ng-container *ngIf="o.authenticationType.value === 'OTP'">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label"> </label>
                    <div class="col-sm-4">
                        <!-- [disabled]="isSentOTP" -->
                        <button  type="submit" name="otpTimer" class="btn btn-primary " [disabled]="isSentOTP"  (click)="btnSendOTPandTimer()">Send OTP </button>
                        <label class="col-sm-4 col-form-label">{{      display}}</label>
                    </div>
                </div>
                <!-- *ngIf="isSentOTP" -->
                <div  *ngIf="isVerifiedOTP"  class="form-group row">
                    <label class="col-sm-4 col-form-label">OTP : <span class="mandatoryCls">*</span></label>
                    <div class="col-sm-4">
                        <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)">
                        </ngx-otp-input>
                        <!-- <div class="invalid-feedback">
                            <div >Please Enter Valid OTP</div>
                        </div> -->
                        <div *ngIf="invalidOTP"><span class="mandatoryCls">Please Enter Valid OTP</span></div>
                    </div>
                    <!-- <label class="col-sm-4 col-form-label">OTP : <span class="mandatoryCls">*</span></label> -->
                    
                    
                </div>
                <!-- <div class="form-group row">
                    <label class="col-sm-4 col-form-label">OTP : <span class="mandatoryCls">*</span></label>
                    <div class="col-sm-8">
                        <input type="password" name="ddPassword" formControlName="ddPassword" class="inputCls" required 
                        [ngClass]="{ 'is-invalid':eMudhraSubmitted && e.ddPassword.errors }"  maxlength="20">
                        <div *ngIf="eMudhraSubmitted && e.ddPassword.errors" class="invalid-feedback">
                            <div *ngIf="e.ddPassword.errors.required">Please enter Password</div>
                        </div>
                    </div>
                </div> -->
                <!-- *ngIf="isSentOTP" -->
                <div  *ngIf="isVerifiedOTP" class="modal-footer">
                    <!-- [disabled]="isVerifiedOTP" -->
                    <button  type="submit" name="signdocument" class="btn btn-primary "  (click)="btnAadharOTP()">Verify OTP </button>
                </div>
            </ng-container>
            <ng-container *ngIf="o.authenticationType.value === 'Biometric'">
                <div class="btnHolder mt-4">
                    <button *ngIf="!showBiometricPopup" class="btn btn-primary float-right" (click)="biometricBtn()">Biometric Authenticate</button>
                    <button *ngIf="showBiometricPopup" class="btn btn-primary float-right" (click)="checkBiometricStatus()">Check Biometric Status</button>
                </div>
            </ng-container>
            </form>
        </div>
    </ng-template>

    <!-- dasboard count -->
    <ng-template #showReqData let-modal class="modalCls">

        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{dashbardpopupTitle}} Service Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span >×</span>
            </button>
        </div>

        <div class="modal-body" id="transPoup">

            <div class="table-responsive tableFixHead">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Application Number</th>
                            <th class="width32">Service Name</th>
                            <th>Transaction Date</th>
                            <th>Amount</th>
                        </tr>
                    </thead>

                    <tbody *ngIf="(servicesData.length != 0 || servicesData.length != '')">
                        <tr *ngFor="let item of servicesData;let i =index;">
                            <!-- <td>{{(i+1)}}</td> -->
                            <td>{{ ((popupPage-1) * popupPageSize) + (i+1) }}</td>
                            <td>{{ item?.requisitioN_ID }}</td>
                            <td>{{ item?.serviceFullName }}</td>
                            <td>{{ item?.transactioN_DATE | date: 'dd/MM/yyyy h:mm:ss a' }}</td>
                            <td>{{ item?.transactioN_AMOUNT }}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="(servicesData.length ==0 || servicesData.length == '')">
                        <br><tr class="text-center">
                        Records not found...  
                        </tr>
                    </tbody>

                </table>

            </div>
            <ngb-pagination class="d-flex justify-content-end mt-3" *ngIf="totalPageCount > 0" [collectionSize]="totalPageCount" [(page)]="popupPage" [pageSize]="popupPageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="pageChanged(dashbardpopupTitle)">
            </ngb-pagination>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Close</button>
        </div>
    </ng-template>
    <!-- dasboard count -->
    <ng-template #fisheriesTemplate>
        <form  [formGroup]="fisheriesForm">
            <div class="fieldHolder">
                <label class="labelCls">Beneficiary Depending on Fishing Profession <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                <input id="isFishingProfession_Y" type="radio" formControlName="isFishingProfession" value="Yes" class="radioCls mr-2"  (change)="onChangeFisheryProf()">
                <label for="isFishingProfession_Y">Yes</label>
                <input id="isFishingProfession_N" type="radio" formControlName="isFishingProfession" value="No" class="radioCls mx-2"  (change)="onChangeFisheryProf()">
                <label for="isFishingProfession_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && f3.isFishingProfession.errors">
                    <div *ngIf="submitted && f3.isFishingProfession.errors?.required">Please select appropriate value to confirm</div>
                </div>
            </div>
            <ng-container *ngIf="f3.isFishingProfession.value === 'Yes'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">How many years he/she is in the Fishing Profession. ? <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input type="text" name="noOfYears" class="inputCls" formControlName="noOfYears"
                        [ngClass]="{ 'is-invalid':submitted && f3.noOfYears.errors }" numbersOnly maxlength="2">
                      <div *ngIf="submitted && (f3.noOfYears.errors || f3.noOfYears.value == 0 ||  f3.noOfYears.value > 99)" class="selectError">
                        <div *ngIf="f3.noOfYears.errors?.required">Please enter the no of years</div>
                        <div *ngIf="f3.noOfYears.value == 0 ||  f3.noOfYears.value > 99">No of years should be between 1 to 99 </div>
                      </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Type of Fishermen <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                     <select class="selectCls" formControlName="typeOfFishermen"  (change)="onChangeFishermenType()"
                        [ngClass]="{ 'is-invalid':submitted && (f3.typeOfFishermen.errors || f3.typeOfFishermen.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="Marine">Marine</option>
                        <option value="Inland">Inland</option>
                      </select>
                      <div *ngIf="submitted && (f3.typeOfFishermen.errors || f3.typeOfFishermen.value === 'Select')" class="selectError">
                        <div *ngIf="f3.typeOfFishermen.errors?.required || f3.typeOfFishermen.value === 'Select'">Please select the Type of Fishermen</div>
                      </div>
                    </div>
                </div>
                <ng-container *ngIf="f3.typeOfFishermen.value === 'Marine'">
                    <div class="fieldHolder">
                        <label class="labelCls">Marine FCS Member  ? <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                        <input id="marineFCSMember_Y" type="radio" formControlName="marineFCSMember" value="Yes" class="radioCls mr-2"  (change)="onChangeMarineFCSMember()">
                        <label for="marineFCSMember_Y">Yes</label>
                        <input id="marineFCSMember_N" type="radio" formControlName="marineFCSMember" value="No" class="radioCls mx-2"  (change)="onChangeMarineFCSMember()">
                        <label for="marineFCSMember_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f3.marineFCSMember.errors">
                            <div *ngIf="submitted && f3.marineFCSMember.errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    <ng-container *ngIf="f3.marineFCSMember.value === 'Yes'">
                    <div class="fieldHolder mb-2" >
                        <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input type="text" name="marineAdmissionNo" class="inputCls" formControlName="marineAdmissionNo"
                            [ngClass]="{ 'is-invalid':submitted && f3.marineAdmissionNo.errors }" maxlength="100">
                          <div *ngIf="submitted && f3.marineAdmissionNo.errors" class="selectError">
                            <div *ngIf="f3.marineAdmissionNo.errors?.required">Please enter the Admission No</div>
                          </div>
                        </div>
                    </div>
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <div class="input-group">
                                <input class="calendarCls form-control" appBlockCopyPaste
                                       name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                                       [maxDate]="maxDate" required formControlName="marineDateOfAdmission"
                                       [ngClass]="{ 'is-invalid':submitted && f3.marineDateOfAdmission.errors }" >
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                              </div>
                              <div *ngIf="submitted && f3.marineDateOfAdmission.errors" class="selectError">
                                <div *ngIf="f3.marineDateOfAdmission.errors?.required || f3.marineDateOfAdmission.invalid">Please enter valid Date of Admission </div>
                            </div>
                        </div>
                    </div>
                    </ng-container>
                    <div class="fieldHolder">
                        <label class="labelCls">Possess Valid Bio Metric ID Card  ? <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                        <input id="validBiometricId_Y" type="radio" formControlName="validBiometricId" value="Yes" class="radioCls mr-2"  (change)="onChangeBiometricId()">
                        <label for="validBiometricId_Y">Yes</label>
                        <input id="validBiometricId_N" type="radio" formControlName="validBiometricId" value="No" class="radioCls mx-2"  (change)="onChangeBiometricId()">
                        <label for="validBiometricId_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f3.validBiometricId.errors">
                            <div *ngIf="submitted && f3.validBiometricId.errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    <div class="fieldHolder mb-2" *ngIf="f3.validBiometricId.value === 'Yes'">
                        <label class="labelCls">MFID No <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input type="text" name="mfidNo" class="inputCls" formControlName="mfidNo"
                            [ngClass]="{ 'is-invalid':submitted && f3.mfidNo.errors }" maxlength="100">
                          <div *ngIf="submitted && f3.mfidNo.errors" class="selectError">
                            <div *ngIf="f3.mfidNo.errors?.required">Please enter the MFID No</div>
                          </div>
                        </div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Availed Ban Period Relief  ? <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                        <input id="banOnFishingProf_Y" type="radio" formControlName="banOnFishingProf" value="Yes" class="radioCls mr-2"  (change)="onChangeBanFishing()">
                        <label for="banOnFishingProf_Y">Yes</label>
                        <input id="banOnFishingProf_N" type="radio" formControlName="banOnFishingProf" value="No" class="radioCls mx-2"  (change)="onChangeBanFishing()">
                        <label for="banOnFishingProf_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f3.banOnFishingProf.errors">
                            <div *ngIf="submitted && f3.banOnFishingProf.errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    <div class="fieldHolder mb-2" *ngIf="f3.banOnFishingProf.value === 'Yes'">
                        <label class="labelCls">Copy of Bank entry <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" name="copyOfBankEntry" formControlName = "copyOfBankEntry" [ngClass]="{ 'is-invalid':submitted && f3.copyOfBankEntry.errors }" accept=".pdf" (change) = "onCopyBankEntrySelected($event)" >
                                    <label class="custom-file-label" #copyOfBankEntryLabel for="copyOfBankEntry"><i class="fas fa-search"></i>No File choosen</label>
                                </div>
                                <div class="clearCls" (click)="clearFileUpload('copyOfBankEntry', 'copyOfBankEntryLabel')" *ngIf="f3.copyOfBankEntry.value"></div>
                              </div>
                              <div *ngIf="submitted && f3.copyOfBankEntry.errors" class="selectError">
                                  <div *ngIf="f3.copyOfBankEntry.errors?.required">Please upload Document</div>
                                <div *ngIf="f3.copyOfBankEntry.errors?.inValidExt && !f3.copyOfBankEntry.errors?.required">Please upload the file in PDF Format</div>
                                <div *ngIf="f3.copyOfBankEntry.errors?.inValidSize && !f3.copyOfBankEntry.errors?.inValidExt && !f3.copyOfBankEntry.errors?.required">File size exceeding 1MB</div>  
                              </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="f3.typeOfFishermen.value === 'Inland'">
                    <div class="fieldHolder">
                        <label class="labelCls">Inland FCS Member   ? <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                        <input id="inlandFCSMember_Y" type="radio" formControlName="inlandFCSMember" value="Yes" class="radioCls mr-2"  (change)="onChangeInlandFCSMember()">
                        <label for="inlandFCSMember_Y">Yes</label>
                        <input id="inlandFCSMember_N" type="radio" formControlName="inlandFCSMember" value="No" class="radioCls mx-2"  (change)="onChangeInlandFCSMember()">
                        <label for="inlandFCSMember_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f3.inlandFCSMember.errors">
                            <div *ngIf="submitted && f3.inlandFCSMember.errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    <ng-container *ngIf="f3.inlandFCSMember.value === 'Yes'">
                    <div class="fieldHolder mb-2" >
                        <label class="labelCls">Admission No  <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input type="text" name="inlandAdmissionNo" class="inputCls" formControlName="inlandAdmissionNo"
                            [ngClass]="{ 'is-invalid':submitted && f3.inlandAdmissionNo.errors }" maxlength="100">
                          <div *ngIf="submitted && f3.inlandAdmissionNo.errors" class="selectError">
                            <div *ngIf="f3.inlandAdmissionNo.errors?.required">Please enter the Admission No</div>
                          </div>
                        </div>
                    </div>
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">No of Tanks under FCS<span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input type="text" name="inlandNoOfTanks" class="inputCls" formControlName="inlandNoOfTanks"
                            [ngClass]="{ 'is-invalid':submitted && f3.inlandNoOfTanks.errors }" numbersOnly maxlength="50">
                          <div *ngIf="submitted && f3.inlandNoOfTanks.errors" class="selectError">
                            <div *ngIf="f3.inlandNoOfTanks.errors?.required">Please enter the No of Tanks under FCS</div>
                          </div>
                        </div>
                    </div>
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Extent under FCS <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input type="text" name="inlandExtent" class="inputCls" formControlName="inlandExtent"
                            [ngClass]="{ 'is-invalid':submitted && f3.inlandExtent.errors }">
                          <div *ngIf="submitted && f3.inlandExtent.errors" class="selectError">
                            <div *ngIf="f3.inlandExtent.errors?.required">Please enter the Extent under FCS </div>
                          </div>
                        </div>
                    </div>
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Date of Admission <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <div class="input-group">
                                <input class="calendarCls form-control" appBlockCopyPaste
                                       name="d"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate"
                                       [maxDate]="maxDate" required formControlName="inlandDateOfAdmission"
                                       [ngClass]="{ 'is-invalid':submitted && f3.inlandDateOfAdmission.errors }" >
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                                </div>
                              </div>
                              <div *ngIf="submitted && f3.inlandDateOfAdmission.errors" class="selectError">
                                <div *ngIf="f3.inlandDateOfAdmission.errors?.required || f3.inlandDateOfAdmission.invalid">Please enter valid Date of Admission </div>
                            </div>
                        </div>
                    </div>
                    </ng-container>
                    <div class="fieldHolder">
                        <label class="labelCls">Individual License holder  ? <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                        <input id="individualLicenceHolder_Y" type="radio" formControlName="individualLicenceHolder" value="Yes" class="radioCls mr-2"  (change)="onChangeLicenceHolder()">
                        <label for="individualLicenceHolder_Y">Yes</label>
                        <input id="individualLicenceHolder_N" type="radio" formControlName="individualLicenceHolder" value="No" class="radioCls mx-2"  (change)="onChangeLicenceHolder()">
                        <label for="individualLicenceHolder_N">No</label>
                        </div>
                        <div class="selectError" *ngIf="submitted && f3.individualLicenceHolder.errors">
                            <div *ngIf="submitted && f3.individualLicenceHolder.errors?.required">Please select appropriate value to confirm</div>
                        </div>
                    </div>
                    <div class="fieldHolder mb-2" *ngIf="f3.individualLicenceHolder.value === 'Yes'">
                        <label class="labelCls">Copy of the license issued <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <div class="input-group mb-3">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" name="copyOfLicence" formControlName = "copyOfLicence" [ngClass]="{ 'is-invalid':submitted && f3.copyOfLicence.errors }" accept=".pdf" (change) = "onCopyOfLicenceSelected($event)" >
                                    <label class="custom-file-label" #copyOfLicenceLabel for="copyOfLicence"><i class="fas fa-search"></i>No File choosen</label>
                                </div>
                                <div class="clearCls" (click)="clearFileUpload('copyOfLicence', 'copyOfLicenceLabel')" *ngIf="f3.copyOfLicence.value"></div>
                              </div>
                              <div *ngIf="submitted && f3.copyOfLicence.errors" class="selectError">
                                  <div *ngIf="f3.copyOfLicence.errors?.required">Please upload Document</div>
                                <div *ngIf="f3.copyOfLicence.errors?.inValidExt && !f3.copyOfLicence.errors?.required">Please upload the file in PDF Format</div>
                                <div *ngIf="f3.copyOfLicence.errors?.inValidSize && !f3.copyOfLicence.errors?.inValidExt && !f3.copyOfLicence.errors?.required">File size exceeding 1MB</div>  
                              </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </form>
    </ng-template>
    <ng-template #dappuArtistsTemplate>
        <form  [formGroup]="dappuArtistsForm">
            <div class="fieldHolder">
                <label class="labelCls">Whether Dappu Instrument is in the house <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                <input id="instrumentInhouse_Y" type="radio" formControlName="instrumentInhouse" value="Yes" class="radioCls mr-2" >
                <label for="instrumentInhouse_Y">Yes</label>
                <input id="instrumentInhouse_N" type="radio" formControlName="instrumentInhouse" value="No" class="radioCls mx-2"  >
                <label for="instrumentInhouse_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && d.instrumentInhouse.errors">
                    <div *ngIf="submitted && d.instrumentInhouse.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Whether the applicant is able to play the Dappu properly atleast for a minute <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                <input id="playDappu_Y" type="radio" formControlName="playDappu" value="Yes" class="radioCls mr-2" >
                <label for="playDappu_Y">Yes</label>
                <input id="playDappu_N" type="radio" formControlName="playDappu" value="No" class="radioCls mx-2"  >
                <label for="playDappu_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && d.playDappu.errors">
                    <div *ngIf="submitted && d.playDappu.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Whether beneficiary play Dappu solo or as part of Group <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                <input id="dappuType_solo" type="radio" formControlName="dappuType" value="Solo" class="radioCls mr-2" >
                <label for="dappuType_solo">Solo</label>
                <input id="dappuType_group" type="radio" formControlName="dappuType" value="Group" class="radioCls mx-2"  >
                <label for="dappuType_group">Group</label>
                </div>
                <div class="selectError" *ngIf="submitted && d.dappuType.errors">
                    <div *ngIf="submitted && d.dappuType.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <div class="fieldHolder mb-2">
                <label class="labelCls">From how many years he is playing Dappu Instrument <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input type="text" name="noOfYears" class="inputCls" formControlName="noOfYears"
                    [ngClass]="{ 'is-invalid':submitted && d.noOfYears.errors }" numbersOnly maxlength="2">
                  <div *ngIf="submitted && (d.noOfYears.errors || d.noOfYears.value == 0 ||  d.noOfYears.value > 99)" class="selectError">
                    <div *ngIf="d.noOfYears.errors?.required">Please enter the no of years</div>
                    <div *ngIf="d.noOfYears.value == 0 ||  d.noOfYears.value > 99">No of years should be between 1 to 99 </div>
                  </div>
                </div>
            </div>
            <div class="fieldHolder mb-2">
                <label class="labelCls">Date of his last program playing in a public place with date and place <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <div class="input-group">
                        <input class="calendarCls form-control" appBlockCopyPaste
                               name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate"
                               [maxDate]="maxDate" required formControlName="lastProgDate"
                               [ngClass]="{ 'is-invalid':submitted && d.lastProgDate.errors }" >
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                        </div>
                      </div>
                      <div *ngIf="submitted && d.lastProgDate.errors" class="selectError">
                        <div *ngIf="d.lastProgDate.errors?.required || d.lastProgDate.invalid">Please enter valid Date of last program </div>
                    </div>
                </div>
            </div>
            <div class="fieldHolder mb-2">
                <label class="labelCls"></label>
                <div class="valueCls">
                    <input type="text" name="address" class="inputCls" formControlName="address"
                    [ngClass]="{ 'is-invalid':submitted && d.address.errors }" maxlength="250">
                  <div *ngIf="submitted && d.address.errors" class="selectError">
                    <div *ngIf="d.address.errors?.required">Please enter the address</div>
                  </div>
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Whether any of the member of the family already getting Dappu Artists pension<span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                <input id="existPension_Y" type="radio" formControlName="existPension" value="Yes" class="radioCls mr-2"  (change)="onChangeExistsPension()">
                <label for="existPension_Y">Yes</label>
                <input id="existPension_N" type="radio" formControlName="existPension" value="No" class="radioCls mx-2"  (change)="onChangeExistsPension()">
                <label for="existPension_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && d.existPension.errors">
                    <div *ngIf="submitted && d.existPension.errors?.required">Please select appropriate value to confirm</div>
                </div>
            </div>
            <ng-container *ngIf="d.existPension.value === 'Yes'">
                <div class="fieldHolder mb-2" >
                    <label class="labelCls">Pension Id of the family member<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input type="text" name="pensionId" class="inputCls" formControlName="pensionId"
                        [ngClass]="{ 'is-invalid':submitted && d.pensionId.errors }" maxlength="50">
                      <div *ngIf="submitted && d.pensionId.errors" class="selectError">
                        <div *ngIf="d.pensionId.errors?.required">Please enter the Pension Id of the family member</div>
                      </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </ng-template>
    <!-- cobbler pension template -->
    <ng-template #traditionalCobblerTemplate>
        <form [formGroup]="traditionalCobblerform">
            <div class="fieldHolder mb-2">
                <label class="labelCls">Whether the cobbling is the main occupation of the applicant without any principal source of income? <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input id="isCobblingMainOcc_Y" type="radio" 
                        formControlName="isCobblingMainOcc" name="isCobblingMainOcc" value="Yes" class="radioCls mr-2" />
                    <label for="isCobblingMainOcc_Y">Yes</label>
                    <input id="isCobblingMainOcc_N" type="radio" 
                        formControlName="isCobblingMainOcc" name="isCobblingMainOcc" value="No" class="radioCls mx-2" />
                    <label for="isCobblingMainOcc_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && f4.isCobblingMainOcc.errors">
                    <div *ngIf="f4.isCobblingMainOcc.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <div class="fieldHolder mb-2">  
                <label class="labelCls">Whether beneficiary having his own cobbler shop? <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input id="isHavingCobblerShop_Y" type="radio" 
                        formControlName="isHavingCobblerShop" name="isHavingCobblerShop" value="Yes" class="radioCls mr-2" />
                    <label for="isHavingCobblerShop_Y">Yes</label>
                    <input id="isHavingCobblerShop_N" type="radio" 
                        formControlName="isHavingCobblerShop" name="isHavingCobblerShop" value="No" class="radioCls mx-2" />
                    <label for="isHavingCobblerShop_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && f4.isHavingCobblerShop.errors">
                    <div *ngIf="f4.isHavingCobblerShop.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <ng-container *ngIf="f4.isHavingCobblerShop?.value === 'Yes'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Venue of the shop <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input class="inputCls" type="text" name="cobblingAddress" formControlName="cobblingAddress"
                            [ngClass]="{ 'is-invalid':submitted && f4.cobblingAddress.errors }" maxlength="250" />
                        <div *ngIf="submitted && f4.cobblingAddress.errors" class="selectError">
                            <div *ngIf="f4.cobblingAddress.errors?.required">Please enter address</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="f4.isHavingCobblerShop?.value === 'No'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">If there is no shop, where does he perform the cobbling activity? <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input class="inputCls" type="text" name="cobblingAddress" formControlName="cobblingAddress"
                            [ngClass]="{ 'is-invalid':submitted && f4.cobblingAddress.errors }" maxlength="250" />
                        <div *ngIf="submitted && f4.cobblingAddress.errors" class="selectError">
                            <div *ngIf="f4.cobblingAddress.errors?.required">Please enter address</div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="fieldHolder mb-2">
                <label class="labelCls">From how many years he is in cobbler occupation ? <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input class="inputCls" type="text" name="noOfYearsIntoCobbling" formControlName="noOfYearsIntoCobbling"
                        [ngClass]="{ 'is-invalid':submitted && f4.noOfYearsIntoCobbling.errors }" numbersOnly maxlength="2" />
                    <div class="selectError" *ngIf="submitted && (f4.noOfYearsIntoCobbling.errors || f4.noOfYearsIntoCobbling.value == 0 ||  f4.noOfYearsIntoCobbling.value > 99)">
                        <div *ngIf="submitted && f4.noOfYearsIntoCobbling.errors?.required">Please provide appropriate value</div>
                        <div *ngIf="!f4.noOfYearsIntoCobbling.errors?.required && (f4.noOfYearsIntoCobbling.value == 0 ||  f4.noOfYearsIntoCobbling.value > 99)">No of years should be between 1 to 99 </div>
                    </div>
                </div>
            </div>
            <div class="fieldHolder mb-2">
                <label class="labelCls">Whether any of the member of the family already getting Cobbler pension. <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input id="receivingCobblerPension_Y" type="radio" 
                        formControlName="receivingCobblerPension" name="receivingCobblerPension" 
                        value="Yes" class="radioCls mr-2" (change)="onChangeExistsCobblerPension()" />
                    <label for="receivingCobblerPension_Y">Yes</label>
                    <input id="receivingCobblerPension_N" type="radio" 
                        formControlName="receivingCobblerPension" name="receivingCobblerPension" 
                        value="No" class="radioCls mx-2" (change)="onChangeExistsCobblerPension()" />
                    <label for="receivingCobblerPension_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && f4.receivingCobblerPension.errors">
                    <div *ngIf="f4.receivingCobblerPension.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <ng-container *ngIf="f4.receivingCobblerPension?.value === 'Yes'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Pension id of the family member <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input class="inputCls" type="text" name="pensionId" formControlName="pensionId"
                            [ngClass]="{ 'is-invalid':submitted && f4.pensionId.errors }" />
                        <div class="selectError" *ngIf="submitted && f4.pensionId.errors">
                            <div *ngIf="f4.pensionId.errors?.required">Please provide pension id</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </ng-template>

    <!-- Handloom Weaver Pensions template -->
    <ng-template #handloomWeaverTemplate>
        <form [formGroup]="handloomWeaverForm">
            <div class="fieldHolder mb-2">
                <label class="labelCls">Whether Loom is present in the House? <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input id="isLoomPresentInHouse_Y" type="radio" 
                        formControlName="isLoomPresentInHouse" name="isLoomPresentInHouse" value="Yes" class="radioCls mr-2" (change)="onChangeIsLoomPresentInHouse()" />
                    <label for="isLoomPresentInHouse_Y">Yes</label>
                    <input id="isLoomPresentInHouse_N" type="radio" 
                        formControlName="isLoomPresentInHouse" name="isLoomPresentInHouse" value="No" class="radioCls mx-2" (change)="onChangeIsLoomPresentInHouse()" />
                    <label for="isLoomPresentInHouse_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && f5.isLoomPresentInHouse.errors">
                    <div *ngIf="f5.isLoomPresentInHouse.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <div class="fieldHolder mb-2" *ngIf="f5.isLoomPresentInHouse?.value === 'Yes'">
                <label class="labelCls">Type of Loom<span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <select class="selectCls" required formControlName = "loomType"
                        [ngClass]="{ 'is-invalid':submitted && (f5.loomType.errors || f5.loomType.value === 'null' || f5.loomType.value == '') }">
                        <option [ngValue]="null">Select</option>
                        <option value="Pit loom">Pit loom</option>
                        <option value="Frame loom">Frame loom</option>
                    </select>
                    <div class="selectError" *ngIf="submitted && f5.loomType.errors">
                        <div *ngIf="f5.loomType.errors?.required">Please select appropriate value</div>
                    </div>
                </div>
            </div>
            <div class="fieldHolder mb-2" *ngIf="f5.isLoomPresentInHouse?.value === 'Yes'">
                <label class="labelCls">Loom is working condition <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input id="loomWorkingCondition_Y" type="radio" 
                        formControlName="loomWorkingCondition" name="loomWorkingCondition" value="Yes" class="radioCls mr-2" (change)="onChangeLoomWorkingCondition()" />
                    <label for="loomWorkingCondition_Y">Yes</label>
                    <input id="loomWorkingCondition_N" type="radio" 
                        formControlName="loomWorkingCondition" name="loomWorkingCondition" value="No" class="radioCls mx-2" (change)="onChangeLoomWorkingCondition()" />
                    <label for="loomWorkingCondition_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && f5.loomWorkingCondition.errors">
                    <div *ngIf="f5.loomWorkingCondition.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <ng-container *ngIf="f5.isLoomPresentInHouse?.value === 'Yes' && f5.loomWorkingCondition?.value === 'Yes'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Supporting Document <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <div class="input-group">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" name="loomWorkingConditionDocument" formControlName = "loomWorkingConditionDocument"
                                    [ngClass]="{ 'is-invalid':submitted && f5.loomWorkingConditionDocument.errors }" accept=".jpg,.jpeg" (change)="loomWorkingConditionDocSelected($event)" >
                                <label class="custom-file-label" #loomWorkingConditionLabel for="loomWorkingConditionDocument"><i class="fas fa-search"></i>No File choosen</label>
                            </div>
                            <div class="clearCls" (click)="clearFileUpload('loomWorkingConditionDocument', 'loomWorkingConditionLabel')" *ngIf="f5.loomWorkingConditionDocument.value"></div>
                        </div>
                        <div *ngIf="submitted && f5.loomWorkingConditionDocument.errors" class="selectError">
                            <div *ngIf="f5.loomWorkingConditionDocument.errors?.required">Please upload Document</div>
                          <div *ngIf="f5.loomWorkingConditionDocument.errors?.inValidExt && !f5.loomWorkingConditionDocument.errors.required">Please upload file in jpg/jpeg format</div>
                          <div *ngIf="f5.loomWorkingConditionDocument.errors?.inValidSize && !f5.loomWorkingConditionDocument.errors.inValidExt && !f5.loomWorkingConditionDocument.errors.required">File size exceeding 1MB</div>  
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="f5.isLoomPresentInHouse?.value === 'Yes' && f5.loomWorkingCondition?.value === 'Yes'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">From how many years He/she is depending on weaving profession (at least from last Two years)? <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input class="inputCls" type="text" name="noOfYearsIntoWeaving" formControlName="noOfYearsIntoWeaving"
                            [ngClass]="{ 'is-invalid':submitted && f5.noOfYearsIntoWeaving.errors }" numbersOnly maxlength="2" />
                        <div class="selectError" *ngIf="submitted && (f5.noOfYearsIntoWeaving.errors || f5.noOfYearsIntoWeaving.value == 0 ||  f5.noOfYearsIntoWeaving.value > 99)">
                            <div *ngIf="submitted && f5.noOfYearsIntoWeaving.errors?.required">Please provide appropriate value</div>
                            <div *ngIf="!f5.noOfYearsIntoWeaving.errors?.required && (f5.noOfYearsIntoWeaving.value == 0 ||  f5.noOfYearsIntoWeaving.value > 99)">No of years should be between 1 to 99 </div>
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Working Under<span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <select class="selectCls" required formControlName = "workingUnder"
                            [ngClass]="{ 'is-invalid':submitted && (f5.workingUnder.errors || f5.workingUnder.value === 'null' || f5.workingUnder.value == '') }">
                            <option [ngValue]="null">Select</option>
                            <option value="HWCS">HWCS</option>
                            <option value="Master Weaver">Master Weaver</option>
                            <option value="Individual">Individual</option>
                        </select>
                        <div class="selectError" *ngIf="submitted && f5.workingUnder.errors">
                            <div *ngIf="f5.workingUnder.errors?.required">Please select appropriate value</div>
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Any Household benefited under Nethanna Nestham? <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input id="benifittedUnderNethannaNestham_Y" type="radio" 
                            formControlName="benifittedUnderNethannaNestham" name="benifittedUnderNethannaNestham" value="Yes" class="radioCls mr-2" />
                        <label for="benifittedUnderNethannaNestham_Y">Yes</label>
                        <input id="benifittedUnderNethannaNestham_N" type="radio" 
                            formControlName="benifittedUnderNethannaNestham" name="benifittedUnderNethannaNestham" value="No" class="radioCls mx-2" />
                        <label for="benifittedUnderNethannaNestham_N">No</label>
                    </div>
                    <div class="selectError" *ngIf="submitted && f5.benifittedUnderNethannaNestham.errors">
                        <div *ngIf="f5.benifittedUnderNethannaNestham.errors?.required">Please select appropriate value</div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Whether any of the Member of the family already getting weaver pension? <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input id="receivingWeaverPension_Y" type="radio" 
                            formControlName="receivingWeaverPension" name="receivingWeaverPension" 
                            value="Yes" class="radioCls mr-2" (change)="onChangeExistsWeaverPension()" />
                        <label for="receivingWeaverPension_Y">Yes</label>
                        <input id="receivingWeaverPension_N" type="radio" 
                            formControlName="receivingWeaverPension" name="receivingWeaverPension" 
                            value="No" class="radioCls mx-2" (change)="onChangeExistsWeaverPension()" />
                        <label for="receivingWeaverPension_N">No</label>
                    </div>
                    <div class="selectError" *ngIf="submitted && f5.receivingWeaverPension.errors">
                        <div *ngIf="f5.receivingWeaverPension.errors?.required">Please select appropriate value</div>
                    </div>
                </div>
                <ng-container *ngIf="f5.receivingWeaverPension?.value === 'Yes'">
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Pension id of the family member <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <input class="inputCls" type="text" name="pensionId" formControlName="pensionId"
                                [ngClass]="{ 'is-invalid':submitted && f5.pensionId.errors }" />
                            <div class="selectError" *ngIf="submitted && f5.pensionId.errors">
                                <div *ngIf="f5.pensionId.errors?.required">Please provide pension id</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </form>    
    </ng-template>

    <ng-template #handloomWeaverADODHTOTemplate>
        <form [formGroup]="weaverADOHDTOForm">
            <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'HWCS'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Date of Admission into Society. <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <div class="input-group">
                            <input class="calendarCls form-control" appBlockCopyPaste
                                   name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate"
                                   [maxDate]="maxDate" required formControlName="admissionDate"
                                   [ngClass]="{ 'is-invalid':submitted && f6.admissionDate.errors }" >
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                            </div>
                          </div>
                          <div *ngIf="submitted && f6.admissionDate.errors" class="selectError">
                            <div *ngIf="f6.admissionDate.errors?.required || f6.admissionDate.invalid">Please enter valid Date of last program </div>
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Undertaking from the President / Secretary / Accountant of the PHWCS that they are providing work to weaver member. <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input id="undertakingFromPHWC_Y" type="radio" 
                            formControlName="undertakingFromPHWC" name="undertakingFromPHWC" value="Yes" class="radioCls mr-2" (change)="onChangeUndertakingFromPHWC()"/>
                        <label for="undertakingFromPHWC_Y">Yes</label>
                        <input id="undertakingFromPHWC_N" type="radio" 
                            formControlName="undertakingFromPHWC" name="undertakingFromPHWC" value="No" class="radioCls mx-2" (change)="onChangeUndertakingFromPHWC()" />
                        <label for="undertakingFromPHWC_N">No</label>
                    </div>
                    <div class="selectError" *ngIf="submitted && f6.undertakingFromPHWC.errors">
                        <div *ngIf="f6.undertakingFromPHWC.errors?.required">Please select appropriate value</div>
                    </div>
                </div>
                <ng-container *ngIf="f6.undertakingFromPHWC?.value === 'Yes'">
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Supporting Document <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" name="undertakingFromPHWCDocument" formControlName = "undertakingFromPHWCDocument" [ngClass]="{ 'is-invalid':submitted && f6.undertakingFromPHWCDocument.errors }" accept=".pdf" (change) = "onUndertakingFromPHWCDocSelected($event)" >
                                    <label class="custom-file-label" #undertakingFromPHWCLabel for="undertakingFromPHWCDocument"><i class="fas fa-search"></i>No File choosen</label>
                                </div>
                                <div class="clearCls" (click)="clearFileUpload('undertakingFromPHWCDocument', 'undertakingFromPHWCLabel')" *ngIf="f6.undertakingFromPHWCDocument.value"></div>
                            </div>
                            <div *ngIf="submitted && f6.undertakingFromPHWCDocument.errors" class="selectError">
                                <div *ngIf="f6.undertakingFromPHWCDocument.errors?.required">Please upload Document</div>
                              <div *ngIf="f6.undertakingFromPHWCDocument.errors?.inValidExt && !f6.undertakingFromPHWCDocument.errors.required">Please upload the file in PDF Format</div>
                              <div *ngIf="f6.undertakingFromPHWCDocument.errors?.inValidSize && !f6.undertakingFromPHWCDocument.errors.inValidExt && !f6.undertakingFromPHWCDocument.errors.required">File size exceeding 1MB</div>  
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">The weaver produced the production at least 15 days on a month for PHWCS <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input id="productionAtPHWC_Y" type="radio" 
                            formControlName="productionAtPHWC" name="productionAtPHWC" value="Yes" class="radioCls mr-2" (change)="onChangeProductionAtPHWC()"/>
                        <label for="productionAtPHWC_Y">Yes</label>
                        <input id="productionAtPHWC_N" type="radio" 
                            formControlName="productionAtPHWC" name="productionAtPHWC" value="No" class="radioCls mx-2" (change)="onChangeProductionAtPHWC()"/>
                        <label for="productionAtPHWC_N">No</label>
                    </div>
                    <div class="selectError" *ngIf="submitted && f6.productionAtPHWC.errors">
                        <div *ngIf="f6.productionAtPHWC.errors?.required">Please select appropriate value</div>
                    </div>
                </div>
                <ng-container *ngIf="f6.productionAtPHWC?.value === 'Yes'">
                    <div class="fieldHolder mb-2">
                        <label class="labelCls">Supporting Document <span class="mandatoryCls">*</span></label>
                        <div class="valueCls">
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" name="productionAtPHWCDocument" formControlName = "productionAtPHWCDocument" [ngClass]="{ 'is-invalid':submitted && f6.productionAtPHWCDocument.errors }" accept=".pdf" (change) = "onProductionAtPHWCDocSelected($event)" >
                                    <label class="custom-file-label" #productionAtPHWCLabel for="productionAtPHWCDocument"><i class="fas fa-search"></i>No File choosen</label>
                                </div>
                                <div class="clearCls" (click)="clearFileUpload('productionAtPHWCDocument', 'productionAtPHWCLabel')" *ngIf="f6.productionAtPHWCDocument.value"></div>
                            </div>
                            <div *ngIf="submitted && f6.productionAtPHWCDocument.errors" class="selectError">
                                <div *ngIf="f6.productionAtPHWCDocument.errors?.required">Please upload Document</div>
                              <div *ngIf="f6.productionAtPHWCDocument.errors?.inValidExt && !f6.productionAtPHWCDocument.errors.required">Please upload the file in PDF Format</div>
                              <div *ngIf="f6.productionAtPHWCDocument.errors?.inValidSize && !f6.productionAtPHWCDocument.errors.inValidExt && !f6.productionAtPHWCDocument.errors.required">File size exceeding 1MB</div>  
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'Master Weaver'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Date of Working with Master Weaver <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <div class="input-group">
                            <input class="calendarCls form-control" appBlockCopyPaste
                                   name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate"
                                   [maxDate]="maxDate" required formControlName="workingDate"
                                   [ngClass]="{ 'is-invalid':submitted && f6.workingDate.errors }" >
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                            </div>
                          </div>
                          <div *ngIf="submitted && f6.workingDate.errors" class="selectError">
                            <div *ngIf="f6.workingDate.errors?.required || f6.workingDate.invalid">Please enter valid Date of last program </div>
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Proof of wages transferred to weaver account through online for the past (2) years. <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <div class="input-group">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" name="wagesProofDocument" formControlName = "wagesProofDocument" [ngClass]="{ 'is-invalid':submitted && f6.wagesProofDocument.errors }" accept=".pdf" (change) = "onWagesProofDocSelected($event)" >
                                <label class="custom-file-label" #wagesProofLabel for="wagesProofDocument"><i class="fas fa-search"></i>No File choosen</label>
                            </div>
                            <div class="clearCls" (click)="clearFileUpload('wagesProofDocument', 'wagesProofLabel')" *ngIf="f6.wagesProofDocument.value"></div>
                        </div>
                        <div *ngIf="submitted && f6.wagesProofDocument.errors" class="selectError">
                            <div *ngIf="f6.wagesProofDocument.errors?.required">Please upload Document</div>
                          <div *ngIf="f6.wagesProofDocument.errors?.inValidExt && !f6.wagesProofDocument.errors.required">Please upload the file in PDF Format</div>
                          <div *ngIf="f6.wagesProofDocument.errors?.inValidSize && !f6.wagesProofDocument.errors.inValidExt && !f6.wagesProofDocument.errors.required">File size exceeding 1MB</div>  
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Undertaking from the Master Weaver that he is providing work to the weaver since (2) years. <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <div class="input-group">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" name="undertakingMasterDocument" formControlName = "undertakingMasterDocument" [ngClass]="{ 'is-invalid':submitted && f6.undertakingMasterDocument.errors }" accept=".pdf" (change) = "onUndertakingMasterDocSelected($event)" >
                                <label class="custom-file-label" #undertakingFromMasterLabel for="undertakingMasterDocument"><i class="fas fa-search"></i>No File choosen</label>
                            </div>
                            <div class="clearCls" (click)="clearFileUpload('undertakingMasterDocument', 'undertakingFromMasterLabel')" *ngIf="f6.undertakingMasterDocument.value"></div>
                        </div>
                        <div *ngIf="submitted && f6.undertakingMasterDocument.errors" class="selectError">
                            <div *ngIf="f6.undertakingMasterDocument.errors?.required">Please upload Document</div>
                          <div *ngIf="f6.undertakingMasterDocument.errors?.inValidExt && !f6.undertakingMasterDocument.errors.required">Please upload the file in PDF Format</div>
                          <div *ngIf="f6.undertakingMasterDocument.errors?.inValidSize && !f6.undertakingMasterDocument.errors.inValidExt && !f6.undertakingMasterDocument.errors.required">File size exceeding 1MB</div>  
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="WEAWWDSHistory[0]?.handloomWeaverData?.workingUnder == 'Individual'">
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Date of Erection of loom. <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <div class="input-group">
                            <input class="calendarCls form-control" appBlockCopyPaste
                                   name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate"
                                   [maxDate]="maxDate" required formControlName="erectionLoomDate"
                                   [ngClass]="{ 'is-invalid':submitted && f6.erectionLoomDate.errors }" >
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                            </div>
                          </div>
                          <div *ngIf="submitted && f6.erectionLoomDate.errors" class="selectError">
                            <div *ngIf="f6.erectionLoomDate.errors?.required || f6.erectionLoomDate.invalid">Please enter valid Date of last program </div>
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">The Weaver should purchase yarn from NHDC or from private merchant having GST number for the last two years. <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <div class="input-group">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" name="yarnPurchaseDocument" formControlName = "yarnPurchaseDocument" [ngClass]="{ 'is-invalid':submitted && f6.yarnPurchaseDocument.errors }" accept=".pdf" (change) = "onYarnPurchaseDocSelected($event)" >
                                <label class="custom-file-label" #yarnPurchaseLabel for="yarnPurchaseDocument"><i class="fas fa-search"></i>No File choosen</label>
                            </div>
                            <div class="clearCls" (click)="clearFileUpload('yarnPurchaseDocument', 'yarnPurchaseLabel')" *ngIf="f6.yarnPurchaseDocument.value"></div>
                        </div>
                        <div *ngIf="submitted && f6.yarnPurchaseDocument.errors" class="selectError">
                            <div *ngIf="f6.yarnPurchaseDocument.errors?.required">Please upload Document</div>
                          <div *ngIf="f6.yarnPurchaseDocument.errors?.inValidExt && !f6.yarnPurchaseDocument.errors.required">Please upload the file in PDF Format</div>
                          <div *ngIf="f6.yarnPurchaseDocument.errors?.inValidSize && !f6.yarnPurchaseDocument.errors.inValidExt && !f6.yarnPurchaseDocument.errors.required">File size exceeding 1MB</div>  
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Verify the NHDC Yarn Pass Book or Yarn Purchase Bills with GST number for the last (2) years. <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input class="inputCls" type="text" name="yarnPassBookOrBills" formControlName="yarnPassBookOrBills"
                            [ngClass]="{ 'is-invalid':submitted && f6.yarnPassBookOrBills.errors }" />
                        <div class="selectError" *ngIf="submitted && f6.yarnPassBookOrBills.errors">
                            <div *ngIf="submitted && f6.yarnPassBookOrBills.errors?.required">Please provide appropriate value</div>
                        </div>
                    </div>
                </div>
                <div class="fieldHolder mb-2">
                    <label class="labelCls">Is the weaver consuming atleast (1) bundle (approximate value Rs. 4000/-) or silk yarn should be (2) Kgs (approximate value Rs. 10,000/), per month? <span class="mandatoryCls">*</span></label>
                    <div class="valueCls">
                        <input id="isWeaverConsuming_Y" type="radio" 
                            formControlName="isWeaverConsuming" name="isWeaverConsuming" value="Yes" class="radioCls mr-2" />
                        <label for="isWeaverConsuming_Y">Yes</label>
                        <input id="isWeaverConsuming_N" type="radio" 
                            formControlName="isWeaverConsuming" name="isWeaverConsuming" value="No" class="radioCls mx-2" />
                        <label for="isWeaverConsuming_N">No</label>
                    </div>
                    <div class="selectError" *ngIf="submitted && f6.isWeaverConsuming.errors">
                        <div *ngIf="f6.isWeaverConsuming.errors?.required">Please select appropriate value</div>
                    </div>
                </div>
            </ng-container>
            <div class="fieldHolder mb-2">
                <label class="labelCls">Whether enquired with Neighbours regarding loom functioning since last (2) years? <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input id="loomFunctioningEnquired_Y" type="radio" 
                        formControlName="loomFunctioningEnquired" name="loomFunctioningEnquired" value="Yes" class="radioCls mr-2" />
                    <label for="loomFunctioningEnquired_Y">Yes</label>
                    <input id="loomFunctioningEnquired_N" type="radio" 
                        formControlName="loomFunctioningEnquired" name="loomFunctioningEnquired" value="No" class="radioCls mx-2" />
                    <label for="loomFunctioningEnquired_N">No</label>
                </div>
                <div class="selectError" *ngIf="submitted && f6.loomFunctioningEnquired.errors">
                    <div *ngIf="f6.loomFunctioningEnquired.errors?.required">Please select appropriate value</div>
                </div>
            </div>
            <div class="fieldHolder mb-2">
                <label class="labelCls">Which type of Varieties are producing by the Weaving? <span class="mandatoryCls">*</span></label>
                <div class="valueCls">
                    <input class="inputCls" type="text" name="varitiesProduced" formControlName="varitiesProduced"
                        [ngClass]="{ 'is-invalid':submitted && f6.varitiesProduced.errors }" />
                    <div class="selectError" *ngIf="submitted && f6.varitiesProduced.errors">
                        <div *ngIf="submitted && f6.varitiesProduced.errors?.required">Please provide appropriate value</div>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>