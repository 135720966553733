import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
@Component({
  selector: 'app-viksit-bharat-distribution-report',
  templateUrl: './viksit-bharat-distribution-report.component.html',
  styleUrls: ['./viksit-bharat-distribution-report.component.css']
})
export class ViksitBharatDistributionReportComponent implements OnInit {
  form: UntypedFormGroup;
  districts: any[] = [];
  mandals: any[] = [];
  locations: any[]= [];
  format: any;
  formatTypes: any[]= [];
  items: any[] = [];
  showReports:Boolean=false;
  submitted:Boolean = false;
  page = 1;
  pageSize = 10;
  noOfCol: any = 8;
  commonConstants : any = CommonConstants;
  @ViewChild('TABLE') table!: ElementRef;
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService,private router: Router) {
    this.form = this.formBuilder.group({
      district: [null],
      mandal: [null],
      gplocation :[null]
    });
    this.format = 'District Wise';
    this.formatTypes = [{name: 'District Wise', isActive: true},
                        {name:'Mandal Wise', isActive: false},
                        {name:'GP/Location Wise', isActive: false}
  ]
   }

  ngOnInit(): void {
    this.getDistricts();
    this.getReportData();
    this.format = 'District Wise';
    this.form.patchValue({
      district:'All',
      mandal:'All',
      gplocation: 'All',
    });
  }
  get f() { return this.form.controls; }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.GetViksitBharatDistrict).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getDistricts')
        if(responseData.result?.getDistrictDetails) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
         } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.locations = [];
    this.f.mandal.patchValue('All');
    this.f.gplocation.patchValue('All');    
    this.commonService.getRequest(this.commonConstants.GetViksitBharatMandalByDistrictCode + '?DistrictCode=' + this.f['district'].value.districtCode).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'mandals')
        if(responseData.result?.getMandalDetails) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  onMandalChange() {
    this.showReports = false;
    this.locations = [];
   this.f.gplocation.patchValue('All');
    this.commonService.getRequest(this.commonConstants.GetViksitBharatGpByMandal + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'locations')
        if(responseData.result?.getGPDetails) {
          this.locations = this.commonService.sortItems(responseData.result?.getGPDetails, 'gpName');
        } else {
          this.locations = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  
  getFormat(): any {
      if (this.format === 'District Wise' ) {
      this.noOfCol = 8;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 9;
      return this.noOfCol;
    } else {
      this.noOfCol = 11;
      return this.noOfCol;
    }
  }
  getReportData(from?: any): any {    
        this.page = 1;       
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let gpLocation = this.f.gplocation.value === 'All' ? 'All' : this.f.gplocation.value?.gpName;
     if(this.format == 'GP/Location Wise'){
      this.format ='GP Wise';
     }
        let postData: any = '?Flag='+ this.format +'&DistrictID=' + district + '&MandalId=' + mandal +'&GramPanchayatId=' + gpLocation ;
        this.commonService.getRequest(this.commonConstants.getDaynodalIECReport + postData).subscribe({
          next: (responseData: any) => { 
            console.log(typeof(responseData?.result),'result man')
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                 // this.items = this.items.filter(item => item.totalFlag !== 'Y')
                 // this.totalItems = responseData?.result.filter((item: { totalFlag: string; }) => item.totalFlag === 'Y')
                  console.log(this.items,'items')
                 // console.log( this.totalItems ,'total items')
            } else {
              this.items = [];
            //  this.totalItems = [];
              //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
      downloadExcel() {
        let fileName: any = 'Day nodal officer IEC distribution report' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
        const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
        let wscols = [
          {wpx:50},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100}
      ];
      
      ws['!cols'] = wscols;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Day nodal officer report');
        
        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
      }
      saveAsExcelFile(buffer: any, fileName: string) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
      }
      goToHome() {
        this.router.navigate(['/home']);
      }
    
    
}
