<div class="container mt-5 mb-5">
    <div class="content-seperator">
        <h5>Online Payments</h5>
        <p>
            This online payment system is provided by OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS DEPARTMENT.
            The OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS DEPARTMENT may
            update these terms from time to time and any changes will be effective immediately on being
            set out here. Please ensure you are aware of the current terms. The country of domicile for
            OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS DEPARTMENT is India.
        </p>
    </div>
    <div class="content-seperator">
        <h5>Terms &amp; Conditions</h5>
        <p>
            Please read these terms carefully before using the online payment facility. Using the online
            payment facility on this website indicates that you accept these terms. If you do not accept
            these terms do not use this facility.
        </p>
        <p>
            All payments are subject to the following conditions:-
        </p>
        <p>
            The description of services of match making are specific to your need, when you log in with
            your unique password. Normally payment is required in advance (i.e. before you commence
            your activity).
        </p>
        <p>
            All Fees quoted are in Indian Rupees. The OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS DEPARTMENT reserves the right to change the fees at any time.
        </p>
        <ul>
            <li>
                Your payment will normally reach the OFFICE OF THE COMMISIONER AND
                DIRECTOR, GSWS DEPARTMENT account to which you are making a payment
                within two working days.
            </li>
            <li>
                We cannot accept liability for a payment not reaching the correct OFFICE OF THE
                COMMISIONER AND DIRECTOR, GSWS DEPARTMENT account due to you
                quoting an incorrect account number or incorrect personal details. Neither can we
                accept liability if payment is refused or declined by the credit/debit card supplier for
                any reason.
            </li>
            <li>
                If the card supplier declines payment the OFFICE OF THE COMMISIONER AND
                DIRECTOR, GSWS DEPARTMENT is under no obligation to bring this fact to your
                attention. You should check with your bank/credit/debit card supplier that payment
                has been deducted from your account.
            </li>
            <li>
                In no event will the OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS
                DEPARTMENT be liable for any damages whatsoever arising out of the use, inability
                to use, or the results of use of this site, any websites linked to this site, or the materials
                or information contained at any or all such sites, whether based on warranty, contract,
                tort or any other legal theory and whether or not advised of the possibility of such
                damages.
            </li>
            <li>
                Payment gateway Transaction charges are to be borne by the citizens only
            </li>
        </ul>
    </div>
    <div class="content-seperator">
        <h5>Refund Policy/Cancellation Policy</h5>
        <ul>
            <li>
                If the Customer leaves the OFFICE OF THE COMMISIONER AND DIRECTOR,
                GSWS DEPARTMENT before they complete their service period, there shall be no
                entitlement to a refund of paid service fees.
            </li>
            <li>
                Refunds, if applicable, at the discretion of the Management, will only be made to the
                debit/credit card used for the original transaction. For the avoidance of doubt nothing
                in this Policy shall require the OFFICE OF THE COMMISIONER AND
                DIRECTOR, GSWS DEPARTMENT to refund the Fees (or part thereof) unless such
                Fees (or part thereof) have previously been paid.
            </li>
        </ul>
    </div>
    <div class="content-seperator">
        <h5>Privacy Policy</h5>
        <p>
            This Privacy Policy applies to all of the products, services and websites offered by GSWS
            DEPARTMENT. Sometimes, we may post product specific privacy notices or Help Centre
            materials to explain our products in more detail.
        </p>
        <p>
            If you have any questions about this Privacy Policy, please feel free to contact us through our
            website or write to us at <span>support[at]gsws[dot]ap[dot]gov[dot]in</span> for Information we collect and how we use
            it for our OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS
            DEPARTMENT.
        </p>
    </div>
    <div class="content-seperator">
        <h5>Changes to our Privacy Policy</h5>
        <p>
            OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS DEPARTMENT reserves the
            entire right to modify/amend/remove this privacy statement anytime and without any reason.
            Nothing contained herein creates or is intended to create a contract/agreement between
            OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS DEPARTMENT and any
            user visiting the OFFICE OF THE COMMISIONER AND DIRECTOR, GSWS
            DEPARTMENT website or providing identifying information of any kind.
        </p>
    </div>
    <div class="content-seperator">
        <h5>DND Policy</h5>
        <p>
            If you wish to stop any further sms/email alerts/contacts from our side, all you need to do is
            to send an email:<span>support[at]gsws[dot]ap[dot]gov[dot]in</span> with your mobile numbers and you will be
            excluded from the alerts list.
    </div>
    <div class="content-seperator">
        <h5>Contact Details</h5>
        <p>
            Email: <span>support[at]gsws[dot]ap[dot]gov[dot]in</span>
        </p>
    </div>
</div>