import { Component, OnInit, Inject ,ViewChild, ElementRef} from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { NgbModal, NgbModalConfig,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommonService ,WhitespaceValidator, AadharValidator} from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { PoliciesPopupComponent } from '../../shared/policies-popup/policies-popup.component';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { JaganannaScheduleSearchComponent } from '../jagananna-schedule-search/jagananna-schedule-search.component';
import { CaptchaComponent } from 'src/shared/captcha-viewer-popup/captcha/captcha.component';
import { AarogyaSurakshaCampDetailsComponent } from '../aarogya-suraksha-camp-details/aarogya-suraksha-camp-details.component';
@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit {
  applicationNo: any;
  certApplicationNo : any;
  aadharNumber:any='';
  maskedaadharNumber: any='';
  isTextSelected = false;
  adharApplication:any;
  loginDisplay = false;
  commonConstants: any = CommonConstants;
  isAadharapplication:Boolean=false;
  reff:any;
  services:any[]=[];
  @ViewChild('addModal') addModal: any;
  public submitted:Boolean=false;
  page = 1;
  pageSize = 10;
  constructor( @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,private authService: MsalService,
  private modalService: NgbModal, modalConfig: NgbModalConfig, private commonService: CommonService,private formBuilder: UntypedFormBuilder) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
   }

   transactionDataPopup: any = [];
   cntDash: number = 0;
   closeResult:any;
   showSearch: Boolean = false;
   departmentCode = "RV";
   certificateTypeName = "";
   requisitionTypeForCertificate = "";
   @ViewChild('aadharLabel')
  aadharLabel!: ElementRef; 
  //captchaId: any;
  //generatedCode: any;

  isMobile = false;
  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }

    /* this.transactionDataPopup =    [
      { role : "Digital Asistant", transaction_status:"Within SLA", transaction_date:"01-01-2022", status:"Transaction Initiated" },
      { role : "Village Revenue Officer" , transaction_status:"Beyond SLA", transaction_date:"01-01-2022", status:"Application Approved" },
      { role : "Revenue Inspector" , transaction_status:"Open", transaction_date:"01-01-2022", status:"Application Recommended" },
      { role : "Revenue Inspector" , transaction_status:"Closed", transaction_date:"01-01-2022", status:"Application Recommended" }
    ]; */
      // { role : "Revenue Inspector" , transaction_status:"Open", transaction_date:"01-01-2022", status:"Application Recommended" },
      // { role : "Tahsildar" , transaction_status:"Closed", transaction_date:"01-01-2022", status:"Transaction Closed" },
        /* this.transactionDataPopup =    {
      data: [
        { role : "Digital Asistant", transaction_status:"Within SLA", transaction_date:"01-01-2022", status:"Transaction Initiated" },
        { role : "Village Revenue Officer" , transaction_status:"Beyond SLA", transaction_date:"01-01-2022", status:"Application Approved" },
        { role : "Revenue Inspector" , transaction_status:"Open", transaction_date:"01-01-2022", status:"Application Recommended" },
        { role : "Revenue Inspector" , transaction_status:"Closed", transaction_date:"01-01-2022", status:"Application Recommended" },
        // { role : "Revenue Inspector" , transaction_status:"Open", transaction_date:"01-01-2022", status:"Application Recommended" },
        // { role : "Tahsildar" , transaction_status:"Closed", transaction_date:"01-01-2022", status:"Transaction Closed" },
      ],
      tableData : {
        district: "test 4",
        mandal: "test 1",
        service_name : "test 13465",
        SLA: "5 days",
        citizen_name: "test test dsgsd",
      }
    }; 
    this.cntDash = this.transactionDataPopup.length;
    console.log(this.cntDash,"cntDash")
    
    */

  }

  // generateCaptcha() {
  //   this.commonService.postRequest(this.commonConstants.generateCaptcha, "",true).subscribe({
  //     next: (responseData:any)=>{
  //       if(typeof(responseData?.result) !== 'string'){
  //       let captchaData = responseData.result
  //       let captchaId = captchaData.id
  //       let generatedCode = captchaData.captcha
  //       this.onAadharSearch(captchaId, generatedCode);
  //       }
  //     }
  //   })
  //   }

  isValidAadharNumber(aadharnumber: string) : boolean {
    // multiplication table
    const d = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [1, 2, 3, 4, 0, 6, 7, 8, 9, 5], 
      [2, 3, 4, 0, 1, 7, 8, 9, 5, 6], 
      [3, 4, 0, 1, 2, 8, 9, 5, 6, 7], 
      [4, 0, 1, 2, 3, 9, 5, 6, 7, 8], 
      [5, 9, 8, 7, 6, 0, 4, 3, 2, 1], 
      [6, 5, 9, 8, 7, 1, 0, 4, 3, 2], 
      [7, 6, 5, 9, 8, 2, 1, 0, 4, 3], 
      [8, 7, 6, 5, 9, 3, 2, 1, 0, 4], 
      [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
      ];
      
      // permutation table
      const p = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 
      [1, 5, 7, 6, 2, 8, 3, 0, 9, 4], 
      [5, 8, 0, 3, 7, 9, 6, 1, 4, 2], 
      [8, 9, 1, 6, 0, 4, 3, 5, 2, 7], 
      [9, 4, 5, 3, 1, 2, 6, 8, 7, 0], 
      [4, 2, 8, 6, 5, 7, 3, 9, 0, 1], 
      [2, 7, 9, 3, 8, 0, 6, 4, 1, 5], 
      [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
      ];
      let c = 0;
      if(aadharnumber && (aadharnumber as string !== null)) {
        let invertedArray = aadharnumber.split('').map(Number).reverse();
        invertedArray.forEach((val:any, i:any) => {
          c = d[c][p[(i % 8)][val]]
        });
        if (c !== 0)
          return false;
      }
      return true;
  }
  setSelection(event:any, val:any){
    if(!this.isMobile) {      
      event.target.setSelectionRange(this.aadharNumber.length, this.aadharNumber.length);
      this.isTextSelected = val;
      }
  }
  select(event:any) {
    event.target.selectionStart = event.target.selectionEnd;
    event.target.setSelectionRange(this.aadharNumber.length, this.aadharNumber.length);
  }
  createstars(n:any) {
    return new Array(n+1).join("X")
  } 
  onkeydown(event:any){
    if(!this.isMobile) {      
      var code = event.which;    
      if (code >= 48 && code <= 57) {
        if(this.aadharNumber.length >= 12) { event.preventDefault(); return; }
        var character = String.fromCharCode(code);
        this.aadharNumber = (this.aadharNumber + character);
        var current_val = this.aadharNumber.length;
        this.maskedaadharNumber = this.createstars(current_val-1) + this.aadharNumber.substring(current_val - 1); 
        setTimeout(()=>{ 
          this.maskedaadharNumber = this.createstars(this.aadharNumber.length);
      },400); 
      } else  if (code == 8 && this.isTextSelected == false) {
        let length = this.aadharNumber.length;
        this.aadharNumber = this.aadharNumber.substring(0, length-1);
      } else {
        event.preventDefault();
      }
    }
  }
  onKeypress(event:any){  
    if(!this.isMobile) {      
      event.preventDefault();
    } 
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      //this.router.navigate(['/revenue/integrated-certificate'])
    } else {
      this.authService.loginRedirect();
    }
  }
  search(searchModal: any) {
    console.log('search', this.applicationNo);
    if(this.applicationNo.trim() !== null && this.applicationNo.trim() !== '') {
      this.modalService.open(searchModal,  { size: 'lg', centered: true, backdrop: 'static'})
      this.commonService.getRequest(this.commonConstants.getWorkItemHistory + '' + '&WorkItemReference=' + this.applicationNo.trim()).subscribe({
        next: (responseData:any) => { 
           let historyItems = responseData?.result?.items;
           if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 3) {
              this.getCertificateDetails();
           } else if (historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken !== 3) {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.reqId = '';
            modalRef.componentInstance.title = 'Transaction Details';
            modalRef.componentInstance.message = 'This service request is not approved yet.';
           } else {
           }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
  }
  getCertificateDetails() {

  }
  print() {
  }

  getDashBoardApplicationDetails(content:any,applicationNo:any,type:any){
    console.log("herer call APi",applicationNo)
    let appId  ="DPP220103000001";
    this.transactionDataPopup = [];
    //this.applicationNo=applicationNo;
    if(type === 'aadharSearch'){
      this.adharApplication = applicationNo;
      this.isAadharapplication = true;
      //applicationNo='';
    }else{
      this.adharApplication = ''; 
      this.isAadharapplication=false;
    }
     let postData ={
      ApplicationId : applicationNo
    }
    this.commonService
    .postRequest( this.commonConstants.getDashBoardApplicationDetails , postData, true)
    .subscribe({
      next: (responseData: any) => {
      
        console.log(responseData,"getDashBoardApplicationDetails")
  
        this.transactionDataPopup =  responseData.result;
        console.log(this.transactionDataPopup.length,"this.transactionDataPopup.lenght")
        let result =  Array.isArray(this.transactionDataPopup);
        if(result && this.transactionDataPopup.length != 0 )
        { 
          this.cntDash = this.transactionDataPopup.length;
          this.transactionDataPopup.sort((a:any, b:any) => {
            var keyA = new Date(a.step_Order);
            var keyB = new Date(b.step_Order);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          
          this.modalService
          .open(content, {
            //size: 'lg',
            size: 'xl',
            ariaLabelledBy: 'modal-basic-title',
            windowClass: 'app-modal-window',
          })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
        } else {
          this.alertPopupForNoRecords(responseData.result);
        }
        // }else if(responseData.result ==='Transaction Failed for this application number'){
        //   this.commonService.commonErrorPopup("Alert",'Transaction Failed for this application number' , "md")
        // }
        //   else{
         
        //   this.alertPopupForNoRecords();
        // }

      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getDashBoardForCertificatePreview(){
    console.log("herer call APi",this.certApplicationNo)
    let deptCode:any;
    if(this.certApplicationNo?.substr(0, 2)?.toUpperCase() === 'MC') {
      deptCode = 'RS';
    } else {
      deptCode = 'RV';
    }
    this.transactionDataPopup = [];
    this.commonService.getRequest(this.commonConstants.getSignedCertificateDataForPreview + this.certApplicationNo + '&DepartmentCode='+ deptCode ).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getSignedCertificateDataForPreview viewDatainPDF'); 
        console.log('responseData.result.fileContents',responseData)
        if(responseData.result.error == null){
          let fileInfo =  responseData.result.fileContents;
          const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
          modalRef.componentInstance.sourceFile = fileInfo;
          // modalRef.componentInstance.fileName = this.downloadCertId;
          modalRef.componentInstance.showPrintDownload = false;
          
        }else{
          this.requisitionTypeForCertificate = responseData.result.requisitionType;
          let isApproved = responseData.result.isApproved;
          if((this.requisitionTypeForCertificate == "IntegratedCertificate" 
          || this.requisitionTypeForCertificate == "IntegratedCertificate-RDO"
          || this.requisitionTypeForCertificate == "IntegratedCertificateForStudents"
          || this.requisitionTypeForCertificate == "IntegratedCertificateForStudents-RDO"
          || this.requisitionTypeForCertificate == "IncomeCertificate"
          || this.requisitionTypeForCertificate == "IncomeCertificateForStudents") && isApproved){
            this.straightThruGenerateCertificateData("CatB"); 
          }else if(responseData.result.error == 'No Aarogyasri certificate available'){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.message = 'Preview Aarogyasri certificate is temporarily stopped.';
          } else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'No Detail found...Please enter valid application number.';
            modalRef.result.then((result: any) => {});
          }
        }       
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
    // let postData ={
    //   ApplicationId : this.applicationNo
    // }
    // this.commonService
    // .postRequest( this.commonConstants.getDashBoardApplicationDetails , postData, true)
    // .subscribe({
    //   next: (responseData: any) => {
      
    //     console.log(responseData,"getDashBoardApplicationDetails")
  
    //     this.transactionDataPopup =  responseData.result;
    //     console.log(this.transactionDataPopup.length,"this.transactionDataPopup.lenght")
    //     let result =  Array.isArray(this.transactionDataPopup);
    //     if(result && this.transactionDataPopup.length != 0 )
    //     { 
    //       this.cntDash = this.transactionDataPopup.length;
    //       this.transactionDataPopup.sort((a:any, b:any) => {
    //         var keyA = new Date(a.step_Order);
    //         var keyB = new Date(b.step_Order);
    //         if (keyA < keyB) return -1;
    //         if (keyA > keyB) return 1;
    //         return 0;
    //       });
          
    //       // this.modalService
    //       // .open(content, {
    //       //   //size: 'lg',
    //       //   size: 'xl',
    //       //   ariaLabelledBy: 'modal-basic-title',
    //       //   windowClass: 'app-modal-window',
    //       // })
    //       // .result.then(
    //       //   (result) => {
    //       //     this.closeResult = `Closed with: ${result}`;
    //       //   },
    //       //   (reason) => {
    //       //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //       //   }
    //       // );
          
    //     }else{
         
    //       this.alertPopupForNoRecords();
    //     }

    //   },
    //   error: (error) => {
    //     console.log('client side', error);
    //   },
    // });
  }

  alertPopupForNoRecords(message: any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = (message && message?.trim() != "") ? message : 'The entered application not found...Please enter valid application number.';
    modalRef.result.then((result: any) => {});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  showSearchForm() {
    this.showSearch = true;
  } 
  clearSearch() {
    this.showSearch = false;
  }
  showTeamPopup(){
   
      let modalRef: any = this.modalService.open(JaganannaScheduleSearchComponent, { size: 'xl', centered: true, backdrop: 'static'});
     
    
     
  }

  showAarogyaSurakshaCampDetails() {
    this.modalService.open(AarogyaSurakshaCampDetailsComponent, { size: 'xl', centered: true, backdrop: 'static'});
  }
  showPoliciesPopup(type:any){
    console.log('type on open', type);
    if(type === 'informationWizard'){
      let modalRef: any = this.modalService.open(PoliciesPopupComponent, { size: 'xl', centered: true, backdrop: 'static'});
      modalRef.componentInstance.popup = type;
    }else{
      let modalRef: any = this.modalService.open(PoliciesPopupComponent, { size: 'lg', centered: true, backdrop: 'static'});
      modalRef.componentInstance.popup = type;
    }
     
  }
  onAadharSearch(data: any){
    this.services=[];
    this.page = 1;
    this.pageSize = 10;
    //this.applicationNo='';
    if(this.aadharNumber){ 
              // let aadhar = encodeURIComponent(this.commonService.RSAEncrypt( this.aadharNumber))
              let postData = {
                "uid":this.aadharNumber,
                "isEncrypt":"N",
                "id": data.Id,
                "captcha": data.captcha
              }
                this.commonService.postRequest( this.commonConstants.GetApplicationsDetailsByUIDPost, postData, true)
                .subscribe({
                  next: (responseData: any) => {                  
                    console.log(responseData,"GetApplicationsDetailsByUID")
                    if (responseData.result == "Invalid Captcha") {
                      this.commonService.commonErrorPopup("Alert", responseData.result, "md") 
                    } else if(responseData.result.length >0){
                      this.services =responseData.result;
                      this.reff = this.modalService.open(this.addModal,
                        {
                          size: 'lg',
                          centered: true,
                          backdrop: 'static'
                        }
                      );
                    } else if(responseData.result.length === 0){
                      this.commonService.commonErrorPopup("Alert", "No Records available !!", "md")
                    }                 
              
                  },   
                  error: (error) => {
                    console.log('client side', error);
                  },
                });


      }else{
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.message = 'Please enter Valid Aadhar number.';
      }
  }
  // modal(){
  //   this.page = 1;
  // this.pageSize = 10;
  // this.aadharNumber='';
  // }

  validateCaptcha(from:any, popup?:any){    
    if(from == 'aadhar' && this.isMobile) {      
      this.aadharNumber = this.aadharLabel.nativeElement.value;
      if(from == 'aadhar' && isNaN(+this.aadharNumber)) {
        this.commonService.commonErrorPopup("Alert", "Please enter valid aadhar number.", "md");
        return;
      }
    }
    if(from === 'transaction' && this.certApplicationNo != '' && this.certApplicationNo != null) {
        this.openCaptchaPopup('transaction')
    }
   else if(from === 'aadhar' && this.aadharNumber != '' && this.aadharNumber != null && this.aadharNumber?.length ==12  && this.isValidAadharNumber(this.aadharNumber)) {
      this.openCaptchaPopup('aadhar')
    }
    else if(from === 'application' && this.applicationNo != '' && this.applicationNo != null){
      this.openCaptchaPopup('application', popup)
    }
    else{
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      if(from === 'aadhar' && this.aadharNumber && (this.aadharNumber?.length != 12 || !this.isValidAadharNumber(this.aadharNumber))) {
          modalRef.componentInstance.message = "Please enter valid "+ from +" number"; 
        } else {
          modalRef.componentInstance.message = "Please enter "+ from +" number"; 
        }
     }
  }
  openCaptchaPopup(from:any, popup?:any) {
    const modalRef = this.modalService.open(CaptchaComponent, { size: 'md' });
    modalRef.componentInstance.message = 'captcha code';
    modalRef.result.then((data: any) => {
      if (data){
        console.log(data, 'data status')
        if(from === 'transaction') {
          if(this.certApplicationNo?.substr(0, 4)?.toUpperCase() == 'SADA') {
            this.getSadareamId();
          }else{
            this.getDashBoardForCertificatePreview()
          }          
        }
         else if(from=== 'aadhar'){
          this.onAadharSearch(data);
        }
        else if(from=== 'application'){
          let content:any;
          let type:any;
          this.getDashBoardApplicationDetails(popup, this.applicationNo,'application' );
        }
        else{
          alert('no data')
        }
       //code here
      }     
    }).catch((res) => {});
  }

 
  straightThruGenerateCertificateData(certitificateType:any){ 
    let count = this.commonConstants.certificateList.filter((item: any) => item.certName === this.requisitionTypeForCertificate);
    if (count.length > 0) {
        this.certificateTypeName = count[0].certID;
    }       
    var sendPostData={
      RequisitionId: this.certApplicationNo,
      Department: this.departmentCode,
      CertificateType : this.certificateTypeName
    }
    this.commonService.postRequest(this.commonConstants.postforApprovedCertificateForPreview, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData.result.isSuccess == true){
          this.straightThruGenerateCertificate(certitificateType);
        }else{         
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'Certificate data not available..!!'; 

        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  
  straightThruGenerateCertificate(certitificateType:any) {
    this.commonService.getRequest(this.commonConstants.getforApprovedCertificateForPreview + this.certApplicationNo + '&Department=' + this.departmentCode).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess) {
          this.getDashBoardForCertificatePreview();
        }else if(responseData?.result?.error) {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = responseData?.result?.error;
          modalRef.result.then((result: any) => {});
        } else {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'Error in certificate generation.';
          modalRef.result.then((result: any) => {});
        }
      },
      error: (error) => {     
        console.log('client side', error);
      }
    });
    }
    getSadareamId(){  
      let postData= {
        RequisitionId: this.certApplicationNo.trim(),
       
      };
        this.commonService.postRequest( this.commonConstants.CheckSadaremPaymentDetails, postData, true)
        .subscribe({
          next: (responseData: any) => {                  
            if(responseData.result.status == 3){
              this.previewCertificate(responseData.result.sadaremId)             
            } else {
              this.commonService.commonErrorPopup("Alert",responseData.result.message, "md")
            }                 
      
          },   
          error: (error) => {
            console.log('client side', error);
          },
        });

    }
    previewCertificate(sadaremId:any){
      let certPostData :any={};
      certPostData.sadaremID=sadaremId;
      this.commonService.postRequestForWebLand(this.commonConstants.getSadaramCertificateData, certPostData).subscribe({
      next: (certData:any) => { 
        if(certData.RESPONSE.Certificate){          
            let fileInfo =  certData.RESPONSE.Certificate;
            const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
            modalRef.componentInstance.sourceFile = fileInfo;     
      
        } 
        else {
          this.commonService.commonErrorPopup('Alert', certData.RESPONSE.Status, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
    }
    
}
