<div class="modal-header testCls">
    <h6 *ngIf="!isImage" class="modal-title" id="modal-basic-title">View PDF</h6>
    <h6 *ngIf="isImage" class="modal-title" id="modal-basic-title">View Image</h6>
    
  
    <button class="greenBtnCls btnPosition" *ngIf="showPrintDownload" (click)="printCertificate()">Print</button>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span >×</span>
    </button>
</div>
<div class="modal-body testCls">
    <ng-container *ngIf="!isImage">
    <ngx-extended-pdf-viewer [base64Src]="sourceFile" [textLayer]="true" [zoom]="'page-width'" [sidebarVisible]="false" [showBookmarkButton]="false" [showBorders]="true" [showOpenFileButton]="false" [showPrintButton]="showPrintDownload" [showDownloadButton] ="showPrintDownload" [showPropertiesButton]="false" [showUnverifiedSignatures]="true" [showScrollingButton]="false" [showSecondaryToolbarButton]="false" [showSidebarButton]="false" [filenameForDownload]="fileName" [contextMenuAllowed]="showPrintDownload" [enablePrint] = "showPrintDownload">
    </ngx-extended-pdf-viewer>
    </ng-container>
    <ng-container *ngIf="isImage">
        <img [src]="sourceFile" class="img-fluid">
    </ng-container>
</div>
<div class="modal-footer testCls">
    <!-- button class="greenBtnCls" (click)="activeModal.close('Close click')">Ok</button> -->
    <button class="greenBtnCls" id="okbtn" (click)="activeModal.close('Close click')">Ok</button>
</div>