import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';

@Component({
  selector: 'app-search-screen-caste',
  templateUrl: './search-screen-caste.component.html',
  styleUrls: ['./search-screen-caste.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class SearchScreenCasteComponent implements OnInit {
  commonConstants : any = CommonConstants;
  form: UntypedFormGroup;
  aadharForm: UntypedFormGroup;
  aadharTransactionID: any;
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  currentDate = new Date();
  submitted: Boolean = false;
  maxDate: any;
  minDate: any;
  anyNotNull: boolean = false;
  responseDataToken: any;
  showEmptyAlert: boolean = false;
  showEmptyAlertnull: boolean = false;
  page = 1;
  pageSize = 10;
  showButton: boolean = false;
  submits: Boolean = true;
  aadharData: any;
  isAadharOtpValidated = false;
  isAadharDisable: Boolean = false;
  finalkey: any;
  aadhIntegratedCertificate: any;
  selectedItem: any;
  constructor(private formBuilder: UntypedFormBuilder,private datePipe: DatePipe, public activeModal: NgbActiveModal, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal, private router: Router) { 
    config.backdrop = 'static';
    config.keyboard = false;
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null],
      applicationNo: [null, [Validators.minLength(15), Validators.maxLength(15)]],
      applicantname: [null],
      fatherHusName: [null],
      caste: [null]
    });
    this.aadharForm = this.formBuilder.group({
      aadharNo: [null],
      aadharOtp: [null]
    })
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.getDistricts();
    this.aadhIntegratedCertificate = this.commonService.aadh;
  }

  ngOnInit(): void {
    this.getTokenForSearchCaste();
  }
  get f() { return this.form.controls; }
  get g() { return this.aadharForm.controls;}

  getDistricts() {
    this.f.mandal.patchValue(null);
    this.f.village.patchValue(null);
    this.commonService.getRequest(this.commonConstants.GetRevenueDistrictMSP).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          let districts: any[] = [];
           responseData.result?.getDistrictDetails.forEach((district: any)=>{
            districts.push(district);
          })
          this.districts = this.commonService.sortItems(districts, 'rV_DISTRICT_ENGLISHNAME');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getMandals(){
    this.f.mandal.patchValue(null);
    this.f.village.patchValue(null);
    this.mandals = [];
    this.villages = [];
    this.commonService.getRequest(this.commonConstants.GetRevenueMandalByDistrictMSP + '?DistrictCode=' + this.f['district'].value.rV_DISTRICT_ID
    ).subscribe({
      next: (responseData: any) => { 
        let mandals: any[] = [];
        responseData.result?.datalist.forEach((mandal: any)=>{
          mandals.push(mandal);
       })
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(mandals, 'revenuE_MANDAL_ENGLISHNAME');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onMandalChange() {
    this.f.village.patchValue(null);
    this.villages = [];
    this.commonService.getRequest(this.commonConstants.GetRevenueVillageByMandalMSP +'?DistrictCode=' + this.f['district'].value.rV_DISTRICT_ID + '&MandalCode=' + this.f['mandal'].value.revenuE_MANDAL_CODE).subscribe({
      next: (responseData: any) => { 
        let villages: any[] = [];
        responseData.result?.datalist.forEach((village: any)=>{
          villages.push(village);
       })
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(villages, 'revenuE_VILLAGE_ENGLISHNAME');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onDistrictChange() {
    this.getMandals();
  }

  selectSchedule(item: any) {
    this.showButton = true; 
    this.selectedItem = item;
    this.g.aadharNo.patchValue(this.aadhIntegratedCertificate);
    this.g.aadharNo.disable();
  }

  aadharChange(from?:any){
    if(from === 'aadhar') {
      this.g.aadharOtp.patchValue(null);
      if(this.g.aadharNo.valid){
        this.isAadharDisable = false;
      }else{
        this.isAadharDisable = true;
      }
    } else {
    }
  }
  aadharsentmessage: any;
  aadharotpname: any;
  sendAadharOtp() : any{
    this.submits = true;

    if(this.commonService.aadh != this.g.aadharNo.value) { 
      this.commonService.commonErrorPopup('Alert', 'Aadhar Number must match with the aadhar number given in integrated certificate' ,'md')
      return false;
    }
    if(this.g.aadharNo.invalid || this.g.aadharNo.value == "" || this.g.aadharNo.value == null) {
      this.submits = true;
      this.commonService.commonErrorPopup("Alert", "Please enter aadhar number", "md");
      return false;
    }
    else {
    var sendPostData={
      P_USER_ID: "10690658-DA",
      PID_DATA: "",
      P_OTP: "",
      P_UID: this.g.aadharNo.value,
      P_LATITUDE: "",
      P_LONGITUDE: "",
      AUTHENTICATION_TYPE: "EKYCOTPGENERATE"
  }
  let postData: any = btoa(JSON.stringify(sendPostData));
  this.commonService.postRequestForAadhar(this.commonConstants.pensionAadharOTP, postData, 'pension').subscribe({
    next: (responseData:any) => {
      this.aadharsentmessage = responseData?.message;
      if(responseData?.message == 'OTP Sent Successfully'){
        this.aadharTransactionID = responseData?.result;

      } else {
        this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
      }
    },
    error: (error: any) => {
      console.log('client side', error);
    }
  });
}
  }

  validateAadharOTP(){
    this.submits = true;
    if((this.g.aadharNo.value.length == 12) && this.g.aadharOtp.value.length == 6 ){
      this.aadharData = [];
      var sendPostData={
        P_USER_ID: "10690658-DA",
        PID_DATA: this.aadharTransactionID,
        P_OTP: this.g.aadharOtp.value,
        P_UID:  this.g.aadharNo.value,
        P_LATITUDE: "",
        P_LONGITUDE: "",
        AUTHENTICATION_TYPE: "EKYCOTPVALIDATE"
      }
      let postData: any = btoa(JSON.stringify(sendPostData));
      this.commonService.postRequestForAadhar(this.commonConstants.pensionAadharOTP, postData, 'pension').subscribe({
        next: (responseData:any) => { 
          if(responseData?.message == 'Data Loaded successfully !!!'){
            this.commonService.commonErrorPopup('Alert', 'Authentication Success.', 'md')
              this.aadharData = responseData?.result;
              let data: any;
              data = {}
              data.firstName = this.aadharData?.name;
              data.fatherHusName = this.aadharData?.co;
              data.gender = this.loadGender(this.aadharData?.gender?.toUpperCase()?.trim());
              data.dob = this.aadharData?.dob;
                this.submits=false;
                this.isAadharOtpValidated = true;
                this.aadharotpname = responseData?.result?.name;
                this.redirectbuttonFlag = true
                this.redirectbutton();
          }else if(responseData?.message == 'OTP Flooding error.'){
            this.submits=true;
            this.commonService.commonErrorPopup('Alert', 'OTP Flooding error. Please try again after sometime.' , 'md')
          }else{
            this.commonService.commonErrorPopup('Alert', responseData?.message , 'md')
          }
        },
        error: (error: any) => {
          console.log('client side', error);
        }
      });
  }

  }
  redirectbuttonFlag: boolean = false;
  redirectbutton() {
    if((this.aadharotpname)?.toLowerCase() == (this.selectedItem.Name)?.toLowerCase()) {
     this.redirectbuttonFlag = true;
     this.commonService.commonErrorPopup("Alert", "The Aadhar Number name and Applicant name matched and please click on Re-Issuance Redirect button", "md");
    }
    else if((this.aadharotpname)?.toLowerCase() == (this.selectedItem.Applicant_Name)?.toLowerCase()) {
      this.redirectbuttonFlag = true;
      this.commonService.commonErrorPopup("Alert", "The Aadhar Number name and Applicant name matched and please click on Re-Issuance Redirect button", "md");
    }
    else {
      this.redirectbuttonFlag = false; 
      this.commonService.commonErrorPopup("Alert", "The Aadhar Number name and Applicant name doesnot matched", "md")
    }
  }

  navigatetoreissuance() {
    let finalkey: any;
    if(this.selectedItem?.Application_Number) {
      finalkey = this.selectedItem?.Application_Number;
    }
    else {
      finalkey = this.selectedItem?.TransactionID.slice(2);
    }
    let params = {
      isSearchScreenCertificate: true,
      applictionId: finalkey,
      selfReissuance: true,
      from: "IC",
      applicantAadhar: this.commonService.RSAEncrypt(this.g.aadharNo.value),
    }
    this.router.navigate(['/revenue/re-issuance-of-certificates-new'], { queryParams: params });
    
    this.activeModal.close('Close click');
  }

  loadGender(value: string) {
    if (value === 'M')
      return 'Male';
    else if (value === 'F')
      return 'Female';
    else if (value !== null && value !== '')
      return 'Transgender';
    else
      return null;
  }


  submit(){
    const values: any = [this.f.village.value, this.f.mandal.value,this.f.district.value,this.f.applicationNo.value,this.f.applicantname.value, this.f.caste.value, this.f.fatherHusName.value]
    this.showEmptyAlert = !values.some((value: any) => (value !== "" || value !== null));
    this.showEmptyAlertnull = values.filter((value: any) => (value === null || value === "")).length == values.length;
    this.submitted = true;
    for(const key in this.form.value) {
      if(this.form.value.hasOwnProperty(key)) {
        if(this.form.value[key] !== null) {
          this.anyNotNull = true;
          break;
        }
      }
    }
   
   if(!this.anyNotNull) {
      this.commonService.commonErrorPopup('Alert', 'Please fill atleast one search criteria', 'md');
      return;
    }
    else if(this.f.applicationNo.valid && this.f.applicationNo.value !== "" && this.f.applicationNo.value !== null) {
      this.searchcastedetailsData = []; 
      this.getVSWSCasteCertificateDetails();
    }
    else if(this.f.applicationNo.value == "" && this.showEmptyAlert) {
      this.responseDataFromgetVSWSCasteCertificate = [];
      this.commonService.commonErrorPopup('Alert', 'Please fill atleast one search criteria', 'md');
      return;
    }
    else if(this.showEmptyAlert) {
      this.commonService.commonErrorPopup('Alert', 'Please fill atleast one search criteria', 'md');
      return;
    }
    else if(this.showEmptyAlertnull) {
      this.searchcastedetailsData = [];
      this.commonService.commonErrorPopup('Alert', 'Please fill atleast one search criteria', 'md');
      return;
    }
     else {
      this.responseDataFromgetVSWSCasteCertificate = [];
      this.getSearchCasteDetails();
    }
  }


  getTokenForSearchCaste() {
    this.commonService.getRequestForToken(this.commonConstants.getTokenForSearchScreen).subscribe({
      next: (responseData: any) => {
        if(responseData.STATUSDESC == "Success") {
          this.responseDataToken = responseData.DtJToken[0].Token;
        }
      } 
  })
  }
  searchcastedetailsData: any = [];
  getSearchCasteDetails() {
    this.f.village.setValidators([Validators.required]);
    this.f.village.updateValueAndValidity();
    this.f.district.setValidators([Validators.required]);
    this.f.district.updateValueAndValidity();
    this.f.mandal.setValidators([Validators.required]);
    this.f.mandal.updateValueAndValidity();
    if((this.f.village.status == 'INVALID') || (this.f.district.status == 'INVALID') || (this.f.mandal.status == 'INVALID')) {
      this.submitted = true;
      return;
    }
    else {
      this.f.village.clearValidators();
      this.f.mandal.clearValidators();
      this.f.district.clearValidators();
      this.f.village.updateValueAndValidity();
      this.f.mandal.updateValueAndValidity();
      this.f.district.updateValueAndValidity();
      let postData = {
        TOKENID : this.responseDataToken,
        DISTID : (this.f.district?.value?.rV_DISTRICT_ID) ? (this.f.district?.value?.rV_DISTRICT_ID) : "",
        MANDID : (this.f.mandal?.value?.revenuE_MANDAL_CODE) ? (this.f.mandal?.value?.revenuE_MANDAL_CODE) : "",
        VILLID : (this.f.village?.value?.revenuE_VILLAGE_CODE) ? (this.f.village?.value?.revenuE_VILLAGE_CODE).slice(-3) : "",
        APPNO : "",
        APPNAME: (this.f.applicantname.value) ? (this.f.applicantname.value) : "",
        FATHERNAME: (this.f.fatherHusName.value) ? (this.f.fatherHusName.value) : "",
        CASTE : (this.f.caste?.value) ? (this.f.caste?.value) : ""
      }
      this.commonService.postRequestForWebLand(this.commonConstants.getSearchCasteDetails, postData).subscribe({
        next: (responseData: any) => {
          if(responseData.STATUSDESC == 'Success') {
            this.searchcastedetailsData = responseData.DtJToken;
            this.showButton = false;
          }
          else {
            this.searchcastedetailsData = [];
            this.commonService.commonErrorPopup("Alert", "No Records Found", "md");
            this.showButton = false;
          }
        }
      })
    }
    this.f.village.updateValueAndValidity();
    this.f.mandal.updateValueAndValidity();
    this.f.district.updateValueAndValidity();
  }




  responseDataFromgetVSWSCasteCertificate: any = [];

  getVSWSCasteCertificateDetails() {
    this.f.mandal.clearValidators();
    this.f.village.clearValidators();
    this.f.district.clearValidators();
    this.f.mandal.updateValueAndValidity();
    this.f.village.updateValueAndValidity();
    this.f.district.updateValueAndValidity();
    this.commonService.aadh = this.aadhIntegratedCertificate;
    let postData = {
      TOKENID: this.responseDataToken,
      APPNO: this.form.value.applicationNo
    }
    this.commonService.postRequestForWebLand(this.commonConstants.getVSWSCasteCertificate, postData).subscribe({
      next: (responseData: any) => {
        if(responseData.STATUSDESC == 'Success') {
          this.responseDataFromgetVSWSCasteCertificate = responseData.DtJToken;
          this.showButton = false;
        }
        else {
          this.commonService.commonErrorPopup("Alert", "No Records Found", "md");
          this.responseDataFromgetVSWSCasteCertificate = [];
          this.showButton = false;
        }
      }
    })
  }
 
}
