<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
          GSWS / Application Submission <span class="activePage">/ Caste Survey Grievance </span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3">Caste Survey Grievance Application Form</h6>
        <form [formGroup]="socialAuditGrievanceForm">
            <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
                <ng-container [ngbNavItem] = "1">
                    <a ngbNavLink>Basic Details</a>
                    <ng-template ngbNavContent>
                        <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" [service]="'SocialAuditGrievance'" 
                        (reset)="commonFormSubmitted = false" (checkValidation)="checkDataValidation()"></app-common-form>
                        <div class="btnHolder">
                          <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem] = "2">
                    <a ngbNavLink>Application Form</a>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                            <div class="col">
                                <div class="subHeadingCls mb-3 text-center">Caste Audit (2024-25) SC Category</div>
                                <div class="text-center">
                                    <label class="mr-3"><span class="subHeadingCls">Urban / Rural:</span> {{ citizenSurveyData?.ruraL_URBAN_FLAG == 'R' ? 'Rural' : 'Urban' }}</label>
                                    <label class="mr-3"><span class="subHeadingCls">District Name:</span> {{ citizenSurveyData?.districT_NAME }}</label>
                                    <label><span class="subHeadingCls">Mandal Name:</span> {{ citizenSurveyData?.mandaL_NAME }}</label>
                                </div>
                                <div class="text-center">
                                    <label class="mr-3"><span class="subHeadingCls">Secretariat Code:</span> {{ citizenSurveyData?.secretariaT_CODE }}</label>
                                    <label><span class="subHeadingCls">Secretariat Name:</span> {{ citizenSurveyData?.secretariaT_NAME }}</label>
                                </div>
                                <div class="tab table-responsive">
                                    <table class="tableCls">
                                        <thead>
                                            <tr>
                                                <th>SL No</th>
                                                <th>Citizen Aadhar (Masked)</th>
                                                <th>Name of the Citizen</th>
                                                <th>Gender</th>
                                                <th>Date of Birth</th>
                                                <th>Marital Status</th>
                                                <th>Caste</th>
                                                <th>Sub-Caste</th>
                                                <th>Religion</th>
                                                <th>Type of House</th>
                                                <th>Access To Toilet Facility</th>
                                                <th>Access To Clean Drinking Water,</th>
                                                <th>Access To Cooking Gas</th>
                                                <th>Educational Qualification</th>
                                                <th>Profession</th>
                                                <th>Agricultural Land Owned</th>
                                                <th>Residential Land Owned</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>{{ citizenSurveyData?.uiD_NUM.replace(citizenSurveyData?.uiD_NUM.substr(0, 8), 'XXXX-XXXX-') }}</td>
                                                <td>{{ citizenSurveyData?.citizeN_NAME ? citizenSurveyData?.citizeN_NAME : '-' }}</td>
                                                <td>{{ citizenSurveyData?.gender ? citizenSurveyData?.gender : '-' }}</td>
                                                <td>{{ citizenSurveyData?.doB_DT ? citizenSurveyData?.doB_DT : '-' }}</td>
                                                <td>{{ citizenSurveyData?.maritalstatus ? citizenSurveyData?.maritalstatus : '-' }}</td>
                                                <td>{{ citizenSurveyData?.caste ? citizenSurveyData?.caste : '-' }}</td>
                                                <td>{{ citizenSurveyData?.subcaste ? citizenSurveyData?.subcaste : '-' }}</td>
                                                <td>{{ citizenSurveyData?.religion ? citizenSurveyData?.religion : '-' }}</td>
                                                <td>{{ citizenSurveyData?.housetype ? citizenSurveyData?.housetype : '-' }}</td>
                                                <td>{{ citizenSurveyData?.defecationstatus ? citizenSurveyData?.defecationstatus : '-' }}</td>
                                                <td>{{ citizenSurveyData?.waterresource ? citizenSurveyData?.waterresource : '-' }}</td>
                                                <td>{{ citizenSurveyData?.gasconnection ? citizenSurveyData?.gasconnection : '-' }}</td>
                                                <td>{{ citizenSurveyData?.qualification ? citizenSurveyData?.qualification : '-' }}</td>
                                                <td>{{ citizenSurveyData?.occupation ? citizenSurveyData?.occupation : '-' }}</td>
                                                <td>{{ citizenSurveyData?.agricultureland ? citizenSurveyData?.agricultureland : '-' }}</td>
                                                <td>{{ citizenSurveyData?.residentalland ? citizenSurveyData?.residentalland : '-' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">Grievance Raised By: <span class="mandatoryCls">*</span></label>
                                <select class="selectCls" formControlName="grievanceRaisedBy" required
                                    [ngClass]="{ 'is-invalid':submitted && (f.grievanceRaisedBy.errors || f.grievanceRaisedBy.value === 'null')}">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option value="self">Self</option>
                                    <option value="thirdParty">Third party</option>
                                </select>
                                <div *ngIf="submitted && (f.grievanceRaisedBy.errors || f.grievanceRaisedBy.value === 'null')" class="selectError">
                                    <div *ngIf="f.grievanceRaisedBy.errors?.required || f.grievanceRaisedBy.value === 'null'">Please select the appropriate option</div>
                                </div>
                            </div>
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls">Grievance Type: <span class="mandatoryCls">*</span></label>
                                <select class="selectCls" formControlName="grievanceType" required (change)="onGrievanceTypeChange()"
                                    [ngClass]="{ 'is-invalid':submitted && (f.grievanceType.errors || f.grievanceType.value === 'null')}">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option value="casteSubcaste" [disabled]="disableCaste">Caste / Sub caste</option>
                                    <option value="others" [disabled]="disableOther">Others</option>
                                </select>
                                <div *ngIf="submitted && (f.grievanceType.errors || f.grievanceType.value === 'null')" class="selectError">
                                    <div *ngIf="f.grievanceType.errors?.required || f.grievanceType.value === 'null'">Please select the appropriate option</div>
                                </div>
                            </div>
                            <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngIf="f.grievanceType.value == 'others'">
                                <label class="labelCls">Grievance Sub Type <span class="mandatoryCls">*</span></label>
                                <ng-multiselect-dropdown [ngClass]="{ 'is-invalid': submitted && f.otherGrievance?.value.length == 0 }"
                                    [placeholder]="'Select'"
                                    [settings]="dropdownSettings"
                                    [data]="otherGrievanceList"
                                    formControlName="otherGrievance">
                                </ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.otherGrievance?.value.length == 0" class="selectError">
                                    <div>Please select atleast 1 appropriate option</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="checkBoxCont">
                                    <label class="labelCls" for="sscmarksmemo">Grievance copy</label>
                                </div>
                                <div class="input-group">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" formControlName="grievanceCopy" id="grievanceCopy" accept=".pdf,.jpg,.jpeg"
                                            [ngClass]="{ 'is-invalid':submitted && f.grievanceCopy.errors }" (change)="onGrievanceCopySelected($event)">
                                        <label class="custom-file-label" #grievanceCopyLabel for="grievanceCopy"><i class="fas fa-search"></i>No File choosen</label>
                                    </div>
                                    <div class="clearCls" (click)="clearFileUpload('grievanceCopy', 'grievanceCopyLabel')" *ngIf="f.grievanceCopy.value"></div>
                                </div>
                                <div *ngIf="submitted && f.grievanceCopy.errors" class="selectError">
                                    <div *ngIf="f.grievanceCopy.errors?.inValidExt ">Please upload the file in PDF/JPG/JPEG format only</div>
                                    <div *ngIf="f.grievanceCopy.errors?.inValidSize && !f.grievanceCopy.errors.inValidExt">File size exceeding 1MB</div>
                                </div>
                            </div>
                        </div>
                        <hr class="hrCls mt-4">
                            <div class="row mb-3">
                                <div class="ml-4 col-sm-9">
                                    <input class="form-check-input " type="checkbox" formControlName="consent" required
                                        value="" id="consent"
                                        [ngClass]="{ 'is-invalid':submitted && f.consent.errors }">
                                    <label class="labelCls" for="consent"><b>I (Applicant), agree that the
                                      information/documents submitted is with my consent and can be verified. <span
                                                class="mandatoryCls">*</span></b> </label>
                                    <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                        <div *ngIf="f.consent.errors.required">Please Check the Consent</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="ml-4 col-sm-9">
                                    <input class="form-check-input " type="checkbox" formControlName="consent2" required
                                      value="" id="consent2"
                                      [ngClass]="{ 'is-invalid':submitted && f.consent2.errors }">
                                  <label class="labelCls" for="consent2"><b>I (Verify Authority), hereby, declare that the particulars
                                    given above are correct and complete. I will be responsible for any misrepresentation
                                    of facts and will be liable for punitive action. <span
                                              class="mandatoryCls">*</span></b> </label>
                                  <div *ngIf="submitted && f.consent2.errors" class="invalid-feedback">
                                      <div *ngIf="f.consent2.errors.required">Please Check the Consent</div>
                                  </div>
                                </div>
                            </div>
                            <div class="btnHolder">
                                <button  class="greenBtnCls float-right" (click)="submit()">Submit</button>
                            </div>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav"></div>
        </form>
    </div>
</div>