import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../../constants/common-constants.component';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from 'src/shared/common.service';
import { AgeValidator } from '../../../shared/validators/age.validator';
import {  AadharValidator } from '../../../shared/common.service';
@Component({
  selector: 'app-family-member-certificate-view',
  templateUrl: './family-member-certificate-view.component.html',
  styleUrls: ['./family-member-certificate-view.component.css'],
  providers: [
    DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class FamilyMemberCertificateViewComponent implements OnInit {
@Input() certificateInfo: any;
@Input() basicInfo: any;
 @Input() isEditable: Boolean = false;
  @Input() fileList: any;
  @Input() reqId: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() validateForm = new EventEmitter();

  role: string = '';
  form: UntypedFormGroup;
  gridForm: UntypedFormGroup;
  commonConstants : any = CommonConstants;
  maxDate: any;
  minDate: any;
  currentDate = new Date();
  submitted: Boolean = false;
  formSubmitted: Boolean = false;
  occupations: any = [];
  relations: any = [];
  reasonDeath: any = [];
  gridData: any [] = [];
  idMinLength = 12;
  idMaxLength = 12;
  updatedData = 0;
  constructor(private formBuilder: UntypedFormBuilder, public commonService: CommonService, private datePipe: DatePipe) {
   
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
  this.form = this.formBuilder.group({
    deceasedfirstName : ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
    deceasedmiddleName : ['', [ Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
    deceasedlastName : ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
    fatherrHusbandName : ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
    dateOfDeath : ['', [Validators.required]],
    occupation : ['', [Validators.required]],
    reasonForDeath : ['', [Validators.required]],
    deathPlace : ['', [Validators.required,Validators.pattern(this.commonConstants.alphabetPattern), WhitespaceValidator.whiteSpaceValidate]],
    aadharEnrollmentNo: ['']
  });
  this.gridForm = this.formBuilder.group({
    tbFamilymembername:  ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      tbDeceased:[null, [Validators.required]] ,
      tbAge:['', [Validators.required,AgeValidator,Validators.pattern(this.commonConstants.zeroNotAllowedPattern)]], 
      tbGender:[null, [Validators.required]], 
      tbMaritalStatus:[null, [Validators.required]],
      tbIdProof:[null, [Validators.required]],
      tbIdNumber:[''],
      tberrorText : ['Please enter Valid ID Proof']
  });
  this.commonService.checkAction.subscribe((data: any)=> {
    if(data.isActionBtn) {
      this.formSubmitted = true;
      this.submitted = true;
      this.formValidate(data);
    }
  });
  this.commonService.commonAction.subscribe((data: any)=> {
    if(data) {
      this.onDataChange(data);
    }
  });
}
  ngOnInit(): void {
    // let dateOfDeath = this.certificateInfo?.deceasedDateofDeath?.split('T')[0]
    // this.form.setValue({
    //   deceasedfirstName : this.certificateInfo?.deceasedInfo?.firstName,
    //   deceasedmiddleName : this.certificateInfo?.deceasedInfo?.middleName ? (this.certificateInfo.deceasedInfo?.middleName) : '',
    //   deceasedlastName : this.certificateInfo?.deceasedInfo?.lastName ? this.certificateInfo.deceasedInfo?.lastName : '',
    //   fatherrHusbandName : this.certificateInfo?.deceasedInfo?.fatherHusbandName,
    //   dateOfDeath : this.datePipe.transform( dateOfDeath, 'dd-MM-yyyy'),
    //   occupation : this.certificateInfo?.deceasedOccupation, 
    //   reasonForDeath : this.certificateInfo?.reasonofDeath,
    //   deathPlace : this.certificateInfo?.placeofDeath,
    //  aadharEnrollmentNo: this.certificateInfo?.deceasedAadharNo ? this.certificateInfo.deceasedAadharNo : ''
    // });                                
    // this.gridData = this.certificateInfo?.familyDetails;
    // this.gridData.map((item: any) => {
    //     item.isEditRow = false;
    //     item.isAddRow = false;
    //     return item;
    // });
    // if(this.isEditable) {
    //   this.getNoIncomeOccupations();
    //   this.getRelations();
    //   this.getReasonForDeath();
    //  }
    let userInfo = this.commonService.getLocalStorage('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
      console.log(this.role)
    }
    this.onDataChange(this.certificateInfo);
    this.updateCheck()
    
  }

  updateCheck()
  {
    if(this.certificateInfo.familyDetails?.length == this.certificateInfo.updatedFamilyDetails?.length)
    {
      for(let i = 0; i <= this.certificateInfo.familyDetails.length ; i++)
      {
        if(this.certificateInfo?.familyDetails[i]?.familyMemberInfo?.personNames[0]?.firstName != this.certificateInfo?.updatedFamilyDetails[i]?.familyMemberInfo?.personNames[0]?.firstName
        || this.certificateInfo?.familyDetails[i]?.familyMemberInfo?.gender != this.certificateInfo?.updatedFamilyDetails[i]?.familyMemberInfo?.gender
        || this.certificateInfo?.familyDetails[i]?.familyMemberInfo?.maritalStatus != this.certificateInfo?.updatedFamilyDetails[i]?.familyMemberInfo?.maritalStatus
        || this.certificateInfo?.familyDetails[i]?.relationwithDeceased != this.certificateInfo?.updatedFamilyDetails[i]?.relationwithDeceased
        || this.certificateInfo?.familyDetails[i]?.identityProof != this.certificateInfo?.updatedFamilyDetails[i]?.identityProof
        || this.certificateInfo?.familyDetails[i]?.identityNumber != this.certificateInfo?.updatedFamilyDetails[i]?.identityNumber)
        {
          this.updatedData += 1;
        }
      }
    }
    else
    {
      this.updatedData += 1;
    }
  }
  get f() { return this.form.controls; }
  get f1() { return this.gridForm.controls; }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  getNoIncomeOccupations() {
    this.commonService.getRequest(this.commonConstants.getFamilyMemberOccupation).subscribe({
      next: (responseData: any) => { 
        this.occupations = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getRelations() {
    this.commonService.getRequest(this.commonConstants.getRelations).subscribe({
      next: (responseData: any) => { 
        this.relations = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getReasonForDeath() {
    this.commonService.getRequest(this.commonConstants.getReasonForDeath).subscribe({
      next: (responseData: any) => { 
        this.reasonDeath = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  editRow(item: any): any {
    let isAddActive = this.gridData?.filter((item: any) => item.isAddRow);
    if(isAddActive?.length > 0) {
      this.submitted = true;
      return false;
    } else {
      //console.log('item value', item);
      this.gridData?.map((item: any) => {
        item.isEditRow = false;
        return item;
      });
      item.isEditRow = true;

      this.gridForm.patchValue({
        tbFamilymembername: item.familyMemberInfo?.personNames[0]?.firstName,
        tbDeceased: item.relationwithDeceased,
        tbAge: item.age, 
        tbGender: item.familyMemberInfo?.gender, 
        tbMaritalStatus: item.familyMemberInfo?.maritalStatus,
        tbIdProof: item.identityProof,
        tbIdNumber: this.commonService.RSADecrypt(item.identityNumber)
      });
      this.idproofselection('edit');  
  }
  }
  cancelEdit(item: any, i: any) {
    if(!item.isAddRow) {
      item.isEditRow = false;
    } else {
      if(this.gridData?.length > 1) {
        this.gridData.splice(i, 1)
      } else {
        let msg = 'Please enter the family member details.';
        this.commonService.commonErrorPopup('Alert', msg, 'md');
       // alert('Please enter the family member details.');
      }
    }
  }
  updateRow(item: any): any {
    this.submitted = true;
    if(!this.gridForm.valid) {
      //console.log('if')
      return false;
    }
    else {
      //console.log('else')
      item.isEditRow = false;
      item.isAddRow = false;
      item.familyMemberInfo.personNames[0].firstName = this.f1.tbFamilymembername.value;
      item.relationwithDeceased = this.f1.tbDeceased.value;
      item.familyMemberInfo.gender = this.f1.tbGender.value;
      item.age = this.f1.tbAge.value;
      item.familyMemberInfo.maritalStatus = this.f1.tbMaritalStatus.value;
      item.identityProof = this.f1.tbIdProof.value;
      item.identityNumber = this.f1.tbIdNumber.value;
    }
  }
  deleteRow(row: any, i: any): any {
    let isAddActive = this.gridData?.filter((item: any, index: any) => item.isAddRow && index === i);
    if(isAddActive?.length > 0) {
      this.submitted = true;
      return false;
    } else {
      if(this.gridData?.length > 1) {
        this.gridData.splice(i, 1)
      } else {
        let msg = 'You can\'t delete the entire family.';
      this.commonService.commonErrorPopup('Alert', msg, 'md');
       // alert('You can\'t delete the entire family.');
      }
  }
  }
  addRow() {
    let isAddActive = this.gridData?.filter((item: any) => item.isAddRow);
    if(isAddActive?.length === 0) {
      this.gridData.map((item: any) => {
        item.isEditRow = false;
        return item;
      });
      this.gridForm.reset();
      this.submitted = false;
      let item: any = {};
      item.familyMemberInfo = {};
      item.familyMemberInfo.personNames = [];
      item.familyMemberInfo.personNames = [{
        'firstName' : ''
      }];
      item.relationwithDeceased = null;
      item.age = '';
      item.familyMemberInfo.gender = null;
      item.familyMemberInfo.maritalStatus = null;
      item.identityProof = null;
      item.identityNumber = '';
      item.isEditRow = true;
      item.isAddRow = true;
      //console.log('item add', item);
      this.gridData.push(item);
    } else {
      this.submitted = true;
      let msg = 'please enter and update the values for the added family member.';
      this.commonService.commonErrorPopup('Alert', msg, 'md');
      //alert('please enter and update the values for the added family member.');
    }
    
  }
  idproofselection(from?: any){
    if (from !== 'edit') {
      this.f1.tbIdNumber.patchValue("") ;
    }
    if(this.f1.tbIdProof?.value === "Aadhaar No"){
     this.f1.tberrorText?.setValue("Please enter valid Aadhaar No of 12 digits");
      this.f1.tbIdNumber?.setValidators([Validators.required,Validators.minLength(12),Validators.maxLength(12),WhitespaceValidator.whiteSpaceValidate,AadharValidator.aadharValidate])
      this.f1.tbIdNumber?.updateValueAndValidity();  
      this.idMinLength = 12; this.idMaxLength = 12;
    }
    else if(this.f1.tbIdProof?.value === "Aadhar Enrollment No"){
      this.f1.tberrorText?.setValue("Please enter valid Aadhaar Enrollment No of 14 digits");
       this.f1.tbIdNumber?.setValidators([Validators.pattern(this.commonConstants.aadharenrollmentIdPattern),Validators.required,Validators.minLength(14),Validators.maxLength(14),WhitespaceValidator.whiteSpaceValidate])
       this.f1.tbIdNumber?.updateValueAndValidity();  
       this.idMinLength = 14; this.idMaxLength = 14;
     }else if(this.f1.tbIdProof?.value === "EPIC ID/ Voter ID"){
      this.f1.tberrorText?.setValue("Enter Valid EPIC ID/ Voter ID No of 10 characters (Eg: XYZ1234567 Format)");
      this.f1.tbIdNumber?.setValidators([Validators.pattern(this.commonConstants.voterIdPattern),Validators.required,Validators.minLength(10),Validators.maxLength(12),WhitespaceValidator.whiteSpaceValidate])
      this.f1.tbIdNumber?.updateValueAndValidity();
      this.idMinLength = 10; this.idMaxLength = 12;
    }else if(this.f1.tbIdProof?.value === "Passport ID"){
      this.f1.tberrorText?.setValue("Enter Valid Passport No of 8 characters (Eg: X1234567 Format)");
      this.f1.tbIdNumber?.setValidators([Validators.pattern(this.commonConstants.passportPattern),Validators.required,Validators.minLength(8),Validators.maxLength(10),WhitespaceValidator.whiteSpaceValidate])
      this.f1.tbIdNumber?.updateValueAndValidity();
      this.idMinLength = 8; this.idMaxLength = 10;
    } else{
      this.f1.tberrorText?.setValue("Please enter Valid ID Proof");
      this.f1.tbIdNumber?.setValidators([Validators.pattern(this.commonConstants.alphanumericPattern),Validators.required,Validators.minLength(12),Validators.maxLength(12),WhitespaceValidator.whiteSpaceValidate])
      this.f1.tbIdNumber?.updateValueAndValidity();
      this.idMinLength = 12; this.idMaxLength = 12;
    }
    
  }
  formValidate(data: any) {
    let isEditActive = this.gridData?.filter((item: any) => item.isEditRow);
    //console.log('this.form.valid', this.form.valid);
    //console.log('this.gridForm.valid', this.gridForm.valid);
    let gridValid: any = isEditActive?.length > 0 ? this.gridForm.valid : true;
    if(this.form.valid && gridValid) {
      //console.log('true', this.form.value);
      //console.log('this.gridData', this.gridData);
      data.isValid = true;
      this.updateRequisition(data);
    } else {
      //console.log('false');
      data.isValid = false;
      this.validateForm.next(data);
    }
  }

  updateRequisition(data: any) {
    let postData: any = {} 
    let certificateInfo: any =   {
      deceasedFirstName: this.f['deceasedfirstName'].value,
      deceasedMiddleName: this.f['deceasedmiddleName'].value,
      deceasedLastName: this.f['deceasedlastName'].value,
      deceasedFatherHusbandName: this.f['fatherrHusbandName'].value,
      deceasedDateofDeath: this.commonService.formatDate(this.f['dateOfDeath'].value),
      reasonofDeath: this.f['reasonForDeath'].value,
      deceasedOccupation: this.f['occupation'].value,
      deceasedAadharNo: this.commonService.RSAEncrypt(this.f['aadharEnrollmentNo'].value),
      placeofDeath: this.f['deathPlace'].value
      //familyDetails: this.gridData
    }
    this.gridData = this.gridData?.map((item:any) => {
      item.identityNumber = item.identityProof === 'Aadhaar No' ? this.commonService.RSAEncrypt(item.identityNumber) : item.identityNumber;
      return item;
    });
    postData.requisitionId = this.reqId;
    postData.departmentCode = 'RV';
    postData.certificateDetails = certificateInfo;
    postData.updatedFamilyDetails = this.gridData;
   // console.log('update', postData);
    this.commonService.postRequest(this.commonConstants.updateFamilyMemberReq, postData).subscribe({
      next: (responseData:any) => { 
        if (responseData?.result?.isSuccess) {
            this.validateForm.next(data);
            this.certificateInfo.deceasedInfo.firstName = certificateInfo.deceasedFirstName;
            this.certificateInfo.deceasedInfo.middleName = certificateInfo?.deceasedMiddleName ? certificateInfo?.deceasedMiddleName : '';
            this.certificateInfo.deceasedInfo.lastName = certificateInfo?.deceasedLastName ? certificateInfo?.deceasedLastName : '';
            this.certificateInfo.deceasedInfo.fatherHusbandName = certificateInfo.deceasedFatherHusbandName;
            this.certificateInfo.deceasedDateofDeath = certificateInfo.deceasedDateofDeath;
            this.certificateInfo.reasonofDeath = certificateInfo.reasonofDeath;
            this.certificateInfo.deceasedOccupation = certificateInfo.deceasedOccupation;
            this.certificateInfo.deceasedAadharNo = certificateInfo?.deceasedAadharNo ? certificateInfo?.deceasedAadharNo : '';
            this.certificateInfo.placeofDeath = certificateInfo.placeofDeath;
        } else if (!responseData?.result?.isSuccess && responseData?.result?.error !== null) {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  onDataChange(data: any) {
    let dateOfDeath = data?.deceasedDateofDeath?.split('T')[0];
    if (data && data?.deceasedInfo) {
      this.form.setValue({
        deceasedfirstName : data?.deceasedInfo?.firstName,
        deceasedmiddleName : data?.deceasedInfo?.middleName ? (data.deceasedInfo?.middleName) : '',
        deceasedlastName : data?.deceasedInfo?.lastName ? data.deceasedInfo?.lastName : '',
        fatherrHusbandName : data?.deceasedInfo?.fatherHusbandName,
        dateOfDeath : this.datePipe.transform( dateOfDeath, 'dd-MM-yyyy'),
        occupation : data?.deceasedOccupation, 
        reasonForDeath : data?.reasonofDeath,
        deathPlace : data?.placeofDeath,
        aadharEnrollmentNo: data?.deceasedAadharNo ? this.commonService.RSADecrypt(data.deceasedAadharNo) : ''
      });                                
    }
    this.gridData = data?.familyDetails;
    this.gridData?.map((item: any) => {
        item.isEditRow = false;
        item.isAddRow = false;
        return item;
    });
    if(this.isEditable) {
      this.getNoIncomeOccupations();
      this.getRelations();
      this.getReasonForDeath();
     }
  }
  aadharChange(){
    if(this.f.aadharEnrollmentNo.value?.length === 12){
      this.f.aadharEnrollmentNo.setValidators([AadharValidator.aadharValidate]);
      this.f.aadharEnrollmentNo.updateValueAndValidity();
    }else{
      this.f.aadharEnrollmentNo.setValidators([Validators.minLength(14), Validators.maxLength(14)]);
      this.f.aadharEnrollmentNo.updateValueAndValidity();
    }
  }
}
