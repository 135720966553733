import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from 'src/shared/common.service';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { dobValidator } from 'src/shared/validators/dob.validator';

@Component({
  selector: 'app-social-audit-grievance-view',
  templateUrl: './social-audit-grievance-view.component.html',
  styleUrls: ['./social-audit-grievance-view.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class SocialAuditGrievanceViewComponent implements OnInit {

  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Input() reqId: any;
  @Input() serviceName: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();
  @Output() validateForm = new EventEmitter();
  @Output() casteChange: any = new EventEmitter();
  vroForm!: UntypedFormGroup;

  commonConstants : any = CommonConstants;
  subcaste: any;
  casteCategory: any[] = [];
  subCasteData: any[] =[];
  loggedInUserId: any;
  userInfo: any;
  userInformation: any;
  loggedinDetails: any;
  loggedinuserName: any;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  divisionName: any;
  typeOfHouseDropdown: any = [];
  accessToCookingGas: any = [];
  maritalStatus: any = [];
  educationalQualification: any = [];
  accessToDrinkingWater: any = [];
  religion: any = [];
  accessToToiletFacility: any = [];
  profession: any = [];
  agriculturalLandOwned: any = [];
  resedentialLandOwned: any = [];
  accessToCookingGasBoolean: boolean = false;
  agriculturalLandOwnedBoolean: boolean = false;
  resedentialLandOwnedBoolean: boolean = false;
  typeOfHouseBoolen: boolean = false;
  maritalStatusBoolean: boolean = false;
  educationalQualificationBoolean: boolean = false;
  accessToCleanDrinkingWaterBoolean: boolean = false;
  religionBooelan: boolean = false;
  accessToToiletFacilityBoolean: boolean = false;
  professionBoolean: boolean = false;
  resultBooleanData: any = [];
  formSubmitted: boolean = false;
  submitted: boolean = false;

  constructor(private commonService: CommonService, private formBuilder: UntypedFormBuilder) {
    let userData = this.commonService.getLocalStorage('userInfo');
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      this.loggedInUserId = this.userInfo?.userPrincipalName
    }
    // this.getbooleanData(this.certificateInfo);
    this.commonService.checkAction.subscribe((data: any)=> {
      if(data.isActionBtn) {
        this.formSubmitted = true;
        this.submitted = true;
        this.setFormValidators();
        this.formValidate(data);
      }
    });
  
    this.getDropdownData();
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
  }

  resultDataBoolean:any = [];

  ngOnInit(): void {
    this.vroForm = this.formBuilder.group({
      citizenName: [],
      gender: [],
      dob: [],
      caste: [],
      subcaste: [],
      typeOfHouseDropdown: [],
      accessToCookingGas: [],
      maritalStatus: [],
      educationalQualification: [],
      accessToCleanDrinkingWater: [],
      religion: [],
      accessToToiletFacility: [],
      profession: [],
      agriculturallandOwned: [],
      residentailLandOwned: [],
    })
    this.userInformation = this.commonService.getLocalStorage('userInfo');
    this.loggedinDetails = JSON.parse(this.userInformation);
    this.loggedinuserName = this.loggedinDetails.jobTitle;
    this.getCaste();
  }
  formValidate(data: any): any {
    if(this.vroForm.valid) {
      data.isValid = true;
      this.updateRequisition(data);
    } else {
      this.submitted = true;
      this.formSubmitted = true;
      data.isValid = false;
      this.validateForm.next(data);
    }

  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }

  get f() {
    return this.vroForm.controls;
  }
  getCaste() {
    this.commonService.getRequest(this.commonConstants.getCaste).subscribe({
      next: (responseData: any) => {
        this.casteCategory = this.commonService.sortData(responseData.result);
        if (this.certificateInfo.citizenDetails?.caste !== 'SC') {
          for (let i = this.casteCategory.length - 1; i >= 0; i--) {
            if (this.casteCategory[i].name != 'SC') {
              this.casteCategory.splice(i, 1);
            }
          }
        }
        this.commonService.setMasterData('caste', this.casteCategory);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  ngOnChanges(): void {
    if (this.certificateInfo && (this.basicInfo?.requisitionType === 'GrievanceCaste' || this.basicInfo?.requisitionType === 'GrievanceOthers')) {
      this.getbooleanData(this.certificateInfo);
    }
  }

  onCasteChange(name: string, event?: any, ) {
    this.commonService.getRequest(this.commonConstants.getSubCaste + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
        this.subcaste = this.commonService.sortData(responseData.result);
        this.casteChange.emit(this.subcaste);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  getbooleanData(reqData?: any) {
    if (this.basicInfo?.requisitionType === 'GrievanceOthers') {
      let allGrievanceData = ["Access to cooking gas", "Agricultural land owned", "Residential land owned", "Type of House", "Marital Status", "Educational Qualification", "Access to clean drinking water","Religion", "Access to Toilet facility", "Profession"]
      let othersGrievanceData = Object.values(reqData?.othersGrievance?.map((element: any) => element.label))
      this.resultBooleanData = allGrievanceData.map((item: any) => othersGrievanceData.some((check: any) => check === item));
      this.accessToCookingGasBoolean = this.resultBooleanData[0];
      this.agriculturalLandOwnedBoolean = this.resultBooleanData[1];
      this.resedentialLandOwnedBoolean = this.resultBooleanData[2];
      this.typeOfHouseBoolen = this.resultBooleanData[3];
      this.maritalStatusBoolean = this.resultBooleanData[4];
      this.educationalQualificationBoolean = this.resultBooleanData[5];
      this.accessToCleanDrinkingWaterBoolean = this.resultBooleanData[6];
      this.religionBooelan = this.resultBooleanData[7];
      this.accessToToiletFacilityBoolean = this.resultBooleanData[8];
      this.professionBoolean = this.resultBooleanData[9];
    }
  }

  setFormValidators() {
    if (this.loggedinuserName == 'Village Revenue Officer' || this.loggedinuserName === 'Ward Revenue Secretary') {
      if (!this.certificateInfo.citizenDetails.name) {
        this.f.citizenName.setValidators([Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
        this.f.citizenName.updateValueAndValidity();
      }
      if (!this.certificateInfo.citizenDetails.gender) {
        this.f.gender.setValidators([Validators.required]);
        this.f.gender.updateValueAndValidity();
      }
      if (!this.certificateInfo.citizenDetails.dateOfBirth) {
        this.f.dob.setValidators([Validators.required, dobValidator]);
        this.f.dob.updateValueAndValidity();
      }
    }
    if(this.basicInfo?.requisitionType === 'GrievanceCaste' && (this.loggedinuserName == 'Village Revenue Officer' || this.loggedinuserName === 'Ward Revenue Secretary')) {
      //this.formSubmitted = true;
      this.f.caste.setValidators([Validators.required]);
      this.f.subcaste.setValidators([Validators.required]);
      this.f.caste.updateValueAndValidity();
      this.f.subcaste.updateValueAndValidity();
    } else {
      this.f.caste.clearValidators();
      this.f.subcaste.clearValidators();
      this.f.caste.updateValueAndValidity();
      this.f.subcaste.updateValueAndValidity();
    }
    if(this.basicInfo.requisitionType === 'GrievanceOthers' && (this.loggedinuserName === "Village Revenue Officer" || this.loggedinuserName === 'Ward Revenue Secretary')) {
      if(this.accessToCookingGasBoolean) {
        this.f.accessToCookingGas.setValidators([Validators.required]);
        this.f.accessToCookingGas.updateValueAndValidity();
      }
      else {
        this.f.accessToCookingGas.clearValidators();
        this.f.accessToCookingGas.updateValueAndValidity();
      }
      if(this.agriculturalLandOwnedBoolean) {
        this.f.agriculturallandOwned.setValidators([Validators.required]);
        this.f.agriculturallandOwned.updateValueAndValidity();
      }
      else {
        this.f.agriculturallandOwned.clearValidators();
        this.f.agriculturallandOwned.updateValueAndValidity();
      }
      if(this.resedentialLandOwnedBoolean) {
        this.f.residentailLandOwned.setValidators([Validators.required]);
        this.f.residentailLandOwned.updateValueAndValidity();
      }
      else {
        this.f.residentailLandOwned.clearValidators();
        this.f.residentailLandOwned.updateValueAndValidity();
      }
      if(this.typeOfHouseBoolen) {
        this.submitted = true;
        this.f.typeOfHouseDropdown.setValidators([Validators.required]);
        this.f.typeOfHouseDropdown.updateValueAndValidity();
      }
      else {
        this.f.typeOfHouseDropdown.clearValidators();
        this.f.typeOfHouseDropdown.updateValueAndValidity();
      }
      if(this.maritalStatusBoolean) {
        this.f.maritalStatus.setValidators([Validators.required]);
        this.f.maritalStatus.updateValueAndValidity();
      }
      else {
        this.f.maritalStatus.clearValidators();
        this.f.maritalStatus.updateValueAndValidity();
      }
      if(this.educationalQualificationBoolean) {
        this.f.educationalQualification.setValidators([Validators.required]);
        this.f.educationalQualification.updateValueAndValidity();
      }
      else {
        this.f.educationalQualification.clearValidators();
        this.f.educationalQualification.updateValueAndValidity();
      }
      if(this.accessToCleanDrinkingWaterBoolean) {
        this.f.accessToCleanDrinkingWater.setValidators([Validators.required]);
        this.f.accessToCleanDrinkingWater.updateValueAndValidity();
      }
      else {
        this.f.accessToCleanDrinkingWater.clearValidators();
        this.f.accessToCleanDrinkingWater.updateValueAndValidity();
      }
      if(this.religionBooelan) {
        this.f.religion.setValidators([Validators.required]);
        this.f.religion.updateValueAndValidity();
      }
      else {
        this.f.religion.clearValidators();
        this.f.religion.updateValueAndValidity();
      }
      if(this.accessToToiletFacilityBoolean) {
        this.f.accessToToiletFacility.setValidators([Validators.required]);
        this.f.accessToToiletFacility.updateValueAndValidity();
      }
      else {
        this.f.accessToToiletFacility.clearValidators();
        this.f.accessToToiletFacility.updateValueAndValidity();
      }
      if(this.professionBoolean) {
        this.f.profession.setValidators([Validators.required]);
        this.f.profession.updateValueAndValidity();
      }
      else {
        this.f.profession.clearValidators();
        this.f.profession.updateValueAndValidity();
      }
    }
  }

  public trackItem (item: any) {
    return item;
  }

  getDropdownData() {
    this.commonService.getRequest(this.commonConstants.getByParentId + 'Caste Survey Greviance&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => { 
        let temp: any = [];
        let data = responseData?.result;
        data?.forEach((item: any) => {
          if (item !== '' && item !== null) {
            temp.push({ value: item.id, label: item.name });
          }
        });
        // this.otherGrievanceList = this.commonService.sortItems(temp, 'label');
        temp.forEach((element: any, index: any) => {
          this.commonService.getRequest(this.commonConstants.getByParentId + element.label + '&LanguageCode=EN-IN&ParentId=' + element.value).subscribe({
            next: (responseData: any) => {
              if(responseData.result[0].entityType == 'Type of House') {
                this.typeOfHouseDropdown = responseData.result
              }
              if(responseData.result[0].entityType == "Access to cooking gas") {
                this.accessToCookingGas = responseData.result
              }
              if(responseData.result[0].entityType == "Marital Status") {
                this.maritalStatus = responseData.result
              }
              if(responseData.result[0].entityType == "Educational Qualification") {
                this.educationalQualification = responseData.result
              }
              if(responseData.result[0].entityType == "Access to clean drinking water") {
                this.accessToDrinkingWater = responseData.result
              }
              if(responseData.result[0].entityType == "Religion") {
                this.religion = responseData.result
              }
              if(responseData.result[0].entityType == "Access to Toilet facility") {
                this.accessToToiletFacility = responseData.result
              }
              if(responseData.result[0].entityType == "Profession") {
                this.profession = responseData.result
              }
              if(responseData.result[0].entityType == "Agricultural land owned") {
                this.agriculturalLandOwned = responseData.result
              }
              if(responseData.result[0].entityType == "Residential land owned") {
                this.resedentialLandOwned = responseData.result
              }
            }
          })
        })
      
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  getSubCaste(){
  
    let districtCode = this.basicInfo?.applicantInfo?.addresses[0].district;
    let mandalCode = this.basicInfo?.applicantInfo?.addresses[0].mandalMunicipality;
  this.commonService.getRequest(this.commonConstants.getDistrictWiseCasteData + districtCode + '&MandalCode='+ mandalCode +'&CasteCategory=' + this.f.caste.value?.replace('-','')).subscribe({
    next: (responseData:any) => { 
      if(responseData.result?.isSuccess) {
        let data: any = responseData.result?.subcasteList;
        this.subCasteData = data.sort((a:any,b:any) => (a > b) ? 1 : -1);
     } else {
      this.subCasteData = [];
     }
    },
    error: (error) => {
      this.subCasteData  = [];
      console.log('client side', error);
    }
  })
}
updateRequisition(data?: any) {
  let postData: any = {} 
  if(this.basicInfo.requisitionType === 'GrievanceCaste') {
    postData.RequisitionId  = this.reqId;
    postData.RequisitionType = this.basicInfo.requisitionType;
    postData.Name = this.certificateInfo?.citizenDetails?.name ? this.certificateInfo?.citizenDetails?.name : this.f.citizenName.value;
    postData.Aadhar  = this.certificateInfo?.citizenDetails?.aadhar;
    postData.Gender  = this.certificateInfo?.citizenDetails?.gender ? this.certificateInfo?.citizenDetails?.gender : this.f.gender.value,
    postData.DateOfBirth  = this.certificateInfo?.citizenDetails?.dateOfBirth ? this.certificateInfo?.citizenDetails?.dateOfBirth : this.commonService.dobFormatDate(this.f.dob.value),
    postData.MaritalStatus  = this.certificateInfo?.citizenDetails?.maritalStatus,
    postData.Caste  = this.f.caste.value;
    postData.SubCaste  = this.f.subcaste.value;
    postData.Religion  = this.certificateInfo?.citizenDetails?.religion,
    postData.TypeofHouse  = this.certificateInfo?.citizenDetails?.typeOfHouse,
    postData.AccessToToiletFacility  = this.certificateInfo?.citizenDetails?.accessToToiletFacility,
    postData.AccessToCleanDrinkingWater  = this.certificateInfo?.citizenDetails?.accessToCleanDrinkingWater,
    postData.AccessToCookingGas  = this.certificateInfo?.citizenDetails?.accessToCookingGas,
    postData.EducationalQualification  = this.certificateInfo?.citizenDetails?.educationalQualification,
    postData.Profession  = this.certificateInfo?.citizenDetails?.profession,
    postData.AcriculturalLandOwned  = this.certificateInfo?.citizenDetails?.agriculturallandOwned,
    postData.ResidentailLandOwned  = this.certificateInfo?.citizenDetails?.residentailLandOwned  
  }
  else {
  postData.RequisitionId  = this.reqId;
  postData.RequisitionType = this.basicInfo.requisitionType;
  postData.Name = this.certificateInfo?.citizenDetails?.name ? this.certificateInfo?.citizenDetails?.name : this.f.citizenName.value;
  postData.Aadhar  = this.certificateInfo?.citizenDetails?.aadhar;
  postData.Gender  = this.certificateInfo?.citizenDetails?.gender ? this.certificateInfo?.citizenDetails?.gender : this.f.gender.value,
  postData.DateOfBirth  = this.certificateInfo?.citizenDetails?.dateOfBirth ? this.certificateInfo?.citizenDetails?.dateOfBirth : this.commonService.dobFormatDate(this.f.dob.value),
  postData.MaritalStatus  = this.maritalStatusBoolean ? this.f.maritalStatus.value : this.certificateInfo?.citizenDetails?.maritalStatus,
  postData.Caste  = this.certificateInfo?.citizenDetails?.caste;
  postData.SubCaste  = this.certificateInfo?.citizenDetails?.subcaste;
  postData.Religion  = this.religionBooelan ? this.f.religion.value : this.certificateInfo?.citizenDetails?.religion,
  postData.TypeofHouse  = this.typeOfHouseBoolen ? this.f.typeOfHouseDropdown.value : this.certificateInfo?.citizenDetails?.typeOfHouse,
  postData.AccessToToiletFacility  = this.accessToToiletFacilityBoolean ? this.f.accessToToiletFacility.value : this.certificateInfo?.citizenDetails?.accessToToiletFacility,
  postData.AccessToCleanDrinkingWater  = this.accessToCleanDrinkingWaterBoolean ? this.f.accessToCleanDrinkingWater.value : this.certificateInfo?.citizenDetails?.accessToCleanDrinkingWater,
  postData.AccessToCookingGas  = this.accessToCookingGasBoolean ? this.f.accessToCookingGas.value : this.certificateInfo?.citizenDetails?.accessToCookingGas,
  postData.EducationalQualification  = this.educationalQualificationBoolean ? this.f.educationalQualification.value : this.certificateInfo?.citizenDetails?.educationalQualification,
  postData.Profession  = this.professionBoolean ? this.f.profession.value : this.certificateInfo?.citizenDetails?.profession,
  postData.AcriculturalLandOwned  = this.agriculturalLandOwnedBoolean ? this.f.agriculturallandOwned.value : this.certificateInfo?.citizenDetails?.agriculturallandOwned,
  postData.ResidentailLandOwned  = this.resedentialLandOwnedBoolean ? this.f.residentailLandOwned.value : this.certificateInfo?.citizenDetails?.residentailLandOwned
}

  this.commonService.postRequest(this.commonConstants.UpdateCasteGrievanceData, postData).subscribe({
    next: (responseData:any) => { 
      if (responseData?.result?.isSuccess) {
          this.validateForm.next(data);
      } 
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
}
}
