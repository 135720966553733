<!-- <div class="modal-header" >
    <button type="button" class="close" aria-label="Close" (click)="reset()" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span >&times;</span>
    </button>
</div> -->
<div class="mr-3 modal-body">
    <!-- <app-aadhar-authentication></app-aadhar-authentication> -->
    <app-aadhar-authentication [aadharNumber] = "aadharNumberInput" (isAuthenticated)="authenticateUpdate()"></app-aadhar-authentication>

</div>
