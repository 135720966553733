    <div class="certificateInfo">
        <div class="subHeadingCls mb-3">Applicant Details</div>
        <ng-container *ngIf="certificateInfo?.services?.length > 0">
            <div class="row mb-3">
                <div class="col">
                    <div class="tab table-responsive">
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th>Sl.No.</th>
                                    <th>Department Name</th>
                                    <th>Service Name</th>
                                    <th>Has citizen furnished all the mandatory documents?</th>
                                    <th>Service Token</th>
                                    <th width="15%">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="services && services?.length > 0">
                                <tr *ngFor="let item of services; let i = index">
                                    <td> {{ i + 1 }}</td>
                                    <td> {{ item.departmentName }}</td>
                                    <td> {{ item.serviceName }}</td>
                                    <td> {{ item.isAllDocuments }}</td>
                                    <td> {{ item.tokenNo ? item.tokenNo : "No token is generated" }}</td>
                                    <td>
                                        <button *ngIf="item.tokenNo" class="btn btn-primary mr-3" (click)="openConfirmModal(confirmModal, item.tokenNo)">
                                            <i class="fa fa-trash"></i>Delete Token
                                        </button>
                                    </td>
                                </tr>                                                
                            </tbody>
                            <tbody *ngIf="services && services?.length === 0">
                                <tr><td colspan="6" class="text-center">No records found.</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #confirmModal let-modal>
        <div class="modal-header">
         <h5 class="modal-title" id="editProfileLabel">Confirm Delete</h5>
         <button type="button" class="close" #closebutton (click)="modal.dismiss(); selectedToken = '';" aria-label="Close">
          <span >&times;</span>
         </button>
        </div>
        
        <div class="modal-body">
          <p>Are you sure you want to delete this token?</p>
        </div>
        <div class="modal-footer">
          <button  name="close" class="greenBtnCls" (click)="modal.dismiss(); selectedToken = '';">No</button>
          <button  type="submit" class="greenBtnCls" name="logout" (click)="deleteToken()">Yes</button>
        </div>
       </ng-template>