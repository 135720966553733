import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  NgbNavConfig,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbNavChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  CommonService,
  WhitespaceValidator
} from 'src/shared/common.service';
import { DatePipe } from '@angular/common';
import { CommonConstants } from 'src/constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  fileExtensionValidator,
  fileSizeValidator,fileSizeValidatorForAarogyaSri
} from 'src/shared/validators/file-validator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { parse } from 'path';
import { PatientBankDetailsPopupComponent } from 'src/app/patient-bank-details-popup/patient-bank-details-popup.component';

@Component({
  selector: 'app-patient-bankdetails',
  templateUrl: './patient-bankdetails.component.html',
  styleUrls: ['./patient-bankdetails.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class PatientBankDetailsComponent implements OnInit {
  
  commonConstants: any = CommonConstants;
  bankDetailForm: UntypedFormGroup;
  passbookFile: any[] = [];
  base64File: any = "";
  
  public commonFormSubmitted: Boolean = false;
  public getDetailsFlag: Boolean = false;
  public caseData:any[] = [];
  public FamilyDetails:any[] = [];
  public BankDetails:any[] = [];
  public BankBranchDetails:any[] = [];
  public selectedCase: any = null;
  public submitted:boolean = false;
  public isAuthenticated:boolean = false;

  @ViewChild('nav') nav: any;
  @ViewChild('passbookLabel') passbookLabel!: ElementRef ;

  get f() {
    return this.bankDetailForm.controls;
  }

  constructor(
    private config: NgbNavConfig,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    this.bankDetailForm = this.formBuilder.group({
      commonForm: [],
      aarogya_sri_number: ["", [Validators.required,Validators.minLength(11)]],
      account_holder_type: ["S", [Validators.required]],
      account_holder_name: [null, [Validators.required]],
      father_name: [null, [Validators.required,  Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      account_number: [null, [Validators.required]],
      re_account_number: [null, [Validators.required]],
      bank_name: [null, [Validators.required]],
      bank_branch: [null, [Validators.required]],
      ifsc_code: [null, [Validators.required, Validators.pattern(this.commonConstants.ifscCodePattern), WhitespaceValidator.whiteSpaceValidate]],
      remarks: [null, [Validators.required,  Validators.pattern(this.commonConstants.noSpecialCharacterPattern), WhitespaceValidator.whiteSpaceValidate]],
      passbook : ['',Validators.required],
      familyForm: ['', [Validators.required]],
    }, { validator: this.checkAccountNumber });
    config.destroyOnHide = false;
    config.roles = false;
  }

  checkAccountNumber(group: UntypedFormGroup) {
    const acno = group.controls.account_number.value;
    const confirmacno = group.controls.re_account_number.value;

    if(!isNaN(Number(acno))){
      var numberValue = Number(acno);
      if(numberValue > 0) {
        group.controls.account_number.setErrors(null);
      } else {
        group.controls.account_number.setErrors({ acnumbernotvalid: true });
      }
    } else{
      group.controls.account_number.setErrors({ acnumbernotvalid: true });
    }
    
    if (acno === confirmacno) {
      group.controls.re_account_number.setErrors(null);
    } else {
      group.controls.re_account_number.setErrors({ acnumberMismatch: true });
    }
}

  ngOnInit(): void {
    this.getBankDetails();
  }

  goToNextTab() {
    this.commonFormSubmitted = true;
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }else {
      this.commonService.commonErrorPopup('Alert', "Please enter the mandatory fields!", 'md');
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;     
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      this.commonService.commonErrorPopup('Alert', "Please enter the mandatory fields!", 'md');       
      changeEvent.preventDefault();
    }
  }

  resetCommonForm(){
    this.commonFormSubmitted = false;
    this.getDetailsFlag = false;
    this.caseData = []; 
    this.selectedCase = null;
    this.f['aarogya_sri_number'].patchValue('');
  }

  onUHIDChange(){
    this.caseData = []; 
    this.selectedCase = null;
  }

  getcaseData():any {
    this.caseData = []; 
    this.selectedCase = null;
    this.getDetailsFlag = true; 
    if (this.f['aarogya_sri_number'].value.length < 11) { 
      return false;
    } 
    else {
      const uhid = this.f['aarogya_sri_number'].value;
      this.validateUHIDwithAadhar(uhid);
    }    
  }

  validateUHIDwithAadhar(uhid: any) {
    let postData= {
      UHID: uhid
    }
    this.commonService.postRequest(this.commonConstants.getDataBasedOnUHID, postData, true).subscribe({ next: (responseData: any) => {       
      if(responseData?.result?.isSuccess===true) {
        let flag=responseData?.result?.dataFlag;
        let familyData:any=[];
        let legacyData:any=[];
        let legacyaadhar:any=[];
        let aadhar:any=[];
        if(flag=== 'R') {
          familyData=responseData?.result?.requisition?.requisitionData.familyDetails;
          aadhar = familyData.filter((item:any) => ( this.commonService.RSADecrypt(item.familyAadharNo) == this.f['commonForm'].value['aadharNo']))
        }else{
          legacyData=responseData?.result?.legacyData;
          legacyaadhar=legacyData.filter((item:any) => (item.aadhar_No == this.f['commonForm'].value['aadharNo']))
        }       
        
        if(aadhar.length <= 0 && legacyaadhar.length <= 0){
          this.commonService.commonErrorPopup('Alert', "The aadhar number entered in Basic details does not match with the UHID.", 'md');
          return;
        } else {
          const modalRef = this.modalService.open(PatientBankDetailsPopupComponent, { size: 'xl' });
                    modalRef.componentInstance.title = 'Alert';
                    modalRef.componentInstance.aadhaarNumber = aadhar;
                    modalRef.result.then((data: any) => {
                    if (data) {
                      this.isAuthenticated = true;
                      this.commonService.getRequest(this.commonConstants.caseSearch + "?uhid="+ uhid, "MAUD").subscribe({ next: (responseData: any) => {       
                        if(responseData != null && responseData != undefined && responseData.length > 0) {
                          responseData.forEach((item: any) => {
                            this.caseData.push(item);
                          });
                        } else {
                          this.commonService.commonErrorPopup('Alert', "No case found.", 'md');
                        }      
                      },
                      error: (error) => {
                        console.log('client side', error);
                      }});
                    }
                  });
        }      
      }else {
        this.commonService.commonErrorPopup('Alert', "Please enter valid UHID.", 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }});
  }

  selectCase(_selectedCase: any) {
    if(_selectedCase) {
      this.selectedCase = _selectedCase;
      setTimeout(() => {
        this.resetForm(true);
        this.getFamilyDetails(_selectedCase.caseId);
      }, 1000);      
    }
  }

  resetForm(isResetAccoutHolderDetails: boolean) {
    if(isResetAccoutHolderDetails) {
      this.f['account_holder_type'].patchValue("S");
      this.f['account_holder_name'].patchValue(this.selectedCase.patientName);
    }
    this.f['father_name'].patchValue(null);
    this.f['account_number'].patchValue(null);
    this.f['re_account_number'].patchValue(null);
    this.f['bank_name'].patchValue(null);
    this.f['bank_branch'].patchValue(null);
    this.f['ifsc_code'].patchValue(null);
    this.f['remarks'].patchValue(null);
    this.f['familyForm'].patchValue(null);
    this.clearFileUpload('passbook', 'passbookLabel');
    this.BankBranchDetails = []; 
  }

  acTypeChange(account_holder_type: string) {    
    if(account_holder_type == "S") {
      this.f['account_holder_name'].patchValue(this.selectedCase.patientName);
    } else if(account_holder_type == "F") {
      this.f['account_holder_name'].patchValue(null);
    }
    this.resetForm(false);
  }

  acNameChange() {
    this.resetForm(false);
  }

  getFamilyDetails(caseId: any):any {
    this.FamilyDetails = [];
    if (caseId == "") { 
      return false;
    } else {
      this.commonService.getRequest(this.commonConstants.getFamilyDetails + "?caseId="+ caseId, "MAUD").subscribe({ next: (responseData: any) => {       
        if(responseData != null && responseData != undefined) {
          responseData.forEach((item: any) => {
            this.FamilyDetails.push(item);
          });
        }
      },
      error: (error) => {
        console.log('client side', error);
      }});
    }
  }

  getBankDetails():any {
    this.BankDetails = [];   
    this.commonService.getRequest(this.commonConstants.getBankDetails, "MAUD").subscribe({ next: (responseData: any) => {       
      if(responseData != null && responseData != undefined) {
        responseData.forEach((item: any) => {
          this.BankDetails.push(item);
        });
      }
    },
    error: (error) => {
      console.log('client side', error);
    }});        
  }

  onBankChange(event?: any) {
    this.BankBranchDetails = [];  
    this.f['ifsc_code'].patchValue('');
    if(this.f['bank_name'].value != null) {
      this.commonService.getRequest(this.commonConstants.getBankDetails + "?bankName=" + this.f['bank_name'].value.bankName, "MAUD").subscribe({
        next: (responseData: any) => { 
          if(responseData != null && responseData != undefined) {
            responseData.forEach((item: any) => {
              this.BankBranchDetails.push(item);
            });
          }        
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    } 
  }

  onBankBranchChange(event?: any) {
    this.f['ifsc_code'].patchValue('');
    if(this.f['bank_name'].value != null && this.f['bank_branch'].value != null) {
      this.commonService.getRequest(this.commonConstants.getBankDetails + "?bankName=" + this.f['bank_name'].value.bankName + "&branchName=" +this.f['bank_branch'].value.branchName, "MAUD").subscribe({
        next: (responseData: any) => { 
          if(responseData != null && responseData != undefined && responseData.length > 0) {
            this.f['ifsc_code'].patchValue(responseData[0].ifscCode); 
          }        
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
  }

  onIfscCodeChange() {
    this.f['bank_name'].patchValue(null);
    this.f['bank_branch'].patchValue(null);
    if(this.f['ifsc_code'].valid) {      
      this.commonService.getRequest(this.commonConstants.getBankDetails + "?ifscCode=" + this.f['ifsc_code'].value, "MAUD").subscribe({
        next: (responseData: any) => { 
          if(responseData != null && responseData != undefined && responseData.length > 0) {
            const filteredBank = this.BankDetails.filter((x:any) => x.bankName === responseData[0].bankName) 
            if(filteredBank.length > 0) {              
              this.f['bank_name'].patchValue(filteredBank[0]); 
              this.BankBranchDetails = [];  
              if(this.f['bank_name'].value != null) {
                this.commonService.getRequest(this.commonConstants.getBankDetails + "?bankName=" + this.f['bank_name'].value.bankName, "MAUD").subscribe({
                  next: (responseDataBank: any) => { 
                    if(responseDataBank != null && responseDataBank != undefined) {
                      responseDataBank.forEach((item: any) => {
                        this.BankBranchDetails.push(item);
                      });
                      const filteredBranch = this.BankBranchDetails.filter((x:any) => x.branchName === responseData[0].branchName) 
                      if(filteredBranch.length > 0) {   
                        this.f['bank_branch'].patchValue(filteredBranch[0]); 
                      }
                    }        
                  },
                  error: (error) => {
                    console.log('client side', error);
                  }
                });
              }
            }
          }        
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
  }

  submit(id: any){
    if(!this.bankDetailForm.valid){
      return;
    } else {
      let account_holder_name= "";
      if(this.f.account_holder_type.value == "S") {
        account_holder_name = this.selectedCase.patientName;
      } else {
        account_holder_name = this.f.account_holder_name.value.memberName;
      }

      let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
      let postData = {          
        requisitionHeader:  {
          requisitionType: 'PatientBankDetails',
          requisitionPKey: '',
          department: 'HF',
          applicantInfo: {
            id: '',
            aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo']?.trim(), 
            personNames: [
              {
                languageCode: 'en-in',
                firstName: this.f['commonForm'].value['firstName'],
                middleName: this.f['commonForm'].value['middleName'],
                lastName: this.f['commonForm'].value['lastName'],
                fatherHusbandName:
                  this.f['commonForm'].value['fatherHusName'],
              },
            ],
            gender: this.f['commonForm'].value['gender'],
            birthDate: dateFormat,
            caste: this.f['commonForm'].value['caste'].name,
            religion: this.f['commonForm'].value['religion'].name,
            qualification: this.f['commonForm'].value['qualification'].name,
            maritalStatus: this.f['commonForm'].value['maritalStatus'],
            addresses: [
              {
                addressType: 'present',
                doorNo: this.f['commonForm'].value['preDoorNo'],
                streetName: this.f['commonForm'].value['preStreet'],
                villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                district: this.f['commonForm'].value['preDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['prePinCode'],
                isRevenueLocation: true,
                postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
              },
              {
                addressType: 'permanent',
                doorNo: this.f['commonForm'].value['permDoorNo'],
                streetName: this.f['commonForm'].value['permStreet'],
                villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                district: this.f['commonForm'].value['permDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['permPinCode'],
                isRevenueLocation: true,
                postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
              },
            ],
            rationCardNo: '',
            deliveryType: this.f['commonForm'].value['deliveryType'],
            emailAddress: this.f['commonForm'].value['email'],
            mobileNumber: this.f['commonForm'].value['mobileNo'],
            isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
            isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
          },
          remarks: '',
        },        
        requisitionData: {
          "RequestId":  id,
          "uhid": this.f['aarogya_sri_number'].value,
          "caseId": this.selectedCase.caseId,
          "accStatus": this.selectedCase.accStatus,
          "patientName": this.selectedCase.patientName,
          "cardNumber": this.selectedCase.cardNo,
          "hospitalName": this.selectedCase.hospName,
          "acHolderType": this.f.account_holder_type.value,
          "acHolderName": account_holder_name,
          "fatherHusbandName": this.f.father_name.value,
          "accountNo": this.commonService.RSAEncrypt(this.f.account_number.value),
          "bankName": this.f.bank_name.value.bankName,
          "bankBranch": this.f.bank_branch.value.branchName,
          "iFSCCode": this.f.ifsc_code.value,
          "remarks": this.f.remarks.value,
        },
      };
      this.commonService.postRequest(this.commonConstants.submitAccountDetails, postData).subscribe({ next: (responseData: any) => {       
        this.updateFileUpload(responseData['result'].id);
      },
      error: (error) => {
        console.log('client side', error);
      }});    

    }
  }

  onPassbookFileSelected(fileEvent: any) {
    const appFormFile: File | null = fileEvent.target.files[0];
    this.passbookLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.bankDetailForm.controls['passbook'].setValidators([
      Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)
    ])
    this.bankDetailForm.controls['passbook'].updateValueAndValidity();
    if (this.f.passbook.valid){
      this.fileUpload(appFormFile,this.f['passbook'].value,fileEvent.target.files[0].name,'Passbook', 'passbook', 'passbookLabel');
    }
  }

  clearFileUpload(name:string, label:string) {
    this.f[name].reset();
    if (label === 'passbookLabel') {
      this.passbookLabel.nativeElement.innerText = 'No File Choosen';
    } 
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
      console.log( formData,'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => {
        if(typeof(responseData.result) !== 'string'){ 
          this.passbookFile  = [{
            FileName: fileName,
            DocumentType: documentType,
            BlobRef : responseData['result'].blobRef
          }];
        }else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }

  updateFileUpload(id: any) {
    let fileData = {
      FileList: this.passbookFile,
      RequisitionId: id
    };
    this.commonService
      .postRequest(this.commonConstants.HealthCardFileUpload, fileData)
      .subscribe({
        next: (resData: any) => {
          if(resData.result?.updateResult == 'Success') {
            this.showPayment(id);
          } else {
            this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
          }
          console.log(resData, 'File Upload  Respose data');
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  submitAccountDetails() {
    this.submitted = true;
    if(this.bankDetailForm.valid){
      let account_holder_name= "";
      if(this.f.account_holder_type.value == "S") {
        account_holder_name = this.selectedCase.patientName;
      } else {
        account_holder_name = this.f.account_holder_name.value.memberName;
      }
      let postData = {
        "caseId": this.selectedCase.caseId,
        "acHolderType": this.f.account_holder_type.value,
        "acHolderName": account_holder_name,
        "fatherHusbandName": this.f.father_name.value,
        "accountNo": this.commonService.RSAEncrypt(this.f.account_number.value),
        "bankName": this.f.bank_name.value.bankName,
        "bankBranch": this.f.bank_branch.value.branchName,
        "iFSCCode": this.f.ifsc_code.value,
        "remarks": this.f.remarks.value,
        "blobRef": this.passbookFile[0].BlobRef
      };
      this.commonService
        .postRequest(this.commonConstants.submitExternalAccountDetails, postData)
        .subscribe({
          next: (resData: any) => {
            if(resData && resData.result && resData.result.submitAsaraResponse 
            && this.isJsonString(resData.result.submitAsaraResponse) && resData.result.status == "Success") {
              const submitAsaraResponse = JSON.parse(resData.result.submitAsaraResponse);
              if(submitAsaraResponse && submitAsaraResponse.status){
                if(submitAsaraResponse.status == "Aasara Details Submitted Successfully") {
                  this.submit(resData.result.requestId);
                } else {
                  this.commonService.commonErrorPopup('Alert', submitAsaraResponse.status, 'md');
                }
              } else {
                this.commonService.commonErrorPopup('Alert', "Error occured during call the Nwh Asara submit API.", 'md');
              }              
            } else if(resData && resData.result && resData.result.submitAsaraResponse 
              && this.isJsonString(resData.result.submitAsaraResponse) && resData.result.status == "Failed") { 
              const submitAsaraResponse = JSON.parse(resData.result.submitAsaraResponse);
              if(submitAsaraResponse && submitAsaraResponse.status){                
                  this.commonService.commonErrorPopup('Alert', submitAsaraResponse.status, 'md');                
              } else {
                this.commonService.commonErrorPopup('Alert', "Error occured during call the Nwh Asara submit API.", 'md');
              }
            } else {
              this.commonService.commonErrorPopup('Alert', "Error occured during call the Nwh Asara submit API.", 'md');
            }
            console.log(resData, 'External API Respose data');
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
      }
  }
  
  isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  showPayment(requisionId: any) {
    const queryParams: Params = {
      id: requisionId,
      department: 'HF',
      type: 'PatientBankDetails',
    };
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
}