import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import * as XLSX from 'xlsx';
import { CheckoutService } from '../../../angular-checkout-js/src/lib/services/checkout.service';

@Component({
  selector: 'app-challan-generation',
  templateUrl: './challan-generation.component.html',
  styleUrls: ['./challan-generation.component.css'],
})
export class ChallanGenerationComponent implements OnInit {
  challanGeneration: UntypedFormGroup;
  public submitted: Boolean = false;
  public isActive: Boolean = false;
  listOfTrasaction: any[] = [];
  challanDetails: any = {};
  title: string = '';
  closeResult: string = '';
  commonConstants: any = CommonConstants;
  userInfo: any = [];
  totalAmount: number = 0;
  totalTransaction: number = 0;
  totalAmountInWords: string = '';
  postData: any = {};
  trasactionData: any[] = [];
  trasactionHistroryData: any[] = [];
  barcodeValue: any;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  serviceList: any = [];
  paymentRedirectionURL:any = '';
  merchantID:any = '';
  merchantIDsamllcase:any = '';
  buildUPIUrl:any = '';
  userIdData : string = '';
  transactionID: any;
  departmentID: any;
  userData: any;
  mobileNo: any;
  masterSelectedd:boolean =false;
  checklist: any = [];
  checkedList:any = [];
  totalTransactionAmnt:any = 0;
  tempItems: any = [];

  challanServiceType:any;
  challanTypeActive:boolean =false;
  isChallan:Boolean=true;

  totalPageCount: number = 0;
  secreteriateCode:any;
  page = 1;
  pageSize = 200;
  isCitizen:Boolean = false;
  listOfAllTrasaction: any[] = [];

  @ViewChild('selectedChallanModal') selectedChallanModal!: ElementRef ;


  /* 
    MAINTAIN FLAG FOR SHOW HIDE OLD or NEW CHALLAN FUNCTIONALITY
    TRUE : NEW
    FALSE : OLD 
  */
  newChallanFunction:boolean = true;
  isProd: Boolean = false;
  isLoginPresent: Boolean = false;
  loggedinUserMobileNo: any;
  /* -------------------paytm------- */
isCheckoutVisible = false;
openInPopup = true;
config: any = {};
/* -------------------paytm------- */
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private readonly checkoutService: CheckoutService
  ) {
    let userInfo = this.commonService.getLocalStorage('userInfo');
    if (userInfo && userInfo !== null) {
      this.userData = JSON.parse(userInfo);
      this.userIdData = this.userData.userPrincipalName;
    }
    this.secreteriateCode =this.commonService.getLocalStorage('locationCode');
    this.challanGeneration = this.formBuilder.group({
      payment_mode: [null, [Validators.required]],
    });

   
    this.currentRoute.queryParams.subscribe((params: any) => {
     
      if (params.id) {
        const modalRef = this.modalService.open(CommonMsgModalComponent, {
          size: 'md',
        });
        //modalRef.componentInstance.reqId = params.id;
        let mode: any = params?.mode === 'paytm' ? 'The Paytm' : 'The Bildesk'
        modalRef.componentInstance.title = 'Transaction Success';
        modalRef.componentInstance.message = mode + ' Transaction is Successfully done with Transaction ID : ' +
          params.id +
          '  Transaction Amount : ' +
          params.amount +
          ' /-.';
        modalRef.result.then((result: any) => {
          if (result) {
            //this.router.navigate([], { queryParams: null });

            const queryParams: Params = { type: params.type};
            this.router.navigate(['/view-challan'], {
              relativeTo: this.currentRoute,
              queryParams: queryParams,
              queryParamsHandling: '',
            });
            //console.log(result);
          }
        });
      } else if(params.type){
            //console.log('type......',params.type);
            this.checkedList = [];
            this.totalTransactionAmnt = 0;
            this.page = 1;
            this.pageSize = 200;
            this.challanServiceType = params.type;
            let userData = this.commonService.getLocalStorage('userInfo');
            if (userData && userData !== null) {
              this.userInfo = JSON.parse(userData);
              this.isCitizen = (this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva') ? true : false;
              ////console.log(this.userInfo)
              let userId = this.userInfo.userPrincipalName?.split('-');
              if (userId?.length > 0) {
                let loginIds: any = ['12345678'];
                let secretariatCode = userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] : userId[0];
                this.isLoginPresent = loginIds.includes(secretariatCode);
              }
              if(this.newChallanFunction === true){
                if(params.type === 'ots'){
                  this.challanTypeActive = true;
                  this.getOTS_TrnasactionWiseAmount(this.userInfo.userPrincipalName, "");
                }else{
                  this.challanTypeActive = false;
                  this.getTrnasactionWiseAmount(this.userInfo.userPrincipalName, "");
                }
              }else{
                this.getServiceWiseAmount(this.userInfo.userPrincipalName);
              }
            }
      }
      else{
        //console.log('data not available.');
        const queryParams: Params = { type: "gsws"};
        this.router.navigate(['/view-challan'], {
          relativeTo: this.currentRoute,
          queryParams: queryParams,
          queryParamsHandling: '',
        });
      }
    });


    

    this.masterSelectedd = false;
  }

  pageIndexSizeChanged(type: string) {
    if(type != "print" && type != "export") {
      this.masterSelectedd=false;
    }
    if(type === 'pageSize') {
      this.page = 1;
    }
    if(this.newChallanFunction === true){
      if(this.challanServiceType === 'ots'){
        this.challanTypeActive = true;
        this.getOTS_TrnasactionWiseAmount(this.userInfo.userPrincipalName, type);
      }else{
        this.challanTypeActive = false;
        this.getTrnasactionWiseAmount(this.userInfo.userPrincipalName, type);
      }
    }else{
      this.getServiceWiseAmount(this.userInfo.userPrincipalName);
    }
  }

  get f() {
    return this.challanGeneration.controls;
  }

  ngOnInit(): void {
    if (
      /^vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {
      this.isProd = true;
      //for prod gateway
      this.paymentRedirectionURL =
        'https://app-vsws-payment-response-ci-prod.azurewebsites.net/RequestPage.aspx';
        this.merchantID = "APGVWVVSWS";
        this.merchantIDsamllcase = "apgvwvvsws";
        this.buildUPIUrl = "https://pgi.billdesk.com/pgidsk/PGIMerchantPayment?msg=";
        /* this.newChallanFunction = false;
        this.getServiceWiseAmount(this.userInfo.userPrincipalName); */
    } 
    else if (
      /^uat.vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) {
      this.isProd = false;
      //for UAT gateway
      this.paymentRedirectionURL =
        'https://app-gsws-paymentresponse-ci-uat.azurewebsites.net/RequestPage.aspx';
      this.merchantID = "BDSKUATY";
      this.merchantIDsamllcase = "bdskuaty";
      this.buildUPIUrl = "https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=";
     /*  this.newChallanFunction = true;
      this.getTrnasactionWiseAmount(this.userInfo.userPrincipalName); */

    } else {
      this.isProd = false;
      this.paymentRedirectionURL ='https://app-vsws-dev-paymentresponse-ci-01.azurewebsites.net/RequestPage.aspx';
      // this.paymentRedirectionURL =
      //   'https://gsws-paymentresponse.azurewebsites.net/RequestPage.aspx';
        this.merchantID = "BDSKUATY";
        this.merchantIDsamllcase = "bdskuaty";
        this.buildUPIUrl = "https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=";
       /*  this.newChallanFunction = true;
        this.getTrnasactionWiseAmount(this.userInfo.userPrincipalName); */
    }

    //console.log(this.paymentRedirectionURL, '---->paymentRedirectionURL');
    this.challanGeneration.reset();
    // this.challanGeneration.reset({
    //   payment_mode: 'Select'
    // });
  }
  getServiceNameFromConfig() {
    this.commonService.getRequest(this.commonConstants.getServiceList).subscribe({
      next: (responseData: any) => {
         this.serviceList = responseData.result;
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getOTS_TrnasactionWiseAmount(userId: any, type: string) {
    let querystring = "";
    if(type == "print" || type == "export") {
      this.listOfAllTrasaction = [];
      querystring = (this.isCitizen ===  true ? userId : '') +'&Secretariat_Code=' + (this.isCitizen ===  false ?   this.secreteriateCode : '') + '&PageIndex=1&PageSize=' + this.totalPageCount;
    } else {
      this.listOfTrasaction = [];
      this.checklist = [];
      //this.totalAmount = 0;
      //this.totalTransaction = 0;
      querystring = (this.isCitizen ===  true ? userId : '') +'&Secretariat_Code=' + (this.isCitizen ===  false ?   this.secreteriateCode : '') + '&PageIndex=' + this.page + '&PageSize=' + this.pageSize;
    }
    this.commonService
      .getRequest(this.commonConstants.GetOTSTransactionForChallanGeneration + querystring)
      .subscribe({
        next: (responseData: any) => {
          //console.log('service client side', responseData);

          if(type == "print" || type == "export") {
            responseData.result?.pagedList?.forEach((element: any,id:any) => {
          
              let temp: any = {};
              temp.id = id;
              temp.departmant = element.departmant;
              temp.requisitionId = element.requisitionId;
              temp.serviceName = element.serviceName;
              temp.transactionAmount = element.transactionAmount;
              temp.transactionDate = element.transactionDate;
              temp.transactionId = element.transactionId;
              temp.transactionMode = element.transactionMode;
              temp.department_App_No = element.department_App_No;
              temp.isSelected = false;
              temp.beneficiaryName = element.beneficiaryName;
              temp.beneficiaryId = element.beneficiaryId;
              temp.housingId = element.housingId;

              this.listOfAllTrasaction.push(temp);              
            });
            setTimeout(() => {
              if(this.listOfAllTrasaction.length >0){
                if(type == "print") {
                  this.printData('_printData');
                } else if(type == "export") {
                  this.exportexcel();
                }
              }
            }, 1000);            
          } else {    
            this.totalPageCount = responseData.result?.totalResultsCount;
            this.page = responseData.result?.currentPage;         
            responseData.result?.pagedList?.forEach((element: any,id:any) => {
              let temp: any = {};
              temp.id = ((this.page -1) * this.pageSize) + (id + 1);
              temp.departmant = element.departmant;
              temp.requisitionId = element.requisitionId;
              temp.serviceName = element.serviceName;
              temp.transactionAmount = element.transactionAmount;
              temp.transactionDate = element.transactionDate;
              temp.transactionId = element.transactionId;
              temp.transactionMode = element.transactionMode;
              temp.department_App_No = element.department_App_No;
              temp.beneficiaryName = element.beneficiaryName;
              temp.beneficiaryId = element.beneficiaryId;
              temp.housingId = element.housingId;
              temp.isSelected = this.checkedList.find( (x:any) => x.transactionId == element.transactionId)?.isSelected;
              this.checklist.push(temp);
            });
            this.masterSelectedd = this.checklist.every(function(item:any) {
              return item.isSelected == true;
            })
          }

          //console.log(this.checklist,"this.checklist")
          //this.getCheckedItemList();

        },
        error: (error) => {
          //console.log('client side', error);
        },
      });

     // this.getOldPortalServiceWiseAmount(userId);
  }

  getTrnasactionWiseAmount(userId: any, type: string) {
    let querystring = "";
    if(type == "print" || type == "export") {
      this.listOfAllTrasaction = [];
      querystring = (this.isCitizen ===  true ? userId : '') +'&Secretariat_Code=' + (this.isCitizen ===  false ?   this.secreteriateCode : '') + '&PageIndex=1&PageSize=' + this.totalPageCount;
    } else {
      this.listOfTrasaction = [];
      this.checklist = [];
      //this.totalAmount = 0;
      //this.totalTransaction = 0;
      querystring = (this.isCitizen ===  true ? userId : '') +'&Secretariat_Code=' + (this.isCitizen ===  false ?   this.secreteriateCode : '') + '&PageIndex=' + this.page + '&PageSize=' + this.pageSize;
    }
    this.commonService
      .getRequest(this.commonConstants.getTransactionsForChallanGeneration + querystring)
      .subscribe({
        next: (responseData: any) => {
          //console.log('service client side', responseData);

          if(type == "print" || type == "export") {
            responseData.result?.pagedList?.forEach((element: any,id:any) => {
          
              let temp: any = {};
              temp.id = id;
              temp.departmant = element.departmant;
              temp.requisitionId = element.requisitionId;
              temp.serviceName = element.serviceName;
              temp.transactionAmount = element.transactionAmount;
              temp.transactionDate = element.transactionDate;
              temp.transactionId = element.transactionId;
              temp.transactionMode = element.transactionMode;
              temp.department_App_No = element.department_App_No;
              temp.isSelected = false;
          
              this.listOfAllTrasaction.push(temp);              
            });
            setTimeout(() => {
              if(this.listOfAllTrasaction.length >0){
                if(type == "print") {
                  this.printData('_printData');
                } else if(type == "export") {
                  this.exportexcel();
                }
              }
            }, 1000);            
          } else {
            this.totalPageCount = responseData.result?.totalResultsCount;
            this.page = responseData.result?.currentPage;   
            responseData.result?.pagedList?.forEach((element: any,id:any) => {
          
              let temp: any = {};
              temp.id = ((this.page -1) * this.pageSize) + (id + 1);
              temp.departmant = element.departmant;
              temp.requisitionId = element.requisitionId;
              temp.serviceName = element.serviceName;
              temp.transactionAmount = element.transactionAmount;
              temp.transactionDate = element.transactionDate;
              temp.transactionId = element.transactionId;
              temp.transactionMode = element.transactionMode;
              temp.department_App_No = element.department_App_No;
              temp.isSelected = this.checkedList.find( (x:any) => x.transactionId == element.transactionId)?.isSelected;
              this.checklist.push(temp);
            });
            this.masterSelectedd = this.checklist.every(function(item:any) {
              return item.isSelected == true;
            })
            
          //console.log(this.checklist,"this.checklist")
            //this.getCheckedItemList();
          }

        },
        error: (error) => {
          //console.log('client side', error);
        },
      });

     // this.getOldPortalServiceWiseAmount(userId);
  }
  getServiceWiseAmount(userId: any) {
    this.listOfTrasaction = [];
    this.totalAmount = 0;
    this.totalTransaction = 0;
    this.commonService
      .getRequest(this.commonConstants.getServiceWiseAmount + userId)
      .subscribe({
        next: (responseData: any) => {
          ////console.log('service client side', responseData);
          // this.listOfTrasaction = responseData.result;
          responseData.result.forEach((element: any) => {
            element.transactionFrom = "new";
            this.listOfTrasaction.push(element);
          });
          //this.calculateTotalTransaction();
        },
        error: (error) => {
          //console.log('client side', error);
        },
      });

      this.getOldPortalServiceWiseAmount(userId);
  }

  getOldPortalServiceWiseAmount(userId: any) {
    //userId = 'SIRIPURAM-1-DA@apgsws.onmicrosoft.com';
    this.commonService
      .getRequest(
        this.commonConstants.getOldPortalServiceWiseAmount + userId,
        'MAUD'
      )
      .subscribe({
        next: (responseData: any) => {
          // //console.log(responseData, 'getOldPortalServiceWiseAmount');
          if (responseData.result) {
            responseData.result.forEach((element: any) => {
              element.transactionFrom = "old";
              element.requisitionType = element.serviceName;
              this.listOfTrasaction.push(element);
            });
            //this.calculateTotalTransaction();

            //console.log(this.listOfTrasaction, 'listOfTrasaction');
          }
        },
        error: (error) => {
          //console.log('client side', error);
        },
      });
  }

  calculateTotalTransaction() {

    //console.log("here work data")
    this.totalAmount = 0;
    this.totalTransaction = 0;
    if (this.listOfTrasaction.length >= 1) {
      this.listOfTrasaction.map((data) => {
        this.totalAmount += data.amount;
        this.totalTransaction += +data.noOfTransactions;
      });

      //this.totalAmountInWords = this.intToEnglishWordConverter(this.totalAmount);
    }
  }
  transactionPopup(content: any, reqType: any,transactionFrom:any) {
    this.trasactionData = [];

    let inputString =
      'UserId=' +
      this.userInfo.userPrincipalName +
      '&RequisitionType='+reqType;
      
      let apiCall =  this.commonConstants.getTransactionByRequisitionType+ inputString;
      let reqAPIType =  '';
      if(transactionFrom == 'old'){
        apiCall = this.commonConstants.getoldPortalTransactionByRequisitionType + inputString;
        reqAPIType  = 'MAUD';
      }

      this.commonService
       .getRequest(
        apiCall , reqAPIType
      )
      .subscribe({
        next: (responseData: any) => {
          //console.log('service client side', responseData);

         // this.trasactionData = responseData.result;
          if(transactionFrom == 'old'){
              
            /* responseData.result.forEach((element: any) => {
                element.transactionId = element.app_No;
                element.serviceName = element.service_Desc;
                element.transactionDate = element.request_Date ;
                element.transactionAmount = element.transaction_Amount ;
                this.trasactionData.push(element);
              }); */

              responseData.result.forEach((element: any) => {
                let temp: any = {};
                 temp.transactionId = element.app_No;
                 temp.serviceName = element.service_Desc;
                 temp.transactionDate = element.request_Date ;
                 temp.transactionAmount = element.transaction_Amount ;
                 temp.dept_App_No = element.dept_App_No ;
                 temp.transactionFrom = "OLD" ;
                 this.trasactionData.push(temp);
               });

          }else{
            
            //this.trasactionData = responseData.result;
            
            responseData.result.forEach((element: any) => {
              let temp: any = {};
              temp.transactionId = element.transactionId;
              temp.serviceName = element.serviceName;
              temp.transactionDate = element.transactionDate ;
              temp.transactionAmount = element.transactionAmount ;
              temp.dept_App_No = "";
              temp.transactionFrom = "NEW" ;
              this.trasactionData.push(temp);
            });


          }
          this.modalService
            .open(content, {
              size: 'lg',
              ariaLabelledBy: 'modal-basic-title',
              windowClass: 'app-modal-window',
            })
            .result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
        },
        error: (error) => {
          //console.log('client side', error);
        },
      });
  }

  generateChallan(content: any): any {
    this.submitted = true;
    if (!this.challanGeneration.valid) {
      //console.log('herer A');
      return false;
    } else {
      
      
      //this.submitForm();
      
      if (this.totalAmount == 0 ) {
       alert('Total Amount should be greater than 0');
     
      // if (this.totalAmount == 0 || this.transactionCount <= 0) {
      //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      //   modalRef.componentInstance.title = 'Alert';
      //   modalRef.componentInstance.message = 'Please select atleast one transaction and transaction amount should be grater than 0.';       


      } else {
        this.postData = {
          amount: this.totalAmount,
          noOfTransactions: '' + this.totalTransaction,
          paymentGateway: this.f['payment_mode'].value,
          createdBy: this.userInfo.userPrincipalName,
        };

        this.commonService
          .postRequest(this.commonConstants.generateChallan, this.postData)
          .subscribe({
            next: (responseData: any) => {
              // //console.log(responseData, ' respose data');
              this.challanDetails = responseData.result;
              
              
              if( this.challanDetails === 'Challan already exist'){
                
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert';
                modalRef.componentInstance.message = 'Challan already exist for this transactions.';
                this.getServiceWiseAmount(this.userInfo.userPrincipalName);
              }else{
                 
                  // this.updateChallanIdInGswsToVsws(this.userInfo.userPrincipalName,this.challanDetails.challanId);

                  if (this.f['payment_mode'].value === 'BILLDESK_UPI') {
                    this.getCheckSomeData();
                  } else {
                    this.challanDetails.createdBy = this.userInfo.userPrincipalName;

                    this.barcodeValue = JSON.stringify(this.challanDetails);

                    // this.getServiceWiseAmount(this.userInfo.userPrincipalName);
                    // if(this.newChallanFunction === true){
                    //   this.getTrnasactionWiseAmount(this.userInfo.userPrincipalName);
                    // }else{
                    //   this.getServiceWiseAmount(this.userInfo.userPrincipalName);
                    // }

                    this.modalService
                      .open(content, {
                        ariaLabelledBy: 'Generate Challan',
                      })
                      .result.then(
                        (result) => {
                          this.closeResult = `Closed with: ${result}`;
                        },
                        (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(
                            reason
                          )}`;
                        }
                      );
                  }
              }
            },
            error: (error) => {
              //console.log('client side', error);
            },
          });
      }
    }
  }

  updateChallanIdInGswsToVsws(userId:any, challanId:any){
    let postData = {
      userId: userId,
      challanId: challanId
    }
    
    this.commonService.postRequestForWebLand(this.commonConstants.updateChallanIdInGswsToVsws , postData)
    .subscribe({
        next: (responseData: any) => {
           //console.log(responseData, 'updateChallanIdInGswsToVsws');
        },
        error: (error) => {
          //console.log('client side', error);
        },
      });
  }

  getCheckSomeData() {
    this.transactionID = this.challanDetails.challanId;
    this.departmentID = '';
    this.totalAmount = this.challanDetails.challanAmount;

    var minutesToAdd = 10;
    var currentDate = new Date();
    var currentDateWith15 = new Date(currentDate.getTime() + minutesToAdd*60000);
    var currentDateWith15Format = ("0" + currentDateWith15.getDate()).slice(-2) + "-" + ("0"+(currentDateWith15.getMonth()+1)).slice(-2) + "-" +
        currentDateWith15.getFullYear() + " " + ("0" + currentDateWith15.getHours()).slice(-2) + ":" + ("0" + currentDateWith15.getMinutes()).slice(-2) +":00";
    console.log(currentDateWith15Format)

    let pipeLineStr =
    this.merchantID + '|' +
      this.transactionID +
      '|NA|' +
      this.totalAmount +
      '|NA|NA|NA|INR|NA|R|'+this.merchantIDsamllcase+'|NA|'+currentDateWith15Format+'|F|UPI|' +
      this.totalAmount +
      '|NA|NA|NA|NA|NA|' +
      this.paymentRedirectionURL;


    let postData = {
      requisitionId: this.transactionID,
      transactionId: this.transactionID,
      requisitionType: 'BILLDESK_UPI',
      transactionAmount: this.totalAmount,
      transactionMode: 'UPI',
      departmentId: this.departmentID,
      piplineString: pipeLineStr,
      userId: this.userInfo.userPrincipalName,
      serviceName: '',
      customerName: this.userInfo.userPrincipalName,
      challanId: this.transactionID
    };
    const aesKey = this.commonService.getRandomString(32);
    const aesVector = this.commonService.getRandomString(16);
    let encodedData: any = {
      key: this.commonService.RSAEncrypt(aesKey),
      vector: this.commonService.RSAEncrypt(aesVector),
      value: this.commonService.AESEncrypt(JSON.stringify(postData), aesKey, aesVector)
    }
    ////console.log(this.postData, '===>post data');

    this.commonService
      .postRequest(this.commonConstants.getCheckSum, encodedData)
      .subscribe({
        next: (responseData: any) => {
          ////console.log(responseData, 'check sum respose data');
          /* let buildDeskURL =
            'https://uat.billdesk.com/pgidsk/PGIMerchantPayment?msg=' +
            responseData.result;
          //console.log(buildDeskURL, '>> buildDeskURL');
          window.location.href = buildDeskURL; */
          
          this.isActive = true;

          let buildDeskURL =  this.buildUPIUrl + responseData.result.result;
          //console.log(buildDeskURL, '>> buildDeskURL');
          window.location.href = buildDeskURL;
          
        },
        error: (error) => {
          //console.log('client side', error);
        },
      });
  }

  public intToEnglishWordConverter(number: number): any {
    var NS = [
      { value: 10000000, str: 'Crore' },
      { value: 100000, str: 'Lakh' },
      { value: 1000, str: 'Thousand' },
      { value: 100, str: 'Hundred' },
      { value: 90, str: 'Ninety' },
      { value: 80, str: 'Eighty' },
      { value: 70, str: 'Seventy' },
      { value: 60, str: 'Sixty' },
      { value: 50, str: 'Fifty' },
      { value: 40, str: 'Forty' },
      { value: 30, str: 'Thirty' },
      { value: 20, str: 'Twenty' },
      { value: 19, str: 'Nineteen' },
      { value: 18, str: 'Eighteen' },
      { value: 17, str: 'Seventeen' },
      { value: 16, str: 'Sixteen' },
      { value: 15, str: 'Fifteen' },
      { value: 14, str: 'Fourteen' },
      { value: 13, str: 'Thirteen' },
      { value: 12, str: 'Twelve' },
      { value: 11, str: 'Eleven' },
      { value: 10, str: 'Ten' },
      { value: 9, str: 'Nine' },
      { value: 8, str: 'Eight' },
      { value: 7, str: 'Seven' },
      { value: 6, str: 'Six' },
      { value: 5, str: 'Five' },
      { value: 4, str: 'Four' },
      { value: 3, str: 'Three' },
      { value: 2, str: 'Two' },
      { value: 1, str: 'One' },
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // //console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return (
              this.intToEnglishWordConverter(t) +
              ' ' +
              n.str +
              ' ' +
              this.intToEnglishWordConverter(d)
            );
          } else {
            return this.intToEnglishWordConverter(t) + ' ' + n.str;
          }
        }
      }
    }
    return result;
  }

  public printData(printId: any) {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat(printId));
    pwa?.document.close();
  }

  preparePrintFormat(printID: any) {
    const printContent: HTMLElement | null = document.getElementById(printID);
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>ChallanPrint</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';

    if (printID === 'transPoup') {
      htmlData +=
        "<style type='text/css' media='print'> @media print{@page{size: A4 !important;margin: 11mm 17mm 17mm 17mm !important; }  table td, table th{ padding:10px !important; border:0.5px solid #ccc !important; } .ptCls{ margin:50px !important;} table {width:90% !important; margin:0 auto !important;} .btnHide,.pHide{display:none !important;}.headCls{ text-align:center !important; padding :10px 0px !important;}.tableFixHead{height:auto !important;overflow:unset !important;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'><div class='headCls'><h3> Transaction Details<h3><br><div>";
    } else if (printID === '__printingFrame') {
      htmlData +=
        "<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} table{ width: 80% !important; margin : 0 auto !important;} table th ,table td{border:none!important;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";
    } else {
      htmlData +=
        "<style type='text/css' media='print'> @media print{@page{size: A4 !important;margin: 11mm 17mm 17mm 17mm !important; }  table td, table th{ padding:10px !important; border:0.5px solid #ccc !important; } .ptCls{ margin:50px !important;} table {width:90% !important; margin:0 auto !important;} .btnHide,.pHide{display:none !important;}.headCls{ text-align:center !important; padding :10px 0px !important;}.tableFixHead{height:auto !important;overflow:unset !important;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'><div class='headCls'><h3>Service Transaction Details<h3><br><div>";
    }

    htmlData += printdata;
    htmlData += '<hr></body></html>';

    return htmlData;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fileName = 'ChallanDataExcelSheet.xlsx';

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  validateAllChallan(event: any) {
    if(event.target.checked) {
      let TotalCheckedItems = this.checkedList.filter( (x:any) => x.isSelected == true).length;
      let TotalUncheckedItems = this.checklist.filter( (x:any) => x.isSelected == false||  x.isSelected ==undefined || x.isSelected == null).length;

      if(TotalCheckedItems + TotalUncheckedItems > 200) {
        this.commonService.commonErrorPopup('Alert', 'Maximum 200 transactions can be seleted.','md');
        event.preventDefault();
      }
    }
  }
  
  // The master checkbox will check/ uncheck all items
  checkUncheckAll() {
    this.isChallan=true;
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelectedd;
      this.checkedList.forEach((element: any,index: number)=>{
        if(element.transactionId == this.checklist[i].transactionId) this.checkedList.splice(index,1);
     });
    }
    this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.totalTransactionAmnt = 0;
    for (var i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected){
        this.checkedList.push(this.checklist[i]);
      }
    }
    for (var i = 0; i < this.checkedList.length; i++) {
      if(this.checkedList[i].isSelected){
        this.totalTransactionAmnt += this.checkedList[i].transactionAmount;
      }
    }

  }

  validateChallan(event: any) {
    if(event.target.checked) {
      if(this.checkedList.length >= 200) {
        this.commonService.commonErrorPopup('Alert', 'Maximum 200 transactions can be seleted.','md');
        event.preventDefault();
      }
    }
  }

  // Check All Checkbox Checked
  selectChallan(item:any) {
    this.isChallan=true;
    this.masterSelectedd = this.checklist.every(function(item:any) {
        return item.isSelected == true;
      })
    //this.getCheckedItemList();
    if(item.isSelected) {
      this.checkedList.push(item);
    } else {
      this.checkedList.forEach((element: any,index: number)=>{
        if(element.transactionId == item.transactionId) this.checkedList.splice(index,1);
     });
    }
    this.totalTransactionAmnt = 0;
    for (var i = 0; i < this.checkedList.length; i++) {
      if(this.checkedList[i].isSelected){
        this.totalTransactionAmnt += this.checkedList[i].transactionAmount;
      }
    }
  }

  // Get List of Checked Items
  // getCheckedItemList(){
  //   //this.checkedList = [];
  //   this.totalTransactionAmnt = 0;
  //   for (var i = 0; i < this.checklist.length; i++) {
  //     if(this.checklist[i].isSelected){
  //       this.checkedList.push(this.checklist[i]);
  //       this.totalTransactionAmnt += this.checklist[i].transactionAmount;
  //     }
  //   }
  //   //this.checkedList = JSON.stringify(this.checkedList);
  //   //console.log(this.checkedList,"this.checkedList");
  // }


  generateChallanNew(content: any): any {
    this.submitted = true;    
    if (!this.challanGeneration.valid) {
      //console.log('herer A');
      return false;
    } else {
      this.isChallan =false;
      //console.log('checkedList Cnt',this.checkedList.length);
     
      if (this.totalTransactionAmnt == 0 || this.checkedList.length <= 0) {
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = 'Please select atleast one transaction and transaction amount should be greater than 0.';       

      } else {
        this.postData = {
          noOfTransactions: '' +this.checkedList.length,
          paymentGateway: this.f['payment_mode'].value,
          createdBy:this.userInfo.userPrincipalName,
          secretariat_Code: (this.isCitizen ===  false ?   this.secreteriateCode : ''),
          amount : this.totalTransactionAmnt,
          transactions:this.checkedList,
          // transactions: JSON.stringify(this.checkedList),
        };

        this.totalAmountInWords = this.intToEnglishWordConverter(this.totalTransactionAmnt);
        //console.log("post data",this.postData)

        this.commonService
          .postRequestWithoutRetry(this.commonConstants.generateChallan_New, this.postData)
          .subscribe({
            next: (responseData: any) => {
             
              this.challanDetails = responseData.result;
              if(typeof(responseData?.result) === 'string'){
                
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = 'Alert';
                modalRef.componentInstance.message = responseData?.result; //'Challan already exist for this transactions.';
                this.masterSelectedd=false;
               this.isChallan=true;
               this.challanGeneration.controls['payment_mode'].reset();
               this.submitted=false; 
               this.getTrnasactionWiseAmount(this.userInfo.userPrincipalName, "");
              }else{
               
                 if (this.f['payment_mode'].value === 'BILLDESK_UPI') {
                  this.masterSelectedd=false;
                  this.isChallan=true;
                  this.challanGeneration.controls['payment_mode'].reset();
                  this.submitted=false;
                    this.getCheckSomeData();
                  }
               
                  if (this.f['payment_mode'].value === 'ICICI_CHALLAN' || this.f['payment_mode'].value === 'IPB_CHALLAN') {
                    this.challanDetails.createdBy = this.userInfo.userPrincipalName;
                    this.barcodeValue = JSON.stringify(this.challanDetails);
                    this.masterSelectedd=false;
                    this.isChallan=true;
                    this.submitted=false;
                   // this.getTrnasactionWiseAmount(this.userInfo.userPrincipalName);
                    this.modalService
                      .open(content, {
                        ariaLabelledBy: 'Generate Challan',
                      })
                      .result.then(
                        (result) => {
                          this.closeResult = `Closed with: ${result}`;
                        },
                        (reason) => {
                          this.closeResult = `Dismissed ${this.getDismissReason(
                            reason
                          )}`;
                        }
                      );

                  }
                  if (this.f['payment_mode'].value === 'PAYTM_CHALLAN') {
                    this.getPaytmCheckSomeData();

                  }
                  if (this.f['payment_mode'].value === 'IPB_CHALLAN') {
                      this.getPhoneNumberforDAWEDS(); 
                      this.GetPostmanDetails();    
                  }
                  if (this.f['payment_mode'].value === 'Select') {
                    this.challanGeneration.reset();
                    window.location.reload();
                  }
                  this.isChallan =true;
              }
            },
            error: (error) => {
              //console.log('client side', error);
              window.location.reload();
            },
          });
      }
    }
  }
  getPaytmCheckSomeData() {
    
    this.config = {};
    this.postData = {
      userId: this.userInfo.userPrincipalName,
      transactionId: this.challanDetails.challanId,
      transactionAmount: this.challanDetails.challanAmount,
      transactionMode:"UPI",
      challanId:this.challanDetails.challanId,
      };
    
      console.log(this.postData, '===> getPaytmCheckSum checkum post data');

    this.commonService
      .postRequest(this.commonConstants.getPaytmCheckSum, this.postData, true)
      .subscribe({
        next: (responseData: any) => {
          
          this.isActive = true;
          console.log(responseData, 'check sum respose data');
         
        
          if( responseData.result.isSuccess === true){
              this.config = {
                style: {
                    bodyBackgroundColor: "#fafafb",
                    bodyColor: "",
                    themeBackgroundColor: "#dfa231",
                    themeColor: "#ffffff",
                    headerBackgroundColor: "#284055",
                    headerColor: "#ffffff",
                    errorColor: "",
                    successColor: "",
                    card: {
                        padding: "",
                        backgroundColor: ""
                    }
                },
                jsFile: "",
                data: {
                    orderId: responseData.result.transactionId,
                    amount: this.challanDetails.challanAmount,
                    //token: "e334366c509b4294a285a3b42a5659ea1584106015734",
                    token: responseData.result.token,
                    tokenType: "TXN_TOKEN",
                    userDetail: {
                        mobileNumber: '',
                        name: ''
                    }
                },
                merchant: {
                  // mid: "wHNUTH68224456779429",
                    mid: this.isProd ? 'Andhra66557540944393' :"APSTAT88271885509383",
                    name: "Andhra Pradesh GSWS",
                    logo: "",
                    redirect: true
                },
                mapClientMessage: {},
                labels: {},
                payMode: {
                    labels: {},
                    filter: {
                        exclude: []
                    },
                    order: [
                        "NB",
                        "CARD",
                        "LOGIN"
                    ]
                },
                flow: "DEFAULT"
              };
              this.isCheckoutVisible = true;
              console.log(this.config, 'this.config');
              //call paytm methods
              this.initializeCheckout()
        }
         
      },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  initializeCheckout(): void {
    console.log("this.openInPopup",this.openInPopup)
    console.log("this.isCheckoutVisible",this.isCheckoutVisible)
    const config = this.appendHandler(this.config);
    this.checkoutService.init(config, { env: this.isProd ? 'PROD' :'STAGE', openInPopup: this.openInPopup});
    
  }
    /* ------------------paytm pay-------------------- */

    appendHandler(config:any) {
      const newConfig = { ...config };
  
      newConfig.handler = {
        notifyMerchant: this.notifyMerchantHandler
      }
  
      return newConfig;
    }
    notifyMerchantHandler = (eventType:any, data:any): void => {
      console.log('MERCHANT NOTIFY LOG', eventType, data);
      // if(eventType == "APP_CLOSED"){
      //   let InputStr = {
      //     Userid: this.userInfo.userPrincipalName,
      //     RequisitionId : this.challanDetails.challanId,
      //     Department: null,
      //     RequisitionType: this.requisitionType,
      //     TransactionId: null ,
      //     TransactionAmount : this.challanDetails.challanAmount,
      //     TransactionStatus : 'aborted'
      //   }      
      //   this.updateTransactionStatusInRequisitionDetails(InputStr);
      // }
    }
 getPhoneNumberforDAWEDS() {
    this.commonService.getRequest(this.commonConstants.getUserIdDetails).subscribe({
      next: (responseData: any) => {
       this.loggedinUserMobileNo = responseData.result[0]?.phoneNo;
       if(this.loggedinUserMobileNo!=null && this.loggedinUserMobileNo!= ''){
        this.sendSMS();
      }
      },
      error: (error) => {  
        console.log('client side', error);
      }
    });
  }
  sendSMS() {
    let templateId: any;
    let message: any;
        templateId = '1307169537877823471';
        message = `Dear DA/WEDS, you have successfully raised a Challan with No:` + this.challanDetails.challanId + ` for the amount Rs.` + this.challanDetails.challanAmount + `.Postman will collect the amount shortly from you-GOVTAP`; 
    let postData: any = {};
    postData.phoneNo = [this.loggedinUserMobileNo];
    postData.templateId = templateId;
    postData.message = message;
    postData.referenceId = this.challanDetails.challanId;
    postData.userId = this.userInfo.userPrincipalName; 
    postData.department = null;
    postData.referenceType = null;
    postData.isBulkSend = false;
    this.commonService.postRequest(this.commonConstants.sendSMS, postData).subscribe({
      next: (responseData:any) => {         
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  GetPostmanDetails(){
    this.commonService.getRequest(this.commonConstants.GetPostmanDetails +'?SECRETARIAT_CODE='+ (this.isCitizen ===  false ?   this.secreteriateCode : '')).subscribe({
      next: (responseData: any) => { 
        this.mobileNo = responseData.result.mobileNo;
        if(this.mobileNo!=null && this.mobileNo!= ''){
          this.sendPostManSMS();
        }
      },
      error: (error) => {  
        console.log('client side', error);
      }
    });
  }
  sendPostManSMS() {
    let templateId: any;
    let message: any;
        templateId = '1307169537889767524';
        message = `Dear Postman, A challan has been raised by ` + this.secreteriateCode + ` for the amount Rs.` + this.challanDetails.challanAmount + `.Please collect the amount-GOVTAP`;
    let postData: any = {};
    postData.phoneNo = [this.mobileNo];
    postData.templateId = templateId;
    postData.message = message;
    postData.referenceId = this.challanDetails.challanId;
    postData.userId = this.userInfo.userPrincipalName; 
    postData.department = null;
    postData.referenceType = null;
    postData.isBulkSend = false;
    this.commonService.postRequest(this.commonConstants.sendSMS, postData).subscribe({
      next: (responseData:any) => {         
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  generateOTS_ChallanNew(content: any): any {
    this.submitted = true;
    if (!this.challanGeneration.valid) {
      //console.log('herer A');
      return false;
    } else {
      
      //console.log('checkedList Cnt',this.checkedList.length);
     
      if (this.totalTransactionAmnt == 0 || this.checkedList.length <= 0) {
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = 'Please select atleast one transaction and transaction amount should be greater than 0.';       

      } else {
            this.postData = {
              noOfTransactions: '' +this.checkedList.length,
              createdBy: this.userInfo.userPrincipalName,
              secretariat_Code: (this.isCitizen ===  false ?   this.secreteriateCode : ''),
              amount : this.totalTransactionAmnt,
              transactions:this.checkedList,
              paymentGateway: this.f['payment_mode'].value
          };

            this.totalAmountInWords = this.intToEnglishWordConverter(this.totalTransactionAmnt);
            //console.log("post data",this.postData)

            if (this.f['payment_mode'].value === 'ICICI_CHALLAN' || this.f['payment_mode'].value === 'IPB_CHALLAN') {
              this.commonService
                .postRequestWithoutRetry(this.commonConstants.generateOTSChallan, this.postData)
                .subscribe({
                  next: (responseData: any) => {
                  
                    this.challanDetails = responseData?.result?.result;
                    if(!responseData?.result?.isSuccess && responseData?.result?.error === 'Challan already exist'){
                      
                      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                      modalRef.componentInstance.title = 'Alert';
                      modalRef.componentInstance.message = 'Challan already exist for this transactions.';
                      this.getOTS_TrnasactionWiseAmount(this.userInfo.userPrincipalName, "");
                    }else if (responseData?.result?.isSuccess) {
                        
                          this.challanDetails.createdBy = this.userInfo.userPrincipalName;
                          this.barcodeValue = JSON.stringify(this.challanDetails);

                          //console.log("this.challanDetails",this.challanDetails)
                        
                          this.modalService
                            .open(content, {
                              ariaLabelledBy: 'Generate Challan',
                            })
                            .result.then(
                              (result) => {
                                this.closeResult = `Closed with: ${result}`;
                              },
                              (reason) => {
                                this.closeResult = `Dismissed ${this.getDismissReason(
                                  reason
                                )}`;
                              }
                            );
                        
                    } else {
                      this.commonService.commonErrorPopup('Alert', responseData?.result?.error,'md')
                    }
                  },
                  error: (error) => {
                    window.location.reload();
                    //console.log('client side', error);
                  },
                });
            }
            
            if (this.f['payment_mode'].value === 'Select') {
              this.challanGeneration.reset();
              window.location.reload();
            }
      }
    }
  }

  showSelectedChallans() {
    this.modalService.open(this.selectedChallanModal, { size: 'xl', centered: true, backdrop: 'static'});
  }

  getSortedSeletedChallans() {
    return this.checkedList.sort((a:any, b:any) => a.id - b.id);
  }
 
  redirectChallanHistory(typeChanllan:any){
    const queryParams: Params = { type: typeChanllan};
      this.router.navigate(['/challan-history'], {
        relativeTo: this.currentRoute,
        queryParams: queryParams,
        queryParamsHandling: '',
      });
  }

  closePopup(){
    window.location.reload();
  }
}
