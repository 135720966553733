<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Revenue Division : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls"  formControlName="revenue" (change)="getMandals()">
        <option value = "All">All</option>
        <option *ngFor="let division of divisions" [ngValue]="division">{{division.revenueDivisionName}}</option>
        </select>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
        <option value = "All">All</option>
        <option value = "R">Rural</option>
        <option value = "U">Urban</option>
        </select>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Mandal/Municipality:  <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
      </select>
    </div>
    </div>
  
    <div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="village" (change)="onVillageChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
        </select>
    </div>


    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Department: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="department" (change)="onDeptChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.department.errors || f.department.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let dept of departments" [ngValue]="dept">{{dept}}</option>
        </select>
    </div>

<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Service: <span class="mandatoryCls">*</span></label>
    <select class="selectCls" formControlName="service" (change) = "onServiceChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.service.errors || f.service.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let service of services" [ngValue]="service">{{service}}</option>
    </select>
</div>

  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Bank: <span class="mandatoryCls">*</span></label>
    <select class="selectCls" formControlName="bank">
      <option value="All">All</option>
      <option value="BILLDESK">Billdesk</option>
      <option value="ICICI">ICICI</option>
      <option value="UPI">UPI</option>
      </select>
</div>
</div>
 
<div class="row mb-3">
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
    <div class="input-group">
      <input class="calendarCls form-control" placeholder="dd-mm-yyyy" appBlockCopyPaste
             name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
             [maxDate]="maxDate" required formControlName="fromDate"
             [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
      </div>
    </div>
    <div *ngIf="submitted && f.fromDate.errors" class="selectError">
        <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
    </div>
    </div>

 
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd-mm-yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
            <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
        </div>
        </div>
      </div>
      
    

    <div class="tabClass" >
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light ">
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getPayementReport()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol" *ngIf="format !== 'SinceInception Secretariat Wise'">AP SEVA Payment Dashboard from {{f.fromDate.value}} to  {{f.toDate.value}}</th>
                <th [attr.colspan]="noOfCol"  *ngIf="format === 'SinceInception Secretariat Wise'">AP SEVA Payment Dashboard from 06-01-2022 to  {{f.toDate.value}}</th>
            </tr>
            <tr>
                <th rowspan="4">S.No</th>
                <th rowspan="4" *ngIf="format === 'District Wise'|| format === 'Revenue Division Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'|| format === 'SinceInception Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="4" *ngIf="format === 'Revenue Division Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DIV_NAME'">Revenue Division Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="4" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'SinceInception Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="4" *ngIf="format === 'Secretariat Wise' || format === 'SinceInception Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="4" *ngIf="format === 'Department Wise' || format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'department'">Department Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="4" *ngIf="format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'servicename'">Service Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="4" *ngIf="format === 'Bank Wise'" scope="col" [mdbTableSort]="items" [sortBy]= "'bank'">Bank(UPI/ICICI/Billdesk)</th>
                <th colspan="2" *ngIf="format!== 'Bank Wise'" >Total</th>
                <th rowspan="4" *ngIf="format!== 'Bank Wise'" scope="col" [mdbTableSort]="items" [sortBy]="'zerO_AMOUNT_REQUESTS'" >Zero Amount Requests</th>
                <th [attr.colspan]=" format !== 'Bank Wise'? 8 : 2 " >Pending</th>
                <th [attr.colspan]=" format !== 'Bank Wise'? 8 : 2 "scope="col" [mdbTableSort]="items" [sortBy]="''">Paid</th>
             </tr>
             <tr>
                <th rowspan="3" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_REQUESTS'">No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  rowspan="3" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  rowspan="3" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_REQUESTS'">Total No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  rowspan="3" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_AMOUNT'">Total Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" colspan="2" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Challan Not Generated</th>
                <th colspan="4" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Challan Generated,but not Deposited</th>
                <th rowspan="3" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_REQUESTS'">Total No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="3" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_AMOUNT'">Total Amount<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format!== 'Bank Wise'" colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">UPI</th>
                <th rowspan="2" *ngIf="format!== 'Bank Wise'" colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">ICICI</th>
                <th rowspan="2" *ngIf="format!== 'Bank Wise'" colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Billdesk</th>
             </tr>
             <tr>
                <th colspan="2" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Billdesk</th>
               <th colspan="2" *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">ICICI</th>
               </tr>
            <tr>
               <th *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_NO_CHALLAN_REQUESTS'">No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_NO_CHALLAN_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_BILLDESK_REQUESTS'">No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_BILLDESK_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format!== 'Bank Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_ICICI_REQUESTS'">No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_ICICI_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_UPI_REQUESTS'">No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_UPI_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_ICICI_REQUESTS'">No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format!== 'Bank Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_ICICI_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_BILLDESK_REQUESTS'">No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paiD_BILLDESK_AMOUNT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
            </thead>
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'SinceInception Secretariat Wise' || format === 'Revenue Division Wise'">{{item.districT_NAME}}</td>
                    <td *ngIf="format === 'Revenue Division Wise'">{{item.revenuE_DIV_NAME}}</td>
                   <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">{{item.mandaL_NAME}}</td>
                   <td *ngIf="format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                   <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{item.department}}</td>
                   <td *ngIf="format === 'Service Wise'">{{item.servicename}}</td>
                   <td *ngIf="format === 'Bank Wise'">{{item.bank}}</td>
                   <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.totaL_REQUESTS}}</td>
                   <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.totaL_AMOUNT}}</td>
                   <td *ngIf="format!== 'Bank Wise'">{{item.zerO_AMOUNT_REQUESTS}}</td>
                   <td class="text-right">{{item.pendinG_REQUESTS}}</td>
                   <td class="text-right">{{item.pendinG_AMOUNT}}</td>
                   <!-- <td class="text-right">{{item.paiD_REQUESTS}}</td>
                   <td class="text-right">{{item.paiD_AMOUNT}}</td> -->
                    
                    <td class="openWithin text-right" *ngIf = "format !='Bank Wise'"><span *ngIf="format !== 'SinceInception Secretariat Wise'"><a (click) ="getPendingReport(item, beyondSLAModal,'noGen')"class="linkCls" ><b>{{item.pendinG_NO_CHALLAN_REQUESTS}}</b></a></span>
                        <span style="color:#6c757d;" *ngIf="format === 'SinceInception Secretariat Wise'"><b>{{item.pendinG_NO_CHALLAN_REQUESTS}}</b></span></td>
                    <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.pendinG_NO_CHALLAN_AMOUNT}}</td>
                    <td class="openWithin text-right" *ngIf = "format !='Bank Wise'"><span *ngIf="format !== 'SinceInception Secretariat Wise'"><a (click) ="getPendingReport(item, beyondSLAModal,'genBill')"class="linkCls" ><b>{{item.pendinG_BILLDESK_REQUESTS}}</b></a></span>
                        <span style="color:#6c757d;" *ngIf="format === 'SinceInception Secretariat Wise'"><b>{{item.pendinG_BILLDESK_REQUESTS}}</b></span></td>
                    <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.pendinG_BILLDESK_AMOUNT}}</td>
                    <td class="openWithin text-right" *ngIf = "format !='Bank Wise'"><span *ngIf="format !== 'SinceInception Secretariat Wise'"><a (click) ="getPendingReport(item, beyondSLAModal,'genIcici')"class="linkCls" ><b>{{item.pendinG_ICICI_REQUESTS}}</b></a></span>
                        <span style="color:#6c757d;" *ngIf="format === 'SinceInception Secretariat Wise'"><b>{{item.pendinG_ICICI_REQUESTS}}</b></span></td>
                    <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.pendinG_ICICI_AMOUNT}}</td>
                    <td class="text-right">{{item.paiD_REQUESTS}}</td>
                    <td class="text-right">{{item.paiD_AMOUNT}}</td>
                    <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.paiD_UPI_REQUESTS}}</td>
                    <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.paiD_UPI_AMOUNT}}</td>
                    <td class="text-right" *ngIf = "format != 'Bank Wise'">{{item.paiD_ICICI_REQUESTS}}</td>
                    <td class="text-right"*ngIf = "format !='Bank Wise'">{{item.paiD_ICICI_AMOUNT}}</td>
                    <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.paiD_BILLDESK_REQUESTS}}</td>
                    <td class="text-right" *ngIf = "format !='Bank Wise'">{{item.paiD_BILLDESK_AMOUNT}}</td>
                </tr>
                <tr *ngIf="totalItems && totalItems.length > 0">
                    <td></td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise' || format === 'Revenue Division Wise'">{{totalItems[0].districT_NAME}}</td>
                    <td *ngIf="format === 'Revenue Division Wise'">{{totalItems[0].revenuE_DIV_NAME}}</td>
                   <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                   <td *ngIf="format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                   <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{totalItems[0].department}}</td>
                   <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>
                   
                   <td *ngIf="format === 'Bank Wise'">{{totalItems[0].bank}}</td>
                   
                   <td class="text-right">{{totalItems[0].totaL_REQUESTS}}</td>
                   <td class="text-right">{{totalItems[0].totaL_AMOUNT}}</td>
                   <td *ngIf="format!== 'Bank Wise'">{{totalItems[0].zerO_AMOUNT_REQUESTS}}</td>
                   <td class="text-right">{{totalItems[0].pendinG_REQUESTS}}</td>
                   <td class="text-right">{{totalItems[0].pendinG_AMOUNT}}</td>
                   <!-- <td class="text-right">{{totalItems[0].paiD_REQUESTS}}</td>
                   <td class="text-right">{{totalItems[0].paiD_AMOUNT}}</td> -->
                    
                    <td class="text-right">{{totalItems[0].pendinG_NO_CHALLAN_REQUESTS}}</td>
                    <td class="text-right">{{totalItems[0].pendinG_NO_CHALLAN_AMOUNT}}</td>
                    <td class="text-right">{{totalItems[0].pendinG_BILLDESK_REQUESTS}}</td>
                    <td class="text-right">{{totalItems[0].pendinG_BILLDESK_AMOUNT}}</td>
                    <td class="text-right">{{totalItems[0].pendinG_ICICI_REQUESTS}}</td>
                    <td class="text-right">{{totalItems[0].pendinG_ICICI_AMOUNT}}</td>
                    <td class="text-right">{{totalItems[0].paiD_REQUESTS}}</td>
                    <td class="text-right">{{totalItems[0].paiD_AMOUNT}}</td>
                    <td class="text-right">{{totalItems[0].paiD_UPI_REQUESTS}}</td>
                    <td class="text-right">{{totalItems[0].paiD_UPI_AMOUNT}}</td>
                    <td class="text-right">{{totalItems[0].paiD_ICICI_REQUESTS}}</td>
                    <td class="text-right">{{totalItems[0].paiD_ICICI_AMOUNT}}</td>
                    <td class="text-right">{{totalItems[0].paiD_BILLDESK_REQUESTS}}</td>
                    <td class="text-right">{{totalItems[0].paiD_BILLDESK_AMOUNT}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                 <!-- <tr>
                    <td [attr.colspan]="format === 'State Wise' ? 13 : 14" class="text-center">No records found.</td>
                </tr>  -->
                 <!-- <tr>
                    <td [attr.colspan]=" format !== 'Bank Wise'? 22 : 6 " class="text-center">No records found.</td>
                </tr>  -->
                
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                    <!-- <td colspan="22" class="text-center">No records found.</td> -->
                </tr>
            </tbody>
        </table>
        <div class="mt-4 btnHide">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    </div>
    </div>
    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
       <table class="tableCls" >
           <thead>
               <tr>
               <!-- <th [attr.colspan]="format === 'State Wise' ? 11 : 12"><span>{{format}} SLA Dashboard </span><span *ngIf="f.district.value !== 'All'"> 
                <span>&nbsp; for &nbsp;</span><span> {{f.district.value?.name}} </span></span> <span *ngIf="f.mandal.value !== 'All'"> ,
                         {{f.mandal.value?.name}} </span> <span *ngIf="f.village.value !== 'All'"> ,
                         {{f.village.value?.name}} </span><span> &nbsp;from&nbsp; </span><span> {{f.fromDate.value}} to {{f.toDate.value}}</span></th> -->
                         <th [attr.colspan]="noOfCol" *ngIf="format !== 'SinceInception Secretariat Wise'">AP SEVA Payment Dashboard from {{f.fromDate.value}} to  {{f.toDate.value}}</th>
                         <th [attr.colspan]="noOfCol"  *ngIf="format === 'SinceInception Secretariat Wise'">AP SEVA Payment Dashboard from 06-01-2022 to  {{f.toDate.value}}</th>
                </tr>
               <tr>
                <th [attr.rowspan]=" format !== 'Bank Wise'? 4 :  2">S.No</th>
                <th width="100px" rowspan="4" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'|| format === 'Revenue Division Wise'">District Name</th>
                <th width="100px" rowspan="4" *ngIf="format === 'Revenue Division Wise'">Revenue Division Name</th>
                <th width="100px" rowspan="4" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">Mandal Name</th>
                <th width="100px" rowspan="4" *ngIf="format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">Secretariat Name </th>
                <th width="100px" rowspan="4" *ngIf="format === 'Department Wise' || format === 'Service Wise'">Department Name</th>
                <th width="100px" rowspan="4" *ngIf="format === 'Service Wise'">Service Name</th>
                <th width="100px" rowspan="2" *ngIf="format === 'Bank Wise'">Bank(UPI/ICICI/Billdesk)</th>
                <th width="100px" colspan="2" *ngIf="format!== 'Bank Wise'" >Total</th>
                <th  width="100px" rowspan="4" *ngIf="format!== 'Bank Wise'">Zero Amount Requests</th>
                <th width="100px" [attr.colspan]=" format !== 'Bank Wise'? 8 : 2 " >Pending</th>
                <th width="100px" [attr.colspan]=" format !== 'Bank Wise'? 8 : 2 " >Paid</th>
               </tr>
               <tr *ngIf="format!== 'Bank Wise'">
                <th width="100px" rowspan="3" >No of Requests</th>
                <th width="100px" rowspan="3" >Amount</th>
                <th width="100px" rowspan="3" >Total No of Requests</th>
                <th width="100px" rowspan="3" >Total Amount</th>
                <th width="100px" rowspan="2" colspan="2" >Challan Not Generated</th>
                <th width="100px" colspan="4" >Challan Generated,but not Deposited</th>
                <th width="100px" rowspan="3" >Total No of Requests</th>
                <th width="100px" rowspan="3" >Total Amount</th>
                <th width="100px" rowspan="2"  colspan="2">UPI</th>
                <th width="100px" rowspan="2"  colspan="2">ICICI</th>
                <th width="100px" rowspan="2"  colspan="2">Billdesk</th>
             </tr>
             <tr *ngIf="format!== 'Bank Wise'">
                <th width="100px"colspan="2" >Billdesk</th>
                <th width="100px"colspan="2" >ICICI</th>
               </tr>
            <tr>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">No of Requests</th>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">Amount</th>
                <th width="100px">No of Requests</th>
                <th  width="100px">Amount</th>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">No of Requests</th>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">Amount</th>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">No of Requests</th>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">Amount</th>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">No of Requests</th>
                <th  width="100px" *ngIf="format!== 'Bank Wise'">Amount</th>
                <th  width="100px">No of Requests</th>
                <th  width="100px">Amount</th>
             </tr>
           </thead>
    
           <tbody *ngIf="items && items.length > 0">
               <tr *ngFor="let item of items;let i = index;">
                   <td width="100px">{{i + 1}}</td>
                   <td width="100px" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'|| format === 'Revenue Division Wise'">{{item.districT_NAME}}</td>
                   <td  width="100px" *ngIf="format === 'Revenue Division Wise'">{{item.revenuE_DIV_NAME}}</td>
                   <td width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">{{item.mandaL_NAME}}</td>
                   <td width="100px" *ngIf="format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                   <td width="100px" *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{item.department}}</td>
                   <td width="100px" *ngIf="format === 'Service Wise'">{{item.servicename}}</td>
                  
                   <td width="100px" *ngIf="format === 'Bank Wise'">{{item.bank}}</td>
                    
                    <td *ngIf="format!== 'Bank Wise'">{{item.totaL_REQUESTS}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.totaL_AMOUNT}}</td>
                    <td width="100px" *ngIf="format!== 'Bank Wise'">{{item.zerO_AMOUNT_REQUESTS}}</td>
                    <td [attr.colspan]=" format !== 'Bank Wise'? 1 :1">{{item.pendinG_REQUESTS}}</td>
                    <td [attr.colspan]=" format !== 'Bank Wise'? 1 :1">{{item.pendinG_AMOUNT}}</td>
                    <!-- <td >{{item.paiD_REQUESTS}}</td>
                    <td >{{item.paiD_AMOUNT}}</td> -->
                    
                    <td *ngIf="format!== 'Bank Wise'">{{item.pendinG_NO_CHALLAN_REQUESTS}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.pendinG_NO_CHALLAN_AMOUNT}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.pendinG_BILLDESK_REQUESTS}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.pendinG_BILLDESK_AMOUNT}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.pendinG_ICICI_REQUESTS}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.pendinG_ICICI_AMOUNT}}</td>
                    <td [attr.colspan]=" format !== 'Bank Wise'? 1 : 1 ">{{item.paiD_REQUESTS}}</td>
                    <td [attr.colspan]=" format !== 'Bank Wise'? 1 : 1 ">{{item.paiD_AMOUNT}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.paiD_UPI_REQUESTS}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.paiD_UPI_AMOUNT}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.paiD_ICICI_REQUESTS}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.paiD_ICICI_AMOUNT}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.paiD_BILLDESK_REQUESTS}}</td>
                    <td *ngIf="format!== 'Bank Wise'">{{item.paiD_BILLDESK_AMOUNT}}</td>
               </tr>
               <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'|| format === 'Revenue Division Wise'">{{totalItems[0].districT_NAME}}</td>
                <td *ngIf="format === 'Revenue Division Wise'">{{totalItems[0].revenuE_DIV_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'SinceInception Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'  || format === 'SinceInception Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
               <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{totalItems[0].department}}</td>
               <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>
              
               <td *ngIf="format === 'Bank Wise'">{{totalItems[0].bank}}</td>
                
                <td >{{totalItems[0].totaL_REQUESTS}}</td>
                <td >{{totalItems[0].totaL_AMOUNT}}</td>
                <td *ngIf="format!== 'Bank Wise'">{{totalItems[0].zerO_AMOUNT_REQUESTS}}</td>
                <td >{{totalItems[0].pendinG_REQUESTS}}</td>
                <td >{{totalItems[0].pendinG_AMOUNT}}</td>
                <!-- <td >{{totalItems[0].paiD_REQUESTS}}</td>
                <td >{{totalItems[0].paiD_AMOUNT}}</td> -->
                <!-- NEW REQUIREMENT ADDED FOR SUB HEADINGS-->
                <td >{{totalItems[0].pendinG_NO_CHALLAN_REQUESTS}}</td>
                <td >{{totalItems[0].pendinG_NO_CHALLAN_AMOUNT}}</td>
                <td >{{totalItems[0].pendinG_BILLDESK_REQUESTS}}</td>
                <td >{{totalItems[0].pendinG_BILLDESK_AMOUNT}}</td>
                <td >{{totalItems[0].pendinG_ICICI_REQUESTS}}</td>
                <td >{{totalItems[0].pendinG_ICICI_AMOUNT}}</td>
                <td >{{totalItems[0].paiD_REQUESTS}}</td>
                <td >{{totalItems[0].paiD_AMOUNT}}</td>
                <td >{{totalItems[0].paiD_UPI_REQUESTS}}</td>
                <td >{{totalItems[0].paiD_UPI_AMOUNT}}</td>
                <td >{{totalItems[0].paiD_ICICI_REQUESTS}}</td>
                <td >{{totalItems[0].paiD_ICICI_AMOUNT}}</td>
                <td >{{totalItems[0].paiD_BILLDESK_REQUESTS}}</td>
                <td>{{totalItems[0].paiD_BILLDESK_AMOUNT}}</td>
            </tr>
           </tbody>
           <tbody *ngIf="items && items.length === 0">
               <!-- <tr>
                   <td [attr.colspan]="format === 'State Wise' ? 13 : 14" class="text-center">No records found.</td>
               </tr> -->
               <!-- <tr>
                    <td [attr.colspan]=" format !== 'Bank Wise'? 22 : 6 " class="text-center">No records found.</td>
                </tr> -->

               <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                <!-- <td colspan="22" class="text-center">No records found.</td> -->
            </tr>
           </tbody>
       </table>
    </div>
    <div class="table-responsive tableFixHead" #BEYONDSLATABLE class="hideTable">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th>S.No</th>
                   <th>District Name</th>
                   <th>Revenue Division Name</th>
                   <th>Revenue Mandal Name</th>
                   <th>Secretariat Code</th>
                   <th>Mandal Name</th>
                   <th>Secretariat Name</th>
                   <th>Application No</th>
                   <th>Department</th>
                   <th>Service Name</th>
                   <!-- <th>Raised Date</th> -->
                   <th>Amount</th>
                   <th>Transaction Date</th>
                   <th>Challan Status</th>
                </tr>
            </thead>
    
            <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
                <tr *ngFor="let item of beyondSLAItems;let i = index;">
                    <td>{{i + 1}}</td>
                    <td>{{item.districT_NAME}}</td>
                    <td>{{item.revenuE_DIV_NAME}}</td>
                    <td>{{item.revenuE_MANDAL_NAME}}</td>
                    <td>{{item.secretariaT_CODE}}</td>
                   <td>{{item.mandaL_NAME}}</td>
                   <td>{{item.secretariaT_NAME}}</td>
                   <td>{{item.application_No}}</td>
                   <td>{{item.department}}</td>
                   <!-- <td>{{item.apP_DATE | date: 'dd-MM-yyyy'}}</td> -->
                   <td>{{item.servicename}}</td>
                   <td>{{item.amount}}</td>
                   <td>{{item.transaction_Date | date: 'dd-MM-yyyy'}}</td>
                   <td>{{item.challan_Status}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
                <!-- <tr>
                    <td [attr.colspan]=" format !== 'Bank Wise'? 22 : 6 " class="text-center">No records found.</td>
                </tr> -->
                <tr>
                    <td colspan="14" class="text-center">No records found. </td>
                </tr>
            </tbody>
        </table>
    </div>
    <ng-template #beyondSLAModal let-modal>
        <div class="modal-header">
         <h5 class="modal-title" id="editProfileLabel">Pending Transactions</h5>
         <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
          <span >&times;</span>
         </button>
        </div>
        
        <div class="modal-body">
            <div class="table-responsive tableFixHead">
                <table class="tableCls" >
                    <thead> 
                        <tr>
                            <th>S.No</th>
                            <th *ngFor="let head of headerCol; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="beyondSLAItems" [sortBy]="headerCol[i].colId">{{ head.colName }}
                                <mdb-icon fas icon="sort"></mdb-icon>
                              </th>
                              
                        </tr>
                    </thead>
            
                    <tbody *ngIf="beyondSLAItems && beyondSLAItems.length > 0">
                        <tr *ngFor="let item of beyondSLAItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                            <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                            <td>{{item.districT_NAME}}</td>
                            <td>{{item.revenuE_DIV_NAME}}</td>
                            <td>{{item.revenuE_MANDAL_NAME}}</td>
                            <td>{{item.secretariaT_CODE}}</td>
                            <td>{{item.mandaL_NAME}}</td>
                            <td>{{item.secretariaT_NAME}}</td>
                            <td>{{item.application_No}}</td>
                            <td>{{item.department}}</td>
                            <!-- <td>{{item.apP_DATE | date: 'dd-MM-yyyy'}}</td> -->
                            <td>{{item.servicename}}</td>
                            <td>{{item.amount}}</td>
                            <td>{{item.transaction_Date | date: 'dd-MM-yyyy'}}</td>
                            <td>{{item.challan_Status}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="beyondSLAItems && beyondSLAItems.length === 0">
                        <!-- <tr>
                            <td [attr.colspan]=" format !== 'Bank Wise'? 22 : 6 " class="text-center">No records found.</td>
                        </tr> -->
                        <tr>
                            <td colspan="14" class="text-center">No records found.</td>
                        </tr>
                    </tbody>
                </table>
                <div class="mt-4 btnHide">
                    <div class="float-left" *ngIf="beyondSLAItems.length > 0">
                        <label>Requests per page: </label>
                        <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                            <option  value="10">10</option>
                            <option  value="20">20</option>
                            <option  value="30">30</option>
                            <option  value="40">40</option>
                            <option  value="50">50</option>
                        </select> 
                    </div>
                    <ngb-pagination class="d-flex justify-content-end" *ngIf="beyondSLAItems.length > 0" [collectionSize]="beyondSLAItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                        [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                    </ngb-pagination>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
          <button  name="close" class="greenBtnCls" *ngIf="beyondSLAItems && beyondSLAItems.length > 0" (click)="downloadExcelForBeyondSLA()">Download Excel</button>
        </div>
       </ng-template>
 
         