import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
@Component({
  selector: 'app-policies-popup',
  templateUrl: './policies-popup.component.html',
  styleUrls: ['./policies-popup.component.css']
})
export class PoliciesPopupComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() popup: any;
  @Input() userName: any;
  isAccepted: Boolean = false;
  submitted: Boolean = false;
  showCitizen:Boolean=false;
  showLand:Boolean=false;
  showProperty:Boolean=false;
  showMarriageStatus:Boolean=false;
  showPhysical:Boolean=false;
  showAge:Boolean=false;
  showVehicle:Boolean=false;
  showPaybills:Boolean=false;
  showRevenue:Boolean=false;
  showPensions:Boolean=false;
  isHardwareStatusSubmitted: Boolean = false;
  isUserExist: Boolean = false;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  casteCategory:any=[];
  userInfo: any = [];
  pendingAmount : any;
  secretariatCode : any;
  information: UntypedFormGroup;
  HardwareStatus: UntypedFormGroup;
  isSubmitted: Boolean = false;
  districts: any[] = [];
  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig,private formBuilder: UntypedFormBuilder,private commonService: CommonService) {
    config.backdrop = 'static';
    config.keyboard = false;
    
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    let userData = this.commonService.getLocalStorage('userInfo');
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      let userId = this.userInfo.userPrincipalName?.split('-');
      this.secretariatCode = this.commonService.getLocalStorage('locationCode');
    }
	
    this.casteCategory=[
      {"name": "SC" },   { "name": "ST"},      
     
      {
        "name": "BC"
      },
      {
        "name": "OC"
      },
      {
        "name": "Minorities"
      }
    ]
    this.information = this.formBuilder.group({
      
      name: ['', [Validators.required,Validators.pattern(this.commonConstants.namePattern)]],
      age: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      caste: ['', [Validators.required]],
      address: ['', [Validators.required]],
      district: ['', [Validators.required]],
      pincode:['', [Validators.required]]
      
    });

    this.HardwareStatus = this.formBuilder.group({ 
      googleChrome: ['', [Validators.required]],
      FPSDrivers: ['', [Validators.required]],
      pswasmapped: ['', [Validators.required]],
      vrowardmapped: ['', [Validators.required]],
    });

    this.districts =[
      {  "name": "ALLURI SITHARAMA RAJU"},{  "name": "ANAKAPALLI"},{  "name": "ANANTHAPUR"},{  "name": "ANNAMAYYA"},
      {  "name": "BAPATLA"},{  "name": "CHITTOOR"},{  "name": "Dr.B.R.Ambedkar Konaseema"},{  "name": "EAST GODAVARI"},
      {  "name": "ELURU"},{  "name": "GUNTUR"},{  "name": "KAKINADA"},{  "name": "KRISHNA"},
      {  "name": "KURNOOL"},{  "name": "NANDYAL"},{  "name": "NTR"},{  "name": "PALNADU"},
      {  "name": "PARVATHIPURAM MANYAM"},{  "name": "PRAKASAM"},{  "name": "SPSR NELLORE"},{  "name": "SRI SATHYA SAI"},
      {  "name": "SRIKAKULAM"},{  "name": "TIRUPATI"},{  "name": "VISAKHAPATNAM"},{  "name": "VIZIANAGARAM"},
      {  "name": "WEST GODAVARI"},{  "name": "YSR KADAPA"}
    ]
  }

  ngOnInit(): void {
    this.GetPendingChallanAmountByDA();
  }
  get f() {
    return this.information.controls;
  }
  get y() {
    return this.HardwareStatus.controls;
  }  
  GetPendingChallanAmountByDA(){
    this.commonService.getRequest(this.commonConstants.GetPendingChallanAmountByDA).subscribe({
      next: (responseData: any) => { 
        this.pendingAmount = responseData.result.pendingAmount;
        console.log(responseData.result.pendingAmount);      
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  SaveHardwareStatusOfHouseSite(){
    if(this.HardwareStatus.valid){
      this.isHardwareStatusSubmitted = false;
    let HardwareStatusOfHouseSite = {
      userId: this.userInfo.userPrincipalName,
      secretariatCode : this.secretariatCode,
      googleOrMicrosoftUpdated : this.y.googleChrome?.value,
      fpsDriversInstalled : this.y.FPSDrivers?.value,
      panchayatOrWardSecretaryMapped : this.y.pswasmapped?.value,
      vroOrWardSecretaryMapped : this.y.vrowardmapped?.value
    };
    this.commonService.postRequest(this.commonConstants.SaveHardwareStatusOfHouseSite, HardwareStatusOfHouseSite).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'responseData');
        if(responseData.result.isSuccess){
          this.isUserExist = responseData.result.isUserExist;
          this.commonService.commonErrorPopup('Alert', 'Details are saved successfully', 'md');
          this.activeModal.close();
      }
      else{
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
      error: (error) => {
        console.log('client side', error);
      }
    });      
  }
  else{
    this.isHardwareStatusSubmitted = true;
  }
}

  saveHouseHold(){
    this.SaveHardwareStatusOfHouseSite();
  }
  onAccept(): any {
    this.submitted = true
    if(!this.isAccepted)
        return false;
    this.activeModal.close();
  }
  onChangeChecklist(value:any,qn:any){
    if(this.information.valid){
      this.isSubmitted = false;
    if(value ==='yes' && qn === 'Citizen'){
       this.showCitizen=true;
    }else if(value ==='no' && qn === 'Citizen'){
      this.showCitizen=false;
    }

    if(value ==='yes' && qn === 'Land'){
      this.showLand = true;
    }else if(value ==='no' && qn === 'Land'){
      this.showLand=false;
    }

    if(value ==='yes' && qn === 'Property'){
      this.showProperty = true;
    }else if(value ==='no' && qn === 'Property'){
      this.showProperty=false;
    }

    if(value ==='yes' && qn === 'Marriage'){
      this.showMarriageStatus = true;
    }else if(value ==='no' && qn === 'Marriage'){
      this.showMarriageStatus=false;
    }

    if(value ==='yes' && qn === 'Physical'){
      this.showPhysical = true;
    }else if(value ==='no' && qn === 'Physical'){
      this.showPhysical=false;
    }

    if(value ==='yes' && qn === 'Age'){
      this.showAge = true;
    }else if(value ==='no' && qn === 'Age'){
      this.showAge=false;
    }
    if(value ==='yes' && qn === 'vehicle'){
      this.showVehicle = true;
    }else if(value ==='no' && qn === 'vehicle'){
      this.showVehicle=false;
    }
    if(value ==='yes' && qn === 'payBill'){
      this.showPaybills = true;
    }else if(value ==='no' && qn === 'payBill'){
      this.showPaybills=false;
    }
    if(value ==='yes' && qn === 'revenue'){
      this.showRevenue = true;
    }else if(value ==='no' && qn === 'revenue'){
      this.showRevenue=false;
    }
    if(value ==='yes' && qn === 'pension'){
      this.showPensions = true;
    }else if(value ==='no' && qn === 'pension'){
      this.showPensions=false;
    }}
    else{
      this.isSubmitted = true;
      alert('Please enter Mandatory fields');
     
    }
  }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => { 
        this.districts = this.commonService.sortData(responseData.result);      
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
}
