import { Component, OnInit} from '@angular/core';
import {
  NgbNavConfig,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  CommonService,
  WhitespaceValidator
} from 'src/shared/common.service';
import { DatePipe } from '@angular/common';
import { CommonConstants } from 'src/constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-payment-reconciliation',
  templateUrl: './payment-reconciliation.component.html',
  styleUrls: ['./payment-reconciliation.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class PaymentReconciliationComponent implements OnInit {
  
  commonConstants: any = CommonConstants;
  paymentReconciliationForm: UntypedFormGroup;
  public submitted:boolean = false;
  public paymentModes = [
    {title: 'ICICI Challan', value:'ICICI Challan'},
    {title: 'BILLDESK', value:'BILLDESK'}
  ];
  public bankTransfers = [
    {title: 'ICICI Challan', value:'ICICI Challan'},
    {title: 'BILLDESK', value:'BILLDESK'}
  ];
  public challanStatus = [
    {title: 'Fail', value:'Fail'},
    {title: 'Failed', value:'Failed'},
    {title: 'Challan Initiated', value:'Challan Initiated'},
    {title: 'Success', value:'SUCCESS'},
    {title: 'InProgress', value:'InProgress'},
    {title: 'Reject', value:'REJECT'},
    {title: 'Rejected', value:'Rejected'}
  ];
  public amountRefunded = [
    {title: 'Yes', value:'Yes'},
    {title: 'No', value:'No'}
  ];
  currentDate = new Date();
  ChallanMaxDate: any;
  ChallanMinDate: any;
  isChallanInfoFetched:boolean = false;

  get f() {
    return this.paymentReconciliationForm.controls;
  }

  constructor(
    private config: NgbNavConfig,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute, 
    private datePipe: DatePipe
  ) {

    this.paymentReconciliationForm = this.formBuilder.group({
      payment_mode: [null, [Validators.required]],
      no_of_transactions: [{value: null, disabled: true}, [Validators.required]],
      challan_number: [null, [Validators.required, Validators.pattern(this.commonConstants.alphanumericPattern), WhitespaceValidator.whiteSpaceValidate]],
      challan_date: [{value: null, disabled: true}, [Validators.required]],
      challan_amount: [{value: null, disabled: true}, [Validators.required, Validators.pattern(this.commonConstants.decimalPattern)]],
      payment_gsws_date: [null, [Validators.required]],
      transferred_gsws_amount: [null, [Validators.required, Validators.pattern(this.commonConstants.decimalPattern)]],
      transferred_bank_amount: [null, [Validators.required]],
      bank_transaction_id: [null, [Validators.required,  Validators.pattern(this.commonConstants.alphanumericPattern), WhitespaceValidator.whiteSpaceValidate]],
      challan_status: [null, [Validators.required]],
      challan_system_status: [{value: null, disabled: true}],
      ammount_refunded: [null, [Validators.required]],
      remarks: [null, [Validators.required, WhitespaceValidator.whiteSpaceValidate]]
    }, { validator: this.checkDates.bind(this) });
    config.destroyOnHide = false;
    config.roles = false;
       
    this.ChallanMaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.ChallanMinDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
  }

  checkDates(group: UntypedFormGroup) {
    if((group.controls.challan_date.errors || group.controls.payment_gsws_date.errors) && !group.controls.payment_gsws_date.errors?.invalidGSWSDate){
      return;
    }
    const challanDate = group.controls.challan_date.value;
    const GSWSDate = group.controls.payment_gsws_date.value;
    if(challanDate && GSWSDate) {
      if(this.commonService.formatDate(challanDate) == "Invalid date") {
        group.controls.challan_date.setErrors({ invalid: true});
      } else if(this.commonService.formatDate(GSWSDate) == "Invalid date") {
        group.controls.payment_gsws_date.setErrors({ invalid: true});
      } else if(this.commonService.formatDate(challanDate) > this.commonService.formatDate(GSWSDate)){
        group.controls.payment_gsws_date.setErrors({ invalidGSWSDate: true });
      } else {
        group.controls.payment_gsws_date.setErrors(null);
      }
    }
  }

  ngOnInit(): void {
  }

  resetForm() { 
    this.f['challan_number'].enable(); 
    this.submitted = false; 
    this.isChallanInfoFetched = false;
    this.f['payment_mode'].patchValue(null);
    this.f['no_of_transactions'].patchValue(null);
    this.f['challan_number'].patchValue(null);
    this.f['challan_date'].patchValue(null);
    this.f['challan_amount'].patchValue(null);
    this.f['payment_gsws_date'].patchValue(null);
    this.f['transferred_gsws_amount'].patchValue(null);
    this.f['bank_transaction_id'].patchValue(null);
    this.f['transferred_bank_amount'].patchValue(null);
    this.f['challan_status'].patchValue(null);
    this.f['challan_system_status'].patchValue(null);
    this.f['ammount_refunded'].patchValue(null);
    this.f['remarks'].patchValue(null);
  }

  getChallanInfo(){
    if(this.f['challan_number'].value) {
      this.commonService.getRequest(this.commonConstants.GetChallanDetailsByChallanId + this.f['challan_number'].value).subscribe({
        next: (responseData: any) => { 
            if(responseData.result?.isSuccess && responseData.result?.challanDetailsForPreFill) {
              this.isChallanInfoFetched = true;
              this.f['challan_number'].disable();
              this.f['no_of_transactions'].patchValue(responseData.result.challanDetailsForPreFill.noOfTransactions);
              const challanDate = new Date(responseData.result.challanDetailsForPreFill.challanDate);
              let challanDateFormat = challanDate.getDate()+"-"+(challanDate.getMonth()+1)+"-"+challanDate.getFullYear();
              this.f['challan_date'].patchValue(challanDateFormat);
              this.f['challan_amount'].patchValue(responseData.result.challanDetailsForPreFill.challanAmount);
              this.f['challan_system_status'].patchValue(responseData.result.challanDetailsForPreFill.challanStatus);
            } else if (responseData.result?.error) {
              this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
            } else {
              this.commonService.commonErrorPopup('Alert', "Please enter valid challan number", 'md');
            }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    } else {
      this.commonService.commonErrorPopup('Alert', "Please enter challan number", 'md');
    }
  }

  submitAccountDetails() {
    this.submitted = true;
    if(!this.isChallanInfoFetched) {
      this.commonService.commonErrorPopup('Alert', "Please pre-fill the challan information.", 'md');
    } else {
    if(this.paymentReconciliationForm.valid){
        let postData = {
          "modeOfPayment": this.f.payment_mode.value,
          "noOfServices": this.f.no_of_transactions.value,
          "challanNo": this.f.challan_number.value,
          "challanDate": this.f.challan_date.value ? this.commonService.formatDate(this.f.challan_date.value) : null,
          "challanAmount": this.f.challan_amount.value,
          "dateOfPaymentToGSWS": this.f.payment_gsws_date.value ? this.commonService.formatDate(this.f.payment_gsws_date.value) : null,
          "amountTransferredToGSWS": this.f.transferred_gsws_amount.value,
          "amountTransferredToBank": this.f.transferred_bank_amount.value,
          "transactionId": this.f.bank_transaction_id.value,
          "challanSystemStatus": this.f.challan_system_status.value,
          "challanStatus": this.f.challan_status.value,
          "refundStatus": this.f.ammount_refunded.value,
          "remarks": this.f.remarks.value
        };
        this.commonService
          .postRequest(this.commonConstants.SavePaymentReconciliationCollectionDetails, postData)
          .subscribe({
            next: (resData: any) => {
              if(resData){
                if (resData.result.isSuccess == true) {                
                  this.commonService.commonErrorPopup('Success', "Payment issue request is submitted successfully. Request ID # :" + resData.result.slNo, 'md');
                  this.resetForm();
                } else {
                  this.commonService.commonErrorPopup('Alert', resData.result.error, 'md');
                }
              } 
            },
            error: (error) => {
              console.log('client side', error);
            },
          });
        }
    }
  }
}