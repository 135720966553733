import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { PdfViewerPopupComponent } from '../../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-aarogyasri-view',
  templateUrl: './aarogyasri-view.component.html',
  styleUrls: ['./aarogyasri-view.component.css']
})
export class AarogyasriViewComponent implements OnInit, OnChanges {
  aarogyaSriView : UntypedFormGroup;
  commonConstants : any = CommonConstants;
  userInfo :any=[]
  teluguName:any[]=[];
  postData: any = {};
  isProceed:Boolean=false;
  @Input() certificateInfo: any;
  @Input() isNewEnrollment : Boolean = false;
  @Input() isEditable:Boolean = false; 
  @Input() basicInfo: any;
  @Input() id: any;
  @Input() reqId: any;
  @Input() taskId: any;
  @Input() originalFileList: any;
  fileList: any = [];
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() getUpdatedData = new EventEmitter<any>();
  //@Output() isProceed = new EventEmitter();
  validExt = '';
  @ViewChild('familyPhotoLabel')
  familyPhotoLabel!: ElementRef ;
  public submitted: Boolean = false;
  imgSrc:any;
  imgReupload: Boolean = false;
  showBtn:Boolean = false;
  aarogyaSri : UntypedFormGroup;
  familyDetailsTableData!:UntypedFormArray;
  isEdit:Boolean=false;
  @ViewChild('familyPhoto') familyPhoto: any;

gridData:any[]=[]
  constructor(private commonService: CommonService, private formBuilder: UntypedFormBuilder , private activatedRoute:ActivatedRoute , private router: Router , private modalService: NgbModal) {
    this.aarogyaSriView = this.formBuilder.group({
      familyPhoto : ['']
    })


    this.aarogyaSri = this.formBuilder.group({

      familyDetailsTableData : this.formBuilder.array([this.addfamilyDetails()])
    });


    this.familyDetailsTableData = this.aarogyaSri.get('familyDetailsTableData') as UntypedFormArray;



  this.commonService.checkAction.subscribe((data: any)=> {
    if(data.isActionBtn && data.actionName == "updateNewAarogyasri") {
      this.submitted = true;
      if(this.aarogyaSri.valid){
        let telugunames:any[] = [];
        this.familyDetailsTableData.value.forEach(
          (element: { tbTeluguName:any, tbfamilyAadharNo: any}) => {
            return telugunames.push({
              familyAadharNo: element.tbfamilyAadharNo,
              telguName: element.tbTeluguName
            });
        });
        this.getUpdatedData.emit({actionName:data.actionName, telugunames: telugunames});        
      }
    }
  });


  //   }
  // });
  this.commonService.commonAction.subscribe((data: any)=> {
    if(data) {
      this.onDataChange(data);
    }
  });
  
}

  ngOnInit(): void {
    if(this.isEditable){
    this.isEdit = true;  
    this.showBtn = true
    }
    if(!this.isNewEnrollment)
    {
      this.aarogyaSriView.controls['familyPhoto'].setValidators([
        Validators.required
      ]);

      this.aarogyaSriView.controls['familyPhoto'].updateValueAndValidity();
    }
    else
    {
      this.aarogyaSriView.controls['familyPhoto'].clearValidators();
      this.aarogyaSriView.controls['familyPhoto'].updateValueAndValidity();
    }

    console.log("taskId =",this.taskId)
    this.showImage();
    // let userData = this.commonService.getLocalStorage('userInfo');
    // if(userData && userData !== null) {
    //   this.userInfo = JSON.parse(userData);
    //   if(this.userInfo.jobTitle === 'Aarogyasri District Coordinator' )
    //   this.showBtn = true
    // }
   this.onDataChange(this.certificateInfo);
  }

  addfamilyDetails(data?:any, familyAadharNo?: any) {
   if(data){
  return this.formBuilder.group({
    tbTeluguName: [data],
    tbfamilyAadharNo: [familyAadharNo]
  })
  }else{
  return this.formBuilder.group({
    tbTeluguName: [""],
    tbfamilyAadharNo: [familyAadharNo]
  })
}

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.showImage();
    console.log(this.aarogyaSri +'form')

  }


  //file.blobRef, file.fileName

  showImage(bRef="") {
   // console.log('file Extension', this.checkFileExtension(fileName));
    this.imgSrc = ""
    console.log(this.originalFileList)
    console.log(JSON.stringify(this.originalFileList) + 'originalFileList')
     let filteredOriginal: any = this.originalFileList.filter((item : any) =>
    (item.documentType === "Family Photo") || (item.DocumentType ==="Family Photo"));
    // let blobRef = filtered[0].blobRef ? filtered[0].blobRef : this.fileList[0].BlobRef;
    // let fileName = filtered[0].fileName?  filtered[0].fileName: this.fileList[0].FileName;
    // console.log('showww Imageee', blobRef)
    // this.originalFileList[0].blobRef = blobRef;
    // this.originalFileList[0].fileName = fileName
     console.log(bRef + 'bref')
     console.log(JSON.stringify(filteredOriginal) + 'filteredOriginal')
    let blobRef = filteredOriginal[0]?.blobRef;
    let fileName = filteredOriginal[0]?.fileName;
    if(bRef !== ""){
      let filtered: any = this.fileList.filter((item : any) =>
      item.DocumentType === "Family Photo");
      blobRef = filtered[0]?.BlobRef;
      fileName =filtered[0]?.FileName;
     this.originalFileList=this.fileList
      //  this.originalFileList[0].blobRef = blobRef;
      //  this.originalFileList[0].fileName = fileName;


    }
    if(bRef=== 'reupload'){
      let filtered: any = this.fileList.filter((item : any) =>
      (item.DocumentType === "Family Photo") );
      console.log(JSON.stringify(filtered) + 'filtered')
      // this.originalFileList.filter((item : any) =>
      // item.documentType === "Family Photo");
      //  this.originalFileList[0].blobRef = BlobRef;
      //  this.originalFileList[0].fileName = fileName;
      this.originalFileList=this.fileList
      console.log(this.originalFileList)
      blobRef=filtered[0]?.BlobRef;
    }
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => {
        let fileInfo =  responseData.result.fileContents;
        console.log(fileName)
        if (this.checkFileExtension(fileName) !== 'pdf') {

          this.imgSrc = 'data:image/png;base64,' +fileInfo;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });

  }

  downloadFile(file: any) {
    console.log(JSON.stringify(file) + 'file')

    if(file.FileName && file.DocumentType && file?.BlobRef){
      file.fileName =file.FileName
      file.documentType=file.DocumentType
      file.blobRef=file?.BlobRef
    }
      this.download.emit(file);
  }


  viewFile(blobRef: any, fileName:any) {
    console.log('file Extension', this.checkFileExtension(fileName));
    console.log('viewFile', blobRef);
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => {
        let fileInfo =  responseData.result.fileContents;
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.showPrintDownload = false;
        if (this.checkFileExtension(fileName) !== 'pdf') {
          modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        }
        else {
          this.view.emit(blobRef);
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });

  }
  viewFileforAadhar(blobRef: any) {
    this.view.emit(blobRef);
  }
  checkFileExtension(name: any) {
    let fileNameArr = name.split('.');
    return fileNameArr[fileNameArr?.length - 1]?.toLowerCase();
  }


  onFamilyPhotoSelected(fileEvent: any) {
    const familyPhotoFile: File | null = fileEvent.target.files[0];
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    console.log(fileExtensionType);
    if (fileExtensionType.toLowerCase() === 'jpg') {
      this.validExt = 'jpg';
    } else if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } else{
      this.validExt = 'png';
    }
    this.familyPhotoLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.aarogyaSriView.controls['familyPhoto'].setValidators([
     fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent)
    ])
    this.aarogyaSriView.controls['familyPhoto'].updateValueAndValidity();
    if (this.aarogyaSriView.controls.familyPhoto.valid)

      this.fileUpload(familyPhotoFile,this.aarogyaSriView.controls['familyPhoto'].value,fileEvent.target.files[0].name,'Family Photo', 'familyPhoto', 'familyPhotoLabel');
  }

  clearFileUpload(name:string, label:string) {
    this.aarogyaSriView.controls[name].reset();
    if (label === 'familyPhotoLabel') {
      this.familyPhotoLabel.nativeElement.innerText = 'No File Choosen';
      this.aarogyaSriView.controls[name].clearValidators();
      this.aarogyaSriView.controls[name].updateValueAndValidity();
    }
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    this.fileList = []
    let filteredFile: any = this.originalFileList.filter((item : any) =>
    item.documentType === "Aadhaar Card");
    if(filteredFile[0])
    {
      this.fileList.push(filteredFile[0])
      console.log("aadhar card" , this.fileList)
    }
    // this.docUploaded = true;
    // console.log("doc uploaded" + this.docUploaded)
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{}");
      formData.append('CertType', 'DHC')  
      console.log( formData,'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => {
        if(typeof(responseData.result) !== 'string'){

        let index = this.fileList.map((item : any) => item.DocumentType).indexOf(documentType);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }

        this.fileList.push({
          FileName: fileName,
          DocumentType: documentType,
          BlobRef : responseData['result']?.blobRef
        })
        this.updateFileUpload()
      }else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          console.log(this.fileList,'FileList')
        }
      
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }


  numberMasking(aadhaar:any) {
    var mask = "";
    if(aadhaar){
      for(let j=1; j<=aadhaar.length-4; j++){
        mask += "x";
      }
      return mask + aadhaar.slice(8,12);
    }
    else return null;
  }

  submit()
  {

    this.submitted = true
    if(!this.aarogyaSriView.valid )
    {
      console.log("Not submitted")
    }
    else
    {
      //this.updateFileUpload(this.id)
      console.log("Submitted")
    }
  }

  showReuploadImage(){

    this.imgReupload = true;
    this.familyPhoto.nativeElement.click();
  }
  updateFileUpload() {
    let fileData = {
      FileList: this.fileList,
      RequisitionId: this.taskId ? this.taskId : this.activatedRoute.snapshot.queryParams.id,
      requisitionType : this.basicInfo.requisitionType
    }
    console.log("fileData",fileData)
    this.commonService.postRequest(this.commonConstants.HealthCardFileUpload,fileData).subscribe({
      next: (resData:any) => {
        console.log(resData,'File Upload  Respose data');
        //this.forwardToNextStep()
        this.showImage("reupload")
        this.imgReupload = false;
        this.clearFileUpload('familyPhoto', 'familyPhotoLabel')
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  forwardToNextStep()
  {
    let postData: any = {};
    postData.taskId = this.taskId;
    postData.comment = JSON.stringify({fileList: this.fileList});
    postData.action = 1;
    this.commonService.putRequest(this.commonConstants.approveReject,postData).subscribe({next:(resData:any) =>{

      let responseData = resData.result;
      console.log(responseData,"need to check responsedata")
      if(typeof(responseData) === 'string')
      {
        console.log("task not pending")
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = responseData
      }
      else if(responseData.updatedTask.nextTaskId)
      {
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = 'Application forwarded to next level.'
      }
      else
      {
        const mdRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        mdRef.componentInstance.message = 'Application not forwarded to next level.'
      }
    }
  })
  }
  checkText(event : any , index : any)
  {
    let regex = '/?/g'
    if(event.target.value.includes("?"))
    {
      let result = event.target.value.replace(event.target.value, "");
      console.log(event.target.value , "event.target.value",result)
      this.familyDetailsTableData.controls[index].get('tbTeluguName')?.setValue(result)
    }
  }

  onDataChange(data: any) {

    let count :any = []
    this.familyDetailsTableData.controls.forEach((e,i) => {

     count.push(i)

  });

  count.slice().reverse().forEach((x: any) =>this.familyDetailsTableData.removeAt(x));
    console.log(JSON.stringify(data) + 'data from common dashboard')
    data?.familyDetails.forEach(
      (
        element: {
        teluguName:any;
        familyAadharNo:any;
        }  , index: any  ) => {

        let formGroup = this.formBuilder.group({
          tbTeluguName : element.teluguName,
          tbfamilyAadharNo : element.familyAadharNo

        });
   this.familyDetailsTableData.push(this.addfamilyDetails(element.teluguName, element.familyAadharNo) )
        this.familyDetailsTableData.controls[index].get('tbTeluguName')?.setValue(element.teluguName);
        this.familyDetailsTableData.controls[index].get('tbfamilyAadharNo')?.setValue(element.familyAadharNo);
      }
    );


  }
// onKeyup(e:any,i:any,aadhar:any){
// console.log(e.target.value, i ,aadhar)
// // let editData = this.certificateInfo?.familyDetails.filter((item : any) => item.familyAadharNo === aadhar);
// // console.log(editData[0].teluguName,'telugu name',e.target.value)
// // this.commonService.commonErrorPopup("Alert", 'Edited name aadhar is not matching with family details', "md")
// //  if(editData[0].teluguName !== e.target.value){
//   this.isEdit = true;

//   // let teluguName= this.familyDetailsTableData.controls[i].get('tbTeluguName')?.value;
//   // this.certificateInfo?.familyDetails[i].teluguName.patchValue(teluguName);
//  //}
// //else{
// //   this.isEdit = false;
// // }
// // console.log(editData.teluguName !== e.target.value + 'editData.teluguName !== e')
// // console.log(this.isEdit + 'isEdit')
// // console.log(editData.teluguName)

//   }
//   updateRequisition(id:any, uhid:any){
//     this.postData = {}
//     this.postData.RequisitionId=id;
//     this.postData.famaddress=this.certificateInfo?.familyAddress;
    

//   this.postData.RequisitionData=this.certificateInfo;
//   this.gridData=[];
//   this.familyDetailsTableData.value.forEach(
//     (element: {
//           tbTeluguName:any,

//     }) => {



//       return this.gridData.push({

//         teluguName : element.tbTeluguName,

//       });
//     }
//   );
//   console.log(JSON.stringify(this.gridData)+ 'whle updating')
//   this.postData.RequisitionData.uhid=uhid;
//   //this.postData.RequisitionData =this.gridData;
//   for(var i = 0; i<this.certificateInfo?.familyDetails.length ; i++){
//     this.postData.RequisitionData.familyDetails[i].teluguName = this.gridData[i].teluguName;
//   }
//   console.log(this.postData)
//     this.commonService
//     .postRequest(
//       this.commonConstants.UpdateRequisitionDataForArogyasri,
//       this.postData
//     )
//     .subscribe({
//       next: (responseData: any) => {
//         if(responseData.result.isSuccess ===  true){
//         this.generate.emit();
//         }

//         console.log(
//           responseData,
//           'Aarogya sri update health card respose data'
//         );
//         console.log(responseData.status, 'stats');

//       },
//       error: (error) => {
//         console.log('client side', error);
//       },
//     });
 
// }
matcher(event:any) {
  const allowedRegex = /[0-9a-zA-Z]/g;
  const special =/[`~,!@#$%^&*<>;':"{}()=_+-]/g;

  if (event.key.match(allowedRegex) || event.key.match(special) ) {
      event.preventDefault();
  }

}
}
