import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-suomoto-pending-applications-report',
  templateUrl: './suomoto-pending-applications-report.component.html',
  styleUrls: ['./suomoto-pending-applications-report.component.css']
})
export class SuomotoPendingApplicationsReportComponent implements OnInit {
  form: UntypedFormGroup;
  districts: any[] = [];
  items: any[] = [];
  designation: any[] = [];
  totalItems: any[] = [];
  showReports: boolean = false;
  noOfCol: any = 7;
  page = 1;
  pageSize = 10;
  commonConstants: any = CommonConstants;
  @ViewChild('TABLE') table!: ElementRef;

  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService, private router: Router) {
    this.form = this.formBuilder.group({
      district: [null],
      designation:  [null]
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      designation:'All'
    });
    this.getDistricts();
  }

  get f() { return this.form.controls; }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  onDistrictChange() {
    this.showReports = false;
    this.items = [];
    this.totalItems = [];
    this.form.patchValue({designation: "All"});
    this.getReportData(this.f.district.value?.districtCode, this.f.designation.value);
  }
  onDesignationChange() {
    this.showReports = false;
    this.items = [];
    this.totalItems = [];
    this.getReportData(this.f.district.value?.districtCode, this.f.designation.value);
  }

  getReportData(districtID: any, designation: any) {
    this.page = 1;
    this.commonService.getRequest(this.commonConstants.getSuoMotoICReport + districtID + '&Designation=' + designation).subscribe({
      next: (responseData: any) => {
        if (Array.isArray(responseData?.result) && responseData?.result.length > 0) {
          this.showReports = true;
          this.items = this.commonService.sortItems(responseData?.result, 'designation');
          this.items = this.items.filter(item => item.totalflag !== 'Y')
          this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y');
        } else {
          this.showReports = true;
          this.items = [];
          this.totalItems = [];
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  downloadExcel() {
    let fileName: any = 'SUOMOTO APPLICATIONS PENDING REPORT' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SUOMOTO PENDING REPORT');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

}
