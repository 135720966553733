<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS / Application Submission / Labour <span class="activePage">/ Application For Integrated Registration Of Establishment Under Labour Laws (SECOND SCHEDULE [Sec.2(d) and Sec4(1)])- FORM A</span>
      </div>
    </div>
    <div class="mainPageContainer mr-3">
      <h6>Application For Integrated Registration Of Establishment Under Labour Laws (SECOND SCHEDULE [Sec.2(d) and Sec4(1)])- FORM A</h6>
      <form [formGroup]="registartionForm">
        <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
          <ng-container [ngbNavItem] = "1">
            <a ngbNavLink>Basic Details</a>
            <ng-template ngbNavContent>
              <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted"  (reset)="commonFormSubmitted = false"></app-common-form>
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
              </div>
            </ng-template>
          </ng-container>
          <ng-container [ngbNavItem] = "2">
            <a ngbNavLink>Application Form</a>
            <ng-template ngbNavContent>

              <div class="row">
                <div class="col-lg-4">
                  <label class="subHeadingCls">Is Application Fresh or Renew?<span class="mandatoryCls">*</span></label>
                </div>
                <div class="col-lg-6" [ngClass]="{ 'is-invalid':submitted && f.isFreshRenew?.errors }">
                  <input type="radio" (change)="onTypeChanged('fresh')" value="fresh" name="isFreshRenew" formControlName="isFreshRenew">
                  <span class="mx-2 radioCls">Fresh</span>
                  <input type="radio" (change)="onTypeChanged('renew')" value="renew" name="isFreshRenew" formControlName="isFreshRenew">
                  <span class="mx-2 radioCls">Renew</span>
                </div>
              </div>

              <ng-container *ngIf="f.isFreshRenew?.value == 'renew'">
                <div class="row mb-3">
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Previous Application Number: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="prevApplicationNo" formControlName="prevApplicationNo" class="inputCls width75" required 
                    [ngClass]="{ 'is-invalid':isFetchSubmitted && f.prevApplicationNo.errors }" [readonly] ="isInfoPrefilled" maxlength="20" minlength="5">
                    <button class="btn btn-primary float-right" [attr.disabled] ="isInfoPrefilled ? '' : null" (mousedown)="getCertificateDetails()">Pre-Fill</button>
                    <div *ngIf="isFetchSubmitted && f.prevApplicationNo.errors" class="invalid-feedback">
                      <div *ngIf="f.prevApplicationNo.errors.required || f.prevApplicationNo.errors.whiteSpaceValidate">Application Number is required</div>
                      <div *ngIf="f.prevApplicationNo.errors.minlength || f.prevApplicationNo.errors.maxlength">Please enter valid Application Number</div>
                  </div>
                  </div>
                </div>
                <app-labour-registration-view *ngIf="isInfoPrefilled" [basicInfo]="basicInfo" [certificateInfo]="certificateInfo" [fileList]="fileList" (view)="viewFile($event)" (download)="downloadFile($event)"></app-labour-registration-view>
              </ng-container>


              <ng-container *ngIf="f.isFreshRenew?.value == 'fresh'">
                <div class="row">
                    <div class="col-lg-4">
                    <label class="subHeadingCls">Is Your Establishment An Industry?<span class="mandatoryCls">*</span></label>
                   </div>
                    <div class="col-lg-6" [ngClass]="{ 'is-invalid':submitted && f.isEstablishment?.errors }">
                    <input type="radio"  value="Yes" name="isEstablishment" formControlName="isEstablishment" (change)="onChange(showPopuphtml)">
                    <span class="mx-2 radioCls">Yes</span>
                    <input type="radio"  value="No" name="isEstablishment" formControlName="isEstablishment" (change)="onChange('')">
                    <span class="mx-2 radioCls">No</span>
                </div>
                </div>
                <div *ngIf="showForm === true">
              

                  <div class="row mt-3 mb-3">
                    <div class="col-lg-6">
                    <label class="labelCls" for="isRegistered">Are you Registered with AP innovation Society:<span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col-lg-6"  [ngClass]="{ 'is-invalid':submitted && f.isRegistered?.errors }">
                    <input type="radio"  value="Yes" name="isRegistered" id="yes" formControlName="isRegistered"  (change)="onChangeRegistered()">
                    <span class="mx-2 radioCls" for="yes">Yes</span>
                    <input type="radio"  value="No" name="isRegistered" id="no" formControlName="isRegistered" (change)="onChangeRegistered()">
                    <span class="mx-2 radioCls" for="no">No</span>

                    <div *ngIf="submitted && f.isRegistered.errors" class="selectError">
                      <div *ngIf="f.isRegistered.errors?.required">Please Select Are you Registered with AP innovation Society</div>
                    </div> 
                </div>
                
                  </div>
                  
                  

<div class="row" *ngIf="isShowStartUpId">
<div class="col-4">
  <label class="labelCls">APIS Startup_id (AP innovation Society Startup_id): <span class="mandatoryCls"> *</span></label>
</div>
<div class="clo-4">
  <input type="text" name="StartUpId" (keypress)="keyPressAlphaNumericOnly($event)"
  appBlockCopyPaste class="inputCls" formControlName = "StartUpId"  required [ngClass]="{ 'is-invalid':submitted && f.StartUpId?.errors }">
  <div *ngIf="submitted && f.StartUpId.errors" class="invalid-feedback">
      <div *ngIf="f.StartUpId.errors?.required">Please enter APIS Startup_id (AP innovation Society Startup_id)</div>
    </div>
</div>

</div>
                <div class="subHeadingCls mb-3">Service Details</div>
                <div class="row mb-3">
                  <div class="col-lg-3">
                    <label class="labelCls">Area of Services: <span class="mandatoryCls"> *</span></label>
                    <select class="selectCls" formControlName="areaOfService" required
                      [ngClass]="{ 'is-invalid':submitted && (f.areaOfService.errors || f.areaOfService.value === 'Select')}">
                      
                      <option value="Registering/Licencingofficerwise">REGISTERING / LICENCING OFFICER WISE</option>

                      <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                    </select>
                    <div *ngIf="submitted && (f.areaOfService.errors|| f.areaOfService.value === 'Select')"
                      class="selectError">
                      <div *ngIf="f.areaOfService.errors?.required|| f.areaOfService.value === 'Select'">Please select Area of Services</div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-3">
                      <label class="labelCls">Application Number: <span class="mandatoryCls">*</span></label>
                      <input type="text" name="nature" (keypress)="keyPressAlphaNumeric($event)" required maxlength="16"
                        appBlockCopyPaste class="inputCls" formControlName = "applicationNumber" [ngClass]="{ 'is-invalid':submitted && f.applicationNumber?.errors }">


                        <div *ngIf="submitted && f.applicationNumber.errors" class="invalid-feedback">
                          <div *ngIf="f.applicationNumber.errors?.required">Please enter Appication Number</div>
                        </div>
                    </div> -->
                    <div class="col-lg-6">
                      <label class="labelCls">Nature of Business/work/construction/activity/manufactory: <span class="mandatoryCls">*</span></label>
                      <select class="selectCls" formControlName="natureOfBusiness" required
                        [ngClass]="{ 'is-invalid':submitted && (f.natureOfBusiness.errors || f.natureOfBusiness.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let item of natureOFBusiness" [ngValue]="item.name"> {{item.name}}</option>
                      </select>
                      <div *ngIf="submitted && (f.natureOfBusiness.errors|| f.natureOfBusiness.value === 'Select')"
                        class="selectError">
                        <div *ngIf="f.natureOfBusiness.errors?.required|| f.natureOfBusiness.value === 'Select'">Please select Nature of Business/work/construction/activity/manufactory</div>
                      </div>
                    </div>
                  </div>
                  <hr class="hrCls mt-4">
                  <div class="subHeadingCls mb-3">Registration/License Required under<span class="mandatoryCls"> *</span></div>
                
                      <div class="row mb-3 ml-3">
                          <div class="col-lg-4">
                            <input class="form-check-input " type="checkbox" formControlName = "apShop"  required (change)="on1988Selected()" id="apShop" [ngClass]="{ 'is-invalid':submitted && f.apShop.errors }">
                            <label class="labelCls" for="consent"><b>AP Shops and ESTTS, Act 1988<span
                              class="mandatoryCls">*</span></b> </label>
                            <div *ngIf="submitted && f.apShop.errors" class="invalid-feedback">
                              <div *ngIf="f.apShop.errors?.required">Please Check AP Shops and ESTTS, Act 1988</div>
                            </div>
                          </div>
                  
                          <!-- <div class="col-lg-4">
                              <input class="form-check-input " type="checkbox" formControlName = "motorTransport"   id="motorTransport" [ngClass]="{ 'is-invalid':submitted && f.motorTransport.errors }">
                              <label class="labelCls" for="consent"><b>Motor Transport Workers Act,1961<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <input class="form-check-input " type="checkbox" formControlName = "contractLabour"   id="contractLabour" [ngClass]="{ 'is-invalid':submitted && f.contractLabour.errors }">
                              <label class="labelCls" for="consent"><b>Contract Labour(R and A) Act,1970-Principal Employer<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div> -->
                          </div>

                          <div *ngIf="showWorkers === true">
                            <div class="subHeadingCls mb-3">AP Shops and ESTTS. Act 1988:</div>

                            <div class="subHeadingCls mb-3 mt-3">Worker Details:</div>

                            <div class="subHeadingCls mb-3 mt-3">Direct Workers:</div>
                        

                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="fieldHolder">
                                      <label class="labelCls">Male: <span class="mandatoryCls">*</span></label>
                                      <input type="text" name="directMale" appBlockCopyPaste class="inputCls" required numbersOnly maxlength ="4"  (keyup)="onWorkersChange()" formControlName = "directMale" [ngClass]="{ 'is-invalid':submitted && f.directMale.errors}">
                                      <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                    
                                      <div *ngIf="submitted && f.directMale.errors" class="invalid-feedback">
                                          <div *ngIf="f.directMale.errors?.required">Please enter Direct Male Workers</div>
                                        </div>
                                      
                                </div> 
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="fieldHolder">
                                    <label class="labelCls">Female: <span class="mandatoryCls">*</span></label>
                                    <input type="text" name="directFemale" appBlockCopyPaste class="inputCls" required numbersOnly maxlength ="4" (keyup)="onWorkersChange()" formControlName = "directFemale" [ngClass]="{ 'is-invalid':submitted && f.directFemale.errors}">
                                      <div *ngIf="submitted && f.directFemale.errors" class="invalid-feedback">
                                        <div *ngIf="f.directFemale.errors?.required">Please enter Direct Female Workers</div>
                                      </div>
                                  </div>                     
                                </div>
                                </div>
                            
                              
                                <div class="subHeadingCls mb-3 mt-3">Contract Workers:</div>
                        

                                <div class="row">
                                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                      <div class="fieldHolder">
                                          <label class="labelCls">Male: <span class="mandatoryCls">*</span></label>
                                          <input type="text" name="contractMale" appBlockCopyPaste class="inputCls" required numbersOnly maxlength ="4" (keyup)="onWorkersChange()" formControlName = "contractMale" [ngClass]="{ 'is-invalid':submitted && f.contractMale.errors}">
                                          <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                      
                                          <div *ngIf="submitted && f.contractMale.errors" class="invalid-feedback">
                                            <div *ngIf="f.contractMale.errors?.required">Please enter Contract Male Workers</div>
                                          </div>
                                        
                                    </div> 
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                      <div class="fieldHolder">
                                        <label class="labelCls">Female: <span class="mandatoryCls">*</span></label>
                                        <input type="text" name="contractFemale" appBlockCopyPaste class="inputCls" required numbersOnly maxlength ="4" (keyup)="onWorkersChange()" formControlName = "contractFemale" [ngClass]="{ 'is-invalid':submitted && f.contractFemale.errors}">
                                        <div *ngIf="submitted && f.contractFemale.errors" class="invalid-feedback">
                                            <div *ngIf="f.contractFemale.errors?.required">Please enter Contract Female Workers</div>
                                          </div>
                                      </div>                     
                                  </div>
                                  </div>
                                

                                  <div class="subHeadingCls mb-3 mt-3">Casual / Daily wage Workers:</div>
                        

                                  <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="fieldHolder">
                                            <label class="labelCls">Male: <span class="mandatoryCls">*</span></label>
                                            <input type="text" name="casualMale" appBlockCopyPaste class="inputCls" required numbersOnly maxlength ="4" (change)="onWorkersChange()" formControlName = "casualMale" [ngClass]="{ 'is-invalid':submitted && f.casualMale.errors}">
                                            <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                          
                                            <div *ngIf="submitted && f.casualMale.errors" class="invalid-feedback">
                                                <div *ngIf="f.casualMale.errors?.required">Please enter Casual / Daily wage Male Workers</div>
                                              </div>
                                            
                                      </div> 
                                      </div>
                                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="fieldHolder">
                                          <label class="labelCls">Female: <span class="mandatoryCls">*</span></label>
                                          <input type="text" name="casualFemale" appBlockCopyPaste class="inputCls" required numbersOnly maxlength ="4" (keyup)="onWorkersChange()" formControlName = "casualFemale" [ngClass]="{ 'is-invalid':submitted && f.casualFemale.errors}">
                                            <div *ngIf="submitted && f.casualFemale.errors" class="invalid-feedback">
                                              <div *ngIf="f.casualFemale.errors?.required">Please enter Casual / Daily wage Female Workers</div>
                                            </div>
                                        </div>                     
                                      </div>
                                      </div>
                                  


                                  <div class="row mt-4">
                                    <div class="col-3">  <label class="labelCls">Total Workers: <span class="mandatoryCls">*</span></label></div>
                                  <div class="col-3">
                                    <input type="text" name="totalWorkers" appBlockCopyPaste class="inputCls" required numbersOnly maxlength ="6" formControlName = "totalWorkers" readonly [ngClass]="{ 'is-invalid':submitted && f.totalWorkers.errors}">
                                    <div *ngIf="submitted && f.totalWorkers.errors" class="invalid-feedback">
                                        <div *ngIf="f.totalWorkers.errors?.required">Please enter Total Workers</div>
                                      </div>
                                  </div>
                                  
                                  </div>   

                          </div>
                          <!-- <div class="row ml-3 mb-3">
                            <div class="col-lg-4">
                              <input class="form-check-input " type="checkbox" formControlName = "InterStateMigrantWorkmen"   id="InterStateMigrantWorkmen" [ngClass]="{ 'is-invalid':submitted && f.InterStateMigrantWorkmen.errors }">
                              <label class="labelCls" for="consent"><b>InterState Migrant Workmen(RE and CS)Act,1979-Principal Employer<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <input class="form-check-input " type="checkbox" formControlName = "buildingAndOtherConstructionWorker"   id="buildingAndOtherConstructionWorker" [ngClass]="{ 'is-invalid':submitted && f.buildingAndOtherConstructionWorker.errors }">
                              <label class="labelCls" for="consent"><b>Building And Other Construction Workers(RE and CS)Act ,1996<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <input class="form-check-input " type="checkbox" formControlName = "paymentOfGatuity"   id="paymentOfGatuity" [ngClass]="{ 'is-invalid':submitted && f.paymentOfGatuity.errors }">
                              <label class="labelCls" for="consent"><b>Payment of Gratuity Act,1972<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div>
                          </div>
                          <div class="row ml-3 mb-3">
                            <div class="col-sm-4">
                              <input class="form-check-input " type="checkbox" formControlName = "beediAndCigerWorkers"   id="beediAndCigerWorkers" [ngClass]="{ 'is-invalid':submitted && f.beediAndCigerWorkers.errors }">
                              <label class="labelCls" for="consent"><b>Beedi & Cigar Workers(COE)Act,1966<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <input class="form-check-input " type="checkbox" formControlName = "contractLabourEstablishment"   id="contractLabourEstablishment" [ngClass]="{ 'is-invalid':submitted && f.contractLabourEstablishment.errors }">
                              <label class="labelCls" for="consent"><b>Contract Labour(R and A) Act,1970(License of Contractor Establishment)<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div>
                            <div class="col-sm-4">
                              <input class="form-check-input " type="checkbox" formControlName = "interStateMigrantWorkmen"   id="interStateMigrantWorkmen" [ngClass]="{ 'is-invalid':submitted && f.interStateMigrantWorkmen.errors }">
                              <label class="labelCls" for="consent"><b>Inter State Migrant Workmen(RE and CS)Act,1979(License of Contractor Establishment)<span
                                class="mandatoryCls">*</span></b> </label>
                              <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                              </div>
                            </div>
                          </div> -->
                      

                  
                          <hr class="hrCls mt-4">

                  <div class="subHeadingCls mb-3">PARTICULARS OF ESTABLISHMENT / EMPLOYER (COMMON UNDER ALL THE ACTS):</div>
                  <div class="subHeadingCls mb-3">ESTABLISHMENT DETAILS :</div>

                  <div class="row mt-3">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="fieldHolder">
                      <label class="labelCls">Name of Shop/Establishment: <span class="mandatoryCls">*</span></label>
                      <input type="text" name="nameOfShop"   maxlength ="50" (keypress)="keyPressAlphaNumeric($event)"
                        appBlockCopyPaste class="inputCls" formControlName = "nameOfShop" required [ngClass]="{ 'is-invalid':submitted && f.nameOfShop.errors}">
                        <div *ngIf="submitted && f.nameOfShop.errors" class="invalid-feedback">
                          <div *ngIf="f.nameOfShop.errors?.required">Please enter Name of Shop/Establishment</div>
                          <div *ngIf="f.nameOfShop.errors?.pattern">Please enter valid Name of Shop/Establishment</div>
                        </div>
                    </div> 
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="fieldHolder">
                      <label class="labelCls">Classification of Establishment: <span class="mandatoryCls">*</span></label>
                      <select class="selectCls" formControlName="classificationOfEstablishment" required
                      [ngClass]="{ 'is-invalid':submitted && (f.classificationOfEstablishment.errors || f.classificationOfEstablishment.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of classificationEstablishment" [ngValue]="item.name"> {{item.name}}</option>
                    </select>
                        <div *ngIf="submitted && f.classificationOfEstablishment.errors" class="selectError">
                          <div *ngIf="f.classificationOfEstablishment.errors?.required">Please enter Classification of Establishment</div>
                        </div>
                    </div>                    
                    </div>
                    </div>
                  
                    <div class="row mt-3">
                      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div class="fieldHolder">
                              <label class="labelCls">Category of Establishment: <span class="mandatoryCls">*</span></label>
                              <select class="selectCls" formControlName="categoryofEstablishment" required
                              [ngClass]="{ 'is-invalid':submitted && (f.categoryofEstablishment.errors || f.categoryofEstablishment.value === 'Select')}">
                              <option [ngValue]="null" disabled>Select</option>
                              <option *ngFor="let item of categoryEstablishment" [ngValue]="item.name"> {{item.name}}</option>
                            </select>
                                <div *ngIf="submitted && f.categoryofEstablishment.errors" class="selectError">
                                  <div *ngIf="f.categoryofEstablishment.errors?.required">Please enter Category of Establishment</div>
                                </div>
                        </div> 
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                          <div class="fieldHolder">
                            <label class="labelCls">Street/Door No: <span class="mandatoryCls">*</span></label>
                            <input type="text" name="StreetOrDoorNo" [ngClass]="{ 'is-invalid':submitted && f.StreetOrDoorNo.errors}"
                              appBlockCopyPaste class="inputCls" formControlName = "StreetOrDoorNo" required>
                              <div *ngIf="submitted && f.StreetOrDoorNo.errors" class="invalid-feedback">
                                <div *ngIf="f.StreetOrDoorNo.errors?.required">Please enter Street/Door No</div>
                                <div *ngIf="f.StreetOrDoorNo.errors?.pattern">Please enter valid Street/Door No</div>
                              </div>
                          </div>                     
                        </div>
                        </div>
                      

                        <div class="row mt-3">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Locality: <span class="mandatoryCls">*</span></label>
                                <input type="text" name="locality" appBlockCopyPaste class="inputCls" formControlName = "locality" required [ngClass]="{ 'is-invalid':submitted && f.locality.errors}">
                                <div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
                                    <div *ngIf="f.locality.errors?.required">Please enter Locality</div>
                                    <div *ngIf="f.locality.errors?.pattern">Please enter valid Locality</div>
                                  </div>
                                  <!-- <div *ngIf="submitted && f.locality.errors" class="selectError">
                                    <div *ngIf="f.locality.errors?.required">Please enter Category of Establishment</div>
                                  </div> -->
                          </div> 
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                              <!-- <label class="labelCls">District: <span class="mandatoryCls">*</span></label>
                              <input type="text" name="district" 
                                appBlockCopyPaste class="inputCls" formControlName = "district">
                                <div *ngIf="submitted && f.district.errors" class="selectError">
                                  <div *ngIf="f.district.errors?.required">Please enter District</div>
                                </div> -->
                                <label class="labelCls">District: <span class="mandatoryCls">*</span></label>
                                <select class="selectCls" formControlName="district" required (change)="onDistrictChange('establishment', 'district', $event.target)"
                                [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select')}">
                                <option [ngValue]="null" disabled>Select</option>
                                <option *ngFor="let item of districts" [ngValue]="item"> {{item.name}}</option>
                              </select>
                              <div *ngIf="submitted && f.district.errors" class="selectError">
                                <div *ngIf="f.district.errors?.required">Please select District</div>
                              </div> 
                            </div>                     
                          </div>
                          </div>

                          <div class="row mt-3">
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <div class="fieldHolder">
                                  <label class="labelCls">Mandal: <span class="mandatoryCls">*</span></label>
                                  <select class="selectCls" formControlName="mandal" required (change)="onMandalChange('establishment','mandal',$event.target)"
                                  [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select')}">
                                  <option [ngValue]="null" disabled>Select</option>
                                  <option *ngFor="let item of mandals" [ngValue]="item"> {{item.name}}</option>
                                </select>
                                  <div *ngIf="submitted && f.mandal.errors" class="selectError">
                                      <div *ngIf="f.mandal.errors?.required">Please select Mandal</div>
                                    </div>
                                  
                            </div> 
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <div class="fieldHolder">
                                <label class="labelCls">Village Secretariat/Ward Secretariat: <span class="mandatoryCls">*</span></label>
                                
                                
                                <select class="selectCls" formControlName="village" required
                                [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select')}">
                                <option [ngValue]="null" disabled>Select</option>
                                <option *ngFor="let item of villages" [ngValue]="item"> {{item.name}}</option>
                              </select>
                                <!-- <input type="text" name="village" 
                                  appBlockCopyPaste class="inputCls" formControlName = "village"> -->
                                  <div *ngIf="submitted && f.village.errors" class="selectError">
                                    <div *ngIf="f.village.errors?.required">Please seelct Village</div>
                                  </div>
                              </div>                     
                            </div>
                            </div>
                        
                            <div class="row mt-3">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="fieldHolder">
                                    <label class="labelCls">Pin Code: <span class="mandatoryCls">*</span></label>
                                    <input type="text" name="pincode" appBlockCopyPaste class="inputCls" formControlName = "pincode"  numbersOnly maxlength ="6" required [ngClass]="{ 'is-invalid':submitted && f.pincode.errors}">
                                    <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                  
                                    <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                        <div *ngIf="f.pincode.errors?.required">Please enter Pin Code</div>
                                      </div>
                                    
                              </div> 
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="fieldHolder">
                                  <label class="labelCls">Mobile No: </label>
                                  <input type="text" name="mobile" appBlockCopyPaste class="inputCls"  formControlName = "mobile"  numbersOnly maxlength ="10" [ngClass]="{ 'is-invalid':submitted && f.mobile.errors}">
                                    <!-- <div *ngIf="submitted && f.mobile.errors" class="selectError">
                                      <div *ngIf="f.mobile.errors?.required">Please enter Mobile</div>
                                    </div> -->
                                </div>                     
                              </div>
                              </div>
                          
                              
                            <div class="row mt-3">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="fieldHolder">
                                    <label class="labelCls">Email: </label>
                                    <input type="text" name="email" appBlockCopyPaste class="inputCls" formControlName = "email" maxlength="50" [ngClass]="{ 'is-invalid':submitted && f.email.errors}">
                                    
                                    

                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                      <div *ngIf="f.email.errors?.pattern">Please enter valid Email</div>
                                    </div>

                              </div> 
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="fieldHolder">
                                  <label class="labelCls">Company/Proprietor/Owner/Partnership: <span class="mandatoryCls">*</span></label>
                                  <select class="selectCls" formControlName="companyOrPropritor" required
                                  [ngClass]="{ 'is-invalid':submitted && (f.companyOrPropritor.errors || f.companyOrPropritor.value === 'Select')}">
                                  <option [ngValue]="null" disabled>Select</option>
                                  <option value="Company or Firm">Company or Firm</option>
                                  <option value="Proprietor or Owner">Proprietor or Owner</option>
                                  <option value="Partnership">Partnership</option>
                                
                                  <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                </select>
                                    <div *ngIf="submitted && f.companyOrPropritor.errors" class="selectError">
                                      <div *ngIf="f.companyOrPropritor.errors?.required">Please select Company/Proprietor/Owner/Partnership</div>
                                    </div>
                                </div>                     
                              </div>
                              </div>
                          
                            
                            
                                  <div class="row mt-3 mb-3">
                                    <div class="col-5 mt-3">
                                      <label class="labelCls">Date of Commencement of Business/work/construction/activity: <span class="mandatoryCls">*</span></label>
                                    </div>
                                    <div class="col-4">
                                      <!-- <input type="text" name="dateOfCommencement" appBlockCopyPaste class="inputCls" formControlName = "dateOfCommencement"> -->
                                      <div class="input-group">
                                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                                name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate" 
                                                [maxDate]="maxDate" required formControlName="dateOfCommencement"  required
                                                [ngClass]="{ 'is-invalid':submitted && f.dateOfCommencement?.errors }" >
                                        <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.dateOfCommencement?.errors" class="selectError">
                                      <div *ngIf="f.dateOfCommencement?.errors?.required || f.dateOfCommencement?.invalid">Please select Date of Commencement of Business/work/construction/activity</div>
                                  </div>
                                    </div>
                                      <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                    
                                </div> 
                              
                                <div class="row labelCls ml-3" style="color:red">Fresh Application Registration Valid Date is calculated for three financial years based on the Date of Commencement of Business/work/construction/activity</div>
                                  <div class="row mt-4">
                                    <div class="col-5">
                                    <label class="labelCls">Date of Completion of work/construction/activity(if applicable): </label>
                                  </div>
                                  <div class="col-4">
                                    <!-- <input type="text" name="dateOfCompletion" appBlockCopyPaste class="inputCls" formControlName = "dateOfCompletion">  -->
                                
                                    <div class="input-group">
                                      <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                              name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" 
                                              [maxDate]="maxDate"  formControlName="dateOfCompletion"  
                                              [ngClass]="{ 'is-invalid':submitted && f.dateOfCompletion?.errors }" >
                                      <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                                      </div>
                                  </div>
                                
                                  <!--<div *ngIf="submitted && f.dateOfCompletion?.errors" class="selectError">
                                    <div *ngIf="f.dateOfCompletion?.errors?.required || f.dateOfCompletion?.invalid">Please select Date of Completion of work/construction/activity</div>
                                </div> -->
                                </div>
                                  
                                  </div>                     
                              
                              
                            
                                  <hr class="hrCls mt-4">
                                <div class="subHeadingCls mb-3">Employer,Managing partner or Managing Director as the case may be :</div>
            
                              <div class="row mt-3 ">
                              
                              <div class="col-lg-2"><label class="labelCls">State: <span class="mandatoryCls">*</span></label></div>
                                <div class="col-lg-2">
                                  <select class="selectCls" formControlName="employerState" required
                                  (change)="onChangeOfState()"
                                  [ngClass]="{ 'is-invalid':submitted && (f.employerState.errors || f.employerState.value === 'Select')}">
                                  <option [ngValue]="null" disabled>Select</option>
                                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                                  <option value="Other State">Other State</option>

                                  <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                </select>
                                <div *ngIf="submitted && f.employerState.errors" class="selectError">
                                  <div *ngIf="f.employerState.errors?.required">Please select State</div>
                                </div>
                                </div>
                              
                                <div class="col-lg-3"><label class="labelCls">Employer's Aadhar Number : <span class="mandatoryCls">*</span></label></div>
                                <div class="col-lg-2">
                                  <input type="text" name="employersAadhar" appBlockCopyPaste class="inputCls" formControlName = "employersAadhar"  numbersOnly maxlength="12" minlength="12" required [ngClass]="{ 'is-invalid':submitted && f.employersAadhar.errors}">
                                  <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                
                                  <div *ngIf="submitted && f.employersAadhar.errors" class="invalid-feedback">
                                      <div *ngIf="f.employersAadhar.errors?.required || f.employersAadhar.errors.whiteSpaceValidate">Please enter Employer's Aadhar Number</div>
                                      <div *ngIf="f.employersAadhar.errors.aadharValidate || f.employersAadhar.errors.minlength || f.employersAadhar.errors.maxlength">Please enter valid Aadhaar Number</div>
                                    </div>  
                                </div>
                              
                              
                              </div>
                              <div class="row mt-3">
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <div class="fieldHolder">
                                  <label class="labelCls">Employer's Name: <span class="mandatoryCls">*</span></label>
                                  <input type="text" name="emplyersName" appBlockCopyPaste class="inputCls" formControlName = "emplyersName"  (keypress)="keyPressAlpha($event)" maxlength ="70" required [ngClass]="{ 'is-invalid':submitted && f.emplyersName.errors}">
                                  <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                
                                  <div *ngIf="submitted && f.emplyersName.errors" class="invalid-feedback">
                                      <div *ngIf="f.emplyersName.errors?.required">Please enter Employer's Name</div>
                                    </div>                                     
                            </div> 
                            </div>

                            
                          
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                              <div class="fieldHolder">
                                <label class="labelCls">Father/Husband's Name: <span class="mandatoryCls">*</span></label>
                                <input type="text" name="fatherOrHusbandName" appBlockCopyPaste class="inputCls" formControlName = "fatherOrHusbandName"  (keypress)="keyPressAlpha($event)"   maxlength ="70" required [ngClass]="{ 'is-invalid':submitted && f.fatherOrHusbandName.errors}">
                                  <div *ngIf="submitted && f.fatherOrHusbandName.errors" class="invalid-feedback">
                                    <div *ngIf="f.fatherOrHusbandName.errors?.required">Please enter Father/Husband's Name</div>
                                  </div>
                              </div>                     
                            </div>
                            </div>

                            <div class="row mt-3" *ngIf="isOtherAddress === true">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <div class="fieldHolder">
                                <label class="labelCls">Address: <span class="mandatoryCls">*(This field is mandatory in case of Other State)</span></label>
                                <input type="text" name="otherAddress" appBlockCopyPaste class="inputCls" formControlName = "otherAddress"   maxlength ="70" required [ngClass]="{ 'is-invalid':submitted && f.otherAddress.errors}">
                                <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                              
                                <div *ngIf="submitted && f.otherAddress.errors" class="invalid-feedback">
                                    <div *ngIf="f.otherAddress.errors?.required">Please enter Address</div>
                                  </div>                                     
                              </div> 
                            </div>
                          </div>
                        
                            <div class="row mt-3" *ngIf="isAPAddress === true">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="fieldHolder">
                                    <label class="labelCls">District: <span class="mandatoryCls">*</span></label>
                                    <select class="selectCls" formControlName="employerDistrict" required (change)="onDistrictChange('employer', 'employerDistrict', $event.target)"
                                    [ngClass]="{ 'is-invalid':submitted && (f.employerDistrict.errors || f.employerDistrict.value === 'Select')}">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let item of districts" [ngValue]="item"> {{item.name}}</option>
                                  </select>
                                    <div *ngIf="submitted && f.employerDistrict.errors" class="selectError">
                                        <div *ngIf="f.employerDistrict.errors?.required">Please enter District</div>
                                      </div>
                                    
                              </div> 
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="fieldHolder">
                                  <label class="labelCls">Mandal: <span class="mandatoryCls">*</span></label>
                                  <select class="selectCls" formControlName="employerMandal" required (change)="onMandalChange('employer', 'employerMandal', $event.target)"
                                  [ngClass]="{ 'is-invalid':submitted && (f.employerMandal.errors || f.employerMandal.value === 'Select')}">
                                  <option [ngValue]="null" disabled>Select</option>
                                  <option *ngFor="let item of empMandals" [ngValue]="item"> {{item.name}}</option>
                                </select>
                                    <div *ngIf="submitted && f.employerMandal.errors" class="selectError">
                                      <div *ngIf="f.employerMandal.errors?.required">Please select Mandal</div>
                                    </div>
                                </div>                     
                              </div>
                              </div>
                        
                              <div class="row mt-3" *ngIf="isAPAddress === true">
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="fieldHolder">
                                      <label class="labelCls">Village Secretariat/Ward Secretariat: <span class="mandatoryCls">*</span></label>
                                      <select class="selectCls" formControlName="employerVillage" 
                                      [ngClass]="{ 'is-invalid':submitted && (f.employerVillage.errors || f.employerVillage.value === 'Select')}">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let item of empVillages" [ngValue]="item"> {{item.name}}</option>
                                    </select>
                                      <!-- <div *ngIf="submitted && f.employerDistrict.errors" class="selectError">
                                          <div *ngIf="f.employerDistrict.errors?.required">Please enter District</div>
                                        </div> -->
                                      
                                </div> 
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="fieldHolder">
                                    <label class="labelCls">Pincode: </label>
                                    <input type="text" name="employerPincode" appBlockCopyPaste class="inputCls" formControlName = "employerPincode"  numbersOnly maxlength ="6" [ngClass]="{ 'is-invalid':submitted && f.employerPincode.errors}">
                                    <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                  
                                      <!-- <div *ngIf="submitted && f.district.errors" class="selectError">
                                        <div *ngIf="f.district.errors?.required">Please select Mandal</div>
                                      </div> -->
                                  </div>                     
                                </div>
                                </div>
                          
                                
                              <div class="row mt-3">
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="fieldHolder">
                                  <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                                  <input type="text" name="employerMobile" appBlockCopyPaste class="inputCls" required formControlName = "employerMobile"  numbersOnly maxlength ="10" [ngClass]="{ 'is-invalid':submitted && f.employerMobile.errors}">
                                  <!-- <option *ngFor="let item of highestEdu" [ngValue]="item.entityCode"> {{item.name}}</option> -->
                                
                                    <div *ngIf="submitted && f.employerMobile.errors" class="invalid-feedback">
                                      <div *ngIf="f.employerMobile.errors?.required">Please enter Mobile No</div>
                                    </div>
                                </div>                     
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="fieldHolder">
                                      <label class="labelCls">Designation: <span class="mandatoryCls">*</span></label>
                                      <select class="selectCls" formControlName="employerDisgnation"  required
                                      [ngClass]="{ 'is-invalid':submitted && (f.employerDisgnation.errors || f.employerDisgnation.value === 'Select')}">
                                      <option [ngValue]="null" disabled>Select</option>
                                      <option *ngFor="let item of designations" [ngValue]="item.name"> {{item.name}}</option>
                                    </select>
                                      <div *ngIf="submitted && f.employerDisgnation.errors" class="selectError">
                                          <div *ngIf="f.employerDisgnation.errors?.required">Please select Designation</div>
                                        </div>
                                      
                                </div> 
                                </div>
                                
                                </div>
                          
                                <!-- <div class="col-sm-9">
                                <input class="form-check-input " type="checkbox" formControlName = "presentAddressisSame"   id="presentAddressisSame" [ngClass]="{ 'is-invalid':submitted && f.presentAddressisSame.errors }">
                                <label class="labelCls" for="consent"><b>Please Select If Present Address is same as Employer,Managing partner or Managing Director<span
                                  class="mandatoryCls">*</span></b> </label>
                                <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                                  <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                                </div>
                              </div> -->
                              <hr class="hrCls mt-4">
                              
                              <div class="subHeadingCls mb-3">Applicant Details :</div>  
                              <div class="row">
                                <div class="col-3">
                                  <label class="labelCls">Relationship: <span class="mandatoryCls">*</span></label>
                                </div>
                                <div class="col-3">
                                  <select class="selectCls" formControlName="relationShip"  required
                                  [ngClass]="{ 'is-invalid':submitted && (f.relationShip.errors || f.relationShip.value === 'Select')}">
                                  <option [ngValue]="null" disabled>Select</option>
                                  <option value="Father">Father</option>
                                  <option value="Mother">Mother</option>
                                  <option value="Brother">Brother</option>
                                  <option value="Sister">Sister</option>
                                  <option value="Self">Self</option>
                                  <option value="Employee">Employee</option>
                                  <option value="Others">Others</option>
                                </select>

                                <div *ngIf="submitted && f.relationShip.errors" class="selectError">
                                  <div *ngIf="f.relationShip.errors?.required">Please select RelationShip</div>
                                </div>
                                </div>

                              </div>         


                              <hr class="hrCls mt-4">

        <div class="subHeadingCls mb-3">Document List :</div>
      <div class="note">(Note: All Upload Documents should be in PDF Format Only and size should be less than 1 mb)</div>


      <div class="row">
      <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
          

        </div>                     
      </div> -->
      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="checkBoxCont">
        <label class="labelCls">Employer Photo with Signature(Supported docs are .jpg, .jpeg & .png) : <span class="mandatoryCls">*</span></label>
      </div>
      <div class="input-group mb-3">
        <div class="custom-file">
            <input type="file" class="custom-file-input" name="empPhoto"  required formControlName = "empPhoto" [ngClass]="{ 'is-invalid':submitted && f.empPhoto.errors }" 
            (change) = "onFileSelected($event, 'empPhoto', 'empPhotoLabel')"accept=".jpg, .jpeg , .png">
            <label class="custom-file-label" #empPhotoLabel for="empPhoto"><i class="fas fa-search"></i>No File choosen</label>
        </div>
        <div class="clearCls"  (click)="clearFileUpload('empPhoto', 'empPhotoLabel')" *ngIf="f.empPhoto.value"></div>
      </div>
      <div *ngIf="submitted && f.empPhoto.errors" class="selectError">
        <div *ngIf="f.empPhoto.errors.required">Please upload Employer Photo with Signature </div>
        <div *ngIf="f.empPhoto.errors?.inValidExt">Please upload the file in Correct Format</div>
        <div *ngIf="f.empPhoto.errors?.inValidSize && !f.empPhoto.errors.inValidExt">File size exceeding 1 mb</div>  
      </div>  
    </div>
      
  
    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="checkBoxCont">
        <label class="labelCls">PAN No</label>
      </div>
      <div class="input-group mb-3">
        <div class="custom-file">
            <input type="file" class="custom-file-input" name="panNo"  formControlName = "panNo" [ngClass]="{ 'is-invalid':submitted && f.panNo.errors }" 
            (change) = "onFileSelected($event, 'panNo', 'panNoLabel')"accept=".pdf">
            <label class="custom-file-label" #panNoLabel for="panNo"><i class="fas fa-search"></i>No File choosen</label>
        </div>
        <div class="clearCls" (click)="clearFileUpload('panNo', 'panNoLabel')"  *ngIf="f.panNo.value"></div>
      </div>
      <div *ngIf="submitted && f.panNo.errors" class="selectError">
        <div *ngIf="f.panNo.errors?.inValidExt">Please upload the file in PDF Format</div>
        <div *ngIf="f.panNo.errors?.inValidSize && !f.panNo.errors.inValidExt">File size exceeding 1 mb</div>  
      </div>  
    </div>


    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="checkBoxCont">
        <label class="labelCls">AADHAR No :<span class="mandatoryCls">*</span></label>
      </div>
      <div class="input-group mb-3">
        <div class="custom-file">
            <input type="file" class="custom-file-input" name="aadharNo" required  formControlName = "aadharNo" [ngClass]="{ 'is-invalid':submitted && f.aadharNo.errors }" 
            (change) = "onFileSelected($event, 'aadharNo', 'aadharNoLabel')"accept=".pdf">
            <label class="custom-file-label" #aadharNoLabel for="aadharNo"><i class="fas fa-search"></i>No File choosen</label>
        </div>
        <div class="clearCls" (click)="clearFileUpload('aadharNo', 'aadharNoLabel')"  *ngIf="f.aadharNo.value"></div>
      </div>
      <div *ngIf="submitted && f.aadharNo.errors" class="selectError">
        <div *ngIf="f.aadharNo.errors.required">Please upload Aadhar </div>
        <div *ngIf="f.aadharNo.errors?.inValidExt">Please upload the file in PDF Format</div>
        <div *ngIf="f.aadharNo.errors?.inValidSize && !f.aadharNo.errors.inValidExt">File size exceeding 1 mb</div>  
      </div>  
    </div>

    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="checkBoxCont">
        <label class="labelCls">VAT No</label>
      </div>
      <div class="input-group mb-3">
        <div class="custom-file">
            <input type="file" class="custom-file-input" name="vatNo"  formControlName = "vatNo" [ngClass]="{ 'is-invalid':submitted && f.vatNo.errors }" 
            (change) = "onFileSelected($event, 'vatNo', 'vatNoLabel')"accept=".pdf">
            <label class="custom-file-label" #vatNoLabel for="vatNo"><i class="fas fa-search"></i>No File choosen</label>
        </div>
        
        <div class="clearCls" (click)="clearFileUpload('vatNo', 'vatNoLabel')" *ngIf="f.vatNo.value"></div>
      </div>
      <div *ngIf="submitted && f.vatNo.errors" class="selectError">
        <div *ngIf="f.vatNo.errors?.inValidExt">Please upload the file in PDF Format</div>
        <div *ngIf="f.vatNo.errors?.inValidSize && !f.vatNo.errors.inValidExt">File size exceeding 1 mb</div>  
      </div>  
    </div>


    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="checkBoxCont">
        <label class="labelCls">GST No</label>
      </div>
      <div class="input-group mb-3">
        <div class="custom-file">
            <input type="file" class="custom-file-input" name="gstNo"  formControlName = "gstNo" [ngClass]="{ 'is-invalid':submitted && f.gstNo.errors }" 
            (change) = "onFileSelected($event, 'gstNo', 'gstNoLabel')"accept=".pdf">
            <label class="custom-file-label" #gstNoLabel for="gstNo"><i class="fas fa-search"></i>No File choosen</label>
        </div>
        <div class="clearCls"  (click)="clearFileUpload('gstNo', 'gstNoLabel')" *ngIf="f.gstNo.value"></div>
      </div>
      <div *ngIf="submitted && f.gstNo.errors" class="selectError">
        <div *ngIf="f.gstNo.errors?.inValidExt">Please upload the file in PDF Format</div>
        <div *ngIf="f.gstNo.errors?.inValidSize && !f.gstNo.errors.inValidExt">File size exceeding 1 mb</div>  
      </div>  
    </div>

    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="checkBoxCont">
        <label class="labelCls">Authorization Letter given by the employer Or self Authorization: <span class="mandatoryCls">*</span></label>
      </div>
      <div class="input-group mb-3">
        <div class="custom-file">
            <input type="file" class="custom-file-input" name="Authorization" required formControlName = "Authorization" [ngClass]="{ 'is-invalid':submitted && f.Authorization.errors }" 
            (change) = "onFileSelected($event, 'Authorization', 'AuthorizationLabel')"accept=".pdf">
            <label class="custom-file-label" #AuthorizationLabel for="Authorization"><i class="fas fa-search"></i>No File choosen</label>
        </div>
        <div class="clearCls"   (click)="clearFileUpload('Authorization', 'AuthorizationLabel')" *ngIf="f.Authorization.value"></div>
      </div>
      <div *ngIf="submitted && f.Authorization.errors" class="selectError">
        <div *ngIf="f.Authorization.errors.required">Please upload Authorization Letter given by the employer Or self Authorization </div>
        <div *ngIf="f.Authorization.errors?.inValidExt">Please upload the file in PDF Format</div>
        <div *ngIf="f.Authorization.errors?.inValidSize && !f.Authorization.errors.inValidExt">File size exceeding 1 mb</div>  
      </div>  
    </div>

    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="checkBoxCont">
        <label class="labelCls">Uploads establishment address copy:<span class="mandatoryCls">*</span></label>
      </div>
      <div class="input-group mb-3">
        <div class="custom-file">
            <input type="file" class="custom-file-input" name="uploadEstablishment"  formControlName = "uploadEstablishment" [ngClass]="{ 'is-invalid':submitted && f.uploadEstablishment.errors }" 
            (change) = "onFileSelected($event, 'uploadEstablishment', 'uploadEstablishmentLabel')"accept=".pdf">
            <label class="custom-file-label" #uploadEstablishmentLabel for="uploadEstablishment"><i class="fas fa-search"></i>No File choosen</label>
        </div>
        <div class="clearCls"   (click)="clearFileUpload('uploadEstablishment', 'uploadEstablishmentLabel')" *ngIf="f.uploadEstablishment.value"></div>
      </div>
      <div *ngIf="submitted && f.uploadEstablishment.errors" class="selectError">
        <div *ngIf="f.uploadEstablishment.errors.required">Please upload Uploads establishment address copy </div>
        <div *ngIf="f.uploadEstablishment.errors?.inValidExt">Please upload the file in PDF Format</div>
        <div *ngIf="f.uploadEstablishment.errors?.inValidSize && !f.uploadEstablishment.errors.inValidExt">File size exceeding 1 mb</div>  
      </div>  
    </div>
      
      </div>


      <div class="subHeadingCls mb-3">Applicant Declaration :</div>
      
      <div class="row mb-3 ml-3">
    
        <input class="form-check-input " type="checkbox" formControlName = "checkDeclaration"  required  id="checkDeclaration" [ngClass]="{ 'is-invalid':submitted && f.checkDeclaration.errors }">
        <label class="labelCls" for="consent"><b> I/we hereby declare that I/we have complied with all relevant provisions of the Labour Acts applicable to the establishment.In case the information furnished above is found to be false, mirepresented or suppresed any material information or evaded to furnish the information, I/we are liable for prosecution as per law besides cancellation of the registration/license granted.<span
          class="mandatoryCls">*</span></b> </label>
        <div *ngIf="submitted && f.checkDeclaration.errors" class="invalid-feedback">
          <div *ngIf="f.checkDeclaration.errors?.required">Please Check Application Declaration</div>
      </div>
      </div>

      <!-- <div class="row">
        <div class="col-lg-2">
            <label class="labelCls">Application Date:</label>
                                                </div>
                                                <div>
            <div class="input-group col-lg-12" style="margin-left: -36%;">
              <input class="calendarCls form-control" placeholder="dd/mm/yyyy" formControlName="appDate" [attr.disabled]= "true">
              </div>
    </div>

      
    </div> -->


    



                              
                            
                            
                </div> 
              </ng-container>  
              
              <div *ngIf="showForm === true || isInfoPrefilled == true" class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="submit()">Show Payment</button>
              </div> 
            </ng-template>            
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
      </form>
    </div>

  </div>
  <ng-template #showPopuphtml let-modal class="modalCls btnHide">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">PLEASE APPLY VIA SINGLE DESK PORTAL</h4>

      

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span >×</span>
        </button>
    </div>

    <div class="modal-body" >
     <a target="_blank" href="http://www.apindustries.gov.in/APIndus/userinterface/singlewindowservicesApplication/Loginportal/Introduction.aspx">http://www.apindustries.gov.in/APIndus/userinterface/singlewindowservicesApplication/Loginportal/Introduction.aspx</a>

        

      
            
    
    </div>

    <div class="modal-footer">
         </div>
</ng-template>
  
  