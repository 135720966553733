import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbNavChangeEvent, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/shared/common.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonFormComponent } from 'src/shared/common-form/common-form.component';
import { fileExtensionValidator } from 'src/shared/validators/file-validator';
import { fileSizeValidator } from 'src/shared/validators/file-validator';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-social-audit-grievance',
  templateUrl: './social-audit-grievance.component.html',
  styleUrls: ['./social-audit-grievance.component.css']
})
export class SocialAuditGrievanceComponent implements OnInit {

  socialAuditGrievanceForm : UntypedFormGroup;
  dropdownSettings:IDropdownSettings={};
  commonConstants : any = CommonConstants;
  otherGrievanceList: any = [];
  selectedItems: any = [];
  public commonFormSubmitted: boolean = false;
  submitted: boolean = false;
  isPrefillClicked: boolean = false;
  allowApplication: boolean = false;
  disableCaste: boolean = true;
  disableOther: boolean = false;
  citizenSurveyData: any;
  loggedInSecretariatCode: any;
  @ViewChild('nav') nav: any;
  @ViewChild(CommonFormComponent) childComp: any;
  @ViewChild('grievanceCopyLabel')
  grievanceCopyLabel!: ElementRef;
  fileList : { FileName: string, DocumentType: string, BlobRef : string }[] = [];

  constructor(
    private config: NgbNavConfig,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.socialAuditGrievanceForm = this.formBuilder.group({
      commonForm: [],
      grievanceRaisedBy: [null, [Validators.required]],
      grievanceType: [null, [Validators.required]],
      otherGrievance: [[]],
      grievanceCopy: [''],
      consent: ['',Validators.required],
      consent2: ['',Validators.required],
    });
    this.loggedInSecretariatCode = this.commonService.getLocalStorage('locationCode');
    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'value',
      textField: 'label',
      enableCheckAll: false,
      allowSearchFilter: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All"
    };
  }

  get f(){ return this.socialAuditGrievanceForm.controls; }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    if (!this.f.commonForm.valid) {
      this.commonService.commonErrorPopup('Alert', 'Please enter all the mandatory fields', 'md');
      changeEvent.preventDefault();
    } else if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    } else if (changeEvent.nextId === 2 && this.f.commonForm.valid && !this.isPrefillClicked) {
      this.commonService.commonErrorPopup('Alert', 'Please click on Prefill to fetch citizen data', 'md');
      changeEvent.preventDefault();
    } else if (!this.allowApplication) {
      this.commonService.commonErrorPopup('Alert', 'Applicant has to raise grievance at his/her respective secretariat only', 'md');
      changeEvent.preventDefault();
    }
  }

  goToNextTab() {
    this.commonFormSubmitted = true;
    if (!this.f.commonForm.valid) {
      this.commonService.commonErrorPopup('Alert', 'Please enter all the mandatory fields', 'md');
    } else if (!this.isPrefillClicked) {
      this.commonService.commonErrorPopup('Alert', 'Please click on Prefill to fetch citizen data', 'md');
    } else if (!this.allowApplication) {
      this.commonService.commonErrorPopup('Alert', 'Applicant has to raise grievance at his/her respective secretariat only', 'md');
    } else if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }

  resetForm() {
    this.isPrefillClicked = false;
    setTimeout(() => {
      this.childComp.resetForm();
    }, 1000);
  }

  checkDataValidation() {
    this.isPrefillClicked = true;
    let postData = {
      citizen_UID: this.f['commonForm'].value['aadharNo']
    }
    this.commonService.postRequest(this.commonConstants.getCasteSurveyData, postData, true).subscribe({
      next: (responseData: any) => {
        if (responseData.result?.error) {
          this.allowApplication = false;
          this.commonService.commonErrorPopup('Alert', 'Survey data is not available' , 'md');
          this.resetForm();
        } else if (responseData && responseData.result?.surveyData) {
          this.citizenSurveyData = responseData.result.surveyData;
          let postData = {
            citizen_UID: this.f['commonForm'].value['aadharNo']
          }
          this.commonService.postRequest(this.commonConstants.getReqtypesforGrievance, postData, true).subscribe({
            next: (resData: any) => {
              if (resData && resData?.result?.result) {
                let reqTypes = ["GrievanceCaste", "GrievanceOthers"];
                if (this.stopGrievanceFiling(reqTypes, resData?.result?.result)) {
                  this.disableCaste = true;
                  this.disableOther = true;
                  this.allowApplication = false;
                  this.commonService.commonErrorPopup('Alert', 'Applicant has already raised all types of grievance request', 'md');
                  this.resetForm();
                } else {
                  this.disableCaste = resData?.result?.result.indexOf('GrievanceCaste') != -1;
                  this.disableOther = resData?.result?.result.indexOf('GrievanceOthers') != -1;
                  if (this.loggedInSecretariatCode == this.citizenSurveyData.secretariaT_CODE) {
                    this.allowApplication = true;
                  } else if (this.loggedInSecretariatCode != this.citizenSurveyData.secretariaT_CODE) {
                    this.allowApplication = false;
                    this.commonService.commonErrorPopup('Alert', 'Applicant has to raise grievance at his/her respective secretariat only', 'md');
                    this.resetForm();
                  }
                }
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
          });
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  stopGrievanceFiling(array1: any, array2: any) {
    if (array1.length !== array2.length) {
      return false;
    }
    const sortedArray1 = array1.slice().sort((a: any, b: any) => a.toLowerCase().localeCompare(b.toLocaleLowerCase()));
    const sortedArray2 = array2.slice().sort((a: any, b: any) => a.toLowerCase().localeCompare(b.toLowerCase()));
    for (let i = 0; i< sortedArray1.length; i++) {
      if (sortedArray1[i].toLocaleLowerCase() !== sortedArray2[i].toLocaleLowerCase()) {
        return false;
      }
    }
    return true;
  }

  onGrievanceTypeChange() {
    if (this.f.grievanceType.value == 'casteSubcaste') {
      this.f.otherGrievance.patchValue([]);
      this.f.otherGrievance.clearValidators();
      this.f.otherGrievance.updateValueAndValidity();
      this.selectedItems = [];
      this.otherGrievanceList = [];
    } else {
      this.getOtherGrievanceList();
      this.f.otherGrievance.setValidators([Validators.required]);
      this.f.otherGrievance.updateValueAndValidity();
    }
  }

  getOtherGrievanceList() {
    this.commonService.getRequest(this.commonConstants.getByParentId + 'Caste Survey Greviance&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        let temp: any = [];
        let data = responseData?.result;
        data?.forEach((item: any) => {
          if (item !== '' && item !== null) {
            temp.push({ value: item.id, label: item.name });
          }
        });
        this.otherGrievanceList = this.commonService.sortItems(temp, 'label');
        this.otherGrievanceList.forEach((ele: any) => {
          if (this.citizenSurveyData.defecationstatus == '' && ele.label == 'Access to Toilet facility') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.waterresource == '' && ele.label == 'Access to clean drinking water') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.gasconnection == '' && ele.label == 'Access to cooking gas') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.agricultureland == '' && ele.label == 'Agricultural land owned') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.qualification == '' && ele.label == 'Educational Qualification') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.maritalstatus == '' && ele.label == 'Marital Status') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.occupation == '' && ele.label == 'Profession') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.religion == '' && ele.label == 'Religion') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } if (this.citizenSurveyData.residentalland == '' && ele.label == 'Residential land owned') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          } else if (this.citizenSurveyData.housetype == '' && ele.label == 'Type of House') {
            ele.isDisabled = true;
            this.selectedItems.push(ele);
          }
        });
        this.f.otherGrievance.patchValue(this.selectedItems);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  onGrievanceCopySelected(fileEvent: any) {
    const grievanceCopyFile: File | null = fileEvent.target.files[0];
    this.grievanceCopyLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.socialAuditGrievanceForm.controls['grievanceCopy'].setValidators([
      fileExtensionValidator('pdf,jpg,jpeg'), fileSizeValidator(fileEvent)
    ])
    this.socialAuditGrievanceForm.controls['grievanceCopy'].updateValueAndValidity();
    if (this.f.grievanceCopy.valid)
      this.fileUpload(grievanceCopyFile, this.f['grievanceCopy'].value, fileEvent.target.files[0].name, 'Grievance Copy', 'grievanceCopy', 'grievanceCopyLabel');
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => { 
        if(typeof(responseData.result) !== 'string'){
        let index = this.fileList.map((item) => item.DocumentType).indexOf(documentType);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
        this.fileList.push({
          FileName: fileName,
          DocumentType: documentType,
          BlobRef : responseData['result'].blobRef
        })
      } else {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
      }
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }

  clearFileUpload(name:string, label:string) {
    this.f[name].reset();
    if (label === 'grievanceCopyLabel') {
      this.grievanceCopyLabel.nativeElement.innerText = 'No File Choosen';
      this.socialAuditGrievanceForm.controls[name].clearValidators();
      this.socialAuditGrievanceForm.controls[name].updateValueAndValidity();
    }
  }

  submit(): any  {
    this.submitted = true;
    if (!this.socialAuditGrievanceForm.valid) {
      return false;
    } else if (!this.allowApplication) {
      this.commonService.commonErrorPopup('Alert', 'Applicant doesnot belong to SC caste or grievance has to raised from his secretariat', 'md');
      return false;
    } else {
      let dateFormat =  this.commonService.formatDate(this.f['commonForm'].value['dob']);
      let otherGrievanceData = this.f.otherGrievance.value.filter((ele: any) => delete ele.isDisabled);
      let postData = {
        requisitionHeader: {
          requisitionType: this.f.grievanceType.value == 'casteSubcaste' ? "GrievanceCaste" : 'GrievanceOthers',
          department: "RV",
          applicantInfo: {
            id:"", 
            aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo'].trim(), //CryptoJS.AES.encrypt(this.f['commonForm'].value['aadharNo'].trim(), this.commonConstants.encryptDecryptPassword.trim()).toString(), //this.f['commonForm'].value['aadharNo'],
            personNames: [
              {
                languageCode: "en-in",
                firstName: this.f['commonForm'].value['firstName'],
                middleName: this.f['commonForm'].value['middleName'] ,
                lastName: this.f['commonForm'].value['lastName'],
                fatherHusbandName:this.f['commonForm'].value['fatherHusName'],
              }
            ],
            gender: this.f['commonForm'].value['gender'],
            birthDate: dateFormat,
            caste: this.f['commonForm'].value['caste'].name,
            religion: this.f['commonForm'].value['religion'].name,
            qualification: this.f['commonForm'].value['qualification'].name,
            maritalStatus: this.f['commonForm'].value['maritalStatus'],
            addresses: [
              {
                addressType:"present",
                doorNo: this.f['commonForm'].value['preDoorNo'],
                streetName:this.f['commonForm'].value['preStreet'],
                villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                district: this.f['commonForm'].value['preDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['prePinCode'],
                // isRevenueLocation: true,
                postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !==  null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
                // prePostalOffice
              },
              {
                addressType:"permanent",
                doorNo: this.f['commonForm'].value['permDoorNo'],
                streetName:this.f['commonForm'].value['permStreet'],
                villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                district: this.f['commonForm'].value['permDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['permPinCode'],
                postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
              }
            ],
            rationCardNo: '',
            deliveryType: '',
            emailAddress: this.f['commonForm'].value['email'],
            mobileNumber: this.f['commonForm'].value['mobileNo'],
            isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
            isDataFromAadhar: ''
          },
          remarks: '',
        },
        requisitionData: {
          grievanceRaisedBy: this.f.grievanceRaisedBy.value,
          citizenDetails: {
            aadhar: this.commonService.RSAEncrypt(this.citizenSurveyData?.uiD_NUM),
            name: this.citizenSurveyData?.citizeN_NAME,
            gender: this.citizenSurveyData?.gender,
            dateOfBirth: this.citizenSurveyData?.doB_DT,
            age: this.citizenSurveyData?.age,
            maritalStatus: this.citizenSurveyData?.maritalstatus,
            caste: this.citizenSurveyData?.caste,
            subcaste: this.citizenSurveyData?.subcaste,
            religion: this.citizenSurveyData?.religion,
            typeOfHouse: this.citizenSurveyData?.housetype,
            accessToToiletFacility: this.citizenSurveyData?.defecationstatus,
            accessToCleanDrinkingWater: this.citizenSurveyData?.waterresource,
            accessToCookingGas: this.citizenSurveyData?.gasconnection,
            educationalQualification: this.citizenSurveyData?.qualification,
            profession: this.citizenSurveyData?.occupation,
            agriculturallandOwned: this.citizenSurveyData?.agricultureland,
            residentailLandOwned: this.citizenSurveyData?.residentalland,
            mobileNumber: this.citizenSurveyData?.mobilE_NUMBER,
            ruralUrbanFlag: this.citizenSurveyData?.ruraL_URBAN_FLAG,
            districtCode: this.citizenSurveyData?.lgD_DIST_CODE,
            districtName: this.citizenSurveyData?.districT_NAME,
            mandalCode: this.citizenSurveyData?.lgD_MANDAL_CODE,
            mandalName: this.citizenSurveyData?.mandaL_NAME,
            secretariatCode: this.citizenSurveyData?.secretariaT_CODE,
            secretariatName: this.citizenSurveyData?.secretariaT_NAME,
          },
          grievanceType: this.f.grievanceType.value,
          othersGrievance: this.f.grievanceType.value == 'casteSubcaste' ? null : otherGrievanceData
        }
      }
      if (this.f.grievanceType.value == 'casteSubcaste') {
        this.commonService.postRequest(this.commonConstants.saveCasteGrievanceData, postData).subscribe({
          next: (responseData: any) => {
            if (responseData) {
              this.updateFileUpload(responseData['result'].id, responseData['result'].requisitionHeader.requisitionType);
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
        });
      } else {
        this.commonService.postRequest(this.commonConstants.saveOtherGrievanceData, postData).subscribe({
          next: (responseData: any) => { 
            if (responseData) {
              this.updateFileUpload(responseData['result'].id, responseData['result'].requisitionHeader.requisitionType);
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
        });
      }
    }
  }

  updateFileUpload(id: any, type: any) {
    let fileData = {
      FileList: this.fileList,
      RequisitionId: id
    }
    this.commonService.postRequest(this.commonConstants.updateFileUpload, fileData).subscribe({
      next: (resData:any) => { 
        this.showPayment(id, type);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  showPayment(requisitionId: any, requisitionType: any) {
   const queryParams: Params = { id: requisitionId, department: 'RV', type: requisitionType};
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', 
    });
  }

}
