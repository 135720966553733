<div class="modal-header" >
    <h6 class="modal-title">{{title}}</h6>
    <!-- <button type="button" class="close" aria-label="Close"  (click)="activeModal.dismiss('Cross click')">
      <span >&times;</span>
    </button> -->
  </div>
  <div class="modal-body modelScroll">
    <p class="line-break">{{message}} <b>{{reqId}}</b></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="red-btn" (click)="activeModal.close('Close click')">{{btnText}}</button>
  </div>
 