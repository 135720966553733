import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { Router } from '@angular/router';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-jagananna-suraksha-camp-status-report',
  templateUrl: './jagananna-suraksha-camp-status-report.component.html',
  styleUrls: ['./jagananna-suraksha-camp-status-report.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class JaganannaSurakshaCampStatusReportComponent implements OnInit {
  commonConstants : any = CommonConstants;
  headerCol = [{colId:'districT_NAME', colName: 'District'},
  {colId:'mandaL_NAME', colName: 'Mandal'},
  {colId:'secretariaT_NAME', colName: 'Secretariat'},
  {colId:'requisitionid', colName: 'Requisition Id'},
  {colId:'transactionamount', colName: 'Amount'},
  {colId:'transactiondate', colName: 'Requisition Date'},
  {colId:'paymenT_STATUS', colName: 'Payment Status'},
  {colId:'challanid', colName: 'Challan Id'},
  {colId:'challaN_TYPE', colName: 'Challan Type'},
  {colId:'challaN_DATE', colName: 'Challan Date'},
  ]
  form: UntypedFormGroup;
  userInfo: any = [];
  departments: any[] = [];
  services: any[] = [];
  formatTypes: any[]= [];
  items: any[] = [];
  totalItems: any[] = [];
  beyondSLAItems: any[] = [];
  page = 1;
  popupPage = 1;
  pageSize = 10;
  format: any;
  showReports:Boolean=false;
  submitted:Boolean=false;
  noOfCol: any = 13;
  role: any;
  defaultFromDate: any;
  currentDate = new Date();
  popupPageSize = 10;
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('BEYONDSLATABLE') beyondSLATable!: ElementRef;
  toMinDate: any;
  maxDate: { year: number; month: number; day: number; };
  minDate: { year: number; month: number; day: number; };
  // maxDate:any;
  // minDate:any; 
  // Flag: any;
  // Date: any;
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService,private router: Router,private datePipe: DatePipe,private modalService: NgbModal) {
    this.form = this.formBuilder.group({
      toDate: ['', [Validators.required]],
    });
    this.maxDate = {
      year: 2024,
      month: 6,
      day: 30
    };
    this.minDate = {
      year: 2024,
      month: 1,
      day: 1  
    };
    // this.toMinDate = {
    //   year: this.currentDate.getFullYear() - 150,
    //   month: this.currentDate.getMonth() + 1,
    //   day: this.currentDate.getDate()
    // }
    // this.minDate = {"01-07-2023"};
    // this.maxDate = "31-07-2023";
    this.format = 'District Wise';
    this.formatTypes = [{name: 'District Wise', isActive: true},
                        {name:'Mandal Wise', isActive: false},
                        {name:'Secretariat Wise', isActive: false}
  ]
  let userData = this.commonService.getLocalStorage('userInfo');
  if(userData && userData !== null) {
    this.userInfo = JSON.parse(userData);
  }
}

  ngOnInit(): void {
    this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.format = 'District Wise';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village: 'All',
       RUFlag: 'All',
       toDate: this.defaultFromDate,
     });
     this.getJaganannaSurakshaCampStatusReport();
  }

  get f() { return this.form.controls; }
 
  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  // onFromDateSelect(event: any) {
  //   this.showReports = false;
  //   this.toMinDate = event;
  // }
  onToDateSelect(event: any) {
    this.showReports = false;
  }
  getFormat(): any {
      if (this.format === 'District Wise' ) {
      this.noOfCol = 12;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 13;
      return this.noOfCol;
    } else {
      this.noOfCol = this.validUsers()? 17:15;
      return this.noOfCol;
    }
  }

  downloadExcel() {
    let fileName: any = 'Camp Status Report (' + this.f.toDate.value + ')';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Camp Status Report');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  viewImgFile(blobRef: any) {
    this.commonService
      .getRequest(this.commonConstants.viewFile + blobRef)
      .subscribe({
        next: (responseData: any) => {
          let fileInfo = responseData.result.fileContents;
           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
           modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  validUsers(){
    if(this.userInfo.userPrincipalName === "12345678-DA@apgsws.onmicrosoft.com"|| this.userInfo.userPrincipalName === "cmo_apseva@apgsws.onmicrosoft.com" || this.userInfo.userPrincipalName === "com_gsws@apgsws.onmicrosoft.com"){
      return true;
    }
    else{
      return false;
    }
  }
  getJaganannaSurakshaCampStatusReport(from?: any): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } else { 
        this.page = 1;
       let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd-MM-yyyy')
       let postData: any = '?Flag='+ this.format + '&Date=' + toDate ;
        this.commonService.getRequest(this.commonConstants.GetJaganannaSurakshaCampStatusReport + postData ).subscribe({
          next: (responseData: any) => { 
            console.log(typeof(responseData?.result),'result man')
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter(item => item.districT_NAME !== 'Total')
                  this.totalItems = responseData?.result.filter((item: { districT_NAME: string; }) => item.districT_NAME === 'Total')
                  console.log(this.items,'items')
                  console.log( this.totalItems ,'total items')
            } else {
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
    }
   
    goToHome() {
      this.router.navigate(['/home']);
    }
  }
  
