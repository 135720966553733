import { AbstractControl, ValidationErrors } from '@angular/forms';

export function dobValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null; // No validation if value is empty
    }

    const dob = control.value;
    const isValidDate = validateDate(dob);
    const isValidDob = validateDob(dob);
    function validateDate(dob: string): boolean {
        const regex = '^([0]?[1-9]|[1|2][0-9]|[3][0|1])[/-]([0]?[1-9]|[1][0-2])[/-]([0-9]{4}|[0-9]{2})$';
        if (!dob.match(regex)) {
            return false;
        }

        const [day, month, year] = dob.split('-').map((num) => parseInt(num, 10));
        const date = new Date(year, month - 1, day);

        return (
            date.getDate() === day &&
            date.getMonth() === month - 1 &&
            date.getFullYear() === year
        );
    }

    function validateDob(dob: string): boolean {
        const [day, month, year] = dob.split('-').map((num) => parseInt(num, 10));
        const date = new Date(year, month - 1, day);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return today >= date && year >= 1800;
    }

    if (!isValidDate) {
        return { invalidDate: true };
    }

    if (!isValidDob) {
        return { invalidDob: true };
    }

    return null; // No errors
}
