import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-excel-report',
  templateUrl: './excel-report.component.html',
  styleUrls: ['./excel-report.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class ExcelReportComponent implements OnInit {
  commonConstants : any = CommonConstants;
  form: UntypedFormGroup;
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  maxDate: any;
  minDate: any;
  toMinDate: any;
  currentDate = new Date();
  submitted: Boolean = false;
  defaultFromDate: any;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  showReports: Boolean = false;
  totalReport: Boolean = false;
  role: any;
  @ViewChild('TABLE') table!: ElementRef;
  
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal, private currentRoute: ActivatedRoute, private router: Router)  {
    this.currentRoute.queryParams.subscribe((params) => {
      if(!params.range){
        this.totalReport = true;
      } else {
        this.totalReport = false;
      }
    });
    config.backdrop = 'static';
    config.keyboard = false;
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      format: [null],
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]]
    });
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.toMinDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    }
   }

  ngOnInit(): void {
    if (!this.commonService.getMasterData('districts'))
    this.getDistricts();
  else 
    this.districts = this.commonService.getMasterData('districts')
   this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    
    this.form.patchValue({
      district:'All',
      mandal:'All',
      village: 'All',
      format: 'State Wise',
      fromDate: this.defaultFromDate,
      toDate: this.defaultFromDate,
    });
    let userInfo = this.commonService.getLocalStorage('userInfo');
      if (userInfo && userInfo !== null) {
        let userData = JSON.parse(userInfo);
        this.role = userData.jobTitle;
      }
  }
  get f() { return this.form.controls; }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => { 
        this.districts = this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('districts', this.districts);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getMandals + this.f['district'].value.id).subscribe({
      next: (responseData: any) => { 
        this.mandals = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange() {
    this.showReports = false;
    this.villages = [];
    this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getVillages + this.f['mandal'].value.id).subscribe({
      next: (responseData: any) => { 
        this.villages = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onVillageChange() {
    this.showReports = false;
  }
  getReportData(): any {
    this.submitted = true;
    if (!this.form.valid)
      return false;
    else { 
      let diffDate = this.commonService.diffInDates(this.commonService.formatDate(this.f.fromDate.value), this.commonService.formatDate(this.f.toDate.value));
      if (diffDate && !this.totalReport) {
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = 'Please select the date range with less than or equal to 31 days.';
      } else if (this.f.format.value === 'Mandal Wise' && this.f.mandal.value === 'All'){
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = 'Please select one Mandal to get the Mandal Wise Report.';
      } else {
        this.page = 1;
        let fromDate = this.commonService.formatDate(this.f.fromDate.value)?.split('T')[0];
        let toDate = this.commonService.formatDate(this.f.toDate.value)?.split('T')[0];
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.entityCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.entityCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.entityCode;
        let format = this.f.format.value;
        let postData: any = 'FromDate='+ fromDate +'&ToDate=' +toDate + '&District=' + district +'&Mandal=' +mandal +'&Secretariat=' + village + '&format=' +format;
        this.commonService.getRequest(this.commonConstants.getReportsData + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
              this.showReports = true;
              this.items = responseData?.result;
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
      }
  }
  downloadExcel() {
    let fileName: any = 'SLA Dashboard from'+ this.f.fromDate.value +' to '+ this.f.toDate.value +')';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement);
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SLA Dashboard');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //   const EXCEL_EXTENSION = '.xlsx';
  //   const data: Blob = new Blob([buffer], {
  //     type: EXCEL_TYPE
  //   });
  //   FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  // }
  onFromDateSelect(event: any) {
    this.showReports = false;
    this.toMinDate = event;
  }
  onToDateSelect(event: any) {
    this.showReports = false;
  }
  onChangeFormat() {
    this.showReports = false;
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
}
