<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3 doNotPrint">
        <div class="breadcrumbCls" *ngIf="this.departmentCode !== 'RD'">
                GSWS / Application Submission / {{departmentName}} Department <span class="activePage">/  {{ requisitionType != 'RegistrationofTokens' ? 'Payment Receipt' :  'Acknowledgement Receipt'}}</span>
        </div>
        <div class="breadcrumbCls" *ngIf="this.departmentCode === 'RD'">
            GSWS / Application Submission / Pension Department <span class="activePage">/ Payment Receipt</span>
        </div>
    </div>
  
    <div class="mainPageContainer mr-3 mb-2">
        <div class="btnHolder" *ngIf="userInfo.jobTitle === 'Village Revenue Officer'
        || userInfo.jobTitle === 'Panchayat Secretary'
        || userInfo.jobTitle === 'Ward Admin Secretary'
        || userInfo.jobTitle === 'Welfare & Educational Assistant'
        || userInfo.jobTitle === 'Ward Revenue Secretary'
        || userInfo.jobTitle === 'Ward Welfare & Development Secretary'
        " >
            <button class="float-right greenBtnCls homebtn mr-3" 
            (click)="goToHome()">Home</button>
        </div>
        <div id="__printingFrame">


            <!-- <div class="{{ requisitionType != 'RegistrationofTokens' ? 'row mb-3 mt-3' : 'row mb-3' }}">
                <img src="{{ requisitionType != 'RegistrationofTokens' ? '../../assets/images/logo_official.png' : '../../assets/images/Ack_Receipt_header.png'}}" class="d-block mx-auto" 
                   [attr.width]="requisitionType != 'RegistrationofTokens' ? '11%' : '100%'">
            </div> -->
        


            <div class="row mb-3 mt-3">
                <img src="../../assets/images/logo_official.png" class="d-block mx-auto" width="11%"/>
            </div>
        
            <div class="subHeadingCls text-center"><b>{{ requisitionType != 'RegistrationofTokens' ? 'Payment Receipt' :  'Acknowledgement Receipt'}}</b></div>
            <div class="row">
                <!-- <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right" style="line-height: 10px;">
                    <label class="labelCls note_txt"><b>Custome Care Number : </b> <a href="tel:1902">1902</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                </div> -->

                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right" style="line-height: 10px;">
                    <label class="labelCls note_txt"><b>Date :</b> {{ dateTime | date: 'dd/MM/yyyy'}} {{ dateTime | date: 'h:mm:ss a'}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
               
                </div>
            </div>

            <hr class="hrCls">
           
            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Secretariat Name :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{(this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva') ? 'NA': secretariatName | titlecase}}</label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Secretariat ID :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ sacretaraitName }}</label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>

            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Application Number :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls" *ngIf="!((this.departmentCode ==='RV' || this.departmentCode === 'HF' ) && (this.requisitionInfo?.serviceCode ==='PrintCertificate' || this.requisitionInfo?.serviceCode === 'PrintCertificateForAarogyaSri'))">{{ requisitionInfo?.requisitionId}}</label>
                    <label class="labelCls" *ngIf="(this.departmentCode ==='RV' || this.departmentCode === 'HF' ) && (this.requisitionInfo?.serviceCode ==='PrintCertificate' || this.requisitionInfo?.serviceCode === 'PrintCertificateForAarogyaSri')">{{ requisitionInfo?.requisitionId}} </label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Transaction ID:</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls"> {{requisitionInfo?.transactionID }} </label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>

            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Service Name :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !=='RD' && !isReissuance">
                    <label class="labelCls" style="word-break: break-word;">{{ serviceName}} <span *ngIf = "hhsplitType !== null && hhsplitType !== ''">({{hhsplitType}})</span></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="isReissuance">
                    <label class="labelCls" style="word-break: break-word;">{{ reIssuanceSubservice }}</label>
                </div>
                <!-- <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RV' && this.requisitionInfo?.serviceCode ==='PrintCertificate'">
                    <label class="labelCls" style="word-break: break-word;">Print Certificate</label>
                </div> -->
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@1' || this.requisitionInfo?.serviceCode ==='Pensions@V1')">
                    <label class="labelCls" style="word-break: break-word;">Pensions O.A.P</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@2' || this.requisitionInfo?.serviceCode ==='Pensions@V2' || this.requisitionInfo?.serviceCode ==='Pensions-NEW@2' || this.requisitionInfo?.serviceCode ==='Pensions-NEW@V2')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Weavers</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@3' || this.requisitionInfo?.serviceCode ==='Pensions@V3')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Disabled</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@4' || this.requisitionInfo?.serviceCode ==='Pensions@V4')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Widow</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@5' || this.requisitionInfo?.serviceCode ==='Pensions@V5')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Toddy Tappers</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@6' || this.requisitionInfo?.serviceCode ==='Pensions@V6')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Transgender</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@7' || this.requisitionInfo?.serviceCode ==='Pensions@V7')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Fishermen</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@8' || this.requisitionInfo?.serviceCode ==='Pensions@V8')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Single Women</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@9' || this.requisitionInfo?.serviceCode ==='Pensions@V9' || this.requisitionInfo?.serviceCode ==='Pensions-NEW@9' || this.requisitionInfo?.serviceCode ==='Pensions-NEW@V9')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Traditional Cobblers</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo?.serviceCode ==='Pensions@10' || this.requisitionInfo?.serviceCode ==='Pensions@V10' || this.requisitionInfo?.serviceCode ==='Pensions-NEW@10' || this.requisitionInfo?.serviceCode ==='Pensions-NEW@V10')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Dappu Artists</label>
                </div>

                <!-- 
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Application Number :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ requisitionID}}</label>
                </div> -->

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Department Name:</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls"> {{departmentName }} Department </label>
                </div>
                

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>

          

            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b> Applicant Name : </b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ applicantName }} </label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Father/Husband Name : </b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ applicantFatherName }}</label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>


            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b> District :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{district }}</label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-1">
                    <label class="labelCls lableHead"><b> Mandal:</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{mandal }}</label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>

            <ng-container *ngIf="requisitionType != 'RegistrationofTokens'">
                <div class="row mb-3"  *ngIf="this.departmentCode !== 'RD'">
                    <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                    
                    <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label class="labelCls lableHead"><b> Amount Paid : </b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ amountToBePaid }} /-</label>
                    </div>

                    <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label class="labelCls lableHead"><b>Amount In Words :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls"> {{ amountInwords }} </label>
                    </div>

                    <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                </div>                

                <div class="row mb-3">
                    <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="this.departmentCode !== 'RD'">
                        <label class="labelCls lableHead"><b>Payment Mode :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !== 'RD'">
                        <label class="labelCls">{{paymentMode}}</label>
                    </div>
                    
                    <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label class="labelCls lableHead"><b>SLA :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls">{{ SLA }}</label>
                    </div>

            
                    <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>

                <!--    <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label class="labelCls lableHead"><b>Transaction ID:</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls"> {{transactionID }} </label>
                    </div> -->
                    
                    <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <label class="labelCls lableHead"><b>Transaction Date :</b></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <label class="labelCls"> {{ dateTime | date: 'dd/MM/yyyy'}} {{ dateTime | date: 'h:mm:ss a'}} </label>
                    </div>

                
                    <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">
                    </div>
                </div>
            </ng-container>

            <!-- watertax code -->
             <ng-container *ngIf="requisitionType == 'WaterTaxService'">
            <div class="row mb-3">

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>      

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    <label class="labelCls lableHead"><b>Khata: </b></label>

                </div>

                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">

                    <label class="labelCls"> {{waterTax[0]?.khata_Number}} </label>

                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    <label class="labelCls lableHead"><b>Khata Extent:</b></label>

                </div>

                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">

                    <label class="labelCls">{{waterTax[0]?.khata_extent}} </label>

                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">

                </div>

            </div>

 

            <div class="row mb-3">

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>              

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    <label class="labelCls lableHead"><b>Demand Notice Number: </b></label>

                </div>

                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">

                    <label class="labelCls"> {{waterTax[0]?.uniqueNumber}} </label>

                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    <label class="labelCls lableHead"><b>Upto Fasil Year:</b></label>

                </div>

                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">

                    <label class="labelCls"> 1432 </label>

                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">

                </div>

            </div>

 

            <div class="row mb-3">

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    <label class="labelCls lableHead"><b>Service Charges :</b></label>

                </div>

                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">

                    <label class="labelCls"> {{totalAmount == amountToBePaid ? 0 : totalAmount-amountToBePaid}} /-</label>

                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">

                    <label class="labelCls lableHead"><b>Total Amount :</b></label>

                </div>

                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">

                    <label class="labelCls"> {{ totalAmount }} /- </label>

                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">

                </div>
            </div>
           </ng-container>
            <div *ngIf="requisitionType == 'RegistrationofTokens'" class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Services :</b></label>
                </div>
                <div class="col col-lg-8 col-md-8 col-sm-8 col-xs-8">
                    <table *ngIf="services.length > 0" class="tableCls">
                        <thead>
                            <tr>
                                <th>Sl.No.</th>
                                <th>Department Name</th>
                                <th>Service Name</th>
                                <th>Service Token</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of services; let i = index">
                                <td> {{ i + 1 }}</td>
                                <td> {{ item.departmentName }}</td>
                                <td> {{ item.serviceName }}</td>
                                <td class="boldtext"> {{ item.tokenNo }} </td>
                            </tr>                                                
                        </tbody>
                    </table>
                    <ng-container *ngIf="services.length <= 0">
                        <label class="subHeadingCls lableHead"><b>No token is generated.</b></label>
                    </ng-container>
                </div>
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>

         <!--    <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Department Name:</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls"> {{departmentName }} Department </label>
                </div>
                
               
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">
                </div>
            </div> -->

            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-8 col-md-8 col-sm-8 col-xs-8">

                    <label class="labelCls lableHead"><b> Note :</b></label>
                                 
                <!--     <label class="labelCls note_txt">1. As per the GO. MS. NO. 173 dated 6th March, 2019, the scanning charges beyond 4 pages has been revised to Rs. 5/- for scanning and uploading documents. </label> -->
                    <label class="labelCls note_txt">1. To Know the Application status, Please visit <a href="https://vswsonline.ap.gov.in" target="_blank">https://vswsonline.ap.gov.in</a></label>

                    <label class="labelCls note_txt">2. For any query call us on : <a href="tel:1902">1902</a> </label>


                   <!--  <label class="labelCls note_txt" *ngIf="departmentCode==='CS' || departmentCode==='RD'">3. GO *** ప్రకారం గా మీ ధరకాస్తు పరిశీలించి అర్హతను నిర్దారించిన తదుపరి వచ్చే జూన్ / డిసెంబర్ లో మీకు మీ అర్హత ఆధారం గా లబ్ది అందజేయబడును.</label> -->
                    <label class="labelCls note_txt" *ngIf="departmentCode==='CS' || departmentCode==='RD'">3. GO *** ప్రకారం గా మీ దరఖాస్తు పరిశీలించి అర్హతను నిర్ధారించిన తదుపరి వచ్చే జూన్ / డిసెంబర్ లో మీకు మీ అర్హత ఆధారం గా లబ్ధి అందజేయబడును.</label>
                    
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
            </div>

            <div class="row mb-2 mt-1">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                <div class="col col-lg-10 col-md-10 col-sm-10 col-xs-10 bottomHead">
                   
                    <b>
                        Asking Bribe? - Call ACB 1064 or send a mail to complaints-acb@ap.gov.in ఎవరైనా లంచం అడిగినా అవినీతికి పాల్పడినా టోల్ ఫ్రీ నెంబర్ 1064 కు ఫిర్యాదు చేయండి లేదా మీ ఫిర్యాదులను complaints-acb@ap.gov.in కి ఈ - మెయిల్ పంపండి.
                </b>
                </div>
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>
          
        </div>
            
        <div class="row mb-3 mt-3 doNotPrint">
            <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            <div class="col col-lg-10 col-md-10 col-sm-12 col-xs-12 btnAlign text-center">
                <button class="btn btn-primary"  *ngIf="showSuccessTStatus" (click)="printReceipt();" style="float: left;">Print Receipt</button>
                <button *ngIf='requisitionTypeForCertificate === "AssignmentofLandstoEx-ServicepersonServingSoldiers"' class="btn btn-primary" routerLink="/home"  routerLinkActive='active'>Back to Home</button>
            <!--    <button class="btn btn-primary">List Of Services</button>
                <button class="btn btn-primary">OBC Application</button>
                <button class="btn btn-primary"> Print OBC</button> -->
                <!-- <button class="btn btn-primary" routerLink="/view-transactions" routerLinkActive='active'>View Tranasactions</button> -->
        
              <!--   <button class="btn btn-primary" (click)="downLoadCertificate();" style="float: right;" *ngIf="showPrintCertificate"> Print Certficate</button> -->
              <!--  <button class="btn btn-primary" (click)="getPrintedCertificate_old();" style="float: right;" *ngIf="showPrintCertificate"> Print Certficate</button> -->
                
            
              <button class="btn btn-primary" *ngIf="redirectedFrom==='transaction' && requisitionTypeForCertificate === 'AssignmentofLandstoEx-ServicepersonServingSoldiers'" routerLink="/view-transactionzswo" [queryParams]="{preservesearch:true}" routerLinkActive='active'>back to view transactions</button>
              <button class="btn btn-primary" *ngIf="redirectedFrom==='transaction' && requisitionTypeForCertificate !== 'AssignmentofLandstoEx-ServicepersonServingSoldiers'" routerLink="/view-transactions" [queryParams]="{preservesearch:true}" routerLinkActive='active'>back to view transactions</button>

              <button class="btn btn-primary" (click)="stationaryPopup(showStationaryPopup, showTransactionPopuphtml);" style="float: right;"
                     *ngIf="((showPrintCertificate && showSuccessTStatus) && (this.requisitionInfo?.serviceCode !== 'SadaremPrintCertificate') 
                    || (this.requisitionInfo?.serviceCode==='PrintCertificate')) && (this.departmentCode != 'HF')"> Print Certificate</button>
              <button class="btn btn-primary"  (click)="stationaryPopup(showStationaryPopup, showTransactionPopuphtml, 'certificate')" *ngIf="this.requisitionInfo?.serviceCode === 'SadaremPrintCertificate' && showSuccessTStatus"> Print Sadarem Certificate</button>
              <button class="btn btn-primary"  (click)="getSadaramCertificate('Id');" *ngIf="this.requisitionInfo?.serviceCode === 'SadaremPrintCertificate' && showSuccessTStatus"> Print Sadarem ID Card</button>

             
          <!--    <button class="btn btn-primary" (click)="htmlDownloadCertificateFromGSWS(showTransactionPopuphtml);" style="float: right;" *ngIf="htmlBtn"> Print HTML Certficate</button>  -->


            </div>
            <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        </div>

       

    </div>
</div>




  <!-- StationaryPopup Popup -->
  <ng-template #showStationaryPopup let-modal class="modalCls btnHide">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Stationary Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span >×</span>
        </button>
    </div>

    <div class="modal-body"  id="transPoup">
        <br>    
        <form [formGroup]="stationaryForm">
        <div class="row">
            <div class="col-lg-4">
                <label class="labelCls lableHead"><b>Enter stationary serial number :</b></label>
            </div>
           
            <div  class="col-lg-8 tableClsDiv" formArrayName="copiesData">
                <div [formGroupName]="i" *ngFor="let copies of copiesData.controls; let i = index">
          
                <label class="labelCls mr-3">
                    <input type="text" name="stationary_number" placeholder="stationary number" class="inputCls" 
                    formControlName="stationary_number" maxlength="12"
                    [ngClass]="{ 'is-invalid':submitted && this.copiesData.controls[i].get('stationary_number')?.errors}">
                    
                    <div *ngIf="submitted &&  this.copiesData.controls[i].get('stationary_number')?.errors" class="selectError">
                        <div *ngIf="this.copiesData.controls[i].get('stationary_number')?.errors?.required">Please Enter stationary number</div>
                        <div *ngIf=" this.copiesData.controls[i].get('stationary_number')?.errors?.pattern">Please enter valid stationary number</div>
                    </div>
                </label>      
      
    </div>
</div>
       
          
            
        </div>

            <div>
                <label class="labelCls lableHead float-right">
                    <button type="button" class="btn btn-primary" (click)="submit(showTransactionPopuphtml)">submit</button>
                </label>
            </div>
            
        


        <!-- new added for HTML and PDF  -->
       <!--  <br>
        <div class="row">
            <div class="col col-lg-4 col-md-4 col-sm-4 col-xs-3">
                <label class="labelCls lableHead"><b>Select Certificate Format :</b></label>
            </div>
            <div class="col col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <div class="col col-lg-5 col-md-5 col-sm-12 col-xs-12">
                     <input type="radio" name="mode" value="PDF" [checked]=true
                            (click)="select_print_certificate_mode('PDF')">
                            <span class="mx-1 radioCls" style="padding-right: 21px;">PDF</span>
                        <input type="radio" name="mode" value="HTML"
                            (click)="select_print_certificate_mode('HTML')"><span class="mx-1 radioCls">HTML</span>
                </div>
                
            </div>
        </div>

        <br>
        <div class="row">

            <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
            <div class="col col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="labelCls lableHead">
                    <button type="button" class="btn btn-primary" (click)="submit(showTransactionPopuphtml)">submit</button>
                </label>
            </div>
            <div class="col col-lg-5 col-md-5 col-sm-5 col-xs-5"></div>
        </div> -->
    
        <!---------------->

        </form>
    </div>

    <div class="modal-footer">
        <button type="button" id="closePopup" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
<!-- StationaryPopup Popup end -->



<!-- Transaction HTML Popup -->
<ng-template #showTransactionPopuphtml let-modal class="modalCls btnHide">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Certificate Popup</h4>

        <button type="button"  class="btn btn-primary printBtnHtml" (click)="PrintCertificateHTML()">Print Certificate</button>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span >×</span>
        </button>
    </div>

    <div class="modal-body" >
        <div [innerHTML]="fileInfoHTMl" id="htmlDataCertificate" style="display: none;"></div>

        <iframe [srcdoc]="fileInfoHTMl" width="100%" height="800" frameBorder="0" id="clsIframe">

        </iframe>
            
    
    </div>

    <div class="modal-footer">
        <button type="button"  class="btn btn-primary" (click)="PrintCertificateHTML()">Print Certificate</button>
        <button type="button" id="closePopup" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
<!-- Transaction HTML Popup  end -->