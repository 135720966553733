<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Revenue District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.rV_DISTRICTNAME}}</option>
      </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Revenue Mandal : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" required formControlName="mandal" (change)="onMandalChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.revenuE_MANDAL_NAME}}</option>
      </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Revenue Village : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" required formControlName="village" 
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.revenuE_VILLAGE_NAME}}</option>
      </select>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Service Name: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" required formControlName="service" (change) = "onServiceChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.service.errors || f.service.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let service of services" [ngValue]="service">{{service.serviceType}}</option>
      </select> 
    </div>

        <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="labelCls">Application From Date: <span class="mandatoryCls">*</span></label>
            <div class="input-group">
              <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                     name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                     [maxDate]="maxDate" required formControlName="fromDate"
                     [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
              </div>
            </div>
            <div *ngIf="submitted && f.fromDate.errors" class="selectError">
              <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
          </div>
          </div>
          <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="labelCls">Application To Date: <span class="mandatoryCls">*</span></label>
            <div class="input-group">
              <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                     name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                     [maxDate]="maxDate" required formControlName="toDate"
                     [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
              </div>
            </div>
            <div *ngIf="submitted && f.toDate.errors" class="selectError">
              <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
          </div>
          </div>
        </div> 
        <div class="tabClass">
            <ul>
                  <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
            </ul>
            <div class="btnHolder">
                <button  class="greenBtnCls float-right my-3" (click)="getReports()">Get Reports</button>
                <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
                <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
            </div>
          </div>
    </div>
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">Passbooks Related Service Report  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
                </tr>
                <tr>
                  <th rowspan="2">S.No</th>
                  <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districtname'">District<mdb-icon fas icon="sort"></mdb-icon></th>
                  <th rowspan="2" *ngIf="format === 'Mandal Wise' || format === 'Village Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districtname'">Division<mdb-icon fas icon="sort"></mdb-icon></th>
                  <th rowspan="2" *ngIf="format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandalname'">Mandal<mdb-icon fas icon="sort"></mdb-icon></th> 
                  <th rowspan="2" *ngIf="format === 'Village Wise' || format === 'Application Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'villagename'">Village<mdb-icon fas icon="sort"></mdb-icon></th> 
                  <th rowspan="2" *ngIf="format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'servicE_TYPE'">Service<mdb-icon fas icon="sort"></mdb-icon></th> 
                  <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apprvD_COUNT'">Total Applications Approved<mdb-icon fas icon="sort"></mdb-icon></th> 
                  <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'senT_MSP_COUNT'">Passbooks Posted to Printers BY AP SEVA<mdb-icon fas icon="sort"></mdb-icon></th> 
                  <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'printeD_COUNT'">Passbooks Printed by Printers<mdb-icon fas icon="sort"></mdb-icon></th> 
                  <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dispatcheD_COUNT'">Passbooks Dispatched by Printers<mdb-icon fas icon="sort"></mdb-icon></th> 
                  <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'delivereD_COUNT'">Passbooks Delivered<mdb-icon fas icon="sort"></mdb-icon></th> 
                </tr>
            </thead>
    
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{item.districtname}}</td>
                    <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise'">{{item.divisionname}}</td>
                    <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{item.mandalname}}</td>
                    <td *ngIf="format === 'Village Wise' || format === 'Application Wise'">{{item.villagename}}</td>
                    <td *ngIf="format === 'Service Wise'">{{item.servicE_TYPE}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Service Wise'">{{item.apprvD_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Service Wise'">{{item.senT_MSP_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Service Wise'" class="text-right">{{item.printeD_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'|| format === 'Service Wise'" class="text-right">{{item.dispatcheD_COUNT}}</td>
                    <td class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'app')" class="linkCls">{{item.apprvD_COUNT}}</a></td>
                    <td class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'MSP Sent')" class="linkCls">{{item.senT_MSP_COUNT}}</a></td>
                    <td class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'print')" class="linkCls">{{item.printeD_COUNT}}</a></td>
                    <td  class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'dispatch')" class="linkCls">{{item.dispatcheD_COUNT}}</a></td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{item.delivereD_COUNT}}</td>
                </tr>
                <tr *ngIf="totalItems && totalItems.length > 0">
                    <td></td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{totalItems[0].districtname}}</td>
                    <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise'">{{totalItems[0].divisionname}}</td>
                    <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{totalItems[0].mandalname}}</td>
                    <td *ngIf="format === 'Village Wise' || format === 'Application Wise'">{{totalItems[0].villagename}}</td>
                    <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicE_TYPE}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'">{{totalItems[0].apprvD_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'">{{totalItems[0].senT_MSP_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{totalItems[0].printeD_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{totalItems[0].dispatcheD_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{totalItems[0].delivereD_COUNT}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        <div class="mt-4 btnHide">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    </div>
</div>   
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead>
            <tr>
                <th [attr.colspan]="noOfCol">Passbooks Related Service Report  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
             </tr>
            <tr>
              <th>S.No</th>
                <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districtname'">District<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Mandal Wise' || format === 'Village Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districtname'">Division<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandalname'">Mandal<mdb-icon fas icon="sort"></mdb-icon></th> 
                <th *ngIf="format === 'Village Wise' || format === 'Application Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'villagename'">Village<mdb-icon fas icon="sort"></mdb-icon></th> 
                <th *ngIf="format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'servicE_TYPE'">Service<mdb-icon fas icon="sort"></mdb-icon></th> 
                <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apprvD_COUNT'">Total Applications Approved<mdb-icon fas icon="sort"></mdb-icon></th> 
                <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'senT_MSP_COUNT'">Passbooks Posted to Printers BY AP SEVA<mdb-icon fas icon="sort"></mdb-icon></th> 
                <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'printeD_COUNT'">Passbooks Printed by Printers<mdb-icon fas icon="sort"></mdb-icon></th> 
                <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dispatcheD_COUNT'">Passbooks Dispatched by Printers<mdb-icon fas icon="sort"></mdb-icon></th> 
                <th *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise'|| format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'delivereD_COUNT'">Passbooks Delivered<mdb-icon fas icon="sort"></mdb-icon></th> 
        </thead>
 
        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;">
                <td>{{(i + 1)}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{item.districtname}}</td>
                <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise'">{{item.divisionname}}</td>
                    <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{item.mandalname}}</td>
                    <td *ngIf="format === 'Village Wise' || format === 'Application Wise'">{{item.villagename}}</td>
                    <td *ngIf="format === 'Service Wise'">{{item.servicE_TYPE}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Service Wise'">{{item.apprvD_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Service Wise'">{{item.senT_MSP_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Service Wise'" class="text-right">{{item.printeD_COUNT}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'|| format === 'Service Wise'" class="text-right">{{item.dispatcheD_COUNT}}</td>
                    <td class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'app')" class="linkCls">{{item.apprvD_COUNT}}</a></td>
                    <td class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'MSP Sent')" class="linkCls">{{item.senT_MSP_COUNT}}</a></td>
                    <td class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'print')" class="linkCls">{{item.printeD_COUNT}}</a></td>
                    <td  class="openBeyond text-right" *ngIf="format === 'Village Wise'"><a (click) ="getModal(item, modal, 'dispatch')" class="linkCls">{{item.dispatcheD_COUNT}}</a></td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{item.delivereD_COUNT}}</td>
                 </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
             <td></td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{totalItems[0].districtname}}</td>
             <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise'">{{totalItems[0].divisionname}}</td>
             <td *ngIf="format === 'Mandal Wise' || format === 'Village Wise' || format === 'Application Wise'">{{totalItems[0].mandalname}}</td>
             <td *ngIf="format === 'Village Wise' || format === 'Application Wise'">{{totalItems[0].villagename}}</td>
             <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicE_TYPE}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'">{{totalItems[0].apprvD_COUNT}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'">{{totalItems[0].senT_MSP_COUNT}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{totalItems[0].printeD_COUNT}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{totalItems[0].dispatcheD_COUNT}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Village Wise' || format === 'Service Wise'" class="text-right">{{totalItems[0].delivereD_COUNT}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
               <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
             </tr>
        </tbody>
    </table>
   </div>
   <div class="table-responsive tableFixHead" #MODALTABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
             <th>S.No</th>
             <th>District</th>
             <th>Division</th>
             <th>Mandal</th>
             <th>Village</th>
             <th>Application Number</th>
             <th>Application Approved Date</th>
             <th>Passbook sent to Printers Date</th>
             <th>Passbook Printed by Printers Date</th>
             <th>Passbook Dispatched by Printers Date</th>
             <th>Consignment Number/ Courier Ref Number</th> 
            </tr>
        </thead>

        <tbody *ngIf="modalItems && modalItems.length > 0">
            <tr *ngFor="let item of modalItems;let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.districtname}}</td>
               <td>{{item.divisionname}}</td>
               <td>{{item.mandalname}}</td>
               <td>{{item.villagename}}</td>
               <td>{{item.applicatioN_NO}}</td>
               <td>{{item.approveD_DATE  | date: 'dd/MM/yyyy'}}</td>
               <td>{{item.passbooK_SENT_DATE | date: 'dd/MM/yyyy'}}</td>
               <td>{{item.printeD_DATE | date: 'dd/MM/yyyy'}}</td>
               <td>{{item.dispatcheD_DATE | date: 'dd/MM/yyyy'}}</td>
               <td>{{item.consignmenT_NO}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="modalItems && modalItems.length === 0">
            <tr>
                <td colspan="11" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #modal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">{{title}}</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="table-responsive tableFixHead">
            <table class="tableCls" >
                <thead> 
                    <tr>
                        <th>S.No</th>
                        <th *ngFor="let head of headerCol; let i = index" aria-controls="tableSortExample" scope="col" [mdbTableSort]="modalItems" [sortBy]="headerCol[i].colId">{{ head.colName }}
                            <mdb-icon fas icon="sort"></mdb-icon>
                          </th>
                    </tr>
                </thead>
        
                <tbody *ngIf="modalItems && modalItems.length > 0">
                    <tr *ngFor="let item of modalItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                        <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                        <td>{{item.districtname}}</td>
                        <td>{{item.divisionname}}</td>
                        <td>{{item.mandalname}}</td>
                        <td>{{item.villagename}}</td>
                        <td>{{item.applicatioN_NO}}</td>
                        <td>{{item.approveD_DATE  | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.passbooK_SENT_DATE | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.printeD_DATE | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.dispatcheD_DATE | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.consignmenT_NO}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="modalItems && modalItems.length === 0">
                    <tr>
                        <td colspan="11" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="modalItems.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="modalItems.length > 0"    [collectionSize]="modalItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  name="close" class="greenBtnCls" *ngIf="modalItems && modalItems.length > 0" (click)="downloadExcelForModal()">Download Excel</button>
    </div>
   </ng-template>    

    

