import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
@Component({
  selector: 'app-secreteriate-revenue-village-mapping-report',
  templateUrl: './secreteriate-revenue-village-mapping-report.component.html',
  styleUrls: ['./secreteriate-revenue-village-mapping-report.component.css']
})
export class SecreteriateRevenueVillageMappingReportComponent implements OnInit {
  form: UntypedFormGroup;
  districts: any[] = [];
  mandals: any[] = [];
  secreteriates: any[]= [];
  format: any;
  formatTypes: any[]= [];
  totalItems:any=[];
  items: any[] = [];
  villageWiseItems:any[]=[];
  totalVillageWiseItems:any[]=[];
  showReports:Boolean=false;
  submitted:Boolean = false;
  page = 1;
  pageSize = 10;
  noOfCol: any = 9;
  popupPageSize = 10;
  popupPage = 1;
  commonConstants : any = CommonConstants;
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('SUBTABLE')subTable !: ElementRef;
  constructor(private formBuilder: UntypedFormBuilder,
     private commonService: CommonService,
     private router: Router,
     private modalService: NgbModal) { 
    this.form = this.formBuilder.group({
      district: [null],
      RUFlag:[null],
      mandal: [null],
      secreteriate :[null]
    });
    this.format = 'District Wise';
    this.formatTypes = [{name: 'District Wise', isActive: true},
                        {name:'Mandal Wise', isActive: false},
                        {name:'Secretariat Wise', isActive: false}
  ]
   }
  
  ngOnInit(): void {    
    this.format = 'District Wise';
    this.form.patchValue({
      district:'All',
      RUFlag:'All',
      mandal:'All',
      secreteriate: 'All',      
    });
    this.getDistricts();
    this.getReportData();
  }
  get f() { return this.form.controls; }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  onRuralUrbanChange() {
    this.getMandals();
    
  }
  
  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.secreteriates = [];
    this.f.mandal.patchValue('All');
    this.f.secreteriate.patchValue('All');  
    this.getMandals();
  
  }
  getMandals(){
    this.showReports = false;
    this.mandals = [];
    this.secreteriates = [];
    this.f.mandal.patchValue('All');
    this.f.secreteriate.patchValue('All'); 
    let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + RUFlag).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  onMandalChange() {
    this.showReports = false;
    this.secreteriates = [];
    this.f.secreteriate.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.secreteriates = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.secreteriates = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  
  getFormat(): any {
      if (this.format === 'District Wise' ) {
      this.noOfCol = 9;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 10;
      return this.noOfCol;
    } else {
      this.noOfCol = 7;
      return this.noOfCol;
    }
  }
  getReportData(sec?: any): any {    

    if (this.format === 'Secretariat Wise' && this.f.district.value === 'All'){
      this.commonService.commonErrorPopup('Alert', 'Please select one district to get the Secretariat Wise Report.', 'md');
      return
    } else{
      this.page = 1;    
      let DistrictID = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
      let RUrban = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
      let MandalId = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
      let SecCode = this.f.secreteriate.value === 'All' ? 'All' : this.f.secreteriate.value?.secretariatCode;
      let postData:any;
        postData = '?FlagName='+ this.format +'&DistrictId=' + DistrictID + '&RURBAN=' + RUrban + '&MandalId=' + MandalId +'&SecCode=' + SecCode  ;
              
      this.commonService.getRequest(this.commonConstants.getSecRevVilgMappingReport + postData).subscribe({
        next: (responseData: any) => { 
          console.log(typeof(responseData?.result),'result man')
          if(typeof(responseData?.result) !== 'string') {             
              this.showReports = true;
              this.items = responseData?.result;
              this.items = this.items.filter(item => item.totalflag !== 'Y')
              this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')
              
            } else {
            this.items = [];
            this.showReports= true;
            this.totalItems = [];
            this.villageWiseItems=[];
            this.totalVillageWiseItems=[];
            //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
      
      }
      getSubReportData(sec: any , modal: any){        
        this.popupPage  =1;
        let postData:any;      
        postData ='?FlagName='+ 'VILLAGE WISE' +'&SecCode=' + sec      
        this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});    
        this.commonService.getRequest(this.commonConstants.getSecRevVilgMappingReport + postData).subscribe({
          next: (responseData: any) => { 
            console.log(typeof(responseData?.result),'result man')
            if(typeof(responseData?.result) !== 'string') {             
                this.showReports = true;
                this.villageWiseItems =  responseData?.result;
                this.villageWiseItems = this.villageWiseItems.filter(item => item.totalflag !== 'Y')
                console.log(this.villageWiseItems +'this.villageWiseItems ')
                this.totalVillageWiseItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')

              } else {
              this.showReports= true;              
              this.villageWiseItems=[];
              this.totalVillageWiseItems=[];
              //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
      downloadExcel() {
        let fileName: any = 'SECRETARIAT-REVENUE VILLAGE MAPPING REPORT' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
        const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
        let wscols = [
          {wpx:50},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100}
      ];
      
      ws['!cols'] = wscols;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SECRETARIAT-REVENUE REPORT');
        
        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
      }
      downloadExcelForSubReport(){
        let fileName: any = 'SECRETARIAT-REVENUE VILLAGE MAPPING SUB REPORT Village Wise' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
        const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.subTable.nativeElement, {raw: true});
        let wscols = [
          {wpx:50},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100}
      ];
      
      ws['!cols'] = wscols;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SECRETARIAT-REVENUE SUB REPORT');
        
        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
      }
      saveAsExcelFile(buffer: any, fileName: string) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
      }
      goToHome() {
        this.router.navigate(['/home']);
      }
    
    
  }
  