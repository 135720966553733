import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import * as moment from 'moment';
import { CommonMsgModalComponent } from './common-msg-modal/common-msg-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from './environment.service';
import { environment } from 'src/environments/environment';
import * as Forge from 'node-forge';
import * as CryptoJS from 'crypto-js';   
import { ToWords } from 'to-words';
import { sha3_256 } from 'js-sha3';

@Injectable({
  providedIn: 'root'
})
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? (date.day < 10 ? '0' + date.day : date.day) + this.DELIMITER + (date.month < 10 ? '0' + date.month : date.month) + this.DELIMITER + date.year : '';
  }
}
/*white space validator*/
export class WhitespaceValidator {
  static whiteSpaceValidate(control: AbstractControl) : ValidationErrors | null {
      if(control.value && (control.value as string !== null) && ((control.value as string).trim() === '')){
          return {whiteSpaceValidate: true}
      }

      return null;
  }
}

// validates Aadhar number received as string
export class AadharValidator {
  static aadharValidate(control: AbstractControl) : ValidationErrors | null {
    // multiplication table
    const d = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      [1, 2, 3, 4, 0, 6, 7, 8, 9, 5], 
      [2, 3, 4, 0, 1, 7, 8, 9, 5, 6], 
      [3, 4, 0, 1, 2, 8, 9, 5, 6, 7], 
      [4, 0, 1, 2, 3, 9, 5, 6, 7, 8], 
      [5, 9, 8, 7, 6, 0, 4, 3, 2, 1], 
      [6, 5, 9, 8, 7, 1, 0, 4, 3, 2], 
      [7, 6, 5, 9, 8, 2, 1, 0, 4, 3], 
      [8, 7, 6, 5, 9, 3, 2, 1, 0, 4], 
      [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
      ];
      
      // permutation table
      const p = [
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 
      [1, 5, 7, 6, 2, 8, 3, 0, 9, 4], 
      [5, 8, 0, 3, 7, 9, 6, 1, 4, 2], 
      [8, 9, 1, 6, 0, 4, 3, 5, 2, 7], 
      [9, 4, 5, 3, 1, 2, 6, 8, 7, 0], 
      [4, 2, 8, 6, 5, 7, 3, 9, 0, 1], 
      [2, 7, 9, 3, 8, 0, 6, 4, 1, 5], 
      [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
      ];
      let c = 0;
      if(control.value && (control.value as string !== null)) {
        let invertedArray = control.value.split('').map(Number).reverse();
        invertedArray.forEach((val:any, i:any) => {
          c = d[c][p[(i % 8)][val]]
        });
        if (c !== 0)
          return {aadharValidate: true};
      }

      return null;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  //url: String = ""; 
  apiUrl: any;
  inboundApiUrl: any;
  villageid: any;
  filepath: any;
  aadh: any;
  sumotodata: any;
  filesforsumotodata: any;
  //7819e1cf869d4bcfb4ee8d71ab0b0f7b internal replaced with 0356f181388f46a090fe8782574ec6ce
  //b248c8be2e9d4a5487ada90f99eecbef external replaced with 6fe79bebadf7453a9b6cf5d4d61bb747
  constructor(private httpClient: HttpClient, private modalService: NgbModal, private environmentService: EnvironmentService) { 
    this.apiUrl = this.environmentService.apiUrl;
    this.inboundApiUrl = this.environmentService.InboundApiUrl;
  }
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.internalKey,  //'7819e1cf869d4bcfb4ee8d71ab0b0f7b',
      'Ocp-Apim-Trace': 'true'
      //'Access-Control-Allow-Credentials': 'true',
      //'Access-Control-Allow-Headers': 'Authorization, Origin, X-Requested-With, Content-Type, Accept'
    })
  }
  fileUploadOptions = {
    headers: new HttpHeaders({
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.internalKey,  //'7819e1cf869d4bcfb4ee8d71ab0b0f7b',
      'Ocp-Apim-Trace': 'true'
    })
  }
  weblandOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
     // 'Ocp-Apim-Subscription-Key': '7819e1cf869d4bcfb4ee8d71ab0b0f7b',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey,  //'b248c8be2e9d4a5487ada90f99eecbef',
      'Ocp-Apim-Trace': 'true'
    })
  }

  citizenInfoOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'key': 'DDB18786FD372DEE936CBB98E618A4E45BE16F3825DE5E52DB82E5AE3F3AE22E5E456D91D1',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey, //'b248c8be2e9d4a5487ada90f99eecbef',
      'Ocp-Apim-Trace': 'true'
    })
  }
  sixStepOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'key': '52711373A28D2ABD2EB87C645CED55D762574AB6F3A443D28F7881EEEB43B6E15D524E9F8D',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey, //'b248c8be2e9d4a5487ada90f99eecbef',
      'Ocp-Apim-Trace': 'true'
    })
  }
  aarogyaSriOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'key': '85F9A7787957A88FAF34716B1EFBABB1474BA3EA4A22D4BE2352326D1E13A5F36F8D7DFD76',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey, //'b248c8be2e9d4a5487ada90f99eecbef',
      'Ocp-Apim-Trace': 'true'
    })
  }
  decimalWords(value:any){
    const toWords = new ToWords({
      localeCode: 'en-IN',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: { // can be used to override defaults for the selected locale
          name: 'Rupee',
          plural: 'Rupees',
          symbol: '₹',
          fractionalUnit: {
            name: 'Paisa',
            plural: 'Paise',
            symbol: '',
          },
        }
      }
    });
    let words = toWords.convert(value, { currency: true }); 
    return words;
  }
  aadharotpdatamessage: any;
  authTypeFromBasicPopup: any;
  postRequestForAadhar(url:any,masterData:any, from?:any) { 
   // console.log(masterData, 'Sending post master data'); 
     let apiUrl:any;
     let options: any;
     if(from === 'pension') {
      apiUrl = url;
      options = this.pensionOtpOptions;
      return this.httpClient.post(this.apiUrl + url , masterData, {headers: options, responseType:'text'}).pipe(
        map((encrptedStr: any)=>{
          let decodedStr: string = decodeURIComponent(escape(atob(encrptedStr.toString())))
          let data: any = JSON.parse(decodedStr);
          console.log(data);
          return data;
        }),
         catchError(this.httpError)
       )
     } else {
      apiUrl = this.apiUrl + url;
      options = this.aadharOptions;

      const aesKey = this.getRandomString(32);
      const aesVector = this.getRandomString(16);
      let encodedData: any = {
        key: this.RSAEncrypt(aesKey),
        vector: this.RSAEncrypt(aesVector),
        value: this.AESEncrypt(JSON.stringify(masterData), aesKey, aesVector)
      }
      let body = JSON.stringify(encodedData);

      return this.httpClient.post(this.apiUrl + url , encodedData, {headers: options, responseType:'text'}).pipe(
        map((response: any)=>{
          const res = JSON.parse(response);
          if(res && res.key && res.vector && res.value){
            const aesKey = this.RSADecrypt(res.key);
            const aesVector = this.RSADecrypt(res.vector);
            return JSON.parse(this.AESDecrypt(res.value, aesKey, aesVector));
          } else {
            return null;
          }
        }),
        catchError(this.httpError)
      )
     }

      
  }

  aadharOptions = 
    new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.internalKey,
      'key' :'2bf9a14608d717715c230f383b2213576aba39817d9354c7feb051a269e18067',
      
      'Ocp-Apim-Trace': 'true'
      //'Access-Control-Allow-Credentials': 'true',
      //'Access-Control-Allow-Headers': 'Authorization, Origin, X-Requested-With, Content-Type, Accept'
    })
  
  pensionOtpOptions =  new HttpHeaders({
    'Content-Type': 'text/plain,charset="utf-8"',
    'Access-Control-Allow-Origin': '*',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.internalKey,
      'key' :'21869eae665d1389544965f7a93e4dd01077a38fe3720ed01beaa8e29e71ffad',
      
      'Ocp-Apim-Trace': 'true'
      //'Access-Control-Allow-Credentials': 'true',
      //'Access-Control-Allow-Headers': 'Authorization, Origin, X-Requested-With, Content-Type, Accept'
    })

  getRequest(url:any, department?:any, isEncrypt?:boolean) {
    
    let urlApim = "";
    let optionsApim = {};
   
    if(department === "MAUD"){
      urlApim = this.inboundApiUrl;
      optionsApim = this.weblandOptions;
    }else{
      urlApim = this.apiUrl;
      optionsApim = this.options;
    }

    return this.httpClient.get(urlApim + url, optionsApim)
      .pipe(
        map((response: any)=> {
          if(isEncrypt) {
            const res = response;
            if(res && res.key && res.vector && res.value){
              const aesKey = this.RSADecrypt(res.key);
              const aesVector = this.RSADecrypt(res.vector);
              return JSON.parse(this.AESDecrypt(res.value, aesKey, aesVector));
            } else {
              return null;
            }
          } else {
            return response;
          }
        })
        ,catchError(this.httpError)
      )
  }

  getRequestForToken(url:any) {
    
    let urlApim = "";
    let optionsApim = {};

      urlApim = this.apiUrl;
      optionsApim =  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey,  //'7819e1cf869d4bcfb4ee8d71ab0b0f7b',
          'Ocp-Apim-Trace': 'true'
          //'Access-Control-Allow-Credentials': 'true',
          //'Access-Control-Allow-Headers': 'Authorization, Origin, X-Requested-With, Content-Type, Accept'
        })
      }

    return this.httpClient.get(urlApim + url, optionsApim)
      .pipe(
        //retry(1),
        catchError(this.httpError)
      )
  }
  getRequestForGraphapi(url:any) {
    
    let urlApim = "";
    let optionsApim = {};
    let graphtoken  = this.AESDecrypt(localStorage.getItem('graphtoken')??"", environment.aesKey, environment.aesVector)
      urlApim = this.apiUrl;
      optionsApim =  {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey,  //'7819e1cf869d4bcfb4ee8d71ab0b0f7b',
          'Auth-Token': `Bearer `+graphtoken,
          //'Ocp-Apim-Trace': 'true'
          //'Access-Control-Allow-Credentials': 'true',
          //'Access-Control-Allow-Headers': 'Authorization, Origin, X-Requested-With, Content-Type, Accept'
        })
      }

    return this.httpClient.get(urlApim + url, optionsApim)
      .pipe(
        //retry(1),
        map((response: any)=> {
        
            const res = response;
            if(res && res.key && res.vector && res.value){
              const aesKey = this.RSADecrypt(res.key);
              const aesVector = this.RSADecrypt(res.vector);
              return JSON.parse(this.AESDecrypt(res.value, aesKey, aesVector));
            } else {
              return null;
            }
          
        }),
        catchError(this.httpError)
      )
  }

  getRequestWithoutBaseUrl(url:any) {
   // console.log('get in service', this.environmentService.apiUrl);
    return this.httpClient.get(url, this.options)
      .pipe(
        //retry(1),
        catchError(this.httpError)
      )
  }
  
  // postRequest(url:any,masterData:any) { 
  // // console.log(masterData, 'Sending post master data'); 
  //   let body=JSON.stringify(masterData);
  //   return this.httpClient.post(this.apiUrl + url , body,this.options).pipe(
  //     //retry(1),
  //     catchError(this.httpError)
  //   )
  // }

  postRequest(url:any,masterData:any, isEncrypt?:any) {
    // console.log(masterData, 'Sending post master data');
      let body;
      if (isEncrypt) {
        const aesKey = this.getRandomString(32);
        const aesVector = this.getRandomString(16);
        let encodedData: any = {
          key: this.RSAEncrypt(aesKey),
          vector: this.RSAEncrypt(aesVector),
          value: this.AESEncrypt(JSON.stringify(masterData), aesKey, aesVector)
        }
        body = JSON.stringify(encodedData);
      } else {
        body = JSON.stringify(masterData);
      }
      return this.httpClient.post(this.apiUrl + url , body,this.options).pipe(
        //retry(1),
        map((response: any)=> {
          if(isEncrypt) {
            const res = response;
            if(res && res.key && res.vector && res.value){
              const aesKey = this.RSADecrypt(res.key);
              const aesVector = this.RSADecrypt(res.vector);
              return JSON.parse(this.AESDecrypt(res.value, aesKey, aesVector));
            } else {
              return null;
            }
          } else {
            return response;
          }
        })
        ,catchError(this.httpError)
      )
  }

  postRequestWithoutRetry(url:any,masterData:any) { 
    // console.log(masterData, 'Sending post master data'); 
      let body=JSON.stringify(masterData);
      return this.httpClient.post(this.apiUrl + url , body,this.options).pipe(
        catchError(this.httpError)
      )
    }

  postRequestWithoutBaseUrl(url:any,masterData:any) { 
    //console.log(masterData, 'Sending post master data'); 
     let body=JSON.stringify(masterData);
     return this.httpClient.patch(url , body,this.options).pipe(
      // retry(1),
       catchError(this.httpError)
     )
   }
  postSoapRequest(url:any, masterData:any, methodName: any) { 
    let reissueOptions = new HttpHeaders({
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*',
     // 'Ocp-Apim-Subscription-Key': '7819e1cf869d4bcfb4ee8d71ab0b0f7b',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey,  //'b248c8be2e9d4a5487ada90f99eecbef',
      'Ocp-Apim-Trace': 'true',
      'SOAPAction': "http://AP.org/" + methodName,
    });
    //console.log(masterData, 'Sending post master data'); 
     //let body=JSON.stringify(masterData);
     return this.httpClient.post(this.inboundApiUrl + url , masterData, {headers: reissueOptions, responseType:'text'}).pipe(
      map((xmlString: string)=>{
        const asJson = this.xmlStringToJson(xmlString);
        return asJson;
      }),
      catchError(this.httpError)
     )
   }
   postweblandSoapRequest(url:any,body:any,methodName:any){
    let sixstepNic = new HttpHeaders({
      'Content-Type': 'text/xml,charset="utf-8"',
      'Access-Control-Allow-Origin': '*',
      'Ocp-Apim-Trace': 'true',
      'X-Forwarded-For':'20.204.111.224',
     
      'SOAPAction': "http://service.service.epdsdataport.com/" + methodName,
    });
    //console.log(body, 'Sending post master data'); 
    //  let data=JSON.stringify(body);
     return this.httpClient.post(url , body, {headers: sixstepNic, responseType:'text'}).pipe(
      map((xmlString: string)=>{
        const asJson = this.xmlStringToJson(xmlString);
        return asJson;
      }),
      catchError(this.httpErrorRequest)
     )
   }
   xmlStringToJson(xml: string)
   {
     // Convert the XML string to an XML Document.
     const oParser = new DOMParser();
     const oDOM = oParser.parseFromString(xml, "application/xml");
     // Convert the XML Document to a JSON Object.
     return oDOM;
   }
  postRequestForWebLand(url:any,masterData:any, requestType?:any, token?:any) { 
    //console.log(masterData, 'Sending post master data'); 
    //console.log(masterData, 'Sending post master data'); 
    let weblandOptions  = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
     // 'Ocp-Apim-Subscription-Key': '7819e1cf869d4bcfb4ee8d71ab0b0f7b',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey,  //'b248c8be2e9d4a5487ada90f99eecbef',
      'Ocp-Apim-Trace': 'true'
    })
    let options: any;
    if (requestType === 'citizenInfo') {
      options = this.citizenInfoOptions
    } else if (requestType === 'sixstep') {
      options = this.sixStepOptions
    } else if (requestType === 'strResponse') {
      options = {headers: weblandOptions,   responseType:'text'}
    } else if(requestType === 'aarogyaSri'){
      options =this.aarogyaSriOptions
    } else if(requestType === 'resurvey') {
      options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*',
          'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey, 
          'Ocp-Apim-Trace': 'true',
          'Authorization': token
        })
      }
    }
    else {
      options = this.weblandOptions
    }
     let body=JSON.stringify(masterData);
     return this.httpClient.post(this.inboundApiUrl + url , body, options).pipe(
       //retry(1),
       catchError(this.httpError)
     )
   }

   postRequestForNCS(url:any,masterData:any, token?:any) {  
    
    let options: any = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*',
          'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.externalKey, 
          'Ocp-Apim-Trace': 'true',
          'x-Cookie': token
        })};
     let body=JSON.stringify(masterData);
     return this.httpClient.post(this.inboundApiUrl + url , body, options).pipe(
       //retry(1),
       catchError(this.httpError)
     )
   }

  putRequest(url:any,masterData:any) { 
   // console.log(masterData, 'Sending put master data'); 
     let body=JSON.stringify(masterData);
     return this.httpClient.put(this.apiUrl + url , body,this.options).pipe(
       //retry(1),
       catchError(this.httpError)
     )
   }
 
  postUploadedFileRequest(url:any,formData:FormData) { 
     return this.httpClient.post(this.apiUrl + url,formData, this.fileUploadOptions).pipe(
       //retry(1),
       catchError(this.httpError)
     )
   }

  httpError(error: any) {
    let msg = '';
    if(error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
     // alert('Validation error occured');
     // console.log('API request has error to excute',error.status);
      // if (error.status === 400) {
      //     console.log('in if');
      // }
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
   // console.log(msg);
    return throwError(msg);
  }

  httpErrorRequest(error: any) {
    let msg = '';
    if(error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      // alert('Validation error occured');
      //console.log('API request has error to excute',error.status);
      // if (error.status === 400) {
      //     console.log('in if');
      // }
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //console.log(msg);
    return throwError(msg);
  }

  

  sortData(data:any) {
    let sortedData: any;
    sortedData = data.sort((a:any,b:any) => (a.name > b.name) ? 1 : -1);
    return sortedData;
  }
  sortQaziDistrict(data:any) {
    let sortedData: any;
    sortedData = data.sort((a:any,b:any) => (a.districtName > b.districtName) ? 1 : -1);
    return sortedData;
  }
  sortQaziMandal(data:any) {
    let sortedData: any;
    sortedData = data.sort((a:any,b:any) => (a.mandalName > b.mandalName) ? 1 : -1);
    return sortedData;
  }
  sortQaziName(data:any) {
    let sortedData: any;
    sortedData = data.sort((a:any,b:any) => (a.qaziName > b.qaziName) ? 1 : -1);
    return sortedData;
  }
  sortItems(data:any, name: any) {
    let sortedData: any;
    sortedData = data.sort((a:any,b:any) => (a[name] > b[name]) ? 1 : -1);
    return sortedData;
  }
  paymentData: BehaviorSubject<any> = new BehaviorSubject('');
  public get Data(): Observable<any> {
    return this.paymentData.asObservable();
  }

  checkAction: BehaviorSubject<any> = new BehaviorSubject('');
  public get actionData(): Observable<any> {
    return this.checkAction.asObservable();
  }
  commonAction: BehaviorSubject<any> = new BehaviorSubject('');
  public get commonData(): Observable<any> {
    return this.commonAction.asObservable();
  }
  formatDate(value: any) {
    //console.log('original date value', value);
    //console.log('Moment date value', moment(value,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss[Z]"));
      let date = value.split('-');
      let day = parseInt(date[0], 10);
      let month = parseInt(date[1], 10);
      let year = parseInt(date[2], 10);
      let dateVal = new Date(month + '-' + day + '-' + year);
      //console.log('iso', dateVal.toISOString());
      //console.log('utc', dateVal.toISOString());
     //console.log('moment',moment(dateVal).format('YYYY-MM-DDThh:mm:ss-hh'));
      //return moment(value,"dd-MM-yyyy") 
      return moment(value,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss[Z]");
  }
  dateInTimezoneFormat(value: any) {
    return moment(value).format("YYYY-MM-DDThh:mm:ss[Z]");
  }
  // newly added for validation purpose
  dobFormatDate(value: any) {
      let date = value?.split('-');
      let day = parseInt(date[0], 10);
      let month = parseInt(date[1], 10);
      let year = parseInt(date[2], 10);
      let dateVal = new Date(day + '-' + month + '-' + year);
      return moment(value,"DD-MM-YYYY").format("DD-MM-YYYY 00:00:00");
  }
  NCSFormatDate(value: any) {
    let date = value.split('-');
    let day = parseInt(date[0], 10);
    let month = parseInt(date[1], 10);
    let year = parseInt(date[2], 10);
    let dateVal = new Date(day + '-' + month + '-' + year);
    return moment(value,"DD-MM-YYYY").format("DD/MM/YYYY");
}
  dateFormat(value: any){   
    return moment(value).format("DD-MM-YYYY HH:mm:ss");
  }
  regularDateFormat(value: any) {
    return moment(value, "DD-MM-YYYY").format("DD/MM/YYYY");
  }
  requiredDateFormats(value: any, format: any) {
    return moment(value).format(format);
  }
  // newly added for validation purpose
  downloadPdfFile(base64String:string, fileName: string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName
    link.click();
  }
  downloadTIFFile(base64String:string, fileName: string) {
    const source = `data:application/octet-stream;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = fileName
    link.click();
  }


  //emudra
  getPostHttpOptions(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + this.getToken(),
      }),
    };
    return httpOptions;
  }

  getToken(): any {
    let token : any = "" ;
    while (token === '') {
      token = sessionStorage.getItem('access_token');
     }
    return token;
  }

  baseUrl(): string {
    return 'http://localhost:30834/';
  }

  tokenStatus = 'CONNECTED';
  tokenType = 'ALL';
  //eMudhra
  getAppId(): string {
    return Math.random().toString().slice(2, 12);
  }

  catchResponse(error: any): void {
   // console.log(error,error.status);
    alert(error.status);
    if (error.status === 401) {
      sessionStorage.clear();
     // this.router.navigate(['/COMMON/UnAuthorized']);
    } else if (error.status === 403) {
      sessionStorage.clear();
     // this.router.navigate(['/COMMON/UnAuthorized']);
    } else if (error.status === 503) {
      sessionStorage.clear();
     // this.router.navigate(['/COMMON/ServerUnavailable']);
    } else {
      alert(error.statusText);
    }
  }

  isBeyondSLA(item:any): Boolean {
    let date1: Date = new Date();
    let date2: Date = new Date(item.createOn);
    let timeInMilisec: number = date1.getTime() - date2.getTime();
    let daysBetweenDates: number = timeInMilisec / (1000 * 60 * 60 * 24);
    if (daysBetweenDates > item.slaDays) {
      return true;
    } else {
      return false;
    }
  }
  diffInDates(from:any, to: any): Boolean {
    let date1: Date = new Date(from);
    let date2: Date = new Date(to);
   // console.log('date1', date1)
   // console.log('date2', date2)

    let timeInMilisec: number = date2.getTime() - date1.getTime();
    let daysBetweenDates: number = timeInMilisec / (1000 * 60 * 60 * 24);
   // console.log('daysBetweenDates', daysBetweenDates)
    if (daysBetweenDates >= 31) {
      return true;
    } else {
      return false;
    }
  }
  setMasterData(key: any, data: any) {
    this.setLocalStorage(key, JSON.stringify(data));
  }
  getMasterData(key: any) {
    let data = this.getLocalStorage(key);
      if(data && data !== null) {
        return JSON.parse(data);
      }
      return false;
  }

  setLocalStorage(prop: string, value: string) {
    localStorage.setItem(prop, this.AESEncrypt(value, environment.aesKey, environment.aesVector));
  }

  getLocalStorage(prop: string): string {
    return this.AESDecrypt(localStorage.getItem(prop)??"", environment.aesKey, environment.aesVector);
  }
  commonErrorPopup(title: any, msg: any, size: any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {size: size });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = msg;
  }
  RSAEncrypt(plainText:string, isExternal: boolean = false):string{
    try {
      if(!plainText) {
        return plainText;
      }
      const rsa = Forge.pki.publicKeyFromPem(environment.RSAPublicKey);
      return isExternal ? "gsws_ada("+ btoa(rsa.encrypt(plainText)) + ")" : btoa(rsa.encrypt(plainText));
      //return isExternal ? plainText : btoa(rsa.encrypt(plainText));
    } catch (error) {
      return plainText;
    }
  }
  RSADecrypt(encryptedText:string):string{
    try {
      const rsa1 = Forge.pki.privateKeyFromPem(environment.RSAPrivateKey);
      return rsa1.decrypt (Forge.util.decode64(encryptedText));
    } catch (error) {
      return encryptedText;
    } 
  }
  AESEncrypt(plainText:string, encryptionKey:string, vector:string):string{
      const configuration = {
        keySize: 128,
        iv: CryptoJS.enc.Utf8.parse(vector),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      };
    return CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(encryptionKey), configuration).toString();
  }
  AESDecrypt(encryptedValue:string, encryptionKey:string, vector:string):string{
    const configuration = {
      keySize: 128,
      iv: CryptoJS.enc.Utf8.parse(vector),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    };
    return CryptoJS.AES.decrypt(encryptedValue,CryptoJS.enc.Utf8.parse(encryptionKey), configuration).toString(CryptoJS.enc.Utf8); 
}
  getRandomString(length: number):string{
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  SHA3HashedCode(plainText:string) {
    return sha3_256(plainText);
  }
  
 }
