import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-caste-survey-grievances-dashboard',
  templateUrl: './caste-survey-grievances-dashboard.component.html',
  styleUrls: ['./caste-survey-grievances-dashboard.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class CasteSurveyGrievancesDashboardComponent implements OnInit {
 
    commonConstants : any = CommonConstants;
   
    form: UntypedFormGroup;
    districts: any[] = [];
    mandals: any[] = [];
    villages: any[]= [];
    departments: any[] = [];
    services: any[] = [];
    formatTypes: any[]= [];
    items: any[] = [];
    totalItems: any[] = [];
    beyondSLAItems: any[] = [];
    page = 1;
    popupPage = 1;
    pageSize = 10;
    format: any;
    showReports:Boolean=false;
    submitted:Boolean=false;
    noOfCol: any = 20;
    role: any;
    defaultFromDate: any;
    currentDate = new Date();
    popupPageSize = 10;
    maxDate: any;
    minDate: any;
    toMinDate: any;
    formatWise: any;
    @ViewChild('TABLE') table!: ElementRef;
    @ViewChild('BEYONDSLATABLE') beyondSLATable!: ElementRef;
    title: any;
   
    constructor(private formBuilder: UntypedFormBuilder, config: NgbModalConfig, private commonService: CommonService,private router: Router,private modalService: NgbModal, private datePipe: DatePipe) {
      config.backdrop = 'static';
      config.keyboard = false;
      this.form = this.formBuilder.group({
        village: [null],
        mandal: [null],
        district: [null],
        RUFlag: [null],
        fromDate: ['', [Validators.required]],
        toDate: ['', [Validators.required]]
  
      });
      this.maxDate = {
        year: this.currentDate.getFullYear(),
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate()
      };
      this.minDate = {
        year: 2024,
        month: 12,
        day: 25
      };
      this.toMinDate = {
        year: 2024,
        month: 12,
        day: 25
      }
      this.format = 'District Wise';
      this.formatTypes = [{name: 'District Wise', isActive: true},
                          {name:'Mandal Wise', isActive: false},
                          {name:'Secretariat Wise', isActive: false}
    ]
      let userInfo = this.commonService.getLocalStorage('userInfo');
      if (userInfo && userInfo !== null) {
        let userData = JSON.parse(userInfo);
        this.role = userData.jobTitle;
      }
      this.getDistricts();
    }

  
    ngOnInit(): void {
      this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
      this.format = 'District Wise';
       this.form.patchValue({
         district:'All',
         mandal:'All',
         village: 'All',
         fromDate: this.defaultFromDate,
         toDate: this.defaultFromDate,
       });
    }
  
    get f() { return this.form.controls; }
    getDistricts() {
      this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
        next: (responseData: any) => {
          console.log(responseData,'getDistricts')
          if(responseData.result?.isSuccess) {
            this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
            console.log(this.districts,'districts')
          } else {
            this.districts = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    
    goToHome() {
      this.router.navigate(['/home']);
    }

    onFromDateSelect(event: any) {
      this.showReports = false;
      this.toMinDate = event;
    }
    onToDateSelect(event: any) {
      this.showReports = false;
    }
      downloadExcelForBeyondSLA() {
        let fileName: any = this.title + ' ' + '('+ this.f.fromDate.value +' to '+ this.f.toDate.value +')';
        const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.beyondSLATable.nativeElement, {raw: true});
        let wscols = [
          {wpx:50},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100}
      ];
      
      ws['!cols'] = wscols;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        let sheetName = this.title === 'Open Beyond SLA Requests' ? this.title : (this.title === 'Within SLA Requests'? this.title :(this.title === 'Requests Lapsing Within 24 Hours' ? 'Lapsing Within 24 Hours' : 'Lapsing Within 48 Hours'));
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        
        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
      }
  
    onDistrictChange() {
      this.showReports = false;
      this.mandals = [];
      this.villages = [];
      this.f.mandal.patchValue('All');
      this.f.village.patchValue('All');
      let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
      this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + '').subscribe({
        next: (responseData: any) => {
          console.log(responseData,'mandals')
          if(responseData.result?.isSuccess) {
            this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
          } else {
            this.mandals = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
  
    onMandalChange() {
      this.showReports = false;
      this.villages = [];
     this.f.village.patchValue('All');
      this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
        next: (responseData: any) => {
          console.log(responseData,'villages')
          if(responseData.result?.isSuccess) {
            this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
          } else {
            this.villages = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    onVillageChange() {
      this.showReports = false;
    }
  
    onChangeFormat(item: any) {
      this.showReports = false;
      this.formatTypes.map((item:any) => {
        item.isActive = false;
        return item;
      });
      item.isActive = true;
      this.format = item.name;
      this.getFormat();
    }
    
    getFormat(): any {
        if (this.format === 'District Wise' ) {
        this.noOfCol = 16;
        return this.noOfCol;
      } else if (this.format === 'Mandal Wise') {
        this.noOfCol = 17;
        return this.noOfCol;
      } else {
        this.noOfCol = 18;
        return this.noOfCol;
      }
    }
    onRuralUrbanChange() {
      if(this.f['district'].value !== 'All') {
        this.onDistrictChange();
      }
    }
    getBeyondSLA(item: any, modal: any, from?: any) {
      let format: any;
      if (from === 'VRO') {
        this.title = 'Pending With VRO';
        this.formatWise = 'PENDING VRO';
      } else if (from === 'RI') {
        this.title = 'Pending With RI';
        this.formatWise = 'PENDING RI';
      } else if(from === 'TEH') {
        this.title = 'Pending With Tahsildar';
        this.formatWise = 'PENDING TEH';
      } else if(from === 'RDO') {
        this.title = 'Pending With RDO';
        this.formatWise = 'PENDING RDO';
      }  else if(from === 'COLLECTOR') {
        this.title = 'Pending With Collector';
        this.formatWise = 'PENDING COLLECTOR';
      } 
      else if(from === 'OTHER') {
        this.title = 'Pending With Other';
        this.formatWise = 'PENDING OTHER';
      }
      else if(from === 'PENDING TOTAL') {
        this.title = 'Total Pending';
        this.formatWise = 'PENDING TOTAL';
      }
      this.beyondSLAItems = [];
      this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
          this.popupPage = 1;
          let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
          let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
          let district = item.districT_CODE == null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode) : item.districT_CODE;
          let mandal = item.mandaL_CODE == null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode) : item.mandaL_CODE;
          let village = item.secretariaT_CODE === null ? (this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode):item.secretariaT_CODE;
          let postData: any = '?DID=' + district + '&MID=' + mandal + '&RURBAN=All' +'&SEC=' + village + '&Flag='+ this.formatWise +'&FromDate='+ fromDate + '&ToDate=' + toDate;
          this.commonService.getRequest(this.commonConstants.GetGrievanceReport + postData).subscribe({
            next: (responseData: any) => { 
              console.log(typeof(responseData?.result),'result man')
              if(typeof(responseData?.result) !== 'string') {
                this.beyondSLAItems = responseData?.result;
              } else {
                this.beyondSLAItems = [];
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
        });
        }
      
  
    getCasteGrievancesData(from?: any): any {
      this.submitted = true;
      if (!this.form.valid){
        return false;
      } else { 
          this.page = 1;
          let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
          let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
          let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
          let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
          let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
       
          let postData: any = '?DID=' + district + '&MID=' + mandal + '&RURBAN=All' +'&SEC=' + village + '&Flag='+ this.format +'&FromDate='+ fromDate + '&ToDate=' + toDate;
          this.commonService.getRequest(this.commonConstants.GetGrievanceReport + postData).subscribe({
            next: (responseData: any) => { 
              console.log(typeof(responseData?.result),'result man')
              if(typeof(responseData?.result) !== 'string') {
                  this.showReports = true;
                  this.items = responseData?.result;
                    this.items = this.items.filter(item => item.totaL_FLAG !== 'Y')
                    this.totalItems = responseData?.result.filter((item: { totaL_FLAG: string; }) => item.totaL_FLAG === 'Y')
                    console.log(this.items,'items')
                    console.log( this.totalItems ,'total items')
              } else {
                this.items = [];
                this.totalItems = [];
                //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
        });
        }
      }
     
     
    downloadExcel() {
      let fileName: any = 'Caste Grievance' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Caste Grievance');
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }
    
    saveAsExcelFile(buffer: any, fileName: string) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }
  }
