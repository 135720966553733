import { Component, Input, OnInit } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-common-form-view',
  templateUrl: './common-form-view.component.html',
  styleUrls: ['./common-form-view.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonFormViewComponent implements OnInit  {
  commonConstants : any = CommonConstants;
  @Input() basicInfo: any;
  @Input() presentAddr: any;
  @Input() permanentAddr: any;
  @Input() commonFormAddedFields : any;
   entityName: any;
   presentMandal: any;
   presentDistrict: any;
   permanentMandal: any;
   permanentDistrict: any;
   presentVillage: any;
   permanentVillage: any;
   aadharNum: any;
   departmentfromroute:any;
   role:any;
   department:any;
   aadharNumForSuomoto: any;
   urlpath: any;
   suomotodata: any;
  responseData: any = []; 

  constructor(public commonService: CommonService,private router: Router,
    private currentRoute: ActivatedRoute,) {
      this.currentRoute.queryParams.subscribe((params) => {
        this.departmentfromroute = params?.department;
        this.urlpath = this.currentRoute.snapshot.url[0].path
      });
   }

  ngOnInit(): void {
    // if(!this.presentAddr|| this.permanentAddr){
    // this.getaalDetails()
    // }
    let userInfo = this.commonService.getLocalStorage('userInfo');
  //  console.log(userInfo,'userInfo')
  this.commonService.sumotodata  = this.suomotodata;
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);    
      this.role = userData.jobTitle;
      if(this.role === 'Aarogyasri District Coordinator'){
        this.department = 'HF'
      }
      this.department =this.department ? this.department : this.departmentfromroute;
    }

    if(this.commonFormAddedFields?.aadharNum) {
      this.aadharNumForSuomoto = this.commonFormAddedFields?.aadhaarNum
    }
    else {
      this.aadharNumForSuomoto = this.basicInfo?.aadhaarNumber;
    }

    this.decryptAadharNum(this.aadharNumForSuomoto);
  }
  numberMasking(aadhaar:any) {
    var mask = "";
    if(aadhaar){
      for(let j=1; j<=aadhaar.length-4; j++){
        mask += "x";
      }
      return mask + aadhaar.slice(8,12);
    }
    else return null;
  }
  getaalDetails(){
    
    this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter(
      (item: { addressType: string }) => item.addressType === 'present'
    );

    this.permanentAddr =
              this.basicInfo?.applicantInfo?.addresses.filter(
                (item: { addressType: string }) =>
                  item.addressType === 'permanent'
              );
    this.getByEntitycode(this.presentAddr[0].district, 'District', 'present');
    this.getByEntitycode(this.presentAddr[0].mandalMunicipality, 'Mandal', 'present');
    this.getByEntitycode(this.presentAddr[0].villageWard, 'Secretariat', 'present');
    this.getByEntitycode(this.permanentAddr[0].district, 'District', 'permanent');
    this.getByEntitycode(this.permanentAddr[0].mandalMunicipality, 'Mandal', 'permanent');
    this.getByEntitycode(this.permanentAddr[0].villageWard, 'Secretariat', 'permanent');
    // console.log(this.basicInfo?.aadhaarNumber,'this.basicInfo?.aadhaarNumber')
    // this.decryptAadharNum(this.basicInfo?.aadhaarNumber)
  }

  // decryptAadharNum(aadhar : string){
  //   this.aadharNum = CryptoJS.AES.decrypt(aadhar, this.commonConstants.encryptDecryptPassword.trim()).toString(CryptoJS.enc.Utf8);
  // console.log(this.aadharNum,'this.aadharNum')
  // }
  getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
    next: (responseData: any) => { 
        let name =  responseData.result[0]?.name;
        console.log(entityType,responseData.result[0]?.name,'response names form common dashboard')
        if (entityType === 'District' && addrType === "present") {
          this.presentDistrict = name;
        }else if (entityType === 'Mandal' && addrType === "present") {
          this.presentMandal = name; 
        } else if (entityType === 'Secretariat' && addrType === "present") {
          this.presentVillage = name;
        } else if (entityType === 'District' && addrType === "permanent") {
          this.permanentDistrict = name;
        } else  if (entityType === 'Mandal' && addrType === "permanent") {
          this.permanentMandal = name;
        } else if (entityType === 'Secretariat' && addrType === "permanent") {
          this.permanentVillage = name;
        } 
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }


  decryptAadharNum(aadhar : string, from?: any){
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar))
      .subscribe({
        next: (responseData: any) => {
          // console.log(responseData,aadhar, 'aadhar decrypt value respose data');
          this.getCitizenInfo(responseData.result.rawValue)
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
  getCitizenInfo(aadharNumber: any) {
    let postData: any = {};
    postData.aadhaarNumber = aadharNumber
    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe((data: any) => {
      this.suomotodata = data.result[0];
  })

}
}


