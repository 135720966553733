import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-save-designations-positions',
  templateUrl: './save-designations-positions.component.html',
  styleUrls: ['./save-designations-positions.component.css']
})
export class SaveDesignationsPositionsComponent implements OnInit {
  form: UntypedFormGroup;
  commonConstants: any = CommonConstants;
  submitted: Boolean = false;
  validSecretariat: Boolean = false;
  secretariatData: any = [];
  designations: any = [];
  dataFetched: Boolean = false;
  dataSaved: Boolean = false;
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService) { 
    this.form = this.formBuilder.group({
      secretariatCode: [null, [Validators.required]],
      mobileNo: [null, [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]]
    });
  }

  ngOnInit(): void {
  }
  get f() {
    return this.form.controls;
  }
  reset() {
    this.f.secretariatCode.patchValue(null);
    this.f.mobileNo.patchValue(null);
    this.designations = [];
    this.secretariatData = [];
    this.submitted = false;
    this.validSecretariat = false;
    this.dataFetched = false;
    this.dataSaved = false;
  }
  getDetails(): any {
    this.secretariatData= [];
    this.designations = [];
    this.validSecretariat = true;
    if(!this.f.secretariatCode.valid)
      return false;
    this.commonService.getRequest(this.commonConstants.getSecDesignationDetails + this.f.secretariatCode.value).subscribe({
      next: (responseData: any) => { 
        this.secretariatData = responseData.result;
        console.log('this.secretariatData', this.secretariatData);
        if(this.secretariatData.secretariat_type !== null) {
          this.dataFetched = true;
          this.secretariatData.designations.forEach((el:any) => {
            let temp: any = {};
            temp.roleType = null;
            temp.designation = el;
            this.designations.push(temp)
          });
          console.log('this.designations', this.designations);
        } else {
          this.designations = [];
          this.secretariatData = [];
          console.log('this.secretariatData.designations?.join', this.secretariatData.designations);
          this.commonService.commonErrorPopup('Alert',responseData.result?.designations[0] , 'md');
        }
      },
      error: (error:any) => {
        this.secretariatData = [];
        this.designations = [];
        console.log('client side', error);
      }
    });
  }
  saveDetails():any {
    this.submitted = true;
    if(!this.form.valid)
      return false;
    else if(this.designations.filter((item:any) => item.roleType === null)?.length > 0) {
      return false;
    }
    let postData: any = {};
    let roleDetails: any = [];
    this.designations.forEach((item:any) => {
      let temp: any = {};
      temp.designation = item.designation;
      temp.roleType = item.roleType;
      roleDetails.push(temp);
    });
    
    postData.secretariatCode = this.f.secretariatCode.value;
    postData.ruralUrbanFlag = this.secretariatData.secretariat_type;
    postData.createdByMobileNo = this.f.mobileNo.value;
    postData.roleDetails = roleDetails;
    this.commonService.postRequest(this.commonConstants.saveSecDesignationDetails, postData).subscribe({
      next: (responseData: any) => { 
        if(responseData.result === 'Success') {
          this.dataSaved = true;
          this.commonService.commonErrorPopup('Alert', 'Details saved successfully.', 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', responseData.result , 'md');
        }
      },
      error: (error:any) => {
        console.log('client side', error);
      }
    });
  }
}
