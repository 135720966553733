import { Component, EventEmitter, Input, NgModuleRef, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { DatePipe } from '@angular/common';
import { DigitalSignService } from 'src/shared/digital-sign.service';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';

@Component({
  selector: 'app-revoke-worklist',
  templateUrl: './revoke-worklist.component.html',
  styleUrls: ['./revoke-worklist.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class RevokeWorklistComponent implements OnInit {
  showTable:Boolean=false;
  commonConstants : any = CommonConstants;
  transactionID:any
  reqData:any ;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  resultItems: any[] = [];
  checklist: any = [];
  public submitted:Boolean=false;
  serviceList: any = [];
  userInfo: any;
  public tableData: any[] = [];
   isSigning : boolean = false;
   public eMudhraSubmitted: Boolean = false;
   isVerifiedOTP : boolean = false;
   modalFormName : string = "";
  @Input('digitalSign') digiSignPopUp = true;
  isApproveExist: Boolean = false;
  @Output() docGenChange = new EventEmitter<any>();
  @Output() docSubChange = new EventEmitter<any>();
  tokenDetails :any;
  certificateList :any;
  modalFormButtonName : string = "";
  downloadCertId : string = '';
  tokenList: any;
  tknSerialNo : any;
  emBridgeSignerInput : any;
  certificateTokenId = '';
  keyStorePassphrase = '';
  taskId: any;
   mainRef : any;
  yAction:any
  yActionCode:any
  eMudhradisplayName: any;
  JSON: any;
  otpModalRef: any;
  statusUpdatePostData : any={}
  docList: any = [];
  emudhraformGroup: UntypedFormGroup;
  id: any;
  selectedRequistionId: any;
  departmentCode: any;
  eMudhraPopup: any;
  transactionDataPopup: any = [];
  verifytknSerialNo : string = "";
  isProdEnv: Boolean = false; 

  constructor(private modalService: NgbModal,private router: Router,private commonService: CommonService,private formBuilder: UntypedFormBuilder,private digiSign: DigitalSignService,private utils: CommonService, private activatedRoute: ActivatedRoute) {
    this.emudhraformGroup = this.formBuilder.group({
      ddTokens : [null, [Validators.required]],
      ddCertificates : [null, [Validators.required]],
      ddPassword : ['', [Validators.required]],
      });
    let userData = this.commonService.getLocalStorage('userInfo');
    if(userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
    }
    if (/^vswsonline.ap.gov.in/.test(window && window.location && window.location.hostname)) { 
      this.isProdEnv = true;
    }
   }
  ngOnInit(): void {
    this.isVerifiedOTP = false;
    this.getWorklistForRevoke();
    this.getUserIdSerialNoAndAadharNo();
  }

  getUserIdSerialNoAndAadharNo(){
    const userInformation:any  = this.commonService.getLocalStorage('userInfo');
    const loggedinDetails = JSON.parse(userInformation);
    const loggedinuserId = loggedinDetails.userPrincipalName;    
    this.commonService.getRequest(this.commonConstants.getUserIdDetails).subscribe({
      next: (responseData: any) => {        
        this.verifytknSerialNo = responseData.result[0]?.cert_SerialNo
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  get e() { return this.emudhraformGroup.controls; }

  getDashBoardForCertificatePreview(requisitionId:any,departmentCode:any){
    if(requisitionId?.substr(0, 2)?.toUpperCase() === 'MC') {
      departmentCode = 'RS';
    } else {
      departmentCode = 'RV';
    }
    this.transactionDataPopup = [];
    this.commonService.getRequest(this.commonConstants.getSignedCertificateDataForPreview + requisitionId + '&DepartmentCode='+ departmentCode).subscribe({
      next: (responseData: any) => {
        if(responseData.result.error == null){
          let fileInfo =  responseData.result.fileContents;
          const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
          modalRef.componentInstance.sourceFile = fileInfo;
          modalRef.componentInstance.showPrintDownload = false;   
        }else{
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'No Detail found...Please enter valid application number.';
          modalRef.result.then((result: any) => {});
        }    
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
}

  viewPreview(reqID:any,deptId:any,reqType:any) {
    const queryParams: Params = { id: reqID,department:deptId,type:reqType,from:'revokeModule'};
      this.router.navigate(['/application-preview'], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }
  
  loadTokens(): void {
    this.digiSign
      .tokenList()
      .then((resp: any) => {
        if(resp.success){
          console.log(resp.result,'resp.result')
          this.tokenList = resp.result;
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  onRadioClick(item:any) {
    this.items = this.items.map((item: any) => {
      item.isSelected = false;
      return item;
  }); 
    item.isSelected = true;
    this.selectedRequistionId = item.requisitionId
    this.id= item.id
    this.departmentCode = item.departmentCode
  }
  getCertificateName(){
    this.eMudhradisplayName =   this.emudhraformGroup.controls.ddCertificates.value.CommonName
    console.log(this.eMudhradisplayName,'certificate value')
  }
  tokenChange(): void {
    this.certificateList = [];
    this.tokenDetails = this.emudhraformGroup.controls.ddTokens.value
    let certReq = {
      keyStoreDisplayName: this.tokenDetails.keyStoreDisplayName,
      certFilter: {
        commonName: '',
        issuerName: '',
        serialNumber: '',
        isNotExpired: '',
      },
    };

    this.digiSign
      .certificateList(certReq)
      .then((resp: any) => {
        if(resp.success){
          this.certificateList = resp.result;
          this.eMudhradisplayName =   this.certificateList[0].CommonName
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }
  
  capitalizeWords(text : any){
    return text.replace(/(?:^|\s)\S/g,(res : any)=>{ return res.toUpperCase();})
  };
  signDoc(): void {
    let reasonM = "Approved";
    if(this.yAction == 'approve'){
      reasonM = 'Approved'
    }
    else if(this.yAction == 'reject'){
      reasonM = 'Rejected'
    }
    let certID = this.emudhraformGroup.controls.ddCertificates.value.KeyId
    this.keyStorePassphrase = this.emudhraformGroup.controls.ddPassword.value
    this.certificateTokenId = certID 
    var certReq = {
      keyStoreDisplayName : this.tokenDetails.keyStoreDisplayName,
      keyStorePassphrase: this.keyStorePassphrase,
      keyId : this.certificateTokenId,
      TempFolder : "",
      bulkInput : []
    };
    this.emBridgeSignerInput = [];
    this.emBridgeSignerInput=
    {
      Location : "Vijayawada", 
      Reason :  reasonM, 
      SignedBy : this.capitalizeWords( this.eMudhradisplayName) , 
      PageNo : this.pdfPageCount, 
      Coordinates : "75,170,225,230", 
      AppearanceText :"" ,
      IsAcro6Layer : true  ,
      DigitalTokenId : null,   
      DigitalCertificateId :  null, 
      TokenIdFromDevice :   null, 
              
       
    };
    console.log('certificate and password',certReq)
    this.digiSign
      .signDoc(certReq,this.emBridgeSignerInput,this.selectedRequistionId, this.departmentCode)
      .then((resp : any) => {  
        console.log('Sign resp',resp)     
          if(resp.success){
            if(resp.result[0]?.status){

              const res = {
                certificateId : resp.result[0]?.id,
                baseFile : resp.result[0]?.signedData
              }

              this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.selectedRequistionId + '&DepartmentCode=' +  this.departmentCode).subscribe({
                next: (responseData: any) => {
                  if(responseData.result?.isSuccess == false || responseData.result?.error != null){
                    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    modalRef.componentInstance.title = "Sign and Approve";
                    modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData?.result?.error : 'Error Occurs. Please try Again';
                  } else {
                   if(responseData.result.certificateData.status == 2){
                    this.putWorklist(this.yAction,this.yActionCode);
                    
                  }else{
                    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                    modalRef.componentInstance.title = "Sign and Approve";
                    modalRef.componentInstance.message = "Error - Certificate was not Signed Digitally.Please Try Again";
                  }
                }
                },
                error: (error) => {
                  this.isSigning = false;
                  
                  console.log('client side', error);
                }
              });
            this.docSubChange.emit(res);
            }else{
              this.isSigning = false;
              alert('Failed to Sign Document, Please try again !!!')
            }
          }  
      })
      .catch((error: any) => {
        
        this.utils.catchResponse(error);
      });
  }
  pdfPageCount = "1";
  getCertificate(certificateId: any,departmentName: string) {
      this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.selectedRequistionId + '&DepartmentCode=' +  this.departmentCode).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess == false || responseData.result?.error != null){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = "Sign and Approve";
            modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again';
            modalRef.close();
          } else {
              if(responseData.result.certificateData.status == 0){
                this.commonService.getRequest(this.commonConstants.getforApprovedCertificate + certificateId + '&Department=' + departmentName).subscribe({
                  next: (responseData: any) => {
                    if(responseData?.result?.isSuccess) {                         
                      this.downloadCertId  = responseData.result.fileName;
                      if( responseData.result.pageCount > 1){
                        for (let index = 0; index < responseData.result.pageCount; index++) {
                          var countVal = index +1
                          if(index == 0){
                            
                            this.pdfPageCount   = countVal.toString()
                          }else{
                            this.pdfPageCount = this.pdfPageCount +',' + countVal.toString()
                          } 
                        }
                      }
                      console.log(this.pdfPageCount,'this.pdfPageCount',responseData.result.pageCount)
                      this.isApproveExist = false;
                      this.commonService.getRequest(this.commonConstants.checkSignedCertData + this.selectedRequistionId+ '&DepartmentCode=' +  this.departmentCode).subscribe({
                        next: (responseData: any) => {
                          if(responseData.result?.isSuccess == false || responseData.result?.error != null){
                            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                            modalRef.componentInstance.title = "Sign and Approve";
                            modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again';
                            modalRef.close();
                          }
                          if(responseData.result.certificateData.status == 1 ){
                           this.signDoc();
                          }else{
                            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                            modalRef.componentInstance.title = "Sign and Approve";
                            modalRef.componentInstance.message = "Error - Certificate was not created.Please Try Again";
                            modalRef.close();
                          }
                        
                        },
                        error: (error) => {
                          this.isSigning = false;
                          
                          console.log('client side', error);
                        }
                      });
                      
                    console.log(responseData,'emudhra getCertificate');
                    } else {
                      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                      modalRef.componentInstance.title = 'Alert ';
                      modalRef.componentInstance.message = responseData?.result?.error != undefined || null ? responseData?.result?.error : 'Error Occurs. Please try Again';
                    }
                  },
                  error: (error) => {
                    this.isSigning = false;
                    
                    console.log('client side', error);
                  }
                });
              }else{
                const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                modalRef.componentInstance.title = "Sign and Approve";
                modalRef.componentInstance.message = "Error in Generating Certificate.Please Try Again";
                modalRef.close();
              }
            }
          },
          error: (error) => {
            this.isSigning = false;
            console.log('client side', error);
          }
          
      });  
  }
  postCertificate(){
    var sendPostData={
      RequisitionId: this.selectedRequistionId,
      Department:  this.departmentCode,
      CertificateType : 'PNSC'
    }
        this.commonService.postRequest(this.commonConstants.postforApprovedCertificate, sendPostData).subscribe({
          next: (responseData:any) => { 
            if(responseData?.result?.isSuccess) {  
              this.getCertificate(responseData.result.certificateId, this.departmentCode)
             } else {
               const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
               modalRef.componentInstance.title = "Alert";
               modalRef.componentInstance.message = responseData.result.error != undefined || null ? responseData.result.error : 'Error Occurs. Please try Again';
             }
          },
          error: (error) => {
            this.isSigning = false;
            
            console.log('client side', error);
          }
        });
  }

  btnSignDoc() : any {
    this.eMudhraSubmitted = true;
    if (!this.emudhraformGroup.valid )
      return false;
    else if(this.emudhraformGroup.valid ){ 
      this.isSigning = true;
      if(this.emudhraformGroup.controls.ddTokens.value && this.emudhraformGroup.controls.ddTokens.value.keyStoreID) {
        let certID = this.emudhraformGroup.controls.ddTokens.value.keyStoreID;
        this.tknSerialNo =  certID //JSON.parse(certID).SerialNumber
      } else {
        this.tknSerialNo = "";
      }
      this.docGenChange.emit();
    }
    if((this.tknSerialNo.length > 0 && this.verifytknSerialNo.length > 0) || this.isProdEnv == false){
      if((this.tknSerialNo === this.verifytknSerialNo) || this.isProdEnv == false) {
        if(this.yActionCode==3){
          this.postCertificate()
        } else{
        this.putWorklist(this.yAction,this.yActionCode);
        }
      }else{
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.message = 'Incorrect serial number. Please Try Again.'
      }
    } else {
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.message = 'Access Denied !', 'Yor are not the Authorized user to perform this operation'
      this.mainRef.close();
    }
  }
  approveReject(action: any,actionCode:any,targetModal: any): any {
    if(this.items.filter((data: any) =>data.isSelected).length===0){
      this.commonMsgPopup('Please select any application to either approve or reject.');
      return false;
    }
    this.yAction = action
    this.yActionCode =actionCode 
    this.modalFormName = "Digital Signature";
    if(action === 'approve'){
      this.modalFormButtonName = "Sign and Approve";
    } else {
      this.modalFormButtonName = "Sign and Reject";
    }
    if (this.digiSignPopUp) {
      this.loadTokens();
    }
    this.isSigning = false;
    this.eMudhraPopup =  this.modalService.open(targetModal, 
      {   size: 'lg' ,
          centered: true,
          backdrop: 'static'
        }
      );
  }
    putWorklist(action: any, actionCode: any){
     
    this.yActionCode ="" 
    this.submitted = true;
    this.yAction = action
    this.yActionCode =actionCode 
    let postData: any = {};
      postData.taskId = this.id; 
      postData.comment = '';
      postData.action = actionCode;
  
      this.commonService.putRequest(this.commonConstants.approveReject, postData).subscribe({
        next: (resData:any) => { 
          let msg:string;
          let responseData = resData.result;
          if(typeof(responseData) === 'string') {
            this.commonMsgPopup(resData.result);
          } else if(action === 'approve' && typeof(responseData) !== 'string') {
            this.commonMsgPopup('Application Approved successfully.');
          } else if(action === 'reject' && typeof(responseData) !== 'string') { 
            this.commonMsgPopup('Application rejected successfully.');
          }
          this.eMudhraPopup.close();
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
    commonMsgPopup(msg: any) {
      const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md' });
      modalRef.componentInstance.title = 'Alert ';
      modalRef.componentInstance.message = msg;
      modalRef.result.then((result: any) => {
        this.getWorklistForRevoke();
      });
     }
  getWorklistForRevoke(){
     this.resultItems = [];
     this.items = [];
     this.page = 1;
     let postData = '?DepartmentCode=' + this.departmentCode + '&RequisitionType=' + "RevokeCertificate"
     this.commonService.getRequest(this.commonConstants.GetWorklistForRevoke + postData).subscribe({
     next: (responseData: any) => {        
       let serviceList = responseData?.result?.items;
       if (serviceList && serviceList.length > 0) {
           let i=1;
           serviceList.forEach((element: any) => {
           let temp: any = {};
           temp.SNo = i++;
           temp.id=element.id;
           temp.requisitionId = element.extendedProperties.requisitionId;
           temp.applicantName = element.extendedProperties.applicantName;
           temp.departmentCode = element.extendedProperties.departmentCode;
           temp.requestedDate = element.extendedProperties.requestedDate;
           temp.serviceCode = element.extendedProperties.serviceCode;
           temp.requestReason = element.extendedProperties.requestReason;
           temp.fatherHusbandName = element.extendedProperties.fatherHusbandName;
           temp.mandalName = element.extendedProperties.mandalName;
           temp.secretariateName = element.extendedProperties.secretariateName;
           temp.isSelected = false;
           this.items.push(temp);
   });   
   this.items = this.items.map((item: any) => {
    item.isSelected = false;
    return item;
});     
  }
  },
  error: (error) => {
  console.log('client side', error);
   }
  });
 }
}
