import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-vbsy-inventory',
  templateUrl: './vbsy-inventory.component.html',
  styleUrls: ['./vbsy-inventory.component.css']
})
export class VBSYInventoryComponent implements OnInit {

  vbsyInventoryForm: UntypedFormGroup;
  submitted: boolean = false;
  commonConstants: any = CommonConstants;
  currentDate = this.commonService.regularDateFormat(new Date());

  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService,private router: Router) {
    this.vbsyInventoryForm = this.formBuilder.group({
      openingStockCalendars: ['', Validators.required],
      openingStockBooklets: ['', Validators.required],
      openingStockBrochures: ['', Validators.required],
      openingStockTShirts: ['', Validators.required],
      openingStockCaps: ['', Validators.required],
      openingStockBadges: ['', Validators.required],
      receivedTodayCalendars: ['', Validators.required],
      receivedTodayBooklets: ['', Validators.required],
      receivedTodayBrochures: ['', Validators.required],
      receivedTodayTShirts: ['', Validators.required],
      receivedTodayCaps: ['', Validators.required],
      receivedTodayBadges: ['', Validators.required],
      distributedTodayCalendars: ['', Validators.required],
      distributedTodayBooklets: ['', Validators.required],
      distributedTodayBrochures: ['', Validators.required],
      distributedTodayTShirts: ['', Validators.required],
      distributedTodayCaps: ['', Validators.required],
      distributedTodayBadges: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.vbsyInventoryForm.controls;
  }

  submit():any {
    this.submitted = true;
    let closingStockCalendars = +this.f.openingStockCalendars.value + +this.f.receivedTodayCalendars.value - +this.f.distributedTodayCalendars.value;
    let closingStockBooklets = +this.f.openingStockBooklets.value + +this.f.receivedTodayBooklets.value - +this.f.distributedTodayBooklets.value;
    let closingStockBrochures = +this.f.openingStockBrochures.value + +this.f.receivedTodayBrochures.value - +this.f.distributedTodayBrochures.value;
    let closingStockTShirts = +this.f.openingStockTShirts.value + +this.f.receivedTodayTShirts.value - +this.f.distributedTodayTShirts.value;
    let closingStockCaps = +this.f.openingStockCaps.value + +this.f.receivedTodayCaps.value - +this.f.distributedTodayCaps.value;
    let closingStockBadges = +this.f.openingStockBadges.value + +this.f.receivedTodayBadges.value - +this.f.distributedTodayBadges.value;
    if(!this.vbsyInventoryForm.valid || closingStockCalendars < 0 || closingStockBooklets < 0 || closingStockBrochures < 0 || closingStockTShirts < 0 || closingStockCaps < 0 || closingStockBadges < 0) {
      return false;
    }

    let postData = "Flag=SET_DISTRICT_STAT&";
    postData += "Date=" + this.currentDate + "&";
    postData += "OpenCalendar=" + this.f.openingStockCalendars.value + "&";
    postData += "OpenBooklet=" + this.f.openingStockBooklets.value + "&";
    postData += "OpenBrochure=" + this.f.openingStockBrochures.value + "&";
    postData += "OpenTShirt=" + this.f.openingStockTShirts.value + "&";
    postData += "OpenCaps=" + this.f.openingStockCaps.value + "&";
    postData += "OpenBadge=" + this.f.openingStockBadges.value + "&";
    postData += "ReceivedCalendar=" + this.f.receivedTodayCalendars.value + "&";
    postData += "ReceivedBooklet=" + this.f.receivedTodayBooklets.value + "&";
    postData += "ReceivedBrochure=" + this.f.receivedTodayBrochures.value + "&";
    postData += "ReceivedTShirt=" + this.f.receivedTodayTShirts.value + "&";
    postData += "ReceivedCaps=" + this.f.receivedTodayCaps.value + "&";
    postData += "ReceivedBadge=" + this.f.receivedTodayBadges.value + "&";
    postData += "DistributedCalendar=" + this.f.distributedTodayCalendars.value + "&";
    postData += "DistributedBooklet=" + this.f.distributedTodayBooklets.value + "&";
    postData += "DistributedBrochure=" + this.f.distributedTodayBrochures.value + "&";
    postData += "DistributedTShirt=" + this.f.distributedTodayTShirts.value + "&";
    postData += "DistributedCaps=" + this.f.distributedTodayCaps.value + "&";
    postData += "DistributedBadge=" + this.f.distributedTodayBadges.value + "&";
    postData += "ClosedCalendar=" + closingStockCalendars + "&";
    postData += "ClosedBooklet=" + closingStockBooklets + "&";
    postData += "ClosedBrochure=" + closingStockBrochures + "&";
    postData += "ClosedTShirt=" + closingStockTShirts + "&";
    postData += "ClosedCaps=" + closingStockCaps + "&";
    postData += "ClosedBadge=" + closingStockBadges;
 
    this.commonService.getRequest(this.commonConstants.getVBSYScreenDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess) {
          this.submitted = false;
          this.commonService.commonErrorPopup('Alert', 'District statistics submitted successfully.', 'md');
          this.vbsyInventoryForm.reset();
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while saving the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  goToDayNodalReport(){
    this.router.navigate(['/vbd-report']);
  }
  goToDistrictBalanceStationaryReport(){
    this.router.navigate(['/viksit-bharat-report']);
  }
}
