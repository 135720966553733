import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collection-payment-report',
  templateUrl: './collection-payment-report.component.html',
  styleUrls: ['./collection-payment-report.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class CollectionPaymentReportComponent implements OnInit {
  
  commonConstants : any = CommonConstants;
  form: UntypedFormGroup;
  htmlStr: any;
  districts: any[] = [];
  revenue:any[]=[];
  divisions:any[]= [];
  mandals: any[] = [];
  source: any[] = [];
  villages: any[]= [];
  departments: any[] = [];
  services: any[] = [];
  formatTypes: any[]= [];
  format: any;
  maxDate: any;
  minDate: any;
  toMinDate: any;
  currentDate = new Date();
  submitted: Boolean = false;
  defaultFromDate: any;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  showReports: Boolean = false;
  totalReport: Boolean = false;
  noOfCol: any = 24;
  popupPageSize = 10;
  popupPage = 1;
  beyondSLAItems: any[] = [];
  totalItems: any[] = [];
  postData: any = {};
  role: any;
  title: any;
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('BEYONDSLATABLE') beyondSLATable!: ElementRef;
  constructor(private formBuilder: UntypedFormBuilder, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal, private datePipe: DatePipe, private router: Router) { 
    config.backdrop = 'static';
    config.keyboard = false;
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null],
      department: [null],
      service: [null],
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
      revenue: [null],
      source:[null]
      });
      
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.toMinDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    }
    this.getDistricts();
    this.getDepartment();
  }

  ngOnInit(): void {
 
  this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
   this.format = 'District Wise';
    this.form.patchValue({
      district:'All',
      mandal:'All',
      village: 'All',
      RUFlag: 'All',
      department: 'All',
      service: 'All',
      fromDate: this.defaultFromDate,
      toDate: this.defaultFromDate,
      revenue: 'All',
      source: 'All'
      });
    this.formatTypes = [{name: 'District Wise', isActive: true},
    {name:'Revenue Division Wise', isActive: false},
    {name:'Mandal Wise', isActive: false},
    {name:'Secretariat Wise', isActive: false},
    {name:'Department Wise', isActive: false},
    {name:'Service Wise', isActive: false},
    {name:'Month Wise', isActive: false},
      ]

      
    this.getCollectionPaymentReportData();
    let userInfo = this.commonService.getLocalStorage('userInfo');
      if (userInfo && userInfo !== null) {
        let userData = JSON.parse(userInfo);
        this.role = userData.jobTitle;
      }
  }
 
  
 get f() { return this.form.controls; }
  
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  onRuralUrbanChange() {
   if(this.f['district'].value === 'All') {
      this.onDistrictChange();
    }
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.divisions =[];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    this.f.revenue.patchValue('All');
    
    this.getMandals();
  this.getRevenueDivison()
  }
  getMandals(){
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
   
    let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
    let revenueDivision:any= this.f.revenue.value==='All'?'':this.f.revenue.value.revenueDivision;
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode  + '&RevenueDivisionCode=' + revenueDivision).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  this.onVillageChange();
  }
   getRevenueDivison(){
    this.commonService.getRequest(this.commonConstants.GetRevenueDivisionByDistrict  + this.f['district'].value.districtCode).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.divisions = this.commonService.sortItems(responseData.result?.revenueDivisionDetails,'revenueDivisionName');
         } else {
          this.divisions = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onMandalChange() {
    this.showReports = false;
    this.villages = [];
    this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onVillageChange() {
    this.showReports = false;
  }
  getDepartment() {
    this.commonService.getRequest(this.commonConstants.getCSPDeparments).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.departments = this.commonService.sortData(responseData.result?.departmentNames);
        } else {
          this.departments = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onDeptChange() {
    this.showReports = false;
    this.services = [];
    this.f.service.patchValue('All');
    
    this.postData={
      DepartmentName : this.f.department.value
    }
    this.commonService.postRequest(this.commonConstants.getCSPService , this.postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.services = this.commonService.sortData(responseData.result?.serviceNames);
        } else {
          this.services = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onServiceChange() {
    this.showReports = false;
  }
  getReportData(): any {}
  onFromDateSelect(event: any) {
    this.showReports = false;
    this.toMinDate = event;
  }
  onToDateSelect(event: any) {
    this.showReports = false;
  }
  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  downloadExcel() {
    let fileName: any = 'Collection & Payment Report from '+ this.f.fromDate.value +' to '+ this.f.toDate.value;
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Collection & Payment Report');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  downloadExcelForBeyondSLA() {
    let fileName: any = this.title + ' ' + this.f.fromDate.value +' to '+ this.f.toDate.value +')';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.beyondSLATable.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
      
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let sheetName :any = "Pending Transactions";
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // ws['!cols'] = wscols;
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // let sheetName = this.title === 'Pending Transactions' ? this.title : (this.title === 'Pending Transactions'? this.title :(this.title === 'Pending Transactions'));
    // XLSX.utils.book_append_sheet(wb, ws, sheetName);
    
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  getFormat(): any {
    if (this.format === 'District Wise' || this.format === 'Department Wise') {
      this.noOfCol = 16;
      return this.noOfCol;
    } else if (this.format === 'Service Wise') {
      this.noOfCol =17;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 16;
      return this.noOfCol;
    }else if (this.format === 'Month Wise') {
        this.noOfCol = 16;
        return this.noOfCol;
    }else {
          this.noOfCol = 18;
          return this.noOfCol;
        }
      }
    
  
  
      getCollectionPaymentReportData(from?: any): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } 
    else { 
        this.page = 1;
        let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd-MM-yyyy')
        let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd-MM-yyyy')
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
        let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
        let dept = this.f.department.value === 'All' ? 'All' : this.f.department.value;
        let service = this.f.service.value === 'All' ? 'All' : this.f.service.value;
        let source = this.f.source.value === 'All' ? 'All' : this.f.source.value;
       let revenue = this.f.revenue.value === 'All' ? 'All' : this.f.revenue.value?.revenueDivision;
        dept = encodeURIComponent(dept);
        let postData: any = '?Flag='+ this.format +'&DistrictId=' + district + '&RevenueDivisionCode=' +  revenue + '&RuralUrban=' + RUFlag +'&MandalId=' + mandal +'&SeceratraiteCode=' + village + '&Department=' + dept +'&ServiceName=' + service + '&Source=' +  source +'&FromDate=' + fromDate + '&ToDate=' + toDate;
        console.log("working",postData);
        this.commonService.getRequest(this.commonConstants.getCollectionPaymentReportData + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter(item => item.totaL_FLAG !== 'Y')
                  this.totalItems = responseData?.result.filter((item: { totaL_FLAG: string; }) => item.totaL_FLAG === 'Y')
            } else {
              this.items = [];
              this.totalItems = [];
              }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
      // }
  }
  getPendingReport(item: any, modal: any, from?: any) {
    let format: any;
    this.beyondSLAItems = [];
    this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
        this.popupPage = 1;
        let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd-MM-yyyy')
        let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd-MM-yyyy')
        let district = item.lgD_DIST_CODE === null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode) : item.lgD_DIST_CODE;
        let mandal = item.lgD_MANDAL_CODE === null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode) : item.lgD_MANDAL_CODE;
        let village = item.secretariaT_CODE === null ? (this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode) : item.secretariaT_CODE;
        let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
        let dept = item.department === null ? (this.f.department.value === 'All' ? 'All' : this.f.department.value) : item.department;
        let service = item.servicename === null ? (this.f.service.value === 'All' ? 'All' : this.f.service.value) : item.servicename;
        let source = this.f.source.value === 'All' ? 'All' : this.f.source.value;
        dept = encodeURIComponent(dept);// revenuE_DIV_CODE, bank
        let revenue = item.revenuE_DIV_CODE === null ? (this.f.revenue.value === 'All' ? 'All' : this.f.revenue.value?.revenueDivision) : item.revenuE_DIV_CODE;
        let postData: any = '?Flag='+ format +'&DistrictId=' + district + '&RevenueDivisionCode=' + revenue + '&RuralUrban=' + RUFlag +'&MandalId=' + mandal +'&SeceratraiteCode=' + village + '&Department=' + dept +'&ServiceName=' + service + '&Source=' +  source +'&FromDate=' + fromDate + '&ToDate=' + toDate;
        console.log("post",postData)
        this.commonService.getRequest(this.commonConstants.getCollectionPaymentReportData + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
              this.beyondSLAItems = responseData?.result;
            } else {
              this.beyondSLAItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  }

