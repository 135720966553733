import { PaymentChallanHistoryComponent } from './payment-challan-history/payment-challan-history.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonDashboardComponent } from './common-dashboard/common-dashboard.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { ViewTransactionsComponent } from './view-transactions/view-transactions.component';
import { RevokeProvisionComponent } from './revoke-provision/revoke-provision.component';
import { RevokeWorklistComponent } from './revoke-worklist/revoke-worklist.component';
import { TransactionStatusComponent } from './transaction-status/transaction-status.component';
import { HomeComponent } from './home/home.component';
import { EMudraComponent } from './e-mudra/e-mudra.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { ApplicationPreviewComponent } from './application-preview/application-preview.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { PaymentResponsePaytmComponent } from './payment-response-paytm/payment-response-paytm.component';
import { ErrorPageNotFoundComponent } from './error-page-not-found/error-page-not-found.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AuthGuard } from '../shared/auth.guard';
import { ChallanGenerationComponent } from './payment-challan-generation/challan-generation.component';
import { ExcelReportComponent } from './excel-report/excel-report.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { SignatureUploadComponent } from './signature-upload/signature-upload.component';
import { CspServiceReportComponent } from './csp-service-report/csp-service-report.component';
import { CspPaymentReportComponent } from './csp-payment-report/csp-payment-report.component';
import { GoodgovernmentreportComponent } from './goodgovernmentreport/goodgovernmentreport.component';
import { CollectionPaymentReportComponent } from './collection-payment-report/collection-payment-report.component';
import { PassbookRelatedServiceStatusReportComponent } from './passbook-related-service-status-report/passbook-related-service-status-report.component';
// import { ErrorApplicationReportComponent } from './error-application-report/error-application-report.component';
import { VolunteerAppreciationReportComponent } from './volunteer-appreciation-report/volunteer-appreciation-report.component';
import { PassbookRelatedServiceReportComponent } from './passbook-related-service-report/passbook-related-service-report.component';

import { JaganannaSurakshaReportComponent } from './jagananna-suraksha-report/jagananna-suraksha-report.component';
import { JaganannaScheduleSearchComponent } from './jagananna-schedule-search/jagananna-schedule-search.component';
import { LaminationComponent } from './lamination/lamination.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { ReconsulationPaymentStatusComponent } from './reconsulation-payment-status/reconsulation-payment-status.component';
import { MutationForCorrectionsComponent } from './mutation-for-corrections/mutation-for-corrections.component';
import { MutationForTransactionsComponent } from './mutation-for-transactions/mutation-for-transactions.component';
import {FailedtransactionsComponent} from './failedtransactions/failedtransactions.component';
import { ExternalPaymentComponent } from './external-payment/external-payment.component';
import { ExternalPaymentGatewayComponent } from './external-payment-gateway/external-payment-gateway.component';
import { ExternalPaymentResponseComponent } from './external-payment-response/external-payment-response.component';
import{OtsPaymentReportComponent}from './ots-payment-report/ots-payment-report.component';
import { PensionReportComponent } from './pension-report/pension-report.component';
import { RevenueShareReportComponent } from './revenue-share-report/revenue-share-report.component';
import { PrintServiceComponent } from './print-service/print-service.component';
import { CertificateDownloadComponent } from './certificate-download/certificate-download.component';
import { AddressCertificateForAadharEnrollmentComponent } from './address-certificate-for-aadhar-enrollment/address-certificate-for-aadhar-enrollment.component';
import { PaymentReconciliationComponent } from './payment-reconciliation/payment-reconciliation.component';
import { PaymentReconciliationReportComponent } from './payment-reconciliation-report/payment-reconciliation-report.component';
import { ChallanMappingComponent } from './challan-mapping/challan-mapping.component'
import { TeamFormationComponent } from './team-formation/team-formation.component';
import { TeamScheduleComponent } from './team-schedule/team-schedule.component';
import { TokenRegistrationComponent } from './token-registration/token-registration.component';
import { ScheduleReportComponent } from './schedule-report/schedule-report.component';
import { JaganannaSurakshaDashboardComponent } from './jagananna-suraksha-dashboard/jagananna-suraksha-dashboard.component';
import { JaganannaSurakshaCampStatusReportComponent } from './jagananna-suraksha-camp-status-report/jagananna-suraksha-camp-status-report.component';
import { JaganannaSurakshaTokenGenerationReportComponent } from './jagananna-suraksha-token-generation-report/jagananna-suraksha-token-generation-report.component';
import { PallekuPodamComponent } from './palleku-podam/palleku-podam.component';
import { UnveilingOfWelfareSchemesDisplayComponent } from './unveiling-of-welfare-schemes-display/unveiling-of-welfare-schemes-display.component';
import { SaveDesignationsPositionsComponent } from './save-designations-positions/save-designations-positions.component';
import { GswsDynamicSurveyReportComponent } from './gsws-dynamic-survey-report/gsws-dynamic-survey-report.component';
import { AarogyaSurakshaTeamFormComponent } from './aarogya-suraksha-team-form/aarogya-suraksha-team-form.component';
import { AarogyaSurakshaDoctorAssignDhmoComponent } from './aarogya-suraksha-doctor-assign-dhmo/aarogya-suraksha-doctor-assign-dhmo.component';
import { AarogyaTeamScheduleComponent } from './aarogya-team-schedule/aarogya-team-schedule.component';
import { ArogyaSurakshaReportComponent } from './arogya-suraksha-report/arogya-suraksha-report.component';
import { ArogyaSurakshaDashboardComponent } from './arogya-suraksha-dashboard/arogya-suraksha-dashboard.component';
import { AarogyaSurakshaTokenGenerationComponent } from './aarogya-suraksha-token-generation/aarogya-suraksha-token-generation.component';
import { WelfareSchemeProgramReportComponent } from './welfare-scheme-program-report/welfare-scheme-program-report.component';
import { ResheduleWelfareSchemesComponent } from './reshedule-welfare-schemes/reshedule-welfare-schemes.component';
import { PassbookServiceReportComponent } from './passbook-service-report/passbook-service-report.component';
import { AadhaarChallanGenerationComponent } from './aadhaar-challan-generation/aadhaar-challan-generation.component';
import { PensionUrbanElectricityTagComponent } from './pension-ui/pension-urban-electricity-tag/pension-urban-electricity-tag.component';
import { MonitoringDashboardForPANCollectionComponent } from './monitoring-dashboard-for-pan-collection/monitoring-dashboard-for-pan-collection.component';
import { PanDetailsCollectionComponent } from './pan-details-collection/pan-details-collection.component';
import { ViksitBharatDistributionComponent } from './viksit-bharat-distribution/viksit-bharat-distribution.component';
import { ViksitBharatReportComponent } from './viksit-bharat-report/viksit-bharat-report.component';
import { VBSYInventoryComponent } from './vbsy-inventory/vbsy-inventory.component';
import { ViksitBharatDistributionReportComponent} from './viksit-bharat-distribution-report/viksit-bharat-distribution-report.component';
import { VillageMappingInTahsildharComponent } from './village-mapping-in-tahsildhar/village-mapping-in-tahsildhar.component';
import { UtilizationCertificateComponent } from './utilization-certificate/utilization-certificate.component';
import { UCReportComponent } from './uc-report/uc-report.component';
import { PensionsReportComponent } from './pensions-report/pensions-report.component';
import { SearchScreenCasteComponent } from './search-screen-caste/search-screen-caste.component';
import { SecreteriateRevenueVillageMappingReportComponent } from './secreteriate-revenue-village-mapping-report/secreteriate-revenue-village-mapping-report.component';
import { SecreteriatePanUpdatedReportComponent } from './secreteriate-pan-updated-report/secreteriate-pan-updated-report.component';
import { SadaremprintCertificateComponent } from './sadaremprint-certificate/sadaremprint-certificate.component';
import { VolunteerAppreciationProgramDetailsComponent } from './volunteer-appreciation-program-details/volunteer-appreciation-program-details.component';
import { AadharChallanGenerationReportComponent } from './aadhar-challan-generation-report/aadhar-challan-generation-report.component';
import { LandAssignmentToSoldiersViewComponent } from './revenue-view/land-assignment-to-soldiers-view/land-assignment-to-soldiers-view.component';
import { LandAssignmentViewNewComponent } from './revenue-view/land-assignment-view-new/land-assignment-view-new.component';
import { SuomotoPendingApplicationsReportComponent } from './suomoto-pending-applications-report/suomoto-pending-applications-report.component';
import { GswsFeedbackComponent } from './gsws-feedback/gsws-feedback.component';
import { MeesevaFeedbackComponent } from './meeseva-feedback/meeseva-feedback.component';
import { SuomotoCasteVerificationReportComponent } from './suomoto-caste-verification-report/suomoto-caste-verification-report.component';
import { FeedbackStatusComponent } from './feedback-status/feedback-status.component';
import { GoodGovernmentComponent } from './good-government/good-government.component';
import { ReissuanceReportComponent } from './reissuance-report/reissuance-report.component';
import { OnlinePaymentTermsConditionsComponent } from './online-payment-terms-conditions/online-payment-terms-conditions.component';
import { CasteSurveyGrievancesDashboardComponent } from './caste-survey-grievances-dashboard/caste-survey-grievances-dashboard.component';
import { SocialAuditGrievanceComponent } from './social-audit-grievance/social-audit-grievance.component';
import { ApsevaFeedbackComponent } from './apseva-feedback/apseva-feedback.component';

const routes: Routes = [
  //{ path: '',  loadChildren: () => import('./revenue/revenue.module').then(m => m.RevenueModule), data:{showMenu:true} },
  { path: 'home', component: HomeComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  // { path: 'apseva-feedback', component: GswsFeedbackComponent },
  { path: 'apseva-feedback', component: ApsevaFeedbackComponent },
  { path: 'meeseva-feedback', component: MeesevaFeedbackComponent },
  { path: 'feedback-status', component: FeedbackStatusComponent },
  { path: 'online-payment-terms-conditions', component: OnlinePaymentTermsConditionsComponent },
  { path: 'good-government', component: GoodGovernmentComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Panchayat Secretary', 'Ward Admin Secretary']}, canActivate: [AuthGuard] },
  { path: 'suomoto', component: CommonDashboardComponent, data:{showMenu:false,showDashboardMenu: false, showHeader: true} },
  { path: 'document-list', component: DocumentListComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Tahsildar']}, canActivate: [AuthGuard]} ,
  { path: 'about-us', component: AboutUsComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'revenue', loadChildren: () => import('./revenue/revenue.module').then(m => m.RevenueModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Citizen', 'Meeseva']} },
  { path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Citizen', 'Meeseva']} },
  { path: 'minority-welfare', loadChildren: () => import('./minority-welfare/minority-welfare.module').then(m => m.MinorityWelfareModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'civil-supplies', loadChildren: () => import('./civil-supplies/civil-supplies.module').then(m => m.CivilSuppliesModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Citizen']}},
  { path: 'pension-ui', loadChildren: () => import('./pension-ui/pension-ui.module').then(m => m.PensionUiModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary','IT Minister']}, canActivate: [AuthGuard] },
  { path: 'maud', loadChildren: () => import('./maud/maud.module').then(m => m.MAUDModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'human-resources', loadChildren: () => import('./human-resources/human-resources.module').then(m => m.HumanResourcesModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary','Citizen']}},
  { path: 'health', loadChildren: () => import('./health/health.module').then(m => m.HealthModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary','Aarogyasri District Coordinator', 'Citizen']} },
  { path: 'health-view', loadChildren: () => import('./health-view/health-view.module').then(m => m.HealthViewModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary','Aarogyasri District Coordinator']}, canActivate: [AuthGuard] },
  { path: 'labour-registration', loadChildren: () => import('./labour-registration/labour-registration.module').then(m => m.LabourRegistrationModule), data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  //{ path: 'prof-tax-reg', component: ProfTaxRegComponent, data:{showMenu:true} },
  //{ path: 'dashboard', component: DashboardComponent, data:{showDashboardMenu: true} },
  { path: 'common-dashboard', component: CommonDashboardComponent, data:{showMenu:false,showDashboardMenu: false, showHeader: true} },
  { path: 'suomoto-caste-verification-report', component: SuomotoCasteVerificationReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Tahsildar','Revenue Divisonal Officer', 'Joint Collector', 'Collector', 'Reports Admin']},canActivate: [AuthGuard] }, 
  { path: 'payment-gateway', component: PaymentGatewayComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Citizen', 'Meeseva', 'Village Revenue Officer', 'Panchayat Secretary', 'Ward Admin Secretary', 'Welfare & Educational Assistant', 'Ward Revenue Secretary', 'Ward Welfare & Development Secretary','Zilla Sainik Welfare Officer','IT Minister'],isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
  { path: 'view-transactions', component: ViewTransactionsComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary','Aarogyasri District Coordinator', 'Citizen', 'Meeseva','IT Minister'],isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
  { path: 'view-transactionzswo', component: ViewTransactionsComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: true, roles: ['Zilla Sainik Welfare Officer'],isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
  { path: 'revoke-provision', component: RevokeProvisionComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: true, roles: ['Tahsildar','Deputy Tahsildar','Revenue Divisonal Officer','Municipal Commissioner','Panchayat Secretary DDO'],isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
  { path: 'revoke-worklist', component: RevokeWorklistComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: true, roles: ['Joint Collector'],isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
   { path: 'print-soldier-certificate', component: LandAssignmentToSoldiersViewComponent, data: {showMenu: false, showDashboardMenu: false}},
   { path: 'land-assignment-view', component: LandAssignmentViewNewComponent, data: {showMenu: false, showDashboardMenu: false}},
  // { path: 'registration-tokens', component: TokenRegistrationComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Village Revenue Officer', 'Panchayat Secretary', 'Ward Admin Secretary', 'Welfare & Educational Assistant', 'Ward Revenue Secretary', 'Ward Welfare & Development Secretary']}, canActivate: [AuthGuard] },
  // { path: 'registration-token', component: TokenRegistrationComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'view-challan', component: ChallanGenerationComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'payment-issue-tracker', component: PaymentReconciliationComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'payment-issue-tracker-report', component: PaymentReconciliationReportComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'challan-history', component: PaymentChallanHistoryComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'transaction-status', component: TransactionStatusComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary','Aarogyasri District Coordinator']}, canActivate: [AuthGuard] },
  { path: 'application-preview', component: ApplicationPreviewComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary','Joint Collector','Aarogyasri District Coordinator', 'Citizen', 'Meeseva', 'Zilla Sainik Welfare Officer','IT Minister'],isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
  { path: 'payment-response', component: PaymentResponseComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Citizen', 'Meeseva','Zilla Sainik Welfare Officer','IT Minister'],isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
  { path: 'payment-response-paytm', component: PaymentResponsePaytmComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'payment-receipt', component: PaymentReceiptComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Citizen', 'Meeseva','Zilla Sainik Welfare Officer','IT Minister'], isCitizen: true, isMeeseva: true}, canActivate: [AuthGuard] },
  { path: 'acknowledgement-receipt', component: PaymentReceiptComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Village Revenue Officer', 'Panchayat Secretary', 'Ward Admin Secretary', 'Welfare & Educational Assistant', 'Ward Revenue Secretary', 'Ward Welfare & Development Secretary'], isCitizen: false, isMeeseva: false}, canActivate: [AuthGuard] },
  { path: 'lamination', component: LaminationComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },  
  { path: 'print-service', component: PrintServiceComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  // { path: 'caste-survey-grievance', component: SocialAuditGrievanceComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'address-certificate', component: AddressCertificateForAadharEnrollmentComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },  
  { path: 'e-mudhra', component: EMudraComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: true} },
  { path: 'excel-report', component: ExcelReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'csp-service-report', component: CspServiceReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'csp-payment-report', component: CspPaymentReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'good-government-report', component: GoodgovernmentreportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'reissuance-report', component: ReissuanceReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Reports Admin']}, canActivate: [AuthGuard] },
  { path: 'collection-payment-report', component: CollectionPaymentReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'passbook-related-service-report', component: PassbookRelatedServiceReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'passbook-related-service-status-report', component: PassbookRelatedServiceStatusReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'passbook-service-report', component: PassbookServiceReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'aadhaar-challan-generation', component: AadhaarChallanGenerationComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard]},
  // { path: 'error-application-report', component: ErrorApplicationReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
   { path: 'revenue-sadassulu-grievance-report', component: JaganannaSurakshaReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'jagananna-suraksha-dashboard', component: JaganannaSurakshaDashboardComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  // { path: 'schedule-report', component: ScheduleReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} }, 
  { path: 'jagananna-suraksha-camp-status-report', component: JaganannaSurakshaCampStatusReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  // { path: 'jagananna-suraksha-token-generation-report', component: JaganannaSurakshaTokenGenerationReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
 { path: 'palleku-podam', component: PallekuPodamComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
 { path: 'welfare-scheme', component: UnveilingOfWelfareSchemesDisplayComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true,roles: ['Mandal Parishad Development Officers','Municipal Commissioner']}, canActivate: [AuthGuard] },
 { path: 'reshedule-welfare-schemes', component: ResheduleWelfareSchemesComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Reports Admin'], isCommissioner: true} , canActivate: [AuthGuard] },
  { path: 'jagananna-aarogya-suraksha-token-generation-report', component: JaganannaSurakshaTokenGenerationReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'payment-report', component: PaymentReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'signature-upload', component: SignatureUploadComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Tahsildar','Deputy Tahsildar','Revenue Divisonal Officer','Joint Collector','Village Revenue Officer','Revenue Inspector','Collector','Mandal Parishad Development Officers','Municipal Commissioner','Joint Collector Development','Welfare & Educational Assistant','Ward Revenue Secretary','Ward Welfare & Development Secretary','Digital Assistant','Ward Education Secretary','ExciseDC','HandloomDC','HandloomDO','SCWELDC','DMO','FisheryDC','Panchayat Secretary DDO','Panchayat Secretary', 'CEO, WAQF board','Ward Admin Secretary','Superintendent','Assistant Secretary', 'Village surveyor', 'Mandal surveyor', 'Assistant Civil Supplies Officer', 'District Civil Supplies Officer','Zilla Sainik Welfare Officer','IT Minister','Assistant Social Welfare Officer','Fisheries Development Officer' ]}, canActivate: [AuthGuard] },
  { path: 'team-composition', component: TeamFormationComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Mandal Parishad Development Officers','Municipal Commissioner']}, canActivate: [AuthGuard] },
  { path: 'team-schedule', component: TeamScheduleComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Mandal Parishad Development Officers','Municipal Commissioner']}, canActivate: [AuthGuard] },
  { path: 'aarogya-suraksha-team-composition', component: AarogyaSurakshaTeamFormComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Mandal Parishad Development Officers','Municipal Commissioner']}, canActivate: [AuthGuard] },
  { path: 'aarogya-suraksha-assign-doctors', component: AarogyaSurakshaDoctorAssignDhmoComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['District Medical & Health Officer']}, canActivate: [AuthGuard] },
  { path: 'aarogya-team-schedule', component: AarogyaTeamScheduleComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Mandal Parishad Development Officers','Municipal Commissioner']}, canActivate: [AuthGuard] },
  { path: 'aarogya-suraksha-report', component: ArogyaSurakshaReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true}},
  { path: 'welfare-scheme-program', component: WelfareSchemeProgramReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true}},
  { path: 'aarogya-suraksha-dashboard', component: ArogyaSurakshaDashboardComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true}},
  { path: 'aarogya-suraksha-tokens-registaration', component: AarogyaSurakshaTokenGenerationComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Village Revenue Officer', 'Ward Revenue Secretary', 'Welfare & Educational Assistant', 'Ward Welfare & Development Secretary', 'Panchayat Secretary', 'Ward Admin Secretary']}, canActivate: [AuthGuard]},
  { path: 'aarogya-suraksha-token-registaration', component: AarogyaSurakshaTokenGenerationComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard]},
  { path: 'mutation-for-correction', component: MutationForCorrectionsComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'mutation-for-transactions', component: MutationForTransactionsComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'challan-mapping', component: ChallanMappingComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary']}, canActivate: [AuthGuard] },
  { path: 'failedtransactions', component: FailedtransactionsComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Digital Assistant', 'Ward Education Secretary', 'Reports Admin']}, canActivate: [AuthGuard] },
  { path: 'suomoto-pending-applications-report', component: SuomotoPendingApplicationsReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Tahsildar', 'Deputy Tahsildar', 'Revenue Inspector', 'Joint Collector', 'Collector', 'Revenue Divisonal Officer','Reports Admin']}, canActivate: [AuthGuard] },
  { path: 'payment-reconcillation-status', component: ReconsulationPaymentStatusComponent, data:{showMenu:true, showDashboardMenu: false, showHeader: true, roles: ['Digital Assistant']}, canActivate: [AuthGuard] },
  { path: 'Epayment', component: ExternalPaymentComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: true} },
  { path: 'external-payment-gateway', component: ExternalPaymentGatewayComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: true} },
  { path: 'external-payment-response', component: ExternalPaymentResponseComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: true} },
  { path: 'ots-payment-report', component: OtsPaymentReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'monitoring-dashboard-for-pan-collection', component: MonitoringDashboardForPANCollectionComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'pension-report', component: PensionReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'revenue-share-report', component: RevenueShareReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'volunteer-appreciation-report', component: VolunteerAppreciationReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'download-file', component: CertificateDownloadComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'save-designations-positions', component: SaveDesignationsPositionsComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'gsws-dynamic-survey-report', component: GswsDynamicSurveyReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'village-mapping', component: VillageMappingInTahsildharComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Tahsildar']}, canActivate: [AuthGuard]} ,
  { path: 'viksit-bharat-distribution',component:ViksitBharatDistributionComponent,data:{showMenu:false, showDashboardMenu: true, showHeader: true,  roles: ['Mandal Parishad Development Officers','Municipal Commissioner']}, canActivate: [AuthGuard] },    
  { path: 'vbd-report',component:ViksitBharatDistributionReportComponent,data:{showMenu:false, showDashboardMenu: true, showHeader: true,  roles: ['Mandal Parishad Development Officers','Municipal Commissioner','Joint Collector','Collector','Reports Admin','VBSY-DNO']}, canActivate: [AuthGuard] },    
  { path: 'viksit-bharat-report',component:ViksitBharatReportComponent,data:{showMenu:false, showDashboardMenu: true, showHeader: true,  roles: ['Mandal Parishad Development Officers','Municipal Commissioner', 'Joint Collector', 'Collector', 'Reports Admin', 'VBSY-DNO']}, canActivate: [AuthGuard] },       
  //{ path: 'pension-urban-electricity-tagging-screen', component : PensionUrbanElectricityTagComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true,  roles: ['Welfare & Educational Assistant', 'Ward Welfare & Development Secretary','Municipal Commissioner']}, canActivate: [AuthGuard] },
  { path: 'pan-details',component:PanDetailsCollectionComponent,data:{showMenu:false, showDashboardMenu: true, showHeader: true,  roles: ['Digital Assistant', 'Ward Education Secretary','Panchayat Secretary','Ward Admin Secretary']}, canActivate: [AuthGuard] },  
  { path :'utilization-certificate', component : UtilizationCertificateComponent , data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path :'utilization-certificate-report', component : UCReportComponent , data:{showMenu:false, showDashboardMenu: true, showHeader: true} },
  { path: 'pension-occupational-report', component: PensionsReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true}},
  // { path: 'search-caste', component: SearchScreenCasteComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true}},
  { path: 'aadhar-challan-generation-report', component: AadharChallanGenerationReportComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true}},
  { path :'secreteriate-revVillage-mapping-report', component : SecreteriateRevenueVillageMappingReportComponent , data:{showMenu:false, showDashboardMenu: true, showHeader: true} },  
  { path :'secreteriate-pan-updated-report', component : SecreteriatePanUpdatedReportComponent , data:{showMenu:false, showDashboardMenu: true, showHeader: true} }, 
  { path :'sadarem-print-certificate', component : SadaremprintCertificateComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true} },  
  { path :'secreteriate-pan-updated-report', component : SecreteriatePanUpdatedReportComponent , data:{showMenu:false, showDashboardMenu: true, showHeader: true} },  
  { path: 'volunteer-appreciation-program', component: VolunteerAppreciationProgramDetailsComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true, roles: ['Mandal Parishad Development Officers','Municipal Commissioner']}, canActivate: [AuthGuard] },
  {path: 'caste-grievance-report', component: CasteSurveyGrievancesDashboardComponent, data:{showMenu:false, showDashboardMenu: true, showHeader: true}  },
  { path: '', redirectTo: '/home' , pathMatch: 'full'},
  { path: 'page-not-found', component: ErrorPageNotFoundComponent, data:{showMenu:false, showDashboardMenu: false, showHeader: false}},
  { path: '**', redirectTo: '/page-not-found', pathMatch: 'full'},
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes
  //   ,{
  //   initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  // }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
