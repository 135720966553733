import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {CommonService,AadharValidator,WhitespaceValidator} from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';

@Component({
  selector: 'app-challan-mapping',
  templateUrl: './challan-mapping.component.html',
  styleUrls: ['./challan-mapping.component.css']
})
export class ChallanMappingComponent implements OnInit {
  challanmapping: UntypedFormGroup;
  showTable:Boolean=false;
  commonConstants : any = CommonConstants;
  TransactionId:any
  reqData:any ;
  public submitted:Boolean=false;

  constructor(private commonService: CommonService,private formBuilder: UntypedFormBuilder) {
    this.challanmapping = this.formBuilder.group({
      TransactionId: ['',[Validators.required]],
      status:[null,[Validators.required]],
      appNo:['',[Validators.required]]
    });
   }

  ngOnInit(): void {
  }
  get f() {
    return this.challanmapping.controls;
  }
  submit(){
    this.submitted=true;
    if(this.challanmapping.valid){
      let postData = '?TransactionId=' + this.f['TransactionId'].value + '&Source=' + this.f['status'].value + '&Dept_App_No=' + this.f['appNo'].value
      this.commonService.getRequest(this.commonConstants.GetChallanIdByTransactionId + postData).subscribe({
      next: (responseData: any) => { 
      this.reqData = responseData?.result; 
        if(this.reqData){
        this.showTable=true;
        }
        else{
        this.commonService.commonErrorPopup("Alert", "Records not found", "md")
        this.showTable=false;
        }
     }
     })
  error: (error: any) => {
    console.log('client side', error);
  }
  } 
  }
onSelectChange() {
  if(this.f['status'].value !=="OLD"){
   this.f.appNo.patchValue('');
   this.f.appNo.clearValidators();
   this.f.appNo.updateValueAndValidity();
  }
  else{
   this.f.appNo.setValidators(Validators.required);
   this.f.appNo.updateValueAndValidity();
  }
 }
}


