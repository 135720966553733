import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbNavConfig, NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';

@Component({
  selector: 'app-lamination',
  templateUrl: './lamination.component.html',
  styleUrls: ['./lamination.component.css'],
  providers: [
    NgbNavConfig,
  ],
})
export class LaminationComponent implements OnInit {
  fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  lamination: UntypedFormGroup;
  public submitted: Boolean = false;
  postData: any = {};
  public commonFormSubmitted: Boolean = false;
  total = 0;
  commonConstants: any = CommonConstants;
  getData : any = [];
  // getData : any = [ "Rice Card" , "Aarogya sri card" , "Pension Card" , "Any A4 Certificate" , "ID card" , "Aadhaar Card"]
  // getData : any =
  // [
  //   {
  //     name : "Rice Card",
  //     entityCode : "riceCard"
  //   },
  //   {
  //     name : "Aarogya sri card",
  //     entityCode : "aarogyaSriCard"
  //   },
  //   {
  //     name : "Pension Card",
  //     entityCode : "pensionCard"
  //   },
  //   {
  //     name : "Any A4 Certificate",
  //     entityCode : "anyA4Certificate"
  //   },
  //   {
  //     name : "ID card",
  //     entityCode : "idCard"
  //   },
  //   {
  //     name : "Aadhaar Card",
  //     entityCode : "aadhaarCard"
  //   }
  // ]
  @ViewChild('nav')
  nav: any;
  constructor(private formBuilder: UntypedFormBuilder,private commonService: CommonService,private router: Router,private config: NgbNavConfig,private modalService: NgbModal,private activatedRoute: ActivatedRoute) {
    this.getLaminationOptions();
    this.lamination = this.formBuilder.group({
      commonForm: [],
      // riceCard : [''],
      // aarogyaSriCard : [''],
      // pensionCard : [''],
      // anyA4Certificate : [''],
      // idCard : [''],
      // aadhaarCard : [''],
    })
    // for(let i = 0 ; i<this.getData.length ; i++)
    // {
    //   this.lamination.addControl(this.getData[i] , new FormControl(''))
    //   console.log(this.lamination.controls)
    // }
   }

  ngOnInit(): void {
  }

  getLaminationOptions()
  {
    this.commonService.getRequest(this.commonConstants.laminationOptions).subscribe({
      next: (responseData: any) => { 
        this.getData =  responseData.result;
        console.log(this.getData);
         for(let i = 0 ; i<this.getData.length ; i++)
        {
          this.lamination.addControl(this.getData[i].entityCode , new UntypedFormControl(''))
          // console.log(this.lamination.controls)
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
    
  }
  
  get f() {
    return this.lamination.controls;
  }

  goToNextTab() {
    this.commonFormSubmitted = true;
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    }
  }

  // addCount(event : any)
  // {
  //   console.log(event.target.value)
  //   this.total += Number(event.target.value) 
  //   this.f['total'].setValue(this.total)
  //   // this.f['total'].setValue(this.f['countRice Card'].value + this.f['countAarogya sri card'].value + this.f['countPension Card'].value + this.f['countAny A4 Certificate'].value + this.f['countID card'].value + this.f['countAadhar Card'].value);
  // }

  submit()
  {
    this.total = 0
    var alertCount = 0
    this.submitted = true;
    console.log(this.lamination.controls)
    for(let i = 0 ; i< this.getData.length ; i++)
    {
      var temp = this.getData[i].entityCode
      if(this.f[temp].value > 100)
      {
        alertCount += 1
        // console.log(alertCount , "alert")
        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        modalRef.componentInstance.title = 'Alert';
        modalRef.componentInstance.message = "Number of copies limit is 100 for each service";
        modalRef.result.then((result: any) => {
          if (result) {
            console.log(result);
          }
        });
      }

      // this.lamination.controls[temp].setValidators([
      //   Validators.pattern('^(0|[1-9][0-9]*)$'),
      // ]);
      // this.lamination.controls[temp].updateValueAndValidity();

      this.total += Number(this.f[temp].value)
    }
    if(!this.lamination.valid || alertCount != 0)
    {
      // console.log("submitted");
      console.log("Not Submitted", this.lamination.value);
      let invalidControls = [];
      const controls = this.lamination.controls;
      for(const name in controls)
      {
        if(controls[name].invalid)
        {
          invalidControls.push(name);
        }
      }
      console.log(invalidControls);
    }
    else if(this.total == 0)
    {
      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = "Minimum one copy should be selected for lamination";
      modalRef.result.then((result: any) => {
        if (result) {
          console.log(result);
        }
      });
    }
    else
    {
      
      let dateFormat =  this.commonService.formatDate(this.f['commonForm'].value['dob']);
      this.postData = 
      {
        requisitionHeader: {
          requisitionType: "Lamination",
          department: "RV",
          applicantInfo: {
            id: "", 
            aadhaarNumber:  this.f['commonForm'].value['encryptedAadharNo'].trim(), //CryptoJS.AES.encrypt(this.f['commonForm'].value['aadharNo'].trim(), this.commonConstants.encryptDecryptPassword.trim()).toString(), //this.f['commonForm'].value['aadharNo'],
            personNames: [
              {
                languageCode: "en-in",
                firstName: this.f['commonForm'].value['firstName'],
                middleName: this.f['commonForm'].value['middleName'] ,
                lastName: this.f['commonForm'].value['lastName'],
                fatherHusbandName:this.f['commonForm'].value['fatherHusName'],
              }
            ],
            gender: this.f['commonForm'].value['gender'],
            birthDate: dateFormat,
            caste: this.f['commonForm'].value['caste'].name,
            religion: this.f['commonForm'].value['religion'].name,
            qualification: this.f['commonForm'].value['qualification'].name,
             maritalStatus: this.f['commonForm'].value['maritalStatus'],
            addresses: [
              {
                addressType:"present",
                doorNo: this.f['commonForm'].value['preDoorNo'],
                streetName:this.f['commonForm'].value['preStreet'],
                villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                district: this.f['commonForm'].value['preDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['prePinCode'],
                postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
              },
              {
                addressType:"permanent",
                doorNo: this.f['commonForm'].value['permDoorNo'],
                streetName:this.f['commonForm'].value['permStreet'],
                villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                district: this.f['commonForm'].value['permDistrict'].entityCode,
                pinCode: this.f['commonForm'].value['permPinCode'],
                postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
              }
            ],
            rationCardNo: '',
            deliveryType:  this.f['commonForm'].value['deliveryType'],
            emailAddress: this.f['commonForm'].value['email'],
            mobileNumber: this.f['commonForm'].value['mobileNo'],
            isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
            isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']


          },
          remarks: '',
        },
        requisitionData: {
          riceCard: String(this.lamination.controls.riceCard.value) && this.lamination.controls.riceCard.value != null ? String(this.lamination.controls.riceCard.value) : "0" ,
          aarogyasriCard: String(this.lamination.controls.aarogyaSriCard.value) && this.lamination.controls.aarogyaSriCard.value != null ? String(this.lamination.controls.aarogyaSriCard.value) : "0",
          pensionCard: String(this.lamination.controls.pensionCard.value) && this.lamination.controls.pensionCard.value != null ? String(this.lamination.controls.pensionCard.value) : "0",
          a4Certificate: String(this.lamination.controls.anyA4Certificate.value) && this.lamination.controls.anyA4Certificate.value != null ? String(this.lamination.controls.anyA4Certificate.value) : "0",
          idCard: String(this.lamination.controls.idCard.value) && this.lamination.controls.idCard.value != null ? String(this.lamination.controls.idCard.value) : "0",
          aadharCard: String(this.lamination.controls.aadhaarCard​.value) && this.lamination.controls.aadhaarCard​.value != null ? String(this.lamination.controls.aadhaarCard​.value) : "0",
          totalCount: String(this.total)
      }
      };
      console.log(this.postData);
      this.commonService
        .postRequest(this.commonConstants.lamination, this.postData)
        .subscribe({
          next: (responseData: any) => {
            console.log(responseData, 'Lamination response');
            console.log(responseData.status, 'stats');
            this.updateFileUpload(responseData['result'].id);
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
    }
     
     
  }

  updateFileUpload(id: any) {
    let fileData = {
      FileList: this.fileList,
      RequisitionId: id,
    };
    this.commonService
      .postRequest(this.commonConstants.updateFileUpload, fileData)
      .subscribe({
        next: (resData: any) => {
          console.log(resData, 'File Upload  Respose data');
          this.showPayment(id);
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  showPayment(requisionId: any) {
    const queryParams: Params = { id: requisionId,department: 'RV' ,type:'Lamination'};
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }


}
