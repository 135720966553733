<div class="modal-header" >
    <h6 class="modal-title">{{title}}</h6>
    <button type="button" class="close" aria-label="Close"  (click)="activeModal.dismiss('Cross click')">
      <span >&times;</span>
    </button>
  </div>
  <div class="modal-body basic-details-popup">
    <form [formGroup]="basicDetailsForm">
    <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" (reset)="commonFormSubmitted = false"></app-common-form>
    </form>
    <button type="button" class="greenBtnCls float-right mb-3" (click)="submit()">Continue</button>

  </div>
  <!-- <div class="modal-footer">
  </div> -->
