import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  NgbNavConfig,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbNavChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
  CommonService,
  WhitespaceValidator} from 'src/shared/common.service';
import { DatePipe } from '@angular/common';
import { CommonConstants } from 'src/constants/common-constants.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-token-registration',
  templateUrl: './token-registration.component.html',
  styleUrls: ['./token-registration.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class TokenRegistrationComponent implements OnInit {
  
  commonConstants: any = CommonConstants;
  TokenRegistrationForm: UntypedFormGroup;
  public commonFormSubmitted: Boolean = false;
  
  public submitted:boolean = false;
  public isServiceAllowed:boolean = false;
  public serviceNotAllowedMessage = "";
  householdDataAvailable: boolean = false;

  @ViewChild('nav') nav: any;

  public services: any[] = [];
  public department: any = null;
  public departmentList: any[] = [];
  public service: any = null;
  public serviceList: any[] = [];
  public isAllSelected:boolean = false;
  loggedinuserName: any;
  userInformation: any;
  loggedinDetails: any;
  encrtyptedAadhar : any = '';
  isAadharPrefilled: boolean = false;
  isSubmitted: Boolean = false;
  constructor(
    private config: NgbNavConfig,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.TokenRegistrationForm = this.formBuilder.group({
        aadharNo: [''],
        firstName: [''],
        middleName: [''],
        lastName: [''],
        fatherHusName: [''],
        gender: [''],
        dob: [''],
        caste: [''],
        religion: [''],
        qualification: [''],
        maritalStatus: [''],
        mobileNo: [''],
        isWhatsAppAvailable:[''],
        email: [''],
        deliveryType: [''],
        preDoorNo: [''],
        preStreet: [''],
        preVillage: [''],
        preMandal: [''],
        preDistrict: [''],
        prePinCode: [''],
        prePostalOffice : [''],
        prePostalVillage : [''],
        permDoorNo: [''],
        permStreet: [''],
        permVillage: [''],
        permMandal: [''],
        permDistrict: [''],
        permPinCode: [''],
        permPostalOffice: [''],
        permPostalVillage: [''],
        sameAddress: [''],
        encryptedAadharNo:[],
        householdid:[],
        clusterid:[],
        familyForm: ['', [Validators.required]]
    });

    this.userInformation = this.commonService.getLocalStorage('userInfo');
    this.loggedinDetails = JSON.parse(this.userInformation);
    this.loggedinuserName = this.loggedinDetails.jobTitle;

    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {
    const secreteriateCode =this.commonService.getLocalStorage('locationCode');
    if(secreteriateCode){
      this.commonService.getRequest(this.commonConstants.CheckIfJSServiceIsAvailableToUser + secreteriateCode).subscribe({
        next: (responseData: any) => { 
          if(responseData?.result)
          this.isServiceAllowed = responseData?.result?.isServiceAvailable;
          this.serviceNotAllowedMessage = responseData?.result?.message;
        },
        error: (error) => {
        console.log('client side', error);
        }
      });
    }    
    this. getDepartments();
  }

  getCitizenInfo() {
    this.isSubmitted = true;
    if (this.f.aadharNo.valid) {
      let postData: any = {};
      postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.aadharNo.value, true); 
      this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
        next: (responseData: any) => { 
          if(responseData?.result &&responseData?.result.length !==0) { 
            responseData.result.forEach((item: any) => {
              item.aadhaarNumber = item.aadhaarNumber;
            });
            this.loadCitizenData(responseData.result);
            this.isAadharPrefilled = true;            
          } else {                    
            let postDataLive: any = {};
            postDataLive.UIDNUM = this.commonService.RSAEncrypt(this.f.aadharNo.value, true);  
            this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfoLive, postDataLive, 'citizenInfo').subscribe({
              next: (responseData: any) => { 
                if(responseData?.result && responseData?.result.length !==0) { 
                  responseData.result.forEach((item: any) => {
                    item.aadhaarNumber = this.commonService.RSADecrypt(item.aadhaarNumber);
                  });
                  this.loadCitizenDataLive(responseData.result);
                  this.isAadharPrefilled = true;            
                } else {
                  alert('Your details are not present in the Household survey. Please contact respective Secretariat/volunteer for Household Survey.');
                }
              },
              error: (error) => {
                console.log('client side', error);
              }
            });
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });  
     }
    else {         
      alert('Please enter valid Aadhar Number');
    }
  }

  loadCitizenDataLive(result:any) {
    this.f.sameAddress.patchValue(true);
    let data:any = result[0];
    this.TokenRegistrationForm.patchValue({
      aadharNo: data.UID_NUM,
      firstName: data.CITIZEN_NAME,
      middleName: '',
      lastName: '',
      fatherHusName: '',
      gender: this.loadGender(data.GENDER?.toUpperCase()),
      dob: data.DOB_DT,
      religion: null,
      qualification: null,
      maritalStatus: null,
      mobileNo: data.MOBILE_NUMBER,
      isWhatsAppAvailable:null,
      email: null,
      deliveryType: '',
      permVillage: data.SECRETARIAT_CODE,
      permMandal: data.LGD_MANDAL_CODE,
      permDistrict: data.LGD_DIST_CODE,
      permDoorNo: data.DOOR_NO,
      permStreet: '',
      permPinCode: '',
      permPostalOffice : null,
      permPostalVillage : null,
      preDoorNo: data.DOOR_NO,
      preStreet: '',
      preVillage: data.SECRETARIAT_CODE,
      preMandal: data.LGD_MANDAL_CODE,
      preDistrict: data.LGD_DIST_CODE,
      prePinCode: '',
      prePostalOffice : null,
      prePostalVillage : null,
      householdid:data.TEMP_ID,
      clusterid: data.CLUSTER_ID
  });
}

  loadCitizenData(result:any) {
    this.f.sameAddress.patchValue(true);
    let data:any = result[0];  
    this.TokenRegistrationForm.patchValue({
      aadharNo: data.aadhaarNumber,
      firstName: data.firstName,
      middleName: '',
      lastName: '',
      fatherHusName: data.fatherHusbandName,
      gender: this.loadGender(data.gender?.toUpperCase()),
      dob: data.birthDate,
      religion: data.religion,
      qualification: data.qualification,
      caste:data.caste,
      maritalStatus: data.maritalStatus !== '' ? data.maritalStatus : null,
      mobileNo: data.mobileNumber,
      isWhatsAppAvailable:data.isWhatsAppAvailable,
      email: data.email,
      deliveryType: '',
      permVillage: data.seccode,
      permMandal: data.mandalcode,
      permDistrict: data.districtcode,
      permDoorNo: data.doorNo,
      permStreet: '',
      permPinCode: data.pinCode,
      permPostalOffice : null,
      permPostalVillage : null,
      preDoorNo: data.doorNo,
      preStreet: '',
      preVillage: data.seccode,
      preMandal: data.mandalcode,
      preDistrict: data.districtcode,
      prePinCode: data.pinCode,
      prePostalOffice : null,
      prePostalVillage : null,
      householdid:data.id,
      clusterid: data.clusterid
    });
  }

  loadGender(value: string) {
    // if (value !== '' && value !== null) {
    //   this.f.gender.disable();
    // }
    if (value === 'MALE')
      return 'Male';
    else if (value === 'FEMALE')
      return 'Female';
    else if (value === 'TRANSGENDER')
      return 'Transgender';
    else 
      return null;
  }
  aadharencrypt(event: any){
    if(this.f.aadharNo.valid){
      this.getEncryptedAadharValues(this.f.aadharNo.value);
    }else {
      this.encrtyptedAadhar = '';
    }
    
  }
  getEncryptedAadharValues(aadharValue:any){
    this.encrtyptedAadhar = '';
    this.commonService
      .getRequest(this.commonConstants.cryptoEncryptValue + encodeURIComponent(this.commonService.RSAEncrypt(aadharValue)))
      .subscribe({
        next: (responseData: any) => {
          // console.log(responseData.result.encryptedValue, 'encrypt value respose data');
          this.encrtyptedAadhar =  responseData.result.encryptedValue;
         // console.log(this.encrtyptedAadhar,'encryptaadhar')

         console.log(this.encrtyptedAadhar,'encrypt aaaadhhhhar')
          if(this.encrtyptedAadhar?.length !==0 && this.encrtyptedAadhar !== null){
            this.TokenRegistrationForm.patchValue({
              encryptedAadharNo: this.encrtyptedAadhar,
            });
            // console.log(this.form,'encrypt aaaadhhhhar')
             console.log('encrypt aaaadhhhhar  AA')
            }else{
              console.log('encrypt aaaadhhhhar  BB')
              this.commonService.commonErrorPopup("Alert", "Aadhar Encryption Failed !!", "md")
              this.resetForm();
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  resetForm(){
    this.TokenRegistrationForm.reset();
    this.isAadharPrefilled = false;
    this.commonFormSubmitted = false;
    this.submitted = false;
    this.isSubmitted = false;
    this.householdDataAvailable = false;
    this.isAllSelected = false;
    this.department = null;
    this.service = null;
    this.services = [];
    this.serviceList = [];
  }

  getDepartments() {
    this.commonService.getRequest(this.commonConstants.getJSDepartment).subscribe({
      next: (responseData: any) => { 
        this.departmentList = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
    });
  }

  onDepartmentChange(event?: any) {
    if(this.department) {
      this.serviceList = [];
      this.service = null;
      this.commonService.getRequest(this.commonConstants.getJSServices+ this.department.id).subscribe({
        next: (responseData: any) => { 
          this.serviceList = this.commonService.sortData(responseData.result);
        },
        error: (error) => {
        console.log('client side', error);
        }
      });
    }
  }

  addService() {
    if(!this.department || !this.service) {
      this.commonService.commonErrorPopup('Alert', 'Please select service.' , 'md');
      return;
    }
    if(this.services.findIndex((x:any)=> x.serviceCode == this.service.entityCode && x.serviceName == this.service.name) >=0) {
      this.commonService.commonErrorPopup('Alert', 'This service is already added.' , 'md');
      return;
    }
    this.services.push({isSelected: false, departmentId: this.department.id, departmentName: this.department.name, departmentCode: this.department.entityCode, serviceId: this.service.id, serviceName: this.service.name, serviceCode: this.service.entityCode, isAllDocuments: null});
    this.service = null;
  }

  // deleteService(index: any) {
  //   this.services.splice(index, 1);
  // }

  checkUncheckAll() {
    this.services.forEach((element: any,index: number)=>{
      element.isSelected = this.isAllSelected;
   });
  }

  selectService() {
    this.isAllSelected = this.services.every(function(item:any) {
      return item.isSelected == true;
    })
  }

  deleteServices() {
    if(this.services.filter((x:any) => x.isSelected == true).length <= 0) {
      this.commonService.commonErrorPopup('Alert', 'Please select service to delete.' , 'md');
      return;
    }
    this.services = this.services.filter((x:any) => x.isSelected == false);
    this.isAllSelected = false;
  }

  get f() {
    return this.TokenRegistrationForm.controls;
  }

  
  submit(){
    this.submitted = true;
    if(!this.TokenRegistrationForm.valid){
      return;
    } else {
      if(this.services.length <= 0) {
        this.commonService.commonErrorPopup('Alert', 'Atleast one service should be selected.', 'md');
        return;
      }
      if(this.services.filter((x:any) => x.isAllDocuments == null).length > 0) {
        return;
      }      
      let dateFormat = this.commonService.formatDate(this.TokenRegistrationForm.value['dob']);
      let postData = {          
        requisitionHeader:  {
          requisitionType: 'RegistrationofTokens',
          requisitionPKey: '',
          department: 'RV',
          applicantInfo: {
            id: '',
            aadhaarNumber: this.TokenRegistrationForm.value['encryptedAadharNo']?.trim(), 
            personNames: [
              {
                languageCode: 'en-in',
                firstName: this.TokenRegistrationForm.value['firstName'],
                middleName: this.TokenRegistrationForm.value['middleName'],
                lastName: this.TokenRegistrationForm.value['lastName'],
                fatherHusbandName:
                  this.TokenRegistrationForm.value['fatherHusName'],
              },
            ],
            gender: this.TokenRegistrationForm.value['gender'],
            birthDate: (!dateFormat || dateFormat == "Invalid date") ? '1900-01-01T12:00:00Z' : dateFormat,
            caste: this.TokenRegistrationForm.value['caste'],
            religion: this.TokenRegistrationForm.value['religion'],
            qualification: this.TokenRegistrationForm.value['qualification'],
            maritalStatus: this.TokenRegistrationForm.value['maritalStatus'],
            addresses: [
              {
                addressType: 'present',
                doorNo: this.TokenRegistrationForm.value['preDoorNo'],
                streetName: this.TokenRegistrationForm.value['preStreet'],
                villageWard: this.TokenRegistrationForm.value['preVillage'],
                mandalMunicipality: this.TokenRegistrationForm.value['preMandal'],
                district: this.TokenRegistrationForm.value['preDistrict'],
                pinCode: this.TokenRegistrationForm.value['prePinCode'],
                isRevenueLocation: true,
                postalOfficeCode: this.TokenRegistrationForm.value['prePostalOffice'] !== null ? this.TokenRegistrationForm.value['prePostalOffice'].entityCode : "",
                postalOfficeName: this.TokenRegistrationForm.value['prePostalOffice'] !== null ? this.TokenRegistrationForm.value['prePostalOffice'].name : "",
                postalVillageCode: this.TokenRegistrationForm.value['prePostalVillage'] !== null ? this.TokenRegistrationForm.value['prePostalVillage'].entityCode : "",
                postalVillageName: this.TokenRegistrationForm.value['prePostalVillage'] !== null ? this.TokenRegistrationForm.value['prePostalVillage'].name : ""
              },
              {
                addressType: 'permanent',
                doorNo: this.TokenRegistrationForm.value['permDoorNo'],
                streetName: this.TokenRegistrationForm.value['permStreet'],
                villageWard: this.TokenRegistrationForm.value['permVillage'],
                mandalMunicipality: this.TokenRegistrationForm.value['permMandal'],
                district: this.TokenRegistrationForm.value['permDistrict'],
                pinCode: this.TokenRegistrationForm.value['permPinCode'],
                isRevenueLocation: true,
                postalOfficeCode: this.TokenRegistrationForm.value['permPostalOffice'] !==  null ? this.TokenRegistrationForm.value['permPostalOffice'].entityCode : "",
                postalOfficeName: this.TokenRegistrationForm.value['permPostalOffice'] !== null ? this.TokenRegistrationForm.value['permPostalOffice'].name : "",
                postalVillageCode: this.TokenRegistrationForm.value['permPostalVillage'] !== null ? this.TokenRegistrationForm.value['permPostalVillage'].entityCode : "",
                postalVillageName: this.TokenRegistrationForm.value['permPostalVillage'] !== null ? this.TokenRegistrationForm.value['permPostalVillage'].name : ""
              },
            ],
            rationCardNo: '',
            deliveryType: this.TokenRegistrationForm.value['deliveryType'],
            emailAddress: this.TokenRegistrationForm.value['email'],
            mobileNumber: this.TokenRegistrationForm.value['mobileNo'],
            isWhatsAppAvailable: this.TokenRegistrationForm.value['isWhatsAppAvailable'],
            isDataFromAadhar: 'false'
          },
          remarks: '',
        },        
        requisitionData: {
          services:  this.services
                      .map(({departmentId, departmentName, departmentCode, serviceId, serviceName, serviceCode, isAllDocuments}) => 
                      ({departmentId, departmentName, departmentCode, serviceId, serviceName, serviceCode, isAllDocuments})),
          householdid: this.TokenRegistrationForm.value['householdid'],
          clusterid: this.TokenRegistrationForm.value['clusterid'],
          CreatedBy: this.loggedinDetails.userPrincipalName
        }
      };  
      this.commonService.postRequest(this.commonConstants.RegistrationofTokens, postData)
      .subscribe({ next: (responseData: any) => {   
          if(responseData?.result == "Token already exists for one or more services added for the given aadhaar number"){
            this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
          } else if(responseData?.result) {
            this.showPayment(responseData['result'].id);
          } else {
            this.commonService.commonErrorPopup('Alert', "Error occured while saving the service data. Please try again after sometime.", 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
  }

  showPayment(requisionId: any) {
    const queryParams: Params = {
      id: requisionId,
      department: 'RV',
      type: 'RegistrationofTokens',
    };
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  goToHome() {
    this.router.navigate(['/home']);
  }
}