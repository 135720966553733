<div class="mainPageContainer mr-3 mt-5"> 
    <div class="btnHolder mb-3">
        <button class="btn btn-primary float-right my-3"  (click)="goToHome()">Home</button>
        <button class="btn btn-primary float-right my-3 mr-3"  (click)="goToTeamComposition()">Go To Team Composition</button>
        <!-- <button *ngIf="isCommonSplitFlag" class="btn btn-primary float-right my-3 mr-3" (click)="openCampSplitDetails(campSplitModal)">Split Camp</button> -->
    </div>
    <h6 *ngIf="!isScheduleConfirmed" class="mb-3">Aarogya Suraksha 2.0 Team Schedule</h6>
    <h6 *ngIf="isScheduleConfirmed" class="mb-3">Aarogya Suraksha 2.0 Team Schedules are confirmed and freezed.</h6>
    <!-- <h6 class="mb-3 subHeadingCls">Note: Please click on submit for saving the changes (edit/delete/add).</h6> -->
    <form [formGroup]="teamForm">
        <div *ngIf="!isScheduleConfirmed" class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">VHC: <span class="mandatoryCls">*</span></label>
                <select  class="selectCls" required formControlName = "VHC" (change)="onChangeVHC()" [ngClass]="{ 'is-invalid':submitted && (f.VHC.errors || f.VHC.value === 'Select')}">
                    <option  [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let VHC of VHCList" [ngValue]="VHC">{{VHC.vhC_NAME}}</option>
                </select>               
                <div *ngIf="submitted && (f.VHC.errors || f.VHC.value === 'Select')" class="selectError">
                    <div *ngIf="f.VHC.errors?.required || f.VHC.value === 'Select'">Please select VHC</div>
                </div>
            </div> 
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" required formControlName = "secretariat" [ngClass]="{ 'is-invalid':submitted && (f.secretariat.errors || f.secretariat.value === 'Select')}">
                    <option  [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let secretariat of secretariatListAddSchedule" [ngValue]="secretariat">{{secretariat.secretariaT_NAME}}</option>
                </select>
                <div *ngIf="submitted && (f.secretariat.errors || f.secretariat.value === 'Select')" class="selectError">
                    <div *ngIf="f.secretariat.errors?.required || f.secretariat.value === 'Select'">Please select Secretariat</div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                <label class="labelCls">Schedule Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                        name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"                        
                        required formControlName="sheduleDate" [maxDate]="maxDate" [markDisabled]="isDisabled"
                        [ngClass]="{ 'is-invalid':submitted && f.sheduleDate.errors }"
                        >
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>
                <div *ngIf="submitted && f.sheduleDate.errors" class="selectError">
                    <div *ngIf="f.sheduleDate.errors?.required || f.sheduleDate.invalid">Please enter valid Schedule Date</div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  mb-3">
                <label class="labelCls">Team: <span class="mandatoryCls">*</span></label>
                <select  class="selectCls" required formControlName = "team" [ngClass]="{ 'is-invalid':submitted && (f.team.errors || f.team.value === 'Select')}">
                    <option  [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let team of teams" [ngValue]="team.teaM_NAME">{{team.teaM_NAME}}</option>
                </select>
                <div *ngIf="submitted && (f.team.errors || f.team.value === 'Select')" class="selectError">
                    <div *ngIf="f.team.errors?.required || f.team.value === 'Select'">Please select team</div>
                </div>
            </div>              
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  mb-3">
                <label class="labelCls">Venue Type: <span class="mandatoryCls">*</span></label>
                <select  class="selectCls" required formControlName = "VenueType" [ngClass]="{ 'is-invalid':submitted && (f.VenueType.errors || f.VenueType.value === 'Select')}">
                    <option  [ngValue]= "null" disabled>Select</option>
                    <option  [ngValue]= "'School'">School</option>
                    <option  [ngValue]= "'Urban Health Centre'">Urban Health Centre</option>
                    <option  [ngValue]= "'Junior College'">Junior College</option>
                    <option  [ngValue]= "'Secretariat'">Secretariat</option>
                    <option  [ngValue]= "'Village Clinic'">Village Clinic</option>
                    <option  [ngValue]= "'Degree College'">Degree College</option>
                    <option  [ngValue]= "'Community Hall'">Community Hall</option>
                    <option  [ngValue]= "'Others'">Others</option>
                </select>               
                <div *ngIf="submitted && (f.VenueType.errors || f.VenueType.value === 'Select')" class="selectError">
                    <div *ngIf="f.VenueType.errors?.required || f.VenueType.value === 'Select'">Please select Venue Type</div>
                </div>
            </div> 
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  mb-3">
                <label class="labelCls">Venue: <span class="mandatoryCls">*</span></label>
                <input  type="text" class="inputCls value" name="Venue" id="Venue"
                    formControlName = "Venue"  required [ngClass]="{ 'is-invalid':submitted && f.Venue.errors }"
                    maxlength="100" placeholder="i.e. Urban Health Centre">
                <div *ngIf="submitted && f.Venue.errors" class="invalid-feedback">
                    <div *ngIf="f.Venue.errors?.required  || f.Venue.errors?.whiteSpaceValidate">Please enter Venue</div>
                    <div *ngIf="f.Venue.errors?.maxlength">Please enter valid Venue</div>
                </div>
            </div>             
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">PHC:</label>
                <input type="text" class="inputCls value" name="PHC" id="PHC" [attr.disabled]="true"
                    formControlName = "PHC">
            </div>
            <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Doctor:</label>
                <input type="text" class="inputCls value" name="Doctor" id="Doctor" [attr.disabled]="true" 
                    formControlName = "Doctor">
            </div>-->
            <div class="btnHolder mt-4 col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <button  class="greenBtnCls"  (click)="addToSchedule()">Add To Schedule</button>
            </div>
        </div>       
        <div id="teamScheduleE">
            <div class="subHeadingCls mb-3"><b>Team Schedule List</b></div>
            <div class="row mb-3 " >
                <div class="col">
                    <div class="tab table-responsive tableFixHead">
                        <table class="tableCls">
                            <thead>
                                <tr>                                    
                                    <th>Serial No</th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'districT_NAME'">District<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'mandaL_NAME'">Mandal<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'scheduleD_DATE'">Scheduled Date<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'teaM_NAME'">Team Name<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'venuE_TYPE'">Venue Type<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'venuE_ADDRESS'">Venue<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'vhC_NAME'">VHC<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'secretariaT_NAME'">Secretariats<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'phC_NAME'">PHC<mdb-icon fas icon="sort"></mdb-icon></th>
                                    <!-- <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'mO_NAME'">Doctor<mdb-icon fas icon="sort"></mdb-icon></th> -->
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="teamList && teamList?.length > 0">
                                <tr *ngFor="let item of teamList;let i = index;">
                                    <td>{{i + 1}}</td>
                                    <td>{{item.districT_NAME}}</td>
                                    <td>{{item.mandaL_NAME}}</td>
                                    <td>{{item.scheduleD_DATE}}</td>
                                    <td>{{item.teaM_NAME}}</td>
                                    <td>{{item.venuE_TYPE}}</td>
                                    <td>{{item.venuE_ADDRESS}}</td>
                                    <td>{{item.vhC_NAME}}</td>
                                    <td>{{item.secretariaT_NAME }}</td>
                                    <td>{{item.phC_NAME}}</td>
                                    <!-- <td>{{item.mO_NAME}}</td> -->
                                    <td class="text-center">
                                        <button *ngIf="isScheduleConfirmed && item.camP_FLAG !== 'S' && item.camP_FLAG !== 'E' && item.camP_FLAG !== 'V'" class="ml-3 mb-1 greenBtnCls" (click)="edit(item, editModal)">Edit Venue</button>
                                        <!-- <button *ngIf="item.spliT_FLAG !== 'N' && item.spliT_FLAG !== ''" class="ml-3 mb-1 greenBtnCls" (click)="openCampSplitDetails(item, campSplitModal)">Split Camp</button> -->
                                        <button *ngIf="item.camP_FLAG === 'S'" class="ml-3 mb-1 greenBtnCls" (click)="openCampDetails(item, campDetailsModal)">Camp Start Details</button>
                                        <button *ngIf="item.camP_FLAG === 'E'" class="ml-3 mb-1 greenBtnCls" (click)="openCampDetails(item, campDetailsModal)">Camp End Details</button>
                                        <button *ngIf="item.camP_FLAG === 'V'" class="ml-3 mb-1 greenBtnCls" (click)="openCampDetails(item, campDetailsModal)">View Camp Details</button>
                                        <button *ngIf="!isScheduleConfirmed" class="ml-3 mb-1 greenBtnCls" (click)="openConfirmModal(confirmModal, item.vhC_CODE, item.scheduleD_DATE, item.secretariaT_CODE)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="teamList && teamList?.length === 0">
                                <tr><td [attr.colspan]="11" class="text-center">No records found.</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="btnHolder">
            <button class="greenBtnCls float-right"  *ngIf="teamList && teamList?.length > 0" (click) = "printSchedule()">Print Schedule</button>
            <button class="greenBtnCls float-right mr-3"  *ngIf="teamList && teamList?.length > 0 && !isScheduleConfirmed" (click) = "openConfirmScheduleModal1(confirmScheduleModal1)">Confirm Schedule</button>
        </div>
    </form>
</div>

<div  class="hideTable" id="AarogyaTeamSchedule">
    <div class="subHeadingCls mb-3"><b>Team Schedule List</b></div>
    <div class="row mb-3 " >
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>District</th>
                            <th>Mandal</th>
                            <th>Scheduled Date</th>
                            <th>Team Name</th>
                            <th>Venue Type</th>
                            <th>Venue</th>
                            <th>VHC</th>
                            <th>Secretariats</th>
                            <th>PHC</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="teamList && teamList?.length > 0">
                        <tr *ngFor="let item of teamList;let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{item.districT_NAME}}</td>
                            <td>{{item.mandaL_NAME}}</td>
                            <td>{{item.scheduleD_DATE}}</td>
                            <td>{{item.teaM_NAME}}</td>
                            <td>{{item.venuE_TYPE}}</td>
                            <td>{{item.venuE_ADDRESS}}</td>
                            <td>{{item.vhC_NAME}}</td>
                            <td>{{item.secretariaT_NAME }}</td>
                            <td>{{item.phC_NAME}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="teamList && teamList?.length === 0">
                        <tr><td [attr.colspan]="10" class="text-center">No records found.</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<ng-template #confirmModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Confirm Delete</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss(); selectedvhccode = ''; selectedscheduleDate='';" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <p>Are you sure you want to delete this schedule?</p>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();  selectedvhccode = ''; selectedscheduleDate='';">No</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="deleteSchedule()">Yes</button>
    </div>
</ng-template>

<ng-template #confirmScheduleModal1 let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Confirm Schedule</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <p>Are you sure you want to confirm schedules?</p>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">No</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="openConfirmScheduleModal2(confirmScheduleModal2)">Yes</button>
    </div>
</ng-template>

<ng-template #confirmScheduleModal2 let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Confirm Schedule</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <p>After confirming, all the schedules will be freezed and any updates will not be allowed.</p>
      <p>Are you sure you want to confirm schedules?</p>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">No</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="confirmSchedule()">Confirm</button>
    </div>
</ng-template>

<ng-template #editModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Edit Venue</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
            <label class="labelCls">Venue Type: <span class="mandatoryCls">*</span></label>
            <select  class="selectCls" required [(ngModel)]="selectedSchedule.venuE_TYPE" [ngClass]="{ 'is-invalid':isEditSubmitted && (selectedSchedule.venuE_TYPE === 'Select')}">
                <option  [ngValue]= "null" disabled>Select</option>
                <option  [ngValue]= "'School'">School</option>
                <option  [ngValue]= "'Urban Health Centre'">Urban Health Centre</option>
                <option  [ngValue]= "'Junior College'">Junior College</option>
                <option  [ngValue]= "'Secretariat'">Secretariat</option>
                <option  [ngValue]= "'Village Clinic'">Village Clinic</option>
                <option  [ngValue]= "'Degree College'">Degree College</option>
                <option  [ngValue]= "'Community Hall'">Community Hall</option>
                <option  [ngValue]= "'Others'">Others</option>
            </select>               
            <div *ngIf="isEditSubmitted && (selectedSchedule.venuE_TYPE === 'Select')" class="selectError">
                <div *ngIf="selectedSchedule.venuE_TYPE === 'Select'">Please select Venue Type</div>
            </div>
        </div>        
      </div>
      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
            <label class="labelCls">Venue: <span class="mandatoryCls">*</span></label>
            <input  type="text" class="inputCls value" name="Venue" id="Venue"
                [(ngModel)]="selectedSchedule.venuE_ADDRESS"  required [ngClass]="{ 'is-invalid':isEditSubmitted && selectedSchedule.venuE_ADDRESS.trim()=='' }"
                maxlength="100" placeholder="i.e. Urban Health Centre">
            <div *ngIf="isEditSubmitted && selectedSchedule.venuE_ADDRESS.trim()==''" class="invalid-feedback">
                <div *ngIf="selectedSchedule.venuE_ADDRESS.trim()==''">Please enter Venue</div>
            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="update()">Update</button>
    </div>
</ng-template>

<ng-template #campDetailsModal let-modal class="modalCls">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            <span *ngIf="isCampStarted">Camp Start Details</span>
            <span *ngIf="isCampEnded">Camp End Details</span>
            <span *ngIf="isCampClosed">Camp View Details</span>
        </h4>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="closeCampDetailsPopup()">
            <span >×</span></button>
    </div>
    <div class="modal-body">
        <h6 class="mb-3"><strong>Note: All Upload Documents are in jpg/jpeg/png Format Only. Maximum Upload Size Per File is 1MB. </strong></h6>
        <h6 class="mb-3" *ngIf="isCampClosed">Camp Start Details</h6>
        <form [formGroup]="campStartDetailsForm" *ngIf="isCampStarted || isCampClosed"> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Camp Started Status <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesCampStartStatus" formControlName="campStartStatus" required  />
                    <label class="mx-2 labelCls" for="YesCampStartStatus">Yes</label>
                    <input type="radio" value="N" id="noCampStartStatus" formControlName="campStartStatus" required  />
                    <label class="mx-2 labelCls" for="noCampStartStatus" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.campStartStatus.errors" class="selectError">
                        <div *ngIf="f1.campStartStatus.errors.required">Please select Camp Started Status </div>
                    </div>
                </div> 
            </div>           
            <div class="row mb-3">
                <div class="checkBoxCont col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label class="labelCls">Photo of the Camp Upload<span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="custom-file" *ngIf="!isCampClosed">
                    <input type="file" class="custom-file-input" formControlName="campStartPhoto" id="campStartPhoto" accept=".jpg, .jpeg, .png" required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f1.campStartPhoto.errors }" (change)="onCampStartPhotoSelected($event)">
                    <label class="custom-file-label" for="campStartPhoto"><i class="fas fa-search"></i> {{campStartPhotoLabelValue}}</label>
                    </div>
                    <div class="clearCls" (click)="clearCampStartPhotoUpload('campStartPhoto', 'campStartPhotoLabel')" *ngIf="f1.campStartPhoto.value"></div>
                    <img *ngIf="isCampClosed" [src] ='campStartImg' height="100px">
                </div>
                <div *ngIf="isCampDetailsSubmitted && f1.campStartPhoto.errors" class="selectError offset-md-6 pl-3">
                    <div *ngIf="f1.campStartPhoto.errors.required">Please upload camp photo</div>
                    <div *ngIf="f1.campStartPhoto.errors?.inValidExt && !f1.campStartPhoto.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                    <div *ngIf="f1.campStartPhoto.errors?.inValidSize && !f1.campStartPhoto.errors.required && !f1.campStartPhoto.errors.inValidExt">File size exceeding 1MB</div> 
                </div>
            </div> 
            <div class="row mb-3">
                <div class="checkBoxCont col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label class="labelCls">Doctor attendance sheets photo upload<span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="custom-file" *ngIf="!isCampClosed">
                    <input type="file" class="custom-file-input" formControlName="docAttendanceSheet" id="docAttendanceSheet" accept=".jpg, .jpeg, .png" required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f1.docAttendanceSheet.errors }" (change)="onDocAttendanceSheetSelected($event)">
                    <label class="custom-file-label" for="docAttendanceSheet"><i class="fas fa-search"></i> {{docAttendanceSheetLabelValue}}</label>
                    </div>
                    <div class="clearCls" (click)="clearCampStartPhotoUpload('docAttendanceSheet', 'docAttendanceSheetLabel')" *ngIf="f1.docAttendanceSheet.value"></div>
                    <img *ngIf="isCampClosed" [src] ='docSheetImg' height="100px">
                </div>
                <div class="offset-md-6 pl-3" *ngIf="!isCampClosed"><a href="../../assets/JAS-DoctorAttendancesheet.pdf" download>Download Sample Doctor Attendance Sheet</a></div>
                <div *ngIf="isCampDetailsSubmitted && f1.docAttendanceSheet.errors" class="selectError offset-md-6 pl-3">
                    <div *ngIf="f1.docAttendanceSheet.errors.required">Please upload Doctor attendance sheets photo</div>
                    <div *ngIf="f1.docAttendanceSheet.errors?.inValidExt && !f1.docAttendanceSheet.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                    <div *ngIf="f1.docAttendanceSheet.errors?.inValidSize && !f1.docAttendanceSheet.errors.required && !f1.docAttendanceSheet.errors.inValidExt">File size exceeding 1MB</div> 
                </div>
            </div>           
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Female Doctor Present <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesIsFemaleDocPresent" formControlName="isFemaleDocPresent" required  />
                    <label class="mx-2 labelCls" for="YesIsFemaleDocPresent">Yes</label>
                    <input type="radio" value="N" id="noIsFemaleDocPresent" formControlName="isFemaleDocPresent" required  />
                    <label class="mx-2 labelCls" for="noIsFemaleDocPresent" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.isFemaleDocPresent.errors" class="selectError">
                        <div *ngIf="f1.isFemaleDocPresent.errors.required">Please select if Female Doctor Present </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Total number of rooms: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="totalRooms" formControlName="totalRooms" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f1.totalRooms.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f1.totalRooms.errors" class="invalid-feedback">
                        <div *ngIf="f1.totalRooms.errors.required">Please enter Total number of rooms</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Waiting room availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesWaitingRoomAvail" formControlName="waitingRoomAvail" required  />
                    <label class="mx-2 labelCls" for="YesWaitingRoomAvail">Yes</label>
                    <input type="radio" value="N" id="noWaitingRoomAvail" formControlName="waitingRoomAvail" required  />
                    <label class="mx-2 labelCls" for="noWaitingRoomAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.waitingRoomAvail.errors" class="selectError">
                        <div *ngIf="f1.waitingRoomAvail.errors.required">Please select Waiting room availability  </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Private Room Availability for Female Patient Checkups <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesPrivateRoomAvail" formControlName="privateRoomAvail" required  />
                    <label class="mx-2 labelCls" for="YesPrivateRoomAvail">Yes</label>
                    <input type="radio" value="N" id="noPrivateRoomAvail" formControlName="privateRoomAvail" required  />
                    <label class="mx-2 labelCls" for="noPrivateRoomAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.privateRoomAvail.errors" class="selectError">
                        <div *ngIf="f1.privateRoomAvail.errors.required">Please select Private Room Availability for Female Patient Checkups  </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Help desk and Registration desk availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesHelpdeskRegAvail" formControlName="helpdeskRegAvail" required  />
                    <label class="mx-2 labelCls" for="YesHelpdeskRegAvail">Yes</label>
                    <input type="radio" value="N" id="noHelpdeskRegAvail" formControlName="helpdeskRegAvail" required  />
                    <label class="mx-2 labelCls" for="noHelpdeskRegAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.helpdeskRegAvail.errors" class="selectError">
                        <div *ngIf="f1.helpdeskRegAvail.errors.required">Please select Help desk and Registration desk availability </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Medication kits received <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesMedicationKits" formControlName="medicationKits" required  />
                    <label class="mx-2 labelCls" for="YesMedicationKits">Yes</label>
                    <input type="radio" value="N" id="noMedicationKits" formControlName="medicationKits" required  />
                    <label class="mx-2 labelCls" for="noMedicationKits" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.medicationKits.errors" class="selectError">
                        <div *ngIf="f1.medicationKits.errors.required">Please select Medication kits received </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">JAS Folders availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesJASFoldersAvail" formControlName="JASFoldersAvail" required  />
                    <label class="mx-2 labelCls" for="YesJASFoldersAvail">Yes</label>
                    <input type="radio" value="N" id="noJASFoldersAvail" formControlName="JASFoldersAvail" required  />
                    <label class="mx-2 labelCls" for="noJASFoldersAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.JASFoldersAvail.errors" class="selectError">
                        <div *ngIf="f1.JASFoldersAvail.errors.required">Please select JAS Folders availability </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">JAS Case sheets availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesJASCaseSheetAvail" formControlName="JASCaseSheetAvail" required  />
                    <label class="mx-2 labelCls" for="YesJASCaseSheetAvail">Yes</label>
                    <input type="radio" value="N" id="noJASCaseSheetAvail" formControlName="JASCaseSheetAvail" required  />
                    <label class="mx-2 labelCls" for="noJASCaseSheetAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.JASCaseSheetAvail.errors" class="selectError">
                        <div *ngIf="f1.JASCaseSheetAvail.errors.required">Please select JAS Case sheets availability </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">JAS Bags availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesJASBagsAvail" formControlName="JASBagsAvail" required  />
                    <label class="mx-2 labelCls" for="YesJASBagsAvail">Yes</label>
                    <input type="radio" value="N" id="noJASBagsAvail" formControlName="JASBagsAvail" required  />
                    <label class="mx-2 labelCls" for="noJASBagsAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.JASBagsAvail.errors" class="selectError">
                        <div *ngIf="f1.JASBagsAvail.errors.required">Please select JAS Bags availability </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">JAS Banner and Standee availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesJASBannerAvail" formControlName="JASBannerAvail" required  />
                    <label class="mx-2 labelCls" for="YesJASBannerAvail">Yes</label>
                    <input type="radio" value="N" id="noJASBannerAvail" formControlName="JASBannerAvail" required  />
                    <label class="mx-2 labelCls" for="noJASBannerAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.JASBannerAvail.errors" class="selectError">
                        <div *ngIf="f1.JASBannerAvail.errors.required">Please select JAS Banner and Standee availability </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Eye test facilities availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesEyeTestAvail" formControlName="eyeTestAvail" required  />
                    <label class="mx-2 labelCls" for="YesEyeTestAvail">Yes</label>
                    <input type="radio" value="N" id="noEyeTestAvail" formControlName="eyeTestAvail" required  />
                    <label class="mx-2 labelCls" for="noEyeTestAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.eyeTestAvail.errors" class="selectError">
                        <div *ngIf="f1.eyeTestAvail.errors.required">Please select Eye test facilities availability </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Availability of Basic Facilities (Drinking Water, Toilets and Refreshments) <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesBasicFacilitiesAvail" formControlName="basicFacilitiesAvail" required  />
                    <label class="mx-2 labelCls" for="YesBasicFacilitiesAvail">Yes</label>
                    <input type="radio" value="N" id="noBasicFacilitiesAvail" formControlName="basicFacilitiesAvail" required  />
                    <label class="mx-2 labelCls" for="noBasicFacilitiesAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.basicFacilitiesAvail.errors" class="selectError">
                        <div *ngIf="f1.basicFacilitiesAvail.errors.required">Please select Availability of Basic Facilities </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Mask and Sanitizer availability <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesMaskSanitizerAvail" formControlName="maskSanitizerAvail" required  />
                    <label class="mx-2 labelCls" for="YesMaskSanitizerAvail">Yes</label>
                    <input type="radio" value="N" id="noMaskSanitizerAvail" formControlName="maskSanitizerAvail" required  />
                    <label class="mx-2 labelCls" for="noMaskSanitizerAvail" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f1.maskSanitizerAvail.errors" class="selectError">
                        <div *ngIf="f1.maskSanitizerAvail.errors.required">Please select Mask and Sanitizer availability </div>
                    </div>
                </div> 
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Count of patients present in camp at start: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="patientsCount" formControlName="patientsCount" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f1.patientsCount.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f1.patientsCount.errors" class="invalid-feedback">
                        <div *ngIf="f1.patientsCount.errors.required">Please enter count of patients present in camp at start</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">No of specialists attended: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="specialistAttendCount" formControlName="specialistAttendCount" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f1.specialistAttendCount.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f1.specialistAttendCount.errors" class="invalid-feedback">
                        <div *ngIf="f1.specialistAttendCount.errors.required">Please enter no of specialists attended</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Total number of doctors attended: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="doctorsAttended" formControlName="doctorsAttended" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f1.doctorsAttended.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f1.doctorsAttended.errors" class="invalid-feedback">
                        <div *ngIf="f1.doctorsAttended.errors.required">Please enter total number of doctors attended</div>
                    </div>
                </div>
            </div>
        </form>
        <h6 class="mb-3" *ngIf="isCampClosed">Camp End Details</h6>
        <form [formGroup]="campEndDetailsForm" *ngIf="isCampEnded || isCampClosed">   
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Count of patients attended in total: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="totalPatientsCount" formControlName="totalPatientsCount" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.totalPatientsCount.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.totalPatientsCount.errors" class="invalid-feedback">
                        <div *ngIf="f2.totalPatientsCount.errors.required">Please enter count of patients attended in total</div>
                    </div>
                </div>
            </div> 
            <div class="row mb-3">
                <div class="checkBoxCont col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label class="labelCls">Camp end photo upload<span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="custom-file" *ngIf="!isCampClosed">
                    <input type="file" class="custom-file-input" formControlName="campEndPhoto" id="campEndPhoto" accept=".jpg, .jpeg, .png" required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.campEndPhoto.errors }" (change)="onCampEndPhotoSelected($event)">
                    <label class="custom-file-label" for="campEndPhoto"><i class="fas fa-search"></i> {{campEndPhotoLabelValue}}</label>
                    </div>
                    <div class="clearCls" (click)="clearCampStartPhotoUpload('campEndPhoto', 'campEndPhotoLabel')" *ngIf="f2.campEndPhoto.value"></div>
                    <img *ngIf="isCampClosed" [src] ='campEndImg' height="100px">
                </div>
                <div *ngIf="isCampDetailsSubmitted && f2.campEndPhoto.errors" class="selectError offset-md-6 pl-3">
                    <div *ngIf="f2.campEndPhoto.errors.required">Please upload camp end photo</div>
                    <div *ngIf="f2.campEndPhoto.errors?.inValidExt && !f2.campEndPhoto.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                    <div *ngIf="f2.campEndPhoto.errors?.inValidSize && !f2.campEndPhoto.errors.required && !f2.campEndPhoto.errors.inValidExt">File size exceeding 1MB</div> 
                </div>
            </div>  
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Post camp sanitization of venue completed <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesSanitizationOfVenue" formControlName="sanitizationOfVenue" required  />
                    <label class="mx-2 labelCls" for="YesSanitizationOfVenue">Yes</label>
                    <input type="radio" value="N" id="noSanitizationOfVenue" formControlName="sanitizationOfVenue" required  />
                    <label class="mx-2 labelCls" for="noSanitizationOfVenue" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f2.sanitizationOfVenue.errors" class="selectError">
                        <div *ngIf="f2.sanitizationOfVenue.errors.required">Post camp sanitization of venue completed status is required </div>
                    </div>
                </div> 
            </div> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Total OPs: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="totalOPs" formControlName="totalOPs" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.totalOPs.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.totalOPs.errors" class="invalid-feedback">
                        <div *ngIf="f2.totalOPs.errors.required">Please enter total OPs</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Total Number of Women OPs: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="totalWomenOPs" formControlName="totalWomenOPs" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.totalWomenOPs.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.totalWomenOPs.errors" class="invalid-feedback">
                        <div *ngIf="f2.totalWomenOPs.errors.required">Please enter total Number of Women OPs</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Total number of Men OPs: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="totalMenOPs" formControlName="totalMenOPs" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.totalMenOPs.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.totalMenOPs.errors" class="invalid-feedback">
                        <div *ngIf="f2.totalMenOPs.errors.required">Please enter total number of Men OPs</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Number of School children: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfSchoolChilds" formControlName="noOfSchoolChilds" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfSchoolChilds.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfSchoolChilds.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfSchoolChilds.errors.required">Please enter number of School children</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">No. of Severe Acute Malnourished(SAM)/Moderate Acute Malnourished(MAM) children from Angawadi Centers(AWCs): <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfAnganwadiChilds" formControlName="noOfAnganwadiChilds" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfAnganwadiChilds.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfAnganwadiChilds.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfAnganwadiChilds.errors.required">Please enter no. of Severe Acute Malnourished(SAM)/Moderate Acute Malnourished(MAM) children from Angawadi Centers(AWCs)</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">No. of Pregnant/lactating mothers: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfPregnants" formControlName="noOfPregnants" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfPregnants.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfPregnants.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfPregnants.errors.required">Please enter no. of Pregnant/lactating mothers</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">No. of eye screening done: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfEyeScreenings" formControlName="noOfEyeScreenings" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfEyeScreenings.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfEyeScreenings.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfEyeScreenings.errors.required">Please enter no. of eye screening done</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">No. of spectacles distributed under Kanti Velugu: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfSpectacles" formControlName="noOfSpectacles" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfSpectacles.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfSpectacles.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfSpectacles.errors.required">Please enter no. of spectacles distributed under Kanti Velugu</div>
                    </div>
                </div>
            </div>   
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Whether awareness session on NCD/CD conducted using flip chart<span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="yesAwarenessSession" formControlName="awarenessSession" required  />
                    <label class="mx-2 labelCls" for="yesAwarenessSession">Yes</label>
                    <input type="radio" value="N" id="noAwarenessSession" formControlName="awarenessSession" required  />
                    <label class="mx-2 labelCls" for="noAwarenessSession" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f2.awarenessSession.errors" class="selectError">
                        <div *ngIf="f2.awarenessSession.errors.required">Whether awareness session on NCD/CD conducted using flip chart is required </div>
                    </div>
                </div> 
            </div> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Whether awareness on Aarogyasri conducted during the health camp <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="yesAwarenessAarogyasri" formControlName="awarenessAarogyasri" required  />
                    <label class="mx-2 labelCls" for="yesAwarenessAarogyasri">Yes</label>
                    <input type="radio" value="N" id="noAwarenessAarogyasri" formControlName="awarenessAarogyasri" required  />
                    <label class="mx-2 labelCls" for="noAwarenessAarogyasri" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f2.awarenessAarogyasri.errors" class="selectError">
                        <div *ngIf="f2.awarenessAarogyasri.errors.required">Whether awareness on Aarogyasri conducted during the health camp is required </div>
                    </div>
                </div> 
            </div> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Whether session on health and nutrition conducted by ANM <span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesSessionByANM" formControlName="sessionByANM" required  />
                    <label class="mx-2 labelCls" for="YesSessionByANM">Yes</label>
                    <input type="radio" value="N" id="noSessionByANM" formControlName="sessionByANM" required  />
                    <label class="mx-2 labelCls" for="noSessionByANM" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f2.sessionByANM.errors" class="selectError">
                        <div *ngIf="f2.sessionByANM.errors.required">Whether session on health and nutrition conducted by ANM is required </div>
                    </div>
                </div> 
            </div> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">Whether nutrition stall set up by AWCs<span class="mandatoryCls">*</span></label>
                <div class="displayInlineBlk col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="radio" value="Y" id="YesNutritionStall" formControlName="nutritionStall" required  />
                    <label class="mx-2 labelCls" for="YesNutritionStall">Yes</label>
                    <input type="radio" value="N" id="noNutritionStall" formControlName="nutritionStall" required  />
                    <label class="mx-2 labelCls" for="noNutritionStall" >No</label>
                    <div *ngIf="isCampDetailsSubmitted && f2.nutritionStall.errors" class="selectError">
                        <div *ngIf="f2.nutritionStall.errors.required">Whether nutrition stall set up by AWCs is required </div>
                    </div>
                </div> 
            </div> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">No. of citizens who received medication : <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfCitizensCampEnd" formControlName="noOfCitizensCampEnd" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfCitizensCampEnd.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfCitizensCampEnd.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfCitizensCampEnd.errors.required">Please enter no. of citizens who received medication </div>
                    </div>
                </div>
            </div> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12"> No. of doctors present: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfDoctorsCampEnd" formControlName="noOfDoctorsCampEnd" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfDoctorsCampEnd.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfDoctorsCampEnd.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfDoctorsCampEnd.errors.required">Please enter  no. of doctors present</div>
                    </div>
                </div>
            </div> 
            <div class="row mb-3">
                <label class="labelCls col col-lg-6 col-md-6 col-sm-12 col-xs-12">No. of specialists present: <span class="mandatoryCls">*</span></label>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <input type="text" name="noOfSpecialistCampEnd" formControlName="noOfSpecialistCampEnd" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isCampDetailsSubmitted && f2.noOfSpecialistCampEnd.errors }" numbersOnly maxlength="5">
                    <div *ngIf="isCampDetailsSubmitted && f2.noOfSpecialistCampEnd.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfSpecialistCampEnd.errors.required">Please enter no. of specialists present</div>
                    </div>
                </div>
            </div>  
        </form>
        <div class="btnHolder mt-4">
            <button class="greenBtnCls pull-right" *ngIf="isCampStarted"  (click)="updateCampStartDetails()">Submit</button>
            <button class="greenBtnCls pull-right" *ngIf="isCampEnded" (click)="updateCampEndDetails()">Submit</button>
        </div>
    </div>
</ng-template>

<ng-template #campSplitModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="splitCampLabel">Split Camp Schedule</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <form [formGroup]="splitCampForm"> 
            <div class="row">
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Schedule Date: <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minCampSplitDate"                        
                            required formControlName="sheduleDate" [maxDate]="maxDate" [markDisabled]="isDisabled"
                            [ngClass]="{ 'is-invalid':isSplitSubmitted && f3.sheduleDate.errors }"
                            >
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="isSplitSubmitted && f3.sheduleDate.errors" class="selectError">
                        <div *ngIf="f3.sheduleDate.errors?.required || f3.sheduleDate.invalid">Please enter valid Schedule Date</div>
                    </div>
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Team: <span class="mandatoryCls">*</span></label>
                    <select  class="selectCls" required formControlName = "team" [ngClass]="{ 'is-invalid':isSplitSubmitted && (f3.team.errors || f3.team.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option *ngFor="let team of teams" [ngValue]="team.teaM_NAME">{{team.teaM_NAME}}</option>
                    </select>
                    <div *ngIf="isSplitSubmitted && (f3.team.errors || f3.team.value === 'Select')" class="selectError">
                        <div *ngIf="f3.team.errors?.required || f3.team.value === 'Select'">Please select team</div>
                    </div>
                </div>              
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Venue Type: <span class="mandatoryCls">*</span></label>
                    <select  class="selectCls" required formControlName = "VenueType" [ngClass]="{ 'is-invalid':isSplitSubmitted && (f3.VenueType.errors || f3.VenueType.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option  [ngValue]= "'School'">School</option>
                        <option  [ngValue]= "'Urban Health Centre'">Urban Health Centre</option>
                        <option  [ngValue]= "'Junior College'">Junior College</option>
                        <option  [ngValue]= "'Secretariat'">Secretariat</option>
                        <option  [ngValue]= "'Village Clinic'">Village Clinic</option>
                        <option  [ngValue]= "'Degree College'">Degree College</option>
                        <option  [ngValue]= "'Community Hall'">Community Hall</option>
                        <option  [ngValue]= "'Others'">Others</option>
                    </select>               
                    <div *ngIf="isSplitSubmitted && (f3.VenueType.errors || f3.VenueType.value === 'Select')" class="selectError">
                        <div *ngIf="f3.VenueType.errors?.required || f3.VenueType.value === 'Select'">Please select Venue Type</div>
                    </div>
                </div> 
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Venue: <span class="mandatoryCls">*</span></label>
                    <input  type="text" class="inputCls value" name="Venue" id="Venue"
                        formControlName = "Venue"  required [ngClass]="{ 'is-invalid':isSplitSubmitted && f3.Venue.errors }"
                        maxlength="100" placeholder="i.e. Urban Health Centre">
                    <div *ngIf="isSplitSubmitted && f3.Venue.errors" class="invalid-feedback">
                        <div *ngIf="f3.Venue.errors?.required  || f3.Venue.errors?.whiteSpaceValidate">Please enter Venue</div>
                        <div *ngIf="f3.Venue.errors?.maxlength">Please enter valid Venue</div>
                    </div>
                </div> 
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">VHC: <span class="mandatoryCls">*</span></label>
                    <select  class="selectCls" required formControlName = "VHC" (change)="onChangeSplitVHC()" [ngClass]="{ 'is-invalid':isSplitSubmitted && (f3.VHC.errors || f3.VHC.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option *ngFor="let VHC of SplitVHCList" [ngValue]="VHC">{{VHC.vhC_NAME}}</option>
                    </select>               
                    <div *ngIf="isSplitSubmitted && (f3.VHC.errors || f3.VHC.value === 'Select')" class="selectError">
                        <div *ngIf="f3.VHC.errors?.required || f3.VHC.value === 'Select'">Please select VHC</div>
                    </div>
                </div> 
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
                    <select  class="selectCls" required formControlName = "secretariat" [ngClass]="{ 'is-invalid':isSplitSubmitted && (f3.secretariat.errors || f3.secretariat.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option *ngFor="let secretariat of secretariatList" [ngValue]="secretariat.secretariaT_CODE">{{secretariat.secretariaT_NAME}}</option>
                    </select>
                    <div *ngIf="isSplitSubmitted && (f3.secretariat.errors || f3.secretariat.value === 'Select')" class="selectError">
                        <div *ngIf="f3.secretariat.errors?.required || f3.secretariat.value === 'Select'">Please select secretariat</div>
                    </div>
                </div>              
            </div>
        </form>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="splitCamp()">Split Camp</button>
    </div>
</ng-template>


<!-- <ng-template #campSplitModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="splitCampLabel">Split Camp Schedule</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span >&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <form [formGroup]="splitCampForm"> 
            <div class="row">
                <div class="col col-lg-1 col-md-1 col-sm-12 col-xs-12 mb-3">
                    <label class=""><b>Camp 1:</b></label>                    
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Schedule Date:</label>
                    <div class="input-group">
                        <input [attr.disabled]="true"class="calendarCls form-control"                       
                         formControlName="scheduleDateCamp1">
                    </div>
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Venue Type:</label>
                    <select [attr.disabled]="true" class="selectCls"  formControlName = "venueTypeCamp1">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option  [ngValue]= "'School'">School</option>
                        <option  [ngValue]= "'Urban Health Centre'">Urban Health Centre</option>
                        <option  [ngValue]= "'Junior College'">Junior College</option>
                        <option  [ngValue]= "'Secretariat'">Secretariat</option>
                        <option  [ngValue]= "'Village Clinic'">Village Clinic</option>
                        <option  [ngValue]= "'Degree College'">Degree College</option>
                        <option  [ngValue]= "'Community Hall'">Community Hall</option>
                        <option  [ngValue]= "'Others'">Others</option>
                    </select>
                </div> 
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Venue:</label>
                    <input [attr.disabled]="true" type="text" class="inputCls value" name="venueAddrCamp1" id="venueAddrCamp1"
                        formControlName = "venueAddrCamp1" 
                        maxlength="100" placeholder="i.e. Urban Health Centre">
                </div>
                <div class="col col-lg-5 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Secretariat:</label>                    
                    <ng-multiselect-dropdown 
                        [placeholder]="'Select'"
                        [settings]="dropdownSettings"
                        [data]="secretariatList"
                        formControlName="secCodeListCamp1">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="row" *ngIf="selectedSchedule.spliT_FLAG >= '2'">
                <div class="col col-lg-1 col-md-1 col-sm-12 col-xs-12 mb-3">
                    <label class=""><b>Camp 2:</b></label>                    
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Schedule Date:</label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="ds2"  ngbDatepicker #ds2="ngbDatepicker" [minDate]="minCampSplitDate"                        
                            formControlName="scheduleDateCamp2" [maxDate]="maxDate" [markDisabled]="isDisabled">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="ds2.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Venue Type:</label>
                    <select  class="selectCls" formControlName = "venueTypeCamp2">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option  [ngValue]= "'School'">School</option>
                        <option  [ngValue]= "'Urban Health Centre'">Urban Health Centre</option>
                        <option  [ngValue]= "'Junior College'">Junior College</option>
                        <option  [ngValue]= "'Secretariat'">Secretariat</option>
                        <option  [ngValue]= "'Village Clinic'">Village Clinic</option>
                        <option  [ngValue]= "'Degree College'">Degree College</option>
                        <option  [ngValue]= "'Community Hall'">Community Hall</option>
                        <option  [ngValue]= "'Others'">Others</option>
                    </select> 
                </div> 
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Venue:</label>
                    <input  type="text" class="inputCls value" name="venueAddrCamp2" id="venueAddrCamp2"
                        formControlName = "venueAddrCamp2"
                        maxlength="100" placeholder="i.e. Urban Health Centre">
                </div>
                <div class="col col-lg-5 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Secretariat:</label>                    
                    <ng-multiselect-dropdown 
                        [placeholder]="'Select'"
                        [settings]="dropdownSettings"
                        [data]="secretariatList"
                        formControlName="secCodeListCamp2">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="row"  *ngIf="selectedSchedule.spliT_FLAG == '3'">
                <div class="col col-lg-1 col-md-1 col-sm-12 col-xs-12 mb-3">
                    <label class=""><b>Camp 3:</b></label>                    
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Schedule Date:</label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="ds3"  ngbDatepicker #ds3="ngbDatepicker" [minDate]="minCampSplitDate"                        
                            formControlName="scheduleDateCamp3" [maxDate]="maxDate" [markDisabled]="isDisabled">
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="ds3.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Venue Type:</label>
                    <select  class="selectCls" formControlName = "venueTypeCamp3">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option  [ngValue]= "'School'">School</option>
                        <option  [ngValue]= "'Urban Health Centre'">Urban Health Centre</option>
                        <option  [ngValue]= "'Junior College'">Junior College</option>
                        <option  [ngValue]= "'Secretariat'">Secretariat</option>
                        <option  [ngValue]= "'Village Clinic'">Village Clinic</option>
                        <option  [ngValue]= "'Degree College'">Degree College</option>
                        <option  [ngValue]= "'Community Hall'">Community Hall</option>
                        <option  [ngValue]= "'Others'">Others</option>
                    </select>
                </div> 
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Venue:</label>
                    <input  type="text" class="inputCls value" name="venueAddrCamp3" id="venueAddrCamp3"
                        formControlName = "venueAddrCamp3"
                        maxlength="100" placeholder="i.e. Urban Health Centre">
                </div>
                <div class="col col-lg-5 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Secretariat:</label>                    
                    <ng-multiselect-dropdown 
                        [placeholder]="'Select'"
                        [settings]="dropdownSettings"
                        [data]="secretariatList"
                        formControlName="secCodeListCamp3">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="splitCamp()">Split Camp</button>
    </div>
</ng-template> -->